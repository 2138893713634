<template>
  <v-container fluid>
    <Landscape />
    <v-card style="z-index: 99" elevation="1" v-if="isGranted('CUSTOMERS_INDEX')">
      <v-toolbar color="primary" dark elevation="0">
        <v-card-title> Liste des postes supervisés </v-card-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="customersList"
        :server-items-length="maxItem"
        :items-per-page="limitItem"
        class="elevation-1"
        @update:items-per-page="setItemMax"
        @update:page="setPage"
        @click:row="loadSupervision"
        :loading="loading"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
      >
        <template v-slot:item.item.installDate="{ item }">
          {{ dayjs(item.item.installDate).format("DD/MM/YYYY") }}
        </template>

        <template v-slot:body.prepend>
          <tr>
            <td v-if="checkArrayContains('customer.name', headers)">
              <v-text-field v-model="search.customerName" type="text" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('item.type', headers)">
              <v-text-field v-model="search.type" type="text" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('item.name', headers)">
              <v-text-field v-model="search.name" type="text" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('item.manufacturer', headers)">
              <v-text-field v-model="search.manufacturer" type="text" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('item.model', headers)">
              <v-text-field v-model="search.model" type="text" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('item.os', headers)">
              <v-text-field v-model="search.os" type="text" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('item.serialNumber', headers)">
              <v-text-field v-model="search.serialNumber" type="text" label="Rechercher"></v-text-field>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Tools from "../../mixins/Tools";
import Landscape from "../../components/common/Landscape.vue";

export default {
  name: "listSupervision",
  components: { Landscape },
  mixins: [Tools, simpleDebounce],

  data() {
    return {
      sortedBy: null,
      sortedDesc: false,
      allUser: false,
      allAgency: false,
      limitItem: 10,
      loading: false,
      listValue: [
        { name: "", value: null },
        { name: "Oui", value: true },
        { name: "Non", value: false },
      ],
      headers: [
        { text: "Client", value: "customer.name" },
        { text: "Type de poste", value: "item.type" },
        { text: "Poste", value: "item.name" },
        { text: "Marque", value: "item.manufacturer" },
        { text: "Modèle", value: "item.model" },
        { text: "OS", value: "item.os" },
        { text: "N. Série", value: "item.serialNumber" },
        { text: "Date install.", value: "item.installDate" },
      ],
      columnsAvailable: [],
      customersList: [],
      search: {
        name: "",
        customerName: "",
        os: "",
        manufacturer: "",
        type: "",
        serialNumber: "",
        model: "",
      },
      maxItem: 0,
      page: 1,
      date: new Date(),
      asUser: null,
    };
  },
  mounted() {
    let search = sessionStorage.getItem("searchSupervision");
    if (search) {
      this.search = JSON.parse(search);
    }
    this.getSupervisionList();
  },
  beforeDestroy() {
    sessionStorage.setItem("searchSupervision", JSON.stringify(this.search));
  },
  methods: {
    toggleItem(item, key) {
      let index = this.headers.findIndex((x) => x.value === item.value);
      let tmp = key + 1;
      let valI = -1;
      while (valI === -1 && tmp < this.columnsAvailable.length) {
        valI = this.headers.findIndex((x) => x.value === this.columnsAvailable[tmp].value);
        tmp += 1;
      }
      if (index === -1) {
        if (key === 0) {
          this.headers.splice(0, 0, item);
        } else if (key === this.columnsAvailable.length - 1 || valI === -1) {
          this.headers.push(this.columnsAvailable[key]);
        } else {
          this.headers.splice(valI, 0, item);
        }
      } else {
        this.headers.splice(index, 1);
      }
      this.$forceUpdate();
    },

    loadSupervision(row) {
      this.$router.push({ name: "Supervision", params: { id: row.item.id } });
    },
    setItemMax(event) {
      this.limitItem = event;
    },
    setPage(event) {
      this.page = event;
    },
    getSupervisionList() {
      this.loading = true;
      this.customersList = [];
      let search = this.search;

      this.$axios("/api/supervision/swdevices", {
        params: { page: this.page, search, limit: this.limitItem, asUser: this.asUser, allUser: this.allUser, allAgency: this.allAgency },
      }).then((resp) => {
        this.maxItem = resp.data.max;
        this.customersList = resp.data.data;
      });
      this.loading = false;
    },
    getSupervisionListDebounce() {
      this.simpleDebounce(() => {
        this.getSupervisionList();
      }, 300);
    },
    getSupervisionListDebouncePage1() {
      this.page = 1;
      this.getSupervisionListDebounce();
    },
  },
  watch: {
    "search.name": "getSupervisionListDebouncePage1",
    "search.customerName": "getSupervisionListDebouncePage1",
    "search.os": "getSupervisionListDebouncePage1",
    "search.manufacturer": "getSupervisionListDebouncePage1",
    "search.model": "getSupervisionListDebouncePage1",
    "search.type": "getSupervisionListDebouncePage1",
    "search.serialNumber": "getSupervisionListDebouncePage1",
    limitItem: "getSupervisionListDebounce",
    page: "getSupervisionListDebounce",
    sortedBy() {
      this.search.sortedBy = this.sortedBy;
      this.getSupervisionListDebounce();
    },
    sortedDesc() {
      this.search.sortedDesc = this.sortedDesc;
      this.getSupervisionListDebounce();
    },
  },
};
</script>

<style scoped></style>
