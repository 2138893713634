<template>
  <material-card title="Compte" icon="fa-user-circle" class="mt-8">
    <v-container fluid id="customer">
      <v-row>
        <v-col cols="12" style="">
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="fas fa-angle-down" @click="changeFilterGroup('Compte')"> Filtrer par compte </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-7">
                <v-row style="z-index: 150">
                  <v-col cols="2" class="text-center pt-2 pb-0">
                    <v-text-field
                      outlined
                      v-model="search.name"
                      label="Rechercher un compte"
                      :append-outer-icon="search.name.length > 0 ? 'fa-times' : ''"
                      @click:append-outer="search.name = ''"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="text-center pt-2 pb-0">
                    <v-text-field
                      outlined
                      type="phone"
                      v-model="search.phone"
                      label="Rechercher par téléphone"
                      :append-outer-icon="search.phone.length > 0 ? 'fa-times' : ''"
                      @click:append-outer="search.phone = ''"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-select
                      chips
                      deletable-chips
                      label="Prospect"
                      item-text="name"
                      item-value="id"
                      :items="types"
                      v-model="prospect"
                      multiple
                      :append-outer-icon="filterBU !== null ? 'fa-times' : ''"
                      outlined
                      @click:append-outer="filterBU = null"
                    ></v-select>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-select
                      chips
                      deletable-chips
                      label="Client"
                      item-text="name"
                      item-value="id"
                      :items="types"
                      v-model="client"
                      multiple
                      :append-outer-icon="filterBU !== null ? 'fa-times' : ''"
                      outlined
                      @click:append-outer="filterBU = null"
                    ></v-select>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-autocomplete
                      :menu-props="{ maxHeight: '300' }"
                      chips
                      v-model="filterConcu"
                      :items="concu"
                      :search-input.sync="searchConcu.name"
                      item-text="entreprise.name"
                      item-value="entreprise.concurrents.id"
                      label="Concurrent"
                      multiple
                      cache-items
                      :append-outer-icon="filterConcu !== null ? 'fa-times' : ''"
                      @click:append-outer="filterConcu = null"
                      outlined
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-select
                      chips
                      label="Agence"
                      item-text="name"
                      item-value="id"
                      :items="agency"
                      v-model="filterAgency"
                      multiple
                      :append-outer-icon="filterAgency !== null ? 'fa-times' : ''"
                      outlined
                      @click:append-outer="filterAgency = null"
                    ></v-select>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-select
                      chips
                      label="Effectifs"
                      item-text="name"
                      item-value="id"
                      :items="people"
                      v-model="filterPeople"
                      multiple
                      small
                      :append-outer-icon="filterPeople !== null ? 'fa-times' : ''"
                      outlined
                      @click:append-outer="filterPeople = null"
                    ></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-text-field
                      label="Code APE"
                      v-model="search.ape"
                      :append-outer-icon="search.ape.length > 0 ? 'fa-times' : ''"
                      @click:append-outer="search.ape = ''"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      multiple
                      label="Adresse"
                      v-model="search.addressComp"
                      :items="address"
                      :append-outer-icon="search.addressComp && search.addressComp.length > 0 ? 'fa-times' : ''"
                      @click:append-outer="search.addressComp = []"
                      :search-input.sync="searchAddr"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Ville ou Code Postal"
                      v-model="search.cityPostalCode"
                      :append-outer-icon="search && search.cityPostalCode && search.cityPostalCode.length > 0 ? 'fa-times' : ''"
                      @click:append-outer="search.cityPostalCode = ''"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2" class="py-0">
                    <v-select
                      chips
                      deletable-chips
                      label="Client clé"
                      item-text="name"
                      item-value="id"
                      :items="types"
                      v-model="clientKey"
                      multiple
                      :append-outer-icon="filterBU !== null ? 'fa-times' : ''"
                      outlined
                      @click:append-outer="filterBU = null"
                    ></v-select>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-select
                      chips
                      deletable-chips
                      label="Groupe"
                      item-text="name"
                      item-value="id"
                      :items="groupeList"
                      v-model="groupeKey"
                      multiple
                      :append-outer-icon="filterBU !== null ? 'fa-times' : ''"
                      outlined
                      @click:append-outer="filterBU = null"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="fas fa-angle-down" @click="changeFilterGroup('Parc')"> Filtrer par parc </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-7">
                <v-row class="mt-1" style="z-index: 150">
                  <v-col cols="2">
                    <v-select
                      label="Métiers"
                      item-text="name"
                      item-value="id"
                      :items="types"
                      v-model="parcType"
                      :append-outer-icon="parcType !== null ? 'fa-times' : ''"
                      outlined
                      @click:append-outer="parcType = null"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      :menu-props="{ maxHeight: '300' }"
                      style=""
                      v-model="filterParc"
                      :items="parcItems"
                      multiple
                      cache-items
                      item-text="infra.field1"
                      item-value="infra.field1"
                      :search-input.sync="searchTitleParc"
                      label="Rechercher dans le parc (suggestion)"
                      :append-outer-icon="filterParc !== null && filterParc.length > 0 ? 'fa-times' : ''"
                      @click:append-outer="filterParc = null"
                      small-chips
                      deletable-chips
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <!--  <v-col cols="3">
                    <v-text-field outlined label="Rechercher dans le parc" v-model="manualFilterParc"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      :menu-props="{ maxHeight: '300' }"
                      style="z-index: 150"
                      v-model="filterParcId"
                      :items="prestatairesList"
                      multiple
                      small-chips
                      deletable-chips
                      item-text="entreprise.name"
                      item-value="entreprise.concurrents.id"
                      label="Rechercher un prestataire"
                      class="py-0"
                      :append-outer-icon="filterParcId !== null ? 'fa-times' : ''"
                      @click:append-outer="filterParcId = null"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-menu
                      style="z-index: 150"
                      ref="menuDateStartBirthday"
                      v-model="menuDateEcheanceStart"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateEcheanceStartFormatted"
                          label="Début date d'échéance "
                          persistent-hint
                          v-bind="attrs"
                          @blur="dateEcheanceStart = parseDate(dateEcheanceStartFormatted)"
                          :append-outer-icon="dateEcheanceStartFormatted !== null ? 'fa-times' : ''"
                          @click:append-outer="
                            dateEcheanceStartFormatted = null;
                            dateEcheanceStart = null;
                          "
                          v-on="on"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="dateEcheanceStart" @input="menuDateEcheanceStart = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-menu
                      style="z-index: 150"
                      ref="menuDateStartBirthday"
                      v-model="menuDateEcheanceEnd"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateEcheanceEndFormatted"
                          label="Fin date d'échéance "
                          persistent-hint
                          v-bind="attrs"
                          @blur="dateEcheanceEnd = parseDate(dateEcheanceEndFormatted)"
                          :append-outer-icon="dateEcheanceEndFormatted !== null ? 'fa-times' : ''"
                          @click:append-outer="
                            dateEcheanceEndFormatted = null;
                            menuDateEcheanceEnd = null;
                          "
                          v-on="on"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="dateEcheanceEnd" @input="menuDateEcheanceEnd = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-menu
                      style="z-index: 150"
                      ref="menuDateStartBirthday"
                      v-model="menuDateStartBirthday"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateBirthdayStartFormatted"
                          label="Début date d'anniversaire "
                          persistent-hint
                          v-bind="attrs"
                          @blur="dateBirthdayStart = parseDateWithoutYear(dateBirthdayStartFormatted)"
                          :append-outer-icon="dateBirthdayStartFormatted !== null ? 'fa-times' : ''"
                          @click:append-outer="
                            dateBirthdayStartFormatted = null;
                            dateBirthdayStart = null;
                          "
                          v-on="on"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="dateBirthdayStart" @input="menuDateStartBirthday = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-menu
                      style="z-index: 150"
                      ref="menuDateStartBirthday"
                      v-model="menuDateEndBirthday"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateBirthdayEndFormatted"
                          label="Fin date d'anniversaire "
                          persistent-hint
                          v-bind="attrs"
                          @blur="dateBirthdayEnd = parseDateWithoutYear(dateBirthdayEndFormatted)"
                          :append-outer-icon="dateBirthdayEndFormatted !== null ? 'fa-times' : ''"
                          @click:append-outer="
                            dateBirthdayEndFormatted = null;
                            dateBirthdayEnd = null;
                          "
                          v-on="on"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="dateBirthdayEnd" @input="menuDateEndBirthday = false"></v-date-picker>
                    </v-menu>
                  </v-col>

                  <template v-if="itemsInfras">
                    <template v-if="itemsInfras.classic">
                      <div v-for="(field, key) in Object.keys(itemsInfras.classic)" :key="key">
                        <v-col cols="6" class="py-0" v-if="!itemsInfras.classic[field].label.startsWith('Date')">
                          <v-text-field :label="itemsInfras.classic[field].label" v-model="itemsInfras.classic[field].value"></v-text-field>
                        </v-col>
                      </div>
                    </template>
                    <template v-if="itemsInfras.spe">
                      <v-col cols="6" class="py-0" v-for="(field, key) in Object.keys(itemsInfras.spe)" :key="key + '-spe'">
                        <v-select
                          :menu-props="{ maxHeight: '300' }"
                          item-value="id"
                          item-text="name"
                          :label="itemsInfras.spe[field].label"
                          v-model="itemsInfras.spe[field].value"
                          :items="itemsInfras.spe[field].items"
                          :append-outer-icon="itemsInfras.spe[field].value !== null && itemsInfras.spe[field].value !== '' ? 'fa-times' : ''"
                          @click:append-outer="itemsInfras.spe[field].value = null"
                          outlined
                        ></v-select>
                      </v-col>
                    </template>
                  </template> -->
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="fas fa-angle-down" @click="changeFilterGroup('Abonnement')">
                Filtrer par abonnement
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-10">
                  <v-col cols="3" class="py-0">
                    <v-autocomplete
                      :menu-props="{ maxHeight: '300' }"
                      style="z-index: 150"
                      v-model="searchSubscription"
                      :items="subscriptionItems"
                      cache-items
                      item-text="abIntitule"
                      item-value="abIntitule"
                      :search-input.sync="searchSubscriptionSync"
                      label="Rechercher dans les intitulés abonnements"
                      multiple
                      :append-outer-icon="searchSubscription !== null ? 'fa-times' : ''"
                      @click:append-outer="searchSubscription = null"
                      small-chips
                      deletable-chips
                      outlined
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="3" class="py-0">
                    <v-select
                      outlined
                      :menu-props="{ maxHeight: '300' }"
                      small-chips
                      deletable-chips
                      v-model="searchSubType"
                      :items="typeAbos"
                      multiple
                      style="z-index: 150"
                      label="Type d'abonnement"
                    ></v-select>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-autocomplete
                      outlined
                      :menu-props="{ maxHeight: '300' }"
                      item-text="arRef"
                      item-value="arRef"
                      small-chips
                      deletable-chips
                      v-model="searchCodeSub"
                      :search-input.sync="searchCodeSubs"
                      :items="codeSubs"
                      multiple
                      style="z-index: 150"
                      label="Code article"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-text-field outlined label="Rechercher dans les intitulés d'articles" v-model="searchSubsArt"></v-text-field>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-menu
                      style="z-index: 150"
                      ref="menuDateStartBirthday"
                      v-model="menuSubsDateStartBirthday"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="subsDateBirthdayStartFormatted"
                          label="Date de début"
                          persistent-hint
                          v-bind="attrs"
                          @blur="subsDateBirthdayStart = parseDate(subsDateBirthdayStartFormatted)"
                          :append-outer-icon="subsDateBirthdayStartFormatted !== null ? 'fa-times' : ''"
                          @click:append-outer="
                            subsDateBirthdayStartFormatted = null;
                            subsDateBirthdayStart = null;
                          "
                          v-on="on"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="subsDateBirthdayStart" outlined @input="menuSubsDateStartBirthday = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-menu
                      style="z-index: 150"
                      ref="menuDateStartBirthday"
                      v-model="menuSubsDateEndBirthday"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="subsDateBirthdayEndFormatted"
                          label="Date de fin"
                          persistent-hint
                          v-bind="attrs"
                          @blur="subsDateBirthdayEnd = parseDate(subsDateBirthdayEndFormatted)"
                          :append-outer-icon="subsDateBirthdayEndFormatted !== null ? 'fa-times' : ''"
                          @click:append-outer="
                            subsDateBirthdayEndFormatted = null;
                            subsDateBirthdayEnd = null;
                          "
                          outlined
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="subsDateBirthdayEnd" @input="menuSubsDateEndBirthday = false" outlined></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="fas fa-angle-down" @click="changeFilterGroup('Sectors')"> Filtrer par secteur </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-1">
                  <v-col cols="3">
                    <v-autocomplete
                      :menu-props="{ maxHeight: '300' }"
                      style="z-index: 150"
                      v-model="sector"
                      :items="sectors"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      deletable-chips
                      :search-input.sync="secteurSearchSync"
                      label="Rechercher par secteur"
                      class="py-0"
                      :append-outer-icon="filterParc !== null ? 'fa-times' : ''"
                      @click:append-outer="filterParc = null"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col cols="12" class="mt-5" style="position: relative">
          <div>
            <v-btn-toggle>
              <v-btn elevation="0" small color="primary" @click="getCustomers(true)"><i class="far fa-check-square fa-fw me-1"></i> Tous</v-btn>
              <v-btn elevation="0" small color="secondary" @click="getCustomers(true, true)"
                ><i class="far fa-square fa-fw me-1"></i> Aucun</v-btn
              ></v-btn-toggle
            >
            <v-icon v-if="loading" class="ml-3 spinInfinite" color="primary">fa-fw fa-circle-notch</v-icon>
          </div>
          <v-data-table
            :headers="headers"
            :items="customers"
            :items-per-page="limitItem"
            :server-items-length="max"
            class="elevation-1"
            @click:row="addOrRemove"
            @update:page="setPage"
            @update:items-per-page="setItemMax"
            :footer-props="{
              'items-per-page-options': [10, 20, 50, 100],
            }"
          >
            <template v-slot:item.contact="{ item }"> <v-icon small> fa-users</v-icon> {{ item.max }} </template>

            <template v-slot:item.fav="{ item }"> <v-icon small> fa-star</v-icon> {{ item.fav }} </template>

            <template v-slot:item.entreprise.address="{ item }"> {{ item.entreprise.adresse.address }} {{ item.entreprise.adresse.address2 }} </template>

            <template v-slot:item.entreprise.adresse.city="{ item }"> {{ item.entreprise.adresse.postalCode }} {{ item.entreprise.adresse.city }} </template>

            <template v-slot:item.selected="{ item }">
              <v-checkbox v-model="compaign.customers" :value="item.customer.id" disabled></v-checkbox>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12">
          <span class="text-overline"
            >{{ compaign.customers.length }} Compte{{ compaign.customers.length > 1 ? "s" : "" }} séléctionné{{
              compaign.customers.length > 1 ? "s" : ""
            }}</span
          >
          <div v-if="false">
            <v-chip label class="mr-1" v-for="(custo, key) in customersName" :key="key"
              >{{ custo }} <v-icon @click="remove(custo)" x-small>fa-times</v-icon></v-chip
            >
          </div>
        </v-col>
        <v-col cols="12" class="text-right py-0">
          <v-btn color="primary" @click="continueContact()"> Continuer </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </material-card>
</template>

<script>
import MaterialCard from "../../../../components/common/MaterialCard";
import Tools from "../../../../mixins/Tools";
import simpleDebounce from "simple-debounce-vue-mixin";

export default {
  name: "customerFormCompaign",
  components: { MaterialCard },
  props: ["value", "allUser"],
  mixins: [Tools, simpleDebounce],
  data() {
    return {
      headers: [
        { text: "", sortable: false, value: "selected" },
        { text: "Nom", sortable: false, value: "entreprise.name" },
        { text: "E-mail", sortable: false, value: "entreprise.email" },
        { text: "Adresse", sortable: false, value: "entreprise.adresse.address" },
        { text: "Localisation", sortable: false, value: "entreprise.adresse.city" },
        { text: "Contacts", sortable: false, value: "contact" },
        { text: "Favoris", sortable: false, value: "fav" },
      ],
      loading: false,
      sector: null,
      sectors: [],
      typeAbos: [],
      parcType: null,
      searchCodeSub: null,
      searchCodeSubs: "",
      searchSubsArt: "",
      codeSubs: [],
      subscriptionItems: [],
      dateBirthdayStart: null,
      dateBirthdayStartFormatted: null,
      menuDateStartBirthday: false,
      dateBirthdayEnd: null,
      dateBirthdayEndFormatted: null,
      menuDateEndBirthday: false,
      menuDateEcheanceStart: false,
      menuDateEcheanceEnd: false,
      dateEcheanceStart: null,
      dateEcheanceEnd: null,
      dateEcheanceStartFormatted: null,
      dateEcheanceEndFormatted: null,
      subsDateBirthdayStart: null,
      subsDateBirthdayStartFormatted: null,
      menuSubsDateStartBirthday: false,
      subsDateBirthdayEnd: null,
      subsDateBirthdayEndFormatted: null,
      menuSubsDateEndBirthday: false,
      contacts: [],
      searchTitleParc: "",
      filterParc: [],
      customers: [],
      customersName: [],
      concu: [],
      max: 0,
      item: this.value,
      limitItem: 5,
      page: 1,
      people: [],
      filterGroup: null,
      filterPeople: null,
      filterConcu: null,
      filterCustomers: null,
      filterType: null,
      secteurSearch: null,
      secteurSearchSync: "",
      clientKey: null,
      searchAddr: "",
      search: {
        name: "",
        isIndirect: null,
        isKey1: null,
        isKey2: null,
        isKey3: null,
        isKey4: null,
        siret: "",
        postalCode: "",
        city: "",
        sector: "",
        phone: "",
        ctNumAb: "",
        ape: "",
        sortedBy: null,
        sortedDesc: false,
        concurrentSearch: "",
        cityPostalCode: "",
        addressComp: [],
      },
      compaign: this.value,
      prospect: [],
      client: [],
      searchConcu: {
        name: "",
        isIndirect: null,
        siret: "",
        postalCode: "",
        city: "",
        sector: "",
        phone: "",
        ctNumAb: "",
        ape: "",
        sortedBy: null,
        sortedDesc: false,
      },
      searchSubscription: null,
      searchSubType: null,
      searchSubscriptionSync: "",
      parcItems: [],
      filterBU: null,
      filterAgency: null,
      types: [],
      agency: [],
      address: [],
      groupeList: [],
      groupeKey: null,
      filterParcId: null,
      contactsInfos: {},
      headersInfras: {},
      itemsInfras: [],
      manualFilterParc: "",
      prestatairesList: [],
    };
  },
  mounted() {
    this.getTypes();
    this.getAgency();
    this.getPeople();
    if (this.compaign.id) {
      this.search = this.compaign.research.search;
      this.$forceUpdate();
    }
    this.getGroupe();
    this.getInfrasAll();
    this.getSubsInfos();
    this.getPrestataires();
    this.getCustomers();
  },
  methods: {
    changeFilterGroup(filterName) {
      this.filterGroup = filterName;
    },
    getSubsInfos() {
      this.$axios.get("/api/subscriptions/compaignInfo").then((resp) => {
        this.typeAbos = resp.data.type;
      });
    },
    async getMax(id) {
      this.loading = true;
      return this.$axios.get("/api/customers/" + id + "/contacts/campaign", { params: { page: 1, limit: 2 } }).then((res) => {
        this.loading = false;
        return res.data.max;
      });
    },
    getInfoCusto(id) {
      this.loading = true;
      this.$axios.get("/api/customers/" + id + "/contacts/campaign", { params: { page: 1, limit: 2 } }).then((resp) => {
        this.contactsInfos[id] = resp.data;
        this.$forceUpdate();
        this.loading = false;
      });
    },
    formatDateWithoutYear(date) {
      if (!date) return null;

      let data = date.split("-");
      return `${data[2]}/${data[1]}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateWithoutYear(date) {
      if (!date) return null;

      let data = date.split("/");
      return `${2000}-${data[1].padStart(2, "0")}-${data[0].padStart(2, "0")}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    continueContact() {
      document.getElementById("contact").scrollIntoView({
        behavior: "smooth",
      });
    },
    remove(name) {
      let index = this.customersName.indexOf(name);
      // if (index !== -1) {
      this.compaign.customers.splice(index, 1);
      this.customersName.splice(index, 1);
      // }
      this.$forceUpdate();
    },
    setItemMax(event) {
      this.limitItem = event;
    },
    setPage(event) {
      this.page = event;
    },
    add(value) {
      let index = this.compaign.customers.indexOf(value.customer.id);

      if (index === -1) {
        this.compaign.customers.push(value.customer.id);
      }
      index = this.customersName.indexOf(value.customer.entreprise.name);

      if (index === -1) {
        this.customersName.push(value.customer.entreprise.name);
      }
      this.$forceUpdate();
    },
    addOrRemove(value) {
      let index = this.compaign.customers.indexOf(value.customer.id);

      if (index === -1) {
        this.compaign.customers.push(value.customer.id);
      } else {
        this.compaign.customers.splice(index, 1);
      }

      index = this.customersName.indexOf(value.customer.entreprise.name);

      if (index === -1) {
        this.customersName.push(value.customer.entreprise.name);
      } else {
        this.customersName.splice(index, 1);
      }
      this.$forceUpdate();
    },
    getAgency() {
      this.$axios("/api/agency").then((resp) => {
        this.agency = resp.data;
      });
    },
    getTypes() {
      this.$axios("/api/types/active").then((resp) => {
        this.types = resp.data.types;
      });
    },
    getAddr() {
      this.loading = true;
      this.address = [];
      let search = this.search;

      if (this.filterConcu) {
        search.filterConcu = this.filterConcu;
      }
      search.filterBU = this.filterBU;
      let allUser = this.allUser;

      let itemCustomRet = { spe: [], default: {} };

      if (this.itemsInfras && Object.keys(this.itemsInfras).length > 0) {
        for (const item of this.itemsInfras.spe) {
          if (item.value) {
            itemCustomRet.spe.push(item.value);
          }
        }
        // let defaultItm = {};

        for (const item of this.itemsInfras.classic) {
          if (item.value) {
            let index = item.origin.replace("item.", "");
            itemCustomRet.default[index] = item.value;
          }
        }
      }

      //let

      this.item.research = {
        page: this.page,
        search,
        limit: this.limitItem,
        asUser: allUser ? [] : [this.$store.state.user.id],
        userMode: "tous",
        typeMode: "tous",
        onlyEmail: this.compaign.phases[1].emailing,
        filterAgency: this.filterAgency,
        filterBU: this.filterBU,
        filterPeople: this.filterPeople,
        client: this.client,
        infraEnd: this.dateBirthdayEnd,
        sectors: this.sector,
        filterSubscriptions: this.searchSubscription,
        subsStart: this.subsDateBirthdayStart,
        subsEnd: this.subsDateBirthdayEnd,
        echeanceStart: this.dateEcheanceStart,
        echeanceEnd: this.dateEcheanceEnd,
        parcType: this.parcType,
        filterParcCustom: itemCustomRet,
        onlyAddress: true,
        searchSubType: this.searchSubType,
        codeSubs: this.searchCodeSub,
        manualFilterParc: this.manualFilterParc,
        searchSubsArt: this.searchSubsArt,
        onlyClient: true,
        onlyProspect: true,
      };

      this.$axios("/api/customers", { params: this.item.research }).then((resp) => {
        this.address = resp.data;
        this.$forceUpdate();
      });
      this.loading = false;
    },
    getCustomers(selectAll = false, removeAll = false, addr = true) {
      this.loading = true;
      this.customersList = [];
      let search = this.search;

      if (this.filterConcu) {
        search.filterConcu = this.filterConcu;
      }

      if (!selectAll && !removeAll) {
        this.customers = [];
      }
      search.filterBU = this.filterBU;
      let allUser = this.allUser;

      let itemCustomRet = { spe: [], default: {} };

      if (this.itemsInfras && Object.keys(this.itemsInfras).length > 0) {
        for (const item of this.itemsInfras.spe) {
          if (item.value) {
            itemCustomRet.spe.push(item.value);
          }
        }

        for (const item of this.itemsInfras.classic) {
          if (item.value) {
            let index = item.origin.replace("item.", "");
            itemCustomRet.default[index] = item.value;
          }
        }
      }

      this.item.research = {
        page: this.page,
        search,
        limit: this.limitItem,
        allUser,
        onlyEmail: this.compaign.phases[1].emailing,
        filterAgency: this.filterAgency,
        filterBU: this.filterBU,
        filterPeople: this.filterPeople,
        client: this.client,
        prospect: this.prospect,
        filterType: this.filterType,
        filterParc: this.filterParc,
        filterParcId: this.filterParcId,
        selectAll,
        clientKey: this.clientKey,
        infraStart: this.dateBirthdayStart,
        infraEnd: this.dateBirthdayEnd,
        sectors: this.sector,
        filterSubscriptions: this.searchSubscription,
        subsStart: this.subsDateBirthdayStart,
        subsEnd: this.subsDateBirthdayEnd,
        echeanceStart: this.dateEcheanceStart,
        echeanceEnd: this.dateEcheanceEnd,
        parcType: this.parcType,
        filterParcCustom: itemCustomRet,
        searchSubType: this.searchSubType,
        codeSubs: this.searchCodeSub,
        manualFilterParc: this.manualFilterParc,
        searchSubsArt: this.searchSubsArt,
      };
      if (this.groupeKey) {
        this.item.research.clientGroups = this.groupeKey.toString();
      }

      this.$axios("/api/customers/campaign/context", { params: { ...this.item.research, context: [this.filterGroup] } }).then((resp) => {
        this.loading = true;
        if (selectAll) {
          if (removeAll) {
            resp.data.forEach((data) => {
              this.remove(data.name);
            });
            this.loading = false;
            return;
          }
          resp.data.forEach((data) => {
            this.add({ customer: data });
          });
          this.loading = false;
          return;
        }
        let arr = [];
        resp.data.data.forEach((item) => {
          this.loading = true;
          this.$axios.get("/api/customers/" + item.customer.id + "/contacts/campaign", { params: { page: 1, limit: 2 } }).then((res) => {
            arr.push({ ...item, ...res.data });

            this.loading = false;
          });
        });

        this.customers = arr;

        this.getSectors();
        if (addr) this.getAddr();
        this.max = resp.data.max;
        this.$forceUpdate();
      });
      this.loading = false;
    },
    getConcu() {
      this.loading = true;
      this.customersList = [];
      let search = this.searchConcu;

      let allUser = this.isGranted("CUSTOMERS_INDEX_ALL");
      this.$axios("/api/concurrents/list", {
        params: { page: this.page, search, limit: this.limitItem, allUser, asUser: this.asUser, onlyCusto: true },
      }).then((resp) => {
        this.concu = resp.data.data;
        this.loading = false;
      });
    },
    getGroupe() {
      this.$axios("/api/groups").then((resp) => {
        this.groupeList = Object.values(resp.data);
      });
    },
    getPrestataires() {
      this.$axios("/api/concurrents/onlyPresta", {
        params: {
          page: 1,
          limit: 1000,
        },
      }).then((resp) => {
        this.prestatairesList = resp.data.data;
      });
    },
    getInfrasAll() {
      this.$axios("/api/types/infra").then((resp) => {
        this.typesInfra = resp.data;
        resp.data.forEach((item) => {
          if (item && item.settings) {
            this.headersInfras[item.id] = [];

            if (item.settings.field1.enable) {
              this.headersInfras[item.id].push({ text: item.settings.field1.name, value: "item.field1", sortable: false });
            }
            if (item.settings.numberfield.enable) {
              this.headersInfras[item.id].push({ text: item.settings.numberfield.name, value: "item.numberfield", sortable: false });
            }
            if (item.settings.field2.enable) {
              this.headersInfras[item.id].push({ text: item.settings.field2.name, value: "item.field2", sortable: false });
            }
            if (item.settings.date.enable) {
              this.headersInfras[item.id].push({ text: item.settings.date.name, value: "item.date", sortable: false });
            }
            if (item.settings.body.enable) {
              this.headersInfras[item.id].push({ text: item.settings.body.name, value: "item.body", sortable: false });
            }
          } else {
            this.headersInfras[item.id] = [
              //   { text: 'id', value: 'item.id', sortable: false },
              { text: "Version", value: "item.field1", sortable: false },
              { text: "Quantité", value: "item.numberfield", sortable: false },
              { text: "N° License / série", value: "item.field2", sortable: false },
              { text: "Date anniversaire", value: "item.date", sortable: false },
              //{ text: 'Prestataire', value: 'concurrent.name', sortable: false },
              { text: "Note", value: "item.body", sortable: false },
            ];
          }
        });
        let tmp = {};
        this.$axios("/api/types/parc").then((res) => {
          res.data.parc.forEach((item) => {
            if (!tmp[item.type.id]) {
              tmp[item.type.id] = [];
            }
            if (this.headersInfras[item.type.id] === undefined) {
              this.headersInfras[item.type.id] = [];
            }
            this.headersInfras[item.type.id].push({ text: item.name, value: item.slug, sortable: false });
          });
        });
      });
    },
    getPeople() {
      this.$axios("/api/people").then((resp) => {
        this.people = resp.data;
      });
    },
    getParcTitle() {
      this.isLoading = true;
      let search = {
        customerName: "",
        brandName: "",
        field1: this.searchTitleParc,
        modelName: "",
        numberfield: "",
        field2: "",
        concurrentName: "",
        body: "",
        sortedDesc: false,
        sortedBy: null,
      };
      let page = 1;
      this.$axios("/api/infrastructures", { params: { page: page, search, limit: 20, asUser: this.asUser } }).then((res) => {
        this.parcItems = res.data.data;
      });
    },
    getSectors() {
      this.$axios("/api/sectors").then((resp) => {
        this.sectors = resp.data;
      });
    },
    getSubs() {
      this.$axios("/api/subscriptions/search", { params: { name: this.searchSubscriptionSync } }).then((resp) => {
        this.subscriptionItems = resp.data;
      });
    },
    getCodeArticle() {
      this.$axios("/api/subscriptions/codeArticle", { params: { search: this.searchCodeSubs } }).then((resp) => {
        this.codeSubs = resp.data;
      });
    },
    getCustomersDebounce() {
      this.simpleDebounce(() => {
        this.getCustomers();
      }, 500);
    },
  },
  watch: {
    searchCodeSubs() {
      this.simpleDebounce(() => {
        this.getCodeArticle();
      }, 500);
    },
    itemsInfras: {
      deep: true,
      handler() {
        this.simpleDebounce(() => {
          this.getCustomers();
        }, 500);
      },
    },
    "search.addressComp"() {
      this.simpleDebounce(() => {
        this.getCustomers(false, false, false);
      }, 500);
    },

    searchSubscriptionSync() {
      this.simpleDebounce(() => {
        this.getSubs();
      }, 500);
    },
    searchTitleParc() {
      this.simpleDebounce(() => {
        this.getParcTitle();
      }, 500);
    },
    dateBirthdayStart() {
      this.dateBirthdayStartFormatted = this.formatDateWithoutYear(this.dateBirthdayStart);
      this.getCustomersDebounce();
    },
    dateBirthdayEnd() {
      this.dateBirthdayEndFormatted = this.formatDateWithoutYear(this.dateBirthdayEnd);
      this.getCustomersDebounce();
    },
    dateEcheanceStart() {
      this.dateEcheanceStartFormatted = this.formatDate(this.dateEcheanceStart);
      this.getCustomersDebounce();
    },
    dateEcheanceEnd() {
      this.dateEcheanceEndFormatted = this.formatDate(this.dateEcheanceEnd);
      this.getCustomersDebounce();
    },
    subsDateBirthdayStart() {
      this.subsDateBirthdayStartFormatted = this.formatDate(this.subsDateBirthdayStart);
      this.getCustomersDebounce();
    },
    subsDateBirthdayEnd() {
      this.subsDateBirthdayEndFormatted = this.formatDate(this.subsDateBirthdayEnd);
      this.getCustomersDebounce();
    },

    secteurSearchSync() {
      this.getSectors();
    },
    value() {
      this.selected = this.value.customers;
      this.item = this.value;
    },
    "compaign.customers"() {
      this.$emit("input", this.compaign);
    },
    "item.company": "getCustomersDebounce",
    searchSubscription: "getCustomersDebounce",
    searchSubType: "getCustomersDebounce",
    groupeKey: "getCustomersDebounce",
    manualFilterParc: "getCustomersDebounce",
    searchCodeSub: "getCustomersDebounce",
    searchSubsArt: "getCustomersDebounce",
    sector: "getCustomersDebounce",
    page: "getCustomersDebounce",
    filterParc: "getCustomersDebounce",
    filterAgency: "getCustomersDebounce",
    filterConcu: "getCustomersDebounce",
    client: "getCustomersDebounce",
    prospect: "getCustomersDebounce",
    filterBU: "getCustomersDebounce",
    filterPeople: "getCustomersDebounce",
    filterGroup: "getCustomersDebounce",
    filterParcId: "getCustomersDebounce",
    clientKey: "getCustomersDebounce",
    "search.name": "getCustomersDebounce",
    "search.cityPostalCode": "getCustomersDebounce",
    "search.ape": "getCustomersDebounce",
    "search.postalCode": "getCustomersDebounce",
    limitItem: "getCustomersDebounce",
    "search.phone": "getCustomersDebounce",
    parcType() {
      if (this.parcType !== null) {
        this.getConcuParc();
        this.$axios("/api/types/parc/" + this.parcType, { params: { order: "name" } }).then((resp) => {
          let infos = resp.data;
          let item = { classic: [], spe: [], id: this.parcType };
          this.headersInfras[this.parcType].sort((a, b) => {
            let keyA = a.text,
              keyB = b.text;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });

          this.headersInfras[this.parcType].forEach((itm) => {
            if (itm.value !== "concurrent.name" && itm.value !== "actions") {
              if (itm.value.includes("item.")) {
                item.classic.push({ label: itm.text, value: "", origin: itm.value });
              } else {
                item.spe.push({ label: itm.text, value: "", items: infos[itm.value] });
              }
            }
          });
          this.itemsInfras = item;
        });
      }

      this.getCustomers();
    },
    "search.concurrentSearch"() {
      this.simpleDebounce(() => this.getConcu(), 500);
    },
    "searchConcu.name"() {
      this.simpleDebounce(() => this.getConcu(), 500);
    },
  },
};
</script>

<style scoped></style>
