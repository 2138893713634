var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "fill-height" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                "align-center": "align-center",
                "justify-center": "justify-center",
              },
            },
            [
              _c(
                "v-flex",
                { staticClass: "login-form text-xs-center" },
                [
                  _c("div", { staticClass: "display-1 mb-3 text-center" }, [
                    _c("img", {
                      staticStyle: { "max-width": "350px" },
                      attrs: { src: "images/actuelburo.svg" },
                    }),
                  ]),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-divider"),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", text: "", color: "primary" },
                            },
                            [_vm._v("Restez connecté ")]
                          ),
                          _c("v-divider"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  false
                    ? _c(
                        "v-card",
                        { staticClass: "mb-5" },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "div",
                                { staticClass: "subheading" },
                                [[_vm._v("Se connecter")]],
                                2
                              ),
                              _vm.alertToggle
                                ? _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        dense: "",
                                        outlined: "",
                                        type: "error",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(this.alertMessage) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-form",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      light: "light",
                                      "prepend-icon": "fa-envelope",
                                      label: "Email",
                                      type: "email",
                                    },
                                    model: {
                                      value: _vm.user.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "email", $$v)
                                      },
                                      expression: "user.email",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      light: "light",
                                      "prepend-icon": "fa-lock",
                                      label: "Password",
                                      type: "password",
                                    },
                                    model: {
                                      value: _vm.user.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "password", $$v)
                                      },
                                      expression: "user.password",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        block: "block",
                                        type: "submit",
                                        color: "primary",
                                      },
                                      on: {
                                        click: [
                                          function ($event) {
                                            $event.preventDefault()
                                          },
                                          _vm.loginAction,
                                        ],
                                      },
                                    },
                                    [_vm._v("Connexion")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "text-center mt-7" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { type: "submit", color: "primary" },
                          on: {
                            click: [
                              function ($event) {
                                $event.preventDefault()
                              },
                              _vm.loginMicrosoft,
                            ],
                          },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-4" }, [
                            _vm._v("fab fa-microsoft"),
                          ]),
                          _vm._v(" Connexion Microsoft o365"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "absolute bottom-10 left-10" },
            [_c("ClearCookie")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }