<template>
  <v-card class="pa-2" @click="linkContactConfirm(item.contact.id)">
    <v-card-text>
      <v-row class="text-left">
        <v-col cols="12" class="py-0"><v-icon class="mr-1" x-small>fa-user</v-icon> {{ item.contact.firstname + " " + item.contact.lastname }}</v-col>
        <v-col cols="12" class="py-0"
          ><v-icon x-smal>fas fa-graduate</v-icon><v-chip label x-small v-for="(custo, keyc) in item.customer" :key="keyc">{{ custo.name }}</v-chip></v-col
        >
        <v-col cols="12" class="py-0"><v-icon class="mr-1" x-small>fa-envelope</v-icon> {{ item.contact.email }}</v-col>
        <v-col cols="12" class="py-0"><v-icon class="mr-1" x-small>fa-phone</v-icon> {{ item.contact.phone }}</v-col>
        <v-col cols="12" class="py-0"><v-icon class="mr-2" x-small>fa-mobile</v-icon> {{ item.contact.phone2 }}</v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SimilarContactCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
    linkContactConfirm: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    //
  }),
};
</script>
