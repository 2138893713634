var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.compaign
    ? _c(
        "material-card",
        {
          staticClass: "mt-8",
          attrs: { title: "Événements", icon: "fa-calendar" },
        },
        [
          _c(
            "modal",
            {
              attrs: { "max-width": "1024" },
              model: {
                value: _vm.linkOppModal,
                callback: function ($$v) {
                  _vm.linkOppModal = $$v
                },
                expression: "linkOppModal",
              },
            },
            [
              _vm.newEvent
                ? _c("opportunitiesCustomers", {
                    attrs: {
                      "customer-id": _vm.newEvent.customerId,
                      link: true,
                    },
                    on: {
                      "update:customerId": function ($event) {
                        return _vm.$set(_vm.newEvent, "customerId", $event)
                      },
                      "update:customer-id": function ($event) {
                        return _vm.$set(_vm.newEvent, "customerId", $event)
                      },
                      linkOpp: _vm.linkOppFct,
                    },
                    model: {
                      value: _vm.linkOppModal,
                      callback: function ($$v) {
                        _vm.linkOppModal = $$v
                      },
                      expression: "linkOppModal",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "modal",
            {
              attrs: { "max-width": "1920" },
              model: {
                value: _vm.linkModal,
                callback: function ($$v) {
                  _vm.linkModal = $$v
                },
                expression: "linkModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "text-left" },
                [
                  _c("Inbox", {
                    on: {
                      close: function ($event) {
                        _vm.getEvents()
                        _vm.linkModal = false
                      },
                    },
                    model: {
                      value: _vm.typeLink,
                      callback: function ($$v) {
                        _vm.typeLink = $$v
                      },
                      expression: "typeLink",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "modal",
            {
              attrs: { "max-width": "1080" },
              scopedSlots: _vm._u(
                [
                  _vm.newEvent
                    ? {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "strong",
                              { staticClass: "text-h5 mb-0 text-uppercase" },
                              [_vm._v("Evènement")]
                            ),
                            _c("br"),
                            _c("small", { staticClass: "mt-0" }, [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .dayjs(_vm.newEvent.date)
                                    .format("DD/MM/YYYY")
                                ) +
                                  " " +
                                  _vm._s(_vm.newEvent.time) +
                                  " - " +
                                  _vm._s(_vm.newEvent.name)
                              ),
                            ]),
                            _vm.newEvent.id
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "modal-menu--actions" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-left",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      elevation:
                                                                        "0",
                                                                      plain: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$router.push(
                                                                            {
                                                                              name: "Client",
                                                                              params:
                                                                                {
                                                                                  id: _vm
                                                                                    .newEvent
                                                                                    .customerId,
                                                                                },
                                                                            }
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fa-search"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1168081894
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Fiche client"),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    attrs: {
                                                                      small: "",
                                                                      elevation:
                                                                        "0",
                                                                      plain: "",
                                                                      color:
                                                                        "none",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.typeLink.id =
                                                                            _vm.newEvent.id
                                                                          _vm.linkModal = true
                                                                        },
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fa-envelope"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3183207128
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Lier un e-mail"),
                                                  ]),
                                                ]
                                              ),
                                              _vm.newEvent.opportunityId
                                                ? [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "mx-1",
                                                                          attrs:
                                                                            {
                                                                              elevation:
                                                                                "0",
                                                                              small:
                                                                                "",
                                                                              plain:
                                                                                "",
                                                                              color:
                                                                                "warning",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$router.push(
                                                                                  {
                                                                                    name: "Opportunité",
                                                                                    params:
                                                                                      {
                                                                                        id: _vm
                                                                                          .newEvent
                                                                                          .opportunityId,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "v-btn",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "fa-handshake"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          303942909
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Opportunité " +
                                                              _vm._s(
                                                                _vm.newEvent
                                                                  .opportunityId
                                                              )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "mx-1",
                                                                          attrs:
                                                                            {
                                                                              elevation:
                                                                                "0",
                                                                              small:
                                                                                "",
                                                                              plain:
                                                                                "",
                                                                              color:
                                                                                "warning",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.newOpp(
                                                                                  _vm.newEvent
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "v-btn",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "fa-plus"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          3799230991
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Créer opportunité"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              plain:
                                                                                "",
                                                                              elevation:
                                                                                "0",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.linkOppModal = true
                                                                              },
                                                                          },
                                                                        },
                                                                        "v-btn",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "fa-link"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          4288396378
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Associer opportunité"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              variant: "flat",
                              color: "primary",
                              elevation: "0",
                              loading: _vm.loadingSave,
                            },
                            on: { click: _vm.editEventFct },
                          },
                          [_vm._v(" Enregistrer ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: { color: "secondary", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.editEvent = false
                              },
                            },
                          },
                          [_vm._v(" Fermer ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.editEvent,
                callback: function ($$v) {
                  _vm.editEvent = $$v
                },
                expression: "editEvent",
              },
            },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c("commentFormOpportunities", {
                            model: {
                              value: _vm.newEvent,
                              callback: function ($$v) {
                                _vm.newEvent = $$v
                              },
                              expression: "newEvent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "modal",
            {
              attrs: { "max-width": "1280" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm._v(" Confirmation des présences "),
                            ]),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-right",
                                attrs: { cols: "6" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-4",
                                    attrs: { color: "primary" },
                                    on: { click: _vm.savePresence },
                                  },
                                  [_vm._v(" Enregistrer ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1038052137
              ),
              model: {
                value: _vm.confirmPresence,
                callback: function ($$v) {
                  _vm.confirmPresence = $$v
                },
                expression: "confirmPresence",
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  [
                    _c(
                      "v-expansion-panels",
                      { attrs: { focusable: "" } },
                      _vm._l(
                        Object.keys(_vm.customers),
                        function (customer, i) {
                          return _c(
                            "v-expansion-panel",
                            { key: i },
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  attrs: { "expand-icon": "fas fa-angle-down" },
                                },
                                [
                                  _vm._v(" " + _vm._s(customer) + " "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "ml-1",
                                      staticStyle: {
                                        position: "absolute",
                                        right: "50px",
                                      },
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: { value: customer, inset: "" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.checkContain(customer)
                                          },
                                        },
                                        model: {
                                          value: _vm.presenceKeys,
                                          callback: function ($$v) {
                                            _vm.presenceKeys = $$v
                                          },
                                          expression: "presenceKeys",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c(
                                    "v-row",
                                    _vm._l(
                                      _vm.customers[customer],
                                      function (contact, key) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: key,
                                            staticClass: "py-1",
                                            attrs: { cols: "4" },
                                          },
                                          [
                                            _c("v-switch", {
                                              attrs: {
                                                value: contact.contact.id,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        contact.favorite
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  color:
                                                                    "yellow darken-1",
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-star"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              contact.contact
                                                                .firstname
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              contact.contact
                                                                .lastname
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.presence[customer],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.presence,
                                                    customer,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "presence[customer]",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "modal",
            {
              attrs: { "max-width": "1280" },
              model: {
                value: _vm.openModalMail,
                callback: function ($$v) {
                  _vm.openModalMail = $$v
                },
                expression: "openModalMail",
              },
            },
            [
              _vm.openModalMail
                ? _vm._l(
                    _vm.events[this.tab].email[_vm.mailSelected],
                    function (email, key) {
                      return _c(
                        "v-card",
                        {
                          key: key,
                          staticClass: "my-2",
                          attrs: { elevation: "2" },
                        },
                        [
                          _c("v-card-title", [_vm._v(_vm._s(email.name))]),
                          _c("v-card-subtitle", [
                            _vm._v(
                              _vm._s(email.sender) +
                                " - " +
                                _vm._s(email.senderEmail)
                            ),
                          ]),
                          _c("v-card-text", {
                            domProps: { innerHTML: _vm._s(email.body) },
                          }),
                        ],
                        1
                      )
                    }
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "v-card",
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    "fixed-tabs": "",
                    "background-color": "primary",
                    "slider-color": "white",
                  },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        disabled:
                          !_vm.compaign.defaultSettings.phases[1].enable,
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-0 ma-0" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("fa-envelope")]
                              ),
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "9" } }, [
                            _vm._v(" Mailing "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        disabled:
                          !_vm.compaign.defaultSettings.phases[2].enable,
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-0 ma-0" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("fa-reply")]
                              ),
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "9" } }, [
                            _vm._v(" Phoning "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        disabled:
                          !_vm.compaign.defaultSettings.phases[3].enable,
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-0 ma-0" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("fa-users")]
                              ),
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "9" } }, [
                            _vm._v(" Rencontre "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        disabled:
                          !_vm.compaign.defaultSettings.phases[4].enable,
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-0 ma-0" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("fa-reply-all")]
                              ),
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "9" } }, [
                            _vm._v(" Génération business "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              staticClass: "transparent",
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tab-item", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "overflow-y": "auto",
                      "max-height": "calc((100vh / 2) - 75px)",
                    },
                    attrs: { id: "infinite-list-0" },
                  },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-timeline",
                          { attrs: { "align-top": "", dense: "", large: "" } },
                          _vm._l(_vm.events[0].events, function (item, i) {
                            return _c(
                              "v-timeline-item",
                              {
                                key: i,
                                staticClass: "my-0 mb-4 pl-0",
                                attrs: {
                                  small: "",
                                  color:
                                    _vm.events[0].type[item.eventtypeId] &&
                                    _vm.events[0].type[item.eventtypeId].color
                                      ? _vm.events[0].type[item.eventtypeId]
                                          .color
                                      : "",
                                  right: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "icon",
                                      fn: function () {
                                        return [
                                          _vm.events[0].users[item.id].length >
                                          3
                                            ? [
                                                _c(
                                                  "v-avatar",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      size: "40",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "white--text text-h5",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.events[0].users[
                                                              item.id
                                                            ].length
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._l(
                                                _vm.events[0].users[item.id],
                                                function (user, key) {
                                                  return _c("UserAvatar", {
                                                    key: key,
                                                    attrs: {
                                                      userId: user.userId,
                                                      size: "40",
                                                    },
                                                  })
                                                }
                                              ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "white",
                                              staticStyle: {
                                                position: "absolute",
                                                top: "35px",
                                              },
                                              attrs: {
                                                small: "",
                                                color: _vm.returnIcon(
                                                  item.status
                                                ).color,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.returnIcon(item.status)
                                                    .icon
                                                ) + " fa-fw "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "white",
                                              staticStyle: {
                                                position: "absolute",
                                                top: "55px",
                                                "text-align": "center",
                                                "font-size": "0.8rem",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .dayjs(item.start)
                                                      .format("DD/MM/YYYY")
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .dayjs(item.start)
                                                    .format("HH:mm")
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                [
                                  _c(
                                    "v-card",
                                    {
                                      class: {
                                        eventstandby: item.status == 0,
                                        eventsuccess: item.status == 1,
                                        eventcancel: item.status == 2,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "ma-0 pt-0" },
                                        [
                                          _c(
                                            "strong",
                                            {
                                              staticStyle: {
                                                "font-size": "0.9rem",
                                              },
                                            },
                                            [
                                              item.private
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-3",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("fa-lock")]
                                                  )
                                                : _vm._e(),
                                              _vm.events
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        "x-small": "",
                                                        color:
                                                          _vm.events[0].type[
                                                            item.eventtypeId
                                                          ].color,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.events[0].type[
                                                              item.eventtypeId
                                                            ].icon
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.events[0].type[
                                                      item.eventtypeId
                                                    ].name
                                                  ) +
                                                  " - " +
                                                  _vm._s(item.name) +
                                                  " "
                                              ),
                                              item.opportunity
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ma-0 p-0",
                                                      attrs: {
                                                        outlined: "",
                                                        "x-small": "",
                                                        elevation: "0",
                                                        color:
                                                          item.opportunity
                                                            .statusId !== null
                                                            ? ""
                                                            : "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openOpportunity(
                                                            item.opportunity.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.opportunity.id
                                                        ) + " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          item.report && item.report.length > 0
                                            ? [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                    },
                                                                    "span",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "- Note interne disponible"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(item.report)
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                          _vm.events[0].email[item.id].length >
                                          0
                                            ? [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.mailSelected =
                                                                              item.id
                                                                            _vm.openModalMail = true
                                                                          },
                                                                      },
                                                                    },
                                                                    "span",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fa-envelope"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Voir les e-mails liés"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                position: "absolute",
                                                top: "-15px",
                                                right: "5px",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: { "offset-y": "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      dark: "",
                                                                      "x-small":
                                                                        "",
                                                                      fab: "",
                                                                      elevation:
                                                                        "0",
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " fa-ellipsis-v "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "v-list",
                                                    [
                                                      item.status === 0
                                                        ? _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeStatus(
                                                                      item,
                                                                      1
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mx-1",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fa-check"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " Valider "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openEvents(
                                                                item,
                                                                0
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [_vm._v("fa-pen")]
                                                          ),
                                                          _vm._v(" Editer "),
                                                        ],
                                                        1
                                                      ),
                                                      item.status === 0
                                                        ? _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeStatus(
                                                                      item,
                                                                      2
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mx-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fa-trash"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " Annuler "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "text-caption" },
                                            [
                                              item.teams
                                                ? _c("div", [
                                                    item.status !== 2
                                                      ? _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: item.teamsLink,
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                "max-width":
                                                                  "15px",
                                                              },
                                                              attrs: {
                                                                src: "/images/microsoft-teams.svg",
                                                              },
                                                            }),
                                                            _vm._v(" - TEAMS "),
                                                          ]
                                                        )
                                                      : _c("span", [
                                                          _c("img", {
                                                            staticStyle: {
                                                              "max-width":
                                                                "15px",
                                                            },
                                                            attrs: {
                                                              src: "/images/microsoft-teams.svg",
                                                            },
                                                          }),
                                                          _vm._v(" - TEAMS "),
                                                        ]),
                                                  ])
                                                : _vm._e(),
                                              item.body &&
                                              item.body.length > 250
                                                ? _c("read-more", {
                                                    attrs: {
                                                      "more-str": "Lire plus",
                                                      text: item.body,
                                                      "less-str": "Lire moins",
                                                      "max-chars": 250,
                                                    },
                                                  })
                                                : _c("p", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.body
                                                          ? item.body.replaceAll(
                                                              "\n",
                                                              "<br/>"
                                                            )
                                                          : "Pas de commentaire"
                                                      ),
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                              ],
                              2
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { disabled: _vm.page[0] === 1, small: "" },
                        on: {
                          click: function ($event) {
                            _vm.page[0] -= 1
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("fa-angle-left"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-1",
                        attrs: {
                          disabled: _vm.page[0] * 10 >= _vm.max[0],
                          small: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.page[0] += 1
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("fa-angle-right"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-tab-item", [
                _c(
                  "div",
                  {
                    staticStyle: { "overflow-y": "scroll" },
                    attrs: { id: "infinite-list-1" },
                  },
                  [
                    _c(
                      "v-timeline",
                      { attrs: { "align-top": "", dense: "", large: "" } },
                      _vm._l(_vm.events[1].events, function (item, i) {
                        return _c(
                          "v-timeline-item",
                          {
                            key: i,
                            staticClass: "my-0 mb-4 pl-0",
                            attrs: {
                              small: "",
                              color:
                                _vm.events[1].type[item.eventtypeId] &&
                                _vm.events[1].type[item.eventtypeId].color
                                  ? _vm.events[1].type[item.eventtypeId].color
                                  : "",
                              right: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "icon",
                                  fn: function () {
                                    return [
                                      _vm.events[1].users[item.id].length > 3
                                        ? [
                                            _c(
                                              "v-avatar",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  size: "40",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text text-h5",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.events[1].users[
                                                          item.id
                                                        ].length
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._l(
                                            _vm.events[1].users[item.id],
                                            function (user, key) {
                                              return _c("UserAvatar", {
                                                key: key,
                                                attrs: {
                                                  userId: user.userId,
                                                  size: "40",
                                                },
                                              })
                                            }
                                          ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "white",
                                          staticStyle: {
                                            position: "absolute",
                                            top: "35px",
                                          },
                                          attrs: {
                                            small: "",
                                            color: _vm.returnIcon(item.status)
                                              .color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.returnIcon(item.status).icon
                                            ) + " fa-fw "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "white",
                                          staticStyle: {
                                            position: "absolute",
                                            top: "55px",
                                            "text-align": "center",
                                            "font-size": "0.8rem",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .dayjs(item.start)
                                                  .format("DD/MM/YYYY")
                                              )
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .dayjs(item.start)
                                                .format("HH:mm")
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            [
                              _c(
                                "v-card",
                                {
                                  class: {
                                    eventstandby: item.status == 0,
                                    eventsuccess: item.status == 1,
                                    eventcancel: item.status == 2,
                                  },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "ma-0 pt-0" },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: {
                                            "font-size": "0.9rem",
                                          },
                                        },
                                        [
                                          item.private
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("fa-lock")]
                                              )
                                            : _vm._e(),
                                          _vm.events
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    "x-small": "",
                                                    color:
                                                      _vm.events[1].type[
                                                        item.eventtypeId
                                                      ].color,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.events[1].type[
                                                          item.eventtypeId
                                                        ].icon
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.events[1].type[
                                                  item.eventtypeId
                                                ].name
                                              ) +
                                              " - " +
                                              _vm._s(item.name) +
                                              " "
                                          ),
                                          item.opportunity
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ma-0 p-0",
                                                  attrs: {
                                                    outlined: "",
                                                    "x-small": "",
                                                    elevation: "0",
                                                    color:
                                                      item.opportunity
                                                        .statusId !== null
                                                        ? ""
                                                        : "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openOpportunity(
                                                        item.opportunity.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.opportunity.id
                                                    ) + " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      item.report && item.report.length > 0
                                        ? [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                },
                                                                "span",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "- Note interne disponible"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.report)),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _vm.events[1].email[item.id].length > 0
                                        ? [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  staticStyle: {
                                                                    cursor:
                                                                      "pointer",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.mailSelected =
                                                                          item.id
                                                                        _vm.openModalMail = true
                                                                      },
                                                                  },
                                                                },
                                                                "span",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fa-envelope"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Voir les e-mails liés"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "-15px",
                                            right: "5px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  dark: "",
                                                                  "x-small": "",
                                                                  fab: "",
                                                                  elevation:
                                                                    "0",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " fa-ellipsis-v "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  item.status === 0
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeStatus(
                                                                item,
                                                                1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mx-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fa-check"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " Valider "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.status === 0
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openEvents(
                                                                item,
                                                                1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [_vm._v("fa-pen")]
                                                          ),
                                                          _vm._v(" Editer "),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "v-list-item",
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {},
                                                                                "div",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        "",
                                                                                      small:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-2",
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "fa-fw fa-edit"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " Editer "
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Un événement validé ou annulé ne peut être modifié."
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                  item.status === 0
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeStatus(
                                                                item,
                                                                2
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [_vm._v("fa-trash")]
                                                          ),
                                                          _vm._v(" Annuler "),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-caption" },
                                        [
                                          item.teams
                                            ? _c("div", [
                                                item.status !== 2
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: item.teamsLink,
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            "max-width": "15px",
                                                          },
                                                          attrs: {
                                                            src: "/images/microsoft-teams.svg",
                                                          },
                                                        }),
                                                        _vm._v(" - TEAMS "),
                                                      ]
                                                    )
                                                  : _c("span", [
                                                      _c("img", {
                                                        staticStyle: {
                                                          "max-width": "15px",
                                                        },
                                                        attrs: {
                                                          src: "/images/microsoft-teams.svg",
                                                        },
                                                      }),
                                                      _vm._v(" - TEAMS "),
                                                    ]),
                                              ])
                                            : _vm._e(),
                                          item.body && item.body.length > 250
                                            ? _c("read-more", {
                                                attrs: {
                                                  "more-str": "Lire plus",
                                                  text: item.body,
                                                  "less-str": "Lire moins",
                                                  "max-chars": 250,
                                                },
                                              })
                                            : _c("p", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.body
                                                      ? item.body.replaceAll(
                                                          "\n",
                                                          "<br/>"
                                                        )
                                                      : "Pas de commentaire"
                                                  ),
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                          ],
                          2
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { disabled: _vm.page[1] === 1, small: "" },
                        on: {
                          click: function ($event) {
                            _vm.page[1] -= 1
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("fa-angle-left"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-1",
                        attrs: {
                          disabled: _vm.page[1] * 10 >= _vm.max[1],
                          small: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.page[1] += 1
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("fa-angle-right"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "v-tab-item",
                [
                  !_vm.compaign.commando &&
                  _vm.compaign.phase4date &&
                  Date.now() >= _vm.compaign.phase4date
                    ? _c(
                        "div",
                        { staticClass: "text-center mt-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.presence =
                                    _vm.compaign.phase3Presence.presence &&
                                    typeof _vm.compaign.phase3Presence
                                      .presence === "object"
                                      ? _vm.compaign.phase3Presence.presence
                                      : {}
                                  _vm.presenceKeys = _vm.compaign.phase3Presence
                                    .presenceKeys
                                    ? _vm.compaign.phase3Presence.presenceKeys
                                    : []
                                  _vm.confirmPresence = true
                                },
                              },
                            },
                            [_vm._v("Confirmer les présences")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-timeline",
                    { attrs: { "align-top": "", dense: "", large: "" } },
                    _vm._l(_vm.events[2].events, function (item, i) {
                      return _c(
                        "v-timeline-item",
                        {
                          key: i,
                          staticClass: "my-0 mb-4 pl-0",
                          attrs: {
                            small: "",
                            color:
                              _vm.events[2].type[item.eventtypeId] &&
                              _vm.events[2].type[item.eventtypeId].color
                                ? _vm.events[2].type[item.eventtypeId].color
                                : "",
                            right: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _vm.events[2].users[item.id].length > 3
                                      ? [
                                          _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                color: "primary",
                                                size: "40",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "white--text text-h5",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.events[2].users[
                                                        item.id
                                                      ].length
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._l(
                                          _vm.events[2].users[item.id],
                                          function (user, key) {
                                            return _c("UserAvatar", {
                                              key: key,
                                              attrs: {
                                                userId: user.userId,
                                                size: "40",
                                              },
                                            })
                                          }
                                        ),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "white",
                                        staticStyle: {
                                          position: "absolute",
                                          top: "35px",
                                        },
                                        attrs: {
                                          small: "",
                                          color: _vm.returnIcon(item.status)
                                            .color,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.returnIcon(item.status).icon
                                          ) + " fa-fw "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "white",
                                        staticStyle: {
                                          position: "absolute",
                                          top: "55px",
                                          "text-align": "center",
                                          "font-size": "0.8rem",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm
                                                .dayjs(item.start)
                                                .format("DD/MM/YYYY")
                                            )
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .dayjs(item.start)
                                              .format("HH:mm")
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          [
                            _c(
                              "v-card",
                              {
                                class: {
                                  eventstandby: item.status == 0,
                                  eventsuccess: item.status == 1,
                                  eventcancel: item.status == 2,
                                },
                              },
                              [
                                _c(
                                  "v-card-title",
                                  { staticClass: "ma-0 pt-0" },
                                  [
                                    _c(
                                      "strong",
                                      {
                                        staticStyle: { "font-size": "0.9rem" },
                                      },
                                      [
                                        item.private
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-3",
                                                attrs: { small: "" },
                                              },
                                              [_vm._v("fa-lock")]
                                            )
                                          : _vm._e(),
                                        _vm.events
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  "x-small": "",
                                                  color:
                                                    _vm.events[2].type[
                                                      item.eventtypeId
                                                    ].color,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.events[2].type[
                                                        item.eventtypeId
                                                      ].icon
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.events[2].type[
                                                item.eventtypeId
                                              ].name
                                            ) +
                                            " - " +
                                            _vm._s(item.name) +
                                            " "
                                        ),
                                        item.opportunity
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-0 p-0",
                                                attrs: {
                                                  outlined: "",
                                                  "x-small": "",
                                                  elevation: "0",
                                                  color:
                                                    item.opportunity
                                                      .statusId !== null
                                                      ? ""
                                                      : "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openOpportunity(
                                                      item.opportunity.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.opportunity.id) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-card-text",
                                  [
                                    item.report && item.report.length > 0
                                      ? [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                              },
                                                              "span",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "- Note interne disponible"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.report)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm.events[2].email[item.id].length > 0
                                      ? [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.mailSelected =
                                                                        item.id
                                                                      _vm.openModalMail = true
                                                                    },
                                                                },
                                                              },
                                                              "span",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-envelope"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Voir les e-mails liés"),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "-15px",
                                          right: "5px",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: { "offset-y": "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                dark: "",
                                                                "x-small": "",
                                                                fab: "",
                                                                elevation: "0",
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                "x-small": "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " fa-ellipsis-v "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              [
                                                item.status === 0
                                                  ? _c(
                                                      "v-list-item",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeStatus(
                                                              item,
                                                              1
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-check"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" Valider "),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openEvents(
                                                          item,
                                                          2
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mx-1",
                                                        attrs: { small: "" },
                                                      },
                                                      [_vm._v("fa-pen")]
                                                    ),
                                                    _vm._v(" Editer "),
                                                  ],
                                                  1
                                                ),
                                                item.status === 0
                                                  ? _c(
                                                      "v-list-item",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeStatus(
                                                              item,
                                                              2
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mx-1",
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [_vm._v("fa-trash")]
                                                        ),
                                                        _vm._v(" Annuler "),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "text-caption" },
                                      [
                                        item.teams
                                          ? _c("div", [
                                              item.status !== 2
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: item.teamsLink,
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          "max-width": "15px",
                                                        },
                                                        attrs: {
                                                          src: "/images/microsoft-teams.svg",
                                                        },
                                                      }),
                                                      _vm._v(" - TEAMS "),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _c("img", {
                                                      staticStyle: {
                                                        "max-width": "15px",
                                                      },
                                                      attrs: {
                                                        src: "/images/microsoft-teams.svg",
                                                      },
                                                    }),
                                                    _vm._v(" - TEAMS "),
                                                  ]),
                                            ])
                                          : _vm._e(),
                                        item.body && item.body.length > 250
                                          ? _c("read-more", {
                                              attrs: {
                                                "more-str": "Lire plus",
                                                text: item.body,
                                                "less-str": "Lire moins",
                                                "max-chars": 250,
                                              },
                                            })
                                          : _c("p", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item.body
                                                    ? item.body.replaceAll(
                                                        "\n",
                                                        "<br/>"
                                                      )
                                                    : "Pas de commentaire"
                                                ),
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.page[2] === 1, small: "" },
                          on: {
                            click: function ($event) {
                              _vm.page[2] -= 1
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa-angle-left"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-1",
                          attrs: {
                            disabled: _vm.page[2] * 10 >= _vm.max[2],
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.page[2] += 1
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa-angle-right"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-timeline",
                    { attrs: { "align-top": "", large: "", dense: "" } },
                    _vm._l(_vm.events[3].events, function (item, i) {
                      return _c(
                        "v-timeline-item",
                        {
                          key: i,
                          staticClass: "my-0 mb-4 pl-0",
                          attrs: {
                            small: "",
                            color:
                              _vm.events[3].type[item.eventtypeId] &&
                              _vm.events[3].type[item.eventtypeId].color
                                ? _vm.events[3].type[item.eventtypeId].color
                                : "",
                            right: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _vm.events[3].users[item.id].length > 3
                                      ? [
                                          _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                color: "primary",
                                                size: "40",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "white--text text-h5",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.events[3].users[
                                                        item.id
                                                      ].length
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._l(
                                          _vm.events[3].users[item.id],
                                          function (user, key) {
                                            return _c("UserAvatar", {
                                              key: key,
                                              attrs: {
                                                userId: user.userId,
                                                size: "40",
                                              },
                                            })
                                          }
                                        ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pt-1 mx-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { "white-space": "nowrap" },
                                  attrs: { cols: "2" },
                                },
                                [
                                  _c("strong", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          .dayjs(item.start)
                                          .format("DD-MM-YYYY")
                                      ) + " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dayjs(item.start).format("HH:mm")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "2" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: _vm.returnIcon(
                                                  item.status
                                                ).color,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.returnIcon(item.status)
                                                    .icon
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center",
                                          attrs: { cols: "12", md: "9" },
                                        },
                                        [
                                          item.opportunity
                                            ? _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    small: "",
                                                    color:
                                                      item.opportunity
                                                        .statusId !== null
                                                        ? ""
                                                        : "success",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openOpportunity(
                                                        item.opportunity.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.opportunity.id)
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "my-0 py-1" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _c(
                                          "strong",
                                          [
                                            _vm.events
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-3",
                                                    attrs: {
                                                      "x-small": "",
                                                      color:
                                                        _vm.events[3].type[
                                                          item.eventtypeId
                                                        ].color,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.events[3].type[
                                                            item.eventtypeId
                                                          ].icon
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.events[3].type[
                                                    item.eventtypeId
                                                  ].name
                                                ) +
                                                " - " +
                                                _vm._s(item.name)
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "4" },
                                        },
                                        [
                                          item.report && item.report.length > 0
                                            ? [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                    },
                                                                    "span",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "- Note interne disponible"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(item.report)
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                          _vm.events[3].email[item.id].length >
                                          0
                                            ? [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.mailSelected =
                                                                              item.id
                                                                            _vm.openModalMail = true
                                                                          },
                                                                      },
                                                                    },
                                                                    "span",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fa-envelope"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Voir les e-mails liés"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  dark: "",
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " fa-ellipsis-h "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  item.status === 0
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeStatus(
                                                                item,
                                                                1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mx-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fa-check"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " Valider"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openEvents(
                                                            item,
                                                            3
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mx-1",
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("fa-pen")]
                                                      ),
                                                      _vm._v(" Editer "),
                                                    ],
                                                    1
                                                  ),
                                                  item.status === 0
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeStatus(
                                                                item,
                                                                2
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [_vm._v("fa-trash")]
                                                          ),
                                                          _vm._v(" Annuler "),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  !item.opportunity
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.newOpp(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [_vm._v("fa-plus")]
                                                          ),
                                                          _vm._v(
                                                            " Opportunité "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "text-caption" },
                                    [
                                      item.teams
                                        ? _c("div", [
                                            item.status !== 2
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: item.teamsLink,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        "max-width": "15px",
                                                      },
                                                      attrs: {
                                                        src: "images/microsoft-teams.svg",
                                                      },
                                                    }),
                                                    _vm._v(" - TEAMS "),
                                                  ]
                                                )
                                              : _c("span", [
                                                  _c("img", {
                                                    staticStyle: {
                                                      "max-width": "15px",
                                                    },
                                                    attrs: {
                                                      src: "images/microsoft-teams.svg",
                                                    },
                                                  }),
                                                  _vm._v(" - TEAMS "),
                                                ]),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-small": "" } },
                                            [_vm._v("fa-user-circle")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.events[3].customers[item.id]
                                                  .name
                                              )
                                          ),
                                        ],
                                        1
                                      ),
                                      item.body && item.body.length > 250
                                        ? _c("read-more", {
                                            attrs: {
                                              "more-str": "Lire plus",
                                              text: item.body.replaceAll(
                                                "\n",
                                                "<br/>"
                                              ),
                                              link: "#",
                                              "less-str": "Lire moins",
                                              "max-chars": 250,
                                            },
                                          })
                                        : _c("p", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                item.body
                                                  ? item.body.replaceAll(
                                                      "\n",
                                                      "<br/>"
                                                    )
                                                  : "Pas de commentaire"
                                              ),
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.page[3] === 1, small: "" },
                          on: {
                            click: function ($event) {
                              _vm.page[3] -= 1
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa-angle-left"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-1",
                          attrs: {
                            disabled: _vm.page[3] * 10 >= _vm.max[3],
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.page[3] += 1
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa-angle-right"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }