<template>
  <material-card :header="false">
    <v-container style="margin-top: -40px">
      <v-row align="center" class="mb-3 mt-0 pt-0">
        <v-col class="text-right">
          <v-btn icon class="mr-2" @click="$emit('prevChall')"> <v-icon>fa-chevron-left fa-fw</v-icon></v-btn>
        </v-col>
        <v-col class="text-center">
          <h2 class="mb-0 text-uppercase primary--text">{{ challenge.challenge.name }}</h2>
          <span class="text-overline font-italic grey--text text--darken-1"
            >Période du {{ dayjs(challenge.challenge.startDate).format("DD/MM/YYYY") }} au {{ dayjs(challenge.challenge.endDate).format("DD/MM/YYYY") }}</span
          >
        </v-col>
        <v-col class="text-left">
          <v-btn icon class="ml-2" @click="$emit('nextChall')">
            <v-icon>fa-chevron-right fa-fw</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-row v-if="challenge && challenge.global && challenge.global.length > 3">
      <v-col cols="4">
        <v-row>
          <v-col cols="4" class="text-right">
            <img src="/images/challenge/helmet--gold.svg" width="48" />
          </v-col>
          <v-col cols="8" class="pt-0">
            <div>
              <span class="text-overline" style="font-size: 1rem !important; color: #ffa900">{{ challenge.global[0].name }}</span>
            </div>
            <div style="font-size: 0.8em">
              <div v-if="challenge.global[0].user === $store.state.user.id || isGranted('CHALLENGES_VIEW_AMOUNT')">
                Marge : <strong>{{ Math.round(challenge.global[0].gain).toLocaleString("fr") }} €</strong>
              </div>
              <div>
                Points : <strong>+{{ Math.round(challenge.global[0].points).toLocaleString("fr") }}</strong>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row>
          <v-col cols="4" class="text-right">
            <img src="/images/challenge/helmet--silver.svg" width="48" />
          </v-col>
          <v-col cols="8" class="pt-0">
            <div>
              <span class="text-overline" style="font-size: 1rem !important; color: #c0c0c0">{{ challenge.global[1].name }}</span>
            </div>
            <div style="font-size: 0.8em">
              <div v-if="challenge.global[1].user === $store.state.user.id || isGranted('CHALLENGES_VIEW_AMOUNT')">
                Marge : <strong>{{ Math.round(challenge.global[1].gain).toLocaleString("fr") }} €</strong>
              </div>
              <div>
                Points : <strong>+{{ Math.round(challenge.global[1].points).toLocaleString("fr") }}</strong>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row>
          <v-col cols="4" class="text-right">
            <img src="/images/challenge/helmet--bronze.svg" width="48" />
          </v-col>
          <v-col cols="8" class="pt-0 small">
            <div>
              <span class="text-overline" style="font-size: 1rem !important; color: #c49c48">{{ challenge.global[2].name }}</span>
            </div>
            <div style="font-size: 0.8em">
              <div v-if="challenge.global[2].user === $store.state.user.id || isGranted('CHALLENGES_VIEW_AMOUNT')">
                Marge : <strong>{{ Math.round(challenge.global[2].gain).toLocaleString("fr") }} €</strong>
              </div>
              <div>
                Points : <strong>+{{ Math.round(challenge.global[2].points).toLocaleString("fr") }}</strong>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
import Tools from "../../mixins/Tools";

export default {
  name: "titleChallenge",
  components: { MaterialCard },
  props: ["value"],
  mixins: [Tools],
  data() {
    return {
      challenge: this.value,
    };
  },
  watch: {
    value() {
      this.challenge = this.value;
    },
    challenge() {
      this.$emit("input", this.challenge);
    },
  },
};
</script>

<style scoped></style>
