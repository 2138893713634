<template>
  <v-container fluid>
    <v-card style="z-index: 99" elevation="1" v-if="isGranted('CUSTOMERS_INDEX')">
      <v-toolbar color="primary" dark elevation="0">
        <div class="listbox--actions-add">
          <v-btn
            small
            fab
            color="primary"
            dark
            @click="
              newClient = {
                name: '',
                ape: '',
                ctNumAb: '',
                siret: '',
                adresse: '',
                adresse2: '',
                postalCode: '',
                city: '',
                isIndirect: '',
                peopleId: null,
                email: '',
                phone: '',
                phone2: '',
                site: '',
              };
              modalAdd = true;
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-plus </v-icon>
              </template>
              <span>Ajouter</span>
            </v-tooltip>
          </v-btn>
        </div>
        <v-card-title>
          <div class="d-flex align-center">
            <div>
              <h3 style="min-width: 95px" class="mb-2">Clients</h3>
            </div>
            <div>
              <RapidSearchCustomers @selectedRapidSearch="rapidSearch" />
            </div>
          </div>

          <div
            :style="
              isMobile()
                ? {
                    marginLeft: '20px',
                  }
                : {
                    position: 'absolute',
                    top: '0',
                    left: '50%',
                    background: '#78909c',
                    padding: '5px',
                    transform: 'translate(-50%)',
                    borderRadius: '0 0 5px 5px',
                  }
            "
          >
            <v-btn text @click="modalSettings = true" small>
              <v-tooltip bottom v-if="isGranted('CUSTOMERS_INDEX_ALL') || isGranted('AGENCY_CUSTOMERS')">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-filter </v-icon>
                </template>
                <span>Filtrer</span>
              </v-tooltip>
            </v-btn>

            <v-menu content-class="mt-2" :close-on-content-click="false" offset-y right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-cog </v-icon>
                    </template>
                    <span>Colonnes</span>
                  </v-tooltip>
                </v-btn>
              </template>

              <v-card>
                <v-toolbar color="primary" dark elevation="0" class="py-0" height="45px"> Colonnes visibles </v-toolbar>
                <v-card-text class="pa-1 pb-3" style="min-width: 180px; max-height: calc(100vh - 115px); overflow-y: auto">
                  <v-list>
                    <v-list-item style="min-height: 40px" v-for="(item, key) in columnsAvailable" :key="key" @click="toggleItem(item, key)">
                      <v-list-item-title>
                        <v-icon color="primary" v-if="checkArrayContains(item.value, headers)" small>far fa-fw fa-check-square </v-icon>
                        <v-icon v-else small>far fa-fw fa-square</v-icon>
                        <span class="ml-1">{{ item.text }}</span>
                      </v-list-item-title>
                      <v-divider></v-divider>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </v-card-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="customersList"
        :server-items-length="maxItem"
        :items-per-page="limitItem"
        class="elevation-0"
        :page="page"
        @update:items-per-page="setItemMax"
        @update:page="setPage"
        @click:row="loadCustomer"
        @update:options="setPageOptions"
        :loading="loading"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
        :options="tableOptions"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 15, 25, 50],
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right',
        }"
        :hide-default-header="isMobile()"
      >
        <template v-slot:item.isClient="{ item }">
          <v-tooltip v-for="(infra, key) in item.isClient" :color="infra.color" :key="key" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip label depressed class="mr-1 mb-1" :color="infra.color" :outlined="!item.custoKey.includes(infra.id)" v-bind="attrs" v-on="on" small>
                <v-icon x-small>
                  {{ infra.icon ? infra.icon : "fa-star" }}
                </v-icon>
              </v-chip>
            </template>
            <span class="text-center grey--text text--darken-4">{{ infra.name }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.custoKey="{ item }">
          <v-tooltip v-for="(customerKeyId, key) in item.custoKey" :color="findInfraColor(customerKeyId)" :key="key" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip label depressed class="mr-1 mb-1" :color="findInfraColor(customerKeyId)" :outlined="false" v-bind="attrs" v-on="on" small>
                <v-icon x-small>
                  {{ findInfraIcon(customerKeyId) }}
                </v-icon>
              </v-chip>
            </template>
            <span class="text-center grey--text text--darken-4">{{ findInfraName(customerKeyId) }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.customer.isIndirect="{ item }">
          <v-chip :color="item.customer.isIndirect ? 'success' : 'error'">
            <v-icon>{{ item.customer.isIndirect ? "fa-check" : "fa-not-equal" }}</v-icon>
          </v-chip>
        </template>

        <template v-slot:item.entreprise.adresse.postalCode="{ item }">
          <template v-if="item.entreprise.adresse"> {{ item.entreprise.adresse.postalCode }} {{ item.entreprise.adresse.city }}</template>
        </template>

        <template v-slot:item.affectation="{ item }">
          <div class="customer-list--affectation-badge" v-for="(user, key) in item.users" :key="key">
            <v-tooltip :color="user.typeElem.color" v-if="user.dateEnd === null" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-badge v-if="user.type" bordered bottom :color="user.typeElem.color" dot offset-x="10" offset-y="10">
                  <UserAvatar size="40" class="mr-1" :userId="user.id" v-bind="attrs" v-on="on" />
                </v-badge>
              </template>
              <div class="text-center grey--text text--darken-4">
                <strong>{{ user.typeElem && user.typeElem.name ? user.typeElem.name : "Pas de service" }}</strong
                ><br /><span class="text-capitalize">{{ user.firstname.toLowerCase() }}</span> {{ user.lastname }}
              </div>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.groups="{ item }">
          <v-chip v-for="(group, key) in item.groups" :key="key" small label class="mr-1">
            {{ groups[parseInt(group - 1)].name }}
          </v-chip>
        </template>
        <template v-slot:body.prepend>
          <tr>
            <td v-if="checkArrayContains('entreprise.id', headers)"></td>

            <td v-if="checkArrayContains('entreprise.name', headers)">
              <v-text-field v-model="search.name" type="text" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('entreprise.siret', headers)">
              <v-text-field v-model="search.siret" type="text" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('entreprise.adresse.postalCode', headers)">
              <v-text-field v-model="search.cityPostalCode" label="Rechercher" type="text"></v-text-field>
            </td>
            <td v-if="checkArrayContains('groups', headers)">
              <v-autocomplete v-model="search.groups" :items="groups" item-text="name" item-value="id" label="Rechercher" multiple chips></v-autocomplete>
            </td>
            <td v-if="checkArrayContains('isClient', headers)">
              <!-- <v-autocomplete
                v-model="search.type"
                :append-outer-icon="search.type !== null ? 'fa-times' : ''"
                :items="listTypeArray"
                :menu-props="{ maxHeight: '300 !important' }"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="id"
                label="Filtrer"
                @click:append-outer="search.type = null"
              ></v-autocomplete> -->
            </td>
            <td v-if="checkArrayContains('custoKey', headers)"></td>
            <td v-if="checkArrayContains('affectation', headers)"></td>
            <td v-if="checkArrayContains('sector.name', headers)">
              <v-text-field v-model="search.sector" label="Rechercher"></v-text-field>
            </td>
            <!-- <td v-if="checkArrayContains('entreprise.adresse.city')">
              <v-text-field v-model="search.city" label="Rechercher" type="text"></v-text-field>
            </td> -->
            <td v-if="checkArrayContains('entreprise.phone', headers)">
              <v-text-field v-model="search.phone" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('customer.ctNumAb', headers)">
              <v-text-field v-model="search.ctNumAb" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('customer.ape', headers)">
              <v-text-field v-model="search.ape" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('people.name', headers)"></td>

            <!-- <td v-if="checkArrayContains('actions')"></td> -->
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <modal v-model="modalSettings" maxWidth="700">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Filtrer</strong>
      </template>
      <v-row>
        <v-col cols="3">
          <v-select v-model="usersSearch.mode" :items="['tous', 'au moins', 'aucun']" label="Mode de recherche" persistent-placeholder chips></v-select>
        </v-col>
        <v-col cols="9">
          <listUser v-if="isGranted('CUSTOMERS_INDEX_ALL')" @selectChange="setUser" includeAll></listUser>
        </v-col>
        <v-col cols="3">
          <v-select v-model="servicesSearch.mode" :items="['tous', 'au moins', 'aucun']" label="Mode de recherche" persistent-placeholder chips></v-select>
        </v-col>
        <v-col cols="9">
          <ListServices v-if="isGranted('CUSTOMERS_INDEX_ALL')" @selectChange="setServices" :allInfras="allInfras"></ListServices>
        </v-col>
        <v-col cols="6" v-if="isGranted('CUSTOMERS_INDEX_ALL')">
          <v-switch label="Afficher les prospects" v-model="prospectOnly"></v-switch>
        </v-col>
        <v-col cols="6" v-if="isGranted('CUSTOMERS_INDEX_ALL')">
          <v-switch label="Afficher les clients" v-model="clientOnly"></v-switch>
        </v-col>
        <v-col cols="6">
          <v-switch label="Uniquement liés à une campagne" v-model="campaignOnly"></v-switch>
        </v-col>
        <v-col cols="6">
          <v-switch label="Uniquement les clients clés" v-model="customerKey"></v-switch>
        </v-col>
      </v-row>
    </modal>
    <ClientFormCustomers v-model="modalAdd" @refresh="getCustomersList"></ClientFormCustomers>
  </v-container>
</template>

<script>
import axios from "axios";
import simpleDebounce from "simple-debounce-vue-mixin";

import Tools from "../../mixins/Tools";
import savePage from "../../mixins/savePage";
import Modal from "../../components/common/modal";
import ListUser from "../../components/user/listUser";
import ClientFormCustomers from "./clientFormCustomers";
import UserAvatar from "../../components/user/UserAvatar.vue";
import MobileDetection from "../../mixins/MobileDetection";
import ListServices from "../../components/common/listServices.vue";
import RapidSearchCustomers from "./rapidSearchCustomers.vue";

export default {
  name: "listCustomers",
  components: { ClientFormCustomers, ListUser, Modal, UserAvatar, ListServices, RapidSearchCustomers },
  mixins: [Tools, simpleDebounce, savePage, MobileDetection],

  data() {
    return {
      cancelTokenSource: axios.CancelToken.source(),
      mobile: false,
      modalAdd: false,
      sortedBy: null,
      sortedDesc: false,
      limitItem: 10,
      loading: false,
      groups: [],
      allInfras: [],
      listValue: [
        { name: "", value: null },
        { name: "Oui", value: true },
        { name: "Non", value: false },
      ],
      headers: [
        { text: "Intitulé", value: "entreprise.name", class: "text-no-wrap" },
        { text: "SIRET", value: "entreprise.siret", class: "text-no-wrap" },
        { text: "Localisation", value: "entreprise.adresse.postalCode", class: "text-no-wrap" },
        { text: "Client pour", value: "isClient", class: "text-no-wrap" },
        { text: "Client clé", value: "custoKey", class: "text-no-wrap" },
        { text: "Affectation", value: "affectation", class: "text-no-wrap", align: "start" },
      ],
      columnsAvailable: [
        { text: "id", value: "entreprise.id", class: "text-no-wrap" },
        { text: "Intitulé", value: "entreprise.name", class: "text-no-wrap" },
        { text: "SIRET", value: "entreprise.siret", class: "text-no-wrap" },
        { text: "Localisation", value: "entreprise.adresse.postalCode", class: "text-no-wrap" },
        { text: "Groupes", value: "groups", class: "text-no-wrap", align: "start" },
        { text: "Client pour", value: "isClient", class: "text-no-wrap" },
        { text: "Client clé", value: "custoKey", class: "text-no-wrap", sortable: false },
        { text: "Affectation", value: "affectation", sortable: false, class: "text-no-wrap" },
        { text: "Secteur", value: "sector.name", class: "text-no-wrap" },
        { text: "Téléphone", value: "entreprise.phone", class: "text-no-wrap" },
        { text: "N° AB", value: "customer.ctNumAb", class: "text-no-wrap" },
        { text: "APE", value: "customer.ape", class: "text-no-wrap" },
        { text: "Effectif", value: "people.name", class: "text-no-wrap", align: "start" },
      ],
      customersList: [],
      search: {
        name: "",
        isIndirect: null,
        siret: "",
        postalCode: "",
        city: "",
        sector: "",
        phone: "",
        ctNumAb: "",
        ape: "",
        sortedBy: null,
        sortedDesc: false,
        cityPostalCode: "",
        groups: "",
      },
      servicesSearch: {
        services: [],
        mode: "tous",
      },
      usersSearch: {
        users: [],
        mode: "tous",
      },
      campaignOnly: false,
      prospectOnly: true,
      clientOnly: true,
      customerKey: false,
      maxItem: 0,
      page: 1,
      date: new Date(),
      asServices: null,
      modalSettings: false,
    };
  },
  mounted() {
    this.getData("listCustomers");
    this.campaignOnly = sessionStorage.getItem("campaignCusto") ? JSON.parse(sessionStorage.getItem("campaignCusto")) : false;
    this.customerKey = sessionStorage.getItem("customerKeyCusto") ? JSON.parse(sessionStorage.getItem("customerKeyCusto")) : false;
    this.usersSearch.mode = sessionStorage.getItem("usersSearchModeCusto") ? JSON.parse(sessionStorage.getItem("usersSearchModeCusto")) : "tous";
    this.usersSearch.users = sessionStorage.getItem("usersSearchUsersCusto") ? JSON.parse(sessionStorage.getItem("usersSearchUsersCusto")) : [];
    this.servicesSearch.mode = sessionStorage.getItem("servicesSearchModeCusto") ? JSON.parse(sessionStorage.getItem("servicesSearchModeCusto")) : "tous";
    this.prospectOnly = sessionStorage.getItem("prospectOnlyCusto") ? JSON.parse(sessionStorage.getItem("prospectOnlyCusto")) : true;
    this.clientOnly = sessionStorage.getItem("clientOnlyCusto") ? JSON.parse(sessionStorage.getItem("clientOnlyCusto")) : true;
    this.servicesSearch.services = sessionStorage.getItem("servicesSearchServicesCusto")
      ? JSON.parse(sessionStorage.getItem("servicesSearchServicesCusto"))
      : [];
    let headers = sessionStorage.getItem("headersCusto");
    if (headers) {
      this.headers = JSON.parse(headers);
    }
    this.mobile = this.isMobile();

    this.getPeople();
    this.getCustomersList();
    this.getInfrasAll();
    this.getGroups();
  },
  methods: {
    rapidSearch(event) {
      switch (event) {
        case "Mes clients":
          this.usersSearch.users = [this.$store.state.user.id];
          this.usersSearch.mode = "tous";
          this.servicesSearch.services = [this.$store.state.user.service_id];
          this.servicesSearch.mode = "tous";
          this.campaignOnly = false;
          this.clientOnly = true;
          this.prospectOnly = false;
          this.customerKey = false;

          break;
        case "Mes prospects":
          this.usersSearch.users = [this.$store.state.user.id];
          this.usersSearch.mode = "tous";
          this.servicesSearch.services = [this.$store.state.user.service_id];
          this.servicesSearch.mode = "tous";
          this.prospectOnly = true;
          this.campaignOnly = false;
          this.clientOnly = false;
          this.customerKey = false;
          break;
        case "Mes clients partagés":
          this.usersSearch.users = [this.$store.state.user.id];
          this.usersSearch.mode = "au moins";
          this.servicesSearch.services = [];
          this.servicesSearch.mode = "tous";
          this.campaignOnly = false;
          this.clientOnly = true;
          this.prospectOnly = false;
          this.customerKey = false;
          break;
        case "Mes prospects partagés":
          this.usersSearch.users = [this.$store.state.user.id];
          this.usersSearch.mode = "au moins";
          this.servicesSearch.services = [];
          this.servicesSearch.mode = "tous";
          this.campaignOnly = false;
          this.clientOnly = false;
          this.prospectOnly = true;
          this.customerKey = false;
          break;
        default:
          this.usersSearch.users = [];
          this.usersSearch.mode = "tous";
          this.servicesSearch.services = [];
          this.servicesSearch.mode = "tous";
          this.campaignOnly = false;
          this.clientOnly = true;
          this.prospectOnly = true;
          this.customerKey = false;
          break;
      }
    },
    findInfraColor(id) {
      const infraColor = this.allInfras.find((inf) => inf.id === id)?.color;
      return infraColor ? infraColor : "";
    },
    findInfraIcon(id) {
      const infraIcon = this.allInfras.find((inf) => inf.id === id)?.icon;
      return infraIcon ? infraIcon : "fa-star";
    },
    findInfraName(id) {
      const infraName = this.allInfras.find((inf) => inf.id === id)?.name;
      return infraName ? infraName : "";
    },
    toggleItem(item, key) {
      let index = this.headers.findIndex((x) => x.value === item.value);
      let tmp = key + 1;
      let valI = -1;
      while (valI === -1 && tmp < this.columnsAvailable.length) {
        valI = this.headers.findIndex((x) => x.value === this.columnsAvailable[tmp].value);
        tmp += 1;
      }
      if (index === -1) {
        if (key === 0) {
          this.headers.splice(0, 0, item);
        } else if (key === this.columnsAvailable.length - 1 || valI === -1) {
          this.headers.push(this.columnsAvailable[key]);
        } else {
          this.headers.splice(valI, 0, item);
        }
      } else {
        this.headers.splice(index, 1);
      }

      sessionStorage.setItem("headersCusto", JSON.stringify(this.headers));
      this.$forceUpdate();
    },
    setUser(event) {
      this.usersSearch.users = event;
    },
    setServices(event) {
      this.servicesSearch.services = event;
    },
    loadCustomer(row) {
      if (this.isGranted("CUSTOMERS_VIEW")) {
        this.$router.push({ name: "Client", params: { id: row.customer.id } });
      }
    },
    setItemMax(event) {
      this.limitItem = event;
    },
    setPage(event) {
      this.page = event;
    },
    getCustomersList() {
      this.loading = true;
      this.customersList = [];
      this.cancelTokenSource.cancel("Operation canceled due to new request.");
      this.cancelTokenSource = axios.CancelToken.source();
      this.$axios("/api/customers", {
        cancelToken: this.cancelTokenSource.token,
        params: {
          page: this.page,
          search: this.search,
          limit: this.limitItem,
          asUser: this.usersSearch.users,
          asType: this.servicesSearch.services,
          userMode: this.usersSearch.mode,
          typeMode: this.servicesSearch.mode,
          campaignOnly: this.campaignOnly,
          prospectOnly: this.prospectOnly,
          clientOnly: this.clientOnly,
          customerKey: this.customerKey,
        },
      }).then((resp) => {
        this.maxItem = resp.data.max;
        this.customersList = resp.data.data;
      });
      this.loading = false;
      sessionStorage.setItem("searchCusto", JSON.stringify(this.search));
      sessionStorage.setItem("campaignOnlyCusto", this.campaignOnly);
      sessionStorage.setItem("customerKeyCusto", this.customerKey);
      sessionStorage.setItem("usersSearchModeCusto", JSON.stringify(this.usersSearch.mode));
      sessionStorage.setItem("usersSearchUsersCusto", JSON.stringify(this.usersSearch.users));
      sessionStorage.setItem("servicesSearchModeCusto", JSON.stringify(this.servicesSearch.mode));
      sessionStorage.setItem("servicesSearchServicesCusto", JSON.stringify(this.servicesSearch.services));
      sessionStorage.setItem("prospectOnlyCusto", this.prospectOnly);
      sessionStorage.setItem("clientOnlyCusto", this.clientOnly);
      sessionStorage.setItem("headersCusto", JSON.stringify(this.headers));
      this.setData("listCustomers");
    },
    getGroups() {
      this.$axios("/api/groups").then((resp) => {
        Object.keys(resp.data).forEach((index) => {
          this.groups.push(resp.data[index]);
        });
      });
    },

    getPeople() {
      this.$axios("/api/customers/people").then((resp) => {
        this.listPeople = resp.data;
      });
    },
    getCustomersListDebounce() {
      this.simpleDebounce(() => {
        this.getCustomersList();
      }, 500 /* optional debounce time: default is 300 */);
    },
    getCustomersListDebouncePage1() {
      this.page = 1;
      this.simpleDebounce(() => {
        this.getCustomersList();
      }, 500 /* optional debounce time: default is 300 */);
    },
    getInfrasAll() {
      this.$axios("/api/types/active").then((resp) => {
        this.allInfras = resp.data.types;
      });
    },
  },
  watch: {
    mobile() {
      this.headers = [
        { text: "Intitulé", value: "entreprise.name", class: "text-no-wrap" },
        { text: "SIRET", value: "entreprise.siret", class: "text-no-wrap" },
        // { text: "Localisation", value: "entreprise.adresse.postalCode", class: "text-no-wrap" },
        // { text: "Client pour", value: "isClient", class: "text-no-wrap" },
        // { text: "Client clé", value: "custoKey", class: "text-no-wrap" },
        // { text: "Affectation", value: "affectation", class: "text-no-wrap", align: "start" },
      ];
    },
    "newClient.siret"() {
      this.simpleDebounce(() => {
        this.alreadySiret = null;

        let headers = {
          headers: {
            "X-INSEE-Api-Key-Integration": process.env.VUE_APP_INSEE_API_KEY,
            accept: "application/json",
          },
        };
        if (this.newClient.siret.length == 17) {
          this.newClient.siret = this.newClient.siret.replaceAll(" ", "");
        }
        if (this.newClient.siret.length === 14) {
          this.$axios("/api/customers/siret", { params: { siret: this.newClient.siret } }).then((resp) => {
            if (resp.data) {
              this.alreadySiret = resp.data;
            } else {
              axios("https://api.insee.fr/api-sirene/3.11/siret/" + this.newClient.siret, headers).then((resp) => {
                let tmp = resp.data.etablissement;
                this.newClient.name = tmp.uniteLegale.denominationUniteLegale ? tmp.uniteLegale.denominationUniteLegale.substr(0, 35) : "";
                this.newClient.ape = tmp.uniteLegale.activitePrincipaleUniteLegale ? tmp.uniteLegale.activitePrincipaleUniteLegale.replace(".", "") : "";
                this.newClient.address = tmp.adresseEtablissement.typeVoieEtablissement
                  ? tmp.adresseEtablissement.typeVoieEtablissement
                  : "" + " " + tmp.adresseEtablissement.libelleVoieEtablissement;
                this.newClient.peopleId = parseInt(tmp.uniteLegale.trancheEffectifsUniteLegale);
                this.newClient.address2 = tmp.adresseEtablissement.complementAdresseEtablissement;
                this.newClient.postalCode = tmp.adresseEtablissement.codePostalEtablissement;
                this.newClient.city = tmp.adresseEtablissement.libelleCommuneEtablissement;
                this.newClient.siege = tmp.etablissementSiege;
                this.$forceUpdate();
              });
            }
          });
        }
      }, 300 /* optional debounce time: default is 300 */);
    },
    campaignOnly: "getCustomersListDebouncePage1",
    "usersSearch.users": "getCustomersListDebouncePage1",
    "usersSearch.mode": "getCustomersListDebouncePage1",
    "servicesSearch.services": "getCustomersListDebouncePage1",
    "servicesSearch.mode": "getCustomersListDebouncePage1",
    prospectOnly: "getCustomersListDebouncePage1",
    clientOnly: "getCustomersListDebouncePage1",
    customerKey: "getCustomersListDebouncePage1",
    "search.name": "getCustomersListDebouncePage1",
    "search.type": "getCustomersListDebouncePage1",
    "search.sector": "getCustomersListDebouncePage1",
    "search.ctNumAb": "getCustomersListDebouncePage1",
    "search.phone": "getCustomersListDebouncePage1",
    "search.ape": "getCustomersListDebouncePage1",
    "search.isIndirect": "getCustomersListDebouncePage1",
    "search.siret": "getCustomersListDebouncePage1",
    "search.cityPostalCode": "getCustomersListDebouncePage1",
    "search.city": "getCustomersListDebouncePage1",
    "search.groups": "getCustomersListDebouncePage1",
    limitItem: "getCustomersList",
    page: "getCustomersList",
    sortedBy() {
      this.search.sortedBy = this.sortedBy;
      this.getCustomersListDebounce();
    },
    sortedDesc() {
      this.search.sortedDesc = this.sortedDesc;
      this.getCustomersListDebounce();
    },
  },
};
</script>

<style scoped>
div.customer-list--affectation-badge {
  display: inline-block;
}
</style>
