var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(
        "v-container",
        { staticClass: "ma-0 py-0", attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-0", attrs: { width: "100%", elevation: "1" } },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "ma-0 pa-0 px-1",
                  attrs: {
                    color: "primary",
                    dark: "",
                    elevation: "0",
                    height: "48px",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showHeaderOpp = !_vm.showHeaderOpp
                    },
                  },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "ma-0 pa-0 font-weight-medium",
                      staticStyle: {
                        display: "block",
                        width: "100%",
                        cursor: "pointer",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "d-none d-md-flex justify-md-space-between flex-wrap",
                        },
                        [
                          _c("div", { staticClass: "align-self-start" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "pa-0 ma-0 grey--text text--lighten-3 font-weight-light",
                                staticStyle: {
                                  "font-size": "0.5em",
                                  display: "block",
                                  "line-height": "0.5em",
                                },
                              },
                              [_vm._v("N°")]
                            ),
                            _vm._v(" " + _vm._s(_vm.$route.params.id) + " "),
                          ]),
                          _vm.customerInfo
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "pa-0 ma-0 grey--text text--lighten-3 font-weight-light",
                                    staticStyle: {
                                      "font-size": "0.5em",
                                      display: "block",
                                      "line-height": "0.5em",
                                    },
                                  },
                                  [_vm._v("Client")]
                                ),
                                _vm._v(
                                  _vm._s(_vm.customerInfo.entreprise.name) + " "
                                ),
                              ])
                            : _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "pa-0 ma-0 grey--text text--lighten-3 font-weight-light",
                                    staticStyle: {
                                      "font-size": "0.5em",
                                      display: "block",
                                      "line-height": "0.5em",
                                    },
                                  },
                                  [_vm._v("Client")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-center flex-lg-row",
                                  },
                                  [
                                    _c("span", [_vm._v("Pas de client")]),
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ml-2 text-caption",
                                        attrs: {
                                          label: "",
                                          small: "",
                                          color: "error",
                                        },
                                      },
                                      [_vm._v("Brouillon")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "pa-0 ma-0 grey--text text--lighten-3 font-weight-light",
                                staticStyle: {
                                  "font-size": "0.5em",
                                  display: "block",
                                  "line-height": "0.5em",
                                },
                              },
                              [_vm._v("Titre")]
                            ),
                            _vm._v(_vm._s(_vm.item.opp.name) + " "),
                          ]),
                          _c(
                            "div",
                            [
                              _vm.item.associated
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "pa-0 ma-0 grey--text text--lighten-3 font-weight-light",
                                        staticStyle: {
                                          "font-size": "0.5em",
                                          display: "block",
                                          "line-height": "0.5em",
                                        },
                                      },
                                      [_vm._v("Contact")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.item.associated.firstname) +
                                        " " +
                                        _vm._s(_vm.item.associated.lastname) +
                                        " "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            [
                              _vm.item.type
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "pa-0 ma-0 grey--text text--lighten-3 font-weight-light",
                                        staticStyle: {
                                          "font-size": "0.5em",
                                          display: "block",
                                          "line-height": "0.5em",
                                        },
                                      },
                                      [_vm._v("Métier")]
                                    ),
                                    _vm._v(" " + _vm._s(_vm.item.type)),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            [
                              _vm.files > 0
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "pa-0 ma-0 grey--text text--lighten-3 font-weight-light",
                                        staticStyle: {
                                          "font-size": "0.5em",
                                          display: "block",
                                          "line-height": "0.5em",
                                        },
                                      },
                                      [_vm._v(" ")]
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        content: _vm.files,
                                                        bottom: "",
                                                        inline: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                color: "white",
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " fas fa-paperclip fa-fw "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1074238989
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.files) +
                                              " Pièce" +
                                              _vm._s(_vm.files > 1 ? "s" : "") +
                                              " jointe" +
                                              _vm._s(_vm.files > 1 ? "s" : "")
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm.listTotalDevis > 0
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-badge",
                                                            {
                                                              attrs: {
                                                                content:
                                                                  _vm.listTotalDevis,
                                                                bottom: "",
                                                                inline: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        color:
                                                                          "white",
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " fa-paperclip fa-fw "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1905060289
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Devis" +
                                                      _vm._s(
                                                        _vm.listTotalDevis > 1
                                                          ? ""
                                                          : ""
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "d-flex d-md-none justify-space-between text-subtitle-2 flex-wrap pa-0 ma-0",
                        },
                        [
                          _c("div", { staticClass: "align-self-start" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "pa-0 ma-0 grey--text text--lighten-3 font-weight-light",
                                staticStyle: {
                                  "font-size": "0.5em",
                                  display: "block",
                                  "line-height": "0.5em",
                                },
                              },
                              [_vm._v("N°")]
                            ),
                            _vm._v(" " + _vm._s(_vm.$route.params.id) + " "),
                          ]),
                          _vm.item.entreprise && _vm.customerInfo
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "pa-0 ma-0 grey--text text--lighten-3 font-weight-light",
                                    staticStyle: {
                                      "font-size": "0.5em",
                                      display: "block",
                                      "line-height": "0.5em",
                                    },
                                  },
                                  [_vm._v("Client")]
                                ),
                                _vm._v(
                                  _vm._s(_vm.customerInfo.entreprise.name) + " "
                                ),
                              ])
                            : _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "pa-0 ma-0 grey--text text--lighten-3 font-weight-light",
                                    staticStyle: {
                                      "font-size": "0.5em",
                                      display: "block",
                                      "line-height": "0.5em",
                                    },
                                  },
                                  [_vm._v("Client")]
                                ),
                                _vm._v("Pas de client "),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-expand-transition", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHeaderOpp,
                        expression: "showHeaderOpp",
                      },
                    ],
                  },
                  [
                    _c(
                      "v-card-text",
                      {
                        style:
                          "background:" +
                          this.$vuetify.theme.currentTheme.background,
                      },
                      [
                        _c(
                          "v-row",
                          { staticClass: "pt-6" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "4" } },
                              [
                                _vm.item.entreprise && _vm.customerInfo
                                  ? [
                                      _c(
                                        "MaterialCard",
                                        {
                                          staticClass: "v-card-profile",
                                          staticStyle: {
                                            "min-height": "13.5rem",
                                          },
                                          attrs: {
                                            title: "Client",
                                            icon: "far fa-user fa-fw",
                                            header: false,
                                          },
                                        },
                                        [
                                          _c("v-card-text", [
                                            _c(
                                              "div",
                                              { staticClass: "text-center" },
                                              [
                                                _vm.customerInfo.entreprise
                                                  ? _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "display-1 mb-0 pb-0 grey--text",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.$router.push(
                                                              {
                                                                name: "Client",
                                                                params: {
                                                                  id: _vm
                                                                    .customerInfo
                                                                    .entreprise
                                                                    .customers
                                                                    .id,
                                                                },
                                                              }
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.customerInfo
                                                                .entreprise.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm.customerInfo.entreprise
                                              ? _c(
                                                  "div",
                                                  { staticClass: "grey--text" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-center mb-3",
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " fa-map-marker-alt fa-fw"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.customerInfo
                                                                .entreprise
                                                                .adresse.address
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.customerInfo
                                                                .entreprise
                                                                .adresse
                                                                .address2
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.customerInfo
                                                                .entreprise
                                                                .adresse
                                                                .postalCode
                                                            ) +
                                                            " - " +
                                                            _vm._s(
                                                              _vm.customerInfo
                                                                .entreprise
                                                                .adresse.city
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-divider"),
                                                    _c("br", {
                                                      staticStyle: {
                                                        clear: "both",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "ma-0 pa-1",
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "green--text text--lighten-1",
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "far fa-id-badge fa-fw"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .customerInfo
                                                                        .customer
                                                                        .ctNumAb
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "ma-0 pa-1",
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fa-barcode fa-fw"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .customerInfo
                                                                        ?.entreprise
                                                                        ?.siret
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm.customerInfo
                                                              .entreprise.phone
                                                              ? _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 pa-1",
                                                                    attrs: {
                                                                      cols: "12",
                                                                      md: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fa-phone fa-fw"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .customerInfo
                                                                            .entreprise
                                                                            .phone
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm.customerInfo
                                                              .entreprise.phone2
                                                              ? _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 pa-1",
                                                                    attrs: {
                                                                      cols: "12",
                                                                      md: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fa-phone fa-fw"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .customerInfo
                                                                            .entreprise
                                                                            .phone2
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm.customerInfo
                                                              .entreprise.email
                                                              ? _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 pa-1",
                                                                    attrs: {
                                                                      cols: "12",
                                                                      md: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "far fa-envelope fa-fw"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .customerInfo
                                                                            .entreprise
                                                                            .email
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm.customerInfo
                                                              .entreprise.site
                                                              ? _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 pa-1",
                                                                    attrs: {
                                                                      cols: "12",
                                                                      md: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fa-globe-europe fa-fw"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .customerInfo
                                                                            .entreprise
                                                                            .site
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("div"),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "MaterialCard",
                                            {
                                              staticClass: "v-card-profile",
                                              attrs: {
                                                icon: "fa-user",
                                                title: "Compte",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center mb-2",
                                                    },
                                                    [_vm._v("Pas de compte")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "font-weight-light grey--text mb-5",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Il n'y a actuellement aucun compte lié."
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: {
                                                        width: "100%",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.addCompteModal = true
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mr-5" },
                                                        [_vm._v(" fa-plus ")]
                                                      ),
                                                      _vm._v(
                                                        " Lier un compte "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                              ],
                              2
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "4" } },
                              [
                                _c(
                                  "MaterialCard",
                                  {
                                    staticClass: "v-card-profile",
                                    staticStyle: { "min-height": "13.5rem" },
                                    attrs: {
                                      icon: "fa-users",
                                      title: "Contacts",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "listbox--actions-add" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              fab: "",
                                              small: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.newContact = {
                                                  customerId:
                                                    _vm.item.entreprise.id,
                                                  isFavorite: false,
                                                  civilityId: 0,
                                                  firstname: "",
                                                  lastname: "",
                                                  phone: "",
                                                  phone2: "",
                                                  fax: "",
                                                  email: "",
                                                  function: "",
                                                  note: "",
                                                  job: null,
                                                }
                                                _vm.similar = []
                                                _vm.newContactModal = true
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v(" fa-plus fa-fw ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.specificContact
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "ma-0 pt-0 text-h5 grey--text text-left",
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-0",
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        " fa-fw fa-id-card "
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.specificContact
                                                            .contact.firstname
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.specificContact
                                                            .contact.lastname
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("v-divider", {
                                                      staticClass: "my-2",
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "ma-0 grey--text mb-5",
                                              },
                                              [
                                                _vm.specificContact.contact
                                                  .function
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "pa-0 mb-1",
                                                        attrs: { cols: "6" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fa-graduation-cap fa-fw"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .specificContact
                                                                .contact
                                                                .function
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.specificContact.contact
                                                  .email
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "pa-0 mb-1",
                                                        attrs: { cols: "6" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "far fa-envelope fa-fw"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .specificContact
                                                                .contact.email
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.specificContact.contact
                                                  .phone
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "pa-0 mb-1",
                                                        attrs: { cols: "6" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fa-phone fa-fw"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .specificContact
                                                                .contact.phone
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.specificContact.contact
                                                  .phone2
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "pa-0 mb-1",
                                                        attrs: { cols: "6" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fa-phone fa-fw"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .specificContact
                                                                .contact.phone2
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.specificContact.contact.fax
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "pa-0 mb-1",
                                                        attrs: { cols: "6" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fa-fax fa-fw"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .specificContact
                                                                .contact.fax
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-expansion-panels",
                                          {
                                            directives: [
                                              {
                                                name: "click-outside",
                                                rawName: "v-click-outside",
                                                value: _vm.onClickOutside,
                                                expression: "onClickOutside",
                                              },
                                            ],
                                            model: {
                                              value: _vm.activePan,
                                              callback: function ($$v) {
                                                _vm.activePan = $$v
                                              },
                                              expression: "activePan",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-expansion-panel",
                                              [
                                                _c(
                                                  "v-expansion-panel-header",
                                                  {
                                                    attrs: {
                                                      "expand-icon":
                                                        "mdi-menu-down",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Liste des contacts client "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-expansion-panel-content",
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "append-icon":
                                                          "mdi-magnify",
                                                        label: "Rechercher",
                                                        "single-line": "",
                                                        "hide-details": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.searchContact,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.searchContact =
                                                            $$v
                                                        },
                                                        expression:
                                                          "searchContact",
                                                      },
                                                    }),
                                                    _c("v-data-table", {
                                                      attrs: {
                                                        dense: "",
                                                        "items-per-page": 5,
                                                        headers: _vm.headers,
                                                        items: _vm.contacts,
                                                        search:
                                                          _vm.searchContact,
                                                      },
                                                      on: {
                                                        "click:row":
                                                          _vm.catchRow,
                                                        "update:search":
                                                          function ($event) {
                                                            _vm.searchContact =
                                                              $event
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "4" } },
                              [
                                _c("filesOpportunities", {
                                  model: {
                                    value: _vm.infoDevis,
                                    callback: function ($$v) {
                                      _vm.infoDevis = $$v
                                    },
                                    expression: "infoDevis",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "modal",
            {
              attrs: { "max-width": "1024" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.newContactModal = false
                              },
                            },
                          },
                          [_vm._v(" Fermer ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: { text: "", disabled: !_vm.singleContact },
                            on: { click: _vm.newContactSub },
                          },
                          [_vm._v(" Enregistrer ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1537991909
              ),
              model: {
                value: _vm.newContactModal,
                callback: function ($$v) {
                  _vm.newContactModal = $$v
                },
                expression: "newContactModal",
              },
            },
            [
              !_vm.singleContact
                ? _c(
                    "v-alert",
                    { attrs: { dense: "", outlined: "", type: "error" } },
                    [_vm._v(" Un contact existe déjà avec cet email. ")]
                  )
                : _vm._e(),
              _c("contact-form", {
                model: {
                  value: _vm.newContact,
                  callback: function ($$v) {
                    _vm.newContact = $$v
                  },
                  expression: "newContact",
                },
              }),
              _vm.similar && _vm.similar.length > 0
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-divider"),
                          _c("h4", [_vm._v("Contacts similaires")]),
                        ],
                        1
                      ),
                      _vm._l(_vm.similar, function (item, key) {
                        return _c(
                          "v-col",
                          {
                            key: key,
                            staticClass: "py-1",
                            attrs: { cols: "6", md: "6" },
                          },
                          [
                            _c("SimilarContactCard", {
                              attrs: {
                                item: item,
                                linkContactConfirm: _vm.linkContactConfirm,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "modal",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.addCompteModal = false
                              },
                            },
                          },
                          [_vm._v(" Fermer ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: { text: "" },
                            on: { click: _vm.validate },
                          },
                          [_vm._v(" Enregistrer ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2249272571
              ),
              model: {
                value: _vm.addCompteModal,
                callback: function ($$v) {
                  _vm.addCompteModal = $$v
                },
                expression: "addCompteModal",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm._v(" Rechercher un compte "),
                      _c("v-autocomplete", {
                        attrs: {
                          "menu-props": { maxHeight: "300" },
                          items: _vm.customerList,
                          loading: _vm.isLoading,
                          "search-input": _vm.search,
                          "hide-no-data": "",
                          "hide-selected": "",
                          "item-text": "entreprise.name",
                          "item-value": "entreprise.id",
                          label: "Rechercher un compte",
                          "prepend-icon": "fa-users",
                          "return-object": "",
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.search = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.search = $event
                          },
                        },
                        model: {
                          value: _vm.selectedCustomer,
                          callback: function ($$v) {
                            _vm.selectedCustomer = $$v
                          },
                          expression: "selectedCustomer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }