<template>
  <material-card class="v-card-profile mt-10" :color="'primary'" icon="fa-paper-plane" :header="true" title="Campagnes">
    <div v-if="compaigns === false" class="font-weight-light grey--text mb-5">Ce compte ne fait parti d'aucune campagne</div>
    <v-data-table
      v-else
      :headers="headers"
      :items="compaigns"
      class="elevation-0"
      :loading="loading"
      :server-items-length="compaigns.length"
      @click:row="openCompaign"
    >
      <template v-slot:body.prepend>
        <tr>
          <td>
            <!--<v-text-field
                v-model="search.id"
                type="number"
                label="Filtrer"
            ></v-text-field>-->
          </td>
          <td>
            <v-text-field v-model="search.name" label="Filtrer"></v-text-field>
          </td>
          <td>
            <v-select multiple v-model="search.types" :items="types" item-text="name" item-value="id" label="Filtrer"></v-select>
          </td>
          <td>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateStartFormatted"
                  label="Filtrer"
                  persistent-hint
                  v-bind="attrs"
                  readonly
                  @click:append-outer="search.dateStart = null"
                  :append-outer-icon="search.dateStart === null ? '' : 'fa-times'"
                  @blur="search.dateStart = parseDate(dateStartFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="search.dateStart" no-title @input="menu1 = false"></v-date-picker>
            </v-menu>
          </td>
          <td>
            <v-menu
              ref="menu1"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateEndFormatted"
                  label="Filtrer"
                  persistent-hint
                  readonly
                  v-bind="attrs"
                  @click:append-outer="search.dateEnd = null"
                  :append-outer-icon="search.dateEnd === null ? '' : 'fa-times'"
                  @blur="search.dateEnd = parseDate(dateEndFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="search.dateEnd" no-title @input="menu2 = false"></v-date-picker>
            </v-menu>
          </td>
          <td>
            <v-select
              :menu-props="{ maxHeight: '300' }"
              label="Filtrer"
              :items="status"
              v-model="search.status"
              item-value="value"
              item-text="name"
              :append-outer-icon="search.status === null ? '' : 'fa-times'"
              @click:append-outer="search.status = null"
            ></v-select>
          </td>
          <td v-if="isGranted('COMPAIGN_COMPANY')">
            <v-select
              v-model="search.typeComp"
              :items="typeComp"
              item-value="value"
              item-text="name"
              label="Filtrer"
              :append-outer-icon="search.typeComp !== null ? 'fa-times' : ''"
              @click:append-outer="search.typeComp = null"
            ></v-select>
          </td>
        </tr>
      </template>

      <template v-slot:item.bu="{ item }">
        <v-chip dark v-for="bu in item.types" :key="bu.id + '-' + item.id" label x-small :color="bu.color" class="mr-1">
          <v-icon x-small>fa-fw {{ bu.icon }}</v-icon>
        </v-chip>
      </template>

      <template v-slot:item.solo="{ item }">
        {{ item.solo ? "Individuelle" : "Entreprise" }}
      </template>

      <template v-slot:item.draft="{ item }">
        <v-chip label small v-if="item.draft" :color="'error'" class="mr-1"> Brouillon </v-chip>
        <template v-else>
          <v-chip label small :color="getPhase(item).color">
            {{ getPhase(item).name }}
          </v-chip>
        </template>
      </template>

      <template v-slot:item.startDate="{ item }">
        {{ dayjs(item.startDate).format("DD/MM/YYYY") }}
      </template>

      <template v-slot:item.userId="{ item }">
        <UserAvatar :userId="item.userId" size="35" class="ml-2" />
      </template>

      <template v-slot:item.endDate="{ item }">
        {{ dayjs(item.endDate).format("DD/MM/YYYY") }}
      </template>
    </v-data-table>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard.vue";
import UserAvatar from "../../components/user/UserAvatar.vue";
import Tools from "../../mixins/Tools";
import simpleDebounceVueMixin from "simple-debounce-vue-mixin";

export default {
  name: "compaignCustomers",
  components: { MaterialCard, UserAvatar },
  mixins: [Tools, simpleDebounceVueMixin],
  data() {
    return {
      typeComp: [],
      loading: false,
      dateStartFormatted: null,
      dateEndFormatted: null,
      menu1: false,
      menu2: false,
      status: [
        { name: "Publié", value: 0 },
        { name: "Brouillon", value: 1 },
      ],
      search: {
        id: null,
        name: null,
        types: null,
        dateStart: null,
        dateEnd: null,
        status: null,
        typeComp: null,
      },
      types: [],

      headers: [
        { text: "#", sortable: false, value: "id" },
        { text: "Nom", sortable: false, value: "name" },
        { text: "Métiers Concernés", sortable: false, value: "bu" },
        { text: "Date de début", sortable: false, value: "startDate" },
        { text: "Date de fin", sortable: false, value: "endDate" },
        { text: "Status", sortable: false, value: "draft" },
        { text: "Type", sortable: false, value: "solo" },
        { text: "Responsable", sortable: false, value: "userId" },
      ],
      compaigns: false,
    };
  },
  mounted() {
    this.getCompaigns();
    this.initTypeComp();
    this.getTypes();
  },
  methods: {
    openCompaign(row) {
      if (row.draft) {
        this.$router.push({ name: "Campagne Edition", params: { id: row.id } });
      } else {
        this.$router.push({ name: "Campagne", params: { id: row.id } });
      }
    },
    getTypes() {
      this.$axios("/api/types").then((resp) => {
        this.types = resp.data.types;
      });
    },
    initTypeComp() {
      this.typeComp = [
        { value: 0, name: "Entreprise" },
        { value: 1, name: "Individuelle" },
      ];
    },
    getCompaigns() {
      this.loading = true;
      this.$axios("/api/customers/" + this.$route.params.id + "/compaigns", { params: { search: this.search } }).then((resp) => {
        this.compaigns = resp.data;
        this.loading = false;
      });
    },
    getCompaingsDebounce() {
      this.simpleDebounce(() => {
        this.getCompaigns();
      }, 500);
    },
  },
  watch: {
    "search.typeComp": "getCompaingsDebounce",
    "search.id": "getCompaingsDebounce",
    "search.name": "getCompaingsDebounce",
    "search.status": "getCompaingsDebounce",
    "search.types": "getCompaingsDebounce",
    allListing: "getCompaingsDebounce",
    search: "getCompaingsDebounce",
    "search.dateStart"() {
      this.dateStartFormatted = this.formatDate(this.search.dateStart);
      this.simpleDebounce(() => {
        this.getCompaigns();
      }, 500);
    },
    "search.dateEnd"() {
      this.dateEndFormatted = this.formatDate(this.search.dateEnd);
      this.simpleDebounce(() => {
        this.getCompaigns();
      }, 500);
    },
  },
};
</script>

<style scoped></style>
