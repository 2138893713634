<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="12" class="ma-0 pa-0">
      <MaterialCard class="v-card-profile mt-9" icon="fa-users" title="Concurrents">
        <div class="listbox--actions-add">
          <v-btn small fab class="text-center mb-5" color="primary" @click="addConcurrentModal = true">
            <v-icon small> fa-fw fa-plus </v-icon>
          </v-btn>
        </div>

        <v-card-text v-if="concurrentsList.length > 0">
          <v-data-table style="width: 100%" :headers="headers" :items="concurrentsList" @click:row="loadCustomer" hide-default-footer>
            <template v-slot:item.delete="{ item }">
              <v-col class="text-right">
                <v-icon color="error" class="deleteConcurrent" small @click.stop="deleteConcu(item.concurrents.id)">fa-fw fa-trash-alt</v-icon>
              </v-col>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-text v-else>
          <div class="font-weight-light grey--text mb-5">Aucun concurrent lié</div>
          <div class="font-weight-light grey--text mb-5" v-if="false">Il n'y a actuellement aucun concurrent lié.</div>
        </v-card-text>
      </MaterialCard>
    </v-col>

    <modal v-model="addConcurrentModal" :onClose="closeConcurrentModal">
      <v-row>
        <v-col cols="12">
          Rechercher un concurrent
          <selectConcurrents v-model="selectedConcurrent"></selectConcurrents>
        </v-col>
        <v-col cols="12"></v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn text class="mr-4" @click="linkConcurrent"> Ajouter </v-btn>
      </template>
    </modal>
  </v-row>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import MaterialCard from "../../components/common/MaterialCard";
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";

import SelectConcurrents from "../../components/concurrents/selectConcurrents";

export default {
  name: "concurrentOpportunities",
  components: { SelectConcurrents, Modal, MaterialCard },
  mixins: [simpleDebounce, Tools],
  props: ["value"],
  data() {
    return {
      allConcurrents: [],
      concurrentsList: [],
      addConcurrentModal: false,
      item: null,
      selectedConcurrent: null,
      headers: [
        {
          text: "Nom",
          align: "start",
          value: "name",
        },
        {
          text: "",
          value: "delete",
          align: "end",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    closeConcurrentModal() {
      this.addConcurrentModal = false;
      this.selectedConcurrent = null;
    },
    loadCustomer(row) {
      if (this.isGranted("CUSTOMERS_VIEW")) {
        this.$router.push({ name: "Client", params: { id: row.id } });
      }
    },
    linkConcurrent() {
      if (this.selectedConcurrent) {
        this.$axios.post("/api/opportunities/" + this.$route.params.id + "/concurrents", { id: this.selectedConcurrent }).then((res) => {
          this.item = res.data;
          this.$simpleToast.success("Concurrent", "Le concurrent a bien été ajouté");
          this.addConcurrentModal = false;
          this.$emit("refresh-all", true);
        });
      } else {
        this.$simpleToast.error("Concurrent", "Vous devez selectionner au moins un concurrent");
      }
    },
    // A GARDER DANS LE CADRE D'UNE HARMONISATION DES APPELS API
    // if (this.selectedConcurrent) {
    //   this.$axios
    //     .post("/api/opportunities/concurrent", {
    //       concurrent: this.selectedConcurrent,
    //       opportunity: this.$route.params.id,
    //     })
    //     .then((res) => {
    //       this.item = res.data;
    //       this.$simpleToast.success("Concurrent", "Le concurrent a bien été ajouté");
    //       this.addConcurrentModal = false;
    //       this.$emit("refresh-all", true);
    //     });
    // } else {
    //   this.$simpleToast.error("Concurrent", "Vous devez selectionner au moins un concurrent");
    // }
    //},
    async deleteConcu(id) {
      const res = await this.$confirm("Êtes-vous sur ?", { title: "Confirmation", color: "primary" });
      if (res) {
        this.$axios.delete("/api/opportunities/" + this.$route.params.id + "/concurrents/" + id).then(() => {
          this.$simpleToast.success("Concurrent", "Le concurrent a bien été supprimé");
          this.$emit("refresh-all", true);
        });
      }
    },
    async getAllConcurrents() {
      this.$axios.get("/api/concurrents/list").then((resp) => {
        this.allConcurrents = resp.data.data;
      });
    },
  },
  watch: {
    "$route.params.id"() {
      this.getAllConcurrents();
    },
    value() {
      this.item = this.value;
      this.concurrentsList = this.value.concurrents;
    },
    item() {
      this.$emit("input", this.item);
    },
  },
};
</script>

<style scoped></style>
