var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    { attrs: { title: "Logiciels" } },
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.tab,
          "server-items-length": _vm.maxItem,
          "items-per-page": _vm.limitItem,
        },
        on: {
          "update:items-per-page": _vm.setItemMax,
          "update:page": _vm.setPage,
        },
        scopedSlots: _vm._u([
          {
            key: "body.prepend",
            fn: function () {
              return [
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        attrs: { type: "text", label: "Rechercher" },
                        model: {
                          value: _vm.search.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "name", $$v)
                          },
                          expression: "search.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        attrs: { type: "text", label: "Rechercher" },
                        model: {
                          value: _vm.search.version,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "version", $$v)
                          },
                          expression: "search.version",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "item.installDate",
            fn: function ({ item }) {
              return [
                item.installDate
                  ? [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dayjs(item.installDate).format("DD/MM/YYYY")
                          ) +
                          " "
                      ),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }