import Vue from "vue";

export const simpleToast = {
  setContent(title = "", body = "") {
    let str = "";

    if (body != "")
      str +=
        `<strong >` +
        title +
        `</strong>
    <br>`;
    str += `<i>` + body + `</i>`;

    return str;
  },

  success(title = "", body = "") {
    Vue.$toast.success(this.setContent(title, body), {
      duration: 3000,
      dismissible: false,
    });
  },
  error(title = "", body = "") {
    Vue.$toast.error(this.setContent(title ? title : "Erreur", body), {
      duration: 7000,
      dismissible: true,
    });
  },
  info(title = "", body = "") {
    Vue.$toast.info(this.setContent(title, body), {
      duration: 3000,
      dismissible: true,
    });
  },
  default(title = "", body = "") {
    Vue.$toast.default(this.setContent(title, body), {
      duration: 3000,
      dismissible: true,
    });
  },
};
