<template :style="isMobile() {marginTop: '50px'} : {}">
  <modal max-width="1024" v-model="modalVal">
    <template v-slot:header>
      <strong class="text-h5 mb-0 text-uppercase"> Création Concurrent</strong>
      <br /><small class="mt-0">Commencez par saisir le N° de SIRET</small>
    </template>

    <v-alert dense type="error" v-if="alreadySiret">
      <div>Un compte existe déjà avec ce SIRET:</div>
      <div>{{ alreadySiret.name }}</div>
      <div>
        <v-btn color="secondary" v-if="isGranted('CUSTOMERS_VIEW') && link === false" @click="$router.push({ name: 'Client', params: { id: alreadySiret.id } })"
          >Accéder au concurrent
        </v-btn>
      </div>
      <div>
        <v-btn color="secondary" v-if="isGranted('CUSTOMERS_VIEW') && link === true" @click="linkItem()">Lier le concurrent</v-btn>
      </div>
    </v-alert>
    <v-row>
      <v-col cols="12" md="6">
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field
            class="pa-0 ma-0 mb-3"
            maxlength="35"
            v-model="newConcurrent.name"
            :error="newConcurrent && newConcurrent.name && newConcurrent.name.length === 0"
            hint="Nom obligatoire"
            outlined
            label="Intitulé"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field
            class="pa-0 ma-0 mb-3"
            maxlength="14"
            v-model="newConcurrent.siret"
            outlined
            label="SIRET"
            :error="newConcurrent && newConcurrent.siret && newConcurrent.siret.length === 0"
            hint="Siret obligatoire"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" v-model="newConcurrent.address" hide-details="true" :maxlength="35" outlined label="Adresse"></v-text-field>
          <v-text-field class="pa-0 ma-0 mb-3" v-model="newConcurrent.address" hide-details="true" :maxlength="35" outlined label="Adresse"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" v-model="newConcurrent.address2" hide-details="true" :maxlength="35" outlined label="Complément"></v-text-field>
          <v-text-field class="pa-0 ma-0 mb-3" v-model="newConcurrent.address2" hide-details="true" :maxlength="35" outlined label="Complément"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field
            class="pa-0 ma-0 mb-3"
            v-model="newConcurrent.postalCode"
            :error="newConcurrent && newConcurrent.postalCode && newConcurrent.postalCode.length === 0"
            hint="Code postal obligatoire"
            :maxlength="5"
            outlined
            label="Code postal"
          ></v-text-field>
          <v-text-field
            class="pa-0 ma-0 mb-3"
            v-model="newConcurrent.postalCode"
            hide-details="true"
            :maxlength="5"
            outlined
            label="Code postal"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field
            class="pa-0 ma-0 mb-1"
            v-model="newConcurrent.city"
            :error="newConcurrent && newConcurrent.city && newConcurrent.city.length === 0"
            hint="Ville obligatoire"
            :maxlength="35"
            outlined
            label="Ville"
          ></v-text-field>
        </v-col>
      </v-col>
      <v-col cols="12" md="6">
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-0" persistent-hint type="email" outlined v-model="newConcurrent.email" label="Email"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" v-model="newConcurrent.ape" outlined label="APE"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-select
            :menu-props="{ maxHeight: '300' }"
            class="pa-0 ma-0 mb-3"
            hide-details="true"
            v-model="newConcurrent.peopleId"
            outlined
            label="Effectifs"
            item-value="id"
            item-text="name"
            :items="listPeople"
          ></v-select>
        </v-col>

        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" hide-details="true" v-model="newConcurrent.phone" outlined label="Téléphone"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" hide-details="true" v-model="newConcurrent.phone2" outlined label="FAX"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" hide-details="true" v-model="newConcurrent.site" outlined label="Site Web" type="url"></v-text-field>*
          <v-switch label="Prestataire" v-model="newConcurrent.prestataire" class="mt-3"></v-switch>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-alert
            dense
            border="top"
            colored-border
            elevation="2"
            x-small
            type="info"
            color="primary"
            v-if="this.$store.state.settings.sage"
            class="text-left pt-3"
          >
            <div class="caption">
              <h3 class="text-h6">Norme N° Concurrent</h3>
              <ul>
                <li>NOM de l’entreprise sans SARL, SA, EURL, GROUPE…</li>
                <li>M + VILLE si Mairie</li>
                <li>CC + Ville si Communauté de Communes</li>
                <li>A défaut on appelle le service COMPTA/ADV</li>
              </ul>
            </div>
          </v-alert>
        </v-col>
      </v-col>
    </v-row>

    <template v-slot:actions>
      <v-btn
        @click="saveConcurrent"
        :disabled="alreadySiret !== null && alreadySiret.id === newConcurrent.siret ? true : false"
        variant="flat"
        color="primary"
        elevation="0"
        depressed
        class="rounded-1 px-3"
        small
      >
        <v-icon x-small class="mr-2">fas fa-check fa-fw</v-icon>
        Enregistrer
      </v-btn>
      <v-btn color="secondary" text class="mr-4" @click="modalVal = false"> Fermer </v-btn>
    </template>
  </modal>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";
import MobileDetection from "../../mixins/MobileDetection";

export default {
  name: "concurrentFormCustomers",
  components: { Modal },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    concurrent: {
      default: false,
      type: Boolean,
    },
    origin: {
      default: false,
      type: Boolean,
    },
    link: {
      default: false,
      type: Boolean,
    },
  },
  mixins: [Tools, simpleDebounce, MobileDetection],
  data() {
    return {
      modalVal: this.value,
      newConcurrent: {
        name: "",
        ape: "",
        ctNumAb: "",
        siret: "",
        address: "",
        address2: "",
        postalCode: "",
        city: "",
        peopleId: null,
        email: "",
        phone: "",
        phone2: "",
        site: "",
        active: 1,
        prestataire: true,
      },
      listPeople: [],
      alreadySiret: null,
      types: [],
    };
  },
  mounted() {
    this.getPeople();
    this.getTypes();
  },
  methods: {
    linkItem() {
      this.$emit("linkconcu", this.alreadySiret);
    },
    getTypes() {
      this.$axios("/api/types/active").then((resp) => {
        this.types = resp.data.types;
      });
    },
    saveConcurrent() {
      if (this.newConcurrent.ape) {
        this.newConcurrent.ape = this.newConcurrent.ape.replace(".", "");
      }
      this.$axios
        .post("/api/concurrents", {
          concurrent: this.newConcurrent,
        })
        .then(() => {
          this.$simpleToast.success("Concurrent", "Le compte a bien été ajouté");
          this.modalVal = false;
          this.$emit("refresh");
        });
    },
    getPeople() {
      this.$axios("/api/customers/people").then((resp) => {
        this.listPeople = resp.data;
      });
    },
    formatName(name) {
      let Arr = ["SELARL ", "SOC ", "EURL ", "GROUPE ", "SA ", "SCI ", "SARL ", "SCIC ", "SCP "];
      let ret = name;
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = name.substring(start.length);
        }
      }

      Arr = ["MAIRIE DE ", "COMMUNE DE "];
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = "M" + name.substring(start.length);
        }
      }

      Arr = ["LYCEE PROFESSIONNEL "];
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = "LP" + name.substring(start.length);
        }
      }

      Arr = ["COMMUNAUTE DE COMMUNES "];
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = "CC" + name.substring(start.length);
        }
      }

      return ret
        .trim()
        .replace(/[^a-z0-9]/gi, "")
        .substring(0, 17);
    },
  },
  watch: {
    "newConcurrent.siret"() {
      this.simpleDebounce(() => {
        this.alreadySiret = null;

        if (this.newConcurrent.siret.length == 17) {
          this.newConcurrent.siret = this.newConcurrent.siret.replaceAll(" ", "");
        }
        if (this.newConcurrent.siret.length === 14) {
          this.$axios("/api/concurrents/siret", { params: { siret: this.newConcurrent.siret } }).then((resp) => {
            if (resp.data) {
              this.alreadySiret = resp.data;
            } else {
              this.$axios("/api/customers/siretData", {
                params: {
                  siret: this.newConcurrent.siret,
                },
              })
                .catch((err) => {
                  this.$simpleToast.error("Concurrent", err.response.data.header.message);
                })
                .then((resp) => {
                  let tmp = resp.data.etablissement;
                  this.newConcurrent.name = tmp.uniteLegale.denominationUniteLegale ? tmp.uniteLegale.denominationUniteLegale.substr(0, 35) : "";
                  this.newConcurrent.ape = tmp.uniteLegale.activitePrincipaleUniteLegale ? tmp.uniteLegale.activitePrincipaleUniteLegale.replace(".", "") : "";
                  this.newConcurrent.address =
                    (tmp.adresseEtablissement.numeroVoieEtablissement ? tmp.adresseEtablissement.numeroVoieEtablissement + " " : "") +
                    (tmp.adresseEtablissement.typeVoieEtablissement ? tmp.adresseEtablissement.typeVoieEtablissement : "") +
                    " " +
                    tmp.adresseEtablissement.libelleVoieEtablissement;
                  this.newConcurrent.peopleId = parseInt(tmp.uniteLegale.trancheEffectifsUniteLegale);
                  this.newConcurrent.address2 = tmp.adresseEtablissement.complementAdresseEtablissement;
                  this.newConcurrent.postalCode = tmp.adresseEtablissement.codePostalEtablissement;
                  this.newConcurrent.city = tmp.adresseEtablissement.libelleCommuneEtablissement;
                  this.newConcurrent.siege = tmp.etablissementSiege;
                  this.$forceUpdate();
                });
            }
          });
        }
      }, 300 /* optional debounce time: default is 300 */);
    },
    value() {
      this.modalVal = this.value;
      this.newConcurrent = {
        name: "",
        ape: "",
        siret: "",
        address: "",
        address2: "",
        postalCode: "",
        city: "",
        peopleId: null,
        email: "",
        phone: "",
        phone2: "",
        site: "",
        active: 1,
        prestataire: true,
      };
    },
    modalVal() {
      this.$emit("input", this.modalVal);
    },
  },
};
</script>

<style scoped></style>
