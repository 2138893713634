var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    { attrs: { id: "infos", title: "Informations", icon: "fa-info" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "py-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12", md: "5" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", label: "Titre de campagne" },
                            model: {
                              value: _vm.item.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.item.company !== "Individuelle"
                        ? _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  "menu-props": { maxHeight: "300" },
                                  disabled:
                                    _vm.item.commando === 1 && !_vm.item.draft,
                                  multiple: "",
                                  label: "Métier",
                                  items: _vm.types,
                                  "item-value": "id",
                                  "item-text": "name",
                                },
                                model: {
                                  value: _vm.item.types,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "types", $$v)
                                  },
                                  expression: "item.types",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.typeList.length > 1
                        ? _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "3" },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  "menu-props": { maxHeight: "300" },
                                  label: "Type de campagne",
                                  disabled:
                                    Object.keys(_vm.item).includes("id") &&
                                    !_vm.item.draft,
                                  items: _vm.typeList,
                                },
                                model: {
                                  value: _vm.item.company,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "company", $$v)
                                  },
                                  expression: "item.company",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12", md: "5" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu1",
                              attrs: {
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "max-width": "290px",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                label: "Date de début",
                                                "persistent-hint": "",
                                                outlined: "",
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  _vm.item.startDate =
                                                    _vm.parseDate(
                                                      _vm.dateStartFormatted
                                                    )
                                                },
                                              },
                                              model: {
                                                value: _vm.dateStartFormatted,
                                                callback: function ($$v) {
                                                  _vm.dateStartFormatted = $$v
                                                },
                                                expression:
                                                  "dateStartFormatted",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.menu1,
                                callback: function ($$v) {
                                  _vm.menu1 = $$v
                                },
                                expression: "menu1",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  "first-day-of-week": "1",
                                  min: _vm.tomorrow,
                                  outlined: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.menu1 = false
                                  },
                                },
                                model: {
                                  value: _vm.item.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "startDate", $$v)
                                  },
                                  expression: "item.startDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu1",
                              attrs: {
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "max-width": "290px",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                label: "Date de fin",
                                                "persistent-hint": "",
                                                outlined: "",
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  _vm.item.endDate =
                                                    _vm.parseDate(
                                                      _vm.dateEndFormatted
                                                    )
                                                },
                                              },
                                              model: {
                                                value: _vm.dateEndFormatted,
                                                callback: function ($$v) {
                                                  _vm.dateEndFormatted = $$v
                                                },
                                                expression: "dateEndFormatted",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.menu2,
                                callback: function ($$v) {
                                  _vm.menu2 = $$v
                                },
                                expression: "menu2",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  "first-day-of-week": "1",
                                  outlined: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.menu2 = false
                                  },
                                },
                                model: {
                                  value: _vm.item.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "endDate", $$v)
                                  },
                                  expression: "item.endDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isGranted("COMPAIGN_COMMANDO") &&
                      _vm.item.company === "Commando"
                        ? _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "py-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("listUser", {
                                        attrs: {
                                          label: "Responsables de campagne",
                                          multiple: "",
                                          includeAll: "",
                                          disabled:
                                            _vm.item.company !== "Commando" &&
                                            !_vm.item.draft,
                                        },
                                        on: { selectChange: _vm.setAsUser },
                                      }),
                                    ],
                                    1
                                  ),
                                  false
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "4" },
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: { label: "Commando" },
                                            model: {
                                              value: _vm.item.commando,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "commando",
                                                  $$v
                                                )
                                              },
                                              expression: "item.commando",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.item.company === "Entreprise"
                        ? _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  "menu-props": { maxHeight: "300" },
                                  items: _vm.users,
                                  chips: "",
                                  "deletable-chips": "",
                                  color: "blue-grey lighten-2",
                                  label: "Utilisateurs participants",
                                  "item-text": "fullname",
                                  "item-value": "id",
                                  multiple: "",
                                  outlined: "",
                                  "persistent-placeholder": "",
                                  "search-input": _vm.search,
                                  "cache-items": "",
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.search = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.search = $event
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "input-value": data.selected,
                                                  close: "",
                                                },
                                                on: {
                                                  click: data.select,
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.remove(data.item)
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _c("UserAvatar", {
                                                attrs: {
                                                  userId: data.item.id,
                                                  left: "",
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.item.firstname +
                                                      " " +
                                                      data.item.lastname
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item",
                                      fn: function (data) {
                                        return [
                                          [
                                            _c(
                                              "v-list-item-avatar",
                                              [
                                                _c("UserAvatar", {
                                                  attrs: {
                                                    userId: data.item.id,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.item.firstname +
                                                          " " +
                                                          data.item.lastname
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3049930954
                                ),
                                model: {
                                  value: _vm.item.commercial,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "commercial", $$v)
                                  },
                                  expression: "item.commercial",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isGranted("COMPAIGN_COMPANY") &&
                      _vm.item.company === "Entreprise"
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "py-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "3" },
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          color: "warning",
                                          disabled:
                                            _vm.item.commando === 1 &&
                                            !_vm.item.draft,
                                          label: "Mailing",
                                        },
                                        model: {
                                          value: _vm.item.phases[1].enable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item.phases[1],
                                              "enable",
                                              $$v
                                            )
                                          },
                                          expression: "item.phases[1].enable",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "3" },
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          color: "primary",
                                          disabled:
                                            _vm.item.commando === 1 &&
                                            !_vm.item.draft,
                                          label: "Phoning",
                                        },
                                        model: {
                                          value: _vm.item.phases[2].enable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item.phases[2],
                                              "enable",
                                              $$v
                                            )
                                          },
                                          expression: "item.phases[2].enable",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "3" },
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          color: "error",
                                          label: "Rencontre",
                                        },
                                        model: {
                                          value: _vm.item.phases[3].enable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item.phases[3],
                                              "enable",
                                              $$v
                                            )
                                          },
                                          expression: "item.phases[3].enable",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "3" },
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          color: "info",
                                          label: "Génération business",
                                        },
                                        model: {
                                          value: _vm.item.phases[4].enable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item.phases[4],
                                              "enable",
                                              $$v
                                            )
                                          },
                                          expression: "item.phases[4].enable",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      false
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "2" } },
                            [
                              _c("v-switch", {
                                attrs: { label: "Publié", value: "1" },
                                model: {
                                  value: _vm.item.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "status", $$v)
                                  },
                                  expression: "item.status",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "py-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-textarea", {
                            staticClass: "py-0",
                            attrs: { outlined: "", label: "Description" },
                            model: {
                              value: _vm.item.body,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "body", $$v)
                              },
                              expression: "item.body",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.continuePhase()
                        },
                      },
                    },
                    [_vm._v(" Continuer ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }