<template>
  <v-container fluid>
    <v-card style="z-index: 20">
      <v-card>
        <v-tabs background-color="primary" center-active dark fixed-tabs v-model="tab">
          <v-tab>Services</v-tab>
          <v-tab>Métiers</v-tab>
        </v-tabs>
      </v-card>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card class="mt-3" elevation="0">
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="11">
                    <v-text-field label="Rechercher" v-model="search"></v-text-field>
                  </v-col>
                  <v-col cols="1" class="text-right">
                    <v-btn fab color="primary" small class=" " @click="selectedItem = { name: '', parentId: null, userId: null, typeId: null }"
                      ><v-icon small>fa-plus fa-fw</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" v-if="tree.children.length > 0" style="border-right: solid 1px lightgray">
                    <Finder v-if="tree !== null" ref="Finder" :tree="tree" :filter="filterFunction" @expand="onExpand" />
                  </v-col>
                  <v-col cols="6" v-if="selectedItem">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field outlined label="Nom" v-model="selectedItem.name"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          outlined
                          :menu-props="{ maxHeight: '300' }"
                          label="Parent"
                          v-model="selectedItem.parentId"
                          :items="items"
                          item-value="id"
                          item-text="name"
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          outlined
                          :menu-props="{ maxHeight: '300' }"
                          label="Responsable"
                          v-model="selectedItem.userId"
                          :items="users"
                          item-value="id"
                          item-text="fullname"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          outlined
                          :menu-props="{ maxHeight: '300' }"
                          label="Métier"
                          v-model="selectedItem.typeId"
                          :items="types"
                          item-value="id"
                          item-text="name"
                        ></v-select>
                      </v-col>
                      <v-col cols="6" offset-md="6" class="text-right">
                        <v-btn color="primary" @click="saveServices()">Enregistrer</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card-title>Métiers </v-card-title>
          <v-card-text>
            <div style="position: relative">
              <v-btn
                color="primary"
                fab
                small
                class="ml-2 listbox--actions-add"
                @click="
                  jobItem = { name: '', icon: '', infrastructure: '', crossellingActive: false, color: null, active: true, bonus: false };
                  editionJobModal = true;
                "
                ><v-icon small>fa-plus fa-fw</v-icon></v-btn
              >

              <modal v-model="editionJobModal" max-width="720">
                <template v-slot:header>
                  <strong class="text-h5 mb-0 text-uppercase">{{ jobItem.id ? "Edition" : "Ajout" }} métier</strong>
                  <br /><small class="mt-0">{{ jobItem.name ? jobItem.name : "" }}</small>
                </template>
                <v-row>
                  <v-col cols="6">
                    <v-text-field outlined label="Nom" v-model="jobItem.name"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-select outlined :menu-props="{ maxHeight: '300' }" v-model="jobItem.color" label="Couleur" :items="colors">
                      <template v-slot:selection="{ item }">
                        <v-chip small label :color="item">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-chip>
                      </template>
                      <template v-slot:item="{ active, item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <v-chip small label :color="item">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-chip>
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select outlined :menu-props="{ maxHeight: '300' }" v-model="jobItem.icon" label="Icone" :items="icons">
                      <template v-slot:selection="{ item }">
                        <v-icon small label>{{ item }} fa-fw</v-icon>
                      </template>
                      <template v-slot:item="{ active, item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <v-icon label small class="mr-3">{{ item }} fa-fw</v-icon><span class="grey--text font-italic">{{ item }}</span>
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field outlined v-model="jobItem.delayProspect" type="number" label="Délais Prospect"></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-switch v-model="jobItem.crossellingActive" label="Cross-selling"></v-switch>
                  </v-col>
                  <v-col cols="4">
                    <v-switch v-model="jobItem.infrastructure" label="Parc"></v-switch>
                  </v-col>
                  <v-col cols="3">
                    <v-switch v-model="jobItem.bonus" label="Bonus" v-if="false"></v-switch>
                  </v-col>
                </v-row>
                <template v-slot:actions>
                  <v-btn color="primary" small @click="saveJob()" elevation="0"> Enregistrer </v-btn>
                  <v-btn text @click="editionJobModal = false"> Fermer </v-btn>
                </template>
              </modal>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-data-table :headers="headers" :items="types" :items-per-page="10" class="elevation-0">
                      <template v-slot:item.color="{ item }">
                        <v-chip label :color="item.color" dark> &nbsp; </v-chip>
                      </template>

                      <template v-slot:item.active="{ item }">
                        <v-icon small :color="item.active ? 'success' : 'error'" dark @click="deactivateJob(item)">
                          {{ item.active ? "fa-check" : "fa-times" }} fa-fw
                        </v-icon>
                      </template>

                      <template v-slot:item.crossellingActive="{ item }">
                        <v-icon small :color="item.crossellingActive ? 'success' : 'error'" dark>
                          {{ item.crossellingActive ? "fa-check" : "fa-times" }} fa-fw
                        </v-icon>
                      </template>

                      <template v-slot:item.crossSellingOrder="{ item }">
                        <div class="d-flex justify-center align-center">
                          <v-chip class="mr-2 text-subtitle-1">{{ item.crossSellingOrder }}</v-chip>
                          <div class="d-flex flex-column justify-center align-center">
                            <v-icon small @click="rankUp(item)" v-if="item.crossSellingOrder != 1"> fa-chevron-up fa-fw </v-icon>
                            <v-icon small @click="rankDown(item)" v-if="item.crossSellingOrder != types.length"> fa-chevron-down fa-fw </v-icon>
                          </div>
                        </div>
                      </template>

                      <template v-slot:item.bonus="{ item }">
                        <v-icon small :color="item.bonus ? 'success' : 'error'" dark> {{ item.bonus ? "fa-check" : "fa-times" }} fa-fw </v-icon>
                      </template>

                      <template v-slot:item.icon="{ item }">
                        <v-icon small>{{ item.icon }} fa-fw</v-icon>
                      </template>

                      <template v-slot:item.infrastructure="{ item }">
                        <v-icon small :color="item.infrastructure ? 'success' : 'error'" dark>
                          {{ item.infrastructure ? "fa-check" : "fa-times" }} fa-fw
                        </v-icon>
                      </template>

                      <template v-slot:item.actions="{ item }">
                        <v-icon small @click="editJob(item)" class="mr-2">fa-edit fa-fw</v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { Finder } from "@jledentu/vue-finder";
import simpleDebounce from "simple-debounce-vue-mixin";
import Modal from "../../components/common/modal";

export default {
  name: "servicesSettings",
  mixins: [simpleDebounce],
  components: {
    Modal,
    Finder,
  },
  data() {
    return {
      jobItem: {},
      icons: ["fa-laptop", "fa-phone", "fa-print", "fa-user-tie", "far fa-bookmark", "fa-glasses", "fa-code", "fab fa-apple", "fa-apple-alt", "far fa-copy"],
      colors: ["#aed581", "#b2dfdb", "#ff7961", "#ffcc80", "#c5cae9", "#81d4fa", "#80deea", "#4db6ac", "#a5d6a7"],
      headers: [
        { text: "Nom", sortable: false, align: "left", value: "name" },
        { text: "Couleur", sortable: false, align: "center", value: "color" },
        { text: "Actif", sortable: false, align: "center", value: "active" },
        { text: "Icone", sortable: false, align: "center", value: "icon" },
        { text: "Parc", sortable: false, align: "center", value: "infrastructure" },
        { text: "Cross-selling", sortable: false, align: "center", value: "crossellingActive" },
        { text: "Ordre", sortable: false, value: "crossSellingOrder", align: "center" },
        { text: "Actions", sortable: false, align: "right", value: "actions" },
      ],
      tab: 0,
      services: [],
      users: [],
      search: "",
      filter: (item) => item.id === this.search,
      items: [],
      selectedItem: null,
      types: [],
      tree: {
        id: "root",
        children: [],
      },
      editionJobModal: false,
    };
  },
  mounted() {
    this.getAllServices();
    this.getUsers();
    this.getTypes();
  },
  methods: {
    saveCrossSellingOrder() {
      this.$axios
        .patch("/api/types/crossSellingOrder/edit", {
          types: this.types.map((item) => ({ type: item.id, crossSellingOrder: item.crossSellingOrder })),
        })
        .then(() => {
          this.$simpleToast.success("Métier", "L'ordre a bien été enregistré");
          this.getTypes();
        });
    },
    saveServices() {
      if (this.selectedItem.typeId === null || this.selectedItem.userId === null) {
        this.$simpleToast.error("Métier", "Le métier doit être renseigné");
        return;
      }
      if (this.selectedItem.id) {
        this.$axios.patch("/api/services/" + this.selectedItem.id, { data: this.selectedItem }).then(() => {
          this.$simpleToast.success("Métier", "L'élément a bien été enregistré");
          this.selectedItem = null;
          this.getAllServices();
        });
      } else {
        this.$axios.post("/api/services", { data: this.selectedItem }).then(() => {
          this.$simpleToast.success("Service", "L'élément a bien été ajouté");
          this.selectedItem = null;
          this.getAllServices();
        });
      }
    },
    saveJob() {
      if (this.jobItem.id) {
        this.$axios.patch("/api/types/" + this.jobItem.id, { data: this.jobItem }).then(() => {
          this.$simpleToast.success("Métier", "L'élément a bien été modifié");
          this.getTypes();
          this.editionJobModal = false;
        });
      } else {
        this.$axios.post("/api/types", { data: this.jobItem }).then(() => {
          this.$simpleToast.success("Métier", "L'élément a bien été ajouté");
          this.getTypes();
          this.editionJobModal = false;
        });
      }
    },
    deactivateJob(item) {
      this.$axios.patch("/api/types/activation/" + item.id).then(() => {
        this.$simpleToast.success("Métier", "L'élément a bien été modifié");
        this.getTypes();
      });
    },
    editJob(item) {
      this.jobItem = JSON.parse(JSON.stringify(item));
      this.editionJobModal = true;
    },
    getTypes() {
      this.$axios("/api/types").then((resp) => {
        this.types = resp.data.types;
        this.sortCrossSelling();
      });
    },
    sortCrossSelling() {
      this.types.sort((a, b) => a.crossSellingOrder - b.crossSellingOrder);
    },

    getUsers() {
      this.$axios("/api/users", { params: { all: true, limit: false } }).then((resp) => {
        this.users = resp.data;
      });
    },
    onExpand({ expanded }) {
      this.items.forEach((item) => {
        if (item.id == expanded[expanded.length - 1]) {
          this.selectedItem = item;
        }
      });
    },
    filterFunction(item) {
      if (this.search.length > 0) {
        return item.label.includes(this.search);
      } else {
        return true;
      }
    },
    rankUp(item) {
      item.crossSellingOrder--;
      let currIndex = this.types.indexOf(item);
      this.types[currIndex - 1].crossSellingOrder++;
      this.sortCrossSelling();
      this.saveCrossSellingOrder();
    },
    rankDown(item) {
      item.crossSellingOrder++;
      let currIndex = this.types.indexOf(item);
      this.types[currIndex + 1].crossSellingOrder--;
      this.sortCrossSelling();
      this.saveCrossSellingOrder();
    },
    getAllServices() {
      let tree = {
        id: "root",
        label: "index",
        children: [],
      };

      this.$axios("/api/services").then((resp) => {
        let tmp = {};
        this.items = resp.data;
        resp.data.forEach((item) => {
          if (item.parentId === null && !Object.keys(tmp).includes(item.parentId)) {
            tmp[parseInt(item.id)] = { item: item, children: [] };
          } else {
            if (tmp[item.parentId]) {
              tmp[item.parentId].children.push(item);
            }
          }
        });

        resp.data.forEach((item) => {
          if (item.parentId !== null && tmp[item.parentId] && !tmp[item.parentId].children.includes(item)) {
            tmp[item.parentId].children.push(item);
          }
        });
        Object.keys(tmp).forEach((key) => {
          let it = tmp[key];
          let child = [];
          it.children.forEach((it2) => {
            child.push({ label: it2.name, id: it2.id });
          });
          tree.children.push({ label: it.item.name, id: it.item.id, children: child });
        });
        this.tree = tree;
      });
    },
  },
  watch: {
    search() {
      this.simpleDebounce(() => {
        let tmp = JSON.parse(JSON.stringify(this.tree));
        this.tree = null;
        this.tree = tmp;
      }, 300 /* optional debounce time: default is 300 */);
    },
  },
};
</script>

<style scoped></style>
