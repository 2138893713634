<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <div class="d-flex justify-center align-center flex-column">
        <v-img src="../../assets/logoActuelburo.png" alt="Page introuvable" class="rounded-lg mb-10"></v-img>
        <v-img src="../../assets/no-results.png" alt="Page introuvable" class="rounded-lg mb-16" height="400" width="400"></v-img>
        <h2 class="mb-16 secondary--text display-1">Oops, Cette page n'existe pas...</h2>
        <v-btn color="primary" dark large :to="{ path: '/' }">Retour à l'accueil</v-btn>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "PageNotFound",
  components: {},
};
</script>

<style scoped></style>
