<template>
  <MaterialCard class="v-card-profile mt-10" :color="'primary'">
    <modal v-model="modalEdit" max-width="1024px">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Edition contact</strong>
        <br /><small class="mt-0">
          <template v-if="contact"> {{ contact.contact.firstname }} {{ contact.contact.lastname }}</template>
        </small>
      </template>
      <v-row v-if="contact">
        <v-col cols="12">
          <v-alert v-if="!singleContact && singleContactId !== contact.contact.id" color="error" outlined small prominent dense text type="error">
            Un contact existe déjà avec cet email.
          </v-alert>
        </v-col>
        <v-col cols="6" class="ma-0 mt-3">
          <v-row>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-select outlined :items="civilities" v-model="contact.contact.civilityId" item-text="name" item-value="id" label="Civilité"></v-select>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-text-field outlined v-model="contact.contact.firstname" label="Prénom"></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-text-field outlined v-model="contact.contact.lastname" label="Nom"></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0" v-if="false">
              <v-row>
                <v-text-field outlined v-model="contact.role" label="Fonction"></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-autocomplete
                  outlined
                  :menu-props="{ maxHeight: '300' }"
                  :items="jobs"
                  v-model="contact.job"
                  item-text="name"
                  item-value="id"
                  label="Fonction"
                  :append-outer-icon="contact.job !== null ? 'fa-times' : ''"
                  @click:append-outer="contact.job = null"
                  item-per-page="5"
                ></v-autocomplete>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-textarea outlined v-model="contact.contact.note" label="Note"></v-textarea>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="ma-0">
          <v-col cols="12" class="ma-0">
            <v-row>
              <v-col cols="12" class="ma-0">
                <v-row>
                  <v-text-field outlined v-model="contact.contact.email" label="E-mail"></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0">
                <v-row>
                  <v-text-field outlined v-model="contact.contact.phone" label="Téléphone fixe"></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0">
                <v-row>
                  <v-text-field outlined v-model="contact.contact.phone2" label="Téléphone portable"></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0" v-if="false">
                <v-row>
                  <v-text-field outlined v-model="contact.contact.skype" label="Skype"></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0" v-if="contact.customers.length > 0">
                <span class="text-overline">Associations</span>
                <v-divider></v-divider>
                <v-col cols="12" class="pb-0" v-for="(itm, key) in contact.customers" :key="key" @click="loadCustomer(itm)">
                  <v-chip small label style="cursor: pointer">
                    {{ itm.name }}
                  </v-chip>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn
          :disabled="!singleContact && singleContactId !== contact.contact.id"
          variant="flat"
          color="primary"
          elevation="0"
          class="mr-4"
          @click="validateEdit"
        >
          Enregistrer
        </v-btn>
        <v-btn color="secondary" text class="mr-4" @click="modalEdit = false"> Fermer </v-btn>
      </template>
    </modal>
    <ConcurrentFormCustomers
      v-model="modalAddConcurrent"
      concurrent
      link
      @linkconcu="prepareLink"
      :origin="this.customer.customer.isConcurrent"
      @refresh="$emit('refresh-all', true)"
    ></ConcurrentFormCustomers>
    <modal v-model="modalLinkConcurrent" max-width="1024">
      <v-row>
        <v-col cols="12" class="pa-0 py-1 ma-0 mt-3">
          <v-select
            :menu-props="{ maxHeight: '300' }"
            class="pa-0 ma-0"
            v-model="linkType"
            label="Métier"
            item-value="id"
            item-text="name"
            :items="types"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <selectConcurrents v-model="addingConcurent" :icon="false" label="Rechercher un concurrent"></selectConcurrents>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-col cols="12" class="text-right">
          <v-btn
            @click="
              addingConcurent = null;
              modalLinkConcurrent = false;
            "
            text
            color="error"
            >FERMER</v-btn
          ><v-btn color="primary" @click="linkConcurrent" :disabled="!linkType || !addingConcurent">LIER</v-btn>
        </v-col>
      </template>
    </modal>
    <template v-slot:heading>
      <v-tabs v-if="isReady" v-model="tabs" background-color="transparent" slider-color="white" :vertical="isMobile()">
        <v-tab class="mr-3">
          <v-row class="pa-0 ma-0">
            <v-col cols="2">
              <v-icon small class="mr-2">fa-fw fa-users</v-icon>
            </v-col>
            <v-col cols="9"><v-badge color="secondary" :content="maxContact ? maxContact : '0'"> Contacts </v-badge> </v-col>
          </v-row>
        </v-tab>
        <v-tab class="mr-3">
          <v-row class="pa-0 ma-0">
            <v-col cols="2">
              <v-icon small class="mr-2">fa-fw fa-user-secret</v-icon>
            </v-col>
            <v-col cols="9">
              <v-badge color="secondary" :content="customer.concurrent.length > 0 ? customer.concurrent.length : '0'"> Nos Concurrents </v-badge>
            </v-col>
          </v-row>
        </v-tab>
        <v-tab class="mr-3" v-if="readyClient && clients.length > 0">
          <v-row class="pa-0 ma-0">
            <v-col cols="2">
              <v-icon small class="mr-2">fa-fw fa-user-check</v-icon>
            </v-col>
            <v-col cols="9">
              <v-badge color="secondary" :content="clients.length > 0 ? clients.length : '0'"> Clients </v-badge>
            </v-col>
          </v-row>
        </v-tab>
      </v-tabs>
    </template>
    <v-tabs-items v-if="isReady" v-model="tabs" class="transparent">
      <v-tab-item>
        <modal v-model="linkContact">
          <v-autocomplete
            :menu-props="{ maxHeight: '300' }"
            label="Rechercher un contact"
            item-text="fullname"
            item-value="id"
            :items="contactsSearch"
            :search-input.sync="searchText"
            v-model="selectedContact"
          ></v-autocomplete>
          <template v-slot:actions>
            <v-btn text class="mr-4" @click="linkContactSub"> Lier </v-btn>
          </template>
        </modal>
        <modal v-model="addContact" max-width="720">
          <template v-slot:header>
            <strong class="text-h5 mb-0 text-uppercase">Création contact</strong>
          </template>
          <v-alert v-if="!singleContact" color="error" outlined small prominent dense text type="error"> Un contact existe déjà avec cet email. </v-alert>
          <contact-form v-model="newContact"></contact-form>
          <v-row v-if="similar && similar.length > 0">
            <v-col cols="12">
              <v-divider></v-divider>
              <v-chip color="error" class="mt-4">
                <h4>Contacts similaires</h4>
              </v-chip>
            </v-col>
            <v-col cols="6" md="6" v-for="(item, key) in similar" :key="key" class="py-1">
              <SimilarContactCard :item="item" :linkContactConfirm="linkContactConfirm" />
            </v-col>
          </v-row>
          <template v-slot:actions>
            <v-btn variant="flat" color="primary" elevation="0" class="mr-4" :disabled="!singleContact" @click="newContactSub"> Enregistrer </v-btn>

            <v-btn color="secondary" text class="mr-4" @click="addContact = false"> Fermer </v-btn>
          </template>
        </modal>
        <v-card-text>
          <v-row>
            <v-col cols="11">
              <v-text-field v-model="searchContact" append-icon="mdi-magnify" label="Rechercher" single-line hide-details class="py-0"></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn
                color="primary"
                class="addContact"
                small
                fab
                @click="
                  similar = [];
                  singleContact = true;
                  newContact = {
                    note: '',
                    firstname: '',
                    lastname: '',
                    phone: '',
                    fax: '',
                    phone2: '',
                    function: '',
                    email: '',
                    civilityId: null,
                    isFavorite: false,
                    job: null,
                  };
                  addContact = true;
                "
              >
                <v-icon small>fa-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table
            style="width: 100%"
            :headers="headersContact"
            :items="contacts"
            :items-per-page="limit"
            :page="page"
            :search="searchContact"
            :server-items-length="maxContact"
            @update:items-per-page="setItemMax"
            @update:page="setPage"
            @click:row="openEdit"
            :footer-props="{
              showFirstLastPage: true,
              itemsPerPageOptions: [5, 10, 15, -1],
              firstIcon: 'fa-angle-double-left',
              lastIcon: 'fa-angle-double-right',
              prevIcon: 'fa-angle-left',
              nextIcon: 'fa-angle-right',
            }"
          >
            <template v-slot:item.isFavorite="{ item }">
              <v-icon
                small
                class="toggleFavoriteBtn"
                :color="item.isFavorite ? 'yellow darken-2' : ''"
                @click.stop="
                  () => {
                    item.isFavorite = !item.isFavorite;
                    toggleFavorite(item.origin);
                  }
                "
                >fa-star</v-icon
              >
            </template>

            <template v-slot:item.phone="{ item }">
              <div v-if="item.phone" class="text-no-wrap">
                <v-icon x-small class="mr-1">fa-fw fa-phone</v-icon><a :href="'tel:' + item.phone">{{ item.phone }}</a>
              </div>
              <div v-if="item.phone2" class="text-no-wrap">
                <v-icon x-small class="mr-1">fa-fw fa-mobile-alt</v-icon><a :href="'tel:' + item.phone2">{{ item.phone2 }}</a>
              </div>
            </template>

            <template v-slot:item.email="{ item }">
              <a :href="'mailto:' + item.email">{{ item.email }}</a>
            </template>

            <template v-slot:item.function="{ item }">
              <template v-if="item.job">{{ item.job.name }}</template>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-if="item.origin.contact.note && item.origin.contact.note.length > 0">
                    <v-menu open-on-hover :close-on-content-click="false" max-width="250" left offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-title v-bind="attrs" v-on="on"><v-icon small class="mr-1">fa-fw fa-comment</v-icon>Note</v-list-item-title>
                      </template>
                      <v-card elevation="0">
                        <v-card-title class="py-2 grey--text text--darken-3 text-uppercase subtitle-1"> Note </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <p class="text-caption">{{ item.origin.contact.note }}</p>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item>
                  <v-list-item>
                    <v-menu open-on-hover :close-on-content-click="false" v-if="item.origin.contact" max-width="350" min-width="350" left offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-title v-bind="attrs" v-on="on"><v-icon small class="mr-1">fa-fw fa-edit</v-icon>Historique</v-list-item-title>
                      </template>
                      <v-card elevation="0">
                        <v-card-title class="py-2 grey--text text--darken-3 text-uppercase subtitle-1"> Historique éditions </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <span>
                            <div class="my-1" v-if="item.origin.contact.createdAt">
                              <span v-if="item.origin.contact.createdUser">
                                <UserAvatar :userId="item.origin.contact.createdUser.id" size="35" class="mr-2" />
                              </span>

                              <span v-else>
                                <v-avatar size="35" class="mr-2"> <img src="/images/users/nopic.png" /> </v-avatar
                              ></span>
                              Création le {{ dayjs(item.origin.contact.createdAt).format("DD/MM/YYYY à HH:mm") }}
                            </div>
                            <div v-if="item.origin.contact.updatedAt">
                              <span v-if="item.origin.contact.updatedUser">
                                <UserAvatar :userId="item.origin.contact.updatedUser.id" size="35" class="mr-2" />
                              </span>
                              <span v-else>
                                <v-avatar size="35" class="mr-2"> <img src="/images/users/nopic.png" /> </v-avatar
                              ></span>
                              Dernière modification le {{ dayjs(item.origin.contact.updatedAt).format("DD/MM/YYYY à HH:mm") }}
                            </div>
                          </span>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-list-item>
                  <v-list-item @click="openEmails(item)" v-if="item?.emails?.length > 0">
                    <v-list-item-title><v-icon small class="mr-1">fa-fw fa-envelope</v-icon>Emails</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click.stop="removeContact(item.origin.id)">
                    <v-list-item-title><v-icon small class="mr-1 removeContactBtn">fa-fw fa-trash</v-icon>Supprimer</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <div style="opacity: 0">CONCU</div>
          <v-row>
            <v-col cols="10">
              <v-text-field v-model="searchContact" append-icon="mdi-magnify" label="Rechercher" single-line hide-details class="py-0"></v-text-field>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-btn fab color="primary" small @click="modalAddConcurrent = true">
                <v-icon small>fa-fw fa-plus</v-icon>
              </v-btn>

              <v-btn
                fab
                color="primary"
                class="ml-3 linkConcurrentBtn"
                small
                @click="
                  addingConcurent = null;
                  linkType = null;
                  modalLinkConcurrent = true;
                "
              >
                <v-icon small>fa-fw fa-link</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            style="width: 100%"
            :headers="headersConcu"
            :items-per-page="5"
            @click:row="loadConcurrent"
            :items="customer.concurrent"
            :page="pageConcu"
            @update:items-per-page="setItemMaxConcu"
            @update:page="setPageConcu"
            :footer-props="{
              showFirstLastPage: true,
              itemsPerPageOptions: [5, 10, 15, -1],
              firstIcon: 'fa-angle-double-left',
              lastIcon: 'fa-angle-double-right',
              prevIcon: 'fa-angle-left',
              nextIcon: 'fa-angle-right',
            }"
          >
            <template v-slot:item.indicateur="{ item }">
              <v-tooltip bottom v-if="item.out">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-if="item.out" @click="setIn(item.idRelation)" v-bind="attrs" v-on="on" color="error"> fa-sign-out-alt </v-icon>
                </template>
                <span>Sortant</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.in">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-if="item.in" @click="setOut(item.idRelation)" v-bind="attrs" v-on="on" color="success" class="ml-2">fa-sign-in-alt</v-icon>
                </template>
                <span>Entrant</span>
              </v-tooltip>
            </template>
            <template v-slot:item.dateUpdate="{ item }">
              <template v-if="item.dateUpdate">{{ dayjs(item.dateUpdate).format("DD/MM/YYYY à HH:mm") }}</template>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn text small @click.stop="removeConcu(item.idRelation)" class="removeConcurrentBtn"><v-icon small>fa-trash</v-icon></v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <v-row>
            <v-col cols="11">
              <v-text-field v-model="searchContact" append-icon="mdi-magnify" label="Rechercher" single-line hide-details class="py-0"></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-menu offset-y v-if="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" :color="'primary'" small><v-icon color="white" small>fa-plus</v-icon></v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title @click="modalAddConcurrent = true" style="cursor: pointer"
                      ><v-icon class="mr-1" x-small>fa-plus</v-icon> Ajouter</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title
                      @click="
                        addingConcurent = null;
                        linkType = null;
                        modalLinkConcurrent = true;
                      "
                      style="cursor: pointer"
                      ><v-icon class="mr-1" x-small>fa-link</v-icon> Lier</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-data-table style="width: 100%" :headers="headersConcu" :items-per-page="5" :items="clients" @click:row="loadCustomer">
            <template v-slot:item.indicateur="{ item }">
              <v-tooltip bottom v-if="item.out">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-if="item.out" @click="setIn(item.idRelation)" v-bind="attrs" v-on="on" color="error">fa-sign-out-alt</v-icon>
                </template>
                <span>Sortant</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.in">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-if="item.in" @click="setOut(item.idRelation)" v-bind="attrs" v-on="on" color="success" class="ml-2">fa-sign-in-alt</v-icon>
                </template>
                <span>Entrant</span>
              </v-tooltip>
            </template>
            <template v-slot:item.dateUpdate="{ item }">
              <template v-if="item.dateUpdate">{{ dayjs(item.dateUpdate).format("DD/MM/YYYY à HH:mm") }}</template>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon @click="loadCustomer(item)" v-bind="attrs" v-on="on" class="ml-2">fa-eye</v-icon>
                </template>
                <span>Voir la fiche</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <view-email-contact v-model="modalEmail" :contact="contact" :emails="selectedContactEmails"></view-email-contact>
  </MaterialCard>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import ConcurrentFormCustomers from "./concurrentFormCustomers";
import MaterialCard from "../../components/common/MaterialCard";
import Modal from "../../components/common/modal";
import ContactForm from "../contacts/contactForm";
import SimilarContactCard from "../../components/common/SimilarContactCard.vue";
import Tools from "../../mixins/Tools";
import MobileDetection from "@/mixins/MobileDetection";
import UserAvatar from "../../components/user/UserAvatar";
import selectConcurrents from "../../components/concurrents/selectConcurrents.vue";
import viewEmailContact from "../contacts/viewEmailContact.vue";

export default {
  name: "contactCustomers",
  components: { ContactForm, ConcurrentFormCustomers, Modal, MaterialCard, UserAvatar, SimilarContactCard, selectConcurrents, viewEmailContact },
  mixins: [simpleDebounce, Tools, MobileDetection],
  props: {
    value: {
      default: {},
    },
    reloadContact: {
      default: false,
    },
  },
  data() {
    return {
      allConcurrents: [],
      singleContact: true,
      singleContactId: null,
      newContact: {
        customerId: null,
      },
      readyClient: false,
      page: 1,
      limit: 5,
      similar: [],
      searchText: "",
      contactsSearch: [],
      maxContact: 0,
      selectedContact: null,
      addContact: false,
      isReady: false,
      linkContact: false,
      pageConcu: 1,
      maxConcu: 10,
      clients: [],
      jobs: [],
      search: {
        name: "",
        isIndirect: null,
        isKey1: null,
        isKey2: null,
        isKey3: null,
        isKey4: null,
        siret: null,
        postalCode: null,
        city: null,
        sector: null,
        phone: null,
        ctNumAb: null,
        ape: null,
        sortedBy: null,
        sortedDesc: false,
      },
      isLoading: false,
      modalEmail: false,
      selectedContactEmails: [],
      modalItemInfo: null,
      addingConcurent: null,
      customer: this.value,
      civilities: [
        {
          id: 0,
          name: "Monsieur",
        },
        {
          id: 1,
          name: "Madame",
        },
        {
          id: 2,
          name: "Mademoiselle",
        },
      ],
      modalEdit: false,
      modalAddConcurrent: false,
      modalLinkConcurrent: false,
      contact: null,
      tabs: 0,
      headersContact: [
        {
          text: "Fav",
          value: "isFavorite",
        },
        { text: "Nom Prénom", value: "name" },
        { text: "Fonction", value: "function" },
        { text: "Email", value: "email" },
        { text: "Téléphone", value: "phone" },
        { text: "Actions", value: "actions", align: "end" },
      ],
      headersConcu: [
        { text: "Nom", value: "name" },
        { text: "Secteur", value: "type" },
        { text: "Indicateur", value: "indicateur" },
        { text: "Dernière mise à jour", value: "dateUpdate" },
        { text: "Action", value: "action", align: "end" },
      ],
      contacts: [],
      concurrents: this.value.concurrent,
      searchContact: "",
      types: [],
      linkType: null,
    };
  },
  mounted() {
    this.getContact();
  },
  methods: {
    async removeConcu(id) {
      let res = await this.$confirm("Êtes-vous sûr?");
      if (res) {
        this.$axios.delete("/api/customers/concurrent/" + id).then(() => {
          this.getContact();
          this.$simpleToast.success("Concurrents", "L'élément a bien été supprimé");
          this.$emit("refresh-all", true);
        });
      }
    },
    async getAllConcurrents() {
      this.$axios.get("/api/concurrents/list", { params: { page: 1, search: this.search, limit: 5, allAgency: false, allUser: true } }).then((resp) => {
        this.allConcurrents = resp.data.data;
      });
    },
    setItemMax(event) {
      this.limit = event;
    },
    setItemMaxConcu(event) {
      this.maxConcu = event;
    },
    setPage(event) {
      this.page = event;
    },
    setPageConcu(event) {
      this.pageConcu = event;
    },
    prepareLink(alreadySiret) {
      this.search.name = alreadySiret.name;
      this.addingConcurent = alreadySiret.id;
      this.modalAddConcurrent = false;
      this.modalLinkConcurrent = true;
    },
    async getJobs() {
      await this.$axios("/api/jobs").then((resp) => {
        this.jobs = resp.data;
      });
      this.isReady = true;
      return;
    },
    newContactSub() {
      this.newContact.customerId = this.$route.params.id;
      this.$axios.post("/api/contacts", { data: this.newContact }).then(() => {
        this.getContact();
        this.$simpleToast.success("Contacts", "Le contact a bien été ajouté");
        this.addContact = false;
      });
    },
    openEdit(item) {
      this.contact = JSON.parse(JSON.stringify(item.origin));
      this.contact.isFavorite = item.isFavorite;
      this.contact.job = this.contact.job ? this.contact.job.id : null;
      this.contact.customers = item.customers;
      this.contact.currentCustomer = parseInt(this.$route.params.id);
      this.modalEdit = true;
    },
    openEmails(item) {
      this.loading = true;
      if (item.id) {
        this.$axios(`/api/contacts/email/linked/${item.id}`)
          .then((res) => {
            this.selectedContactEmails = res.data;
            this.modalEmail = true;
          })
          .catch(() => {
            this.$simpleToast.error("Erreur", "Une erreur est survenue lors de la récupération des emails");
          });
      }
    },
    setOut(id) {
      this.$axios.patch("/api/customers/concurrent/" + id, { in: false, out: true }).then(() => {
        this.$emit("refresh-all", true);

        this.$simpleToast.success("Concurrents", "L'élément a bien été mis à jour");
      });
    },
    setIn(id) {
      this.$axios.patch("/api/customers/concurrent/" + id, { in: true, out: false }).then(() => {
        this.$emit("refresh-all", true);
        this.$simpleToast.success("Concurrents", "L'élément a bien été mis à jour");
      });
    },
    async getTypes() {
      await this.$axios("/api/types/active").then((resp) => {
        this.types = resp.data.types;
      });
      return;
    },
    linkConcurrent() {
      if (this.linkType === null || this.addingConcurent === null) {
        this.$simpleToast.error("Concurrents", "Un des champs est vide");
        return;
      }
      this.$axios
        .post("/api/customers/concurrent", {
          concurrent: this.addingConcurent,
          customer: this.$route.params.id,
          type: this.linkType,
        })
        .then(() => {
          this.$emit("refresh-all", true);
          this.$simpleToast.success("Concurrents", "Le concurrent a été lié");
          this.modalLinkConcurrent = false;
        });
    },
    loadCustomer(row) {
      if (this.isGranted("CUSTOMERS_VIEW")) {
        this.$router.push({ name: "Client", params: { id: row.id } });
      }
    },
    loadConcurrent(row) {
      sessionStorage.setItem("concurrentModalTarget", JSON.stringify(row.name));

      if (this.isGranted("CUSTOMERS_VIEW")) {
        this.$router.push({ name: "Concurrents" });
      }
    },

    async removeContact(id) {
      const res = await this.$confirm("Êtes-vous sur ?", { title: "Confirmation", color: "primary" });
      if (res) {
        this.$axios.delete("/api/customers/contact/" + id).then(() => {
          this.$emit("refresh-all", true);
          this.$simpleToast.success("Contacts", "Le contact a été supprimé");
        });
      }
    },
    validateEdit() {
      this.$axios.patch("/api/customers/contact/" + this.contact.id, { contact: this.contact }).then(() => {
        this.$emit("refresh-all", true);
        this.modalEdit = false;
        this.$simpleToast.success("Contacts", "Le contact a été modifié");
      });
    },
    toggleFavorite(contact) {
      if (contact.job) {
        contact.job = contact.job.id;
      }
      this.$axios
        .patch("/api/customers/contact/" + contact.id, {
          contact: { ...contact, currentCustomer: parseInt(this.$route.params.id), favorite: contact.favorite !== null ? !contact.favorite : true },

          role: this.role,
        })
        .then(() => {
          this.$emit("refresh-all", true);

          this.$simpleToast.success("Contacts", "Le contact a été modifié");
        });
    },
    async getContact() {
      this.contacts = [];
      await this.$axios("/api/customers/" + this.$route.params.id + "/contacts", {
        params: { search: this.searchContact, page: this.page, limit: this.limit },
      }).then((resp) => {
        this.maxContact = resp.data.max;
        resp.data.data.forEach((item) => {
          this.contacts.push({
            name: item.contact.contact.lastname + " " + item.contact.contact.firstname,
            email: item.contact.contact.email,
            emails: item.contact.contact.emails,
            isFavorite: item.contact.favorite,
            function: item.contact.role,
            job: item.contact.job,
            phone: item.contact.contact.phone,
            phone2: item.contact.contact.phone2,
            origin: item.contact,
            customers: item.customers,
            id: item.contact.contact.id,
          });
        });
      });
      await this.getClientInit();
      await this.getTypes();
      await this.getJobs();
    },
    async getClientInit() {
      this.clients = [];

      await this.$axios("/api/customers/" + this.$route.params.id + "/clients").then((resp) => {
        this.clients = resp.data;
        this.readyClient = true;
        this.$forceUpdate();
      });
      return;
    },
    getContactsSearch(override = false) {
      let search = {
        lastname: this.searchText,
        firstname: "",
        function: "",
        phone: "",
        phone2: "",
        email: "",
        sortedBy: null,
        sortedDesc: false,
      };

      if (override) {
        search = this.newContact;
        search.sortedBy = null;
        search.sortedDesc = false;
      }
      let tmp = [];
      this.$axios("/api/contacts", { params: { search, limit: 999 } }).then((resp) => {
        if (override && resp.data.max < 20) {
          this.similar = resp.data.data;
        }
        resp.data.data.forEach((item) => {
          tmp.push({ id: item.contact.id, fullname: item.contact.firstname + " " + item.contact.lastname });
        });
        this.contactsSearch = tmp;
      });
    },
    async linkContactConfirm(id) {
      let res = await this.$confirm("Voulez-vous lier ce contact ?");

      if (res) {
        this.selectedContact = id;
        this.linkContactSub();
        this.addContact = false;
      }
    },
    linkContactSub() {
      this.$axios.post("/api/contacts/" + this.selectedContact + "/link", { customer: this.$route.params.id }).then(() => {
        this.$simpleToast.success("Contacts", "Le contact a bien été lié");
        this.$emit("refresh-all", true);
        //this.getContact();
        this.linkContact = false;
      });
    },
    getSingleEmail(email) {
      this.$axios("/api/contacts/email", { params: { email: email } }).then((resp) => {
        this.singleContact = !resp.data;
        this.singleContactId = resp.data ? resp.data : null;
      });
    },
  },
  watch: {
    reloadContact() {
      if (this.reloadContact) {
        this.getContact();
      }
      this.reloadContact = false;
    },
    searchContact() {
      this.simpleDebounce(() => {
        this.getContact();
        this.page = 1;
      }, 500);
    },
    limit: "getContact",
    page: "getContact",
    pageConcu: "getContact",
    "contact.contact.email"() {
      this.simpleDebounce(() => {
        this.getSingleEmail(this.contact.contact.email);
      }, 500);
    },
    "newContact.email"() {
      this.simpleDebounce(() => {
        this.getSingleEmail(this.newContact.email);
        this.getContactsSearch(true);
      }, 500);
    },
    "newContact.firstname"() {
      this.simpleDebounce(() => {
        this.getContactsSearch(true);
      }, 500);
    },
    "newContact.lastname"() {
      this.simpleDebounce(() => {
        this.getContactsSearch(true);
      }, 500);
    },
    searchText() {
      this.simpleDebounce(() => {
        this.getContactsSearch();
      }, 500);
    },
    "search.name"() {
      this.simpleDebounce(() => {
        this.getAllConcurrents();
      }, 500);
    },
    value() {
      this.customer = this.value;
      this.getContact();
      this.getClientInit();
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped></style>
