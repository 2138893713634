<template>
  <v-container fluid class="pb-0 mb-0 py-0 my-0">
    <v-card style="z-index: 99" :elevation="0" :flat="true">
      <div style="position: relative">
        <v-chip
          color="orange"
          text-color="white"
          style="position: absolute; bottom: 20px; right: 10px; z-index: 9000"
          close
          v-if="underDev"
          @click:close="underDev = false"
          >Outil en cours de développement</v-chip
        >
        <div style="width: 20%; position: absolute; top: 10px; right: 10px; z-index: 9000">
          <v-card style="background-color: rgba(255, 255, 255, 0.95); width: 100%">
            <v-toolbar color="primary " dark elevation="0">
              <v-row class="p-0 m-0 text-left">
                <v-col cols="12" large class="text-uppercase text-body-1"> Recherche </v-col>
              </v-row>
            </v-toolbar>
            <v-col cols="12">
              <list-user :clearable="true" :multiple="false" @selectChange="setAsUser" includeAll :user="$store.state.user.id"></list-user>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="search.type"
                :items="types"
                item-text="name"
                item-value="id"
                label="Choisir une infrastructure"
                @change="getCustomers"
                class="px-3"
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="search.name" label="Nom" class="px-3" />
            </v-col>
            <v-card-actions class="justify-end mr-2">
              <LoaderActuelburo v-if="loading" />
              <span v-else class="grey--text text-overline">{{ customersList.length }} résultats</span>
            </v-card-actions>
          </v-card>
          <v-card class="mt-2" v-if="filteredCustomers.length">
            <v-simple-table dense style="max-height: 250px; overflow-y: auto">
              <tbody>
                <tr v-for="customer in filteredCustomers" :key="customer.id" @click="openPopup(customer)">
                  <td>{{ customer.name }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </div>
        <l-map ref="map" style="height: calc(95vh - 70px)" :zoom="10" :center="center" @moveend="updateVisibleMarkers" @zoomend="updateVisibleMarkers">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-geo-json v-if="departementGeo" :geojson="departementGeo" :options-style="styleDepartement" />
          <l-geo-json v-if="canton && init" :geojson="canton" :options-style="style" :options="options" />
          <l-marker-cluster :options="clusterOptions">
            <l-marker v-for="(customer, index) in visibleCustomers" :key="index" :lat-lng="[parseFloat(customer.gps.lat), parseFloat(customer.gps.lng)]">
              <l-popup style="width: 400px; z-index: 99999" :ref="'popup-' + customer.entrepriseId">
                <v-card elevation="1">
                  <v-toolbar color="primary " dark elevation="0">
                    <v-card-title>{{ customer?.name }} </v-card-title>
                  </v-toolbar>
                  <div class="pa-2">
                    <div class="mb-5">
                      <span class="text-button font-weight-medium">Informations</span>
                      <v-divider class="mb-2"></v-divider>
                      <v-row class="font-size-14">
                        <v-col cols="6" class="">
                          <span class="font-weight-bold">TEL : </span>
                          {{ customer?.phone }}
                        </v-col>
                        <v-col cols="6">
                          <span class="font-weight-bold">E-MAIL : </span>
                          <a :href="`mailto:${customer?.email}`">{{ customer?.email }}</a>
                        </v-col>
                      </v-row>
                    </div>
                    <div>
                      <span class="text-button font-weight-medium">Commerciaux</span>
                      <v-divider class="mb-2"></v-divider>
                      <v-row class="pa-4">
                        <v-tooltip bottom v-for="(user, key) in customer.commercials" :key="key">
                          <template v-slot:activator="{ on, attrs }">
                            <UserAvatar v-bind="attrs" v-on="on" :userId="user.id" size="40" class="mr-2" />
                          </template>
                          <span>{{ user.name }}</span>
                        </v-tooltip>
                      </v-row>
                      <v-row class="justify-end mt-5">
                        <v-btn
                          class="text-uppercase font-size-10 self-end mr-4 mb-4"
                          color="primary"
                          elevation="2"
                          small
                          v-if="customer.customerId"
                          @click="$router.push({ name: 'Client', params: { id: customer.customerId } })"
                          >Accéder au compte
                        </v-btn>
                      </v-row>
                    </div>
                  </div>
                </v-card>
              </l-popup>
            </l-marker>
          </l-marker-cluster>
        </l-map>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { LMap, LTileLayer, LGeoJson, LMarker, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";
import axios from "axios";
import LMarkerCluster from "vue2-leaflet-markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import canton from "../../settings/cantons_2015_simpl.json";
import departement from "../../settings/departements.json";
import simpleDebounce from "simple-debounce-vue-mixin";
import ListUser from "../../../components/user/listUser";
import UserAvatar from "../../../components/user/UserAvatar";
import Tools from "../../../mixins/Tools";
import LoaderActuelburo from "../../../components/common/LoaderActuelburo.vue";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "CustomersMap",
  mixins: [simpleDebounce, Tools],
  props: {},
  components: { LMap, LTileLayer, LGeoJson, UserAvatar, LMarker, LPopup, LMarkerCluster, ListUser, LoaderActuelburo },
  data() {
    return {
      cancelTokenSource: axios.CancelToken.source(),
      init: false,
      canton: canton,
      departementGeo: departement,
      fromFeature: this.$route.path === "/map",
      styleDepartement: {
        color: "red",
        fillColor: "transparent",
        dashArray: "5",
        weight: 2,
      },
      styleCanton: {
        color: "blue",
        fillColor: "transparent",
        weight: 2,
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: "&copy; Actuelburo",
      center: [43.7718693, -0.4637913],
      userSelected: null,
      types: [],
      selectedService: 1,
      mouseOn: null,
      cities: {},
      customersList: [],
      visibleCustomers: [],
      loading: false,
      underDev: true,
      search: {
        name: "",
        users: "",
        type: "",
      },
      clusterOptions: {
        maxClusterRadius: 140,
        disableClusteringAtZoom: 16,
      },
    };
  },
  mounted() {
    this.getTypes();
    this.getCities();
    this.getCustomers();
  },
  methods: {
    setAsUser(item) {
      this.userSelected = item;
      this.search.users = item;
    },
    getTypes() {
      this.$axios("/api/types/active").then((resp) => {
        this.types = resp.data.types;
        this.selectedService = this.types[0].id;
      });
    },
    getCities() {
      this.$axios("/api/sectors/cities").then((resp) => {
        let tmp = {};
        resp.data.forEach((item) => {
          if (item.sectorId) {
            if (!tmp[parseInt(item.sectorId)]) {
              tmp[parseInt(item.sectorId)] = [];
            }
            tmp[parseInt(item.sectorId)].push(item);
          }
        });
        this.cities = tmp;
      });
    },
    getCustomers() {
      this.loading = true;
      this.customersList = [];
      this.visibleCustomers = [];
      this.cancelTokenSource.cancel("Operation canceled due to new request.");
      this.cancelTokenSource = axios.CancelToken.source();
      if (!this.search.users && !this.search.type && this.types.length > 0) {
        this.$simpleToast.error("Commercial ou BU", "Vous devez choisir au moins un commercial ou une BU");
      } else {
        this.$axios("/api/customers/fast", {
          cancelToken: this.cancelTokenSource.token,
          params: {
            ...this.search,
          },
        })
          .then((resp) => {
            this.customersList = resp.data.data;
            this.updateVisibleMarkers();
            this.loading = false;
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.log("Request canceled", err.message);
            } else {
              console.log(err);
            }
            this.loading = false;
          });
      }
    },
    getCustomersDebounce() {
      this.simpleDebounce(() => {
        this.getCustomers();
      }, 500);
    },
    async openPopup(item) {
      this.$refs.map.mapObject.setView([parseFloat(item.gps.lat), parseFloat(item.gps.lng)], 15);

      setTimeout(() => {
        const popupRef = this.$refs["popup-" + item.entrepriseId];
        if (popupRef && popupRef[0] && popupRef[0].mapObject) {
          popupRef[0].mapObject.openOn(this.$refs.map.mapObject);
        } else {
          console.error("Popup reference not found or invalid");
        }
      }, 1000);
    },

    updateVisibleMarkers() {
      const bounds = this.$refs.map.mapObject.getBounds();
      this.visibleCustomers = this.filteredCustomers.filter((customer) => {
        if (typeof customer.gps.lat === "string" && typeof customer.gps.lng === "string") {
          const lat = parseFloat(customer.gps.lat);
          const lng = parseFloat(customer.gps.lng);
          return bounds.contains([lat, lng]);
        }
      });
    },
  },

  computed: {
    filteredCustomers() {
      return this.customersList
        .filter((customer) => typeof customer?.gps?.lat === "string" && typeof customer?.gps?.lng === "string")
        .filter((customer) => {
          return !this.search.name || customer.name.toLowerCase().includes(this.search.name.toLowerCase());
        });
    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
  },
  watch: {
    "search.name": "getCustomersDebounce",
    "search.type": "getCustomersDebounce",
    "search.users": "getCustomersDebounce",
  },
};
</script>

<style>
.leaflet-popup-content {
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  padding: 0px !important;
}
.leaflet-popup-content-wrapper {
  background: transparent !important;
}
.leaflet-popup-close-button {
  display: none !important;
}
</style>
