<template>
  <div v-if="isGranted('CONFIGURATIONS_INDEX')">
    <v-container fluid>
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab v-for="(item, key) in items" :key="key">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card background-color="transparent" flat style="z-index: 99">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-container fluid>
                    <v-col cols="12">
                      <v-card elevation="1">
                        <v-toolbar color="primary " dark elevation="0">
                          <v-card-title>Challenges</v-card-title>
                        </v-toolbar>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-select :items="groupsChoice" v-model="settings.GroupChallenge" item-text="text" item-value="id" label="Standard"></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-switch v-model="settings.subscriptions" label="Inclure les abonnements"></v-switch>
                            </v-col>
                            <v-col cols="12" md="6" v-for="(item, key) in settings.bonus" :key="key">
                              <v-switch v-model="settings.bonus[key].active" :label="'Activer les bonus ' + settings.bonus[key].name"></v-switch>
                            </v-col>

                            <v-col cols="12" md="4">
                              <v-text-field v-model="settings.MaxArticle" type="number" label="Nombre maximum d'article dans une pièce"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field v-model="settings.ProspectClientDuration" type="number" label="Nb mois prospect/client"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field v-model="settings.Bonification" type="number" label="Valeur de la bonification"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" v-if="isGranted('COMPAIGN_SETTINGS')">
                      <v-card elevation="1">
                        <v-toolbar color="primary " dark elevation="0">
                          <v-card-title>MailJet</v-card-title>
                        </v-toolbar>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-switch label="Actif" v-model="settings.mailjetEnable"></v-switch>
                            </v-col>
                          </v-row>
                          <v-expand-transition>
                            <div v-show="settings.mailjetEnable">
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    label="Clé API"
                                    v-model="settings.mailjetApiKey"
                                    @click:append="secretAPI = !secretAPI"
                                    :type="secretAPI ? 'password' : ''"
                                    :append-icon="secretAPI ? 'fa-eye' : 'fa-eye-slash'"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    label="Clé Secrete"
                                    @click:append="secretKey = !secretKey"
                                    v-model="settings.mailjetSecretKey"
                                    :type="secretKey ? 'password' : ''"
                                    :append-icon="secretKey ? 'fa-eye' : 'fa-eye-slash'"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field label="Nom de l'expéditeur" v-model="settings.mailjetName"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field label="E-mail" v-model="settings.mailjetSender"></v-text-field>
                                </v-col>
                              </v-row>
                            </div>
                          </v-expand-transition>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-container>
                </v-col>

                <v-col cols="12" md="6">
                  <v-container fluid>
                    <v-col cols="12">
                      <v-card elevation="1">
                        <v-toolbar color="primary " dark elevation="0">
                          <v-card-title>GRC Configuration</v-card-title>
                        </v-toolbar>
                        <v-card-text>
                          <v-row>
                            <v-col cols="6">
                              <v-switch v-model="settings.maintenance" color="error" label="Maintenance"></v-switch>
                            </v-col>
                            <v-col cols="6">
                              <v-switch v-model="settings.helios" label="Helios"></v-switch>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" v-if="isGranted('DATEFISCAL_SETTINGS')">
                      <v-card elevation="1">
                        <v-toolbar color="primary " dark elevation="0">
                          <v-card-title>Année fiscale</v-card-title>
                        </v-toolbar>
                        <v-card-text>
                          <v-row>
                            <v-col cols="6">
                              <v-menu
                                ref="menu1"
                                v-model="menuStart"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="dateStartFormatted"
                                    label="Date de début"
                                    persistent-hint
                                    v-bind="attrs"
                                    @blur="settings.startDateFiscale = parseDate(dateStartFormatted)"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker first-day-of-week="1" v-model="settings.startDateFiscale" no-title @input="menuStart = false"></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="6">
                              <v-menu
                                ref="menu1"
                                v-model="menuEnd"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="dateEndFormatted"
                                    label="Date de fin"
                                    persistent-hint
                                    v-bind="attrs"
                                    @blur="settings.endDateFiscale = parseDate(dateEndFormatted)"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker first-day-of-week="1" v-model="settings.endDateFiscale" no-title @input="menuEnd = false"></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" v-if="isGranted('PROSPECT_SETTINGS')">
                      <v-card elevation="1">
                        <v-toolbar color="primary " dark elevation="0">
                          <v-card-title>Jours de prospection / campagne</v-card-title>
                        </v-toolbar>
                        <v-card-text>
                          <v-row>
                            <v-col cols="2">
                              <v-switch label="Lundi" value="0" v-model="settings.dayProspection"></v-switch>
                            </v-col>
                            <v-col cols="2">
                              <v-switch label="Mardi" value="1" v-model="settings.dayProspection"></v-switch>
                            </v-col>
                            <v-col cols="2">
                              <v-switch label="Mercredi" value="2" v-model="settings.dayProspection"></v-switch>
                            </v-col>
                            <v-col cols="2">
                              <v-switch label="Jeudi" value="3" v-model="settings.dayProspection"></v-switch>
                            </v-col>
                            <v-col cols="2">
                              <v-switch label="Vendredi" value="4" v-model="settings.dayProspection"></v-switch>
                            </v-col>
                            <v-col cols="2">
                              <v-switch label="Samedi" value="5" v-model="settings.dayProspection"></v-switch>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field type="number" v-model="settings.maxProspection" label="Maximum par jour"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12">
                      <v-card elevation="1">
                        <v-toolbar color="primary " dark elevation="0">
                          <v-card-title>Méthode de vente</v-card-title>
                        </v-toolbar>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="6">
                              <div>
                                <v-text-field v-model="settings.prospectionGoal" type="number" label="Objectif de prospection"></v-text-field>
                              </div>
                              <div>
                                <v-textarea v-model="settings.bebedcComplete" label="Pré-complétion BEBEDC"></v-textarea>
                              </div>
                            </v-col>
                            <v-col cols="6">
                              <div>
                                <v-switch v-model="settings.prospectionPhysical" label="Prospection physique de proximité"></v-switch>
                              </div>
                              <div>
                                <v-text-field v-model="settings.prospectionProximity" type="number" label="Objectif de visite de proximité"></v-text-field>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>

                    <div class="text-right">
                      <v-btn class="mt-2" color="primary" @click="updateSettings">Enregistrer</v-btn>
                    </div>
                  </v-container>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card color="basil" flat style="z-index: 99">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4" v-for="(item, key) in email" :key="key">
                  <v-col cols="12">
                    <v-card elevation="1">
                      <v-toolbar color="primary " dark elevation="0">
                        <v-card-title>{{ item.name }} <v-icon class="ml-2" small @click="openEdit(key)">fa-code</v-icon></v-card-title>
                      </v-toolbar>
                      <v-card-text>
                        <v-text-field outlined label="Titre" v-model="item.title"></v-text-field>

                        <ckeditor :editor="editor" v-model="item.body" :config="editorConfig"></ckeditor>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-col>
              </v-row>

              <div class="text-right">
                <v-btn class="mt-2" @click="updateEmail">Sauvegarder</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <v-card color="basil" flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <v-col cols="12" v-if="settings">
                        <v-card>
                          <v-toolbar color="primary " dark elevation="0">
                            <v-card-title>SAGE 100</v-card-title>
                          </v-toolbar>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12">
                                <v-switch v-model="settings.sage" label="Synchronisation Sage"></v-switch>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field label="Delais synchronisation compte" v-model="settings.delayCustomer"></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field label="Delais synchronisation contact" v-model="settings.delayContact"></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field label="Delais synchronisation abonnements" v-model="settings.delaySubscription"></v-text-field>
                              </v-col>

                              <v-col cols="12" v-if="settings && settings.lastSynchroCusto">
                                <v-icon small :color="settings.errorSageTreated ? 'success' : 'error'">
                                  far {{ settings.errorSageTreated ? "fa-check-circle" : "fa-times-circle" }}
                                  fa-fw
                                </v-icon>
                                Dernière synchronisation des clients :
                                {{ dayjs(settings.lastSynchroCusto).format("DD/MM/YYYY HH:mm") }}
                              </v-col>
                              <v-col cols="12" v-if="settings && settings.lastSynchroContact">
                                <v-icon small :color="settings.errorSageTreated ? 'success' : 'error'">
                                  far {{ settings.errorSageTreated ? "fa-check-circle" : "fa-times-circle" }}
                                  fa-fw
                                </v-icon>
                                Dernière synchronisation des contacts :
                                {{ dayjs(settings.lastSynchroContact).format("DD/MM/YYYY HH:mm") }}
                              </v-col>
                              <v-col cols="12" v-if="settings && settings.LastSynchroSubs">
                                <v-icon small :color="settings.errorSageTreated ? 'success' : 'error'">
                                  far {{ settings.errorSageTreated ? "fa-check-circle" : "fa-times-circle" }}
                                  fa-fw
                                </v-icon>
                                Dernière synchronisation des abonnements :
                                {{ dayjs(settings.LastSynchroSubs).format("DD/MM/YYYY HH:mm") }}
                              </v-col>

                              <v-col cols="12" class="text-right">
                                <v-btn color="error" class="mr-2" @click="deleteError">Supprimer erreur SAGE </v-btn>
                                <v-btn color="primary" @click="saveDelay">Enregistrer</v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-container>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
      <modal v-model="editModal">
        <template v-if="email[editKey]">
          <v-textarea filled v-model="email[editKey].body"></v-textarea>
        </template>
      </modal>
    </v-container>
  </div>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";
import dayjs from "dayjs";

export default {
  mixins: [simpleDebounce, Tools],
  name: "globalSettings",
  components: {
    Modal,
  },
  data() {
    return {
      editKey: null,
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      secretAPI: true,
      secretKey: true,
      tab: null,
      menuStart: false,
      menuEnd: false,
      dateStartFormatted: "",
      dateEndFormatted: "",
      items: ["Général", "E-mails", "ERP"],
      editable: false,
      groupsChoice: [
        {
          id: 0,
          text: "Aucun",
        },
        {
          id: 1,
          text: "Par métier",
        },
      ],
      settings: [],
      email: [],
      editors: [],
      editModal: false,
    };
  },
  mounted() {
    this.getSettings();
    this.getMail();
  },
  methods: {
    async deleteError() {
      let res = await this.$confirm("Êtes-vous sûr ?");

      if (res) {
        this.$axios.delete("/api/settings/errorSage", { delay: this.settings }).then(() => {
          this.getSettings();
          this.$simpleToast.success("Configuration", "L'erreur a été supprimé");
        });
      }
    },
    saveDelay() {
      this.$axios.patch("/api/settings/delay", { delay: this.settings }).then(() => {
        this.getSettings();
        this.$simpleToast.success("Configuration", "Les délais de synchronisation ont été enregistré");
      });
    },
    openEdit(key) {
      this.editKey = key;
      this.editModal = true;
    },
    getMail() {
      this.$axios("/api/emailtype").then((resp) => {
        this.email = resp.data;
        this.editable = true;
      });
    },
    getSettings() {
      this.$axios("/api/settings").then((resp) => {
        resp.data.startDateFiscale =
          resp.data.startDateFiscale !== null ? dayjs(resp.data.startDateFiscale).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD");
        resp.data.endDateFiscale = resp.data.endDateFiscale !== null ? dayjs(resp.data.endDateFiscale).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD");
        this.settings = resp.data;
      });
    },
    updateEmail() {
      if (this.editable) {
        this.$axios.patch("/api/emailtype", { data: this.email }).then(() => {
          this.$simpleToast.success("Configuration", "Les e-mails ont bien été enregistrés");
        });
      }
    },
    updateSettings() {
      if (this.editable) {
        this.$axios.patch("/api/settings", { data: this.settings }).then(() => {
          this.$simpleToast.success("Configuration", "Les paramètres ont bien été mis à jour.");
        });
      }
    },
  },
  watch: {
    "settings.startDateFiscale"() {
      this.dateStartFormatted = this.formatDate(this.settings.startDateFiscale);
    },
    "settings.endDateFiscale"() {
      this.dateEndFormatted = this.formatDate(this.settings.endDateFiscale);
    },
  },
};
</script>

<style scoped></style>
