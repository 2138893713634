<template>
  <div style="margin-top: -25px">
    <Landscape />
    <modal v-model="modalSettings" max-width="1024">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Critères</strong>
      </template>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="4" class="text-right">
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedStart"
                  label="Date de début"
                  persistent-hint
                  prepend-icon="fa-calendar"
                  v-bind="attrs"
                  @blur="dateStart = parseDate(dateFormattedStart)"
                  v-on="on"
                  hide-details
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker first-day-of-week="1" outlined v-model="dateStart" @input="menu1 = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" class="text-left">
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedEnd"
                  label="Date de fin"
                  persistent-hint
                  prepend-icon="fa-calendar"
                  v-bind="attrs"
                  @blur="dateEnd = parseDate(dateFormattedEnd)"
                  v-on="on"
                  outlined
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker first-day-of-week="1" v-model="dateEnd" @input="menu2 = false" outlined></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" class="text-left">
            <v-select
              outlined
              hide-details
              :menu-props="{ maxHeight: '300' }"
              label="Métier"
              :items="services"
              v-model="service"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" class="text-center"> </v-col>
        </v-row>
      </v-container>
      <template v-slot:actions>
        <v-btn color="primary" variant="flat" elevation="0" depressed class="rounded-1 px-3" small @click="loadStats()">Générer </v-btn>

        <v-btn color="secondary" text class="mr-4" @click="modalSettings = false"> Fermer </v-btn>
      </template>
    </modal>

    <v-card elevation="1" :loading="loading">
      <v-toolbar color="primary" elevation="0" dark>
        <v-row class="p-0 m-0 text-left">
          <v-col class="p-0 m-0 text-left">
            <strong class="mb-0 text-uppercase">Statistiques des commerciaux</strong>
            <br /><small class="mt-0 grey--text text--lighten-4">Du {{ dateFormattedStart }} au {{ dateFormattedEnd }}</small>

            <div style="position: absolute; top: 0; left: 50%; background: #78909c; padding: 5px; translate: -50% 0; border-radius: 0 0 5px 5px">
              <v-btn small text @click="modalSettings = true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-filter </v-icon>
                  </template>
                  <span>Critères</span>
                </v-tooltip>
              </v-btn>
              <v-menu content-class="mt-2" :close-on-content-click="false" offset-y right style="z-index: 1111111">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" small>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-cog </v-icon>
                      </template>
                      <span>Colonnes</span>
                    </v-tooltip>
                  </v-btn>
                </template>

                <v-card>
                  <v-toolbar color="primary" dark elevation="0" class="py-0 ma-0" height="45px"> Colonnes visibles </v-toolbar>
                  <v-card-text class="pa-1 pb-3 ma-0" style="min-width: 180px; max-height: calc(100vh - 115px); overflow-y: auto">
                    <v-list>
                      <v-list-item v-for="(column, key) in rowAvailable" :key="key">
                        <v-checkbox v-model="columns" hide-details dense :label="column.title" :value="column"></v-checkbox>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-menu offset-y style="z-index: 1111111">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text small v-bind="attrs" v-on="on">
                    <v-icon small> fa-ellipsis-v fa-fw </v-icon>
                  </v-btn>
                </template>
                <v-list style="z-index: 1111111">
                  <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICS')" @click="$router.push({ name: 'Statistiques Personnelles' })">
                    <v-list-item-title>Mes statistiques</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICSPERS')" @click="$router.push({ name: 'Statistiques BU' })">
                    <v-list-item-title>Statistiques de mon service</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICSAGENCY')" @click="$router.push({ name: 'Statistiques Agence' })">
                    <v-list-item-title>Statistiques de mon agence</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICSCROSSSELLING')" @click="$router.push({ name: 'Cross-Selling' })">
                    <v-list-item-title>Cross-selling</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('EVENTS_EVENTSKEY')" @click="$router.push({ name: 'Relations' })">
                    <v-list-item-title>Relation clients</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('SECTOR_STATISTICS')" @click="$router.push({ name: 'Affectations' })">
                    <v-list-item-title>Affectations</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-toolbar>
      <ve-table
        :loading="loading"
        style="width: 100%"
        :columns="columns"
        :scroll-width="1200"
        :row-style-option="rowStyleOption"
        :cell-style-option="cellStyleOption"
        border-y
        :table-data="tableData"
        :stripe="true"
        setHighlightRow
        :sort-option="sortOption"
        max-height="calc(100vh - 155px)"
      ></ve-table>
    </v-card>
    <p class="mt-3 text-center" v-if="false" style="font-size: 0.65rem">ICI UN TEXTE DE BAS DE PAGE</p>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Modal from "@/components/common/modal.vue";
import Landscape from "../../components/common/Landscape.vue";
import UserAvatar from "../../components/user/UserAvatar.vue";
import Tools from "../../mixins/Tools";
import dayjs from "dayjs";
import PourcentageCell from "./PourcentageCell.vue";

export default {
  name: "commercialStatistics",
  components: { Modal, Landscape, UserAvatar, PourcentageCell },
  mixins: [Tools],
  data() {
    return {
      cellStyleOption: {
        bodyCellClass: ({ column }) => {
          let className = "";
          if (column.title.includes("%")) {
            className += "table-body-cell-pourcentage";
          } else {
            className += "table-body-cell";
          }

          return className;
        },
      },
      services: [],
      rowStyleOption: {
        stripe: true,
      },
      rowAvailable: [],
      service: null,
      virtualScrollOption: {
        enable: true,
      },
      dateStart: this.parseDate(dayjs().subtract(1, "month").format("DD/MM/YYYY")),
      dateFormattedStart: dayjs().subtract(1, "month").format("DD/MM/YYYY"),
      menu1: false,
      menu2: false,
      dateEnd: dayjs().format("YYYY-MM-DD"),
      dateFormattedEnd: this.formatDate(dayjs().format("YYYY-MM-DD")),
      columns: [],
      tableData: [],
      tmpTable: [],
      loading: false,
      modalSettings: false,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
    };
  },
  mounted() {
    this.getServices();
    this.loadStats();
  },
  methods: {
    getServices() {
      this.$axios("/api/services").then((resp) => {
        resp.data.forEach((item) => {
          if (item.parentId !== null) {
            this.services.push(item);
          }
        });
      });
    },
    loadStats() {
      this.loading = "primary darken-2";
      this.$axios("/api/stats/commercial", {
        params: {
          start: this.dateStart,
          end: this.dateEnd,
          service: this.service,
        },
      }).then((resp) => {
        this.modalSettings = false;
        let columns = [];
        columns.push({
          title: "Commerciaux",
          field: "commercial",
          key: "commercial",
          align: "left",
          fixed: "left",
          sortBy: "asc",
          height: 40,
          width: 150,
          renderBodyCell: ({ row, column }) => {
            const text = row[column.field];
            return <UserAvatar userId={row.id} fullname={text} />;
          },
        });
        Object.keys(resp.data.columns).forEach((item) => {
          let obj = {
            title: item,
            key: item,
            field: item,
            children: [],
          };
          Object.keys(resp.data.columns[item]).forEach((field) => {
            let tmp = {
              field: resp.data.columns[item][field],
              align: "right",
              title: field,
              key: resp.data.columns[item][field],
              sortBy: "",
            };
            if (field.indexOf("%") !== -1 && !field.includes("Réussite") && !field.includes("Réal") && !field.includes("Utilisat°")) {
              tmp.renderBodyCell = ({ row, column }) => {
                const text = row[column.field];
                return <PourcentageCell cellData={parseInt(text)} />;
              };
            }
            if (field.indexOf("%") !== -1 && field.includes("Réussite")) {
              tmp.renderBodyCell = ({ row, column }) => {
                const text = row[column.field];

                return <PourcentageCell cellData={parseInt(text)} />;
              };
            }
            if (field.indexOf("%") !== -1 && field.includes("Utilisat°")) {
              tmp.renderBodyCell = ({ row, column }) => {
                const text = row[column.field];

                return <PourcentageCell cellData={parseInt(text)} />;
              };
            }
            if (field.includes("Complét")) {
              tmp.renderBodyCell = ({ row, column }) => {
                const text = row[column.field];

                return <PourcentageCell cellData={parseInt(text)} />;
              };
            }
            if (field.indexOf("%") !== -1 && field.includes("Réal")) {
              tmp.renderBodyCell = ({ row, column }) => {
                const text = row[column.field];
                return <PourcentageCell cellData={parseInt(text)} />;
              };
            }
            obj.children.push(tmp);
          });
          columns.push(obj);
        });

        this.tableData = resp.data.data;
        this.tmpTable = JSON.parse(JSON.stringify(resp.data.data));
        this.columns = columns.slice(0, 6);
        this.rowAvailable = columns.slice(1);

        this.loading = false;
      });
    },
    sortChange(params) {
      let sortField = "";

      for (const [key, value] of Object.entries(params)) {
        if (value) {
          sortField = key;
          break;
        }
      }

      if (params[sortField]) {
        this.tableData.sort((a, b) => {
          if (isNaN(a[sortField])) {
            if (params[sortField] === "asc") {
              return a[sortField].localeCompare(b[sortField]);
            } else if (params[sortField] === "desc") {
              return b[sortField].localeCompare(a[sortField]);
            }
            return a[sortField].localeCompare(b[sortField]);
          } else if (params[sortField]) {
            if (params[sortField] === "asc") {
              return a[sortField] - b[sortField];
            } else if (params[sortField] === "desc") {
              return b[sortField] - a[sortField];
            }

            return 0;
          }
        });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    dateStart() {
      this.dateFormattedStart = this.formatDate(this.dateStart);
    },
    dateEnd() {
      this.dateFormattedEnd = this.formatDate(this.dateEnd);
    },
  },
};
</script>

<style lang="scss">
.ve-table .ve-table-container table.ve-table-content thead.ve-table-header tr.ve-table-header-tr th.ve-table-header-th,
.ve-table-container,
.ve-table-body-td,
.ve-table.ve-table-border-around {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.ve-table td,
.ve-table th {
  font-size: 0.875em !important;
}

.ve-table .ve-table-container table.ve-table-content thead.ve-table-header .ve-table-header-tr .ve-table-header-th .ve-table-sort .ve-table-sort-icon.active {
  color: #607d8b;
}

.bg-warning {
  color: rgba(246, 120, 31, 0.25) !important;
}

.bg-error {
  color: rgba(246, 48, 41, 0.25) !important;
}

.table-body-cell-pourcentage {
  padding: 0 !important;
}
.table-body-cell {
  font-weight: 500;
}
</style>
