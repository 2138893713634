<template>
  <modal v-model="toggleModal" max-width="900px">
    <template v-slot:header v-if="emails.length">
      <v-row>
        <v-col cols="9">
          <h4 class="pa-0 ma-0">
            {{ emails[index]?.sender }} -
            <a class="pa-0 ma-0 text-decoration-underline white--text" style="font-style: italic" :href="'mailto:' + emails[index]?.senderEmail">{{
              emails[index]?.senderEmail
            }}</a>
          </h4>
          <h3 class="pa-0 ma-0">{{ emails[index]?.name }}</h3>
        </v-col>
        <v-col cols="2 d-flex align-end">
          <span class="pa-0 ma-0">{{ dayjs(emails[index]?.linkedAt).format("DD/MM/YYYY HH:mm") }}</span>
        </v-col>
      </v-row>
    </template>
    <v-carousel ref="carousel" v-if="emails.length" height="auto" @change="setIndex" hide-delimiters progress-color="primary">
      <v-carousel-item v-for="(email, i) in emails" :key="i">
        <div class="container-fluid d-flex justify-center align-center">
          <v-card class="pa-2" elevation="0">
            <v-row>
              <v-col cols="12" class="pa-3 ma-0 d-flex justify-center align-center">
                <div style="max-height: calc(100vh - 260px); overflow-y: auto; overflow-x: hidden">
                  <div class="message-box--body text-left" v-html="email.body" style="width: 600px"></div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-carousel-item>

      <template v-slot:prev>
        <!-- Custom previous button -->
        <v-btn icon large :disabled="emails.length < 1" @click="$refs.carousel.prev()">
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </template>

      <template v-slot:next>
        <!-- Custom next button -->
        <v-btn icon large :disabled="emails.length < 1" @click="$refs.carousel.next()">
          <v-icon large>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
    </v-carousel>
  </modal>
</template>

<script>
import Modal from "../../components/common/modal.vue";
import Tools from "../../mixins/Tools";

export default {
  name: "viewEmailContact",
  components: { Modal },
  mixins: [Tools],
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    contact: {
      default: () => {},
      type: Object,
    },
    emails: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    return {
      toggleModal: this.value,
      loading: false,
      index: 0,
    };
  },
  mounted() {
    this.toggleModal = this.value;
  },
  methods: {
    setIndex(index) {
      this.index = index;
    },
  },
  watch: {
    value() {
      this.toggleModal = this.value;
    },
    toggleModal() {
      this.$emit("input", this.toggleModal);
    },
  },
};
</script>

<style></style>
