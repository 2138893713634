<template>
  <v-btn
    @click="
      clearLocalCookie();
      clearServiceWorker();
    "
    x-small
    color="primary"
    class="cookieBtn"
  >
    <v-icon x-small class="">fa-fw fa-recycle</v-icon>
    <span class="cookieText ml-2">Effacer les cookies</span>
  </v-btn>
</template>

<script>
export default {
  name: "ClearCookie",

  components: {},
  mounted() {},
  methods: {
    clearServiceWorker() {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
          console.log("Service Worker unregistered");
        });
        this.$simpleToast.success("Service Worker", "Le service-worker a été désinstallé");
        window.location.reload();
      }
    },
    clearLocalCookie() {
      localStorage.clear();
      sessionStorage.clear();

      const cookies = document.cookie.split("; ");
      for (const cookie of cookies) {
        const [name] = cookie.split("=");
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }

      this.$simpleToast.success("Cookies", "Les cookies ont été effacés");
    },
  },
};
</script>

<style scoped>
.cookieText {
  display: none;
}

.cookieBtn {
  width: 15px;
  transition: width 0.5s ease-in-out;
}
.cookieBtn:hover {
  width: 155px;
}
.cookieBtn:hover .cookieText {
  display: block;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
