<template>
  <v-container fluid v-if="isGranted('CHALLENGES_EDIT')">
    <v-card style="z-index: 10">
      <v-toolbar color="primary " dark elevation="0">
        <v-row>
          <v-col cols="11">
            <v-text-field v-model="search" label="Rechercher" single-line prepend-icon="fa-search" hide-details></v-text-field>
          </v-col>
          <v-col cols="1" class="text-center">
            <v-btn @click="addFct" color="primary" small fab class="listbox--actions-add">
              <v-icon small>fa-plus fa-fw</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table :headers="headers" :items="challenges" class="elevation-0" :search="search">
        <template v-slot:item.startDate="{ item }">
          {{ dayjs(item.startDate).format("DD/MM/YYYY") }}
        </template>
        <template v-slot:item.endDate="{ item }">
          {{ dayjs(item.endDate).format("DD/MM/YYYY") }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editItem(item)">fa-edit fa-fw</v-icon>
          <v-icon small color="error" @click="deleteItem(item)" class="ml-2">fa-trash-alt fa-fw</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <modal v-model="editModal">
      <v-row v-if="editableItem">
        <v-col cols="12" v-if="editableItem.id">
          <v-text-field disabled v-model="editableItem.id" label="#"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="editableItem.name" label="Nom"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-menu ref="menu1" v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateStartFormatted"
                label="Date de début"
                v-bind="attrs"
                @blur="editableItem.startDate = parseDate(dateStartFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="editableItem.startDate" no-title @input="menuStart = false" :max="editableItem.endDate"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <v-menu ref="menu1" v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateEndFormatted"
                label="Date de fin"
                v-bind="attrs"
                @blur="editableItem.endDate = parseDate(dateEndFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="editableItem.endDate" no-title :min="editableItem.startDate" @input="menuEnd = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn color="error" text @click="editModal = false"> Fermer </v-btn>
        <v-btn text class="mr-4" @click="saveGroups"> Enregistrer </v-btn>
      </template>
    </modal>
  </v-container>
</template>

<script>
import Tools from "../../mixins/Tools";
import Modal from "../../components/common/modal";
import dayjs from "dayjs";

export default {
  name: "challengesSettings",
  components: { Modal },
  mixins: [Tools],
  data() {
    return {
      challenges: [],
      search: "",
      editModal: false,
      editableItem: null,
      menuStart: false,
      menuEnd: false,
      dateStartFormatted: false,
      dateEndFormatted: false,
      headers: [
        { text: "#", value: "id", align: "left" },
        { text: "Nom", value: "name", align: "left" },
        { text: "Date début", value: "startDate", align: "left" },
        { text: "Date fin", value: "endDate", align: "left" },
        { text: "", value: "actions", align: "right" },
      ],
    };
  },
  mounted() {
    this.getChallenges();
  },
  methods: {
    saveGroups() {
      if (this.editableItem.id) {
        this.$axios.patch("/api/challenges/" + this.editableItem.id, { data: this.editableItem }).then(() => {
          this.getChallenges();
          this.$simpleToast.success("Challenge", "Le challenge a été mis à jour");
          this.editModal = false;
        });
      } else {
        this.$axios.post("/api/challenges", { data: this.editableItem }).then(() => {
          this.getChallenges();
          this.$simpleToast.success("Challenge", "Le challenge a été ajouté");
          this.editModal = false;
        });
      }
    },
    editItem(item) {
      this.editableItem = JSON.parse(JSON.stringify(item));
      this.editableItem.startDate = dayjs(item.startDate).format("YYYY-MM-DD");
      this.editableItem.endDate = dayjs(item.endDate).format("YYYY-MM-DD");
      this.editModal = true;
    },
    async deleteItem(item) {
      let res = await this.$confirm("Êtes-vous sûr ?");

      if (res) {
        this.$axios.delete("/api/challenges/" + item.id).then(() => {
          this.getChallenges();
          this.$simpleToast.success("Challenge", "Le challenge a été supprimé");
        });
      }
    },
    addFct() {
      this.editableItem = {
        name: "",
        startDate: null,
        endDate: null,
      };
      this.editModal = true;
    },
    getChallenges() {
      this.$axios("/api/challenges").then((resp) => {
        this.challenges = resp.data;
      });
    },
  },
  watch: {
    "editableItem.startDate"() {
      this.dateStartFormatted = this.formatDate(this.editableItem.startDate);
    },
    "editableItem.endDate"() {
      this.dateEndFormatted = this.formatDate(this.editableItem.endDate);
    },
  },
};
</script>

<style scoped></style>
