<template>
  <material-card title="Saison" class="mb-5 pb-7">
    <v-list>
      <challenge-tables-header :season="true"></challenge-tables-header>
      <div v-for="(item, i) in chall" :key="i">
        <v-list-item :style="{ borderLeft: 'solid 5px ' + (item.colorType ? item.colorType : 'grey') }">
          <v-col cols="1">{{ i + 1 }}</v-col>

          <v-list-item-avatar>
            <UserAvatar :userId="item.user" size="40" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-row>
              <v-col cols="3" class="py-0 pl-6 text-left d-none d-sm-flex" style="font-size: 0.9rem">
                {{ item.name }}
              </v-col>
              <v-col cols="3" sm="2" class="py-0 text-right">
                <template v-if="item.user === $store.state.user.id || isGranted('CHALLENGES_VIEW_AMOUNT')">
                  {{ Math.round(item.ca).toLocaleString("fr") }}
                </template>
              </v-col>
              <v-col cols="2" sm="2" class="py-0 text-right">
                <template v-if="item.user === $store.state.user.id || isGranted('CHALLENGES_VIEW_AMOUNT')">
                  {{ Math.round(item.gain).toLocaleString("fr") }}
                </template>
              </v-col>
              <v-col cols="2" class="py-0 text-right"> {{ item.nb }} / {{ challenge.classement.nb_week }} </v-col>

              <v-col cols="2" class="py-0 text-right"> +{{ Math.round(item.points).toLocaleString("fr") }} </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-icon width="24">
            <img src="/images/challenge/helmet--gold.svg" v-if="i === 0" width="24" />
            <img src="/images/challenge/helmet--silver.svg" v-if="i === 1" width="24" />
            <img src="/images/challenge/helmet--bronze.svg" v-if="i === 2" width="24" />
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </v-list>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
import Tools from "../../mixins/Tools";
import UserAvatar from "../../components/user/UserAvatar.vue";
import challengeTablesHeader from "./challengeTablesHeader.vue";

export default {
  name: "seasonChallenge",
  components: { MaterialCard, UserAvatar, challengeTablesHeader },
  props: ["value"],
  mixins: [Tools],
  data() {
    return {
      challenge: this.value,
      chall: [],
    };
  },
  mounted() {
    this.challenge = this.value;
    let key = Object.keys(this.challenge.classement.general);
    this.chall = [];

    key.forEach((item) => {
      this.chall.push(this.challenge.classement.general[item]);
    });

    this.chall.sort((a, b) => {
      if (a["points"] === b["points"] && a["nb"] && b["nb"]) return a["nb"] > b["nb"] ? -1 : 1;
      else if (a["points"] === b["points"]) return a["gain"] > b["gain"] ? -1 : 1;
      else return a["points"] > b["points"] ? -1 : 1;
    });
    this.challenge.global = this.chall;
  },
  watch: {
    challenge() {
      this.$emit("input", this.challenge);
    },
    value() {
      this.challenge = this.value;
      let key = Object.keys(this.challenge.classement.general);
      this.chall = [];

      key.forEach((item) => {
        this.chall.push(this.challenge.classement.general[item]);
      });

      this.chall.sort((a, b) => {
        if (a["points"] === b["points"] && a["nb"] && b["nb"]) return a["nb"] > b["nb"] ? -1 : 1;
        else if (a["points"] === b["points"]) return a["gain"] > b["gain"] ? -1 : 1;
        else return a["points"] > b["points"] ? -1 : 1;
      });
      this.challenge.global = this.chall;
    },
  },
};
</script>

<style scoped></style>
