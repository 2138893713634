var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    { attrs: { header: false } },
    [
      _c(
        "v-container",
        { staticStyle: { "margin-top": "-40px" } },
        [
          _c(
            "v-row",
            { staticClass: "mb-3 mt-0 pt-0", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("prevChall")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("fa-chevron-left fa-fw")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-col", { staticClass: "text-center" }, [
                _c("h2", { staticClass: "mb-0 text-uppercase primary--text" }, [
                  _vm._v(_vm._s(_vm.challenge.challenge.name)),
                ]),
                _c(
                  "span",
                  {
                    staticClass:
                      "text-overline font-italic grey--text text--darken-1",
                  },
                  [
                    _vm._v(
                      "Période du " +
                        _vm._s(
                          _vm
                            .dayjs(_vm.challenge.challenge.startDate)
                            .format("DD/MM/YYYY")
                        ) +
                        " au " +
                        _vm._s(
                          _vm
                            .dayjs(_vm.challenge.challenge.endDate)
                            .format("DD/MM/YYYY")
                        )
                    ),
                  ]
                ),
              ]),
              _c(
                "v-col",
                { staticClass: "text-left" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("nextChall")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("fa-chevron-right fa-fw")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.challenge && _vm.challenge.global && _vm.challenge.global.length > 3
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { cols: "4" } },
                        [
                          _c("img", {
                            attrs: {
                              src: "/images/challenge/helmet--gold.svg",
                              width: "48",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "8" } },
                        [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "text-overline",
                                staticStyle: {
                                  "font-size": "1rem !important",
                                  color: "#ffa900",
                                },
                              },
                              [_vm._v(_vm._s(_vm.challenge.global[0].name))]
                            ),
                          ]),
                          _c("div", { staticStyle: { "font-size": "0.8em" } }, [
                            _vm.challenge.global[0].user ===
                              _vm.$store.state.user.id ||
                            _vm.isGranted("CHALLENGES_VIEW_AMOUNT")
                              ? _c("div", [
                                  _vm._v(" Marge : "),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        Math.round(
                                          _vm.challenge.global[0].gain
                                        ).toLocaleString("fr")
                                      ) + " €"
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("div", [
                              _vm._v(" Points : "),
                              _c("strong", [
                                _vm._v(
                                  "+" +
                                    _vm._s(
                                      Math.round(
                                        _vm.challenge.global[0].points
                                      ).toLocaleString("fr")
                                    )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { cols: "4" } },
                        [
                          _c("img", {
                            attrs: {
                              src: "/images/challenge/helmet--silver.svg",
                              width: "48",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "8" } },
                        [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "text-overline",
                                staticStyle: {
                                  "font-size": "1rem !important",
                                  color: "#c0c0c0",
                                },
                              },
                              [_vm._v(_vm._s(_vm.challenge.global[1].name))]
                            ),
                          ]),
                          _c("div", { staticStyle: { "font-size": "0.8em" } }, [
                            _vm.challenge.global[1].user ===
                              _vm.$store.state.user.id ||
                            _vm.isGranted("CHALLENGES_VIEW_AMOUNT")
                              ? _c("div", [
                                  _vm._v(" Marge : "),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        Math.round(
                                          _vm.challenge.global[1].gain
                                        ).toLocaleString("fr")
                                      ) + " €"
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("div", [
                              _vm._v(" Points : "),
                              _c("strong", [
                                _vm._v(
                                  "+" +
                                    _vm._s(
                                      Math.round(
                                        _vm.challenge.global[1].points
                                      ).toLocaleString("fr")
                                    )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { cols: "4" } },
                        [
                          _c("img", {
                            attrs: {
                              src: "/images/challenge/helmet--bronze.svg",
                              width: "48",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0 small", attrs: { cols: "8" } },
                        [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "text-overline",
                                staticStyle: {
                                  "font-size": "1rem !important",
                                  color: "#c49c48",
                                },
                              },
                              [_vm._v(_vm._s(_vm.challenge.global[2].name))]
                            ),
                          ]),
                          _c("div", { staticStyle: { "font-size": "0.8em" } }, [
                            _vm.challenge.global[2].user ===
                              _vm.$store.state.user.id ||
                            _vm.isGranted("CHALLENGES_VIEW_AMOUNT")
                              ? _c("div", [
                                  _vm._v(" Marge : "),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        Math.round(
                                          _vm.challenge.global[2].gain
                                        ).toLocaleString("fr")
                                      ) + " €"
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("div", [
                              _vm._v(" Points : "),
                              _c("strong", [
                                _vm._v(
                                  "+" +
                                    _vm._s(
                                      Math.round(
                                        _vm.challenge.global[2].points
                                      ).toLocaleString("fr")
                                    )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }