<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="2" v-if="$slots.title" class="py-0 mt-5">
      <slot name="title"></slot>
    </v-col>
    <v-col :cols="$slots.title ? 10 : 12" class="py-0 ma-0">
      <v-autocomplete
        :menu-props="{ maxHeight: '300' }"
        v-model="servicesSelected"
        :items="infras"
        clearable
        chips
        :disabled="disabled"
        :color="labelColor"
        :label="label"
        item-text="name"
        item-value="id"
        :multiple="multiple"
        cache-items
        :persistent-placeholder="!!$slots.title"
      >
        <template v-slot:selection="data">
          <v-chip :color="data.item.color">
            <v-icon x-small>{{ data.item.icon }}</v-icon>
            <span class="ml-2">{{ data.item.name }}</span>
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-chip :color="data.item.color">
            <v-icon x-small>{{ data.item.icon }}</v-icon>
            <span class="ml-2">{{ data.item.name }}</span>
          </v-chip>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Tools from "../../mixins/Tools";

export default {
  name: "ListServices",
  mixins: [simpleDebounce, Tools],
  props: {
    allInfras: {
      type: Array,
      default: null,
    },
    includeAll: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Number,
      default: null,
    },
    serviceMultiple: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "Choisir un service",
    },
    labelColor: {
      type: String,
      default: "blue-grey lighten-2",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      servicesSelected: [],
      toggleUserList: false,
      search: "",
      infras: this.allInfras,
    };
  },
  methods: {
    remove(item) {
      const index = this.servicesSelected.indexOf(item.id);
      if (index >= 0) this.servicesSelected.splice(index, 1);
    },
  },
  watch: {
    servicesSelected() {
      this.$emit("selectChange", this.servicesSelected);
    },
  },
};
</script>

<style scoped></style>
