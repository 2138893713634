var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center" },
    [
      _c(
        "v-avatar",
        {
          attrs: {
            size: _vm.size,
            left: _vm.left,
            right: _vm.right,
            color: _vm.color,
          },
        },
        [_c("img", { attrs: { src: _vm.cachedAvatarUrl } })]
      ),
      _vm.fullname
        ? _c("span", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.fullname))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }