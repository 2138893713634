var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "percentage-span-container" }, [
    _c(
      "span",
      {
        class: "percentage-span " + _vm.colorClass(),
        style: { width: _vm.cellData + "%" },
      },
      [_c("span", [_vm._v(_vm._s(_vm.cellData) + "% ")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }