<template>
  <div v-if="compaign">
    <sui-step-group style="width: 100%; margin-top: -50px">
      <sui-step
        class="pa-1"
        v-if="compaign.defaultSettings.phases[1].enable"
        :active="Date.now() > new Date(compaign.defaultSettings.phases[1].startDate).getTime()"
      >
        <sui-step-content>
          <v-row>
            <v-col cols="12">
              <sui-step-title class="ml-1"> <v-icon small class="mx-1">fa-envelope</v-icon>Mailing</sui-step-title>
              <sui-step-description class="text-center">
                {{ dayjs(compaign.defaultSettings.phases[1].startDate).format("DD/MM/YYYY") }}
              </sui-step-description>
            </v-col>
          </v-row>
        </sui-step-content>
      </sui-step>
      <sui-step
        v-if="compaign.defaultSettings.phases[2].enable"
        class="pa-1"
        :active="Date.now() > new Date(compaign.defaultSettings.phases[2].startDate).getTime()"
      >
        <sui-step-content>
          <v-row>
            <v-col cols="12">
              <sui-step-title class="ml-1">
                <v-icon small class="mx-1">fa-reply</v-icon>
                Phoning</sui-step-title
              >
              <sui-step-description class="text-center">
                {{ dayjs(compaign.defaultSettings.phases[2].startDate).format("DD/MM/YYYY") }}
              </sui-step-description>
            </v-col>
          </v-row>
        </sui-step-content>
      </sui-step>
      <sui-step
        v-if="compaign.defaultSettings.phases[3].enable"
        class="pa-1"
        :active="Date.now() > new Date(compaign.defaultSettings.phases[3].startDate).getTime()"
      >
        <sui-step-content>
          <v-row>
            <v-col cols="12">
              <sui-step-title class="ml-1">
                <v-icon small class="mx-1">fa-users</v-icon>
                Rencontre</sui-step-title
              >
              <sui-step-description class="text-center">
                {{ dayjs(compaign.defaultSettings.phases[3].startDate).format("DD/MM/YYYY") }}
              </sui-step-description>
            </v-col>
          </v-row>
        </sui-step-content>
      </sui-step>
      <sui-step
        v-if="compaign.defaultSettings.phases[4].enable"
        class="pa-1"
        :active="Date.now() > new Date(compaign.defaultSettings.phases[4].startDate).getTime()"
      >
        <sui-step-content>
          <v-row>
            <v-col cols="12">
              <sui-step-title class="ml-1">
                <v-icon small class="mx-1">fa-reply-all</v-icon>
                Génération business</sui-step-title
              >
              <sui-step-description class="text-center">
                {{ dayjs(compaign.defaultSettings.phases[4].startDate).format("DD/MM/YYYY") }}
              </sui-step-description>
            </v-col>
          </v-row>
        </sui-step-content>
      </sui-step>
    </sui-step-group>
  </div>
</template>

<script>
import StepGroup from "semantic-ui-vue/dist/commonjs/elements/Step/StepGroup";
import StepContent from "semantic-ui-vue/dist/commonjs/elements/Step/StepContent";
import StepDescription from "semantic-ui-vue/dist/commonjs/elements/Step/StepDescription";
import StepTitle from "semantic-ui-vue/dist/commonjs/elements/Step/StepTitle";
import Step from "semantic-ui-vue/dist/commonjs/elements/Step/Step";
import Tools from "../../../../mixins/Tools";

export default {
  name: "stepPhaseCompaign",
  components: {
    "sui-step-group": StepGroup,
    "sui-step-content": StepContent,
    "sui-step": Step,
    "sui-step-title": StepTitle,
    "sui-step-description": StepDescription,
  },
  props: ["value"],
  mixins: [Tools],
  data() {
    return {
      compaign: this.value,
    };
  },
  watch: {
    value() {
      this.compaign = this.value;
    },
  },
};
</script>

<style scoped></style>
