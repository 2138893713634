var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.newEvent && Object.keys(_vm.newEvent).length > 0
    ? _c(
        "v-row",
        [
          _vm.newEvent.teams
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        color: "error",
                        outlined: "",
                        small: "",
                        prominent: "",
                        dense: "",
                        text: "",
                        type: "error",
                      },
                    },
                    [
                      _vm._v(
                        " Les contacts liés à l'événement vont recevoir une invitation et les modifications de celui-ci. "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "pb-0 py-4 my-0", attrs: { cols: "12", md: "6" } },
            [
              _c("v-autocomplete", {
                staticClass: "utilisateursParticipantsSelect",
                attrs: {
                  "menu-props": { maxHeight: "300" },
                  items: _vm.users,
                  "small-chips": "",
                  outlined: "",
                  "deletable-chips": "",
                  label: "Utilisateurs participants",
                  "item-text": "fullname",
                  "item-value": "id",
                  multiple: "",
                  "search-input": _vm.search,
                  "cache-items": "",
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.search = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selection",
                      fn: function (data) {
                        return [
                          _c(
                            "v-chip",
                            _vm._b(
                              {
                                attrs: {
                                  "input-value": data.selected,
                                  close: "",
                                },
                                on: {
                                  click: data.select,
                                  "click:close": function ($event) {
                                    return _vm.remove(data.item)
                                  },
                                },
                              },
                              "v-chip",
                              data.attrs,
                              false
                            ),
                            [
                              _c("UserAvatar", {
                                staticClass: "mr-2",
                                attrs: { userId: data.item.id, size: "25" },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    data.item.firstname +
                                      " " +
                                      data.item.lastname
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item",
                      fn: function (data) {
                        return [
                          [
                            _c(
                              "v-list-item-avatar",
                              [
                                _c("UserAvatar", {
                                  staticClass: "mr-2",
                                  attrs: { userId: data.item.id, size: "25" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.item.firstname +
                                          " " +
                                          data.item.lastname
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1530468615
                ),
                model: {
                  value: _vm.usersSelected,
                  callback: function ($$v) {
                    _vm.usersSelected = $$v
                  },
                  expression: "usersSelected",
                },
              }),
            ],
            1
          ),
          [
            _c(
              "v-col",
              { attrs: { cols: "6", sm: "4", md: "2" } },
              [
                _c("v-switch", {
                  attrs: {
                    label: "En attente",
                    color: "secondary",
                    value: "0",
                  },
                  model: {
                    value: _vm.status,
                    callback: function ($$v) {
                      _vm.status = $$v
                    },
                    expression: "status",
                  },
                }),
              ],
              1
            ),
            _vm.newEvent
              ? _c(
                  "v-col",
                  { attrs: { cols: "6", sm: "4", md: "2" } },
                  [
                    _c("v-switch", {
                      attrs: { label: "Réalisé", color: "success", value: "1" },
                      model: {
                        value: _vm.status,
                        callback: function ($$v) {
                          _vm.status = $$v
                        },
                        expression: "status",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.newEvent.id
              ? _c(
                  "v-col",
                  { attrs: { cols: "6", sm: "4", md: "2" } },
                  [
                    _c("v-switch", {
                      attrs: { label: "Annulé", color: "error", value: "2" },
                      model: {
                        value: _vm.status,
                        callback: function ($$v) {
                          _vm.status = $$v
                        },
                        expression: "status",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          _c(
            "v-col",
            {
              staticClass: "py-0 my-0 mt-4 mt-md-0",
              attrs: { cols: "12", md: "12" },
            },
            [
              _c("v-text-field", {
                staticClass: "my-0 py-0",
                attrs: { label: "Sujet", outlined: "", rules: _vm.rules },
                model: {
                  value: _vm.newEvent.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.newEvent, "name", $$v)
                  },
                  expression: "newEvent.name",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0 my-0 mt-4 mt-md-0",
              attrs: { cols: "12", md: "3" },
            },
            [
              _c("v-select", {
                staticClass: "my-0 py-0",
                attrs: {
                  items:
                    _vm.newEvent.eventtypeId &&
                    _vm.typeEventNotForm.includes(_vm.newEvent.eventtypeId)
                      ? _vm.typeEvent
                      : _vm.typeEventCreate,
                  outlined: "",
                  rules: _vm.rules,
                  "item-text": "name",
                  "item-value": "id",
                  label: "Type",
                  "persistent-hint": "",
                  "hide-details": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selection",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "overflow-hidden text-no-wrap" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    color: item.color,
                                    "x-small": "",
                                    label: "",
                                  },
                                },
                                [_vm._v(_vm._s(item.icon) + " fa-fw")]
                              ),
                              _c(
                                "span",
                                { staticClass: "overflow-hidden text-no-wrap" },
                                [_vm._v(_vm._s(item.name))]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item",
                      fn: function ({ active, item }) {
                        return [
                          _c(
                            "span",
                            { staticClass: "text-no-wrap" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    color: item.color,
                                    "x-small": "",
                                    label: "",
                                  },
                                },
                                [_vm._v(_vm._s(item.icon) + " fa-fw")]
                              ),
                              _vm._v(" " + _vm._s(item.name) + " "),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3524926590
                ),
                model: {
                  value: _vm.newEvent.eventtypeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.newEvent, "eventtypeId", $$v)
                  },
                  expression: "newEvent.eventtypeId",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0 my-0 mt-4 mt-md-0",
              attrs: { cols: "12", md: "3" },
            },
            [
              _c(
                "v-menu",
                {
                  ref: "menu1",
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "auto",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "my-0 py-0",
                                    attrs: {
                                      label: "Date",
                                      "persistent-hint": "",
                                      outlined: "",
                                      "hide-details": "",
                                      rules: _vm.rules,
                                    },
                                    on: {
                                      blur: function ($event) {
                                        _vm.newEvent.date = _vm.parseDate(
                                          _vm.dateFormatted
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.dateFormatted,
                                      callback: function ($$v) {
                                        _vm.dateFormatted = $$v
                                      },
                                      expression: "dateFormatted",
                                    },
                                  },
                                  "v-text-field",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1021987608
                  ),
                  model: {
                    value: _vm.menuDate,
                    callback: function ($$v) {
                      _vm.menuDate = $$v
                    },
                    expression: "menuDate",
                  },
                },
                [
                  _c("v-date-picker", {
                    attrs: { "first-day-of-week": "1", color: "primary" },
                    on: {
                      input: function ($event) {
                        _vm.menuDate = false
                      },
                    },
                    model: {
                      value: _vm.newEvent.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.newEvent, "date", $$v)
                      },
                      expression: "newEvent.date",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0 my-0 mt-4 mt-md-0",
              attrs: { cols: "12", md: "3" },
            },
            [
              _vm.newEvent.time
                ? _c("time-picker", {
                    attrs: { outlined: "" },
                    model: {
                      value: _vm.newEvent.time,
                      callback: function ($$v) {
                        _vm.$set(_vm.newEvent, "time", $$v)
                      },
                      expression: "newEvent.time",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0 my-0 mt-4 mt-md-0",
              attrs: { cols: "12", md: 3 },
            },
            [
              _c("v-text-field", {
                staticClass: "my-0 py-0",
                attrs: {
                  label: "Durée",
                  type: "number",
                  outlined: "",
                  rules: _vm.rules,
                  "hide-details": "",
                },
                model: {
                  value: _vm.newEvent.duration,
                  callback: function ($$v) {
                    _vm.$set(_vm.newEvent, "duration", $$v)
                  },
                  expression: "newEvent.duration",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0 my-0 pt-sm-3 pt-md-0",
              attrs: { cols: "12", md: "2" },
            },
            [
              _c("v-switch", {
                attrs: { color: "error", label: "Privé", "hide-details": "" },
                model: {
                  value: _vm.newEvent.private,
                  callback: function ($$v) {
                    _vm.$set(_vm.newEvent, "private", $$v)
                  },
                  expression: "newEvent.private",
                },
              }),
            ],
            1
          ),
          _vm.newEvent.userId === _vm.$store.state.user.id ||
          !_vm.newEvent.userId
            ? _c(
                "v-col",
                {
                  staticClass: "py-0 my-0 pt-sm-3 pt-md-0",
                  attrs: { cols: "12", md: "2" },
                },
                [
                  _c("v-switch", {
                    attrs: {
                      color: "error",
                      label: "Confidentiel",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.newEvent.confidential,
                      callback: function ($$v) {
                        _vm.$set(_vm.newEvent, "confidential", $$v)
                      },
                      expression: "newEvent.confidential",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "py-0 my-0 mt-7", attrs: { cols: "12" } },
            [
              _c("v-textarea", {
                staticClass: "my-0 py-1",
                attrs: {
                  label: "Commentaires internes",
                  outlined: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.newEvent.body,
                  callback: function ($$v) {
                    _vm.$set(_vm.newEvent, "body", $$v)
                  },
                  expression: "newEvent.body",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "my-0 py-3 grey lighten-5",
                  staticStyle: { clear: "both" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 my-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-start align-center mb-10",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "mr-3 text-left text-h6 text-uppercase blue-grey--text",
                            },
                            [_vm._v("Contacts Associés")]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-switch",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "mt-0 pt-0 text-body-2",
                                              attrs: {
                                                color: "primary",
                                                "hide-details": "",
                                                dense: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Invitation calendrier"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.newEvent.invitation,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newEvent,
                                                    "invitation",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newEvent.invitation",
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3438037006
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Envois une invitation au contact sélectionné"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0 my-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        _vm._l(_vm.contactsList, function (item, key) {
                          return _c(
                            "v-col",
                            {
                              key: key,
                              staticClass: "py-0 my-0",
                              attrs: { cols: "12", sm: "6", md: "4", lg: "3" },
                            },
                            [
                              _c("v-switch", {
                                staticClass: "pa-0 ma-0",
                                attrs: {
                                  color: "primary",
                                  value: item.origin.contact.id,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "text-caption" },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _c(
                                            "sup",
                                            [
                                              item.favorite
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color:
                                                          "yellow darken-1",
                                                        "x-small": "",
                                                      },
                                                    },
                                                    [_vm._v("fa-star")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.newEvent.contacts,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newEvent, "contacts", $$v)
                                  },
                                  expression: "newEvent.contacts",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.customer
            ? [
                _c(
                  "v-row",
                  { staticClass: "my-3 mt-0" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "3" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 text-start",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "h4",
                                  { staticClass: "ml-3 blue-grey--text" },
                                  [_vm._v(_vm._s(_vm.customer.entreprise.name))]
                                ),
                                _vm.customer.entreprise.adresse.address
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0 mb-1",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2 float-start",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("fa-map-marker-alt fa-fw")]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "float-start" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.customer.entreprise
                                                    .adresse.address
                                                )
                                            ),
                                            _c("br"),
                                            _vm.customer.entreprise.adresse
                                              .address2
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.customer.entreprise
                                                          .adresse.address2
                                                      )
                                                  ),
                                                  _c("br"),
                                                ]
                                              : _vm._e(),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.customer.entreprise
                                                    .adresse.postalCode +
                                                    " " +
                                                    _vm.customer.entreprise
                                                      .adresse.city
                                                ) +
                                                " "
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("br", { staticStyle: { clear: "both" } }),
                                _vm.customer.entreprise.phone
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0 mb-1",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c("v-divider", {
                                          staticClass: "my-3",
                                        }),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2 float-start",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("fa-phone fa-fw")]
                                        ),
                                        _c("span", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "tel:" +
                                                  _vm.customer.entreprise.phone,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.customer.entreprise.phone
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("br", { staticStyle: { clear: "both" } }),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 mb-1",
                                attrs: { cols: "12" },
                              },
                              [
                                _vm.customer.entreprise.email
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2 float-start",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("fa-envelope fa-fw")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "py-0 float-start" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "mailto:" +
                                                    _vm.customer.entreprise
                                                      .email,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.customer.entreprise
                                                      .email
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("br", { staticStyle: { clear: "both" } }),
                                _vm.customer.entreprise.site
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0 mb-1",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c("v-divider", {
                                          staticClass: "my-3",
                                        }),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2 float-start",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("fa-home fa-fw")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "py-0 float-start text-wrap",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: _vm.customer.entreprise
                                                    .site,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.customer.entreprise.site
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider", { attrs: { vertical: "" } }),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "9" } },
                      [
                        _vm.contactsList && _vm.contactsList.length > 0
                          ? [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0 my-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "py-0 my-0" },
                                    _vm._l(
                                      _vm.selectedContact,
                                      function (itemContact, key) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: key,
                                            attrs: { cols: "12", md: "6" },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              { attrs: { elevation: "1" } },
                                              [
                                                _c(
                                                  "v-toolbar",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                      height: "35px",
                                                      elevation: "0",
                                                    },
                                                  },
                                                  [
                                                    itemContact.favorite
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              color:
                                                                "yellow darken-1",
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fa-star fa-fw"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          itemContact.name
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "text-left" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fas fa-graduation-cap fa-fw"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              itemContact.origin &&
                                                                itemContact
                                                                  .origin.job
                                                                ? itemContact
                                                                    .origin.job
                                                                    .name
                                                                : "Non renseigné"
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fa-phone fa-fw"
                                                            ),
                                                          ]
                                                        ),
                                                        itemContact.origin
                                                          .contact.phone
                                                          ? _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href:
                                                                    "tel:" +
                                                                    itemContact
                                                                      .origin
                                                                      .contact
                                                                      .phone,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    itemContact
                                                                      .origin
                                                                      .contact
                                                                      .phone
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        itemContact.origin
                                                          .contact.phone &&
                                                        itemContact.origin
                                                          .contact.phone2
                                                          ? [_vm._v(" - ")]
                                                          : _vm._e(),
                                                        itemContact.origin
                                                          .contact.phone2
                                                          ? _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href:
                                                                    "tel:" +
                                                                    itemContact
                                                                      .origin
                                                                      .contact
                                                                      .phone2,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    itemContact
                                                                      .origin
                                                                      .contact
                                                                      .phone2
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !itemContact.origin
                                                          .contact.phone &&
                                                        !itemContact.origin
                                                          .contact.phone2
                                                          ? [
                                                              _vm._v(
                                                                " Non renseigné "
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "far fa-envelope fa-fw"
                                                            ),
                                                          ]
                                                        ),
                                                        itemContact.origin
                                                          .contact.email
                                                          ? [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      "mailto:" +
                                                                      itemContact
                                                                        .origin
                                                                        .contact
                                                                        .email,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      itemContact
                                                                        .origin
                                                                        .contact
                                                                        .email
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : [
                                                              _vm._v(
                                                                " Non renseigné "
                                                              ),
                                                            ],
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "py-0 my-0", attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  false
                    ? [
                        _c(
                          "v-col",
                          { staticClass: "px-0 mx-0", attrs: { cols: "6" } },
                          [
                            _c("v-switch", {
                              staticClass: "pa-0 ma-0",
                              attrs: { label: "Déjà effectué", value: "1" },
                              model: {
                                value: _vm.newEvent.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.newEvent, "status", $$v)
                                },
                                expression: "newEvent.status",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }