var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    {
      attrs: {
        "height-tot": "700px",
        color: "info",
        icon: "fa-calendar",
        title: "Mon calendrier",
      },
    },
    [_c("div", [_c("Calendar", { attrs: { type: "month", small: "" } })], 1)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }