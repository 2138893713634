export const featuresList = [
  {
    path: "/",
    color: "white",
    name: "Mon dashboard",
    icon: "fa-fw fa-home",
    permission: "",
    mobile: true,
  },
  {
    path: "/events",
    color: "white",
    name: "Calendrier",
    icon: "far fa-fw fa-calendar-alt",
    permission: "EVENTS_INDEX",
    mobile: true,
  },
  {
    path: "/inbox",
    color: "white",
    name: "Boite aux lettres",
    icon: "far fa-fw fa-envelope",
    permission: "",
    mobile: false,
  },
  {
    path: "/customers",
    color: "white",
    name: "Comptes",
    icon: "far fa-fw fa-user-circle",
    permission: "CUSTOMERS_INDEX",
    mobile: true,
  },

  {
    path: "/contacts",
    color: "white",
    name: "Contacts",
    icon: "far fa-fw fa-address-card",
    permission: "CONTACTS_INDEX",
    mobile: true,
  },
  {
    path: "/opportunities",
    color: "white",
    name: "Opportunités",
    icon: "far fa-fw fa-handshake",
    permission: "OPPORTUNITIES_INDEX",
    mobile: true,
  },
  {
    path: "/concurrents",
    color: "white",
    name: "Concurrents",
    icon: "fa-fw fa-users",
    permission: "CUSTOMERS_INDEX",
    mobile: true,
  },
  {
    path: "/statistics",
    color: "white",
    name: "Statistiques",
    icon: "fa-fw fa-chart-bar",
    permission: ["OPPORTUNITIES_STATISTICS", "OPPORTUNITIES_STATISTICSPERS", "OPPORTUNITIES_STATISTICPERS_ALL"],
    mobile: true,
  },
  {
    path: "/challenges/view",
    color: "white",
    name: "Challenges",
    icon: "fa-fw fa-flag-checkered",
    permission: "CHALLENGES_INDEX",
    mobile: true,
  },
  {
    path: "/infrastructures",
    color: "white",
    name: "Parcs clients",
    icon: "fa-fw fa-network-wired",
    permission: "INFRASTRUCTURES_INDEX",
    mobile: true,
  },
  {
    path: "/subscriptions",
    color: "white",
    name: "Abonnements",
    icon: "fa-fw fa-sync",
    permission: "SUBSCRIPTIONS_INDEX",
    mobile: true,
  },
  {
    path: "/compaign",
    color: "white",
    name: "Campagnes",
    icon: "far fa-fw fa-paper-plane",
    permission: "COMPAIGN_LIST",
    mobile: true,
  },
  {
    path: "/swdevices",
    color: "white",
    name: "Supervision",
    icon: "fa-fw fa-binoculars",
    permission: "SWDEVICES_INDEX",
    mobile: true,
  },
  {
    path: "/map",
    color: "white",
    name: "Carte",
    icon: "fa-fw fa-globe-europe",
    permission: "SECTOR_INDEX",
    mobile: false,
  },
  // {
  //   path: "/dashboard",
  //   color: "white",
  //   name: "Mon Dashboard",
  //   icon: "fa-fw fa-home",
  //   permission: "SWDEVICES_INDEX",
  // },
];
