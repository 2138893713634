var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MaterialCard",
    { staticClass: "mt-9", attrs: { title: "Description", icon: "fa-pen" } },
    [
      _c(
        "v-form",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "my-0 py-0 mt-2", attrs: { cols: "12" } },
                    [
                      _c("v-textarea", {
                        staticClass: "ma-0",
                        staticStyle: {
                          "min-height": "100px",
                          "font-size": "0.85rem !important",
                        },
                        attrs: {
                          disabled:
                            !_vm.isGranted("OPPORTUNITIES_EDIT") ||
                            !_vm.opp.opened,
                          name: "input-7-1",
                          label: "Description",
                          outlined: "",
                          row: "10",
                          "auto-grow": "",
                        },
                        model: {
                          value: _vm.opp.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.opp, "description", $$v)
                          },
                          expression: "opp.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }