<template>
  <material-card class="px-5 py-3" height-tot="700px">
    <template v-slot:heading>
      <v-row>
        <v-col cols="8">
          <v-tabs v-model="tabs" background-color="transparent" slider-color="white">
            <span class="subheading font-weight-light mx-3" style="align-self: center">Opportunités en cours:</span>
            <v-tab class="mr-3">
              <v-badge :content="tasks[0].length ? tasks[0].length : '0'" color="primary" overlap>
                <v-icon class="mr-2"> fa-fingerprint </v-icon>
                <span class="mr-6">Identifié</span>
              </v-badge>
            </v-tab>
            <v-tab class="mr-3">
              <v-badge :content="tasks[1].length ? tasks[1].length : '0'" color="primary" overlap>
                <v-icon class="mr-2"> fa-search </v-icon>
                <span class="mr-6">Étude</span>
              </v-badge>
            </v-tab>
            <v-tab>
              <v-badge :content="tasks[2].length ? tasks[2].length : '0'" color="primary" overlap>
                <v-icon class="mr-2"> fa-handshake </v-icon>
                <span class="mr-6">Négociation</span>
              </v-badge>
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="4" class="pt-5 text-md-right">
          <v-icon @click="newOpp">fa-plus</v-icon>
        </v-col>
      </v-row>
    </template>

    <v-tabs-items v-model="tabs" class="transparent">
      <v-tab-item v-for="n in 3" :key="n">
        <v-card-text>
          <template v-if="tasks[tabs].length === 0">
            <v-alert text prominent type="info"> Il n'y a rien à afficher. </v-alert>
          </template>
          <v-list style="max-height: 440px; overflow-y: scroll">
            <div v-for="(item, i) in tasks[tabs]" :key="i">
              <v-list-item-group color="primary">
                <v-list-item :to="'/opportunities/edit/' + item.opp.id" :key="item.opp.name">
                  <v-list-item-avatar>
                    <v-img :src="$axios.defaults.baseURL + '/upload/users/' + item.opp.userId + '.png'"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-row class="ma-0">
                      <v-col cols="12" md="8">
                        <v-list-item-title>
                          <v-chip v-if="item" color="warning" label class="mb-1 mr-2" text-color="white" small>
                            <v-icon left> fa-hashtag </v-icon>
                            {{ item.opp.id }}
                          </v-chip>
                          <v-chip color="primary" class="mr-2 mb-1" label small>
                            <v-icon left> fa-user </v-icon>
                            {{ item.customer && item.customer.entreprise.name ? item.customer.entreprise.name : "Pas de client" }}
                          </v-chip>
                          <v-chip v-if="item.type" color="pink" label class="mb-1" text-color="white" small>
                            <v-icon left> fa-tag </v-icon>
                            {{ item.type }} </v-chip
                          ><br />

                          {{ item.opp.name ? item.opp.name : "Pas de nom" }}</v-list-item-title
                        >
                        <v-list-item-subtitle class="font-italic">{{ dayjs(item.opp.createdAt).format("DD/MM/YYYY") }}</v-list-item-subtitle>
                      </v-col>
                      <v-col cols="12" md="2" class="heading">
                        <v-chip v-if="item.opp.amount" class="ma-2" color="success" label text-color="white" x-large>
                          <v-icon left> fa-euro-sign </v-icon>
                          {{ item.opp.amount }}
                        </v-chip>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-progress-circular
                          :rotate="360"
                          :size="100"
                          :width="15"
                          :value="item.opp.probability ? item.opp.probability : '0'"
                          :color="item.opp.probability > 50 ? 'success' : 'error'"
                        >
                          {{ item.opp.probability ? item.opp.probability + "%" : "0" + "%" }}
                        </v-progress-circular>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="tasks[tabs].length > i"></v-divider>
              </v-list-item-group>
            </div>
          </v-list>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
import Tools from "../../mixins/Tools";

export default {
  name: "dashboardOpportunities",
  components: { MaterialCard },
  mixins: [Tools],
  data() {
    return {
      tabs: 0,
      tasks: {
        0: [],
        1: [],
        2: [],
      },
    };
  },
  methods: {
    newOpp() {
      this.$axios.post("/api/opportunities").then((res) => {
        this.$router.push({ name: "Opportunité", params: { id: res.data.opp.id } });
      });
    },
  },
  mounted() {
    this.$axios("/api/opportunities/user").then((result) => {
      result.data.forEach((item) => {
        this.tasks[item.opp.stepId > 0 ? item.opp.stepId - 1 : 0].push(item);
      });
    });
  },
};
</script>

<style scoped></style>
