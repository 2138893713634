var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    {
      staticClass: "mt-8",
      attrs: { title: "Statistiques", icon: "fas fa-chart-bar" },
    },
    [
      _c("h3", { staticStyle: { "text-align": "center" } }, [
        _vm._v("Comparatif opportunitées remportées par métier"),
      ]),
      _c("Bar", {
        staticClass: "chartBar",
        attrs: { chartData: _vm.chartData, chartOptions: _vm.chartOptions },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }