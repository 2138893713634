var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-tabs",
        {
          attrs: { "background-color": "transparent", grow: "" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        _vm._l(_vm.items, function (item, key) {
          return _c(
            "v-tab",
            { key: key, attrs: { color: "primary", dark: "", elevation: "0" } },
            [_vm._v(" " + _vm._s(item) + " ")]
          )
        }),
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [_c("v-card-text", [_c("CustomersMap")], 1)],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [_c("SectorSettings", { attrs: { fromFeature: "" } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }