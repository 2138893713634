var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isGranted("ROLES_INDEX")
        ? _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary ", dark: "", elevation: "0" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "11" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Rechercher",
                              "single-line": "",
                              "prepend-icon": "fa-search",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "1" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "listbox--actions-add",
                              attrs: { color: "primary", small: "", fab: "" },
                              on: {
                                click: function ($event) {
                                  _vm.editGroupItem = {
                                    group: { name: "" },
                                    grants: [],
                                  }
                                  _vm.editModal = true
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("fa-plus fa-fw"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.roles.groups,
                      search: _vm.search,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.actions",
                          fn: function ({ item }) {
                            return [
                              _vm.isGranted("ROLES_EDIT")
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("fa-edit")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      967746694
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "modal",
        {
          attrs: { "max-width": "1024" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v(
                    _vm._s(
                      _vm.editGroupItem && _vm.editGroupItem.group.id
                        ? "Édition"
                        : "Ajout"
                    )
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", loading: _vm.loading },
                      on: { click: _vm.saveRoles },
                    },
                    [_vm._v("Enregistrer")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.editModal,
            callback: function ($$v) {
              _vm.editModal = $$v
            },
            expression: "editModal",
          },
        },
        [
          _vm.editGroupItem
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Nom" },
                        model: {
                          value: _vm.editGroupItem.group.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editGroupItem.group, "name", $$v)
                          },
                          expression: "editGroupItem.group.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: {
                            "max-height": "500px",
                            "overflow-y": "scroll",
                          },
                        },
                        [
                          _c(
                            "v-expansion-panels",
                            _vm._l(
                              Object.keys(_vm.rolesList),
                              function (name, key) {
                                return _c(
                                  "v-expansion-panel",
                                  { key: "label-" + key },
                                  [
                                    _c(
                                      "v-expansion-panel-header",
                                      {
                                        staticClass: "text-overline",
                                        attrs: {
                                          "expand-icon": "fas fa-angle-down",
                                        },
                                      },
                                      [_vm._v(_vm._s(name))]
                                    ),
                                    _c(
                                      "v-expansion-panel-content",
                                      [
                                        _c(
                                          "v-row",
                                          _vm._l(
                                            _vm.rolesList[name],
                                            function (item, key) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: "item-" + key,
                                                  staticClass: "py-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      inset: "",
                                                      label: item.name,
                                                      value: item.id,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.editGroupItem
                                                          .grants,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editGroupItem,
                                                          "grants",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editGroupItem.grants",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      false
                        ? _c("v-autocomplete", {
                            attrs: {
                              "menu-props": { maxHeight: "300" },
                              items: _vm.roles.grants,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              dense: "",
                              chips: "",
                              "small-chips": "",
                              label: "Droits",
                              multiple: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              innerHTML: _vm._s(data.item.name),
                                            },
                                          }),
                                          _c("v-list-item-subtitle", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                data.item.categoryName
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2839217515
                            ),
                            model: {
                              value: _vm.editGroupItem.grants,
                              callback: function ($$v) {
                                _vm.$set(_vm.editGroupItem, "grants", $$v)
                              },
                              expression: "editGroupItem.grants",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }