var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": _vm.maxWidth, width: "width", scrollable: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "text-center" },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary", dark: "", elevation: "0" } },
            [
              _c(
                "v-row",
                { staticClass: "p-0 m-0 text-left" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", large: "" } },
                    [
                      _vm._t("header"),
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "15px",
                            right: "15px",
                          },
                          attrs: { "aria-label": "Close" },
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v(" fa-times ")]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { class: _vm.noPadding ? "pa-0" : "py-3" },
            [_vm._t("default")],
            2
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._t("actions", function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Fermer ")]
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }