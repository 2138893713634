<template>
  <MaterialCard class="mt-5" :header="false" v-if="opportunity" :background="'transparent'" :elevation="'0'">
    <v-form>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="my-0 pt-3 pb-0">
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3" class="my-0 py-0">
                <v-text-field
                  :disabled="!isGranted('OPPORTUNITIES_EDIT') || !opportunity.opp.opened"
                  label="Sujet"
                  class="ma-0 mb-8"
                  v-model="opp.name"
                  maxlength="50"
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="1" class="my-0 py-0">
                <v-menu ref="menu1" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="!isGranted('OPPORTUNITIES_EDIT') || !opportunity.opp.opened"
                      v-model="dateFormatted"
                      label="Date d'échéance"
                      persistent-hint
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormatted)"
                      v-on="on"
                      class="ma-0 mb-8"
                      outlined
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker first-day-of-week="1" v-model="date" @input="menu = false" color="primary"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" lg="2" xl="3" class="my-0 py-0">
                <v-select
                  :disabled="!isGranted('OPPORTUNITIES_EDIT') || !opportunity.opp.opened"
                  v-model="opp.job"
                  :items="types"
                  item-text="state"
                  item-value="abbr"
                  label="Métier"
                  persistent-hint
                  return-object
                  class="ma-0 mb-8 no-wrap-selected"
                  outlined
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    <div class="overflow-hidden text-no-wrap">
                      <v-icon :color="item.color" class="mr-2" x-small label>{{ item.icon }} fa-fw</v-icon>
                      <span class="overflow-hidden text-no-wrap">{{ item.state }}</span>
                    </div>
                  </template>
                  <template v-slot:item="{ active, item }">
                    <span class="text-no-wrap">
                      <v-icon :color="item.color" class="mr-2" x-small label>{{ item.icon }} fa-fw</v-icon>
                      {{ item.state }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" lg="2" class="my-0 py-0">
                <v-select
                  :disabled="!isGranted('OPPORTUNITIES_EDIT') || !opportunity.opp.opened"
                  v-model="opp.rank"
                  :items="categories[opp.job && opp.job.abbr ? opp.job.abbr : 0]"
                  item-text="state"
                  item-value="abbr"
                  label="Classement"
                  persistent-hint
                  return-object
                  class="ma-0 mb-8"
                  outlined
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" lg="1" class="my-0 py-0">
                <v-text-field
                  :disabled="!isGranted('OPPORTUNITIES_EDIT') || !opportunity.opp.opened"
                  v-model="opp.amount"
                  label="Montant"
                  suffix="€"
                  class="ma-0 mb-8"
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="2" xl="2" class="my-0 py-0 pt-4">
                <v-slider
                  :disabled="!isGranted('OPPORTUNITIES_EDIT') || !opportunity.opp.opened"
                  v-model="opp.probability"
                  append-icon="fa-percent fa-fw"
                  :color="probabilityColor()"
                  track-color="primary lighten-2"
                  thumb-label="always"
                  step="25"
                  class="ma-0 mb-8"
                  hide-details
                  :thumb-size="24"
                  :thumb-color="probabilityColor()"
                ></v-slider>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </MaterialCard>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Tools from "../../mixins/Tools";
import MaterialCard from "../../components/common/MaterialCard";
import dayjs from "dayjs";

export default {
  name: "formEditOpportunities",
  components: { MaterialCard },
  mixins: [simpleDebounce, Tools],
  props: ["value"],
  data() {
    return {
      opportunity: null,
      date: dayjs().format("YYYY-MM-DD"),
      dateFormatted: this.formatDate(dayjs().format("YYYY-MM-DD")),
      opp: {
        id: null,
        name: "",
        deadline: "",
        job: null,
        rank: null,
        amount: "",
        probability: 0,
        description: "",
      },
      menu: false,
      categories: [],
      types: [],
      load: false,
    };
  },
  mounted() {
    this.getTypes();
    this.$root.$on("description-change", (item) => {
      this.opp.description = item;
    });
  },
  computed: {},
  beforeDestroy() {
    if (this.opp.name !== null && this.opp.name.length > 50) {
      this.$simpleToast.error("Opportunité", "Le nom de l'opportunité est trop long");
    } else {
      // this.saveIt(false);
    }
  },
  methods: {
    getTypes() {
      this.$axios.get("/api/types/active").then((res) => {
        res.data.types.forEach((item) => {
          this.types.push({ state: item.name, abbr: item.id, icon: item.icon, color: item.color });
        });
        Object.keys(res.data.cat).forEach((items) => {
          this.categories[items] = [];
          res.data.cat[items].forEach((item) => {
            this.categories[items].push({ state: item.name, abbr: item.id });
          });
        });
      });
    },
    saveIt(toast = true) {
      if (this.opp.amount < 0) {
        this.$simpleToast.error("Opportunité", "Le montant doit être positif ou null");
        return;
      }

      if (this.opp.name !== null && this.opp.name.length > 50) {
        this.opp.name = this.opp.name.substring(0, 49);
      }

      let data = JSON.parse(JSON.stringify(this.opp));
      data.amount = data.amount ? parseInt(data.amount.replace(/ /g, "")) : 0;

      this.$axios.patch("/api/opportunities/" + this.opp.id, { data: data }).then(() => {
        if (toast) {
          this.$simpleToast.success("Opportunités", "Les modifications ont été enregistrées");
        }

        this.$root.$emit("save");
      });
    },
    probabilityColor() {
      if (this.opp.probability > 75) return "success";
      else if (this.opp.probability >= 75) return "light-green darken-1";
      else if (this.opp.probability >= 50) return "primary";
      else if (this.opp.probability >= 25) return "orange lighten-1";
      return "deep-orange darken-1";
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.opp.deadline = this.date;
    },
    opportunity() {
      this.$emit("input", this.opportunity);
    },
    value() {
      this.opportunity = this.value;
      let date = "";
      let deadline = this.value.opp.deadline ? new Date(this.value.opp.deadline) : "";
      if (deadline !== "") {
        let month = deadline.getMonth() + 1;
        let year = deadline.getFullYear();
        let day = deadline.getDate();
        date = year + "-" + (month >= 10 ? "" : "0") + month + "-" + day;
        this.dateFormatted = this.formatDate(date);
        this.date = this.parseDate(this.dateFormatted);
      }

      let job = null;
      let rank = null;

      this.types.forEach((data) => {
        if (data.abbr && data.abbr === this.value.opp.typeId) {
          job = data;
        }
      });
      if (job && job.abbr) {
        this.categories[job.abbr].forEach((data) => {
          if (data.abbr && data.abbr === this.value.opp.categoryId) {
            rank = data;
          }
        });
      }

      this.opp = {
        id: this.value.opp.id,
        name: this.value.opp.name,
        deadline: date,
        rank: rank,
        amount: this.value.opp.amount ? this.value.opp.amount.toLocaleString("fr-FR") : "",
        probability: this.value.opp.probability,
        job: job,
        description: this.value.opp.note,
      };
      setTimeout(() => {
        this.load = true;
      }, 1000);
    },
    opp: {
      handler() {
        this.getTypes();
        if (this.load) {
          if (this.isGranted("OPPORTUNITIES_EDIT") && this.opportunity.opp.opened) {
            this.simpleDebounce(() => {
              if (this.opp.name && this.opp.name.length > 50) {
                this.$simpleToast.error("Opportunité", "Le nom de l'opportunité est trop long");
              } else {
                this.saveIt();
              }
            }, 1500 /* optional debounce time: default is 300 */);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  max-height: 400px !important;
}
</style>
