<template>
  <v-container fluid>
    <modal v-model="openUploadModal" max-width="760">
      <v-card-title>Changer la photo</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-file-input label="Choisir une image" v-model="filePicture" accept="image/png, image/jpeg, image/bmp" prepend-icon="fa-mdiCamera"></v-file-input>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn text color="error" @click="openUploadModal = false">Annuler</v-btn>
            <v-btn color="primary" @click="uploadPicture">Sauvegarder</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <template v-slot:actions>
        <div></div>
      </template>
    </modal>
    <v-card style="z-index: 10" v-if="isGranted('USERS_INDEX')">
      <v-toolbar color="primary " dark elevation="0">
        <v-row>
          <v-col cols="11">
            <v-text-field v-model="search" prepend-icon="fa-search" label="Rechercher un utilisateur" single-line hide-details></v-text-field>
          </v-col>
          <v-col cols="1" class="text-center">
            <v-btn fab color="primary" class="listbox--actions-add" small @click="addUserModal">
              <v-icon small>fa-plus fa-fw</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table :headers="headers" :items="users" class="elevation-0">
        <template v-slot:item.picture="{ item }">
          <UserAvatar :userId="item.id" size="35" />
        </template>

        <template v-slot:item.state="{ item }" class="text-center">
          <v-icon small :color="item.state ? 'success' : 'warning'">{{ item.state ? "fa-check" : "fa-times" }} fa-fw </v-icon>
        </template>

        <template v-slot:item.lastLogin="{ item }">
          <template v-if="item.lastLogin">
            {{ dayjs(item.lastLogin).format("DD/MM/YYYY") }}
          </template>
          <template v-else> Pas de connexion </template>
        </template>
        <template v-slot:item.role="{ item }">
          {{ getRoleName(item.role) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="takePlace(item)" color="primary" small>fa-key fa-fw</v-icon>
          <v-icon @click="editUser(item)" v-if="isGranted('USERS_EDIT')" small>fa-edit fa-fw</v-icon>
        </template>
      </v-data-table>
      <modal v-model="editModal" max-width="1024">
        <template v-slot:header>
          <strong class="text-h5 mb-0 text-uppercase">{{ editionUser && editionUser.id ? "Édition" : "Ajout" }} - utilisateur </strong>
          <br /><small class="mt-0">{{ editionUser && editionUser.id ? editionUser.firstname + " " + editionUser.lastname : "" }}</small>
        </template>
        <v-row v-if="editionUser">
          <v-col cols="12" md="6">
            <v-hover v-slot="{ hover }">
              <v-avatar v-if="editionUser.id" size="100px" color="primary" class="v-avatar-light-bg primary--text">
                <UserAvatar :userId="editionUser.id" size="100" v-if="!hover" />
                <v-icon v-if="hover" x-large @click="openUploadModal = true">fa-edit fa-fw</v-icon>
              </v-avatar>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch v-model="editionUser.state" label="Actif"></v-switch>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field outlined v-model="editionUser.lastname" label="Nom"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field outlined v-model="editionUser.firstname" label="Prénom"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field outlined v-model="editionUser.email" label="Email"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select outlined :menu-props="{ maxHeight: '300' }" v-model="editionUser.color" label="Couleur" :items="colors">
              <template v-slot:selection="{ item }">
                <v-chip small label :color="item">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-chip>
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <v-chip small label :color="item">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-chip>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="6" v-if="editionUser.id && false">
            <v-text-field outlined v-model="editionUser.password" label="Mot de passe" type="password"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" v-if="editionUser.id && false">
            <v-text-field outlined v-model="editionUser.rePassword" label="Confirmation du mot de passe" type="password"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              outlined
              :menu-props="{ maxHeight: '300' }"
              :items="roles"
              item-text="name"
              item-value="id"
              v-model="editionUser.role.id"
              label="Rôle"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              outlined
              :menu-props="{ maxHeight: '300' }"
              :items="services"
              item-text="name"
              item-value="id"
              v-model="editionUser.service.id"
              label="Service"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" v-if="editionUser.id && false">
            <v-switch v-model="editionUser.state" label="Actif"></v-switch>
          </v-col>
        </v-row>
        <template v-slot:actions>
          <v-btn variant="flat" color="primary" elevation="0" @click="editUserSave"> Enregistrer </v-btn>
          <v-btn color="secondary" text class="mr-4" @click="editModal = false"> Fermer </v-btn>
        </template>
      </modal>
    </v-card>
  </v-container>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Tools from "../../mixins/Tools";
import Modal from "../../components/common/modal";
import UserAvatar from "../../components/user/UserAvatar.vue";

export default {
  name: "userSettings",
  components: { Modal, UserAvatar },
  mixins: [simpleDebounce, Tools],

  data() {
    return {
      editModal: false,
      editionUser: null,
      openUploadModal: false,
      colors: ["#aed581", "#b2dfdb", "#ff7961", "#ffcc80", "#c5cae9", "#81d4fa", "#80deea", "#4db6ac", "#a5d6a7"],
      filePicture: null,
      headers: [
        { text: "", value: "picture", align: "start" },
        { text: "État", value: "state", align: "center" },
        { text: "Nom", value: "lastname", align: "start" },
        { text: "Prénom", value: "firstname", align: "start" },
        { text: "Service", value: "service.name", align: "start" },
        { text: "Role", value: "role", align: "start" },
        { text: "Email", value: "email", align: "start" },
        { text: "Dernière connexion", value: "lastLogin", align: "start" },
        { text: "", value: "actions", align: "right" },
      ],
      users: [],
      search: "",
      services: [],
      roles: [],
    };
  },
  mounted() {
    this.getUsers();
    this.getServices();
    this.getRoles();
  },

  methods: {
    async takePlace(item) {
      let res = await this.$confirm("Êtes-vous sûr de vouloir vous connecter en tant que " + item.firstname + " " + item.lastname + "?");
      if (res) {
        this.$axios("/api/users/" + item.id + "/token").then((resp) => {
          localStorage.setItem("token-actuelburo", resp.data.token);
          this.$router.push({ name: "Panneau de supervision d'opportunités" });
          location.reload();
        });
      }
    },
    getRoles() {
      this.$axios("/api/roles/groups").then((resp) => {
        resp.data.groups.forEach((item) => {
          this.roles.push(item.group);
        });
      });
    },
    getRoleName(roleId) {
      let roleName = "";
      this.roles.forEach((item) => {
        if (roleId === item.id) {
          roleName = item.name;
        }
      });
      return roleName;
    },
    addUserModal() {
      this.editionUser = {
        state: 1,
        lastname: "",
        firstname: "",
        email: "",
        color: "#e6e6e6",
        password: "",
        rePassword: "",
        service: { id: null },
        role: { id: null },
      };
      this.editModal = true;
    },
    uploadPicture() {
      let data = new FormData();
      data.append("avatar", this.filePicture);
      data.append("_method", "POST");
      this.$axios.post("/api/admin/users/" + this.$route.params.id + "/picture", data).then(() => {
        this.user = null;
        this.getUser();
        this.openUploadModal = false;
      });
    },
    editUserSave() {
      if (
        this.editionUser.lastname.length === 0 ||
        this.editionUser.firstname.length === 0 ||
        this.editionUser.email.length === 0 || //|| this.editionUser.role.length === 0
        this.editionUser.service.id === null
      ) {
        this.$simpleToast.error("Utilisateur", "Un des champs est vide");
        return;
      }

      if (this.editionUser.id) {
        if ((this.editionUser.password.length > 0 || this.editionUser.rePassword.length > 0) && this.editionUser.password !== this.editionUser.rePassword) {
          this.$simpleToast.error("Utilisateur", "Les mots de passe ne correspondent pas");
        }

        this.$axios.patch("/api/users/" + this.editionUser.id, { data: this.editionUser }).then(() => {
          this.getUsers();
          this.$simpleToast.success("Utilisateur", "Les modifications ont été enregistrées");
          this.editModal = false;
        });
      } else {
        this.$axios.post("/api/users", { data: this.editionUser }).then(() => {
          this.getUsers();
          this.$simpleToast.success("Utilisateur", "L'utilisateur a été ajouté'");
          this.editModal = false;
        });
      }
    },
    getServices() {
      this.$axios("/api/services").then((resp) => {
        this.services = resp.data;
      });
    },
    editUser(item) {
      this.editionUser = JSON.parse(JSON.stringify(item));
      this.editionUser.role = { id: this.editionUser.role };
      this.editionUser.password = "";
      this.editionUser.rePassword = "";
      this.editModal = true;
    },
    getUsers() {
      this.$axios("/api/users?s=" + this.search + "&limit=" + false + "&all=" + true + "&order=id").then((resp) => {
        this.users = resp.data;
      });
    },
  },

  watch: {
    search() {
      this.simpleDebounce(() => {
        this.getUsers();
      }, 500);
    },
    // permet de récupe les utilisateurs qui se sont connectés après une date donnée
    // users() {
    //   console.log(this.users.filter((user) => this.dayjs(user.lastLogin).isAfter(this.dayjs("2024-02-01"))).map((user) => user.email));
    // },
  },
};
</script>

<style scoped></style>
