<template>
  <MaterialCard icon="fa-history" class="mt-8" title="Évènements" :color="'primary'">
    <modal v-model="openModalMail" max-width="calc(100% - 122px)">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Messages liés</strong>
        <br /><small class="mt-0">Sélectionnez le message</small>
      </template>
      <template v-if="openModalMail">
        <v-tabs vertical>
          <v-tab v-for="(email, key) in events.email[mailSelected]" :key="key" style="justify-content: left; margin-bottom: 1rem">
            <div class="text-left overflow-x-hidden" style="font-size: 0.8em; text-align: left">
              <small style="display: block" class="mb-3"
                >{{ email.sender }} <br />
                {{ email.senderEmail }}
              </small>

              {{ email.name }}
            </div>
          </v-tab>

          <v-tab-item v-for="(email, key) in events.email[mailSelected]" :key="key">
            <div v-html="email.body" class="text-left mx-5"></div>
          </v-tab-item>
        </v-tabs>
      </template>
    </modal>
    <modal v-model="linkModal" max-width="calc(100% - 122px)">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Boîte mail</strong>
        <br /><small class="mt-0">Filtrez les dossiers...</small>
      </template>
      <div class="text-left">
        <Inbox @close="closeInbox" v-model="typeLink"></Inbox>
      </div>
    </modal>

    <modal v-model="linkOppModal" max-width="1024">
      <opportunitiesCustomers v-model="value" :link="true" @linkOpp="linkOppFct"></opportunitiesCustomers>
    </modal>

    <modal v-model="addEvent" max-width="1080">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Evènement</strong>
        <br /><small class="mt-0">{{ dayjs(newEvent.date).format("DD/MM/YYYY") }} {{ newEvent.time }} - {{ newEvent.name }}</small>

        <template v-if="newEvent.id">
          <div class="modal-menu--actions">
            <v-row>
              <v-col cols="12" class="text-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      elevation="0"
                      plain
                      color="primary"
                      @click="$router.push({ name: 'Client', params: { id: newEvent.customerId } })"
                    >
                      <v-icon x-small>fa-search</v-icon>
                    </v-btn>
                  </template>
                  <span>Fiche client</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ml-1"
                      small
                      elevation="0"
                      plain
                      color="none"
                      @click="
                        typeLink.id = newEvent.id;
                        linkModal = true;
                      "
                    >
                      <v-icon x-small>fa-envelope</v-icon>
                    </v-btn>
                  </template>
                  <span>Lier un e-mail</span>
                </v-tooltip>
                <template v-if="newEvent.opportunityId">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1"
                        elevation="0"
                        small
                        plain
                        color="warning"
                        @click="$router.push({ name: 'Opportunité', params: { id: newEvent.opportunityId } })"
                      >
                        <v-icon x-small>fa-handshake</v-icon>
                      </v-btn>
                    </template>
                    <span>Opportunité {{ newEvent.opportunityId }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="newOpp" v-bind="attrs" v-on="on" class="mx-1" elevation="0" small plain color="warning">
                        <v-icon x-small>fa-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Créer opportunité</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" @click="linkOppModal = true" small plain elevation="0">
                        <v-icon x-small>fa-link</v-icon>
                      </v-btn>
                    </template>
                    <span>Associer opportunité</span>
                  </v-tooltip>
                </template>
              </v-col>
            </v-row>
          </div>
        </template>
      </template>
      <v-container fluid>
        <v-row>
          <v-form ref="form" v-model="valid">
            <EventForm v-model="newEvent"></EventForm>
          </v-form>
        </v-row>
      </v-container>
      <template v-slot:actions>
        <v-btn
          color="secondary"
          text
          class="ml-4"
          @click="
            addEvent = false;
            loadingSave = false;
          "
        >
          Fermer
        </v-btn>
        <v-btn variant="flat" color="primary" elevation="0" @click="addEventFct" :loading="loadingSave"> Enregistrer </v-btn>
      </template>
    </modal>

    <div class="listbox--actions-add">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab color="primary" class="mr-5" small v-bind="attrs" v-on="on">
            <v-icon small> fa-fw fa-filter </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-title>
            <div class="px-4">
              <strong class="text-uppercase grey--text text-center">Filtres</strong>
            </div>
          </v-list-title>
          <v-list-item>
            <v-checkbox v-model="onlyCrossSelling" label="seulement les cross-selling" hide-details></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-for="(item, key) in shortcut" :key="key">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            @click="
              initNewEvent();
              addEvent = true;
              newEvent.eventtypeId = item.id;
              newEvent.duration = item.duration;
            "
            v-bind="attrs"
            v-on="on"
            small
            class="mr-2"
            :color="item.color"
          >
            <v-icon small>
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>

      <v-btn
        class="text-center ml-5 addEventBtn"
        fab
        :color="'primary'"
        @click="
          initNewEvent();
          addEvent = true;
        "
        small
      >
        <v-icon color="white" small> fa-plus </v-icon>
      </v-btn>
    </div>
    <v-row>
      <v-col cols="12" md="12" class="py-0">
        <v-text-field v-model="search" label="Rechercher un évènement..."></v-text-field>
      </v-col>
    </v-row>

    <div style="max-height: 400px; overflow-y: auto" class="events-timelines">
      <v-container>
        <v-timeline dense large align-top v-if="events.events && events.events.length > 0">
          <v-timeline-item
            small
            v-for="(item, i) in events.events.filter((item) => (onlyCrossSelling ? item.isCrossSelling === onlyCrossSelling : true))"
            :color="returnIcon(item.status).color"
            :key="i"
            class="my-0 mb-4 pl-0"
            right
          >
            <template v-slot:icon>
              <template v-if="events.users[item.id].length > 3">
                <v-avatar color="primary" size="40">
                  <span class="white--text text-h5">{{ events.users[item.id].length }}</span>
                </v-avatar>
              </template>
              <template v-else-if="events.users[item.id].length == 1">
                <UserAvatar :userId="item.userId" size="40" />
              </template>
              <template v-else>
                <UserAvatar
                  v-for="(user, key) in events.users[item.id]"
                  :key="key"
                  :style="'margin-left:' + (events.users[item.id].length == 2 ? '-10px' : '-15px')"
                  :userId="user.userId"
                  size="40"
                />
              </template>
              <v-icon class="white" small :color="returnIcon(item.status).color" style="position: absolute; top: 35px"
                >{{ returnIcon(item.status).icon }} fa-fw
              </v-icon>
              <span class="white" style="position: absolute; top: 55px; text-align: center; font-size: 0.8rem">
                {{ dayjs(item.start).format("DD/MM/YYYY") }}<br />{{ dayjs(item.start).format("HH:mm") }}
              </span>
            </template>
            <template>
              <v-card :class="{ eventstandby: item.status == 0, eventsuccess: item.status == 1, eventcancel: item.status == 2 }">
                <v-card-title class="ma-0 pt-0">
                  <strong style="font-size: 0.9rem" class="grey--text text--darken-3">
                    <v-icon small class="mr-3" v-if="item.private">fa-lock</v-icon>
                    <v-icon v-if="events" class="mr-1" x-small :color="events.type[item.eventtypeId].color">
                      {{ events.type[item.eventtypeId].icon }}
                    </v-icon>
                    <v-icon v-if="item.confidential" x-small color="error">fa-fw fa-mask</v-icon>
                    <span class="mx-2">{{ events.type[item.eventtypeId].name }} - {{ item.name }}</span>
                    <template v-if="item.opportunity">
                      <v-btn
                        class="ma-0 p-0"
                        outlined
                        x-small
                        elevation="0"
                        :color="item.opportunity.statusId !== null ? '' : 'primary'"
                        @click="openOpportunity(item.opportunity.id)"
                        >{{ item.opportunity.id }}
                      </v-btn>
                    </template>
                    <template v-if="item.isCrossSelling">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" class="ma-0 p-0 mr-2" outlined x-small elevation="0" color="blue">CROSS-SELLING</v-btn>
                        </template>
                        <span class="d-flex">Évènement cross-selling </span>
                      </v-tooltip>
                    </template>
                    <template v-if="item.opportunityOrigin">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="ma-0 p-0"
                            outlined
                            x-small
                            elevation="0"
                            color="success"
                            @click="openOpportunity(item.opportunityOrigin.id)"
                            >{{ item.opportunityOrigin.id }}
                          </v-btn>
                        </template>
                        <span class="d-flex">
                          <UserAvatar :userId="item.opportunityOrigin.closedUserId" />
                          <div class="d-flex align-end flex-column ml-4">
                            <strong>{{ item.opportunityOrigin.name }}</strong>
                            <span style="font-size: 0.8rem; text-align: end">{{ parseInt(item.opportunityOrigin.amount).toFixed(0) }} €</span>
                          </div>
                        </span>
                      </v-tooltip>
                    </template>
                  </strong>
                </v-card-title>

                <v-card-text>
                  <template v-if="item.report && item.report.length > 0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span class="mr-1" v-bind="attrs" v-on="on">- Note interne disponible</span>
                      </template>
                      <span>{{ item.report }}</span>
                    </v-tooltip>
                  </template>
                  <div class="text-caption">
                    <span v-if="item.teams">
                      <a :href="item.teamsLink" target="_blank" v-if="item.status !== 2">
                        <img src="/images/microsoft-teams.svg" style="max-width: 15px" /> TEAMS
                      </a>
                      <span v-else> <img src="/images/microsoft-teams.svg" style="max-width: 15px" /> TEAMS </span>
                    </span>
                    <template v-if="events.email[item.id].length > 0">
                      <span v-if="item.teams"> - </span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="mr-1"
                            style="cursor: pointer"
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              mailSelected = item.id;
                              openModalMail = true;
                            "
                          >
                            <v-badge overlap text color="white" text-color="primary" offset-x="5px" offset-y="5px" class="pa-0 ma-0 red--text" x-small>
                              <v-icon x-small color="primary">far fa-envelope fa-fw</v-icon>
                              <template v-slot:badge>
                                <span class="blue-grey--text text--darken-2">{{ events.email[item.id].length }}</span>
                              </template>
                            </v-badge>
                          </span>
                        </template>
                        <span>Voir les e-mails liés</span>
                      </v-tooltip>
                    </template>
                  </div>
                  <v-divider class="my-2" v-if="events.email[item.id].length > 0 || item.teams"></v-divider>
                  <div style="position: absolute; bottom: 5px; right: 10px">
                    <v-tooltip bottom v-if="events?.attendees && events?.attendees[item.id].length > 0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          elevation="0"
                          color="primary"
                          depressed
                          text
                          @click="openResponseModal(events?.attendees[item.id])"
                          v-bind="attrs"
                          v-on="on"
                          v-if="item.status === 0"
                        >
                          <v-icon x-small>fa-fw fa-reply</v-icon>
                        </v-btn>
                      </template>
                      <span>Voir les réponses des participants</span>
                    </v-tooltip>
                  </div>
                  <div style="position: absolute; top: -15px; right: 5px">
                    <v-menu left offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on" x-small fab elevation="0" class="openEventMenu">
                          <v-icon x-small> fa-ellipsis-v </v-icon>
                        </v-btn>
                      </template>
                      <v-list class="text-left">
                        <v-list-item @click="editEvent(item)">
                          <v-icon class="mr-2" small>fa-fw fa-edit</v-icon>
                          Editer
                        </v-list-item>

                        <!-- <v-list-item v-else>
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-btn disabled small>
                                  <v-icon class="mr-2" small>fa-fw fa-edit</v-icon>
                                  Editer
                                </v-btn>
                              </div>
                            </template>
                            <span>Un événement validé ou annulé ne peut être modifié.</span>
                          </v-tooltip>
                        </v-list-item> -->
                        <v-divider></v-divider>
                        <v-list-item @click="changeStatus(item, 1)">
                          <v-icon class="mr-2" color="success" small>far fa-fw fa-check-circle</v-icon>
                          Valider
                        </v-list-item>
                        <v-list-item @click="changeStatus(item, 2)">
                          <v-icon class="mr-2" color="error" small>far fa-fw fa-times-circle</v-icon>
                          Annuler
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>

                  <div class="text-caption">
                    <read-more
                      v-if="item.body && item.body.length > 250"
                      more-str="Lire plus"
                      :text="item.body"
                      less-str="Lire moins"
                      :max-chars="250"
                    ></read-more>
                    <p v-else v-html="item.body ? item.body.replaceAll('\n', '<br/>') : 'Pas de commentaire'"></p>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </v-timeline-item>
        </v-timeline>
        <div v-else class="font-weight-light grey--text mb-5 text-center">Aucun évènement lié</div>
      </v-container>

      <v-timeline align-top dense v-if="false">
        <v-timeline-item v-for="(item, i) in events.events" :key="i" :color="events.type[item.eventtypeId].color" fill-dot small>
          <template v-slot:icon>
            <UserAvatar :userId="item.userId" />
          </template>
          <v-card :color="events.type[item.eventtypeId].color" dark>
            <v-card-title style="padding: 0.2rem; font-size: 1.1rem">
              <v-icon v-if="events" class="mr-3">
                {{ events.type[item.eventtypeId].icon }}
              </v-icon>
              {{ events.type[item.eventtypeId].name }} - {{ item.name }}
              <span class="font-italic mx-3" style="font-size: 0.8rem">{{ item.status === 1 ? "Réalisé" : "" }} {{ item.status === 2 ? "Annulé" : "" }}</span>
              <v-icon class="mx-1" small v-if="item.status === 0" @click="changeStatusSubmit(item, 1)">fa-check</v-icon>
              <v-icon class="mx-1" small @click="editEvent(item)">fa-pen</v-icon>
              <v-icon class="mx-1" small v-if="item.status === 0" @click="changeStatusSubmit(item, 2)">fa-trash</v-icon>
            </v-card-title>
            <v-card-text class="white text--primary" style="padding: 0.5rem">
              <p class="pa-0 ma-0">{{ item.body ? item.body : "Pas de commentaire" }}</p>
              <p class="pa-0 ma-0"><span class="font-weight-bold">Crée le:</span> {{ dayjs(item.createdAt).format("DD-MM-YYYY à HH:mm") }}</p>
              <p class="pa-0 ma-0">
                <span class="font-weight-bold">Début:</span> {{ dayjs(item.start).format("DD-MM-YYYY à HH:mm") }} -
                <span class="font-weight-bold">Fin:</span>
                {{ dayjs(item.end).format("DD-MM-YYYY à HH:mm") }}
              </p>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </div>
    <modal v-model="responseModal">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Réponses des participants</strong>
      </template>
      <v-row v-if="selectedResponses">
        <v-col cols="12" class="text-left">
          <v-list>
            <v-list-item v-for="(item, key) in selectedResponses" :key="key">
              <v-list-item-content>
                <v-col cols="8">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-list-item-title>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="item.statusResponse === 'accepted'" color="success" v-bind="attrs" v-on="on">fa-fw fa-check</v-icon>
                        <v-icon v-else-if="item.statusResponse === 'declined'" color="error" v-bind="attrs" v-on="on">fa-fw fa-times</v-icon>
                        <v-icon v-else-if="item.statusResponse === 'tentativelyAccepted'" color="warning" v-bind="attrs" v-on="on">fa-fw fa-question</v-icon>
                        <v-icon v-else color="grey" v-bind="attrs" v-on="on">fa-fw fa-clock</v-icon>
                      </template>
                      <span>{{
                        item.statusResponse === "accepted"
                          ? "accepté"
                          : item.statusResponse === "tentativelyAccepted"
                          ? "tentative"
                          : item.statusResponse === "declined"
                          ? "décliné"
                          : "En attente de réponse"
                      }}</span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </modal>
  </MaterialCard>
</template>

<script>
import MaterialCard from "./MaterialCard";
import Tools from "../../mixins/Tools";
import Modal from "./modal";
import EventForm from "./EventForm";
import simpleDebounce from "simple-debounce-vue-mixin";
import { msalMixin } from "vue-msal";
import Inbox from "../../views/inbox/Inbox.vue";
import EventsTools from "../../mixins/Events";
import ReadMore from "./read-more";
import UserAvatar from "../user/UserAvatar.vue";
import dayjs from "dayjs";
import opportunitiesCustomers from "../../views/customer/opportunitiesCustomers.vue";

export default {
  name: "event",
  components: { Inbox, EventForm, Modal, MaterialCard, ReadMore, UserAvatar, opportunitiesCustomers },
  props: {
    value: {
      required: true,
    },
    fromCustomers: {
      type: Boolean,
      default: false,
    },
    fromOpportunities: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [Tools, simpleDebounce, msalMixin, EventsTools],
  data() {
    return {
      typeLink: { name: "Événement" },
      linkModal: false,
      linkOppModal: false,
      openModalMail: false,
      mailSelected: null,
      responseModal: false,
      selectedResponses: null,
      tab: null,
      comments: [],
      events: [],
      comment: "",
      addEvent: false,
      asUser: null,
      contactsList: [],
      newEvent: {},
      loadingSave: false,
      shortcut: null,
      item: null,
      history: [],
      customerInfo: null,
      search: "",
      valid: false,
      onlyCrossSelling: false,
    };
  },
  created() {
    if (this.fromCustomers) {
      this.initNewEvent();
    }
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    closeInbox() {
      this.getEvents();
      this.linkModal = false;
    },
    openResponseModal(responseItem) {
      this.responseModal = true;
      this.selectedResponses = responseItem;
    },
    linkOppFct(idOpp) {
      this.$axios.patch("/api/events/" + this.newEvent.id + "/link", { opp: idOpp }).then(() => {
        this.linkOppModal = false;
        this.addEvent = false;
        this.getEvents();

        this.$simpleToast.success("Evènement", "L'élément a bien été lié");
      });
    },
    newOpp() {
      this.$axios.post("/api/opportunities", { event: this.newEvent }).then((res) => {
        this.linkOppFct(res.data.opp.id);
        this.$router.push({ name: "Opportunité", params: { id: res.data.opp.id } });
      });
    },
    openOpportunity(id) {
      this.$router.push({ name: "Opportunité", params: { id: id } });
    },
    returnIcon(status) {
      switch (status) {
        case 0:
          return { icon: "far fa-pause-circle", color: "#CFD8DC" };
        case 1:
          return { icon: "far fa-check-circle", color: "#43A047" };
        case 2:
          return { icon: "far fa-times-circle", color: "#C62828" };
      }
    },
    editEvent(tmp) {
      let item = JSON.parse(JSON.stringify(tmp));
      let dateStart = dayjs(item.start).format("DD/MM/YYYY");
      let timeStart = dayjs(item.start).format("HH:mm");
      this.newEvent = {};
      let tmpContacts = [];
      this.events.info[item.id].forEach((item) => {
        if (item.contactId) tmpContacts.push(item.contactId);
      });

      this.newEvent = {
        customerId: item.customerId,
        id: item.id,
        opp: this.fromOpportunities ? this.$route.params.id : null,
        eventtypeId: item.eventtypeId,
        opportunityId: item.opportunity ? item.opportunity.id : null,
        name: item.name,
        time: timeStart,
        date: dateStart,
        usersList: this.events.users[item.id],
        duration: item.duration,
        private: item.private,
        confidential: item?.confidential,
        invitation: item.invitation,
        status: item.status,
        body: item.body,
        teams: item.teams,
        contacts: tmpContacts,
        ewsid: item.ewsid,
        idMS: item.ewsid,
        customer: item.customerId,
        history: [],
        report: item.report,
        init: item,
        immutableEws: item.immutableEws,
      };
      this.addEvent = true;
    },
    async changeStatus(item, status) {
      let res = await this.changeStatusSubmit(item, status);

      if (res) {
        this.getEvents();
      }
    },
    initNewEvent() {
      if (this.value.entreprise === null) {
        this.$simpleToast.error("Evènement", "Vous devez d'abord choisir un compte");
        return false;
      }

      this.newEvent = {
        customerId: this.fromCustomers ? this.$route.params.id : this.value.entreprise.customers.id,
        opp: this.fromOpportunities ? this.$route.params.id : null,
        eventtypeId: null,
        name: "",
        time: null,
        date: null,
        duration: 30,
        private: false,
        confidential: false,
        invitation: false,
        status: false,
        teams: false,
        body: "",
        contacts: [],
        customer: this.asUser,
        history: [],
        report: null,
        typeLink: { name: "Événement" },
        linkModal: false,
      };
    },
    setAsUser(event) {
      this.asUser = event;
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    async editEventFct() {
      this.loadingSave = true;
      let res = await this.editEventSubmit(this.newEvent);
      this.loadingSave = false;

      if (res) {
        this.initNewEvent();
        this.getEvents();
        this.addEvent = false;
      }
    },
    async addEventFct() {
      let res = false;
      this.$refs.form.validate();
      if (!this.valid) {
        this.$simpleToast.error("Evènement", "Un des champs est vide.");
      } else {
        this.loadingSave = true;
        res = await this.addEventSubmit(this.newEvent);
        this.loadingSave = false;
      }

      if (res) {
        this.initNewEvent();
        this.getEvents();
        this.addEvent = false;
      }
    },
    getEvents() {
      this.shortcut = [];
      if (this.fromCustomers) {
        this.$axios("/api/events/" + this.$route.params.id + "/customer", { params: { search: this.search } }).then((data) => {
          this.events = data.data;
          for (const type of Object.keys(this.events.type)) {
            if (this.events.type[type] && this.events.type[type].shortcut) {
              this.shortcut.push(this.events.type[type]);
            }
          }
        });
      } else if (this.fromOpportunities) {
        this.$axios("/api/events/" + this.$route.params.id, { params: { search: this.search } }).then((data) => {
          this.events = data.data;
          for (const type of Object.keys(this.events.type)) {
            if (this.events.type[type] && this.events.type[type].shortcut) {
              this.shortcut.push(this.events.type[type]);
            }
          }
        });
      }
    },
    getCustomersInfo() {
      this.$axios("/api/customers/" + this.value.entreprise.id).then((data) => {
        this.customerInfo = data.data;
        this.contactsList = [];
        this.newEvent.customer = data.data.customer.id;

        if (data.data.contact) {
          data.data.contact.forEach((item) => {
            this.contactsList.push({
              name: item.contact.firstname + " " + item.contact.lastname,
              email: item.contact.email,
              origin: item,
              id: item.id,
              favorite: item.contact.isFavorite,
            });
          });
        }

        this.newEvent.contactsList = this.contactsList;
      });
    },
  },
  watch: {
    search() {
      this.simpleDebounce(() => {
        this.getEvents();
      }, 300 /* optional debounce time: default is 300 */);
    },
  },
  menu(val) {
    val && setTimeout(() => (this.activePicker = "YEAR"));
  },
  value() {
    if (this.value.entreprise && this.contactsList.length === 0) {
      this.getCustomersInfo();
    }
    this.$emit("input", this.value);
  },
};
</script>

<style scoped>
.eventstandby {
  border-bottom: 3px solid #cfd8dc;
}

.eventsuccess {
  border-bottom: 3px solid #43a047;
}

.eventcancel {
  border-bottom: 3px solid #c62828;
}
</style>
