var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("Landscape"),
      _c(
        "v-expansion-panels",
        {
          model: {
            value: _vm.panel,
            callback: function ($$v) {
              _vm.panel = $$v
            },
            expression: "panel",
          },
        },
        [
          _c(
            "v-expansion-panel",
            [
              _c(
                "v-expansion-panel-header",
                {
                  staticClass: "text-uppercase blue-grey--text text--darken-2",
                  attrs: { "expand-icon": "fas fa-angle-down" },
                },
                [_vm._v(" Toutes les catégories ")]
              ),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      _vm._l(_vm.settingsList, function (setting) {
                        return [
                          _vm.isGranted(setting.role)
                            ? _c(
                                "v-col",
                                {
                                  key: setting.name,
                                  attrs: { cols: "6", md: "2", sm: "4" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mx-auto text-center",
                                      class: {
                                        activeCard:
                                          setting === _vm.currentSetting,
                                      },
                                      attrs: {
                                        hover: "",
                                        to: "/settings/" + setting.path,
                                        elevation: "1",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setCurrentSetting(setting)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-large": "" } },
                                            [
                                              _vm._v(
                                                _vm._s(setting.icon) + " fa-fw"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "text--primary mt-6",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(setting.name) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mt-8" },
            [
              _vm.currentSetting
                ? _c(
                    "h2",
                    { staticClass: "text-uppercase primary--text pl-3" },
                    [_vm._v(_vm._s(_vm.currentSetting.name))]
                  )
                : _vm._e(),
              _c("router-view"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }