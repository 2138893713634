<template>
  <v-card fluid>
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab v-for="(item, key) in items" :key="key" color="primary" dark elevation="0">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <CustomersMap />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <SectorSettings fromFeature />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import SectorSettings from "../settings/sectorSettings.vue";
import CustomersMap from "./CustomersMap/CustomersMap.vue";

export default {
  name: "Map",
  data() {
    return {
      tab: null,
      items: ["Clients", "Secteurs"],
    };
  },
  components: { SectorSettings, CustomersMap },
  mounted() {},
  methods: {},
};
</script>
