var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { hover: "" },
      on: {
        click: function ($event) {
          return _vm.openOpp(_vm.item.opp.id)
        },
      },
    },
    [
      _c(
        "v-card-title",
        [
          _vm.item.opp.stateId == 2
            ? [
                _vm.item.opp.statusId === 1
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "green--text text--darken-2 card-status--display",
                      },
                      [_vm._v("Gagné")]
                    )
                  : _vm._e(),
                _vm.item.opp.statusId === 2
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "red--text text--darken-2 card-status--display",
                      },
                      [_vm._v("Perdu")]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-1 my-0", attrs: { cols: "12" } },
                [
                  _c("div", { staticStyle: { width: "100%" } }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "white--text text--darken-3 text-no-wrap card-type--label",
                        style:
                          "background-color:" +
                          (_vm.item.typeArray ? _vm.item.typeArray.color : "") +
                          ";",
                        attrs: {
                          label: "",
                          small: "",
                          color: _vm.item.typeArray
                            ? _vm.item.typeArray.color
                            : "",
                        },
                      },
                      [
                        false
                          ? _c("v-icon", { staticClass: "mr-1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.item.typeArray
                                    ? _vm.item.typeArray.icon
                                    : ""
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.item.typeArray ? _vm.item.typeArray.name : ""
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                    _c("h6", { staticClass: "primary--text" }, [
                      _vm._v(" " + _vm._s(_vm.item.opp.id) + " - "),
                      _vm.item.entreprise
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "0.9rem",
                                color: "grey",
                              },
                            },
                            [_vm._v(_vm._s(_vm.item.entreprise.name))]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", [
                    _c("h6", { staticClass: "primary--text text--darken-1" }, [
                      _vm._v(_vm._s(_vm.item.opp.name)),
                    ]),
                  ]),
                  _vm.item.typeArray ? void 0 : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mt-2 py-1", attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "my-1" })],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pt-0 mt-0", attrs: { cols: "6" } },
                [
                  _c("v-rating", {
                    staticStyle: { "margin-left": "-8px" },
                    attrs: {
                      readonly: "",
                      small: "",
                      color: "yellow darken-2",
                      "background-color": "grey",
                      length: "4",
                      value: _vm.stars,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-right py-1 my-0", attrs: { cols: "6" } },
                [
                  _vm.item.opp.amount
                    ? _c("span", { staticStyle: { "font-size": "1rem" } }, [
                        _vm._v(_vm._s(_vm.numStr(_vm.item.opp.amount)) + " €"),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm.nextEvent
            ? [
                _c("v-divider", { staticClass: "my-1 mt-3" }),
                _c(
                  "v-container",
                  { staticClass: "pl-0", attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center", justify: "center" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "py-1 text-center text-lg-left",
                            attrs: { cols: "12", lg: "4" },
                          },
                          [
                            _c("v-icon", { attrs: { "x-small": "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.nextEvent.event.eventtypeId === 1
                                    ? "fa-phone"
                                    : "far fa-calendar-alt "
                                ) + " fa-fw "
                              ),
                            ]),
                            _vm.nextEvent.event.start
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .dayjs(_vm.nextEvent.event.start)
                                          .format("ddd DD/MM/YYYY HH:mm")
                                      ) +
                                      " "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "py-1 text-center text-caption",
                            staticStyle: { "line-height": "1.2em" },
                            attrs: { cols: "12", lg: "4" },
                          },
                          [
                            _vm.nextEvent.event.name
                              ? [
                                  _vm._v(
                                    " " + _vm._s(_vm.nextEvent.event.name) + " "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "font-italic py-1 text-center text-lg-right pr-0",
                            attrs: { cols: "12", lg: "4" },
                          },
                          [
                            _vm.nextEvent.contact
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.nextEvent.contact.firstname +
                                          " " +
                                          _vm.nextEvent.contact.lastname
                                      ) +
                                      " "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c("v-divider", { staticClass: "my-1 mb-2 mt-3" }),
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center text-lg-left pt-2 pb-1",
                  attrs: { cols: "12", lg: "4" },
                },
                [
                  _c("UserAvatar", {
                    attrs: { userId: _vm.item.opp.userId, size: "35" },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center mt-1 pb-1",
                  attrs: { cols: "12", lg: "4" },
                },
                [
                  _vm.item.opp.deadline
                    ? [
                        _c(
                          "h4",
                          {
                            class: {
                              "error--text": _vm.getComparation(
                                _vm.item.opp.deadline,
                                _vm.item.opp.statusId
                              ),
                              "mb-0 ": true,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .dayjs(_vm.item.opp.deadline)
                                    .format("DD/MM/YYYY")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.item.opp.stateId == 1
                          ? _c("span", { staticClass: "my-0 py-0" }, [
                              _vm._v(_vm._s(_vm.diffDate)),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center text-lg-right mt-1 pb-1",
                  attrs: { cols: "12", lg: "4" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left text-lg-right",
                          attrs: { cols: "6" },
                        },
                        [
                          _vm.comments.length > 0
                            ? [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "open-on-hover": "",
                                      "close-on-content-click": false,
                                      left: "",
                                      "offset-x": "",
                                      "max-width": "350",
                                      "min-width": "350",
                                      "content-class": "elevation-1 ",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("fa-comments ")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.comments.length
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      953269927
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        attrs: {
                                          elevation: "0",
                                          color: "#f9fafb",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          {
                                            staticClass:
                                              "text-uppercase py-1 pb-2 text-body-1",
                                          },
                                          [_vm._v("Commentaires ")]
                                        ),
                                        _c("v-divider"),
                                        _c(
                                          "v-card-text",
                                          _vm._l(
                                            _vm.comments,
                                            function (comments, key) {
                                              return _c(
                                                "div",
                                                { key: key },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "2" },
                                                        },
                                                        [
                                                          _c("UserAvatar", {
                                                            attrs: {
                                                              userId:
                                                                comments.userId,
                                                              size: "35",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "10" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "pa-2",
                                                              attrs: {
                                                                elevation: "1",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold grey--text text--darken-2 mb-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .dayjs(
                                                                          comments.createdAt
                                                                        )
                                                                        .format(
                                                                          "ddd DD/MM/YYYY à HH:mm"
                                                                        )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "text-caption",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      comments.body
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { cols: "6" } },
                        [
                          _vm.item.medias > 0
                            ? [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-2", attrs: { small: "" } },
                                  [_vm._v("fas fa-paperclip fa-fw")]
                                ),
                                _vm._v(" " + _vm._s(_vm.item.medias) + " "),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }