<template>
  <v-container fluid v-if="customer">
    <v-row>
      <v-col cols="12" v-if="customer.customer.obsolete && false" style="position: fixed; z-index: 20">
        <v-card class="pa-0 text-center" color="primary">
          <v-card-text class="pa-1" style="color: white">COMPTE EN SOMMEIL</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <titleCustomers v-model="customer" @refresh-all="getCustomer" @reload-contact="setReloadContact"></titleCustomers>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-row class="pa-0">
          <v-col cols="12" class="py-0">
            <opportunitiesCustomers v-model="customer"></opportunitiesCustomers>
          </v-col>
          <v-col cols="12" class="py-0">
            <Event v-model="customer" fromCustomers></Event>
          </v-col>
          <v-col cols="12" class="py-0">
            <compaign-customers></compaign-customers>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-row class="pa-0">
          <v-col cols="12" class="py-0">
            <contactCustomers @refresh-all="getCustomer" v-model="customer" :reloadContact="reloadContact"></contactCustomers>
          </v-col>
          <v-col cols="12" class="py-0">
            <emailList v-model="customer" @refresh-all="getCustomer"></emailList>
          </v-col>
          <v-col cols="12" class="py-0" v-if="customer.customer.id"> <heliosCustomers v-model="customer" /> </v-col>
          <v-col cols="12" class="py-0">
            <statsCustomers></statsCustomers>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleCustomers from "./titleCustomers";
import ContactCustomers from "./contactCustomers";
import CompaignCustomers from "./compaignCustomers";
import OpportunitiesCustomers from "./opportunitiesCustomers";
import EmailList from "../../components/common/emailList";
import Tools from "../../mixins/Tools";
import StatsCustomers from "./statsCustomers.vue";
import Event from "../../components/common/Event.vue";
import HeliosCustomers from "./heliosCustomers.vue";

export default {
  name: "singleCustomer",
  components: {
    CompaignCustomers,
    EmailList,
    OpportunitiesCustomers,
    ContactCustomers,
    TitleCustomers,
    StatsCustomers,
    Event,
    HeliosCustomers,
  },
  mixins: [Tools],
  data() {
    return {
      customer: false,
      reloadContact: false,
    };
  },
  mounted() {
    this.getCustomer();
  },
  methods: {
    setReloadContact() {
      this.reloadContact = true;
    },
    getCustomer() {
      this.$axios("/api/customers/" + this.$route.params.id, { params: { heliosLastMonths: 6 } }).then((resp) => {
        this.customer = resp.data;
      });
    },
  },
  watch: {
    "$route.params.id"() {
      this.customer = false;
      this.getCustomer();
    },
  },
};
</script>

<style scoped></style>
