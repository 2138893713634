<template>
  <material-card title="Caractéristiques">
    <div style="position: relative">
      <div style="position: absolute; right: 5px; top: -5px">
        <v-text-field v-model="search" label="Rechercher"></v-text-field>
      </div>
      <v-data-table :headers="headers" :items="tab" :items-per-page="10" :search="search" class="elevation-1"> </v-data-table>
    </div>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
export default {
  name: "caracteristiqueSupervision",
  components: { MaterialCard },
  props: ["value"],
  data() {
    return {
      item: this.value,
      tab: [],
      search: "",
      headers: [
        { text: "Caractéristique", value: "name" },
        { text: "Valeur", value: "value" },
      ],
    };
  },
  mounted() {
    this.initArray();
  },
  methods: {
    initArray() {
      this.tab = [];
      Object.keys(this.item.item.data).forEach((key) => {
        let tmp = { name: key, value: this.item.item.data[key] };
        this.tab.push(tmp);
      });
    },
  },
  watch: {
    item() {
      this.$emit("input", this.item);
    },
    value() {
      this.item = this.value;
      this.initArray();
    },
  },
};
</script>

<style scoped></style>
