<template>
  <material-card title="Contacts" icon="fa-users" class="mt-8" id="contact">
    <v-container fluid>
      <v-row>
        <v-col :offset="compaign.contactsType !== 'filter' ? 2 : 1" :cols="2" class="text-right">
          <v-switch v-model="compaign.contactsType" value="all" label="Tous"></v-switch>
        </v-col>
        <v-col :cols="2">
          <v-switch v-model="compaign.contactsType" value="favorite" label="Favoris"></v-switch>
        </v-col>
        <v-col cols="2">
          <v-switch v-model="compaign.contactsType" value="none" label="Aucun"></v-switch>
        </v-col>
        <v-col :cols="compaign.contactsType !== 'filter' ? 3 : 4">
          <v-row>
            <v-col cols="2">
              <v-switch v-model="compaign.contactsType" value="filter" label="Filtrer"></v-switch>
            </v-col>
            <v-col cols="10">
              <v-select
                :menu-props="{ maxHeight: '300' }"
                v-if="compaign.contactsType === 'filter'"
                :items="jobs"
                label="Filtrer par rôle"
                v-model="compaign.contactsFilter"
                item-text="name"
                item-value="id"
                multiple
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Voir les contacts ciblés
          <span class="ml-2"
            ><v-chip label small v-if="compaign.exclude.length > 0"
              >{{ compaign.exclude.length }} exclusion{{ compaign.exclude.length > 1 ? "s" : "" }}</v-chip
            ></span
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="contacts"
            :page="page"
            :items-per-page="limitItem"
            :loading="isLoading"
            class="elevation-1"
            style="z-index: 1000"
            :server-items-length="maxItem"
            @update:page="setPage"
            @click:row="addOrRemove"
            @update:items-per-page="setItemMax"
            :footer-props="{
              'items-per-page-options': [10, 20, 50, 100],
            }"
          >
            <template v-slot:item.exclusion="{ item }">
              <v-checkbox v-model="compaign.exclude" :value="item.contact.id" disabled> </v-checkbox>
            </template>

            <template v-slot:item.contact.civilityId="{ item }">
              <template v-if="item.contact.civilityId === 0"> Monsieur </template>
              <template v-else-if="item.contact.civilityId === 1"> Madame </template>
              <template v-else-if="item.contact.civilityId === 2"> Mademoiselle </template>
              <template v-else> N/D </template>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-container fluid>
      <span class="text-overline"
        >{{ maxItem - compaign.exclude.length }} contact{{ maxItem - compaign.exclude.length > 1 ? "s" : "" }} séléctionné{{
          maxItem - compaign.exclude.length > 1 ? "s" : ""
        }}</span
      >
    </v-container>
  </material-card>
</template>

<script>
import MaterialCard from "../../../../components/common/MaterialCard";
import Tools from "../../../../mixins/Tools";
import simpleDebounce from "simple-debounce-vue-mixin";
export default {
  name: "contactFormCompaign",
  components: { MaterialCard },
  props: ["value"],
  mixins: [Tools, simpleDebounce],
  data() {
    return {
      page: 1,
      limitItem: 10,
      maxItem: 0,
      compaign: this.value,
      isLoading: false,
      jobs: [],
      contacts: [],
      search: {
        lastname: "",
        firstname: "",
        function: "",
        phone: "",
        phone2: "",
        email: "",
        job: null,
        favorite: false,
        sortedBy: null,
        sortedDesc: false,
      },
      headers: [
        { text: "Exclure", value: "exclusion", sortable: false },
        { text: "Raison sociale", value: "customer[0].name", sortable: false },
        { text: "Nom", value: "contact.lastname", sortable: false },
        { text: "Prénom", value: "contact.firstname", sortable: false },
        { text: "Fonction", value: "contact.function", sortable: false },
        { text: "Téléphone", value: "contact.phone", sortable: false },
        { text: "Mobile", value: "contact.phone2", sortable: false },
        { text: "Email", value: "contact.email", sortable: false },
      ],
    };
  },
  mounted() {
    this.getJobs();
    if (this.compaign.id) {
      this.search.job = this.compaign.contactsFilter;
      if (this.compaign.contactsType === "favorite") {
        this.search.favorite = true;
      }
      this.getContacts();
    }
  },
  methods: {
    addOrRemove(value) {
      let index = this.compaign.exclude.indexOf(value.contact.id);

      if (index === -1) {
        this.compaign.exclude.push(value.contact.id);
      } else {
        this.compaign.exclude.splice(index, 1);
      }
      this.$forceUpdate();
    },
    getJobs() {
      this.$axios("/api/jobs").then((resp) => {
        this.jobs = resp.data;
      });
    },
    getContacts() {
      this.isLoading = true;
      let search = this.search;
      let page = this.page;
      this.contacts = [];
      if (this.compaign.customers.length === 0) {
        this.maxItem = 0;
        this.isLoading = false;
        return;
      }
      if (this.compaign.contactsType === "none") {
        this.isLoading = false;
        this.contacts = [];
        this.maxItem = 0;
      } else {
        this.$axios
          .post("/api/contacts/get/campaign", {
            page: page,
            search,
            limit: this.limitItem,
            onlyEmail: this.compaign.phases[1].emailing,
            asUser: this.asUser,
            customers: this.compaign.customers,
          })
          .then((res) => {
            this.contacts = res.data.data;
            this.maxItem = res.data.max;
            this.isLoading = false;
          });
      }
    },
    setItemMax(event) {
      this.limitItem = event;
    },
    setPage(event) {
      this.page = event;
    },
    getContactsDebounce() {
      this.simpleDebounce(() => this.getContacts(), 500);
    },
  },
  watch: {
    "compaign.contactsFilter"() {
      this.search.job = this.compaign.contactsFilter;
      this.getContacts();
    },
    "search.favorite"() {
      this.getContacts();
    },
    "compaign.contactsType"() {
      if (this.compaign.contactsType === null) {
        this.compaign.contactsType = "all";
        this.$forceUpdate();
      }
      if (this.compaign.contactsType === "favorite") {
        this.search.favorite = true;
      } else {
        this.search.favorite = false;
      }

      if (this.compaign.contactsType === "none") {
        this.contacts = [];
        this.maxItem = 0;
      }

      if (this.compaign.contactsType !== "filter") {
        this.search.job = null;
      }
    },
    page() {
      this.getContactsDebounce();
    },
    limitItem() {
      this.getContactsDebounce();
    },
    value() {
      this.search.job = this.compaign.contactsFilter;
      this.compaign = this.value;
      if (this.compaign.contactsType === "favorite") {
        this.search.favorite = true;
      }
      this.getContacts();
    },
    compaign() {
      this.$emit("input", this.compaign);
    },
    "compaign.customers"() {
      this.getContactsDebounce();
    },
  },
};
</script>

<style scoped></style>
