<template>
  <v-dialog v-model="dialog" :max-width="maxWidth" width="width" scrollable>
    <v-card class="text-center">
      <v-toolbar color="primary" dark elevation="0">
        <v-row class="p-0 m-0 text-left">
          <v-col cols="12" large>
            <slot name="header"></slot>
            <v-icon aria-label="Close" @click="closeModal" style="position:absolute;top:15px;right:15px;">
              fa-times
            </v-icon>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-card-text :class="noPadding ? 'pa-0' : 'py-3'">
        <slot></slot>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions">
          <v-btn color="secondary" text @click="dialog = false">
            Fermer
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "modal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: "500",
    },

    title: {
      type: String,
      default: "",
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeModal() {
      this.dialog = false;
      this.onClose();
    },
  },
  watch: {
    value() {
      this.dialog = this.value;
    },
    dialog() {
      this.$emit("input", this.dialog);
    },
  },
};
</script>

<style scoped></style>
