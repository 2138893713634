<template>
  <v-menu
      ref="menu1"
      v-model="menuTime"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="time"
          label="Heure"
          :outlined="outlined"
          v-bind="attrs"
          :disabled="disabled"
          v-on="on"
          hide-details
      ></v-text-field>
    </template>
    <v-card width="460">
      <v-toolbar class="primary white--text" elevation="0">
      <v-card-title class="px-0 text-uppercase">Choix de l'heure</v-card-title></v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="9" class="mt-0 pt-0 pr-6" style="border-right: solid 1px lightgray" pr-5>
            <v-card-subtitle class="primary--text">Heure</v-card-subtitle>
            <v-row>
              <v-col cols="2"  class="mt-0 pt-1"  v-for="(i, key) in hours"  :key="key" @click="setHour(i)">
                <v-btn fab small :text="h != i"  elevation="0" :color="h == i ? 'primary':''" >{{i}}</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3"  class="mt-0 pt-0">
            <v-card-subtitle class="primary--text text-md-center ">Minutes</v-card-subtitle>
            <v-row>
              <v-col cols="3" md="12"    class="mt-0 pt-1 text-center"  v-for="(i, key) in minuteStep"  :key="key" @click="setMinutes(i)">
                <v-btn fab small :text="m != i"  elevation="0" :color="m == i ? 'primary':''">{{i}}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "timePicker",
  props: {
    value: {
      default: "09:00",
    },
    outlined: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
  },
  data(){
    return {
      menuTime: false,
      time: this.value,
      hours: [7,8,9,10,11,12,13,14,15,16,17,18,19,20,21],
      minuteStep: [0, 15, 30, 45],

    }
  },
  methods:{
    setHour(h){
      let [hours, minutes] = this.time.split(':');
      hours = h;
      this.time =  (hours < 10 ? '0' + hours : hours)  + ':' + minutes;
    },
    setMinutes(m){
      let [hours, minutes] = this.time.split(':');
      minutes = m;
      this.time =  hours + ':' + (minutes < 10 ? '0' + minutes : minutes);
      this.menuTime = false;
    },
  },
  watch:{
    value(){
      this.time = this.value
    },
    time(){
      this.$emit('input', this.time);
    }
  },
  computed:{
    h(){
     return this.time.split(':')[0];
    },
    m(){
     return this.time.split(':')[1];
    }
  }
}
</script>

<style scoped>

</style>