<template>
  <div>
    <v-row class="py-0">
      <v-col cols="11">
        <step-phase-compaign v-model="compaign"></step-phase-compaign>
      </v-col>
      <v-col cols="1" class="text-right">
        <v-btn small fab color="primary" @click="editRoute"><v-icon small>fa-fw fa-edit</v-icon></v-btn>

        <v-btn small fab color="error" class="ml-4 mr-1" @click="deleteRoute"><v-icon small>fa-fw fa-trash-alt</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row class="py-0 mt-4">
      <v-col cols="12" lg="6">
        <info-compaign v-model="compaign"></info-compaign>
      </v-col>
      <v-col cols="12" lg="6">
        <stats-compaign></stats-compaign>
      </v-col>
    </v-row>
    <v-row class="py-0 mt-4">
      <v-col cols="12" lg="6">
        <goal-compaign v-model="curItem"></goal-compaign>
      </v-col>
      <v-col cols="12" lg="6">
        <event-compaign v-model="compaign" :cur-item.sync="curItem"></event-compaign>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InfoCompaign from "./informations/infoCompaign";
import StepPhaseCompaign from "./steps/stepPhaseCompaign";
import GoalCompaign from "./goal/goalCompaign";
import StatsCompaign from "./stats/statsCompaign";
import EventCompaign from "./event/eventCompaign";

export default {
  name: "singleCompaign",
  components: { EventCompaign, StatsCompaign, GoalCompaign, StepPhaseCompaign, InfoCompaign },
  data() {
    return {
      compaign: null,
      curItem: null,
    };
  },
  mounted() {
    this.getCompaign();
  },
  methods: {
    async deleteRoute() {
      let res = await this.$confirm("Êtes-vous sûr ?");
      if (res) {
        this.$axios.delete("/api/compaign/" + this.$route.params.id).then(() => {
          this.$simpleToast.success("Campagne", "L'élément a bien été supprimé");
          this.$router.push({ name: "Campagnes" });
        });
      }
    },
    editRoute() {
      this.$router.push({ name: "Campagne Edition", params: { id: this.$route.params.id } });
    },
    getCompaign() {
      this.$axios("/api/compaign/" + this.$route.params.id).then((resp) => {
        this.compaign = resp.data;
      });
    },
  },
  watch: {},
};
</script>

<style scoped></style>
