var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pb-0 mb-0 py-0 my-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        {
          staticStyle: { "z-index": "99" },
          attrs: { elevation: 0, flat: true },
        },
        [
          _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _vm.underDev
                ? _c(
                    "v-chip",
                    {
                      staticStyle: {
                        position: "absolute",
                        bottom: "20px",
                        right: "10px",
                        "z-index": "9000",
                      },
                      attrs: { color: "red", "text-color": "white", close: "" },
                      on: {
                        "click:close": function ($event) {
                          _vm.underDev = false
                        },
                      },
                    },
                    [_vm._v("Outil en cours de développement")]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "20%",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    "z-index": "9000",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticStyle: {
                        "background-color": "rgba(255, 255, 255, 0.95)",
                        width: "100%",
                      },
                    },
                    [
                      _c(
                        "v-toolbar",
                        {
                          attrs: {
                            color: "primary ",
                            dark: "",
                            elevation: "0",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "p-0 m-0 text-left" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-uppercase text-body-1",
                                  attrs: { cols: "12", large: "" },
                                },
                                [_vm._v(" Recherche ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("list-user", {
                            attrs: {
                              multiple: false,
                              includeAll: "",
                              user: _vm.$store.state.user.id,
                            },
                            on: { selectChange: _vm.setAsUser },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            staticClass: "px-3",
                            attrs: {
                              items: _vm.types,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Choisir une infrastructure",
                              clearable: "",
                            },
                            on: { change: _vm.getCustomers },
                            model: {
                              value: _vm.search.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "type", $$v)
                              },
                              expression: "search.type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "px-3",
                            attrs: { label: "Nom" },
                            model: {
                              value: _vm.search.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "name", $$v)
                              },
                              expression: "search.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "justify-end mr-2" },
                        [
                          _vm.loading
                            ? _c("LoaderActuelburo")
                            : _c(
                                "span",
                                { staticClass: "grey--text text-overline" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.customersList.length) +
                                      " résultats"
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.filteredCustomers.length
                    ? _c(
                        "v-card",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "v-simple-table",
                            {
                              staticStyle: {
                                "max-height": "250px",
                                "overflow-y": "auto",
                              },
                              attrs: { dense: "" },
                            },
                            [
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.filteredCustomers,
                                  function (customer) {
                                    return _c(
                                      "tr",
                                      {
                                        key: customer.id,
                                        on: {
                                          click: function ($event) {
                                            return _vm.openPopup(customer)
                                          },
                                        },
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(_vm._s(customer.name)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "l-map",
                {
                  ref: "map",
                  staticStyle: { height: "calc(95vh - 70px)" },
                  attrs: { zoom: 10, center: _vm.center },
                  on: {
                    moveend: _vm.updateVisibleMarkers,
                    zoomend: _vm.updateVisibleMarkers,
                  },
                },
                [
                  _c("l-tile-layer", {
                    attrs: { url: _vm.url, attribution: _vm.attribution },
                  }),
                  _vm.departementGeo
                    ? _c("l-geo-json", {
                        attrs: {
                          geojson: _vm.departementGeo,
                          "options-style": _vm.styleDepartement,
                        },
                      })
                    : _vm._e(),
                  _vm.canton && _vm.init
                    ? _c("l-geo-json", {
                        attrs: {
                          geojson: _vm.canton,
                          "options-style": _vm.style,
                          options: _vm.options,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "l-marker-cluster",
                    { attrs: { options: _vm.clusterOptions } },
                    _vm._l(
                      _vm.customersList.filter(
                        (item) => item.gps.lat && item.gps.lng
                      ),
                      function (customer, index) {
                        return _c(
                          "l-marker",
                          {
                            key: index,
                            attrs: {
                              "lat-lng": [
                                parseFloat(customer.gps.lat).toFixed(7),
                                parseFloat(customer.gps.lng).toFixed(7),
                              ],
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openPopup(customer)
                              },
                            },
                          },
                          [
                            _c(
                              "l-popup",
                              {
                                staticStyle: {
                                  width: "400px",
                                  "z-index": "99999",
                                },
                              },
                              [
                                _vm.openPopups[index]
                                  ? _c(
                                      "v-card",
                                      { attrs: { elevation: "1" } },
                                      [
                                        _c(
                                          "v-toolbar",
                                          {
                                            attrs: {
                                              color: "primary ",
                                              dark: "",
                                              elevation: "0",
                                            },
                                          },
                                          [
                                            _c("v-card-title", [
                                              _vm._v(
                                                _vm._s(customer?.name) + " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "pa-2" }, [
                                          _c(
                                            "div",
                                            { staticClass: "mb-5" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-button font-weight-medium",
                                                },
                                                [_vm._v("Informations")]
                                              ),
                                              _c("v-divider", {
                                                staticClass: "mb-2",
                                              }),
                                              _c(
                                                "v-row",
                                                { staticClass: "font-size-14" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold",
                                                        },
                                                        [_vm._v("TEL : ")]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            customer?.phone
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold",
                                                        },
                                                        [_vm._v("E-MAIL : ")]
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: `mailto:${customer?.email}`,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              customer?.email
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-button font-weight-medium",
                                                },
                                                [_vm._v("Commerciaux")]
                                              ),
                                              _c("v-divider", {
                                                staticClass: "mb-2",
                                              }),
                                              _c(
                                                "v-row",
                                                { staticClass: "pa-4" },
                                                _vm._l(
                                                  customer.commercials,
                                                  function (user, key) {
                                                    return _c(
                                                      "v-tooltip",
                                                      {
                                                        key: key,
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "UserAvatar",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              userId:
                                                                                user.id,
                                                                              size: "40",
                                                                            },
                                                                        },
                                                                        "UserAvatar",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(user.name)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "justify-end mt-5",
                                                },
                                                [
                                                  customer.customerId
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "text-uppercase font-size-10 self-end mr-4 mb-4",
                                                          attrs: {
                                                            color: "primary",
                                                            elevation: "2",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$router.push(
                                                                {
                                                                  name: "Client",
                                                                  params: {
                                                                    id: customer.customerId,
                                                                  },
                                                                }
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Accéder au compte "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }