<template>
  <v-card
    :height="heightTot"
    :color="background"
    v-bind="$attrs"
    style="z-index: 10"
    :class="classes"
    :elevation="elevation"
    class="v-card--material pa-1 mx-1"
  >
    <div class="d-flex grow flex-wrap">
      <UserAvatar v-if="avatar" :userId="avatar" size="53" class="mx-auto v-card--material__avatar elevation-6" color="grey" />
      <v-chip v-if="avatarText" label large class="mx-auto v-card--material__avatar elevation-6 white--text" :color="color">
        <h1>{{ avatarText }}</h1>
      </v-chip>

      <v-sheet
        rounded
        v-if="!avatar && header && !title"
        :class="{
          'pa-1': !$slots.image,
        }"
        :color="color"
        :max-height="!isMobile() ? 60 : 'auto'"
        :width="'auto'"
        elevation="0"
        class="text-start v-card--material__heading mb-n6"
        dark
      >
        <slot v-if="$slots.heading" name="heading" />

        <slot v-else-if="$slots.image" name="image" />

        <div v-else-if="title && !icon" class="display-1 font-weight-light" v-text="title" />

        <v-icon v-else-if="icon" small v-text="icon" />

        <div v-if="text" class="headline font-weight-thin" v-text="text" />
      </v-sheet>

      <div v-if="$slots['after-heading']" class="ml-6">
        <slot name="after-heading" />
      </div>

      <div v-else-if="icon || title" class="ml-4">
        <v-card
          label
          :color="color"
          text-color="white"
          class="card-title font-weight-bold pa-1"
          dark
          elevation="0"
          style="margin-top: -20px; width: 150px; text-align: left; display: block;"
        >
          <v-icon class="mx-1 " x-small v-if="icon">{{ icon }} fa-fw</v-icon>
          {{ title }}
        </v-card>
      </div>
    </div>

    <div :style="{ marginTop: $slots.heading ? 0 : '25px' }">
      <slot />
    </div>

    <template v-if="$slots.actions">
      <v-divider class="mt-2" />

      <v-card-actions class="pb-0">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import MobileDetection from "@/mixins/MobileDetection";
import UserAvatar from "../user/UserAvatar.vue";

export default {
  name: "MaterialCard",
  mixins: [MobileDetection],
  props: {
    avatar: {
      type: String,
      default: "",
    },
    avatarText: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    heightTot: {
      type: String,
      default: "none",
    },
    elevation: {
      type: String,
      default: "1",
    },
    background: {
      type: String,
      default: "#ffffff",
    },
    icon: {
      type: String,
      default: undefined,
    },
    image: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    classes() {
      return {
        "v-card--material--has-heading": this.hasHeading,
      };
    },
    hasHeading() {
      return Boolean(this.$slots.heading || this.title || this.icon);
    },
    hasAltHeading() {
      return Boolean(this.$slots.heading || (this.title && this.icon));
    },
  },
  components: { UserAvatar },
};
</script>

<style lang="sass">
.v-card--material
  &__avatar
    position: relative
    top: -32px
    margin-bottom: -32px

  &__heading
    position: relative
    top: -40px
    transition: .3s ease
    z-index: 1
</style>
