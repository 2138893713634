<template :style="isMobile() {marginTop: '50px'} : {}">
  <modal max-width="1024" v-model="modalVal">
    <template v-slot:header>
      <strong class="text-h5 mb-0 text-uppercase"> Création concurrent</strong>
      <br /><small class="mt-0">Commencez par saisir le N° de SIRET</small>
    </template>

    <v-alert dense type="error" v-if="alreadySiret">
      <div>Un compte existe déjà avec ce SIRET:</div>
      <div>{{ alreadySiret.name }}</div>
      <div>
        <v-btn color="secondary" v-if="isGranted('CONCURRENTS_VIEW') && link === true" @click="linkItem()">Lier le compte </v-btn>
      </div>
    </v-alert>
    <v-row>
      <v-col cols="12" md="6">
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" maxlength="35" v-model="concurrent.name" hide-details="true" outlined label="Intitulé"></v-text-field>
        </v-col>

        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" maxlength="14" v-model="concurrent.siret" outlined label="SIRET" hide-details="true"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" v-model="concurrent.address" hide-details="true" :maxlength="35" outlined label="Adresse"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" v-model="concurrent.address2" hide-details="true" :maxlength="35" outlined label="Complément"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" v-model="concurrent.postalCode" hide-details="true" :maxlength="5" outlined label="Code postal"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-1" v-model="concurrent.city" hide-details="true" :maxlength="35" outlined label="Ville"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-switch v-model="concurrent.prestataire" label="Prestataire" class="pa-0 ma-0 mb-3"></v-switch>
        </v-col>
      </v-col>
      <v-col cols="12" md="6">
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field
            class="pa-0 ma-0 mb-0"
            persistent-hint
            type="email"
            :error="concurrent && concurrent.email && concurrent.email.length === 0"
            hint="Obligatoire pour la synchronisation avec SAGE"
            outlined
            v-model="concurrent.email"
            label="Email"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" v-model="concurrent.ape" outlined label="APE"></v-text-field>
        </v-col>

        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" hide-details="true" v-model="concurrent.phone" outlined label="Téléphone"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" hide-details="true" v-model="concurrent.phone2" outlined label="FAX"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-text-field class="pa-0 ma-0 mb-3" hide-details="true" v-model="concurrent.site" outlined label="Site Web" type="url"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 py-1 ma-0">
          <v-alert
            dense
            border="top"
            colored-border
            elevation="2"
            x-small
            type="info"
            color="primary"
            v-if="this.$store.state.settings.sage"
            class="text-left pt-3"
          >
            <div class="caption">
              <h3 class="text-h6">Norme N° Concurrent</h3>
              <ul>
                <li>NOM de l’entreprise sans SARL, SA, EURL, GROUPE…</li>
                <li>M + VILLE si Mairie</li>
                <li>CC + Ville si Communauté de Communes</li>
                <li>A défaut on appelle le service COMPTA/ADV</li>
              </ul>
            </div>
          </v-alert>
        </v-col>
      </v-col>
    </v-row>

    <template v-slot:actions>
      <v-btn
        @click="saveClient"
        :disabled="alreadySiret !== null && alreadySiret.id === concurrent.siret ? true : false"
        variant="flat"
        color="primary"
        elevation="0"
        depressed
        class="rounded-1 px-3"
        small
      >
        <v-icon x-small class="mr-2">fas fa-check fa-fw</v-icon>
        Enregistrer
      </v-btn>
      <v-btn color="secondary" text class="mr-4" @click="modalVal = false"> Fermer </v-btn>
    </template>
  </modal>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";
import MobileDetection from "../../mixins/MobileDetection";

export default {
  name: "editFormConcurrent",
  components: { Modal },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    concurrentData: {
      default: () => {},
      type: Object,
    },
  },
  mixins: [Tools, simpleDebounce, MobileDetection],
  data() {
    return {
      modalVal: this.value,
      concurrent: this.concurrentData,
      alreadySiret: null,
      types: [],
    };
  },
  mounted() {
    this.getTypes();
  },
  methods: {
    getTypes() {
      this.$axios("/api/types/active").then((resp) => {
        this.types = resp.data.types;
      });
    },
    saveClient() {
      // this.concurrent.name = this.formatName(this.concurrent.name);
      if (this.concurrent.name.length > 35) {
        this.$simpleToast.error("Concurrent", "Le nom est trop long");
        return;
      }

      if (this.concurrent.ape) {
        this.concurrent.ape = this.concurrent.ape.replace(".", "");
      }
      if (this.concurrent.id !== null) {
        this.$axios
          .patch("/api/concurrents/" + this.concurrent.id, {
            concurrent: this.concurrent,
          })
          .then(() => {
            this.$simpleToast.success("Concurrent", "Le compte a bien été modifié");
            this.modalVal = false;
            this.$emit("refresh");
          });
      } else {
        this.$axios
          .post("/api/concurrents", {
            concurrent: this.concurrent,
          })
          .then(() => {
            this.$simpleToast.success("Concurrent", "Le compte a bien été ajouté");
            this.modalVal = false;
            this.$emit("refresh");
          });
      }
    },
    formatName(name) {
      let Arr = ["SELARL ", "SOC ", "EURL ", "GROUPE ", "SA ", "SCI ", "SARL ", "SCIC ", "SCP "];
      let ret = name;
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = name.substring(start.length);
        }
      }

      Arr = ["MAIRIE DE ", "COMMUNE DE "];
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = "M" + name.substring(start.length);
        }
      }

      Arr = ["LYCEE PROFESSIONNEL "];
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = "LP" + name.substring(start.length);
        }
      }

      Arr = ["COMMUNAUTE DE COMMUNES "];
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = "CC" + name.substring(start.length);
        }
      }
      return ret
        .trim()
        .replace(/[^a-z0-9]/gi, "")
        .substring(0, 17);
    },
  },
  watch: {
    concurrentData() {
      this.concurrent = this.concurrentData;
    },
    "concurrent.siret"() {
      this.simpleDebounce(() => {
        if (this.concurrent.id !== null) {
          return;
        } else {
          this.alreadySiret = null;
          if (this.concurrent.siret.length == 17) {
            this.concurrent.siret = this.concurrent.siret.replaceAll(" ", "");
          }
          if (this.concurrent.siret.length === 14) {
            this.$axios("/api/concurrents/siret", { params: { siret: this.concurrent.siret } }).then((resp) => {
              if (resp.data !== null && resp.data.length > 0) {
                this.alreadySiret = resp.data;
              } else {
                this.$axios("/api/customers/siretData", {
                  params: {
                    siret: this.concurrent.siret,
                  },
                })
                  .catch((err) => {
                    this.$simpleToast.error("Concurrent", err.response.data.header.message);
                  })
                  .then((resp) => {
                    console.log(resp.data.etablissement);
                    let tmp = resp.data.etablissement;
                    this.concurrent.name = tmp.uniteLegale.denominationUniteLegale ? tmp.uniteLegale.denominationUniteLegale.substr(0, 35) : "";
                    this.concurrent.ape = tmp.uniteLegale.activitePrincipaleUniteLegale ? tmp.uniteLegale.activitePrincipaleUniteLegale.replace(".", "") : "";
                    this.concurrent.address =
                      (tmp.adresseEtablissement.numeroVoieEtablissement ? tmp.adresseEtablissement.numeroVoieEtablissement + " " : "") +
                      (tmp.adresseEtablissement.typeVoieEtablissement ? tmp.adresseEtablissement.typeVoieEtablissement : "") +
                      " " +
                      tmp.adresseEtablissement.libelleVoieEtablissement;
                    this.concurrent.address2 = tmp.adresseEtablissement.complementAdresseEtablissement;
                    this.concurrent.postalCode = tmp.adresseEtablissement.codePostalEtablissement;
                    this.concurrent.city = tmp.adresseEtablissement.libelleCommuneEtablissement;
                    this.concurrent.siege = tmp.etablissementSiege;
                    this.$forceUpdate();
                  });
              }
            });
          }
        }
      }, 300 /* optional debounce time: default is 300 */);
    },
    value() {
      this.modalVal = this.value;
    },
    modalVal() {
      this.$emit("input", this.modalVal);
    },
  },
};
</script>

<style scoped></style>
