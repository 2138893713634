<template>
  <modal v-model="refreshModal">
    <template v-slot:header>
      <strong class="text-h5 mb-0 text-uppercase">Mise à jour</strong>
      <br /><small class="mt-0">La mise à jour est conseillée</small>
    </template>
    <div class="text-left py-4">
      <p>Une nouvelle mise à jour est disponible.</p>
      <p>
        Vous pouvez ignorer la mise à jour et la faire ultérieurement. <br />
        Nous vous conseillons de maintenir à jour l'application.
      </p>
    </div>
    <template v-slot:actions>
      <v-btn color="primary" variant="flat" elevation="0" @click="refreshPage">Rafraîchir</v-btn>
      <v-btn text color="error" @click="toggleRefreshModal()">Ignorer</v-btn>
    </template>
  </modal>
</template>

<script>
import modal from "./modal.vue";

export default {
  name: "SWRefresh",
  components: { modal },
  props: ["toggleRefreshModal", "refreshModal"],
  data() {
    return {};
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
  },
};
</script>
