<template>
  <div>
    <v-row>
      <v-col cols="6" class="py-0">
        <v-radio-group v-model="newContact.civilityId">
          <v-radio label="Monsieur" :value="0"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-radio-group v-model="newContact.civilityId">
          <v-radio label="Madame" :value="1"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field v-model="newContact.firstname" outlined label="Prenom"></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field v-model="newContact.lastname" outlined label="Nom"></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field v-model="newContact.phone" outlined label="Téléphone"></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field v-model="newContact.phone2" outlined label="Mobile"></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0" v-if="false">
        <v-text-field v-model="newContact.fax" outlined label="Fax"></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field v-model="newContact.email" outlined label="Email"></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0" v-if="false">
        <v-text-field v-model="newContact.function" outlined label="Fonction"></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-autocomplete
          :menu-props="{ maxHeight: '300' }"
          :items="jobs"
          v-model="newContact.job"
          item-text="name"
          item-value="id"
          label="Fonction"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" v-if="!status" class="py-0">
        <selectConcurrents v-model="newContact.customerId" :icon="false" label="Rechercher un compte"></selectConcurrents>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-textarea v-model="newContact.note" outlined label="Note"></v-textarea>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-switch v-model="newContact.isFavorite" label="Contact favoris"></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SelectConcurrents from "../../components/concurrents/selectConcurrents";
export default {
  name: "contactForm",
  components: { SelectConcurrents },
  props: {
    value: {},
    status: {
      default: true,
    },
  },
  data() {
    return {
      searchCustomer: null,
      newContact: this.value,
      jobs: [],
    };
  },
  mounted() {
    this.getJobs();
  },
  methods: {
    getJobs() {
      this.$axios("/api/jobs").then((resp) => {
        this.jobs = resp.data;
      });
    },
  },
  watch: {
    newContact() {
      this.$emit("input", this.newContact);
    },
    value() {
      this.newContact = this.value;
    },
  },
};
</script>

<style scoped></style>
