var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticStyle: { "margin-top": "-30px" }, attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          false
            ? _c(
                "v-col",
                { staticClass: "my-0 pa-0 mb-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" fa-arrow-left "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "my-0 px-0 py-0", attrs: { cols: "12" } },
            [
              _c("customerOpportunities", {
                model: {
                  value: _vm.opportunity,
                  callback: function ($$v) {
                    _vm.opportunity = $$v
                  },
                  expression: "opportunity",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("stepsOpportunity", {
                on: {
                  "refresh-all": function ($event) {
                    return _vm.getInfo()
                  },
                },
                model: {
                  value: _vm.opportunity,
                  callback: function ($$v) {
                    _vm.opportunity = $$v
                  },
                  expression: "opportunity",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-0 py-0", attrs: { cols: "12" } },
            [
              _c("formEditOpportunities", {
                model: {
                  value: _vm.opportunity,
                  callback: function ($$v) {
                    _vm.opportunity = $$v
                  },
                  expression: "opportunity",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "px-0 py-0",
              attrs: { cols: "12", md: "4", lg: "3" },
            },
            [
              _vm.opportunity && _vm.opportunity.opp
                ? _c("descriptionOpportunities", {
                    model: {
                      value: _vm.opportunity,
                      callback: function ($$v) {
                        _vm.opportunity = $$v
                      },
                      expression: "opportunity",
                    },
                  })
                : _vm._e(),
              _vm.opportunity && _vm.opportunity.opp
                ? _c("compaignOpportunities", { staticClass: "mt-8" })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "px-0 py-0",
              attrs: { cols: "12", md: "5", lg: "6" },
            },
            [
              _c("Event", {
                attrs: { fromOpportunities: "" },
                model: {
                  value: _vm.opportunity,
                  callback: function ($$v) {
                    _vm.opportunity = $$v
                  },
                  expression: "opportunity",
                },
              }),
              _c("emailList", {
                on: {
                  "refresh-all": function ($event) {
                    return _vm.getInfo()
                  },
                },
                model: {
                  value: _vm.opportunity,
                  callback: function ($$v) {
                    _vm.opportunity = $$v
                  },
                  expression: "opportunity",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-0 py-0", attrs: { cols: "12", md: "3" } },
            [
              _c("concurrentOpportunities", {
                on: {
                  "refresh-all": function ($event) {
                    return _vm.getInfo()
                  },
                },
                model: {
                  value: _vm.opportunity,
                  callback: function ($$v) {
                    _vm.opportunity = $$v
                  },
                  expression: "opportunity",
                },
              }),
              _c("CommentOpportunities", {
                model: {
                  value: _vm.opportunity,
                  callback: function ($$v) {
                    _vm.opportunity = $$v
                  },
                  expression: "opportunity",
                },
              }),
            ],
            1
          ),
          _c("v-col", {
            staticClass: "px-0 py-0",
            attrs: { cols: "12", md: "3" },
          }),
          _c("v-col", {
            staticClass: "px-0 py-0",
            attrs: { cols: "12", md: "6" },
          }),
          _c("v-col", {
            staticClass: "px-0 py-0",
            attrs: { cols: "12", md: "3" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }