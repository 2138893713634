<template>
  <v-card style="z-index: 99">

    <v-row v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="40" color="primary" indeterminate class="my-3"></v-progress-circular>
        <p class="text-caption">
          Chargement des données
        </p>
      </v-col>
    </v-row>
    <ve-table :columns="columns" border-y :table-data="tableData" :max-height="900" v-else></ve-table>
  </v-card>
</template>

<script>
export default {
  name: "singleAffect",
  data() {
    return {
      stats: [],
      columns: [],
      loading: false,
      tableData: [],
    };
  },
  mounted() {
    this.getStats();
  },
  methods: {
    getStats() {
      this.loading = true;
      let keyTest = "";
      this.$axios("/api/stats/affects/" + this.$route.params.id).then((resp) => {
        let columns = [];
        columns.push({ title: "Ville", field: "city", key: "city", sortBy: "" });
        let obj = {};
        Object.keys(resp.data["columns"]).forEach((cities) => {
          Object.keys(resp.data["columns"][cities]).forEach((item) => {
            if (!obj[item]) {
              obj[item] = {
                title: item,
                children: [],
                background: "black",
              };
              Object.keys(resp.data["columns"][cities][item]).forEach((field, key) => {
                keyTest += "1";
                let tmpChild = [
                  { field: field + "-" + "pros", title: "Pros.", key: cities + "-pros-" + keyTest, sortBy: "" },
                  { field: field + "-" + "cli", title: "Cli.", key: cities + "-cli-" + keyTest, sortBy: "" },
                ];
                let tmp = { title: field, key: item + "-" + field + "-" + key, sortBy: "", children: tmpChild };
                obj[item].children.push(tmp);
              });
              columns.push(obj[item]);
            }
          });
        });

        this.columns = columns;
        let tmpData = [];
        Object.keys(resp.data.data).forEach((city) => {
          let tmpVal = {};
          tmpVal.city = city;
          Object.keys(resp.data.data[city]).forEach((val) => {
            tmpVal[val] = resp.data.data[city][val];
          });
          tmpData.push(tmpVal);
        });
        this.tableData = tmpData;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped></style>
