<template>
  <div class="container">
    <v-progress-linear indeterminate color="cyan"></v-progress-linear>
  </div>
</template>

<script>
export default {
  name: "LoaderActuelburo",
};
</script>

<style scoped lang="scss">
.container {
  max-width: 200px;
  img {
    max-width: 100%;
  }
}
</style>
