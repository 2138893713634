var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MaterialCard",
    {
      staticClass: "mt-8",
      attrs: { icon: "fa-history", title: "Évènements", color: "primary" },
    },
    [
      _c(
        "modal",
        {
          attrs: { "max-width": "calc(100% - 122px)" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Messages liés"),
                  ]),
                  _c("br"),
                  _c("small", { staticClass: "mt-0" }, [
                    _vm._v("Sélectionnez le message"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.openModalMail,
            callback: function ($$v) {
              _vm.openModalMail = $$v
            },
            expression: "openModalMail",
          },
        },
        [
          _vm.openModalMail
            ? [
                _c(
                  "v-tabs",
                  { attrs: { vertical: "" } },
                  [
                    _vm._l(
                      _vm.events.email[_vm.mailSelected],
                      function (email, key) {
                        return _c(
                          "v-tab",
                          {
                            key: key,
                            staticStyle: {
                              "justify-content": "left",
                              "margin-bottom": "1rem",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text-left overflow-x-hidden",
                                staticStyle: {
                                  "font-size": "0.8em",
                                  "text-align": "left",
                                },
                              },
                              [
                                _c(
                                  "small",
                                  {
                                    staticClass: "mb-3",
                                    staticStyle: { display: "block" },
                                  },
                                  [
                                    _vm._v(_vm._s(email.sender) + " "),
                                    _c("br"),
                                    _vm._v(
                                      " " + _vm._s(email.senderEmail) + " "
                                    ),
                                  ]
                                ),
                                _vm._v(" " + _vm._s(email.name) + " "),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    _vm._l(
                      _vm.events.email[_vm.mailSelected],
                      function (email, key) {
                        return _c("v-tab-item", { key: key }, [
                          _c("div", {
                            staticClass: "text-left mx-5",
                            domProps: { innerHTML: _vm._s(email.body) },
                          }),
                        ])
                      }
                    ),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "modal",
        {
          attrs: { "max-width": "calc(100% - 122px)" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Boîte mail"),
                  ]),
                  _c("br"),
                  _c("small", { staticClass: "mt-0" }, [
                    _vm._v("Filtrez les dossiers..."),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.linkModal,
            callback: function ($$v) {
              _vm.linkModal = $$v
            },
            expression: "linkModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "text-left" },
            [
              _c("Inbox", {
                on: { close: _vm.closeInbox },
                model: {
                  value: _vm.typeLink,
                  callback: function ($$v) {
                    _vm.typeLink = $$v
                  },
                  expression: "typeLink",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "modal",
        {
          attrs: { "max-width": "1024" },
          model: {
            value: _vm.linkOppModal,
            callback: function ($$v) {
              _vm.linkOppModal = $$v
            },
            expression: "linkOppModal",
          },
        },
        [
          _c("opportunitiesCustomers", {
            attrs: { link: true },
            on: { linkOpp: _vm.linkOppFct },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { "max-width": "1080" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Evènement"),
                  ]),
                  _c("br"),
                  _c("small", { staticClass: "mt-0" }, [
                    _vm._v(
                      _vm._s(
                        _vm.dayjs(_vm.newEvent.date).format("DD/MM/YYYY")
                      ) +
                        " " +
                        _vm._s(_vm.newEvent.time) +
                        " - " +
                        _vm._s(_vm.newEvent.name)
                    ),
                  ]),
                  _vm.newEvent.id
                    ? [
                        _c(
                          "div",
                          { staticClass: "modal-menu--actions" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-left",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            elevation: "0",
                                                            plain: "",
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$router.push(
                                                                {
                                                                  name: "Client",
                                                                  params: {
                                                                    id: _vm
                                                                      .newEvent
                                                                      .customerId,
                                                                  },
                                                                }
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                          },
                                                        },
                                                        [_vm._v("fa-search")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1168081894
                                        ),
                                      },
                                      [_c("span", [_vm._v("Fiche client")])]
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "ml-1",
                                                          attrs: {
                                                            small: "",
                                                            elevation: "0",
                                                            plain: "",
                                                            color: "none",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.typeLink.id =
                                                                _vm.newEvent.id
                                                              _vm.linkModal = true
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                          },
                                                        },
                                                        [_vm._v("fa-envelope")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3183207128
                                        ),
                                      },
                                      [_c("span", [_vm._v("Lier un e-mail")])]
                                    ),
                                    _vm.newEvent.opportunityId
                                      ? [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  elevation:
                                                                    "0",
                                                                  small: "",
                                                                  plain: "",
                                                                  color:
                                                                    "warning",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$router.push(
                                                                        {
                                                                          name: "Opportunité",
                                                                          params:
                                                                            {
                                                                              id: _vm
                                                                                .newEvent
                                                                                .opportunityId,
                                                                            },
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-handshake"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                303942909
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Opportunité " +
                                                    _vm._s(
                                                      _vm.newEvent.opportunityId
                                                    )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  elevation:
                                                                    "0",
                                                                  small: "",
                                                                  plain: "",
                                                                  color:
                                                                    "warning",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.newOpp,
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-plus"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                680333465
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Créer opportunité"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                  plain: "",
                                                                  elevation:
                                                                    "0",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.linkOppModal = true
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-link"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                4288396378
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Associer opportunité"),
                                              ]),
                                            ]
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-4",
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.addEvent = false
                          _vm.loadingSave = false
                        },
                      },
                    },
                    [_vm._v(" Fermer ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        variant: "flat",
                        color: "primary",
                        elevation: "0",
                        loading: _vm.loadingSave,
                      },
                      on: { click: _vm.addEventFct },
                    },
                    [_vm._v(" Enregistrer ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.addEvent,
            callback: function ($$v) {
              _vm.addEvent = $$v
            },
            expression: "addEvent",
          },
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("EventForm", {
                        model: {
                          value: _vm.newEvent,
                          callback: function ($$v) {
                            _vm.newEvent = $$v
                          },
                          expression: "newEvent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "listbox--actions-add" },
        [
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-5",
                              attrs: { fab: "", color: "primary", small: "" },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" fa-fw fa-filter "),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                [
                  _c("v-list-title", [
                    _c("div", { staticClass: "px-4" }, [
                      _c(
                        "strong",
                        {
                          staticClass: "text-uppercase grey--text text-center",
                        },
                        [_vm._v("Filtres")]
                      ),
                    ]),
                  ]),
                  _c(
                    "v-list-item",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "seulement les cross-selling",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.onlyCrossSelling,
                          callback: function ($$v) {
                            _vm.onlyCrossSelling = $$v
                          },
                          expression: "onlyCrossSelling",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.shortcut, function (item, key) {
            return _c(
              "v-tooltip",
              {
                key: key,
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    fab: "",
                                    small: "",
                                    color: item.color,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.initNewEvent()
                                      _vm.addEvent = true
                                      _vm.newEvent.eventtypeId = item.id
                                      _vm.newEvent.duration = item.duration
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" " + _vm._s(item.icon) + " "),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [_c("span", [_vm._v(_vm._s(item.name))])]
            )
          }),
          _c(
            "v-btn",
            {
              staticClass: "text-center ml-5 addEventBtn",
              attrs: { fab: "", color: "primary", small: "" },
              on: {
                click: function ($event) {
                  _vm.initNewEvent()
                  _vm.addEvent = true
                },
              },
            },
            [
              _c("v-icon", { attrs: { color: "white", small: "" } }, [
                _vm._v(" fa-plus "),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", md: "12" } },
            [
              _c("v-text-field", {
                attrs: { label: "Rechercher un évènement..." },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "events-timelines",
          staticStyle: { "max-height": "400px", "overflow-y": "auto" },
        },
        [
          _c(
            "v-container",
            [
              _vm.events.events && _vm.events.events.length > 0
                ? _c(
                    "v-timeline",
                    { attrs: { dense: "", large: "", "align-top": "" } },
                    _vm._l(
                      _vm.events.events.filter((item) =>
                        _vm.onlyCrossSelling
                          ? item.isCrossSelling === _vm.onlyCrossSelling
                          : true
                      ),
                      function (item, i) {
                        return _c(
                          "v-timeline-item",
                          {
                            key: i,
                            staticClass: "my-0 mb-4 pl-0",
                            attrs: {
                              small: "",
                              color: _vm.returnIcon(item.status).color,
                              right: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "icon",
                                  fn: function () {
                                    return [
                                      _vm.events.users[item.id].length > 3
                                        ? [
                                            _c(
                                              "v-avatar",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  size: "40",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text text-h5",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.events.users[
                                                          item.id
                                                        ].length
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm.events.users[item.id].length == 1
                                        ? [
                                            _c("UserAvatar", {
                                              attrs: {
                                                userId: item.userId,
                                                size: "40",
                                              },
                                            }),
                                          ]
                                        : _vm._l(
                                            _vm.events.users[item.id],
                                            function (user, key) {
                                              return _c("UserAvatar", {
                                                key: key,
                                                style:
                                                  "margin-left:" +
                                                  (_vm.events.users[item.id]
                                                    .length == 2
                                                    ? "-10px"
                                                    : "-15px"),
                                                attrs: {
                                                  userId: user.userId,
                                                  size: "40",
                                                },
                                              })
                                            }
                                          ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "white",
                                          staticStyle: {
                                            position: "absolute",
                                            top: "35px",
                                          },
                                          attrs: {
                                            small: "",
                                            color: _vm.returnIcon(item.status)
                                              .color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.returnIcon(item.status).icon
                                            ) + " fa-fw "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "white",
                                          staticStyle: {
                                            position: "absolute",
                                            top: "55px",
                                            "text-align": "center",
                                            "font-size": "0.8rem",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .dayjs(item.start)
                                                  .format("DD/MM/YYYY")
                                              )
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .dayjs(item.start)
                                                .format("HH:mm")
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            [
                              _c(
                                "v-card",
                                {
                                  class: {
                                    eventstandby: item.status == 0,
                                    eventsuccess: item.status == 1,
                                    eventcancel: item.status == 2,
                                  },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "ma-0 pt-0" },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3",
                                          staticStyle: {
                                            "font-size": "0.9rem",
                                          },
                                        },
                                        [
                                          item.private
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("fa-lock")]
                                              )
                                            : _vm._e(),
                                          _vm.events
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    "x-small": "",
                                                    color:
                                                      _vm.events.type[
                                                        item.eventtypeId
                                                      ].color,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.events.type[
                                                          item.eventtypeId
                                                        ].icon
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          item.confidential
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    "x-small": "",
                                                    color: "error",
                                                  },
                                                },
                                                [_vm._v("fa-fw fa-mask")]
                                              )
                                            : _vm._e(),
                                          _c("span", { staticClass: "mx-2" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.events.type[
                                                  item.eventtypeId
                                                ].name
                                              ) +
                                                " - " +
                                                _vm._s(item.name)
                                            ),
                                          ]),
                                          item.opportunity
                                            ? [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "ma-0 p-0",
                                                    attrs: {
                                                      outlined: "",
                                                      "x-small": "",
                                                      elevation: "0",
                                                      color:
                                                        item.opportunity
                                                          .statusId !== null
                                                          ? ""
                                                          : "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openOpportunity(
                                                          item.opportunity.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.opportunity.id
                                                      ) + " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                          item.isCrossSelling
                                            ? [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ma-0 p-0 mr-2",
                                                                      attrs: {
                                                                        outlined:
                                                                          "",
                                                                        "x-small":
                                                                          "",
                                                                        elevation:
                                                                          "0",
                                                                        color:
                                                                          "blue",
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "CROSS-SELLING"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _vm._v(
                                                          "Évènement cross-selling "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                          item.opportunityOrigin
                                            ? [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ma-0 p-0",
                                                                      attrs: {
                                                                        outlined:
                                                                          "",
                                                                        "x-small":
                                                                          "",
                                                                        elevation:
                                                                          "0",
                                                                        color:
                                                                          "success",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openOpportunity(
                                                                              item
                                                                                .opportunityOrigin
                                                                                .id
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .opportunityOrigin
                                                                        .id
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _c("UserAvatar", {
                                                          attrs: {
                                                            userId:
                                                              item
                                                                .opportunityOrigin
                                                                .closedUserId,
                                                          },
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-end flex-column ml-4",
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item
                                                                    .opportunityOrigin
                                                                    .name
                                                                )
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "0.8rem",
                                                                  "text-align":
                                                                    "end",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    parseInt(
                                                                      item
                                                                        .opportunityOrigin
                                                                        .amount
                                                                    ).toFixed(0)
                                                                  ) + " €"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      item.report && item.report.length > 0
                                        ? [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                },
                                                                "span",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "- Note interne disponible"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.report)),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "text-caption" },
                                        [
                                          item.teams
                                            ? _c("span", [
                                                item.status !== 2
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: item.teamsLink,
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            "max-width": "15px",
                                                          },
                                                          attrs: {
                                                            src: "/images/microsoft-teams.svg",
                                                          },
                                                        }),
                                                        _vm._v(" TEAMS "),
                                                      ]
                                                    )
                                                  : _c("span", [
                                                      _c("img", {
                                                        staticStyle: {
                                                          "max-width": "15px",
                                                        },
                                                        attrs: {
                                                          src: "/images/microsoft-teams.svg",
                                                        },
                                                      }),
                                                      _vm._v(" TEAMS "),
                                                    ]),
                                              ])
                                            : _vm._e(),
                                          _vm.events.email[item.id].length > 0
                                            ? [
                                                item.teams
                                                  ? _c("span", [_vm._v(" - ")])
                                                  : _vm._e(),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.mailSelected =
                                                                              item.id
                                                                            _vm.openModalMail = true
                                                                          },
                                                                      },
                                                                    },
                                                                    "span",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-badge",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0 ma-0 red--text",
                                                                      attrs: {
                                                                        overlap:
                                                                          "",
                                                                        text: "",
                                                                        color:
                                                                          "white",
                                                                        "text-color":
                                                                          "primary",
                                                                        "offset-x":
                                                                          "5px",
                                                                        "offset-y":
                                                                          "5px",
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "badge",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "blue-grey--text text--darken-2",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .events
                                                                                            .email[
                                                                                            item
                                                                                              .id
                                                                                          ]
                                                                                            .length
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "x-small":
                                                                                "",
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "far fa-envelope fa-fw"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Voir les e-mails liés"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _vm.events.email[item.id].length > 0 ||
                                      item.teams
                                        ? _c("v-divider", {
                                            staticClass: "my-2",
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            bottom: "5px",
                                            right: "10px",
                                          },
                                        },
                                        [
                                          _vm.events?.attendees &&
                                          _vm.events?.attendees[item.id]
                                            .length > 0
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            item.status === 0
                                                              ? _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          "x-small":
                                                                            "",
                                                                          elevation:
                                                                            "0",
                                                                          color:
                                                                            "primary",
                                                                          depressed:
                                                                            "",
                                                                          text: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.openResponseModal(
                                                                                _vm
                                                                                  .events
                                                                                  ?.attendees[
                                                                                  item
                                                                                    .id
                                                                                ]
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          "x-small":
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fa-fw fa-reply"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Voir les réponses des participants"
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "-15px",
                                            right: "5px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                left: "",
                                                "offset-y": "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "openEventMenu",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  dark: "",
                                                                  "x-small": "",
                                                                  fab: "",
                                                                  elevation:
                                                                    "0",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " fa-ellipsis-v "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                { staticClass: "text-left" },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editEvent(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fa-fw fa-edit"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" Editer "),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeStatus(
                                                            item,
                                                            1
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            color: "success",
                                                            small: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "far fa-fw fa-check-circle"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" Valider "),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeStatus(
                                                            item,
                                                            2
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            color: "error",
                                                            small: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "far fa-fw fa-times-circle"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" Annuler "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-caption" },
                                        [
                                          item.body && item.body.length > 250
                                            ? _c("read-more", {
                                                attrs: {
                                                  "more-str": "Lire plus",
                                                  text: item.body,
                                                  "less-str": "Lire moins",
                                                  "max-chars": 250,
                                                },
                                              })
                                            : _c("p", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.body
                                                      ? item.body.replaceAll(
                                                          "\n",
                                                          "<br/>"
                                                        )
                                                      : "Pas de commentaire"
                                                  ),
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                          ],
                          2
                        )
                      }
                    ),
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "font-weight-light grey--text mb-5 text-center",
                    },
                    [_vm._v("Aucun évènement lié")]
                  ),
            ],
            1
          ),
          false
            ? _c(
                "v-timeline",
                { attrs: { "align-top": "", dense: "" } },
                _vm._l(_vm.events.events, function (item, i) {
                  return _c(
                    "v-timeline-item",
                    {
                      key: i,
                      attrs: {
                        color: _vm.events.type[item.eventtypeId].color,
                        "fill-dot": "",
                        small: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("UserAvatar", {
                                  attrs: { userId: item.userId },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            color: _vm.events.type[item.eventtypeId].color,
                            dark: "",
                          },
                        },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticStyle: {
                                padding: "0.2rem",
                                "font-size": "1.1rem",
                              },
                            },
                            [
                              _vm.events
                                ? _c("v-icon", { staticClass: "mr-3" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.events.type[item.eventtypeId].icon
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.events.type[item.eventtypeId].name
                                  ) +
                                  " - " +
                                  _vm._s(item.name) +
                                  " "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "font-italic mx-3",
                                  staticStyle: { "font-size": "0.8rem" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.status === 1 ? "Réalisé" : "") +
                                      " " +
                                      _vm._s(item.status === 2 ? "Annulé" : "")
                                  ),
                                ]
                              ),
                              item.status === 0
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "mx-1",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeStatusSubmit(item, 1)
                                        },
                                      },
                                    },
                                    [_vm._v("fa-check")]
                                  )
                                : _vm._e(),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mx-1",
                                  attrs: { small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editEvent(item)
                                    },
                                  },
                                },
                                [_vm._v("fa-pen")]
                              ),
                              item.status === 0
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "mx-1",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeStatusSubmit(item, 2)
                                        },
                                      },
                                    },
                                    [_vm._v("fa-trash")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            {
                              staticClass: "white text--primary",
                              staticStyle: { padding: "0.5rem" },
                            },
                            [
                              _c("p", { staticClass: "pa-0 ma-0" }, [
                                _vm._v(
                                  _vm._s(
                                    item.body ? item.body : "Pas de commentaire"
                                  )
                                ),
                              ]),
                              _c("p", { staticClass: "pa-0 ma-0" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Crée le:")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .dayjs(item.createdAt)
                                        .format("DD-MM-YYYY à HH:mm")
                                    )
                                ),
                              ]),
                              _c("p", { staticClass: "pa-0 ma-0" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Début:")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .dayjs(item.start)
                                        .format("DD-MM-YYYY à HH:mm")
                                    ) +
                                    " - "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Fin:")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .dayjs(item.end)
                                        .format("DD-MM-YYYY à HH:mm")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "modal",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Réponses des participants"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.responseModal,
            callback: function ($$v) {
              _vm.responseModal = $$v
            },
            expression: "responseModal",
          },
        },
        [
          _vm.selectedResponses
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-left", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.selectedResponses, function (item, key) {
                          return _c(
                            "v-list-item",
                            { key: key },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(item.name)),
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(_vm._s(item.email)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-right",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        item.statusResponse ===
                                                        "accepted"
                                                          ? _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "success",
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "fa-fw fa-check"
                                                                ),
                                                              ]
                                                            )
                                                          : item.statusResponse ===
                                                            "declined"
                                                          ? _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "error",
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "fa-fw fa-times"
                                                                ),
                                                              ]
                                                            )
                                                          : item.statusResponse ===
                                                            "tentativelyAccepted"
                                                          ? _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "warning",
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "fa-fw fa-question"
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "grey",
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "fa-fw fa-clock"
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.statusResponse ===
                                                      "accepted"
                                                      ? "accepté"
                                                      : item.statusResponse ===
                                                        "tentativelyAccepted"
                                                      ? "tentative"
                                                      : item.statusResponse ===
                                                        "declined"
                                                      ? "décliné"
                                                      : "En attente de réponse"
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }