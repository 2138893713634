var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "-25px" } },
    [
      _c("Landscape"),
      _c(
        "v-card",
        { attrs: { elevation: "1" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary", elevation: "0", dark: "" } },
            [
              _c("v-card-title", [
                _vm._v(" Statistiques de mon service "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      top: "0",
                      left: "50%",
                      background: "#78909c",
                      padding: "5px",
                      translate: "-50% 0",
                      "border-radius": "0 0 5px 5px",
                    },
                  },
                  [
                    _c(
                      "v-menu",
                      {
                        staticStyle: { "z-index": "1111111" },
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { text: "", small: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v(" fa-ellipsis-v fa-fw "),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "v-list",
                          { staticStyle: { "z-index": "1111111" } },
                          [
                            _vm.isGranted("OPPORTUNITIES_STATISTICS")
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Statistiques Personnelles",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Mes statistiques"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isGranted("STATS_COM")
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Statistiques Commerciaux",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Statistiques commerciaux"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isGranted("OPPORTUNITIES_STATISTICSAGENCY")
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Statistiques Agence",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Statistiques de mon agence"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isGranted(
                              "OPPORTUNITIES_STATISTICSCROSSSELLING"
                            )
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Cross-Selling",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Cross-selling"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isGranted("EVENTS_EVENTSKEY")
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Relations",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Relation clients"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isGranted("SECTOR_STATISTICS")
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Affectations",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Affectations"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-row",
            { staticClass: "px-2" },
            [
              _c(
                "v-col",
                { staticClass: "px-1", attrs: { cols: "12", md: "4" } },
                [
                  _c("pipeline-statistics", {
                    model: {
                      value: _vm.stats,
                      callback: function ($$v) {
                        _vm.stats = $$v
                      },
                      expression: "stats",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "px-1", attrs: { cols: "12", md: "8" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-1" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _vm.stats
                            ? _c("opportunitiesStatistics", {
                                attrs: { label: "Cette semaine" },
                                model: {
                                  value: _vm.stats.week,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.stats, "week", $$v)
                                  },
                                  expression: "stats.week",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _vm.stats
                            ? _c("opportunitiesStatistics", {
                                attrs: { label: "Exercice" },
                                model: {
                                  value: _vm.stats.year,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.stats, "year", $$v)
                                  },
                                  expression: "stats.year",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.stats
                            ? _c("mensuelStatistics", {
                                model: {
                                  value: _vm.stats,
                                  callback: function ($$v) {
                                    _vm.stats = $$v
                                  },
                                  expression: "stats",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.stats
                            ? _c("opportunitiesincomingStatistics", {
                                model: {
                                  value: _vm.stats,
                                  callback: function ($$v) {
                                    _vm.stats = $$v
                                  },
                                  expression: "stats",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }