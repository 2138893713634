var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-block d-sm-none landscape" }, [
      _c("img", {
        attrs: {
          src: "/images/rotate-phone.png",
          alt: "Icône Téléphone",
          width: "32px",
          height: "32px",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }