var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "pt-0",
      staticStyle: { position: "relative", "padding-top": "0 !important" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "modal",
        {
          attrs: { "max-width": "1024" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Evènement"),
                  ]),
                  _c("br"),
                  _c(
                    "small",
                    { staticClass: "mt-0" },
                    [
                      _vm.event
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dayjs(_vm.event.date).format("DD/MM/YYYY")
                                ) +
                                " " +
                                _vm._s(_vm.event.time) +
                                " - " +
                                _vm._s(_vm.event.name) +
                                " "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.event
                    ? [
                        _vm.event.id
                          ? [
                              _c(
                                "div",
                                { staticClass: "modal-menu--actions" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-left",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                  elevation:
                                                                    "0",
                                                                  plain: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$router.push(
                                                                        {
                                                                          name: "Client",
                                                                          params:
                                                                            {
                                                                              id: _vm
                                                                                .event
                                                                                .customerId,
                                                                            },
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-search"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1300850490
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Fiche client"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                                attrs: {
                                                                  small: "",
                                                                  elevation:
                                                                    "0",
                                                                  plain: "",
                                                                  color: "none",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.typeLink.id =
                                                                        _vm.event.id
                                                                      _vm.linkModal = true
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-envelope"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1056768036
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Lier un e-mail"),
                                              ]),
                                            ]
                                          ),
                                          _vm.event.opportunityId
                                            ? [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mx-1",
                                                                      attrs: {
                                                                        elevation:
                                                                          "0",
                                                                        small:
                                                                          "",
                                                                        plain:
                                                                          "",
                                                                        color:
                                                                          "warning",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$router.push(
                                                                              {
                                                                                name: "Opportunité",
                                                                                params:
                                                                                  {
                                                                                    id: _vm
                                                                                      .event
                                                                                      .opportunityId,
                                                                                  },
                                                                              }
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fa-handshake"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      571772065
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Opportunité " +
                                                          _vm._s(
                                                            _vm.event
                                                              .opportunityId
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            : void 0,
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        variant: "flat",
                        color: "primary",
                        elevation: "0",
                        loading: _vm.loadingSave,
                      },
                      on: { click: _vm.editEventFct },
                    },
                    [_vm._v(" Enregistrer ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.eventModal = false
                        },
                      },
                    },
                    [_vm._v(" Fermer ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.eventModal,
            callback: function ($$v) {
              _vm.eventModal = $$v
            },
            expression: "eventModal",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("event-form", {
                model: {
                  value: _vm.event,
                  callback: function ($$v) {
                    _vm.event = $$v
                  },
                  expression: "event",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticStyle: { position: "relative" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-center pt-0",
              staticStyle: {
                top: "-15px",
                left: "0",
                position: "fixed",
                "z-index": "1000",
                "max-width": "7rem",
              },
              attrs: { cols: "1" },
            },
            [
              !_vm.isMobile()
                ? _c(
                    "v-card",
                    {
                      staticStyle: {
                        height: "100vh",
                        "box-shadow": "none",
                        border: "none",
                      },
                      attrs: { color: "transparent" },
                      on: {
                        click: function ($event) {
                          _vm.leftPanel = true
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-right",
                          staticStyle: {
                            "line-height": "100vh",
                            "padding-right": "1rem",
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "ml-12", attrs: { large: "" } },
                            [_vm._v(" fa-chevron-right ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-expand-x-transition",
                            [
                              _vm.leftPanel
                                ? _c(
                                    "v-navigation-drawer",
                                    {
                                      staticStyle: {
                                        left: "50px",
                                        "max-width": "720px",
                                        width: "100%",
                                      },
                                      attrs: { fixed: "" },
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "text-left" },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "text-uppercase blue-grey--text text--darken-1",
                                                },
                                                [_vm._v("Filtre textuel")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-container",
                                        {
                                          staticClass: "mx-3",
                                          attrs: { fluid: "" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "ma-0 mt-0 pt-1",
                                                    attrs: {
                                                      label:
                                                        "Client ou titre d'opportunité",
                                                      "hide-details": "",
                                                    },
                                                    model: {
                                                      value: _vm.searchValue,
                                                      callback: function ($$v) {
                                                        _vm.searchValue = $$v
                                                      },
                                                      expression: "searchValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "text-left" },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "text-uppercase blue-grey--text text--darken-1",
                                                },
                                                [_vm._v("Etat")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-container",
                                        {
                                          staticClass: "mx-3",
                                          attrs: { fluid: "" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "ma-0 py-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      color: "success",
                                                      label: "Ouvert",
                                                    },
                                                    model: {
                                                      value: _vm.open,
                                                      callback: function ($$v) {
                                                        _vm.open = $$v
                                                      },
                                                      expression: "open",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "ma-0 py-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      color: "error",
                                                      label: "Fermé",
                                                    },
                                                    model: {
                                                      value: _vm.close,
                                                      callback: function ($$v) {
                                                        _vm.close = $$v
                                                      },
                                                      expression: "close",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.isGranted(
                                        "OPPORTUNITIES_INDEX_ALLSERVICE"
                                      )
                                        ? _c("v-list-item", [
                                            _c(
                                              "h3",
                                              {
                                                staticClass:
                                                  "text-uppercase blue-grey--text text--darken-1",
                                              },
                                              [_vm._v("Métiers")]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.isGranted(
                                        "OPPORTUNITIES_INDEX_ALLSERVICE"
                                      )
                                        ? _c("v-divider")
                                        : _vm._e(),
                                      _vm.isGranted(
                                        "OPPORTUNITIES_INDEX_ALLSERVICE"
                                      )
                                        ? _c(
                                            "v-container",
                                            {
                                              staticClass: "mx-3",
                                              attrs: { fluid: "" },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-2" },
                                                _vm._l(
                                                  _vm.jobs,
                                                  function (item, key) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key: key,
                                                        staticClass:
                                                          "ma-0 py-0",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c("v-switch", {
                                                          staticClass:
                                                            "ma-1 py-0",
                                                          attrs: {
                                                            value: item.id,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "label",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "0.875rem",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.name
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.selectedJobs,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedJobs =
                                                                $$v
                                                            },
                                                            expression:
                                                              "selectedJobs",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("v-list-item", [
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "text-uppercase blue-grey--text text--darken-1",
                                          },
                                          [_vm._v("Services")]
                                        ),
                                      ]),
                                      _c("v-divider"),
                                      _c(
                                        "v-btn-toggle",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary",
                                              },
                                              on: { click: _vm.selectAllUsers },
                                            },
                                            [_vm._v("Tous")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.asUser = []
                                                },
                                              },
                                            },
                                            [_vm._v("Aucun")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.isGranted(
                                        "OPPORTUNITIES_INDEX_ALLSERVICE"
                                      )
                                        ? _c(
                                            "v-container",
                                            {
                                              staticClass: "mr-3",
                                              attrs: { fluid: "" },
                                            },
                                            [
                                              _c(
                                                "v-tabs",
                                                {
                                                  attrs: {
                                                    "center-active": "",
                                                    grow: "",
                                                    "show-arrows": "",
                                                  },
                                                  model: {
                                                    value: _vm.tab,
                                                    callback: function ($$v) {
                                                      _vm.tab = $$v
                                                    },
                                                    expression: "tab",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.services,
                                                  function (service, idSer) {
                                                    return _c(
                                                      "v-tab",
                                                      {
                                                        key: idSer,
                                                        staticStyle: {
                                                          "font-size":
                                                            "0.875rem",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(idSer) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                              _c(
                                                "v-tabs-items",
                                                {
                                                  model: {
                                                    value: _vm.tab,
                                                    callback: function ($$v) {
                                                      _vm.tab = $$v
                                                    },
                                                    expression: "tab",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.services,
                                                  function (service, idSer) {
                                                    return _c(
                                                      "v-tab-item",
                                                      { key: idSer },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "ma-3 pt-4",
                                                          },
                                                          _vm._l(
                                                            service,
                                                            function (
                                                              item,
                                                              key
                                                            ) {
                                                              return _c(
                                                                "v-col",
                                                                {
                                                                  key: key,
                                                                  staticClass:
                                                                    "py-1 switchUser",
                                                                  attrs: {
                                                                    cols: "4",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      staticClass:
                                                                        "ma-0 pa-0",
                                                                      attrs: {
                                                                        value:
                                                                          item.id,
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "label",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "font-size":
                                                                                            "0.875rem",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item.firstname +
                                                                                            " " +
                                                                                            item.lastname
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                      model: {
                                                                        value:
                                                                          _vm.asUser,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.asUser =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "asUser",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          !_vm.isMobile()
            ? _c(
                "v-col",
                {
                  staticStyle: { "min-height": "96vh" },
                  attrs: { cols: "12" },
                  on: {
                    click: function ($event) {
                      _vm.leftPanel = false
                      _vm.rightPanel = false
                    },
                  },
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "mt-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { position: "relative", width: "100%" },
                        },
                        [
                          _vm.isGranted("OPPORTUNITIES_INDEX")
                            ? _c(
                                "v-btn",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    left: "0",
                                    top: "-2.7rem",
                                    "z-index": "99",
                                  },
                                  attrs: {
                                    small: "",
                                    fab: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.newOpp },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("fa-plus fa-fw"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticStyle: {
                            "margin-top": "-35px",
                            "max-height": "96vh",
                            "overflow-y": "scroll",
                          },
                          attrs: { id: "infinite-list" },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0",
                              staticStyle: { position: "relative" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center primary--text text-uppercase font-weight-bold mb-3",
                                  staticStyle: { "letter-spacing": "2px" },
                                },
                                [_vm._v("Identifié")]
                              ),
                              _c(
                                "div",
                                _vm._l(_vm.tasks[0], function (item, key) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: key,
                                      staticClass: "py-1 my-1",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("dashboard-card", {
                                        attrs: { item: item },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center primary--text text-uppercase font-weight-bold mb-3",
                                  staticStyle: { "letter-spacing": "2px" },
                                },
                                [_vm._v("Étude")]
                              ),
                              _c(
                                "div",
                                _vm._l(_vm.tasks[1], function (item, key) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: key,
                                      staticClass: "py-2 my-1",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("dashboard-card", {
                                        attrs: { item: item },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center primary--text text-uppercase font-weight-bold mb-3",
                                  staticStyle: { "letter-spacing": "2px" },
                                },
                                [_vm._v("Négociation")]
                              ),
                              _c(
                                "div",
                                _vm._l(_vm.tasks[2], function (item, key) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: key,
                                      staticClass: "py-2 my-1",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("dashboard-card", {
                                        attrs: { item: item },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-col",
                {
                  staticClass: "ma-0 pa-0",
                  staticStyle: { "min-height": "96vh" },
                  attrs: { cols: "12" },
                  on: {
                    click: function ($event) {
                      _vm.leftPanel = false
                      _vm.rightPanel = false
                    },
                  },
                },
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { variant: "accordion" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-center primary--text text-uppercase font-weight-bold mb-3",
                                staticStyle: { "letter-spacing": "2px" },
                              },
                              [_vm._v("Identifié")]
                            ),
                          ]),
                          _c("v-expansion-panel-content", [
                            _c(
                              "div",
                              _vm._l(_vm.tasks[0], function (item, key) {
                                return _c(
                                  "v-col",
                                  {
                                    key: key,
                                    staticClass: "py-1 my-1",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("dashboard-card", {
                                      attrs: { item: item },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-center primary--text text-uppercase font-weight-bold mb-3",
                                staticStyle: { "letter-spacing": "2px" },
                              },
                              [_vm._v("Etude")]
                            ),
                          ]),
                          _c("v-expansion-panel-content", [
                            _c(
                              "div",
                              _vm._l(_vm.tasks[1], function (item, key) {
                                return _c(
                                  "v-col",
                                  {
                                    key: key,
                                    staticClass: "py-1 my-1",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("dashboard-card", {
                                      attrs: { item: item },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-center primary--text text-uppercase font-weight-bold mb-3",
                                staticStyle: { "letter-spacing": "2px" },
                              },
                              [_vm._v("Négociation")]
                            ),
                          ]),
                          _c("v-expansion-panel-content", [
                            _c(
                              "div",
                              _vm._l(_vm.tasks[2], function (item, key) {
                                return _c(
                                  "v-col",
                                  {
                                    key: key,
                                    staticClass: "py-1 my-1",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("dashboard-card", {
                                      attrs: { item: item },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c(
            "div",
            {
              staticClass: "text-left",
              staticStyle: {
                top: "0px",
                right: "0",
                bottom: "0",
                width: "40px",
                position: "fixed",
                "z-index": "1000",
                "padding-right": "5px",
              },
              attrs: { offset: "11" },
            },
            [
              _c("v-hover", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ hover }) {
                      return [
                        _c(
                          "v-card",
                          {
                            staticStyle: {
                              height: "96vh",
                              "box-shadow": "none",
                              border: "none",
                            },
                            attrs: { color: "transparent" },
                          },
                          [
                            !_vm.isMobile()
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: { "line-height": "96vh" },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { large: "" },
                                      },
                                      [_vm._v(" fa-chevron-left ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-expand-x-transition",
                              [
                                hover
                                  ? _c(
                                      "v-navigation-drawer",
                                      {
                                        attrs: {
                                          fixed: "",
                                          right: "",
                                          width: "600",
                                          color: "#f9fafb",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-toolbar",
                                          {
                                            staticClass: "ma-0",
                                            attrs: {
                                              elevation: "0",
                                              color: "primary",
                                              dark: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { align: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeLastMonday(
                                                              -7
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fa-chevron-left"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "",
                                                        attrs: { outlined: "" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Du " +
                                                            _vm._s(
                                                              _vm
                                                                .dayjs(
                                                                  _vm.lastMonday
                                                                )
                                                                .format(
                                                                  "DD/MM/YYYY"
                                                                )
                                                            ) +
                                                            " au " +
                                                            _vm._s(
                                                              _vm
                                                                .dayjs(
                                                                  _vm.lastSaturday
                                                                )
                                                                .format(
                                                                  "DD/MM/YYYY"
                                                                )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeLastMonday(
                                                              7
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fa-chevron-right"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-container",
                                          { attrs: { fluid: "" } },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticStyle: {
                                                  position: "relative",
                                                },
                                              },
                                              [
                                                Object.keys(_vm.events).length >
                                                  0 && _vm.eventsLoaded
                                                  ? _c("v-checkbox", {
                                                      staticStyle: {
                                                        position: "absolute",
                                                        top: "-5px",
                                                        right: "10px",
                                                        "z-index": "9999",
                                                      },
                                                      attrs: {
                                                        label: "CROSS-SELLING",
                                                        "hide-details": "",
                                                        dense: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.onlyCrossSelling,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.onlyCrossSelling =
                                                            $$v
                                                        },
                                                        expression:
                                                          "onlyCrossSelling",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                Object.keys(_vm.events).length >
                                                  0 && _vm.eventsLoaded
                                                  ? _c(
                                                      "v-list",
                                                      {
                                                        staticClass:
                                                          "ma-0 pa-0 overflow-y-auto",
                                                        staticStyle: {
                                                          "max-height":
                                                            "calc(100vh - 70px)",
                                                        },
                                                        attrs: {
                                                          subheader: "",
                                                          "two-line": "",
                                                          dense: "",
                                                          width: "100%",
                                                        },
                                                      },
                                                      _vm._l(
                                                        Object.keys(_vm.events),
                                                        function (value, date) {
                                                          return _c(
                                                            "v-list-item-group",
                                                            { key: date },
                                                            [
                                                              _c(
                                                                "v-subheader",
                                                                {
                                                                  staticClass:
                                                                    "title mt-2 mb-0 font-italic",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .dayjs(
                                                                            value
                                                                          )
                                                                          .format(
                                                                            "dddd DD MMM YYYY"
                                                                          )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._l(
                                                                _vm.events[
                                                                  value
                                                                ],
                                                                function (
                                                                  item,
                                                                  key
                                                                ) {
                                                                  return _c(
                                                                    "v-list-item-group",
                                                                    {
                                                                      key: key,
                                                                    },
                                                                    [
                                                                      (
                                                                        _vm.onlyCrossSelling
                                                                          ? item
                                                                              .init
                                                                              .isCrossSelling
                                                                          : true
                                                                      )
                                                                        ? _c(
                                                                            "v-divider",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "60px",
                                                                                  "max-width":
                                                                                    "calc(100% - 60px)",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  inset:
                                                                                    "",
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      (
                                                                        _vm.onlyCrossSelling
                                                                          ? item
                                                                              .init
                                                                              .isCrossSelling
                                                                          : true
                                                                      )
                                                                        ? _c(
                                                                            "v-list-item",
                                                                            {
                                                                              staticClass:
                                                                                "my-0 py-0",
                                                                              staticStyle:
                                                                                {
                                                                                  "min-height":
                                                                                    "56px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  dense:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.leftPanel = false
                                                                                    _vm.loadEvent(
                                                                                      item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-avatar",
                                                                                {
                                                                                  staticClass:
                                                                                    "my-0",
                                                                                  attrs:
                                                                                    {
                                                                                      size: "30",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      style:
                                                                                        "background-color:" +
                                                                                        _vm
                                                                                          .eventsList
                                                                                          .type[
                                                                                          item
                                                                                            .init
                                                                                            .eventtypeId
                                                                                        ]
                                                                                          .color,
                                                                                      attrs:
                                                                                        {
                                                                                          dark: "",
                                                                                          "x-small":
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .eventsList
                                                                                              .type[
                                                                                              item
                                                                                                .init
                                                                                                .eventtypeId
                                                                                            ]
                                                                                              .icon
                                                                                          ) +
                                                                                          " fa-fw "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-list-item-content",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-left py-0",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-title",
                                                                                    {
                                                                                      staticClass:
                                                                                        "primary--text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .eventsList
                                                                                            .entreprise[
                                                                                            item
                                                                                              .init
                                                                                              .id
                                                                                          ]
                                                                                            .name
                                                                                        ) +
                                                                                          " "
                                                                                      ),
                                                                                      item
                                                                                        .init
                                                                                        .opportunity
                                                                                        ? [
                                                                                            _c(
                                                                                              "v-chip",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "ml-2",
                                                                                                attrs:
                                                                                                  {
                                                                                                    "x-small":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    label:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "primary",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "mr-1",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        start:
                                                                                                          "",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "far fa-handshake fa-fw"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      item
                                                                                                        .init
                                                                                                        .opportunity
                                                                                                        .id
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        : _vm._e(),
                                                                                      item
                                                                                        .init
                                                                                        .isCrossSelling
                                                                                        ? [
                                                                                            _c(
                                                                                              "v-chip",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "ml-2",
                                                                                                attrs:
                                                                                                  {
                                                                                                    "x-small":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    label:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "blue",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " CROSS-SELLING "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    2
                                                                                  ),
                                                                                  _c(
                                                                                    "v-list-item-subtitle",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item
                                                                                            .init
                                                                                            .name
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-list-item-action",
                                                                                {
                                                                                  staticClass:
                                                                                    "ma-0",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .dayjs(
                                                                                            item
                                                                                              .init
                                                                                              .start
                                                                                          )
                                                                                          .format(
                                                                                            "HH:mm"
                                                                                          )
                                                                                      ) +
                                                                                      " - " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .dayjs(
                                                                                            item
                                                                                              .init
                                                                                              .end
                                                                                          )
                                                                                          .format(
                                                                                            "HH:mm"
                                                                                          )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              _c("v-divider", {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "60px",
                                                                  "max-width":
                                                                    "calc(100% - 60px)",
                                                                },
                                                                attrs: {
                                                                  inset: "",
                                                                },
                                                              }),
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !_vm.eventsLoaded
                                                  ? [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            align: "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "text-center my-10",
                                                            },
                                                            [
                                                              _c(
                                                                "v-progress-circular",
                                                                {
                                                                  staticClass:
                                                                    "my-3",
                                                                  attrs: {
                                                                    size: 40,
                                                                    color:
                                                                      "primary",
                                                                    indeterminate:
                                                                      "",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "text-caption",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Chargement des données"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _vm.eventsLoaded &&
                                                Object.keys(_vm.events)
                                                  .length == 0
                                                  ? [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            align: "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "text-center my-10",
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "my-3",
                                                                  attrs: {
                                                                    size: "40",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "far fa-times-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "text-caption",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Aucun évènement pour cette période"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      false
        ? _c(
            "v-row",
            { staticStyle: { "margin-top": "-35px" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "px-0",
                  staticStyle: { position: "relative" },
                  attrs: { cols: "12", md: "4" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center primary--text text-uppercase font-weight-bold",
                    },
                    [_vm._v("Identifié")]
                  ),
                  _c(
                    "div",
                    _vm._l(_vm.tasks[0], function (item, key) {
                      return _c(
                        "v-col",
                        {
                          key: key,
                          staticClass: "py-1 my-1",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                hover: "",
                                color: _vm.returnCardColor(item.opp.statusId),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openOpp(item.opp.id)
                                },
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0 my-0",
                                          attrs: { cols: "8" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticStyle: { width: "100%" } },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass: "primary--text",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.opp.id) +
                                                      " - "
                                                  ),
                                                  item.entreprise
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "1rem",
                                                            color: "grey",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.entreprise
                                                                .name
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", [
                                            _c(
                                              "h5",
                                              { staticClass: "primary--text" },
                                              [_vm._v(_vm._s(item.opp.name))]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      item.typeArray
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right py-0 my-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    small: "",
                                                    color: item.typeArray.color,
                                                    "text-color": "white",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.typeArray.name
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability >= 25
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability >= 50
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability >= 75
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability === 100
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right py-0 my-0",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          item.opp.amount
                                            ? _c("h4", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.numStr(item.opp.amount)
                                                  ) + " €"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  item.nextEvent
                                    ? [
                                        _c("v-divider", {
                                          staticClass: "my-1",
                                        }),
                                        _c(
                                          "v-container",
                                          { attrs: { fluid: "" } },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "py-1",
                                                    attrs: { cols: "2" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.nextEvent.event
                                                              .eventtypeId === 1
                                                              ? "fa-phone"
                                                              : "fa-calendar"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "py-1",
                                                    attrs: { cols: "6" },
                                                  },
                                                  [
                                                    item.nextEvent.event.start
                                                      ? [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .dayjs(
                                                                    item
                                                                      .nextEvent
                                                                      .event
                                                                      .start
                                                                  )
                                                                  .format(
                                                                    "dddd DD-MM-YYYY HH:mm"
                                                                  )
                                                              ) +
                                                              " - " +
                                                              _vm._s(
                                                                item.nextEvent
                                                                  .event.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                                item.nextEvent.contact
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "font-italicpy-1",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.nextEvent
                                                                .contact
                                                                .firstname +
                                                                " " +
                                                                item.nextEvent
                                                                  .contact
                                                                  .lastname
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  _c("v-divider", { staticClass: "my-1" }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-2 pb-0",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("UserAvatar", {
                                            attrs: {
                                              userId: item.opp.userId,
                                              size: "35",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      item.opp.deadline
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right mt-1 pb-0",
                                              attrs: { cols: "5" },
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  class: {
                                                    "error--text":
                                                      _vm.getComparation(
                                                        item.opp.deadline,
                                                        item.opp.statusId
                                                      ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .dayjs(
                                                            item.opp.deadline
                                                          )
                                                          .format("DD-MM-YYYY")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right mt-1 pb-0",
                                          attrs: { cols: "5" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              item.comments.length > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: { cols: "6" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openModalComments(
                                                            item.comments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                  attrs,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "span",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {},
                                                                          "span",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "fa-comments"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item
                                                                                .comments
                                                                                .length
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            _vm._l(
                                                              item.comments,
                                                              function (
                                                                comments,
                                                                key
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  { key: key },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "2",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "UserAvatar",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    userId:
                                                                                      comments.userId,
                                                                                    size: "50",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "10",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-card",
                                                                              {
                                                                                staticClass:
                                                                                  "pa-2",
                                                                                attrs:
                                                                                  {
                                                                                    elevation:
                                                                                      "2",
                                                                                    width:
                                                                                      "250",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "font-italic",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .dayjs(
                                                                                            comments.createdAt
                                                                                          )
                                                                                          .format(
                                                                                            "DD/MM/YYYY à HH:mm"
                                                                                          )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        comments.body
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.medias > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-paperclip"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.medias) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center primary--text text-uppercase font-weight-bold",
                    },
                    [_vm._v("Étude")]
                  ),
                  _c(
                    "div",
                    _vm._l(_vm.tasks[1], function (item, key) {
                      return _c(
                        "v-col",
                        {
                          key: key,
                          staticClass: "py-1 my-1",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                hover: "",
                                color: _vm.returnCardColor(item.opp.statusId),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openOpp(item.opp.id)
                                },
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0 my-0",
                                          attrs: { cols: "8" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticStyle: { width: "100%" } },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass: "primary--text",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.opp.id) +
                                                      " - "
                                                  ),
                                                  item.entreprise
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "1rem",
                                                            color: "grey",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.entreprise
                                                                .name
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", [
                                            _c(
                                              "h5",
                                              { staticClass: "primary--text" },
                                              [_vm._v(_vm._s(item.opp.name))]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      item.typeArray
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right py-0 my-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    small: "",
                                                    color: item.typeArray.color,
                                                    "text-color": "white",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.typeArray.name
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability >= 25
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability >= 50
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability >= 75
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability === 100
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right py-0 my-0",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          item.opp.amount
                                            ? _c("h4", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.numStr(item.opp.amount)
                                                  ) + " €"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  item.nextEvent
                                    ? [
                                        _c("v-divider", {
                                          staticClass: "my-1",
                                        }),
                                        _c(
                                          "v-container",
                                          { attrs: { fluid: "" } },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "py-1",
                                                    attrs: { cols: "2" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.nextEvent.event
                                                              .eventtypeId === 1
                                                              ? "fa-phone"
                                                              : "fa-calendar"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "py-1",
                                                    attrs: { cols: "6" },
                                                  },
                                                  [
                                                    item.nextEvent.event.start
                                                      ? [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .dayjs(
                                                                    item
                                                                      .nextEvent
                                                                      .event
                                                                      .start
                                                                  )
                                                                  .format(
                                                                    "dddd DD-MM-YYYY HH:mm"
                                                                  )
                                                              ) +
                                                              " - " +
                                                              _vm._s(
                                                                item.nextEvent
                                                                  .event.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                                item.nextEvent.contact
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "font-italicpy-1",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.nextEvent
                                                                .contact
                                                                .firstname +
                                                                " " +
                                                                item.nextEvent
                                                                  .contact
                                                                  .lastname
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  _c("v-divider", { staticClass: "my-1" }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-2 pb-0",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("UserAvatar", {
                                            attrs: {
                                              userId: item.opp.userId,
                                              size: "35",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      item.opp.deadline
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right mt-1 pb-0",
                                              attrs: { cols: "5" },
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  class: {
                                                    "error--text":
                                                      _vm.getComparation(
                                                        item.opp.deadline,
                                                        item.opp.statusId
                                                      ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .dayjs(
                                                            item.opp.deadline
                                                          )
                                                          .format("DD-MM-YYYY")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right mt-1 pb-0",
                                          attrs: { cols: "5" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              item.comments.length > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: { cols: "6" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openModalComments(
                                                            item.comments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                  attrs,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "span",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {},
                                                                          "span",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "fa-comments"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item
                                                                                .comments
                                                                                .length
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            _vm._l(
                                                              item.comments,
                                                              function (
                                                                comments,
                                                                key
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  { key: key },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "2",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "UserAvatar",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    userId:
                                                                                      comments.userId,
                                                                                    size: "50",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "10",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-card",
                                                                              {
                                                                                staticClass:
                                                                                  "pa-2",
                                                                                attrs:
                                                                                  {
                                                                                    elevation:
                                                                                      "2",
                                                                                    width:
                                                                                      "250",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "font-italic",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .dayjs(
                                                                                            comments.createdAt
                                                                                          )
                                                                                          .format(
                                                                                            "DD/MM/YYYY à HH:mm"
                                                                                          )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        comments.body
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.medias > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-paperclip"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.medias) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center primary--text text-uppercase font-weight-bold",
                    },
                    [_vm._v("Négociation")]
                  ),
                  _c(
                    "div",
                    _vm._l(_vm.tasks[2], function (item, key) {
                      return _c(
                        "v-col",
                        {
                          key: key,
                          staticClass: "py-1 my-1",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                hover: "",
                                color: _vm.returnCardColor(item.opp.statusId),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openOpp(item.opp.id)
                                },
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0 my-0",
                                          attrs: { cols: "8" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticStyle: { width: "100%" } },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass: "primary--text",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.opp.id) +
                                                      " - "
                                                  ),
                                                  item.entreprise
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "1rem",
                                                            color: "grey",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.entreprise
                                                                .name
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", [
                                            _c(
                                              "h5",
                                              { staticClass: "primary--text" },
                                              [_vm._v(_vm._s(item.opp.name))]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      item.typeArray
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right py-0 my-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    small: "",
                                                    color: item.typeArray.color,
                                                    "text-color": "white",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.typeArray.name
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability >= 25
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability >= 50
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability >= 75
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color:
                                                  item.opp.probability === 100
                                                    ? "#fdd835"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("fa-star")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right py-0 my-0",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          item.opp.amount
                                            ? _c("h4", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.numStr(item.opp.amount)
                                                  ) + " €"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  item.nextEvent
                                    ? [
                                        _c("v-divider", {
                                          staticClass: "my-1",
                                        }),
                                        _c(
                                          "v-container",
                                          { attrs: { fluid: "" } },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "py-1",
                                                    attrs: { cols: "2" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.nextEvent.event
                                                              .eventtypeId === 1
                                                              ? "fa-phone"
                                                              : "fa-calendar"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "py-1",
                                                    attrs: { cols: "6" },
                                                  },
                                                  [
                                                    item.nextEvent.event.start
                                                      ? [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .dayjs(
                                                                    item
                                                                      .nextEvent
                                                                      .event
                                                                      .start
                                                                  )
                                                                  .format(
                                                                    "dddd DD-MM-YYYY HH:mm"
                                                                  )
                                                              ) +
                                                              " - " +
                                                              _vm._s(
                                                                item.nextEvent
                                                                  .event.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                                item.nextEvent.contact
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "font-italicpy-1",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.nextEvent
                                                                .contact
                                                                .firstname +
                                                                " " +
                                                                item.nextEvent
                                                                  .contact
                                                                  .lastname
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  _c("v-divider", { staticClass: "my-1" }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-2 pb-0",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("UserAvatar", {
                                            attrs: {
                                              userId: item.opp.userId,
                                              size: "35",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      item.opp.deadline
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right mt-1 pb-0",
                                              attrs: { cols: "5" },
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  class: {
                                                    "error--text":
                                                      _vm.getComparation(
                                                        item.opp.deadline,
                                                        item.opp.statusId
                                                      ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .dayjs(
                                                            item.opp.deadline
                                                          )
                                                          .format("DD-MM-YYYY")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right mt-1 pb-0",
                                          attrs: { cols: "5" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              item.comments.length > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: { cols: "6" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openModalComments(
                                                            item.comments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                  attrs,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "span",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {},
                                                                          "span",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "fa-comments"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item
                                                                                .comments
                                                                                .length
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            _vm._l(
                                                              item.comments,
                                                              function (
                                                                comments,
                                                                key
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  { key: key },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "2",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "UserAvatar",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    userId:
                                                                                      comments.userId,
                                                                                    size: "50",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "10",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-card",
                                                                              {
                                                                                staticClass:
                                                                                  "pa-2",
                                                                                attrs:
                                                                                  {
                                                                                    elevation:
                                                                                      "2",
                                                                                    width:
                                                                                      "250",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "font-italic",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .dayjs(
                                                                                            comments.createdAt
                                                                                          )
                                                                                          .format(
                                                                                            "DD/MM/YYYY à HH:mm"
                                                                                          )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        comments.body
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.medias > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-paperclip"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.medias) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }