<template>
  <v-card min-height="60vh" elevation="0" style="z-index: 10">
    <modal max-width="1024" v-model="linkModal">
      <v-stepper v-model="newLink.step" vertical>
        <v-stepper-step :complete="newLink.step > 1" step="1">
          Selectionner un type de lien
          <small>Opportunité, événement, client, contact...</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-select
            :menu-props="{ maxHeight: '300' }"
            outlined
            :items="linkType"
            item-text="name"
            item-value="type"
            v-model="newLink.type"
            return-object
            label="Sélectionner le type de lien"
          >
          </v-select>
        </v-stepper-content>

        <v-stepper-step :complete="newLink.step > 2" step="2">
          {{ newLink.type ? newLink.type.name : "Choix" }}
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-autocomplete
            :menu-props="{ maxHeight: '300' }"
            v-model="newLink.link"
            :items="itemsList"
            chips
            outlined
            label="Choisir l'élément à lier"
            item-text="name"
            item-value="id"
            :search-input.sync="search.name"
          >
          </v-autocomplete>
          <v-btn text @click="newLink.step = 1"> Retour </v-btn>
          <v-btn color="primary" @click="saveLink" :loading="loading"> Lier </v-btn>
        </v-stepper-content>
      </v-stepper>
    </modal>

    <v-row>
      <v-col cols="2">
        <v-navigation-drawer v-model="drawer" height="100%" width="100%" permanent>
          <template>
            <v-text-field v-model="searchFolder" label="Dossiers" solo solo-inverted flat hide-details clearable></v-text-field>
            <div style="max-height: calc(100vh - 95px); overflow: auto">
              <v-treeview v-model="treeSelected" :items="items" on-icon="fa-folder-open" updateAll="selectTree()" activatable :search="searchFolder">
                <template v-slot:prepend="{ item, open }">
                  <v-icon small> far {{ open ? "fa-folder-open" : "fa-folder" }} </v-icon>
                </template>
                <template v-slot:label="{ item }">
                  <a
                    @click="
                      searchInMessages = null;
                      load(item);
                    "
                    >{{ item.name }}</a
                  >
                </template>
                <template v-slot:append="{ item }">
                  <v-chip class="float-end" x-small v-if="item.value.unreadItemCount > 0">
                    {{ item.value.unreadItemCount }}
                  </v-chip>
                </template>
              </v-treeview>
            </div>
          </template>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="10">
        <v-card v-if="selectedDirectory" elevation="0">
          <v-card-title>
            {{ selectedDirectory.name }}
            <v-chip small class="ml-2 float-end" v-if="selectedDirectory.value.unreadItemCount > 0">
              {{ selectedDirectory.value.unreadItemCount }}
            </v-chip>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="5" lg="3" xl="3" style="border-right: solid 1px lightgray">
                <v-row>
                  <v-col cols="2" class="left">
                    <v-btn
                      text
                      v-if="page > 1"
                      small
                      @click="
                        loadMail(page === 2 ? false : prevLink);
                        page -= 1;
                      "
                    >
                      <v-icon small>fa-chevron-left</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="8">
                    <v-text-field
                      v-model="searchInMessages"
                      label="Recherche"
                      dense
                      class="my-0 py-0"
                      :loading="searchInProgress"
                      hide-details
                      clearable
                      @keyup="filterEmailList()"
                      @click:clear="
                        searchInMessages = null;
                        loadMail();
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="text-right">
                    <v-btn
                      text
                      small
                      @click="
                        page += 1;
                        loadMail(nextLink);
                      "
                    >
                      <v-icon small>fa-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-list v-if="mails && mails.length > 0">
                  <div style="max-height: calc(100vh - 200px); overflow-y: auto">
                    <div v-for="(items, key) in mails" :key="key">
                      <v-list-item three-line @click="loadMessage(items)" :class="{ 'font-weight-bold': !items.isRead }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-row>
                              <v-col cols="12" class="font-italic mb-2">
                                <v-btn
                                  fab
                                  x-small
                                  class="mt-0 float-right"
                                  elevation="0"
                                  @click="
                                    itemsList = [];
                                    newLink = { step: 1, type: null, link: null, email: items };
                                    openLinkModal();
                                  "
                                >
                                  <v-icon x-small> fa-fw fa-link</v-icon>
                                </v-btn>
                                {{ items.sender.emailAddress.name }}
                                <br /><small class="grey--text">{{ dayjs(items.sentDateTime).format("DD/MM/YYYY HH:mm") }}</small>
                              </v-col>
                            </v-row>
                          </v-list-item-title>
                          <v-list-item-title class="blue-grey--text">{{ items.subject }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ items.bodyPreview.length > 50 ? items.bodyPreview.substr(0, 50) + "..." : items.bodyPreview }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider :key="key + 'divider'"></v-divider>
                    </div>
                  </div>
                </v-list>
                <v-list v-else-if="!searchPending" class="text-center">
                  <div style="max-height: calc(100vh - 200px); min-height: calc(100vh - 200px); overflow: auto">
                    <div class="text-center my-10 py-10 grey--text text--darken-2">
                      <v-icon x-large class="my-3">far fa-question-circle fa-fw </v-icon>
                      <br />
                      <h3>Aucun message {{ this.searchInMessages && this.searchInMessages.length > 3 ? "pour cette recherche" : "dans ce dossier" }}.</h3>
                      <p class="font-italic">
                        {{
                          this.searchInMessages && this.searchInMessages.length > 3
                            ? "Essayez un autre terme ou dans un autre dossier"
                            : "Sélectionnez un autre dossier"
                        }}.
                      </p>
                    </div>
                  </div>
                </v-list>
                <v-row v-if="mails && mails.length > 0">
                  <v-col cols="6" class="left">
                    <v-btn
                      text
                      v-if="page > 1"
                      small
                      @click="
                        page -= 1;
                        loadMail(page === 2 ? false : prevLink);
                      "
                    >
                      <v-icon small>fa-chevron-left</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="6" class="text-right">
                    <v-btn
                      text
                      small
                      @click="
                        page += 1;
                        loadMail(nextLink);
                      "
                    >
                      <v-icon small>fa-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="7" lg="9" xl="9">
                <div v-if="selectedMessage" class="selectedMessage">
                  <div class="container-fluid">
                    <v-card class="pa-2" elevation="0">
                      <v-row>
                        <v-col cols="12">
                          <h4 class="mb-1 pb-0">
                            {{ selectedMessage.sender.emailAddress.name }}
                            <span style="font-size: 0.9rem; font-style: italic">{{ selectedMessage.sender.emailAddress.address }} </span>
                          </h4>
                          <h3 class="mb-1 pb-3 pt-0 mt-0 blue-grey--text">{{ selectedMessage.subject }}</h3>
                          <small class="grey--text">{{ dayjs(selectedMessage.sentDateTime).format("DD/MM/YYYY HH:mm") }}</small>

                          <v-divider></v-divider>
                        </v-col>

                        <v-col cols="12" class="pa-3 ma-0">
                          <div style="max-height: calc(100vh - 260px); overflow-y: auto; overflow-x: hidden">
                            <div class="message-box--body" v-html="selectedMessage.body.content"></div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </div>
                <div v-else>
                  <v-row>
                    <v-col class="text-center">
                      <v-icon x-large>fa-mail-bulk</v-icon>
                      <h3>Sélectionner un élément pour le lire</h3>
                      <h5>Aucun élément sélectionné</h5>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "axios";
import simpleDebounce from "simple-debounce-vue-mixin";
import dayjs from "dayjs";
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";

export default {
  name: "Inbox",
  components: { Modal },
  props: {
    value: {
      default: false,
    },
  },
  mixins: [simpleDebounce, Tools],
  data() {
    return {
      linkModal: false,
      searchFolder: null,
      searchPending: false,
      searchInMessages: null,
      searchInProgress: false,
      itemsList: [],
      loading: false,
      search: {
        firstname: null,
        lastname: null,
        function: null,
        phone2: null,
        email: null,
        id: null,
        state: null,
        status: null,
        step: null,
        categories: null,
        probability: null,
        commercial: null,
        client: null,
        type: null,
        deadline: null,
        name: "",
        isIndirect: null,
        isKey1: null,
        isKey2: null,
        isKey3: null,
        isKey4: null,
        siret: "",
        postalCode: "",
        city: "",
        sector: "",
        phone: "",
        ctNumAb: "",
        ape: "",
        sortedBy: null,
        sortedDesc: false,
        concurrent: false,
        all: false,
      },
      page: 1,
      linkType: [
        { name: "Opportunité", type: 0 },
        { name: "Événement", type: 1 },
        { name: "Compte", type: 2 },
        { name: "Contact", type: 3 },
      ],
      newLink: {
        step: 1,
        type: null,
        link: null,
        email: null,
      },
      drawer: true,
      items: [],
      folders: [],
      mini: true,
      selectedDirectory: null,
      tokenRespone: null,
      mails: null,
      selectedMessage: null,
      nextLink: null,
      prevLink: null,
      treeSelected: [],
    };
  },
  mounted() {
    this.getFolders();
  },
  methods: {
    async openLinkModal() {
      if (this.value !== false) {
        switch (this.value.name) {
          case "Client": {
            this.newLink.customer = this.value.id;
            break;
          }
          case "Opportunité": {
            this.newLink.opportunity = this.value.id;
            break;
          }
          case "Événement": {
            this.newLink.event = this.value.id;
            break;
          }
          case "Contact": {
            this.newLink.contact = this.value.id;
            break;
          }
        }
        let res = await this.$confirm("Êtes-vous sûr de vouloir lier cet e-mail?");

        if (res) {
          this.saveLink();
        }
      } else {
        this.linkModal = true;
      }
    },
    substringBetween(s, a, b) {
      var p = s.indexOf(a) + a.length;
      return s.substring(p, s.indexOf(b, p));
    },
    loadMessage(item) {
      let url = process.env.VUE_APP_GRAPH_V1 + "messages/" + item.id + "/attachments";
      axios(url, {
        headers: {
          Authorization: "Bearer " + this.tokenResponse.accessToken,
        },
      }).then((resp) => {
        let attachments = [];
        resp.data.value.forEach((attach) => {
          if (attach.contentType.startsWith("image/") && item.body.content.includes("cid:" + attach.contentId)) {
            // Si le corps de l'e-mail contient une référence à cette pièce jointe, remplacez-la par les données de l'image
            item.body.content = item.body.content.replace("cid:" + attach.contentId, "data:" + attach.contentType + ";base64, " + attach.contentBytes);
          } else {
            // Pour les autres types de pièces jointes, ajoutez-les à la liste des pièces jointes
            let downloadLink = "data:" + attach.contentType + ";base64, " + attach.contentBytes;
            attachments.push({ name: attach.name, link: downloadLink });
          }
        });
        attachments.forEach((attachment) => {
          // Vérifiez si la pièce jointe existe déjà dans le corps du message
          if (!item.body.content.includes(attachment.link)) {
            item.body.content += `<div class="attachment-card"><a href="${attachment.link}" download="${attachment.name}">${attachment.name}</a></div>`;
          }
        });

        this.selectedMessage = item;

        let scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

        this.selectedMessage.body.content = this.stringReplace(this.selectedMessage.body.content.replace(scriptRegex, ""));
      });
    },
    stringReplace(str) {
      const stringToReplace = ["a:link", ".container\r\n", "hr\r\n"];

      stringToReplace.forEach((e) => {
        str = str.replace(e, ".message-box--body " + e);
        str = str.replace(".message-box--body .message-box--body", ".message-box--body ");
      });
      return str;
    },
    saveLink() {
      this.loading = true;
      let data = {
        email: this.newLink.email,
      };

      if (this.value !== false) {
        if (this.newLink.opportunity) {
          data.opportunity = this.newLink.opportunity;
        } else if (this.newLink.event) {
          data.event = this.newLink.event;
        } else if (this.newLink.customer) {
          data.customer = this.newLink.customer;
        } else if (this.newLink.contact) {
          data.contact = this.newLink.contact;
        }
      } else {
        if (this.newLink.type.type === 0) {
          data.opportunity = this.newLink.link;
        } else if (this.newLink.type.type === 1) {
          data.event = this.newLink.link;
        } else if (this.newLink.type.type === 2) {
          data.customer = this.newLink.link;
        } else if (this.newLink.type.type === 3) {
          data.contact = this.newLink.link;
        }
      }

      this.$axios.post("/api/email", { data: data }).then(() => {
        this.$simpleToast.success("Email", "L'e-mail a bien été lié");
        this.linkModal = false;
        this.loading = false;
        this.$emit("close");
      });
    },
    load(item) {
      this.selectedDirectory = item;
      this.selectedMessage = null;
      this.loadMail();
    },
    filterEmailList() {
      if (this.searchInMessages.length > 3)
        this.simpleDebounce(() => {
          this.loadMail();
        }, 300 /* optional debounce time: default is 300 */);
    },
    loadMail(url = false) {
      this.mails = null;
      let filters = "";
      if (!url) {
        if (this.searchInMessages && this.searchInMessages.length > 3) {
          this.searchInProgress = true;
          filters = "?" + "$search=" + this.searchInMessages;
        }
        url = process.env.VUE_APP_GRAPH_V1 + "mailFolders/" + this.selectedDirectory.value.id + "/messages" + filters;
        this.searchPending = true;
      }
      axios(url, {
        headers: {
          Authorization: "Bearer " + this.tokenResponse.accessToken,
        },
      })
        .then((resp) => {
          if (this.page === 2) {
            this.prevLink = url;
          }
          if (this.prevLink === null) {
            this.prevLink = this.nextLink;
          }
          this.nextLink = resp.data["@odata.nextLink"];

          let tmp = [];
          resp.data.value.forEach((item) => {
            let image = "";
            /*axios("https://graph.microsoft.com/v1.0/users/" + item.sender.emailAddress.address  + "/photo/$value",{
            headers: {
              Authorization: "Bearer " + this.tokenResponse.accessToken,
            },
            responseType: 'blob'
          }).then((resp) => {
            var reader = new FileReader();
            reader.readAsDataURL(resp.data);
            reader.onloadend = () => {
              //image = reader.result;
            }
          });*/
            item = { ...item, ...{ image: image } };
            tmp.push(item);
          });
          this.mails = tmp;

          this.searchInProgress = false;
          this.searchPending = false;
        })
        .catch(() => {
          this.searchInProgress = false;
          this.searchPending = false;
        });
    },
    async getFolders() {
      this.items = [];
      try {
        this.tokenResponse = await this.$msal.acquireTokenSilent({
          account: this.$msal.getAllAccounts()[0],
          scopes: ["user.read", "mail.send", "Mail.ReadBasic", "Mail.ReadWrite", "Mail.Read", "Contacts.Read"],
        });

        const response = await axios(process.env.VUE_APP_GRAPH_V1 + "mailFolders?$top=100", {
          headers: {
            Authorization: "Bearer " + this.tokenResponse.accessToken,
          },
        });

        response.data.value.forEach((item) => {
          let tmp = {
            id: item.id,
            name: item.displayName,
            value: item,
            children: [],
            hasSub: !!parseInt(item.childFolderCount) > 0,
          };
          this.items.push(tmp);
          if (item.displayName === "Boîte de réception") {
            this.load(tmp);
          }
        });

        Object.keys(this.items).forEach((key) => {
          if (this.items[key].hasSub)
            axios(process.env.VUE_APP_GRAPH_V1 + "mailFolders/" + this.items[key].id + "/childFolders", {
              headers: {
                Authorization: "Bearer " + this.tokenResponse.accessToken,
              },
            }).then((resp) => {
              resp.data.value.forEach((item) => {
                let tmp = {
                  id: item.id,
                  name: item.displayName,
                  value: item,
                  children: [],
                  hasSub: !!parseInt(item.childFolderCount) > 0,
                };

                this.items[key].children.push(tmp);
              });
              Object.keys(this.items[key].children).forEach((keyS) => {
                if (this.items[key].children[keyS].hasSub) {
                  axios(process.env.VUE_APP_GRAPH_V1 + "mailFolders/" + this.items[key].children[keyS].id + "/childFolders", {
                    headers: {
                      Authorization: "Bearer " + this.tokenResponse.accessToken,
                    },
                  }).then((r) => {
                    r.data.value.forEach((itemS) => {
                      let tmp = { id: itemS.id, name: itemS.displayName, value: itemS, children: [] };

                      this.items[key].children[keyS].children.push(tmp);
                    });
                  });
                }
              });
            });
        });
      } catch (error) {
        console.log("Token expired");
        this.$store.dispatch("logout").then(() => {
          this.$router.push("/login").catch(() => {});
        });
      }
    },
    getItemList() {
      this.itemsList = [];
      let search = this.search;
      search.all = false;
      switch (this.newLink.type.type) {
        case 0: {
          this.$axios("/api/opportunities/user/all", { params: { page: 1, search, limit: 10 } }).then((res) => {
            res.data.data.forEach((item) => {
              this.itemsList.push({ id: item.item.id, name: item.item.name });
            });
          });
          break;
        }
        case 1: {
          this.$axios("/api/events/linkemail", { params: { search: this.search.name } }).then((res) => {
            res.data.events.forEach((item) => {
              this.itemsList.push({
                id: item.id,
                name:
                  "[" +
                  dayjs(item.start).format("DD/MM/YYYY à HH:mm") +
                  "]" +
                  " [" +
                  res.data.type[item.eventtypeId].name +
                  "] " +
                  res.data.customer[item.id] +
                  ": " +
                  item.name,
              });
            });
          });
          break;
        }
        case 2: {
          let tmpSearch = {
            name: "",
            isIndirect: null,
            siret: "",
            cityPostalCode: "",
            city: "",
            sector: "",
            phone: "",
            ctNumAb: "",
            ape: "",
            sortedBy: null,
            sortedDesc: false,
          };
          tmpSearch.name = search.name;

          this.$axios("/api/customers", {
            params: {
              page: 1,
              search: tmpSearch,
              limit: 5,
              userMode: "tous",
              typeMode: "tous",
              campaignOnly: false,
              prospectOnly: true,
              clientOnly: true,
              customerKey: false,
            },
          }).then((resp) => {
            resp.data.data.forEach((item) => {
              this.itemsList.push({ id: item.customer.id, name: item.entreprise.name });
            });
          });
          break;
        }
        case 3: {
          search.lastname = search.name;
          search.all = true;
          this.$axios("/api/contacts", { params: { page: 1, search, limit: 10 } }).then((res) => {
            res.data.data.forEach((item) => {
              this.itemsList.push({ id: item.contact.id, name: item.contact.firstname + " " + item.contact.lastname });
            });
          });
          break;
        }
      }
    },
  },
  watch: {
    items() {
      if (this.items) {
        for (let item of this.items) {
          if (item.name === "Inbox") {
            this.load(item);
          }
        }
      }
    },

    "newLink.type"() {
      if (this.newLink.type !== null) {
        this.newLink.step = 2;
      }
    },
    "search.name"() {
      if (this.newLink.type !== null) {
        this.simpleDebounce(() => {
          this.getItemList();
        }, 300 /* optional debounce time: default is 300 */);
      }
    },
  },
};
</script>

<style>
.messagesModal img,
.selectedMessage img {
  max-width: 100% !important;
  height: auto !important;
}

.messagesModal td img,
.selectedMessage td img {
  max-width: none !important;
}

.v-treeview-node__root {
  min-height: 40px !important;
  max-height: 40px !important;
}
.attachment-card {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 10px;
  background-color: #f9f9f9;
}
</style>
