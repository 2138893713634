var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MaterialCard",
    {
      staticClass: "v-card-profile mt-10",
      attrs: { color: "primary" },
      scopedSlots: _vm._u([
        {
          key: "heading",
          fn: function () {
            return [
              _vm.isReady
                ? _c(
                    "v-tabs",
                    {
                      attrs: {
                        "background-color": "transparent",
                        "slider-color": "white",
                        vertical: _vm.isMobile(),
                      },
                      model: {
                        value: _vm.tabs,
                        callback: function ($$v) {
                          _vm.tabs = $$v
                        },
                        expression: "tabs",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        { staticClass: "mr-3" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pa-0 ma-0" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("fa-fw fa-users")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        color: "secondary",
                                        content: _vm.maxContact
                                          ? _vm.maxContact
                                          : "0",
                                      },
                                    },
                                    [_vm._v(" Contacts ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        { staticClass: "mr-3" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pa-0 ma-0" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("fa-fw fa-user-secret")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        color: "secondary",
                                        content:
                                          _vm.customer.concurrent.length > 0
                                            ? _vm.customer.concurrent.length
                                            : "0",
                                      },
                                    },
                                    [_vm._v(" Nos Concurrents ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.readyClient && _vm.clients.length > 0
                        ? _c(
                            "v-tab",
                            { staticClass: "mr-3" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pa-0 ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("fa-fw fa-user-check")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "9" } },
                                    [
                                      _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            color: "secondary",
                                            content:
                                              _vm.clients.length > 0
                                                ? _vm.clients.length
                                                : "0",
                                          },
                                        },
                                        [_vm._v(" Clients ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "modal",
        {
          attrs: { "max-width": "1024px" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Edition contact"),
                  ]),
                  _c("br"),
                  _c(
                    "small",
                    { staticClass: "mt-0" },
                    [
                      _vm.contact
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(_vm.contact.contact.firstname) +
                                " " +
                                _vm._s(_vm.contact.contact.lastname)
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        disabled:
                          !_vm.singleContact &&
                          _vm.singleContactId !== _vm.contact.contact.id,
                        variant: "flat",
                        color: "primary",
                        elevation: "0",
                      },
                      on: { click: _vm.validateEdit },
                    },
                    [_vm._v(" Enregistrer ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.modalEdit = false
                        },
                      },
                    },
                    [_vm._v(" Fermer ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.modalEdit,
            callback: function ($$v) {
              _vm.modalEdit = $$v
            },
            expression: "modalEdit",
          },
        },
        [
          _vm.contact
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      !_vm.singleContact &&
                      _vm.singleContactId !== _vm.contact.contact.id
                        ? _c(
                            "v-alert",
                            {
                              attrs: {
                                color: "error",
                                outlined: "",
                                small: "",
                                prominent: "",
                                dense: "",
                                text: "",
                                type: "error",
                              },
                            },
                            [_vm._v(" Un contact existe déjà avec cet email. ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "ma-0 mt-3", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "ma-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      items: _vm.civilities,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Civilité",
                                    },
                                    model: {
                                      value: _vm.contact.contact.civilityId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contact.contact,
                                          "civilityId",
                                          $$v
                                        )
                                      },
                                      expression: "contact.contact.civilityId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "ma-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: { outlined: "", label: "Prénom" },
                                    model: {
                                      value: _vm.contact.contact.firstname,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contact.contact,
                                          "firstname",
                                          $$v
                                        )
                                      },
                                      expression: "contact.contact.firstname",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "ma-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: { outlined: "", label: "Nom" },
                                    model: {
                                      value: _vm.contact.contact.lastname,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contact.contact,
                                          "lastname",
                                          $$v
                                        )
                                      },
                                      expression: "contact.contact.lastname",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          false
                            ? _c(
                                "v-col",
                                { staticClass: "ma-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          label: "Fonction",
                                        },
                                        model: {
                                          value: _vm.contact.role,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.contact, "role", $$v)
                                          },
                                          expression: "contact.role",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { staticClass: "ma-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      outlined: "",
                                      "menu-props": { maxHeight: "300" },
                                      items: _vm.jobs,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Fonction",
                                      "append-outer-icon":
                                        _vm.contact.job !== null
                                          ? "fa-times"
                                          : "",
                                      "item-per-page": "5",
                                    },
                                    on: {
                                      "click:append-outer": function ($event) {
                                        _vm.contact.job = null
                                      },
                                    },
                                    model: {
                                      value: _vm.contact.job,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.contact, "job", $$v)
                                      },
                                      expression: "contact.job",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "ma-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-textarea", {
                                    attrs: { outlined: "", label: "Note" },
                                    model: {
                                      value: _vm.contact.contact.note,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contact.contact,
                                          "note",
                                          $$v
                                        )
                                      },
                                      expression: "contact.contact.note",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "ma-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "ma-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          label: "E-mail",
                                        },
                                        model: {
                                          value: _vm.contact.contact.email,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contact.contact,
                                              "email",
                                              $$v
                                            )
                                          },
                                          expression: "contact.contact.email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "ma-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          label: "Téléphone fixe",
                                        },
                                        model: {
                                          value: _vm.contact.contact.phone,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contact.contact,
                                              "phone",
                                              $$v
                                            )
                                          },
                                          expression: "contact.contact.phone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "ma-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          label: "Téléphone portable",
                                        },
                                        model: {
                                          value: _vm.contact.contact.phone2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contact.contact,
                                              "phone2",
                                              $$v
                                            )
                                          },
                                          expression: "contact.contact.phone2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              false
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "ma-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              label: "Skype",
                                            },
                                            model: {
                                              value: _vm.contact.contact.skype,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contact.contact,
                                                  "skype",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contact.contact.skype",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.contact.customers.length > 0
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "ma-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-overline" },
                                        [_vm._v("Associations")]
                                      ),
                                      _c("v-divider"),
                                      _vm._l(
                                        _vm.contact.customers,
                                        function (itm, key) {
                                          return _c(
                                            "v-col",
                                            {
                                              key: key,
                                              staticClass: "pb-0",
                                              attrs: { cols: "12" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.loadCustomer(itm)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    small: "",
                                                    label: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(itm.name) + " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("ConcurrentFormCustomers", {
        attrs: {
          concurrent: "",
          link: "",
          origin: this.customer.customer.isConcurrent,
        },
        on: {
          linkconcu: _vm.prepareLink,
          refresh: function ($event) {
            return _vm.$emit("refresh-all", true)
          },
        },
        model: {
          value: _vm.modalAddConcurrent,
          callback: function ($$v) {
            _vm.modalAddConcurrent = $$v
          },
          expression: "modalAddConcurrent",
        },
      }),
      _c(
        "modal",
        {
          attrs: { "max-width": "1024" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "error" },
                          on: {
                            click: function ($event) {
                              _vm.addingConcurent = null
                              _vm.modalLinkConcurrent = false
                            },
                          },
                        },
                        [_vm._v("FERMER")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: !_vm.linkType || !_vm.addingConcurent,
                          },
                          on: { click: _vm.linkConcurrent },
                        },
                        [_vm._v("LIER")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.modalLinkConcurrent,
            callback: function ($$v) {
              _vm.modalLinkConcurrent = $$v
            },
            expression: "modalLinkConcurrent",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0 py-1 ma-0 mt-3", attrs: { cols: "12" } },
                [
                  _c("v-select", {
                    staticClass: "pa-0 ma-0",
                    attrs: {
                      "menu-props": { maxHeight: "300" },
                      label: "Métier",
                      "item-value": "id",
                      "item-text": "name",
                      items: _vm.types,
                    },
                    model: {
                      value: _vm.linkType,
                      callback: function ($$v) {
                        _vm.linkType = $$v
                      },
                      expression: "linkType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-0 py-1 ma-0", attrs: { cols: "12" } },
                [
                  _c("selectConcurrents", {
                    attrs: { icon: false, label: "Rechercher un concurrent" },
                    model: {
                      value: _vm.addingConcurent,
                      callback: function ($$v) {
                        _vm.addingConcurent = $$v
                      },
                      expression: "addingConcurent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isReady
        ? _c(
            "v-tabs-items",
            {
              staticClass: "transparent",
              model: {
                value: _vm.tabs,
                callback: function ($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "modal",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "actions",
                            fn: function () {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-4",
                                    attrs: { text: "" },
                                    on: { click: _vm.linkContactSub },
                                  },
                                  [_vm._v(" Lier ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1625174688
                      ),
                      model: {
                        value: _vm.linkContact,
                        callback: function ($$v) {
                          _vm.linkContact = $$v
                        },
                        expression: "linkContact",
                      },
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "menu-props": { maxHeight: "300" },
                          label: "Rechercher un contact",
                          "item-text": "fullname",
                          "item-value": "id",
                          items: _vm.contactsSearch,
                          "search-input": _vm.searchText,
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.searchText = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.searchText = $event
                          },
                        },
                        model: {
                          value: _vm.selectedContact,
                          callback: function ($$v) {
                            _vm.selectedContact = $$v
                          },
                          expression: "selectedContact",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "modal",
                    {
                      attrs: { "max-width": "720" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c(
                                  "strong",
                                  {
                                    staticClass: "text-h5 mb-0 text-uppercase",
                                  },
                                  [_vm._v("Création contact")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "actions",
                            fn: function () {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-4",
                                    attrs: {
                                      variant: "flat",
                                      color: "primary",
                                      elevation: "0",
                                      disabled: !_vm.singleContact,
                                    },
                                    on: { click: _vm.newContactSub },
                                  },
                                  [_vm._v(" Enregistrer ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-4",
                                    attrs: { color: "secondary", text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.addContact = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Fermer ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        10957361
                      ),
                      model: {
                        value: _vm.addContact,
                        callback: function ($$v) {
                          _vm.addContact = $$v
                        },
                        expression: "addContact",
                      },
                    },
                    [
                      !_vm.singleContact
                        ? _c(
                            "v-alert",
                            {
                              attrs: {
                                color: "error",
                                outlined: "",
                                small: "",
                                prominent: "",
                                dense: "",
                                text: "",
                                type: "error",
                              },
                            },
                            [_vm._v(" Un contact existe déjà avec cet email. ")]
                          )
                        : _vm._e(),
                      _c("contact-form", {
                        model: {
                          value: _vm.newContact,
                          callback: function ($$v) {
                            _vm.newContact = $$v
                          },
                          expression: "newContact",
                        },
                      }),
                      _vm.similar && _vm.similar.length > 0
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-divider"),
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "mt-4",
                                      attrs: { color: "error" },
                                    },
                                    [_c("h4", [_vm._v("Contacts similaires")])]
                                  ),
                                ],
                                1
                              ),
                              _vm._l(_vm.similar, function (item, key) {
                                return _c(
                                  "v-col",
                                  {
                                    key: key,
                                    staticClass: "py-1",
                                    attrs: { cols: "6", md: "6" },
                                  },
                                  [
                                    _c("SimilarContactCard", {
                                      attrs: {
                                        item: item,
                                        linkContactConfirm:
                                          _vm.linkContactConfirm,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "11" } },
                            [
                              _c("v-text-field", {
                                staticClass: "py-0",
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: "Rechercher",
                                  "single-line": "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.searchContact,
                                  callback: function ($$v) {
                                    _vm.searchContact = $$v
                                  },
                                  expression: "searchContact",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "text-right", attrs: { cols: "1" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "addContact",
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    fab: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.similar = []
                                      _vm.singleContact = true
                                      _vm.newContact = {
                                        note: "",
                                        firstname: "",
                                        lastname: "",
                                        phone: "",
                                        fax: "",
                                        phone2: "",
                                        function: "",
                                        email: "",
                                        civilityId: null,
                                        isFavorite: false,
                                        job: null,
                                      }
                                      _vm.addContact = true
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("fa-plus"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-data-table", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          headers: _vm.headersContact,
                          items: _vm.contacts,
                          "items-per-page": _vm.limit,
                          page: _vm.page,
                          search: _vm.searchContact,
                          "server-items-length": _vm.maxContact,
                          "footer-props": {
                            showFirstLastPage: true,
                            itemsPerPageOptions: [5, 10, 15, -1],
                            firstIcon: "fa-angle-double-left",
                            lastIcon: "fa-angle-double-right",
                            prevIcon: "fa-angle-left",
                            nextIcon: "fa-angle-right",
                          },
                        },
                        on: {
                          "update:items-per-page": _vm.setItemMax,
                          "update:page": _vm.setPage,
                          "click:row": _vm.openEdit,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.isFavorite",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "toggleFavoriteBtn",
                                      attrs: {
                                        small: "",
                                        color: item.isFavorite
                                          ? "yellow darken-2"
                                          : "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return (() => {
                                            item.isFavorite = !item.isFavorite
                                            _vm.toggleFavorite(item.origin)
                                          }).apply(null, arguments)
                                        },
                                      },
                                    },
                                    [_vm._v("fa-star")]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.phone",
                              fn: function ({ item }) {
                                return [
                                  item.phone
                                    ? _c(
                                        "div",
                                        { staticClass: "text-no-wrap" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { "x-small": "" },
                                            },
                                            [_vm._v("fa-fw fa-phone")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "tel:" + item.phone,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.phone))]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.phone2
                                    ? _c(
                                        "div",
                                        { staticClass: "text-no-wrap" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { "x-small": "" },
                                            },
                                            [_vm._v("fa-fw fa-mobile-alt")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "tel:" + item.phone2,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.phone2))]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "item.email",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "a",
                                    { attrs: { href: "mailto:" + item.email } },
                                    [_vm._v(_vm._s(item.email))]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.function",
                              fn: function ({ item }) {
                                return [
                                  item.job
                                    ? [_vm._v(_vm._s(item.job.name))]
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "item.actions",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: { "offset-y": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-dots-vertical"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        [
                                          item.origin.contact.note &&
                                          item.origin.contact.note.length > 0
                                            ? _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "open-on-hover": "",
                                                        "close-on-content-click": false,
                                                        "max-width": "250",
                                                        left: "",
                                                        "offset-x": "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {},
                                                                      "v-list-item-title",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1",
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fa-fw fa-comment"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      "Note"
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          attrs: {
                                                            elevation: "0",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "py-2 grey--text text--darken-3 text-uppercase subtitle-1",
                                                            },
                                                            [_vm._v(" Note ")]
                                                          ),
                                                          _c("v-divider"),
                                                          _c("v-card-text", [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.origin
                                                                      .contact
                                                                      .note
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-list-item",
                                            [
                                              item.origin.contact
                                                ? _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "open-on-hover": "",
                                                        "close-on-content-click": false,
                                                        "max-width": "350",
                                                        "min-width": "350",
                                                        left: "",
                                                        "offset-x": "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {},
                                                                      "v-list-item-title",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1",
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fa-fw fa-edit"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      "Historique"
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          attrs: {
                                                            elevation: "0",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "py-2 grey--text text--darken-3 text-uppercase subtitle-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Historique éditions "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-divider"),
                                                          _c("v-card-text", [
                                                            _c("span", [
                                                              item.origin
                                                                .contact
                                                                .createdAt
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "my-1",
                                                                    },
                                                                    [
                                                                      item
                                                                        .origin
                                                                        .contact
                                                                        .createdUser
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _c(
                                                                                "UserAvatar",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-2",
                                                                                  attrs:
                                                                                    {
                                                                                      userId:
                                                                                        item
                                                                                          .origin
                                                                                          .contact
                                                                                          .createdUser
                                                                                          .id,
                                                                                      size: "35",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _c(
                                                                                "v-avatar",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-2",
                                                                                  attrs:
                                                                                    {
                                                                                      size: "35",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          src: "/images/users/nopic.png",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                      _vm._v(
                                                                        " Création le " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .dayjs(
                                                                                item
                                                                                  .origin
                                                                                  .contact
                                                                                  .createdAt
                                                                              )
                                                                              .format(
                                                                                "DD/MM/YYYY à HH:mm"
                                                                              )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              item.origin
                                                                .contact
                                                                .updatedAt
                                                                ? _c("div", [
                                                                    item.origin
                                                                      .contact
                                                                      .updatedUser
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _c(
                                                                              "UserAvatar",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-2",
                                                                                attrs:
                                                                                  {
                                                                                    userId:
                                                                                      item
                                                                                        .origin
                                                                                        .contact
                                                                                        .updatedUser
                                                                                        .id,
                                                                                    size: "35",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _c(
                                                                          "span",
                                                                          [
                                                                            _c(
                                                                              "v-avatar",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-2",
                                                                                attrs:
                                                                                  {
                                                                                    size: "35",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        src: "/images/users/nopic.png",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                    _vm._v(
                                                                      " Dernière modification le " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .dayjs(
                                                                              item
                                                                                .origin
                                                                                .contact
                                                                                .updatedAt
                                                                            )
                                                                            .format(
                                                                              "DD/MM/YYYY à HH:mm"
                                                                            )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          item?.emails?.length > 0
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openEmails(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fa-fw fa-envelope"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v("Emails"),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.removeContact(
                                                    item.origin.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "mr-1 removeContactBtn",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("fa-fw fa-trash")]
                                                  ),
                                                  _vm._v("Supprimer"),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3100344958
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card-text",
                    [
                      _c("div", { staticStyle: { opacity: "0" } }, [
                        _vm._v("CONCU"),
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("v-text-field", {
                                staticClass: "py-0",
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: "Rechercher",
                                  "single-line": "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.searchContact,
                                  callback: function ($$v) {
                                    _vm.searchContact = $$v
                                  },
                                  expression: "searchContact",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "text-right", attrs: { cols: "2" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    color: "primary",
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.modalAddConcurrent = true
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("fa-fw fa-plus"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-3 linkConcurrentBtn",
                                  attrs: {
                                    fab: "",
                                    color: "primary",
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.addingConcurent = null
                                      _vm.linkType = null
                                      _vm.modalLinkConcurrent = true
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("fa-fw fa-link"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-data-table", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          headers: _vm.headersConcu,
                          "items-per-page": 5,
                          items: _vm.customer.concurrent,
                          page: _vm.pageConcu,
                          "footer-props": {
                            showFirstLastPage: true,
                            itemsPerPageOptions: [5, 10, 15, -1],
                            firstIcon: "fa-angle-double-left",
                            lastIcon: "fa-angle-double-right",
                            prevIcon: "fa-angle-left",
                            nextIcon: "fa-angle-right",
                          },
                        },
                        on: {
                          "click:row": _vm.loadConcurrent,
                          "update:items-per-page": _vm.setItemMaxConcu,
                          "update:page": _vm.setPageConcu,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.indicateur",
                              fn: function ({ item }) {
                                return [
                                  item.out
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    item.out
                                                      ? _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setIn(
                                                                        item.idRelation
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " fa-sign-out-alt "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Sortant")])]
                                      )
                                    : _vm._e(),
                                  item.in
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    item.in
                                                      ? _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setOut(
                                                                        item.idRelation
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "fa-sign-in-alt"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Entrant")])]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "item.dateUpdate",
                              fn: function ({ item }) {
                                return [
                                  item.dateUpdate
                                    ? [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .dayjs(item.dateUpdate)
                                              .format("DD/MM/YYYY à HH:mm")
                                          )
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "item.action",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "removeConcurrentBtn",
                                      attrs: { text: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.removeConcu(
                                            item.idRelation
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("fa-trash"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1894832041
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "11" } },
                            [
                              _c("v-text-field", {
                                staticClass: "py-0",
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: "Rechercher",
                                  "single-line": "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.searchContact,
                                  callback: function ($$v) {
                                    _vm.searchContact = $$v
                                  },
                                  expression: "searchContact",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "text-right", attrs: { cols: "1" } },
                            [
                              false
                                ? _c(
                                    "v-menu",
                                    {
                                      attrs: { "offset-y": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          small: "",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "white",
                                                          small: "",
                                                        },
                                                      },
                                                      [_vm._v("fa-plus")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1598428756
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.modalAddConcurrent = true
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { "x-small": "" },
                                                    },
                                                    [_vm._v("fa-plus")]
                                                  ),
                                                  _vm._v(" Ajouter"),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.addingConcurent = null
                                                      _vm.linkType = null
                                                      _vm.modalLinkConcurrent = true
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { "x-small": "" },
                                                    },
                                                    [_vm._v("fa-link")]
                                                  ),
                                                  _vm._v(" Lier"),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-data-table", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          headers: _vm.headersConcu,
                          "items-per-page": 5,
                          items: _vm.clients,
                        },
                        on: { "click:row": _vm.loadCustomer },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.indicateur",
                              fn: function ({ item }) {
                                return [
                                  item.out
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    item.out
                                                      ? _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setIn(
                                                                        item.idRelation
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "fa-sign-out-alt"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Sortant")])]
                                      )
                                    : _vm._e(),
                                  item.in
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    item.in
                                                      ? _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setOut(
                                                                        item.idRelation
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "fa-sign-in-alt"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Entrant")])]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "item.dateUpdate",
                              fn: function ({ item }) {
                                return [
                                  item.dateUpdate
                                    ? [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .dayjs(item.dateUpdate)
                                              .format("DD/MM/YYYY à HH:mm")
                                          )
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "item.actions",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "ml-2",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.loadCustomer(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v("fa-eye")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v("Voir la fiche")])]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3883546160
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("view-email-contact", {
        attrs: { contact: _vm.contact, emails: _vm.selectedContactEmails },
        model: {
          value: _vm.modalEmail,
          callback: function ($$v) {
            _vm.modalEmail = $$v
          },
          expression: "modalEmail",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }