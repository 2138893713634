var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "ma-0 pa-0" },
    [
      _vm.$slots.title
        ? _c(
            "v-col",
            { staticClass: "py-0 mt-5", attrs: { cols: "2" } },
            [_vm._t("title")],
            2
          )
        : _vm._e(),
      _c(
        "v-col",
        {
          staticClass: "py-0 ma-0",
          attrs: { cols: _vm.$slots.title ? 10 : 12 },
        },
        [
          _c("v-autocomplete", {
            attrs: {
              "menu-props": { maxHeight: "300" },
              items: _vm.users,
              clearable: _vm.clearable,
              chips: "",
              disabled: _vm.disabled,
              color: _vm.labelColor,
              label: _vm.label,
              "item-text": "fullname",
              "item-value": "id",
              "return-object": _vm.returnObject,
              multiple: _vm.multiple,
              "search-input": _vm.search,
              "persistent-placeholder": !!_vm.$slots.title,
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.search = $event
              },
              "update:search-input": function ($event) {
                _vm.search = $event
              },
              focus: _vm.loadUsers,
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function (data) {
                  return [
                    _c(
                      "v-chip",
                      _vm._b(
                        {
                          attrs: {
                            "input-value": data.selected,
                            close: _vm.multiple,
                          },
                          on: {
                            click: data.select,
                            "click:close": function ($event) {
                              return _vm.remove(data.item)
                            },
                          },
                        },
                        "v-chip",
                        data.attrs,
                        false
                      ),
                      [
                        _c("UserAvatar", {
                          attrs: { userId: data.item.id, left: "" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              data.item.firstname + " " + data.item.lastname
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item",
                fn: function (data) {
                  return [
                    [
                      _c(
                        "v-list-item-avatar",
                        [_c("UserAvatar", { attrs: { userId: data.item.id } })],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.item.firstname + " " + data.item.lastname
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                  ]
                },
              },
            ]),
            model: {
              value: _vm.usersSelected,
              callback: function ($$v) {
                _vm.usersSelected = $$v
              },
              expression: "usersSelected",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }