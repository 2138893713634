<template>
  <material-card icon="fa-chart-bar" title="Statistiques" class="mt-8 pb-12 px-7">
    <div v-for="(item, key) in Object.keys(stats)" :key="key">
      <div class="text-center">
        <v-row align="center" class="my-3">
          <v-divider></v-divider>
          <span class="text-overline primary--text mx-3" style="font-size: 1.2rem !important"> {{ item }}</span>
          <v-divider></v-divider>
        </v-row>
      </div>
      <v-container class="px-10">
        <v-row>
          <v-col :cols="12 / Object.keys(stats[item]).length" class="text-center my-3" v-for="(value, k) in Object.keys(stats[item])" :key="k">
            <h1 class="primary--text py-0 my-0">
              <span>{{ stats[item][value] }}</span>
            </h1>
            <h4 style="font-style: italic; font-weight: normal;" class="py-0 my-0">
              {{ value }}{{ stats[item][value] > 1 && value != "Montant gagné / Total" ? "s" : "" }}
            </h4>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </material-card>
</template>

<script>
import MaterialCard from "../../../../components/common/MaterialCard";

export default {
  name: "statsCompaign",
  components: { MaterialCard },
  data() {
    return {
      stats: {},
    };
  },
  mounted() {
    this.getStats();
  },
  methods: {
    getStats() {
      this.$axios("/api/compaign/" + this.$route.params.id + "/stats").then((resp) => {
        this.stats = resp.data;
      });
    },
  },
};
</script>

<style scoped></style>
