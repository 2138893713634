var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.$store.getters.isLoggedIn && _vm.$route.name === "CustomersExtension"
        ? [_c("router-view")]
        : [
            _vm.$store.state.settings
              ? [
                  _vm.isGranted("MAINTENANCE_ACCESS") ||
                  !_vm.$store.state.settings.maintenance
                    ? [
                        _vm.$route.name !== "Login"
                          ? _c(
                              "v-app",
                              {
                                style: {
                                  background:
                                    _vm.$vuetify.theme.themes.light.background,
                                },
                              },
                              [
                                _vm.$store.getters.isLoggedIn
                                  ? _c("Menu")
                                  : _vm._e(),
                                _c(
                                  "v-main",
                                  { staticStyle: { position: "relative" } },
                                  [
                                    _vm.preprodBanner
                                      ? _c("PreprodBanner")
                                      : _vm._e(),
                                    _c(
                                      "v-container",
                                      { attrs: { fluid: "" } },
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "fill-height" },
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c(
                                                  "transition",
                                                  { attrs: { name: "fade" } },
                                                  [_c("router-view")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    false
                                      ? _c("img", {
                                          staticStyle: {
                                            "max-width": "150px",
                                            position: "absolute",
                                            right: "5px",
                                            top: "-15px",
                                            "z-index": "0",
                                          },
                                          attrs: { src: "images/logo.png" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm.$store.state.settings &&
                            !_vm.$store.state.settings.maintenance
                          ? _c("v-app", [_c("router-view")], 1)
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.refreshModal
                    ? _c("SWRefresh", {
                        attrs: {
                          toggleRefreshModal: _vm.toggleRefreshModal,
                          refreshModal: _vm.refreshModal,
                        },
                      })
                    : _c(
                        "v-app",
                        { staticStyle: { "background-color": "#f9fafb" } },
                        [
                          _c(
                            "v-container",
                            {
                              attrs: {
                                fluid: "",
                                "fill-height": "fill-height",
                              },
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "align-center": "align-center",
                                    "justify-center": "justify-center",
                                  },
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "login-form text-xs-center",
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: {
                                            background: "transparent",
                                          },
                                          attrs: { elevation: "0" },
                                        },
                                        [
                                          _c("article", [
                                            _c(
                                              "svg",
                                              {
                                                staticStyle: {
                                                  width: "75px",
                                                  "margin-top": "1em",
                                                },
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  viewBox: "0 0 202.24 202.24",
                                                },
                                              },
                                              [
                                                _c("defs"),
                                                _c(
                                                  "g",
                                                  {
                                                    attrs: {
                                                      id: "Layer_2",
                                                      "data-name": "Layer 2",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "g",
                                                      {
                                                        attrs: {
                                                          id: "Capa_1",
                                                          "data-name": "Capa 1",
                                                        },
                                                      },
                                                      [
                                                        _c("path", {
                                                          staticClass: "cls-1",
                                                          attrs: {
                                                            d: "M101.12,0A101.12,101.12,0,1,0,202.24,101.12,101.12,101.12,0,0,0,101.12,0ZM159,148.76H43.28a11.57,11.57,0,0,1-10-17.34L91.09,31.16a11.57,11.57,0,0,1,20.06,0L169,131.43a11.57,11.57,0,0,1-10,17.34Z",
                                                          },
                                                        }),
                                                        _c("path", {
                                                          staticClass: "cls-1",
                                                          attrs: {
                                                            d: "M101.12,36.93h0L43.27,137.21H159L101.13,36.94Zm0,88.7a7.71,7.71,0,1,1,7.71-7.71A7.71,7.71,0,0,1,101.12,125.63Zm7.71-50.13a7.56,7.56,0,0,1-.11,1.3l-3.8,22.49a3.86,3.86,0,0,1-7.61,0l-3.8-22.49a8,8,0,0,1-.11-1.3,7.71,7.71,0,1,1,15.43,0Z",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "h1",
                                              {
                                                staticClass:
                                                  "grey--text text--darken-3 my-6",
                                              },
                                              [_vm._v("Maintenance")]
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Désolé pour le dérangement. Le GRC est actuellement en maintenance. Nous serons de retour sous peu!"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "— L'équipe Actuelburo"
                                                    ),
                                                  ]
                                                ),
                                                _vm.isGranted(
                                                  "MAINTENANCE_ACCESS"
                                                )
                                                  ? _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Accéder au GRC"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }