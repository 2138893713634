var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("bar-chart", {
    attrs: { chartData: _vm.chartData, chartOptions: _vm.chartOptions },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }