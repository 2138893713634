<template>
  <modal v-model="toggleModal">
    <template v-slot:header>
      <strong class="text-h5 mb-0 text-uppercase">Lier un parent</strong>
    </template>
    <v-col class="d-flex flex-column" cols="12">
      <v-autocomplete
        outlined
        :items="customersList"
        v-model="selectedCustomer"
        item-value="customer.id"
        item-text="entreprise.name"
        chips
        label="Clients"
        :search-input.sync="searchCustomers.name"
      ></v-autocomplete>
      <v-row v-if="customer?.customer?.parent?.id">
        <v-col cols="12" class="text-left">
          <strong class="mb-0 text-uppercase">Parent actuel : </strong>
          <span>{{ customer.customer.parent.entreprise.name }} </span>
          <span class="text-caption">({{ customer.customer.parent.entreprise.siret }})</span>
        </v-col>
      </v-row>
    </v-col>
    <template v-slot:actions>
      <v-btn color="secondary" text class="mr-2" @click="toggleModal = false"> Fermer </v-btn>
      <v-btn variant="flat" color="secondary" elevation="0" class="mr-2" @click="unlinkParent" :loading="loadingUnlink" v-if="customer?.customer?.parent?.id">
        Délier
      </v-btn>
      <v-btn :disabled="selectedCustomer === null" variant="flat" color="primary" elevation="0" class="mr-2" @click="linkParent" :loading="loading">
        Lier
      </v-btn>
    </template>
  </modal>
</template>

<script>
import Modal from "../../components/common/modal.vue";
import simpleDebounce from "simple-debounce-vue-mixin";

export default {
  name: "LinkParent",
  components: { Modal },
  mixins: [simpleDebounce],
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    customer: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      toggleModal: this.value,
      loading: false,
      loadingUnlink: false,
      selectedCustomer: null,
      customersList: [],
      searchCustomers: {
        name: "",
        isIndirect: null,
        isKey1: null,
        isKey2: null,
        isKey3: null,
        isKey4: null,
        type: null,
        siret: "",
        postalCode: "",
        city: "",
        sector: "",
        phone: "",
        ctNumAb: "",
        ape: "",
        sortedBy: null,
        sortedDesc: false,
        cityPostalCode: "",
      },
    };
  },
  methods: {
    linkParent() {
      this.loading = true;
      this.$axios
        .patch(`/api/customers/${this.customer.entreprise.id}/parent`, {
          parent: this.selectedCustomer,
        })
        .then((resp) => {
          this.loading = false;
          this.$emit("updateCustomer", resp.data);
          this.$simpleToast.success("Client", "Parent lié avec succès");
          this.$emit("reload-contact", true);
          this.toggleModal = false;
        });
    },
    unlinkParent() {
      this.loadingUnlink = true;
      this.$axios
        .patch(`/api/customers/${this.customer.entreprise.id}/parent`, {
          parent: null,
        })
        .then((resp) => {
          this.loadingUnlink = false;
          this.$emit("updateCustomer", resp.data);
          this.$simpleToast.success("Client", "Parent délié avec succès");
          this.$emit("reload-contact", true);
          this.toggleModal = false;
        });
    },
    getCustomers() {
      this.customersList = [];
      this.$axios("/api/customers", {
        params: {
          page: 1,
          search: this.searchCustomers,
          limit: 30,
          asUser: [],
          asType: [],
          userMode: "tous",
          typeMode: "tous",
          prospectOnly: true,
          clientOnly: true,
        },
      }).then((resp) => {
        this.maxItem = resp.data.max;
        this.customersList = resp.data.data;
      });
    },
    getCustomersDebounce() {
      this.simpleDebounce(() => {
        this.getCustomers();
      }, 500 /* optional debounce time: default is 300 */);
    },
  },
  mounted() {
    this.toggleModal = this.value;
    this.getCustomers();
  },
  watch: {
    value() {
      this.toggleModal = this.value;
    },
    toggleModal() {
      this.$emit("input", this.toggleModal);
    },
    "searchCustomers.name": "getCustomersDebounce",
  },
};
</script>
