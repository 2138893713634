var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    { staticClass: "mb-5 pb-7", attrs: { title: "Hebdomadaire" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _vm.curKey !== 0
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function ($event) {
                              _vm.curKey = _vm.curKey - 1
                              _vm.week = Object.keys(
                                _vm.challenge.classement.week
                              )[_vm.curKey]
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa-chevron-left fa-fw "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass:
                    "text-center week--title primary--text text--darken-1 text-uppercase font-italic",
                },
                [_vm._v("Semaine " + _vm._s(_vm.week) + " ")]
              ),
              _c(
                "v-col",
                { staticClass: "text-left" },
                [
                  _vm.curKey !==
                  Object.keys(_vm.challenge.classement.week).length - 1
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function ($event) {
                              _vm.curKey = _vm.curKey + 1
                              _vm.week = Object.keys(
                                _vm.challenge.classement.week
                              )[_vm.curKey]
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa-chevron-right fa-fw "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.week
        ? _c(
            "v-list",
            [
              _c("challenge-tables-header", { attrs: { hebdo: true } }),
              _vm._l(_vm.chall, function (item, key) {
                return _c(
                  "div",
                  { key: key },
                  [
                    _c(
                      "v-list-item",
                      {
                        style: {
                          borderLeft:
                            "solid 5px " +
                            (item.colorType ? item.colorType : "grey"),
                        },
                      },
                      [
                        _c("v-col", { attrs: { cols: "1" } }, [
                          _vm._v(_vm._s(key + 1)),
                        ]),
                        _c(
                          "v-list-item-avatar",
                          { attrs: { width: "40" } },
                          [
                            _c("UserAvatar", {
                              attrs: { userId: item.user, size: "40" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "py-0 pl-6 text-left d-none d-sm-flex",
                                    staticStyle: { "font-size": "0.9rem" },
                                    attrs: { cols: "3" },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0 text-right",
                                    attrs: { cols: "4", sm: "3" },
                                  },
                                  [
                                    item.user === _vm.$store.state.user.id ||
                                    _vm.isGranted("CHALLENGES_VIEW_AMOUNT")
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                Math.round(
                                                  item.ca
                                                ).toLocaleString("fr")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0 text-right",
                                    attrs: { cols: "4", sm: "3" },
                                  },
                                  [
                                    item.user === _vm.$store.state.user.id ||
                                    _vm.isGranted("CHALLENGES_VIEW_AMOUNT")
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                Math.round(
                                                  item.gain
                                                ).toLocaleString("fr")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0 text-right",
                                    attrs: { cols: "2" },
                                  },
                                  [_vm._v(" +" + _vm._s(item.points) + " ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-list-item-icon", { attrs: { width: "24" } }, [
                          item.has_bonus
                            ? _c("img", {
                                staticStyle: { margin: "0" },
                                attrs: {
                                  src: "/images/challenge/coin.svg",
                                  height: "24",
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c("v-list-item-icon", { attrs: { width: "24" } }, [
                          _vm.challenge.global[0].user === item.user
                            ? _c("img", {
                                attrs: {
                                  src: "/images/challenge/helmet--gold.svg",
                                  width: "24",
                                },
                              })
                            : _vm._e(),
                          _vm.challenge.global[1].user === item.user
                            ? _c("img", {
                                attrs: {
                                  src: "/images/challenge/helmet--silver.svg",
                                  width: "24",
                                },
                              })
                            : _vm._e(),
                          _vm.challenge.global[2].user === item.user
                            ? _c("img", {
                                attrs: {
                                  src: "/images/challenge/helmet--bronze.svg",
                                  width: "24",
                                },
                              })
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c("v-divider"),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }