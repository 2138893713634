var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    {
      staticClass: "mt-8",
      attrs: { title: "Contacts", icon: "fa-users", id: "contact" },
    },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-right",
                  attrs: {
                    offset: _vm.compaign.contactsType !== "filter" ? 2 : 1,
                    cols: 2,
                  },
                },
                [
                  _c("v-switch", {
                    attrs: { value: "all", label: "Tous" },
                    model: {
                      value: _vm.compaign.contactsType,
                      callback: function ($$v) {
                        _vm.$set(_vm.compaign, "contactsType", $$v)
                      },
                      expression: "compaign.contactsType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: 2 } },
                [
                  _c("v-switch", {
                    attrs: { value: "favorite", label: "Favoris" },
                    model: {
                      value: _vm.compaign.contactsType,
                      callback: function ($$v) {
                        _vm.$set(_vm.compaign, "contactsType", $$v)
                      },
                      expression: "compaign.contactsType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-switch", {
                    attrs: { value: "none", label: "Aucun" },
                    model: {
                      value: _vm.compaign.contactsType,
                      callback: function ($$v) {
                        _vm.$set(_vm.compaign, "contactsType", $$v)
                      },
                      expression: "compaign.contactsType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: _vm.compaign.contactsType !== "filter" ? 3 : 4,
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-switch", {
                            attrs: { value: "filter", label: "Filtrer" },
                            model: {
                              value: _vm.compaign.contactsType,
                              callback: function ($$v) {
                                _vm.$set(_vm.compaign, "contactsType", $$v)
                              },
                              expression: "compaign.contactsType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _vm.compaign.contactsType === "filter"
                            ? _c("v-select", {
                                attrs: {
                                  "menu-props": { maxHeight: "300" },
                                  items: _vm.jobs,
                                  label: "Filtrer par rôle",
                                  "item-text": "name",
                                  "item-value": "id",
                                  multiple: "",
                                },
                                model: {
                                  value: _vm.compaign.contactsFilter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.compaign,
                                      "contactsFilter",
                                      $$v
                                    )
                                  },
                                  expression: "compaign.contactsFilter",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expansion-panels",
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _vm._v(" Voir les contacts ciblés "),
                _c(
                  "span",
                  { staticClass: "ml-2" },
                  [
                    _vm.compaign.exclude.length > 0
                      ? _c("v-chip", { attrs: { label: "", small: "" } }, [
                          _vm._v(
                            _vm._s(_vm.compaign.exclude.length) +
                              " exclusion" +
                              _vm._s(_vm.compaign.exclude.length > 1 ? "s" : "")
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    staticStyle: { "z-index": "1000" },
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.contacts,
                      page: _vm.page,
                      "items-per-page": _vm.limitItem,
                      loading: _vm.isLoading,
                      "server-items-length": _vm.maxItem,
                      "footer-props": {
                        "items-per-page-options": [10, 20, 50, 100],
                      },
                    },
                    on: {
                      "update:page": _vm.setPage,
                      "click:row": _vm.addOrRemove,
                      "update:items-per-page": _vm.setItemMax,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.exclusion",
                        fn: function ({ item }) {
                          return [
                            _c("v-checkbox", {
                              attrs: { value: item.contact.id, disabled: "" },
                              model: {
                                value: _vm.compaign.exclude,
                                callback: function ($$v) {
                                  _vm.$set(_vm.compaign, "exclude", $$v)
                                },
                                expression: "compaign.exclude",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "item.contact.civilityId",
                        fn: function ({ item }) {
                          return [
                            item.contact.civilityId === 0
                              ? [_vm._v(" Monsieur ")]
                              : item.contact.civilityId === 1
                              ? [_vm._v(" Madame ")]
                              : item.contact.civilityId === 2
                              ? [_vm._v(" Mademoiselle ")]
                              : [_vm._v(" N/D ")],
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-container", { attrs: { fluid: "" } }, [
        _c("span", { staticClass: "text-overline" }, [
          _vm._v(
            _vm._s(_vm.maxItem - _vm.compaign.exclude.length) +
              " contact" +
              _vm._s(_vm.maxItem - _vm.compaign.exclude.length > 1 ? "s" : "") +
              " séléctionné" +
              _vm._s(_vm.maxItem - _vm.compaign.exclude.length > 1 ? "s" : "")
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }