<template>
  <v-list-item-icon @click="eventLink" style="cursor: pointer" small>
    <v-badge dot :content="eventsLate.events.length" color="#EF5350" overlap v-if="eventsLate && eventsLate.events && eventsLate.events.length > 0">
      <v-icon small color="#fff"> far fa-bell fa-fw </v-icon>
    </v-badge>
    <v-icon small color="#fff" v-else> far fa-bell-slash fa-fw </v-icon>
  </v-list-item-icon>
</template>

<script>
export default {
  name: "NotificationBell",
  data() {
    return {
      eventsLate: [],
      user: null,
    };
  },
  mounted() {
    this.user = this.$store.state.user;
    this.getEventsLate();
  },
  methods: {
    eventLink() {
      this.$router.push({ name: "EventsList" });
      const route = this.$router.resolve({ name: "Panneau de supervision d'opportunités" });
      window.open(route.href, "_blank");
    },
    getEventsLate() {
      this.$axios("/api/events/late").then((resp) => {
        this.eventsLate = {
          type: [],
          customer: [],
          info: [],
          users: [],
          events: [],
        };
        this.eventsLate.type = resp.data.type;
        this.eventsLate.customer = resp.data.customer;
        this.eventsLate.info = resp.data.info;
        this.eventsLate.users = resp.data.users;

        resp.data.events.forEach((item) => {
          if (this.eventsLate.type[item.eventtypeId] && this.eventsLate.type[item.eventtypeId].shortcut) this.eventsLate.events.push(item);
        });
      });
    },
  },
};
</script>

<style></style>
