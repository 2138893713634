var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MaterialCard",
    { staticClass: "mt-8", attrs: { title: _vm.label } },
    [
      _vm.stats
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "4" } },
                [
                  _c("h1", { staticClass: "primary--text py-0 my-0" }, [
                    _vm._v(_vm._s(_vm.stats.created)),
                  ]),
                  _c(
                    "h4",
                    {
                      staticClass: "py-0 my-0",
                      staticStyle: {
                        "font-style": "italic",
                        "font-weight": "normal",
                      },
                    },
                    [_vm._v("Opportunités créées")]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "4" } },
                [
                  _c("h1", { staticClass: "primary--text py-0 my-0" }, [
                    _vm._v(_vm._s(_vm.stats.amount) + " €"),
                  ]),
                  _c(
                    "h4",
                    {
                      staticClass: "py-0 my-0",
                      staticStyle: {
                        "font-style": "italic",
                        "font-weight": "normal",
                      },
                    },
                    [_vm._v("Prévisionnel")]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "4" } },
                [
                  _c("h1", { staticClass: "primary--text py-0 my-0" }, [
                    _c("span", { staticClass: "success--text" }, [
                      _vm._v(_vm._s(_vm.stats.win)),
                    ]),
                    _vm._v(" / "),
                    _c("span", { staticClass: "error--text" }, [
                      _vm._v(_vm._s(_vm.stats.loose)),
                    ]),
                  ]),
                  _c(
                    "h4",
                    {
                      staticClass: "py-0 my-0",
                      staticStyle: {
                        "font-style": "italic",
                        "font-weight": "normal",
                      },
                    },
                    [_vm._v("Opportunités fermées")]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }