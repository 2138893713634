<template>
  <v-container fluid style="margin-top: -30px">
    <v-row>
      <v-col cols="12" class="my-0 pa-0 mb-1" v-if="false">
        <v-btn color="secondary" small @click="$router.go(-1)">
          <v-icon class="" small> fa-arrow-left </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" class="my-0 px-0 py-0">
        <customerOpportunities v-model="opportunity"></customerOpportunities>
      </v-col>
      <v-col cols="12" class="py-0">
        <stepsOpportunity @refresh-all="getInfo()" v-model="opportunity"></stepsOpportunity>
      </v-col>
      <v-col cols="12" class="px-0 py-0">
        <formEditOpportunities v-model="opportunity"></formEditOpportunities>
      </v-col>
      <v-col cols="12" md="4" lg="3" class="px-0 py-0">
        <descriptionOpportunities v-if="opportunity && opportunity.opp" v-model="opportunity"></descriptionOpportunities>
        <compaignOpportunities v-if="opportunity && opportunity.opp" class="mt-8"></compaignOpportunities>
      </v-col>
      <v-col cols="12" md="5" lg="6" class="px-0 py-0">
        <Event v-model="opportunity" fromOpportunities></Event>
        <emailList v-model="opportunity" @refresh-all="getInfo()"></emailList>
      </v-col>
      <v-col cols="12" md="3" class="px-0 py-0">
        <concurrentOpportunities @refresh-all="getInfo()" v-model="opportunity"></concurrentOpportunities>
        <CommentOpportunities v-model="opportunity"></CommentOpportunities>
      </v-col>
      <v-col cols="12" md="3" class="px-0 py-0"> </v-col>
      <v-col cols="12" md="6" class="px-0 py-0"> </v-col>
      <v-col cols="12" md="3" class="px-0 py-0"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StepsOpportunity from "./stepsOpportunities";
import CustomerOpportunities from "./customerOpportunities";
import FormEditOpportunities from "./formEditOpportunities";
import CommentOpportunities from "./commentOpportunities";
import ConcurrentOpportunities from "./concurrentOpportunity";
import DescriptionOpportunities from "./descriptionOpportunities";
import CompaignOpportunities from "./compaignOpportunities";
import EmailList from "../../components/common/emailList";
import Event from "../../components/common/Event.vue";
export default {
  name: "singleOpportunity",
  components: {
    CompaignOpportunities,
    EmailList,
    DescriptionOpportunities,
    ConcurrentOpportunities,
    CommentOpportunities,
    FormEditOpportunities,
    CustomerOpportunities,
    StepsOpportunity,
    Event,
  },
  data() {
    return {
      opportunity: null,
      isReady: false,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$axios.get("/api/opportunities/" + this.$route.params.id).then((result) => {
        this.opportunity = result.data;
        this.isReady = true;
      });
    },
  },
  watch: {
    isReady() {},
    "$route.params.id"() {
      this.getInfo();
    },
  },
};
</script>

<style scoped></style>
