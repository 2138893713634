var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "py-0" },
    [
      _vm.challenge && _vm.challenge.global
        ? [
            _c("Landscape"),
            _c(
              "v-col",
              { staticClass: "py-0", attrs: { cols: "12" } },
              [
                _c("title-challenge", {
                  on: { prevChall: _vm.prevChall, nextChall: _vm.nextChall },
                  model: {
                    value: _vm.challenge,
                    callback: function ($$v) {
                      _vm.challenge = $$v
                    },
                    expression: "challenge",
                  },
                }),
              ],
              1
            ),
            _vm.challenge.global.length > 0
              ? [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 mt-10",
                      attrs: { cols: "12", lg: "6", xl: "4" },
                    },
                    [
                      _c("hebdo-challenge", {
                        model: {
                          value: _vm.challenge,
                          callback: function ($$v) {
                            _vm.challenge = $$v
                          },
                          expression: "challenge",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 mt-10",
                      attrs: { cols: "12", lg: "6", xl: "4" },
                    },
                    [
                      _c("season-challenge", {
                        model: {
                          value: _vm.challenge,
                          callback: function ($$v) {
                            _vm.challenge = $$v
                          },
                          expression: "challenge",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 ma-0",
                      attrs: { cols: "12", xl: "4" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-0 ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 mt-10",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("bonus-challenge", {
                                model: {
                                  value: _vm.challenge,
                                  callback: function ($$v) {
                                    _vm.challenge = $$v
                                  },
                                  expression: "challenge",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 mt-10",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("ecurie-challenge", {
                                model: {
                                  value: _vm.challenge,
                                  callback: function ($$v) {
                                    _vm.challenge = $$v
                                  },
                                  expression: "challenge",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 mt-10",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("legende-challenge", {
                                model: {
                                  value: _vm.challenge,
                                  callback: function ($$v) {
                                    _vm.challenge = $$v
                                  },
                                  expression: "challenge",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center mt-16 v-card-title",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("v-icon", { attrs: { color: "red", size: "100" } }, [
                        _vm._v("mdi-database-alert"),
                      ]),
                      _c(
                        "v-card-title",
                        { staticClass: "mt-2 justify-center primary--text" },
                        [_vm._v("Aucune donnée pour ce challenge")]
                      ),
                    ],
                    1
                  ),
                ],
          ]
        : [
            _c(
              "v-col",
              { staticClass: "text-center mt-16", attrs: { cols: "12" } },
              [_c("LoaderActuelburo")],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }