<template>
  <material-card title="Écurie">
    <v-list>
      <challengeTablesHeader :ecurie="true"></challengeTablesHeader>
      <div v-for="(item, i) in ecurie" :key="i">
        <v-list-item>
          <v-col cols="1">{{ i + 1 }}</v-col>
          <v-list-item-content>
            <v-row>
              <v-col cols="6" class="py-0">
                {{ item.name }}
              </v-col>
              <v-col cols="2" class="py-0 text-right">
                {{ Math.round(item.ca).toLocaleString("fr") }}
              </v-col>
              <v-col cols="2" class="py-0 text-right">
                {{ Math.round(item.gain).toLocaleString("fr") }}
              </v-col>
              <v-col cols="2" class="py-0 text-right"> +{{ Math.round(item.points).toLocaleString("fr") }} </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </v-list>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
import challengeTablesHeader from "./challengeTablesHeader.vue";

export default {
  name: "ecurieChallenge",
  components: { MaterialCard, challengeTablesHeader },
  props: ["value"],
  data() {
    return {
      challenge: this.value,
      ecurie: [],
      types: [],
    };
  },
  mounted() {
    this.challenge = this.value;
    this.ecurie = [];
    Object.keys(this.challenge.classement.ecurie).forEach((item) => {
      let tmp = {
        name: item,
        ca: this.challenge.classement.ecurie[item].ca,
        points: this.challenge.classement.ecurie[item].points,
        gain: this.challenge.classement.ecurie[item].gain,
        color: this.challenge.classement.ecurie[item].color,
      };
      this.ecurie.push(tmp);
    });

    this.ecurie.sort((a, b) => {
      if (a["points"] === b["points"] && a["nb"] && b["nb"]) return a["nb"] > b["nb"] ? -1 : 1;
      else if (a["points"] === b["points"]) return a["gain"] > b["gain"] ? -1 : 1;
      else return a["points"] > b["points"] ? -1 : 1;
    });
  },
  methods: {},
  watch: {
    value() {
      this.challenge = this.value;
      this.ecurie = [];
      Object.keys(this.challenge.classement.ecurie).forEach((item) => {
        let tmp = {
          name: item,
          ca: this.challenge.classement.ecurie[item].ca,
          points: this.challenge.classement.ecurie[item].points,
          gain: this.challenge.classement.ecurie[item].gain,
          color: this.challenge.classement.ecurie[item].color,
        };
        this.ecurie.push(tmp);
      });
    },
  },
};
</script>

<style scoped></style>
