<template>
  <div style="margin-top: -25px">
    <landscape />
    <modal v-model="modalSettings" max-width="1024">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Critères</strong>
      </template>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="4" class="text-right">
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedStart"
                  label="Date de début"
                  persistent-hint
                  prepend-icon="fa-calendar"
                  v-bind="attrs"
                  @blur="dateStart = parseDate(dateFormattedStart)"
                  v-on="on"
                  outlined
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateStart" @input="menu1 = false" outlined hide-details></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" class="text-left">
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedEnd"
                  label="Date de fin"
                  persistent-hint
                  prepend-icon="fa-calendar"
                  v-bind="attrs"
                  @blur="dateEnd = parseDate(dateFormattedEnd)"
                  v-on="on"
                  outlined
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateEnd" @input="menu2 = false" outlined></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" class="text-left">
            <v-select
              outlined
              hide-details
              :menu-props="{ maxHeight: '300' }"
              label="Service"
              :items="services"
              v-model="service"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:actions>
        <v-btn color="primary" variant="flat" elevation="0" depressed class="rounded-1 px-3" small @click="getStats()">Générer</v-btn>

        <v-btn color="secondary" text class="mr-4" @click="modalSettings = false"> Fermer </v-btn>
      </template>
    </modal>

    <v-card elevation="1" :loading="loading">
      <v-toolbar color="primary" elevation="0" dark>
        <v-row class="p-0 m-0 text-left">
          <v-col class="p-0 m-0 text-left">
            <strong class="mb-0 text-uppercase">Statistiques relation client</strong>
            <br /><small class="mt-0 grey--text text--lighten-4">Du {{ dateFormattedStart }} au {{ dateFormattedEnd }}</small>

            <div
              style="
                position: absolute;
                top: 0;
                left: 50%;
                background: #78909c;
                padding: 5px;
                translate: -50% 0;

                border-radius: 0 0 5px 5px;
              "
            >
              <v-btn small text @click="modalSettings = true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-filter </v-icon>
                  </template>
                  <span>Critères</span>
                </v-tooltip>
              </v-btn>

              <v-menu offset-y style="z-index: 1111111">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text small v-bind="attrs" v-on="on"> <v-icon small> fa-ellipsis-v fa-fw </v-icon></v-btn>
                </template>
                <v-list style="z-index: 1111111">
                  <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICS')" @click="$router.push({ name: 'Statistiques Personnelles' })">
                    <v-list-item-title>Mes statistiques</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('STATS_COM')" @click="$router.push({ name: 'Statistiques Commerciaux' })">
                    <v-list-item-title>Statistiques commerciaux</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICSAGENCY')" @click="$router.push({ name: 'Statistiques Agence' })">
                    <v-list-item-title>Statistiques de mon agence</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICSPERS')" @click="$router.push({ name: 'Statistiques BU' })">
                    <v-list-item-title>Statistiques de mon service</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('EVENTS_EVENTSKEY')" @click="$router.push({ name: 'Cross-Selling' })">
                    <v-list-item-title>Cross-Selling</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('SECTOR_STATISTICS')" @click="$router.push({ name: 'Affectations' })">
                    <v-list-item-title>Affectations</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-toolbar>
      <ve-table
        :columns="columns"
        border-y
        :table-data="tableData"
        :row-style-option="rowStyleOption"
        :cell-style-option="cellStyleOption"
        :sort-option="sortOption"
        max-height="calc(100vh - 155px)"
        setHighlightRow
        :stripe="true"
      ></ve-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import dayjs from "dayjs";
import Tools from "../../mixins/Tools";
import Modal from "@/components/common/modal.vue";
import Landscape from "../../components/common/Landscape.vue";
import UserAvatar from "../../components/user/UserAvatar.vue";
import PourcentageCell from "./PourcentageCell.vue";

export default {
  name: "statisticsRelations",
  components: { Modal, Landscape, UserAvatar, PourcentageCell },
  mixins: [Tools],
  data() {
    return {
      loading: false,
      services: [],
      tableData: [],
      service: null,
      modalSettings: false,
      columns: [],
      dateStart: this.parseDate(dayjs().subtract(1, "month").format("DD/MM/YYYY")),
      dateFormattedStart: dayjs().subtract(1, "month").format("DD/MM/YYYY"),
      menu1: false,
      menu2: false,
      dateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      dateFormattedEnd: this.formatDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)),
      cellStyleOption: {},
      rowStyleOption: {
        stripe: true,
      },
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
    };
  },
  mounted() {
    this.setColumnsBaseData();
    this.getServices();
    this.setColumnsCustomData();
    this.setStyleOption();
  },
  methods: {
    renderBodyCellPercentage({ row, column }) {
      const value = parseInt(row[column.field]);
      if (column.field.includes("TauxDeRDVAnnuel") || column.field.includes("TauxDeRencontreAnnuel")) {
        return <PourcentageCell cellData={value} />;
      }
      return value;
    },
    getStats() {
      this.$axios("/api/stats/relations", { params: { start: this.dateStart, end: this.dateEnd, service: this.service } }).then((resp) => {
        this.modalSettings = false;
        let tmpArray = [];
        resp.data.forEach((commercial) => {
          let tmpCommercial = {};
          Object.keys(commercial).forEach((key) => {
            if (typeof commercial[key] === "object") {
              let tmpChild = {};
              Object.keys(commercial[key]).forEach((childKey) => {
                tmpChild[`${key}-${childKey}`] = commercial[key][childKey];
              });
              tmpCommercial = { ...tmpCommercial, ...tmpChild };
            } else {
              tmpCommercial[key] = commercial[key];
            }
          });
          tmpArray.push(tmpCommercial);
        });
        this.tableData = tmpArray;
      });
    },
    getServices() {
      this.loading = "primary darken-2";
      this.$axios("/api/services").then((resp) => {
        resp.data.forEach((item) => {
          if (item.parentId !== null) {
            this.services.push(item);
          }
        });
        this.service = this.$store.state.user["service_id"];
        this.getStats();
        this.loading = false;
      });
    },
    setColumnsBaseData() {
      this.columns = [
        {
          title: "Commerciaux",
          field: "commercial",
          key: "commercial",
          align: "left",
          fixed: "left",
          sortBy: "asc",
          height: 40,
          width: 150,
          renderBodyCell: ({ row }) => {
            const text = row["firstname"] + " " + row["lastname"][0];
            return <UserAvatar userId={row.id} fullname={text} />;
          },
        },
        {
          field: "NombreDeComptes",
          title: "Nombre de comptes",
          key: "NombreDeComptes",
          align: "center",
          borderY: true,
        },
        {
          field: "NombreDeComptesRencontres",
          title: "Nombre de comptes rencontrés",
          key: "NombreDeComptesRencontres",
          align: "center",
          borderY: true,
        },
        {
          field: "NombreDeComptesRencontresRDV",
          title: "Nombre de rencontre (RDV et Visio)",
          key: "NombreDeComptesRencontresRDV",
          align: "center",
          sortBy: "",
          borderY: true,
        },
        {
          field: "TauxDeRDVAnnuel",
          title: "Taux de RDV annuel (%)",
          key: "TauxDeRDVAnnuel",
          align: "center",
          sortBy: "",
          borderY: true,
        },
        {
          field: "TauxDeRencontreAnnuel",
          title: "Taux de rencontre annuel (%)",
          key: "TauxDeRencontreAnnuel",
          align: "center",
          sortBy: "",
          borderY: true,
        },
      ];
    },
    setColumnsCustomData() {
      this.columns = this.columns.map((col) => {
        if (col.field === "commercial") {
          return col;
        } else {
          return {
            ...col,
            children: [
              {
                field: `${col.field}-Prospects`,
                title: "100% prospects",
                key: `${col.field}Prospects`,
                align: "center",
                sortBy: "",
                renderBodyCell: ({ row, column }) => this.renderBodyCellPercentage({ row, column }),
              },
              {
                field: `${col.field}-Clients`,
                title: "Clients",
                key: `${col.field}Clients`,
                align: "center",
                sortBy: "",
                renderBodyCell: ({ row, column }) => this.renderBodyCellPercentage({ row, column }),
              },
              {
                field: `${col.field}-ClientsClé`,
                title: "Clients clé",
                key: `${col.field}ClientsClé`,
                align: "center",
                sortBy: "",
                renderBodyCell: ({ row, column }) => this.renderBodyCellPercentage({ row, column }),
              },
              {
                field: `${col.field}-ClientsAutres`,
                title: "Autres clients",
                key: `${col.field}ClientsAutres`,
                align: "center",
                sortBy: "",
                renderBodyCell: ({ row, column }) => this.renderBodyCellPercentage({ row, column }),
              },
            ],
          };
        }
      });
    },
    setStyleOption() {
      this.cellStyleOption.bodyCellClass = ({ column }) => {
        let className = "";
        if (column.key.includes("TauxDeRDVAnnuel") || column.key.includes("TauxDeRencontreAnnuel")) {
          className += "table-body-cell-pourcentage";
        } else {
          className += "table-body-cell";
        }
        return className;
      };
    },
    sortChange(params) {
      let sortField = "";

      for (const [key, value] of Object.entries(params)) {
        if (value) {
          sortField = key;
          break;
        }
      }
      if (params[sortField]) {
        this.tableData.sort((a, b) => {
          if (isNaN(a[sortField])) {
            if (params[sortField] === "asc") {
              return a[sortField].localeCompare(b[sortField]);
            } else if (params[sortField] === "desc") {
              return b[sortField].localeCompare(a[sortField]);
            }
            return a[sortField].localeCompare(b[sortField]);
          } else if (params[sortField]) {
            if (params[sortField] === "asc") {
              return a[sortField] - b[sortField];
            } else if (params[sortField] === "desc") {
              return b[sortField] - a[sortField];
            }

            return 0;
          }
        });
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    dateStart() {
      this.dateFormattedStart = this.formatDate(this.dateStart);
    },
    dateEnd() {
      this.dateFormattedEnd = this.formatDate(this.dateEnd);
    },
  },
};
</script>

<style lang="scss">
.ve-table .ve-table-container table.ve-table-content thead.ve-table-header tr.ve-table-header-tr th.ve-table-header-th,
.ve-table-container,
.ve-table-body-td,
.ve-table.ve-table-border-around {
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: #322c2c;
  font-weight: bold;
}

.ve-table td,
.ve-table th {
  font-size: 0.875em !important;
}

.ve-table .ve-table-container table.ve-table-content thead.ve-table-header .ve-table-header-tr .ve-table-header-th .ve-table-sort .ve-table-sort-icon.active {
  color: #607d8b;
}

.bg-warning {
  color: rgba(246, 120, 31, 0.25) !important;
}

.bg-error {
  color: rgba(246, 48, 41, 0.25) !important;
}
.table-body-cell-pourcentage {
  padding: 0 !important;
}
.table-body-cell {
  font-weight: 500;
}
</style>
