var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MaterialCard",
    {
      staticClass: "mt-10",
      attrs: { icon: "far fa-envelope", title: "E-mails" },
    },
    [
      _c(
        "modal",
        {
          attrs: { "max-width": "calc(100% - 122px)" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Boîte mail"),
                  ]),
                  _c("br"),
                  _c("small", { staticClass: "mt-0" }, [
                    _vm._v("Filtrez les dossiers..."),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.linkModal,
            callback: function ($$v) {
              _vm.linkModal = $$v
            },
            expression: "linkModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "text-left" },
            [
              _vm.linkModal
                ? _c("Inbox", {
                    on: {
                      close: function ($event) {
                        _vm.getEmails()
                        _vm.linkModal = false
                      },
                    },
                    model: {
                      value: _vm.item,
                      callback: function ($$v) {
                        _vm.item = $$v
                      },
                      expression: "item",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "listbox--actions-add" },
        [
          _c(
            "v-btn",
            {
              staticClass: "linkEmailBtn",
              attrs: { small: "", color: "primary", fab: "" },
              on: {
                click: function ($event) {
                  _vm.linkModal = !_vm.linkModal
                },
              },
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("fa-fw fa-link")])],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { "max-width": "calc(100% - 122px)" },
          scopedSlots: _vm._u(
            [
              _vm.selectedMessage
                ? {
                    key: "header",
                    fn: function () {
                      return [
                        _c("strong", { staticClass: "text-h5 mb-0" }, [
                          _vm._v(_vm._s(_vm.selectedMessage.sender) + " "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "0.9rem",
                                "font-style": "italic",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.selectedMessage.senderEmail) + " "
                              ),
                            ]
                          ),
                        ]),
                        _c("br"),
                        _c("small", { staticClass: "mt-0" }, [
                          _vm._v(_vm._s(_vm.selectedMessage.name)),
                        ]),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
          model: {
            value: _vm.seeAll,
            callback: function ($$v) {
              _vm.seeAll = $$v
            },
            expression: "seeAll",
          },
        },
        [
          _vm.selectedMessage
            ? _c(
                "div",
                { staticClass: "text-left messagesModal" },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-2", attrs: { elevation: "0" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-3", attrs: { cols: "12" } },
                            [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.selectedMessage.body),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        [
          _c("v-text-field", {
            attrs: { label: "Rechercher un e-mail" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _vm.emails.length > 0
        ? _c(
            "div",
            { staticStyle: { "max-height": "500px", "overflow-y": "auto" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-timeline",
                    { attrs: { "align-top": "", dense: "" } },
                    _vm._l(_vm.emails, function (item, i) {
                      return _c(
                        "v-timeline-item",
                        {
                          key: i,
                          staticClass: "my-0 mb-4 pl-0",
                          attrs: { small: "", color: "primary" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c("UserAvatar", {
                                      attrs: { userId: item.user, size: "40" },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "white",
                                        staticStyle: {
                                          position: "absolute",
                                          top: "55px",
                                          "text-align": "center",
                                          "font-size": "0.8rem",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm
                                                .dayjs(
                                                  item.linkedAt,
                                                  "YYYY-MM-DD HH:mm:ss"
                                                )
                                                .format("DD/MM/YYYY")
                                            )
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .dayjs(
                                                item.linkedAt,
                                                "YYYY-MM-DD HH:mm:ss"
                                              )
                                              .format("HH:mm")
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "35px",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "white",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.returnIcon(
                                                                  item
                                                                ).icon
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.returnIcon(item).text
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-title",
                                  { staticClass: "ma-0 pt-0" },
                                  [
                                    _c(
                                      "strong",
                                      {
                                        staticStyle: { "font-size": "0.9rem" },
                                      },
                                      [
                                        _vm._v(" " + _vm._s(item.name) + " "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "font-italic mx-3",
                                            staticStyle: {
                                              "font-size": "0.8rem",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.sender) +
                                                " - " +
                                                _vm._s(item.senderEmail) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("v-card-text", [
                                  _c("div", { staticClass: "text-caption" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.preview
                                            ? item.preview
                                            : "Pas de commentaire"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "-15px",
                                      right: "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-1",
                                        attrs: {
                                          color: "primary",
                                          outlined: "",
                                          fab: "",
                                          elevation: "0",
                                          "x-small": "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.selectedMessage = item
                                            _vm.seeAll = true
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "primary",
                                              "x-small": "",
                                            },
                                          },
                                          [_vm._v(" far fa-fw fa-eye")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-1 deleteEmailBtn",
                                        attrs: {
                                          "x-small": "",
                                          color: "error",
                                          outlined: "",
                                          fab: "",
                                          elevation: "0",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteEmail(item.id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "error",
                                              "x-small": "",
                                            },
                                          },
                                          [_vm._v("fa-fw fa-trash-alt")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "font-weight-light grey--text mb-5 text-center" },
            [_vm._v("Aucun e-mail lié")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }