<template>
  <div style="margin-top: -25px">
    <landscape />
    <v-card elevation="1" class="mb-3">
      <v-toolbar color="primary" elevation="0" dark>
        <v-card-title>
          Affectations
          <div
            style="
              position: absolute;
              top: 0;
              left: 50%;
              background: #78909c;
              padding: 5px;
              translate: -50% 0;

              border-radius: 0 0 5px 5px;
            "
          >
            <v-menu offset-y style="z-index: 1111111">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text small v-bind="attrs" v-on="on">
                  <v-icon small> fa-ellipsis-v fa-fw </v-icon>
                </v-btn>
              </template>
              <v-list style="z-index: 1111111">
                <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICS')" @click="$router.push({ name: 'Statistiques Personnelles' })">
                  <v-list-item-title>Mes statistiques</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isGranted('STATS_COM')" @click="$router.push({ name: 'Statistiques Commerciaux' })">
                  <v-list-item-title>Statistiques commerciaux</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICSPERS')" @click="$router.push({ name: 'Statistiques BU' })">
                  <v-list-item-title>Statistiques de mon service</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isGranted('EVENTS_EVENTSKEY')" @click="$router.push({ name: 'Relations' })">
                  <v-list-item-title>Relation clients</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isGranted('EVENTS_EVENTSKEY')" @click="$router.push({ name: 'Cross-Selling' })">
                  <v-list-item-title>Cross-Selling</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-card-title>
      </v-toolbar>
    </v-card>

    <v-row class="pt-6">
      <v-col cols="12" lg="5">
        <material-card icon="fa-chart-line" title="Affectation">
          <AffectsChart :labels="labels" :datasets="datasets"></AffectsChart>
        </material-card>
      </v-col>
      <v-col cols="12" lg="7">
        <material-card icon="fa-table" title="Tableau">
          <v-data-table
            :headers="headers"
            :items="users"
            hide-default-footer
            :loading="loading"
            :items-per-page="100"
            class="elevation-1"
            :sort-by="'total'"
            :sort-desc="true"
          >
            <template v-for="header in headers" v-slot:[`header.${header.value}`]>
              <v-tooltip v-if="header.legend" bottom :key="header.value" color="primary">
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <div class="text-center">{{ header.legend }}</div>
              </v-tooltip>
              <span v-else :key="header.value">{{ header.text }}</span>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr @click="loadAffect(item)" v-for="item in items" :key="item.id">
                  <td class="text-center">
                    <v-tooltip left :color="item.color">
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="25" :color="item.color" class="mx-0">
                          <v-icon x-small v-bind="attrs" v-on="on">{{ item.icon }} fa-fw</v-icon>
                        </v-avatar>
                      </template>
                      <span class="small">{{ item.name }}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <span class="font-weight-bold"> {{ item.lastname }}</span> <span class="text-capitalize">{{ item.firstname.toLowerCase() }}</span>
                  </td>
                  <td class="text-end">{{ item.contracts }}</td>
                  <td class="text-end">{{ numStr(item.prospect) }}</td>
                  <td class="text-end">{{ numStr(item.total) }}</td>
                  <td class="text-end marge-cell">{{ item.subscriptions_gain_metier ? item.subscriptions_gain_metier : "" }}</td>
                  <td class="text-end marge-cell-metier">{{ item.subscriptions_gain ? item.subscriptions_gain : "" }}</td>
                  <td class="text-end marge-cell">{{ item.subscriptions_gain_metier_pot ? item.subscriptions_gain_metier_pot : "" }}</td>
                  <td class="text-end marge-cell-metier">
                    {{ item.subscriptions_gain_metier_not_affected ? item.subscriptions_gain_metier_not_affected : "" }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </material-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
import Tools from "../../mixins/Tools";
import Landscape from "../../components/common/Landscape.vue";
import AffectsChart from "./AffectsChart.vue";

export default {
  name: "Affects",
  mixins: [Tools],
  components: {
    MaterialCard,
    Landscape,
    AffectsChart,
  },
  data() {
    return {
      loading: true,
      headers: [
        { value: "name", text: "#", class: "text-uppercase primary--text ", align: "center" },
        { value: "lastname", text: "Commercial", class: "text-uppercase primary--text " },
        { value: "contracts", text: "Clients", align: "end", class: "text-uppercase primary--text " },
        { value: "prospect", text: "Prospects", align: "end", class: "text-uppercase primary--text " },
        { value: "total", text: "Total", align: "end", class: "text-uppercase primary--text " },
        {
          value: "subscriptions_gain_metier",
          text: "Marge abos métier",
          align: "end",
          class: "text-uppercase primary--text ",
          legend: "Ensemble des contrats appartenant au commercial pour son métier",
        },
        {
          value: "subscriptions_gain",
          text: "Marge abos",
          align: "end",
          class: "text-uppercase primary--text ",
          legend: "Ensemble des contrats appartenant au commercial quel que soit le métier",
        },
        {
          value: "subscriptions_gain_metier_pot",
          text: "Marge pot. métier",
          align: "end",
          class: "text-uppercase primary--text ",
          legend: "Ensemble des contrats appartenant au commercial quel que soit le métier",
        },
        {
          value: "subscriptions_gain_metier_not_affected",
          text: "Marge Métier hors secteur",
          align: "end",
          class: "text-uppercase primary--text ",
          legend: "Marge des abonnements sur les secteurs non affectés",
        },
      ],
      users: [],
      labels: [],
      datasets: [
        {
          label: "Client",
          backgroundColor: "#434348",
          data: [],
        },
        {
          label: "Prospect",
          backgroundColor: "#7cb5ec",
          data: [],
        },
      ],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    loadAffect(item) {
      this.$router.push({ name: "Affectation", params: { id: item.id } });
    },
    getUsers() {
      this.$axios.get("/api/stats/affects").then((resp) => {
        this.loading = false;
        this.users = resp.data;
        this.users.forEach((item, i) => {
          this.users[i].total = parseInt(item.contracts) + parseInt(item.prospect);
        });
        this.users.sort((a, b) => (a.total < b.total ? 1 : -1));
        this.users.forEach((item) => {
          this.labels.push(item.lastname + " " + item.firstname);
          this.datasets[0].data.push(parseInt(item.contracts));
          this.datasets[1].data.push(parseInt(item.prospect));
        });
      });
    },
    numStr(nbr) {
      var nombre = "" + nbr;
      var retour = "";
      var count = 0;
      for (var i = nombre.length - 1; i >= 0; i--) {
        if (count != 0 && count % 3 == 0) retour = nombre[i] + " " + retour;
        else retour = nombre[i] + retour;
        count++;
      }
      let tmp = retour.split(".");

      return tmp[0];
    },
  },
};
</script>

<style scoped>
.marge-cell {
  background-color: rgba(0, 79, 159, 0.05) !important;
}

.marge-cell-metier {
  background-color: rgba(0, 79, 159, 0.08) !important;
}
</style>
