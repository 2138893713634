var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c("v-layout", { attrs: { "align-center": "", "justify-center": "" } }, [
        _c(
          "div",
          { staticClass: "d-flex justify-center align-center flex-column" },
          [
            _c("v-img", {
              staticClass: "rounded-lg mb-10",
              attrs: {
                src: require("../../assets/logoActuelburo.png"),
                alt: "Page introuvable",
              },
            }),
            _c("v-img", {
              staticClass: "rounded-lg mb-16",
              attrs: {
                src: require("../../assets/no-results.png"),
                alt: "Page introuvable",
                height: "400",
                width: "400",
              },
            }),
            _c("h2", { staticClass: "mb-16 secondary--text display-1" }, [
              _vm._v("Oops, Cette page n'existe pas..."),
            ]),
            _c(
              "v-btn",
              {
                attrs: {
                  color: "primary",
                  dark: "",
                  large: "",
                  to: { path: "/" },
                },
              },
              [_vm._v("Retour à l'accueil")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }