var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-2",
      on: {
        click: function ($event) {
          return _vm.linkContactConfirm(_vm.item.contact.id)
        },
      },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { staticClass: "text-left" },
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-1", attrs: { "x-small": "" } },
                    [_vm._v("fa-user")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.item.contact.firstname +
                          " " +
                          _vm.item.contact.lastname
                      )
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("v-icon", { attrs: { "x-smal": "" } }, [
                    _vm._v("fas fa-graduate"),
                  ]),
                  _vm._l(_vm.item.customer, function (custo, keyc) {
                    return _c(
                      "v-chip",
                      { key: keyc, attrs: { label: "", "x-small": "" } },
                      [_vm._v(_vm._s(custo.name))]
                    )
                  }),
                ],
                2
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-1", attrs: { "x-small": "" } },
                    [_vm._v("fa-envelope")]
                  ),
                  _vm._v(" " + _vm._s(_vm.item.contact.email)),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-1", attrs: { "x-small": "" } },
                    [_vm._v("fa-phone")]
                  ),
                  _vm._v(" " + _vm._s(_vm.item.contact.phone)),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { "x-small": "" } },
                    [_vm._v("fa-mobile")]
                  ),
                  _vm._v(" " + _vm._s(_vm.item.contact.phone2)),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }