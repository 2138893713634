<template>
  <material-card title="Legende">
    <v-container fluid class="pb-10">
      <v-row>
        <v-col cols="6" class="py-0 text-center">
          <img src="/images/challenge/bag.svg" height="48" alt="" />
          <div>Plus grosse marge de la saison en une commande</div>
        </v-col>
        <v-col cols="6" class="py-0 text-center">
          <img src="/images/challenge/coin.svg" height="48" alt="" />
          <div>Plus grosse marge de la semaine en une commande</div>
        </v-col>

        <div v-for="(item, i) in types" :key="i">
          <v-col v-if="item.active" cols="4" class="py-0 text-center">
            <v-icon>{{ returnInfo(item.name).icon }}</v-icon>
            <div>Bonus {{ item.name }}</div>
          </v-col>
        </div>
      </v-row>
    </v-container>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";

export default {
  name: "legendeChallenge",
  components: { MaterialCard },
  data() {
    return {
      types: [],
      typesAll: [],
    };
  },
  mounted() {
    this.getTypes();
    this.getTypesAll();
  },
  methods: {
    returnInfo(name) {
      let ret = {};
      if (this.typesAll.length > 0) {
        this.typesAll.forEach((item) => {
          if (item.name === name) {
            ret = item;
          }
        });
      }
      return ret;
    },
    getTypes() {
      this.$axios("/api/settings").then((resp) => {
        let tmp = [];
        Object.keys(resp.data.bonus).forEach((item) => {
          tmp.push(resp.data.bonus[item]);
        });
        this.types = tmp;
      });
    },
    getTypesAll() {
      this.$axios("/api/types/active").then((resp) => {
        this.typesAll = resp.data.types;
      });
    },
  },
};
</script>

<style scoped></style>
