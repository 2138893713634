var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "-25px" } },
    [
      _c("landscape"),
      _c(
        "modal",
        {
          attrs: { "max-width": "1024" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Critères"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "rounded-1 px-3",
                      attrs: {
                        color: "primary",
                        variant: "flat",
                        elevation: "0",
                        depressed: "",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getStats()
                        },
                      },
                    },
                    [_vm._v("Générer")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.modalSettings = false
                        },
                      },
                    },
                    [_vm._v(" Fermer ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.modalSettings,
            callback: function ($$v) {
              _vm.modalSettings = $$v
            },
            expression: "modalSettings",
          },
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", md: "4" },
                    },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menu1",
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "max-width": "290px",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            label: "Date de début",
                                            "persistent-hint": "",
                                            "prepend-icon": "fa-calendar",
                                            outlined: "",
                                            "hide-details": "",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              _vm.dateStart = _vm.parseDate(
                                                _vm.dateFormattedStart
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.dateFormattedStart,
                                            callback: function ($$v) {
                                              _vm.dateFormattedStart = $$v
                                            },
                                            expression: "dateFormattedStart",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.menu1,
                            callback: function ($$v) {
                              _vm.menu1 = $$v
                            },
                            expression: "menu1",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { outlined: "", "hide-details": "" },
                            on: {
                              input: function ($event) {
                                _vm.menu1 = false
                              },
                            },
                            model: {
                              value: _vm.dateStart,
                              callback: function ($$v) {
                                _vm.dateStart = $$v
                              },
                              expression: "dateStart",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-left",
                      attrs: { cols: "12", md: "4" },
                    },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menu2",
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "max-width": "290px",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            label: "Date de fin",
                                            "persistent-hint": "",
                                            "prepend-icon": "fa-calendar",
                                            outlined: "",
                                            "hide-details": "",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              _vm.dateEnd = _vm.parseDate(
                                                _vm.dateFormattedEnd
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.dateFormattedEnd,
                                            callback: function ($$v) {
                                              _vm.dateFormattedEnd = $$v
                                            },
                                            expression: "dateFormattedEnd",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.menu2,
                            callback: function ($$v) {
                              _vm.menu2 = $$v
                            },
                            expression: "menu2",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { outlined: "" },
                            on: {
                              input: function ($event) {
                                _vm.menu2 = false
                              },
                            },
                            model: {
                              value: _vm.dateEnd,
                              callback: function ($$v) {
                                _vm.dateEnd = $$v
                              },
                              expression: "dateEnd",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-left",
                      attrs: { cols: "12", md: "4" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          outlined: "",
                          "hide-details": "",
                          "menu-props": { maxHeight: "300" },
                          label: "Service",
                          items: _vm.services,
                          "item-text": "name",
                          "item-value": "id",
                        },
                        model: {
                          value: _vm.service,
                          callback: function ($$v) {
                            _vm.service = $$v
                          },
                          expression: "service",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { elevation: "1", loading: _vm.loading } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary", elevation: "0", dark: "" } },
            [
              _c(
                "v-row",
                { staticClass: "p-0 m-0 text-left" },
                [
                  _c("v-col", { staticClass: "p-0 m-0 text-left" }, [
                    _c("strong", { staticClass: "mb-0 text-uppercase" }, [
                      _vm._v("Statistiques relation client"),
                    ]),
                    _c("br"),
                    _c(
                      "small",
                      { staticClass: "mt-0 grey--text text--lighten-4" },
                      [
                        _vm._v(
                          "Du " +
                            _vm._s(_vm.dateFormattedStart) +
                            " au " +
                            _vm._s(_vm.dateFormattedEnd)
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          left: "50%",
                          background: "#78909c",
                          padding: "5px",
                          translate: "-50% 0",
                          "border-radius": "0 0 5px 5px",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { small: "", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.modalSettings = true
                              },
                            },
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { small: "" } },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" fa-fw fa-filter ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [_c("span", [_vm._v("Critères")])]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-menu",
                          {
                            staticStyle: { "z-index": "1111111" },
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { text: "", small: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v(" fa-ellipsis-v fa-fw "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              { staticStyle: { "z-index": "1111111" } },
                              [
                                _vm.isGranted("OPPORTUNITIES_STATISTICS")
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "Statistiques Personnelles",
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Mes statistiques"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isGranted("STATS_COM")
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "Statistiques Commerciaux",
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Statistiques commerciaux"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isGranted("OPPORTUNITIES_STATISTICSAGENCY")
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "Statistiques Agence",
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Statistiques de mon agence"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isGranted("OPPORTUNITIES_STATISTICSPERS")
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "Statistiques BU",
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Statistiques de mon service"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isGranted("EVENTS_EVENTSKEY")
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "Cross-Selling",
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Cross-Selling"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isGranted("SECTOR_STATISTICS")
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "Affectations",
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Affectations"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("ve-table", {
            attrs: {
              columns: _vm.columns,
              "border-y": "",
              "table-data": _vm.tableData,
              "row-style-option": _vm.rowStyleOption,
              "cell-style-option": _vm.cellStyleOption,
              "sort-option": _vm.sortOption,
              "max-height": "calc(100vh - 155px)",
              setHighlightRow: "",
              stripe: true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }