<template>
  <v-container fluid v-if="item" class="ma-0 py-0">
    <v-card width="100%" class="ma-0" elevation="1">
      <v-toolbar color="primary" dark class="ma-0 pa-0 px-1" elevation="0" @click="showHeaderOpp = !showHeaderOpp" height="48px">
        <v-card-title class="ma-0 pa-0 font-weight-medium" style="display: block; width: 100%; cursor: pointer">
          <v-row class="d-none d-md-flex justify-md-space-between flex-wrap">
            <div class="align-self-start">
              <span style="font-size: 0.5em; display: block; line-height: 0.5em" class="pa-0 ma-0 grey--text text--lighten-3 font-weight-light">N°</span>
              {{ $route.params.id }}
            </div>
            <div v-if="customerInfo">
              <span style="font-size: 0.5em; display: block; line-height: 0.5em" class="pa-0 ma-0 grey--text text--lighten-3 font-weight-light">Client</span
              >{{ customerInfo.entreprise.name }}
            </div>
            <div v-else>
              <span style="font-size: 0.5em; display: block; line-height: 0.5em" class="pa-0 ma-0 grey--text text--lighten-3 font-weight-light">Client</span>
              <div class="d-flex flex-column align-center flex-lg-row">
                <span>Pas de client</span><v-chip label small color="error" class="ml-2 text-caption">Brouillon</v-chip>
              </div>
            </div>

            <div>
              <span style="font-size: 0.5em; display: block; line-height: 0.5em" class="pa-0 ma-0 grey--text text--lighten-3 font-weight-light">Titre</span
              >{{ item.opp.name }}
            </div>
            <div>
              <template v-if="item.associated">
                <span style="font-size: 0.5em; display: block; line-height: 0.5em" class="pa-0 ma-0 grey--text text--lighten-3 font-weight-light">Contact</span>
                {{ item.associated.firstname }} {{ item.associated.lastname }}
              </template>
            </div>
            <div>
              <template v-if="item.type">
                <span style="font-size: 0.5em; display: block; line-height: 0.5em" class="pa-0 ma-0 grey--text text--lighten-3 font-weight-light">Métier</span>

                {{ item.type }}</template
              >
            </div>
            <div>
              <template v-if="files > 0">
                <span style="font-size: 0.5em; display: block; line-height: 0.5em" class="pa-0 ma-0 grey--text text--lighten-3 font-weight-light">&nbsp;</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge :content="files" bottom inline>
                      <v-icon v-bind="attrs" v-on="on" small color="white"> fas fa-paperclip fa-fw </v-icon>
                    </v-badge>
                  </template>
                  <span>{{ files }} Pièce{{ files > 1 ? "s" : "" }} jointe{{ files > 1 ? "s" : "" }}</span>
                </v-tooltip>
                <span v-if="listTotalDevis > 0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-badge :content="listTotalDevis" bottom inline>
                        <v-icon v-bind="attrs" v-on="on" small color="white"> fa-paperclip fa-fw </v-icon>
                      </v-badge>
                    </template>
                    <span>Devis{{ listTotalDevis > 1 ? "" : "" }}</span>
                  </v-tooltip>
                </span>
              </template>
            </div>
          </v-row>
          <v-row class="d-flex d-md-none justify-space-between text-subtitle-2 flex-wrap pa-0 ma-0">
            <div class="align-self-start">
              <span style="font-size: 0.5em; display: block; line-height: 0.5em" class="pa-0 ma-0 grey--text text--lighten-3 font-weight-light">N°</span>
              {{ $route.params.id }}
            </div>
            <div v-if="item.entreprise && customerInfo">
              <span style="font-size: 0.5em; display: block; line-height: 0.5em" class="pa-0 ma-0 grey--text text--lighten-3 font-weight-light">Client</span
              >{{ customerInfo.entreprise.name }}
            </div>
            <div v-else>
              <span style="font-size: 0.5em; display: block; line-height: 0.5em" class="pa-0 ma-0 grey--text text--lighten-3 font-weight-light">Client</span>Pas
              de client
            </div>
          </v-row>
        </v-card-title>
      </v-toolbar>
      <v-expand-transition>
        <div v-show="showHeaderOpp">
          <v-card-text :style="'background:' + this.$vuetify.theme.currentTheme.background">
            <v-row class="pt-6">
              <v-col cols="12" md="4">
                <template v-if="item.entreprise && customerInfo">
                  <MaterialCard class="v-card-profile" title="Client" icon="far fa-user fa-fw" :header="false" style="min-height: 13.5rem">
                    <v-card-text>
                      <!-- Exclus volontairement -->
                      <!-- <v-menu left offset-y>
                        <v-btn v-if="isGranted('OPPORTUNITIES_EDIT_SEND')">
                          <span @click="removeLink"><v-icon x-small>fa-unlink</v-icon> Délier</span>
                        </v-btn>
                      </v-menu> -->
                      <div class="text-center">
                        <h6
                          v-if="customerInfo.entreprise"
                          class="display-1 mb-0 pb-0 grey--text"
                          style="cursor: pointer"
                          @click="$router.push({ name: 'Client', params: { id: customerInfo.entreprise.customers.id } })"
                        >
                          {{ customerInfo.entreprise.name }}
                        </h6>
                      </div>

                      <div v-if="customerInfo.entreprise" class="grey--text">
                        <div class="text-center mb-3">
                          <v-icon x-small> fa-map-marker-alt fa-fw</v-icon>
                          {{ customerInfo.entreprise.adresse.address }}
                          {{ customerInfo.entreprise.adresse.address2 }} {{ customerInfo.entreprise.adresse.postalCode }} -
                          {{ customerInfo.entreprise.adresse.city }}
                        </div>

                        <v-divider></v-divider>
                        <br style="clear: both" />
                        <div>
                          <v-row>
                            <v-col cols="12" md="6" class="ma-0 pa-1">
                              <v-icon small class="green--text text--lighten-1">far fa-id-badge fa-fw</v-icon>

                              {{ customerInfo.customer.ctNumAb }}
                            </v-col>
                            <v-col cols="12" md="6" class="ma-0 pa-1">
                              <v-icon small>fa-barcode fa-fw</v-icon>
                              {{ customerInfo?.entreprise?.siret }}
                            </v-col>

                            <v-col cols="12" md="6" class="ma-0 pa-1" v-if="customerInfo.entreprise.phone">
                              <v-icon small>fa-phone fa-fw</v-icon>
                              {{ customerInfo.entreprise.phone }}
                            </v-col>
                            <v-col cols="12" md="6" class="ma-0 pa-1" v-if="customerInfo.entreprise.phone2">
                              <v-icon small>fa-phone fa-fw</v-icon>
                              {{ customerInfo.entreprise.phone2 }}
                            </v-col>
                            <v-col cols="12" md="6" class="ma-0 pa-1" v-if="customerInfo.entreprise.email">
                              <v-icon small>far fa-envelope fa-fw</v-icon>
                              {{ customerInfo.entreprise.email }}
                            </v-col>
                            <v-col cols="12" md="6" class="ma-0 pa-1" v-if="customerInfo.entreprise.site">
                              <v-icon small>fa-globe-europe fa-fw</v-icon>
                              {{ customerInfo.entreprise.site }}
                            </v-col>
                          </v-row>
                        </div>
                        <div></div>
                      </div>
                    </v-card-text>
                  </MaterialCard>
                </template>
                <template v-else>
                  <v-col>
                    <MaterialCard class="v-card-profile" icon="fa-user" title="Compte">
                      <v-card-text>
                        <div class="text-center mb-2">Pas de compte</div>

                        <div class="font-weight-light grey--text mb-5">Il n'y a actuellement aucun compte lié.</div>
                        <v-btn class="text-center" width="100%" color="primary" @click="addCompteModal = true">
                          <v-icon class="mr-5"> fa-plus </v-icon>
                          Lier un compte
                        </v-btn>
                      </v-card-text>
                    </MaterialCard>
                  </v-col>
                </template>
              </v-col>
              <v-col cols="12" md="4">
                <MaterialCard class="v-card-profile" icon="fa-users" title="Contacts" style="min-height: 13.5rem">
                  <div class="listbox--actions-add">
                    <v-btn
                      fab
                      small
                      color="primary"
                      @click="
                        newContact = {
                          customerId: item.entreprise.id,
                          isFavorite: false,
                          civilityId: 0,
                          firstname: '',
                          lastname: '',
                          phone: '',
                          phone2: '',
                          fax: '',
                          email: '',
                          function: '',
                          note: '',
                          job: null,
                        };
                        similar = [];
                        newContactModal = true;
                      "
                    >
                      <v-icon small> fa-plus fa-fw </v-icon>
                    </v-btn>
                  </div>

                  <v-col cols="12" v-if="specificContact">
                    <v-row class="ma-0 pt-0 text-h5 grey--text text-left">
                      <v-col cols="12" class="pa-0">
                        <v-icon> fa-fw fa-id-card </v-icon>
                        {{ specificContact.contact.firstname }} {{ specificContact.contact.lastname }}
                        <v-divider class="my-2"></v-divider>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 grey--text mb-5">
                      <v-col cols="6" class="pa-0 mb-1" v-if="specificContact.contact.function">
                        <v-icon x-small>fa-graduation-cap fa-fw</v-icon> {{ specificContact.contact.function }}
                      </v-col>
                      <v-col cols="6" class="pa-0 mb-1" v-if="specificContact.contact.email">
                        <v-icon x-small>far fa-envelope fa-fw</v-icon> {{ specificContact.contact.email }}
                      </v-col>
                      <v-col cols="6" class="pa-0 mb-1" v-if="specificContact.contact.phone">
                        <v-icon x-small>fa-phone fa-fw</v-icon> {{ specificContact.contact.phone }}
                      </v-col>
                      <v-col cols="6" class="pa-0 mb-1" v-if="specificContact.contact.phone2">
                        <v-icon x-small>fa-phone fa-fw</v-icon> {{ specificContact.contact.phone2 }}
                      </v-col>
                      <v-col cols="6" class="pa-0 mb-1" v-if="specificContact.contact.fax">
                        <v-icon x-small>fa-fax fa-fw</v-icon> {{ specificContact.contact.fax }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-card-text>
                    <v-expansion-panels v-model="activePan" v-click-outside="onClickOutside">
                      <v-expansion-panel>
                        <v-expansion-panel-header expand-icon="mdi-menu-down"> Liste des contacts client </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-text-field v-model="searchContact" append-icon="mdi-magnify" label="Rechercher" single-line hide-details></v-text-field>
                          <v-data-table
                            @click:row="catchRow"
                            dense
                            :items-per-page="5"
                            :headers="headers"
                            :items="contacts"
                            :search.sync="searchContact"
                          ></v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </MaterialCard>
              </v-col>
              <v-col cols="12" md="4">
                <filesOpportunities v-model="infoDevis"></filesOpportunities>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>

    <modal v-model="newContactModal" max-width="1024">
      <v-alert v-if="!singleContact" dense outlined type="error"> Un contact existe déjà avec cet email. </v-alert>
      <contact-form v-model="newContact"></contact-form>
      <v-row v-if="similar && similar.length > 0">
        <v-col cols="12">
          <v-divider></v-divider>
          <h4>Contacts similaires</h4>
        </v-col>
        <v-col cols="6" md="6" v-for="(item, key) in similar" :key="key" class="py-1">
          <SimilarContactCard :item="item" :linkContactConfirm="linkContactConfirm"></SimilarContactCard>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn color="error" text @click="newContactModal = false"> Fermer </v-btn>
        <v-btn text :disabled="!singleContact" class="mr-4" @click="newContactSub"> Enregistrer </v-btn>
      </template>
    </modal>

    <modal v-model="addCompteModal">
      <v-row>
        <v-col cols="12">
          Rechercher un compte
          <v-autocomplete
            :menu-props="{ maxHeight: '300' }"
            v-model="selectedCustomer"
            :items="customerList"
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            item-text="entreprise.name"
            item-value="entreprise.id"
            label="Rechercher un compte"
            prepend-icon="fa-users"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12"></v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn color="error" text @click="addCompteModal = false"> Fermer </v-btn>
        <v-btn text class="mr-4" @click="validate"> Enregistrer </v-btn>
      </template>
    </modal>
  </v-container>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import MaterialCard from "../../components/common/MaterialCard";
import Modal from "../../components/common/modal";
import ContactForm from "../contacts/contactForm";
import Tools from "../../mixins/Tools";
import filesOpportunities from "./filesOpportunities";
import SimilarContactCard from "../../components/common/SimilarContactCard.vue";

export default {
  name: "opportunityCustomer",
  components: { ContactForm, filesOpportunities, Modal, MaterialCard, SimilarContactCard },
  mixins: [simpleDebounce, Tools],
  props: ["value"],
  data() {
    return {
      similar: [],
      singleContact: true,
      infoDevis: [],
      customerList: [],
      activePan: null,
      selectedCustomer: null,
      isLoading: false,
      newContactModal: false,
      addCompteModal: false,
      newContact: {},
      item: null,
      customerInfo: null,
      headers: [
        {
          text: "Nom",
          align: "start",
          value: "name",
        },
        { text: "Email", value: "email" },
      ],
      contacts: [],
      searchContact: "",
      search: "",
      specificContact: null,
      page: 1,
      limit: 5,
      files: [],
      listTotalDevis: [],
      selectedContact: null,
      showHeaderOpp: false,
    };
  },
  mounted() {
    this.loadSearch("");
    this.loadFiles();
    this.loadDevis();
  },
  methods: {
    async linkContactConfirm(id) {
      let res = await this.$confirm("Voulez-vous lier ce contact ?");

      if (res) {
        this.selectedContact = id;
        this.linkContactSub();
        this.newContactModal = false;
      }
    },
    linkContactSub() {
      this.$axios.post("/api/contacts/" + this.selectedContact + "/link", { customer: this.customerInfo.customer.id }).then(() => {
        this.$simpleToast.success("Contact", "Le contact a bien été lié");
        this.$emit("refresh-all", true);
        this.getContact();
        this.newContactModal = false;
      });
    },
    loadFiles() {
      this.$axios("/api/medias/" + this.$route.params.id).then((res) => {
        this.files = res.data.length;
      });
    },
    loadDevis() {
      this.$axios("/api/sage/devis/" + this.$route.params.id).then((resp) => {
        this.listTotalDevis = resp.data.length;
      });
    },
    onClickOutside() {
      this.activePan = null;
    },
    newContactSub() {
      this.$axios.post("/api/contacts", { data: this.newContact }).then(() => {
        this.loadSearch("");
        this.getContact();
        this.newContactModal = false;
        this.$simpleToast.success("Contact", "Le contact a bien été ajouté");
      });
    },
    catchRow(event) {
      this.specificContact = event.origin;

      this.$axios.patch("/api/opportunities/" + this.$route.params.id + "/contact", { contactId: this.specificContact.contact.id }).then((res) => {
        this.item = res.data;
        this.$simpleToast.success("Contact", "Le contact a bien été selectionné");
      });
    },
    async removeLink() {
      const res = await this.$confirm("Êtes-vous sur ?", { title: "Confirmation", color: "primary" });
      if (res) {
        this.$axios.patch("/api/opportunities/" + this.$route.params.id + "/customer/unlink").then((res) => {
          this.item = res.data;
        });
      }
    },
    getSingleEmail(email) {
      this.$axios("/api/contacts/email", { params: { email: email } }).then((resp) => {
        this.singleContact = !resp.data;
        this.singleContactId = resp.data ? resp.data : null;
      });
    },
    validate() {
      if (this.selectedCustomer !== null) {
        this.$axios.patch("/api/opportunities/" + this.$route.params.id + "/customer", { data: this.selectedCustomer }).then((res) => {
          this.item = res.data;
          this.$simpleToast.success("Client", "Le client à bien été lié");
          this.addCompteModal = false;
          this.getCustomersInfo();
        });
      } else {
        this.$simpleToast.error("Client opportunité", "Un client doit être séléctionné");
      }
    },
    loadSearch() {
      this.isLoading = true;
      let search = {
        name: this.search,
        isIndirect: null,
        siret: null,
        postalCode: null,
        city: null,
        sector: null,
        phone: null,
        ctNumAb: null,
        ape: null,
        sortedBy: null,
        sortedDesc: false,
      };
      let allUser = [this.$store.state.user.id];

      if (this.isGranted("CUSTOMERS_INDEX_ALL")) allUser = [];

      this.$axios
        .get("/api/customers", {
          params: {
            page: 1,
            search,
            limit: 10,
            asUser: allUser,
            asType: [],
            userMode: "tous",
            typeMode: "tous",
            campaignOnly: false,
            prospectOnly: true,
            clientOnly: true,
            customerKey: false,
          },
        })
        .then((res) => {
          this.customerList = res.data.data;
        });
      this.isLoading = false;
    },
    getContact() {
      this.contacts = [];
      this.$axios("/api/customers/" + this.item.entreprise.customers.id + "/contacts", { params: { page: this.page, limit: 999 } }).then((resp) => {
        resp.data.data.forEach((item) => {
          this.contacts.push({
            name: item.contact.contact.lastname + " " + item.contact.contact.firstname,
            email: item.contact.contact.email,
            isFavorite: item.contact.favorite,
            function: item.contact.role,
            job: item.contact.job,
            phone: item.contact.contact.phone,
            origin: item.contact,
          });
        });
      });
    },
    getCustomersInfo() {
      this.$axios("/api/customers/" + this.item.entreprise.customers.id).then((data) => {
        this.customerInfo = data.data;
        this.getContact();
        if (this.item.associated) {
          this.specificContact = {};
          this.specificContact.contact = this.item.associated;
        }
      });
    },
    getContactsSearch(override = false) {
      let search = {};

      if (override) {
        search = this.newContact;
        search.sortedBy = null;
        search.sortedDesc = false;
      }
      let tmp = [];
      this.$axios("/api/contacts", { params: { search, limit: 999 } }).then((resp) => {
        if (override && resp.data.max < 20) {
          this.similar = resp.data.data;
        }
        resp.data.data.forEach((item) => {
          tmp.push({ id: item.contact.id, fullname: item.contact.firstname + " " + item.contact.lastname });
        });
        this.contactsSearch = tmp;
      });
    },
  },
  watch: {
    contacts() {
      if (this.item.opp.contactId && this.contacts.length > 0) {
        this.specificContact = {};
        this.specificContact.contact = this.contacts.find((con) => con.origin.contact.id === this.item.opp.contactId).origin.contact;
      }
    },
    "newContact.email"() {
      this.simpleDebounce(() => {
        this.getSingleEmail(this.newContact.email);
        this.getContactsSearch(true);
      }, 500);
    },
    "newContact.firstname"() {
      this.simpleDebounce(() => {
        this.getContactsSearch(true);
      }, 500);
    },
    "newContact.lastname"() {
      this.simpleDebounce(() => {
        this.getContactsSearch(true);
      }, 500);
    },
    "$route.params.id"() {
      this.loadSearch("");
    },
    limit() {
      this.getContact();
    },
    page() {
      this.getContact();
    },
    value() {
      this.loadFiles();
      this.loadDevis();
      this.item = this.value;
      if (this.item.entreprise && this.customerInfo === null) {
        this.getCustomersInfo();
      }
    },
    item() {
      this.$emit("input", this.item);
    },
    search() {
      this.simpleDebounce(() => {
        this.loadSearch();
      });
    },
  },
};
</script>

<style></style>
