<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8">
        <dashboardOpportunities />
      </v-col>
      <v-col cols="12" md="4">
        <dashboardCalendar />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardOpportunities from "./opportunities/dashboardOpportunities";
import DashboardCalendar from "./calendar/dashboardCalendar.vue";
export default {
  name: "Home",

  components: {
    DashboardCalendar,
    DashboardOpportunities,
  },
  mounted() {},
};
</script>
