<template>
  <div>
    <material-card class="pt-0 mt-8" title="Cibles" icon="fa-bullseye">
      <v-tabs background-color="primary" slider-color="white" class="mb-2 rounded" grow>
        <v-tab @click="cibleTab = 'mailing'">Mailing</v-tab>
        <v-tab @click="cibleTab = 'phoning'">Phoning</v-tab>
      </v-tabs>
      <v-row>
        <v-col cols="10" class="py-0">
          <v-text-field label="Rechercher" v-model="search"></v-text-field>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-chip label small>{{ max }} cible{{ max > 1 ? "s" : "" }}</v-chip>
        </v-col>
      </v-row>
      <div style="max-height: 300px; overflow-y: scroll" id="infinite-list" v-if="cibleTab === 'mailing'">
        <v-expansion-panels>
          <v-expansion-panel v-for="(customer, i) in Object.keys(customersMailing)" :key="i">
            <v-expansion-panel-header @click="curItem === customer ? (curItem = null) : (curItem = customer)">
              {{ customer }}
              <!-- <span style="position: absolute; right: 15px" class="ml-1"
                ><v-chip label x-small :color="getScoreResult(customer) > 50 ? 'success' : 'error'">{{ getScoreResult(customer) }}/100</v-chip></span
              > -->
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  class="py-1"
                  cols="4"
                  v-for="(contact, key) in customersMailing[customer] && customersMailing[customer].contactMailing
                    ? customersMailing[customer].contactMailing
                    : []"
                  :key="key"
                >
                  <v-card style="overflow: hidden">
                    <v-card-title class="py-0" style="white-space: nowrap">
                      <v-icon
                        color="yellow darken-1"
                        class="mr-1"
                        v-if="
                          customersMailing[customer] &&
                          customersMailing[customer].relCusto &&
                          customersMailing[customer].relCusto[key] &&
                          customersMailing[customer].relCusto[key].favorite
                        "
                        small
                        >fa-star</v-icon
                      >
                      {{ contact.firstname }} {{ contact.lastname }}
                      <v-icon class="ml-1" x-small v-if="score['open'] && score['open'][customer].includes(contact.id)">fas fa-envelope-open</v-icon>
                      <v-icon class="ml-1" x-small v-if="score['click'] && score['click'][customer].includes(contact.id)">fas fa-hand-pointer</v-icon>
                    </v-card-title>
                    <v-card-text class="text-left">
                      <!-- unsub, sent, opened, clicked, softbounced, hardbounced, blocked -->
                      <div v-if="contact.status">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon x-small class="mr-1" v-bind="attrs" v-on="on">fas fa-envelope</v-icon>
                          </template>
                          <span>Statut e-mail</span>
                        </v-tooltip>

                        <v-chip
                          x-small
                          :color="
                            contact.status === 'unsub'
                              ? 'error'
                              : contact.status === 'sent'
                              ? 'primary'
                              : contact.status === 'opened'
                              ? 'success'
                              : contact.status === 'clicked'
                              ? 'warning'
                              : contact.status === 'softbounced'
                              ? 'warning'
                              : contact.status === 'hardbounced'
                              ? 'error'
                              : contact.status === 'blocked'
                              ? 'error'
                              : 'grey lighten-1'
                          "
                        >
                          <span v-if="contact.status === 'unsub'">Désabonné</span>
                          <span v-if="contact.status === 'sent'">Envoyé</span>
                          <span v-if="contact.status === 'opened'">Ouvert</span>
                          <span v-if="contact.status === 'clicked'">Cliqué</span>
                          <span v-if="contact.status === 'softbounced'">Échec temporaire</span>
                          <span v-if="contact.status === 'hardbounced'">Échec</span>
                          <span v-if="contact.status === 'blocked'">Bloqué</span>
                        </v-chip>
                      </div>
                      <div>
                        <v-icon x-small class="mr-1">fas fa-graduation-cap</v-icon>
                        {{ contact.job.name ? contact.job.name : "Non renseigné" }}
                      </div>
                      <div>
                        <v-icon x-small class="mr-1">fa-phone</v-icon>
                        <template v-if="contact.phone">
                          <a :href="'tel:' + contact.phone">{{ contact.phone }}</a>
                        </template>
                        <template v-else> Non renseigné </template>
                      </div>
                      <div>
                        <v-icon x-small class="mr-1">fa-envelope</v-icon>
                        <template v-if="contact.email">
                          <a :href="'mailto:' + contact.email">{{ contact.email }}</a>
                        </template>
                        <template v-else> Non renseigné </template>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div style="max-height: 300px; overflow-y: scroll" id="infinite-list" v-if="cibleTab === 'phoning'">
        <v-expansion-panels>
          <v-expansion-panel v-for="(customer, i) in Object.keys(customersPhoning)" :key="i">
            <v-expansion-panel-header @click="curItem === customer ? (curItem = null) : (curItem = customer)">
              {{ customer }}
              <span style="position: absolute; right: 15px" class="mr-10"
                ><v-chip label x-small :color="getScoreResult(customer).ratio >= 50 ? 'success' : 'error'">{{
                  getScoreResult(customer).text
                }}</v-chip></span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  class="py-1"
                  cols="4"
                  v-for="(contact, key) in customersPhoning[customer] && customersPhoning[customer].contactPhoning
                    ? customersPhoning[customer].contactPhoning
                    : []"
                  :key="key"
                >
                  <v-card style="overflow: hidden">
                    <v-card-title class="py-0" style="white-space: nowrap">
                      <v-icon
                        color="yellow darken-1"
                        class="mr-1"
                        v-if="
                          customersPhoning[customer] &&
                          customersPhoning[customer].relCusto &&
                          customersPhoning[customer].relCusto[key] &&
                          customersPhoning[customer].relCusto[key].favorite
                        "
                        small
                        >fa-star</v-icon
                      >
                      {{ contact.firstname }} {{ contact.lastname }}
                    </v-card-title>
                    <v-card-text class="text-left">
                      <div>
                        <v-icon x-small class="mr-1">fas fa-graduation-cap</v-icon>
                        {{
                          customersPhoning[customer] && customersPhoning[customer].relCusto && customersPhoning[customer].relCusto.job
                            ? customersPhoning[customer].relCusto.job.name
                            : "Non renseigné"
                        }}
                      </div>
                      <div>
                        <v-icon x-small class="mr-1">fa-phone</v-icon>
                        <template v-if="contact.phone">
                          <a :href="'tel:' + contact.phone">{{ contact.phone }}</a>
                        </template>
                        <template v-else> Non renseigné </template>
                      </div>
                      <div>
                        <v-icon x-small class="mr-1">fa-envelope</v-icon>
                        <template v-if="contact.email">
                          <a :href="'mailto:' + contact.email">{{ contact.email }}</a>
                        </template>
                        <template v-else> Non renseigné </template>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="text-center">
        <v-btn v-if="page > 1" @click="page = page - 1" class="mr-2" small :loading="loadCust"><v-icon small>fa-chevron-left</v-icon></v-btn>
        <v-btn v-if="page < max / 10" @click="page = page + 1" class="mr-2" small :loading="loadCust"><v-icon small>fa-chevron-right</v-icon></v-btn>
      </div>
    </material-card>
  </div>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import MaterialCard from "../../../../components/common/MaterialCard";

export default {
  name: "goalCompaign",
  components: { MaterialCard },
  props: ["value"],
  mixins: [simpleDebounce],
  data() {
    return {
      search: "",
      customersMailing: {},
      customersPhoning: {},
      score: {},
      page: 1,
      max: 0,
      loadCust: false,
      curItem: this.value,
      cibleTab: "mailing",
    };
  },
  mounted() {
    this.getInfos();
  },
  methods: {
    getScoreResult(name) {
      const eventPlanned = Object.keys(this.customersPhoning[name].phoningContactEvent).length;
      const eventDone = Object.values(this.customersPhoning[name].totalEventRealise)[0];

      return { text: `${eventDone}/${eventPlanned}`, ratio: (eventDone / eventPlanned) * 100 };
    },
    getInfos() {
      this.loadCust = true;
      this.$axios("/api/compaign/" + this.$route.params.id + "/customers", { params: { page: this.page, search: this.search } }).then((resp) => {
        this.customersMailing = resp.data.mailing.data;
        this.customersPhoning = resp.data.phoning.data;
        this.max = resp.data.max;
        console.log(this.customersMailing);
        //this.getScore();
        this.loadCust = false;
      });
    },
    getScore() {
      this.$axios("/api/compaign/" + this.$route.params.id + "/score", { params: { page: this.page, search: this.search } }).then((resp) => {
        this.score = { ...this.score, ...resp.data.data };
        this.max = resp.data.max;
      });
    },
  },
  watch: {
    curItem() {
      this.$emit("input", this.curItem);
    },
    value() {
      this.curItem = this.value;
    },
    search() {
      this.simpleDebounce(() => {
        this.customers = {};
        this.max = 0;
        this.getInfos();
      }, 1000);
    },
    page() {
      this.simpleDebounce(() => {
        this.customers = [];
        this.getInfos();
      }, 300);
    },
  },
};
</script>

<style scoped></style>
