var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    { staticClass: "mt-8", attrs: { icon: "fa-info", title: "Informations" } },
    [
      _vm.compaign
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", lg: "6" } }, [
                    _c("div", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("Titre : "),
                      ]),
                      _vm._v(" " + _vm._s(_vm.compaign.name)),
                    ]),
                    _c("div", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("Date de début : "),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .dayjs(_vm.compaign.startDate)
                              .format("DD/MM/YYYY")
                          )
                      ),
                    ]),
                    _c("div", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("Date de fin : "),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dayjs(_vm.compaign.endDate).format("DD/MM/YYYY")
                          )
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("Métiers ciblés : "),
                        ]),
                        _vm._l(_vm.compaign.types, function (item, i) {
                          return _c(
                            "v-chip",
                            {
                              key: i,
                              staticClass: "mr-1",
                              attrs: {
                                outlined: "",
                                color: item.color,
                                label: "",
                                small: "",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { "x-small": "" },
                                },
                                [_vm._v("fa-fw " + _vm._s(item.icon))]
                              ),
                              _vm._v(" " + _vm._s(item.name)),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("v-col", { attrs: { cols: "12", lg: "6" } }, [
                    _c("div", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("Description : "),
                      ]),
                    ]),
                    _c("p", {
                      staticClass: "text-caption",
                      domProps: { innerHTML: _vm._s(_vm.compaign.body) },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }