var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("landscape"),
      _c(
        "modal",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Filtrer"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.openFilter,
            callback: function ($$v) {
              _vm.openFilter = $$v
            },
            expression: "openFilter",
          },
        },
        [
          _vm.isGranted("OPPORTUNITIES_INDEX_ALLSERVICE")
            ? _c("user-list", {
                attrs: { label: "Voir les comptes d'un utilisateur" },
                on: { selectChange: _vm.setAsUser },
              })
            : _vm._e(),
          _c("v-switch", {
            attrs: { label: "Voir uniquement mes comptes" },
            model: {
              value: _vm.search.affect,
              callback: function ($$v) {
                _vm.$set(_vm.search, "affect", $$v)
              },
              expression: "search.affect",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticStyle: { "z-index": "9" }, attrs: { elevation: "1" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary", dark: "", elevation: "0" } },
            [
              _c(
                "v-card-title",
                [
                  _vm._v(" LISTES DES ABONNEMENTS AVEC LEURS ARTICLES "),
                  _c(
                    "div",
                    {
                      style: _vm.isMobile()
                        ? {
                            marginLeft: "20px",
                          }
                        : {
                            position: "absolute",
                            top: "0",
                            left: "50%",
                            background: "#78909c",
                            padding: "5px",
                            transform: "translate(-50%)",
                            borderRadius: "0 0 5px 5px",
                          },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", small: "" },
                          on: {
                            click: function ($event) {
                              _vm.openFilter = true
                            },
                          },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { small: "" } },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" fa-fw fa-filter ")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Filtrer")])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "content-class": "mt-2",
                            "close-on-content-click": false,
                            "offset-y": "",
                            right: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { text: "", small: "" } },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [_vm._v(" fa-fw fa-cog ")]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Colonnes")])]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "py-0",
                                  attrs: {
                                    color: "primary",
                                    dark: "",
                                    elevation: "0",
                                    height: "45px",
                                  },
                                },
                                [_vm._v(" Colonnes visibles ")]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass: "pa-1 pb-3",
                                  staticStyle: {
                                    "min-width": "180px",
                                    "max-height": "calc(100vh - 115px)",
                                    "overflow-y": "auto",
                                  },
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(
                                      _vm.columnsAvailable,
                                      function (item, key) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: key,
                                            staticStyle: {
                                              "min-height": "40px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleItem(item, key)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _vm.checkArrayContains(
                                                  item.value,
                                                  _vm.headers
                                                )
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          small: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "far fa-fw fa-check-square"
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "far fa-fw fa-square"
                                                        ),
                                                      ]
                                                    ),
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [_vm._v(_vm._s(item.text))]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("v-divider"),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-0",
            staticStyle: { "z-index": "1000" },
            attrs: {
              headers: _vm.headers,
              items: _vm.listSubs,
              page: _vm.page,
              search: _vm.researchInfra,
              "items-per-page": _vm.limitItem,
              loading: _vm.isLoading,
              "calculate-widths": "",
              "server-items-length": _vm.maxItem,
              "footer-props": { disableItemsPerPages: true },
              "sort-by": _vm.sortedBy,
              "sort-desc": _vm.sortedDesc,
            },
            on: {
              "update:search": function ($event) {
                _vm.researchInfra = $event
              },
              "update:page": _vm.setPage,
              "click:row": _vm.loadSub,
              "update:items-per-page": _vm.setItemMax,
              "update:sortBy": function ($event) {
                _vm.sortedBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortedBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortedDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortedDesc = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.line.arRef",
                fn: function ({ item }) {
                  return _vm._l(item.line, function (line, key) {
                    return _c(
                      "v-chip",
                      {
                        key: key,
                        staticClass: "mx-1",
                        attrs: { "x-small": "", label: "" },
                      },
                      [_vm._v(_vm._s(line.arRef))]
                    )
                  })
                },
              },
              {
                key: "item.line.alDesign",
                fn: function ({ item }) {
                  return _vm._l(item.line, function (line, key) {
                    return _c(
                      "div",
                      { key: key },
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { "x-small": "", label: "" },
                          },
                          [_vm._v(_vm._s(line.alDesign))]
                        ),
                      ],
                      1
                    )
                  })
                },
              },
              {
                key: "item.line.numLicence",
                fn: function ({ item }) {
                  return _vm._l(item.line, function (line, key) {
                    return _c(
                      "div",
                      { key: key },
                      [
                        line.numLicence
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ma-1",
                                attrs: { "x-small": "", label: "" },
                              },
                              [_vm._v(_vm._s(line.numLicence))]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  })
                },
              },
              {
                key: "item.line.version",
                fn: function ({ item }) {
                  return _vm._l(item.line, function (line, key) {
                    return _c(
                      "div",
                      { key: key },
                      [
                        line.version
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ma-1",
                                attrs: { "x-small": "", label: "" },
                              },
                              [_vm._v(_vm._s(line.version))]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  })
                },
              },
              {
                key: "item.item.startDate",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dayjs(item.item.startDate).format("DD/MM/YYYY")
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.item.endDate",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dayjs(item.item.endDate).format("DD/MM/YYYY")
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "body.prepend",
                fn: function () {
                  return [
                    _c("tr", [
                      _vm.checkArrayContains("customer", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                attrs: { type: "text", label: "Filtrer" },
                                model: {
                                  value: _vm.search.customer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "customer", $$v)
                                  },
                                  expression: "search.customer",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkArrayContains("item.abModele", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c("v-select", {
                                staticStyle: { "z-index": "150" },
                                attrs: {
                                  "menu-props": { maxHeight: "300" },
                                  "small-chips": "",
                                  "deletable-chips": "",
                                  items: _vm.typeAbos,
                                  multiple: "",
                                  label: "Filtrer",
                                },
                                model: {
                                  value: _vm.searchSubType,
                                  callback: function ($$v) {
                                    _vm.searchSubType = $$v
                                  },
                                  expression: "searchSubType",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkArrayContains("item.abIntitule", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                attrs: { label: "Filtrer", type: "text" },
                                model: {
                                  value: _vm.search.intitule,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "intitule", $$v)
                                  },
                                  expression: "search.intitule",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkArrayContains("item.startDate", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu1",
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "auto",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label: "Filtrer",
                                                      "append-outer-icon":
                                                        _vm.search.dateStart !==
                                                          null &&
                                                        _vm.search
                                                          .dateStartFormatted !==
                                                          null
                                                          ? "fa-times"
                                                          : "",
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        _vm.search.dateStart =
                                                          _vm.parseDate(
                                                            _vm.search
                                                              .dateStartFormatted
                                                          )
                                                      },
                                                      "click:append-outer":
                                                        function ($event) {
                                                          _vm.search.dateStart =
                                                            null
                                                          _vm.search.dateStartFormatted =
                                                            null
                                                        },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.search
                                                          .dateStartFormatted,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.search,
                                                          "dateStartFormatted",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "search.dateStartFormatted",
                                                    },
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1477842317
                                  ),
                                  model: {
                                    value: _vm.menuStart,
                                    callback: function ($$v) {
                                      _vm.menuStart = $$v
                                    },
                                    expression: "menuStart",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "" },
                                    on: {
                                      input: function ($event) {
                                        _vm.menuStart = false
                                      },
                                    },
                                    model: {
                                      value: _vm.search.dateStart,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "dateStart", $$v)
                                      },
                                      expression: "search.dateStart",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkArrayContains("item.endDate", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu1",
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "auto",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label: "Filtrer",
                                                      "append-outer-icon":
                                                        _vm.search.dateEnd !==
                                                          null &&
                                                        _vm.search
                                                          .dateEndFormatted !==
                                                          null
                                                          ? "fa-times"
                                                          : "",
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        _vm.search.dateEnd =
                                                          _vm.parseDate(
                                                            _vm.search
                                                              .dateEndFormatted
                                                          )
                                                      },
                                                      "click:append-outer":
                                                        function ($event) {
                                                          _vm.search.dateEnd =
                                                            null
                                                          _vm.search.dateEndFormatted =
                                                            null
                                                        },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.search
                                                          .dateEndFormatted,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.search,
                                                          "dateEndFormatted",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "search.dateEndFormatted",
                                                    },
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3910659874
                                  ),
                                  model: {
                                    value: _vm.menuEnd,
                                    callback: function ($$v) {
                                      _vm.menuEnd = $$v
                                    },
                                    expression: "menuEnd",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "" },
                                    on: {
                                      input: function ($event) {
                                        _vm.menuEnd = false
                                      },
                                    },
                                    model: {
                                      value: _vm.search.dateEnd,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "dateEnd", $$v)
                                      },
                                      expression: "search.dateEnd",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkArrayContains("line.arRef", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c("v-autocomplete", {
                                staticClass: "pt-3",
                                staticStyle: { "z-index": "150" },
                                attrs: {
                                  "menu-props": { maxHeight: "300" },
                                  "item-text": "arRef",
                                  "item-value": "arRef",
                                  "small-chips": "",
                                  "deletable-chips": "",
                                  "search-input": _vm.searchCodeSubs,
                                  items: _vm.codeSubs,
                                  multiple: "",
                                  label: "Code article",
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.searchCodeSubs = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.searchCodeSubs = $event
                                  },
                                },
                                model: {
                                  value: _vm.search.arRefArr,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "arRefArr", $$v)
                                  },
                                  expression: "search.arRefArr",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkArrayContains("user", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                staticStyle: { "max-width": "200px" },
                                attrs: { type: "text", label: "Filtrer" },
                                model: {
                                  value: _vm.search.user,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "user", $$v)
                                  },
                                  expression: "search.user",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkArrayContains("line.alDesign", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                staticStyle: { "max-width": "200px" },
                                attrs: { type: "text", label: "Filtrer" },
                                model: {
                                  value: _vm.search.alDesign,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "alDesign", $$v)
                                  },
                                  expression: "search.alDesign",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkArrayContains("sector", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                staticStyle: { "max-width": "200px" },
                                attrs: { type: "text", label: "Filtrer" },
                                model: {
                                  value: _vm.search.sector,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "sector", $$v)
                                  },
                                  expression: "search.sector",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkArrayContains("city", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                staticStyle: { "max-width": "200px" },
                                attrs: { type: "text", label: "Filtrer" },
                                model: {
                                  value: _vm.search.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "city", $$v)
                                  },
                                  expression: "search.city",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkArrayContains("line.numLicence", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                staticStyle: { "max-width": "200px" },
                                attrs: { type: "text", label: "Filtrer" },
                                model: {
                                  value: _vm.search.numLicence,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "numLicence", $$v)
                                  },
                                  expression: "search.numLicence",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checkArrayContains("line.version", _vm.headers)
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                staticStyle: { "max-width": "200px" },
                                attrs: { type: "text", label: "Filtrer" },
                                model: {
                                  value: _vm.search.version,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "version", $$v)
                                  },
                                  expression: "search.version",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }