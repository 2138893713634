<template>
  <div style="margin-top: -25px">
    <v-card elevation="1" class="mb-7">
      <v-toolbar color="primary" elevation="0" dark>
        <v-card-title> {{ item && item.id ? "Édition" : "Création" }} d'une campagne </v-card-title>
      </v-toolbar>
    </v-card>
    <v-container fluid v-if="item !== null">
      <v-row>
        <v-col cols="12">
          <informationsFormCompaign v-model="item"></informationsFormCompaign>
        </v-col>
        <v-col cols="12">
          <phaseFormCompaign v-model="item"></phaseFormCompaign>
        </v-col>
        <v-col cols="12">
          <customerFormCompaign :all-user.sync="item.company === 'Entreprise' || item.company === 'Commando'" v-model="item"></customerFormCompaign>
        </v-col>
        <v-col cols="12">
          <contactFormCompaign v-model="item"></contactFormCompaign>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="12" class="text-right">
          <v-btn color="primary" @click="saveCompaign(false)" :loading="loading" style="z-index: 10">Initier la campagne</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import InformationsFormCompaign from "./informations/informationsFormCompaign";
import PhaseFormCompaign from "./phases/phaseFormCompaign";
import CustomerFormCompaign from "./customer/customerFormCompaign";
import ContactFormCompaign from "./contact/contactFormCompaign";
import simpleDebounce from "simple-debounce-vue-mixin";
import Tools from "../../../mixins/Tools";
import dayjs from "dayjs";

export default {
  name: "editionCompaign",
  components: { ContactFormCompaign, CustomerFormCompaign, PhaseFormCompaign, InformationsFormCompaign },
  mixins: [simpleDebounce, Tools],
  data() {
    return {
      item: null,
      saveRow: true,
      page: 1,
      loading: false,
      search: "",
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.initItem();
    } else {
      this.item = {
        name: "",
        status: 0,
        draft: true,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().add(7, "days").format("YYYY-MM-DD"),
        types: [],
        company: null,
        contactsFilter: null,
        body: "",
        contactsType: "all",
        customers: [],
        commando: false,
        asUser: null,
        research: null,
        exclude: [],
        commercial: [],
        phases: {
          1: {
            templateName: "",
            validTemplate: true,
            enable: true,
            emailing: false,
            filter: false,
            subject: "",
            dateStartFormatted: dayjs().format("YYYY-MM-DD"),
            startDate: dayjs().format("YYYY-MM-DD"),
            endDate: null,
            mailjet: "",
            timeStart: dayjs().add(2, "hours").format("HH:mm"),
          },
          2: {
            startDate: dayjs().add(7, "days").format("YYYY-MM-DD"),
            endDate: dayjs().add(21, "days").format("YYYY-MM-DD"),
            enable: false,
          },
          3: {
            enable: false,
            startDate: dayjs().add(7, "days").format("YYYY-MM-DD"),
            type: null,
            url: null,
            duration: null,
            timeStart: "09:00",
            place: "",
          },
          4: {
            enable: false,
            startDate: dayjs().add(7, "days").format("YYYY-MM-DD"),
          },
        },
      };
    }
  },
  methods: {
    initItem() {
      this.$axios("/api/compaign/" + this.$route.params.id).then((resp) => {
        let item = resp.data.defaultSettings;
        item.id = resp.data.id;
        item.draft = resp.data.draft;
        this.item = item;
      });
    },
    async saveCompaign(override = false) {
      let res = await this.$confirm("Cette validation va implémenter les évènements sur les agendas des participants.", { width: 720 });

      if (res) {
        if (this.item.commando && override && this.item.asUser.length === 0) {
          return null;
        }
        if (!override) {
          if (this.item.phases[1].enable && !this.item.phases[1].validTemplate) {
            this.$simpleToast.error("Campagne", "Le template d'e-mail n'existe pas.");
            document.getElementById("phases").scrollIntoView({ behavior: "smooth" });
            return;
          }
          if ((this.item.name.length === 0 || this.item.types.length === 0) && this.item.commando === 0) {
            this.$simpleToast.error("Campagne", "Le nom ou le(s) métier(s) ne sont pas renseignés");
            document.getElementById("app").scrollIntoView({ behavior: "smooth" });
            return;
          }
          if (this.item.phases[1].enable && this.item.phases[1].startDate === null) {
            this.$simpleToast.error("Campagne", "Une date doit être renseigner pour la phase 1");
            document.getElementById("phases").scrollIntoView({ behavior: "smooth" });
            return;
          }
          if (this.item.phases[2].enable && (this.item.phases[2].startDate === null || this.item.phases[2].endDate === null)) {
            this.$simpleToast.error("Campagne", "Une date doit être renseigner pour la phase 2");
            document.getElementById("phases").scrollIntoView({ behavior: "smooth" });
            return;
          }
          if (this.item.customers.length === 0) {
            this.$simpleToast.error("Campagne", "Une cible doit être sélectionné");
            document.getElementById("customer").scrollIntoView({ behavior: "smooth" });
            return;
          }
          if (this.item.contactsType === "filter" && (this.item.contactsFilter === null || this.item.contactsFilter.length === 0)) {
            this.$simpleToast.error("Campagne", "Un filtre doit être sélectionné");
            document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
            return;
          }
        }

        this.loading = true;

        if (this.item.id) {
          this.$axios
            .patch("/api/compaign/" + this.item.id, { data: this.item })
            .then(() => {
              this.loading = false;
              this.$simpleToast.success("Campagne", "La compagne a bien été modifiée");
            })
            .catch(() => {
              this.loading = false;
            });
          this.item.id = "notnull";
        } else {
          this.$axios
            .post("/api/compaign", { data: this.item })
            .then(() => {
              this.loading = false;
              //this.item.id = resp.data;
              this.$simpleToast.success("Campagne", "La compagne a bien été créée");
            })
            .catch(() => {
              this.loading = false;
            });
        }
        this.item.draft = false;

        this.$simpleToast.success(
          "Campagne",
          "La compagne est en cours d'enregistrement.<br/>Cela peut prendre quelques minutes.<br/>Merci de ne pas fermer le CRM"
        );

        this.$router.push({ name: "Campagnes" });
      }
    },
    saveDraft() {
      if (this.item.id) {
        this.$axios.patch("/api/compaign/" + this.item.id + "/draft", { data: this.item }).then(() => {
          this.$simpleToast.success("Campagne", "Le brouillon a bien été enregistré");
        });
      } else {
        this.$axios.post("/api/compaign/draft", { data: this.item }).then(() => {
          this.$simpleToast.success("Campagne", "Le brouillon a bien été enregistré");
        });
      }
    },
  },
  beforeDestroy() {
    if (this.item && this.item.draft && this.item.name.length > 0) {
      this.saveDraft();
    }
  },
  watch: {
    item: {
      handler() {
        this.simpleDebounce(() => {
          if (this.item.name.length !== 0 || this.item.types.length !== 0) {
            //this.saveCompaign(true);
          }
        }, 1000);
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
