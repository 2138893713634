import axios from "axios";
import router from "../router";
import { errorMsg } from "../utils/errorMsg";
import { simpleToast } from "./simpleToast";

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  // Autres configurations Axios
});
let toasterOnCd = false;

//AXIOS INTERCEPTOR
HTTP.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 301) {
      router.go(-1);
    }
    if (error.response.status !== 401) {
      console.warn(error.response);

      if (!toasterOnCd) {
        simpleToast.error(
          `Erreur`,
          errorMsg[error.response.status]
            ? errorMsg[error.response.status]
            : error.response.data.toString().indexOf("Cet élément est en cours d'utilisation") != -1
            ? "Client SAGE en cours d'utilisation."
            : "Une erreur est survenue."
        );
        toasterOnCd = true;
        setTimeout(() => {
          toasterOnCd = false;
        }, 5000);
      }
    } else {
      router.push({ name: "Login" }).catch(() => {});
    }
    return Promise.reject(error);
  }
);

export default HTTP;
