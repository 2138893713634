<template>
  <v-card hover @click="openOpp(item.opp.id)">
    <v-card-title>
      <template v-if="item.opp.stateId == 2">
        <div v-if="item.opp.statusId === 1" class="green--text text--darken-2 card-status--display">Gagné</div>
        <div v-if="item.opp.statusId === 2" class="red--text text--darken-2 card-status--display">Perdu</div>
      </template>
      <v-row>
        <v-col cols="12" class="py-1 my-0">
          <div style="width: 100%">
            <span
              label
              small
              :color="item.typeArray ? item.typeArray.color : ''"
              class="white--text text--darken-3 text-no-wrap card-type--label"
              :style="'background-color:' + (item.typeArray ? item.typeArray.color : '') + ';'"
            >
              <v-icon v-if="false" class="mr-1">{{ item.typeArray ? item.typeArray.icon : "" }}</v-icon>
              {{ item.typeArray ? item.typeArray.name : "" }}
            </span>
            <h6 class="primary--text">
              <!--
              <v-chip small v-if="returnCardColor(item.opp.statusId)" label :color="returnCardColor(item.opp.statusId)">
                {{ item.opp.statusId === 1 ? 'Gagné' : 'Perdu' }}
              </v-chip>
              -->
              {{ item.opp.id }} - <span style="font-size: 0.9rem; color: grey" v-if="item.entreprise">{{ item.entreprise.name }}</span>
            </h6>
          </div>
          <div>
            <h6 class="primary--text text--darken-1">{{ item.opp.name }}</h6>
          </div>
          <template cols="5" class="text-right py-1 my-0" v-if="item.typeArray"> </template>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" class="mt-2 py-1">
          <v-divider class="my-1"></v-divider>
        </v-col>
        <v-col cols="6" class="pt-0 mt-0">
          <v-rating style="margin-left: -8px" readonly small color="yellow darken-2" background-color="grey" length="4" :value="stars"></v-rating>
          <!--  <v-icon x-small :color="item.opp.probability >= 25 ? '#fdd835': ''">fa-star fa-fw</v-icon>
          <v-icon x-small :color="item.opp.probability >= 50 ? '#fdd835': ''">fa-star fa-fw</v-icon>
          <v-icon x-small :color="item.opp.probability >= 75 ? '#fdd835': ''">fa-star fa-fw</v-icon>
          <v-icon x-small :color="item.opp.probability === 100 ? '#fdd835': ''">fa-star fa-fw</v-icon>
          -->
        </v-col>
        <v-col cols="6" class="text-right py-1 my-0"
          ><span style="font-size: 1rem" v-if="item.opp.amount">{{ numStr(item.opp.amount) }} €</span>
        </v-col>
      </v-row>
      <template v-if="nextEvent">
        <v-divider class="my-1 mt-3"></v-divider>
        <v-container fluid class="pl-0">
          <v-row align="center" justify="center">
            <v-col cols="12" lg="4" class="py-1 text-center text-lg-left">
              <v-icon x-small>{{ nextEvent.event.eventtypeId === 1 ? "fa-phone" : "far fa-calendar-alt " }} fa-fw </v-icon>
              <template v-if="nextEvent.event.start">
                {{ dayjs(nextEvent.event.start).format("ddd DD/MM/YYYY HH:mm") }}
              </template>
            </v-col>
            <v-col cols="12" lg="4" class="py-1 text-center text-caption" style="line-height: 1.2em">
              <template v-if="nextEvent.event.name">
                {{ nextEvent.event.name }}
              </template>
            </v-col>
            <v-col cols="12" lg="4" class="font-italic py-1 text-center text-lg-right pr-0">
              <template v-if="nextEvent.contact">
                {{ nextEvent.contact.firstname + " " + nextEvent.contact.lastname }}
              </template>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <v-divider class="my-1 mb-2 mt-3"></v-divider>
      <v-row align="center" justify="center">
        <v-col cols="12" lg="4" class="text-center text-lg-left pt-2 pb-1">
          <UserAvatar :userId="item.opp.userId" size="35" />
        </v-col>
        <v-col cols="12" lg="4" class="text-center mt-1 pb-1">
          <template v-if="item.opp.deadline">
            <h4 :class="{ 'error--text': getComparation(item.opp.deadline, item.opp.statusId), 'mb-0 ': true }">
              {{ dayjs(item.opp.deadline).format("DD/MM/YYYY") }}
            </h4>
            <span class="my-0 py-0" v-if="item.opp.stateId == 1">{{ diffDate }}</span>
          </template>
        </v-col>
        <v-col cols="12" lg="4" class="text-center text-lg-right mt-1 pb-1">
          <v-row>
            <v-col cols="6" class="text-left text-lg-right">
              <template v-if="comments.length > 0" @click="openModalComments(comments)">
                <v-menu open-on-hover :close-on-content-click="false" left offset-x max-width="350" min-width="350" content-class="elevation-1 ">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> <v-icon class="mr-2" small>fa-comments </v-icon> {{ comments.length }} </span>
                  </template>
                  <v-card elevation="0" color="#f9fafb">
                    <v-card-title class="text-uppercase py-1 pb-2 text-body-1">Commentaires </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <div v-for="(comments, key) in comments" :key="key">
                        <v-row>
                          <v-col cols="2">
                            <UserAvatar :userId="comments.userId" size="35" />
                          </v-col>
                          <v-col cols="10">
                            <v-card elevation="1" class="pa-2">
                              <span class="font-weight-bold grey--text text--darken-2 mb-1">{{
                                dayjs(comments.createdAt).format("ddd DD/MM/YYYY à HH:mm")
                              }}</span>
                              <p class="text-caption">{{ comments.body }}</p>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-col>
            <v-col cols="6" class="text-right">
              <template v-if="item.medias > 0">
                <v-icon class="mr-2" small>fas fa-paperclip fa-fw</v-icon>
                {{ item.medias }}
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import UserAvatar from "../user/UserAvatar.vue";
import Tools from "../../mixins/Tools";

export default {
  name: "dashboardCard",
  props: ["item"],
  mixins: [Tools],
  data() {
    return {
      comments: [],
      nextEvent: null,
    };
  },
  mounted() {
    this.getComments();
    this.getNextEv();
  },
  methods: {
    getNextEv() {
      this.$axios("/api/opportunities/" + this.item.opp.id + "/nextevent").then((resp) => {
        this.nextEvent = resp.data;
      });
    },
    getComments() {
      this.$axios("/api/comments/" + this.item.opp.id).then((data) => {
        this.comments = data.data.events;
      });
    },
    getComparation(deadline, statusId) {
      let date = this.dayjs().unix();
      if (statusId) return false;
      if (this.dayjs(deadline).unix() <= date) return true;
      return false;
    },
    returnCardColor(id) {
      if (id === null) return false;
      if (id === 1) return "success";
      if (id === 2) return "error";
    },
    openOpp(id) {
      this.$router.push("/opportunities/edit/" + id);
    },
    numStr(nbr) {
      var nombre = "" + nbr;
      var retour = "";
      var count = 0;
      for (var i = nombre.length - 1; i >= 0; i--) {
        if (count != 0 && count % 3 == 0) retour = nombre[i] + " " + retour;
        else retour = nombre[i] + retour;
        count++;
      }
      let tmp = retour.split(".");
      return tmp[0];
    },
    openModalComments(comments) {
      this.comments = comments;
      this.commentsModal = true;
    },
  },
  computed: {
    diffDate() {
      let d = "-";
      if (this.item.opp.deadline) {
        let dead = this.dayjs(this.item.opp.deadline);
        let now = this.dayjs();
        d = dead.diff(now, "days");
        d = d + " jour" + (parseInt(d) > 1 || parseInt(d) < -1 ? "s" : "");
      }
      return d;
    },
    stars() {
      switch (this.item.opp.probability) {
        case 25:
          return 1;
        case 50:
          return 2;
        case 75:
          return 3;
        case 100:
          return 4;
      }
      return 0;
    },
  },
  watch: {},
  components: { UserAvatar },
};
</script>

<style scoped>
.card-type--label {
  float: right;
  overflow: hidden;
  max-width: 40%;
  font-size: 0.9rem;
  padding: 3px 7px;
  margin-right: -16px;
  border-radius: 5px 0px 0px 5px;
  font-size: 0.875rem;
  line-height: 1rem;
}
@media (min-width: 960px) and (max-width: 1264px) {
  .card-type--label {
    border-radius: 5px;
    float: none;
    display: block;
    max-width: 100%;
  }
}

.card-status--display {
  position: absolute;
  font-size: 7rem;
  line-height: 7rem;
  height: 7rem;
  overflow: hidden;
  left: 0;
  top: 3rem;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.2;
  font-weight: normal;
}
</style>
