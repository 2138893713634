<template>
  <v-row v-if="item">
    <v-col cols="6">
      <title-supervision v-model="item"></title-supervision>
      <caracteristique-supervision class="mt-14" v-model="item"></caracteristique-supervision>
    </v-col>
    <v-col cols="6">
      <software-supervision v-model="item"></software-supervision>
    </v-col>
  </v-row>
</template>

<script>
import TitleSupervision from "./titleSupervision";
import CaracteristiqueSupervision from "./caracteristiqueSupervision";
import SoftwareSupervision from "./softwareSupervision";
export default {
  name: "singleSupervision",
  components: { SoftwareSupervision, CaracteristiqueSupervision, TitleSupervision },
  data() {
    return {
      item: null,
    };
  },
  mounted() {
    this.getSupervision();
  },
  methods: {
    getSupervision() {
      this.$axios("/api/supervision/swdevices/" + this.$route.params.id).then((resp) => {
        this.item = resp.data;
      });
    },
  },
};
</script>

<style scoped></style>
