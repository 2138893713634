var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    { attrs: { title: "Bonus" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0 text-center", attrs: { cols: "4" } },
                [
                  _c("img", {
                    attrs: {
                      src: "/images/challenge/bag.svg",
                      height: "48",
                      alt: "",
                    },
                  }),
                ]
              ),
              _c("v-col", { staticClass: "py-0", attrs: { cols: "8" } }, [
                _c(
                  "div",
                  {
                    staticClass: "text-overline primary--text",
                    staticStyle: { "font-size": "1rem !important" },
                  },
                  [_vm._v(_vm._s(_vm.challenge.classement.max_gain.value.name))]
                ),
                _c("div", { staticStyle: { "font-size": "0.8em" } }, [
                  _vm.challenge.classement.max_gain.user ===
                    _vm.$store.state.user.id ||
                  _vm.isGranted("CHALLENGES_VIEW_AMOUNT")
                    ? _c("div", [
                        _vm._v(" Marge : "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              Math.round(
                                _vm.challenge.classement.max_gain.value.gain
                              ).toLocaleString("fr")
                            ) + " €"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }