var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("Landscape"),
      _c(
        "v-card",
        { staticStyle: { "z-index": "90" }, attrs: { elevation: "1" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary", dark: "", elevation: "0" } },
            [
              _c(
                "v-card-title",
                [
                  _vm._v(" Liste des contacts "),
                  _c(
                    "div",
                    { staticClass: "listbox--actions-add" },
                    [
                      _vm.$store.state.settings &&
                      !_vm.$store.state.settings.sage
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                fab: "",
                                color: "primary",
                                dark: "",
                              },
                              on: { click: _vm.addContact },
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { small: "" } },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fa-fw fa-plus")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2110646381
                                  ),
                                },
                                [_c("span", [_vm._v("Ajouter")])]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "modal",
                    {
                      attrs: { "max-width": "720" },
                      scopedSlots: _vm._u([
                        {
                          key: "actions",
                          fn: function () {
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4",
                                  attrs: {
                                    text: "",
                                    disabled: !_vm.singleContact,
                                  },
                                  on: { click: _vm.newContactSub },
                                },
                                [_vm._v(" Enregistrer ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.contactModal,
                        callback: function ($$v) {
                          _vm.contactModal = $$v
                        },
                        expression: "contactModal",
                      },
                    },
                    [
                      !_vm.singleContact
                        ? _c(
                            "v-alert",
                            {
                              attrs: { dense: "", outlined: "", type: "error" },
                            },
                            [_vm._v(" Un contact existe déjà avec cet email. ")]
                          )
                        : _vm._e(),
                      _c("contact-form", {
                        attrs: { status: false },
                        model: {
                          value: _vm.newContact,
                          callback: function ($$v) {
                            _vm.newContact = $$v
                          },
                          expression: "newContact",
                        },
                      }),
                      _vm.similar && _vm.similar.length > 0
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-divider"),
                                  _c("h4", [_vm._v("Contacts similaires")]),
                                ],
                                1
                              ),
                              _vm._l(_vm.similar, function (item, key) {
                                return _c(
                                  "v-col",
                                  {
                                    key: key,
                                    staticClass: "py-1",
                                    attrs: { cols: "6", md: "6" },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      { staticClass: "pa-2" },
                                      [
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "text-left" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "py-0",
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          "x-small": "",
                                                        },
                                                      },
                                                      [_vm._v("fa-user")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.contact
                                                            .firstname +
                                                            " " +
                                                            item.contact
                                                              .lastname
                                                        )
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "py-0",
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          "x-small": "",
                                                        },
                                                      },
                                                      [_vm._v("fa-envelope")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.contact.email
                                                        )
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "py-0",
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          "x-small": "",
                                                        },
                                                      },
                                                      [_vm._v("fa-phone")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.contact.phone
                                                        )
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "py-0",
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          "x-small": "",
                                                        },
                                                      },
                                                      [_vm._v("fa-mobile")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.contact.phone2
                                                        )
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  false
                    ? _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.modalSettings = true
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-filter")])],
                        1
                      )
                    : _vm._e(),
                  false
                    ? _c(
                        "v-menu",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { text: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("fa-cog")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2743138687
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                { on: { click: _vm.onClick } },
                                [_c("v-list-item-title", [_vm._v("Option 1")])],
                                1
                              ),
                              _c(
                                "v-list-item",
                                { attrs: { disabled: "" } },
                                [_c("v-list-item-title", [_vm._v("Option 2")])],
                                1
                              ),
                              _c(
                                "v-list-item",
                                { on: { click: _vm.onClick } },
                                [_c("v-list-item-title", [_vm._v("Option 3")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "modal",
            {
              model: {
                value: _vm.allCusto,
                callback: function ($$v) {
                  _vm.allCusto = $$v
                },
                expression: "allCusto",
              },
            },
            _vm._l(_vm.allCustoItems, function (item, key) {
              return _c(
                "v-chip",
                {
                  key: key,
                  staticClass: "my-1",
                  staticStyle: {
                    width: "100%",
                    "text-align": "center",
                    display: "block",
                  },
                  attrs: { label: "", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.loadCustomer(item.id)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-0",
            staticStyle: { "z-index": "1000" },
            attrs: {
              headers: _vm.headers,
              items: _vm.listContacts,
              page: _vm.page,
              search: _vm.researchClient,
              "items-per-page": _vm.limitItem,
              loading: _vm.isLoading,
              "server-items-length": _vm.maxItem,
              "sort-by": _vm.sortedBy,
              "sort-desc": _vm.sortedDesc,
              "footer-props": {
                showFirstLastPage: true,
                itemsPerPageOptions: [5, 10, 15, 25, 50],
                firstIcon: "fa-angle-double-left",
                lastIcon: "fa-angle-double-right",
                prevIcon: "fa-angle-left",
                nextIcon: "fa-angle-right",
              },
            },
            on: {
              "update:search": function ($event) {
                _vm.researchClient = $event
              },
              "update:sortBy": function ($event) {
                _vm.sortedBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortedBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortedDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortedDesc = $event
              },
              "update:page": _vm.setPage,
              "update:items-per-page": _vm.setItemMax,
            },
            scopedSlots: _vm._u([
              {
                key: "item.contact.customerId",
                fn: function ({ item }) {
                  return [
                    item.customer.length > 0
                      ? [
                          _c(
                            "div",
                            { staticStyle: { position: "relative" } },
                            [
                              _c(
                                "v-chip",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "text-align": "center",
                                    display: "block",
                                  },
                                  attrs: { label: "", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.loadCustomer(
                                        item.customer[0].id
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.customer[0].name) + " "
                                  ),
                                ]
                              ),
                              item.customer.length > 1
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "pa-1",
                                      staticStyle: {
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                      },
                                      attrs: {
                                        color: "primary",
                                        "x-small": "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openCustomer(item.customer)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "+" + _vm._s(item.customer.length - 1)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { icon: "" } },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-dots-vertical"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  [
                                    item.contact.emails.length
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openEmails(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("fa-fw fa-envelope")]
                                                ),
                                                _vm._v("Emails"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openEdit(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                attrs: { small: "" },
                                              },
                                              [_vm._v("fa-fw fa-edit")]
                                            ),
                                            _vm._v("Editer"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openInfo(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                attrs: { small: "" },
                                              },
                                              [_vm._v("fa-fw fa-eye")]
                                            ),
                                            _vm._v("Voir"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.isGranted("CONTACTS_ANONYME")
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleAnonymize(item)
                                              },
                                            },
                                          },
                                          [
                                            !item.contact.anonyme
                                              ? _c(
                                                  "v-list-item-title",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: { small: "" },
                                                      },
                                                      [_vm._v("fa-fw fa-mask")]
                                                    ),
                                                    _vm._v("Anonymiser "),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "v-list-item-title",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: { small: "" },
                                                      },
                                                      [_vm._v("fa-fw fa-user")]
                                                    ),
                                                    _vm._v(
                                                      "Retirer l'anonymat "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.disableItem(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                attrs: { small: "" },
                                              },
                                              [_vm._v("fa-fw fa-trash")]
                                            ),
                                            _vm._v("Supprimer"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "body.prepend",
                fn: function () {
                  return [
                    _c("tr", [
                      !_vm.mobile ? _c("td") : _vm._e(),
                      _c(
                        "td",
                        [
                          _c("v-text-field", {
                            attrs: { type: "text", label: "Filtrer" },
                            model: {
                              value: _vm.search.lastname,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "lastname", $$v)
                              },
                              expression: "search.lastname",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("v-text-field", {
                            attrs: { type: "text", label: "Filtrer" },
                            model: {
                              value: _vm.search.firstname,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "firstname", $$v)
                              },
                              expression: "search.firstname",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("v-text-field", {
                            attrs: { type: "text", label: "Filtrer" },
                            model: {
                              value: _vm.search.customer,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "customer", $$v)
                              },
                              expression: "search.customer",
                            },
                          }),
                        ],
                        1
                      ),
                      !_vm.mobile
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                attrs: { type: "text", label: "Filtrer" },
                                model: {
                                  value: _vm.search.function,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "function", $$v)
                                  },
                                  expression: "search.function",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "td",
                        [
                          _c("v-text-field", {
                            attrs: { type: "text", label: "Filtrer" },
                            model: {
                              value: _vm.search.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "phone", $$v)
                              },
                              expression: "search.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      !_vm.mobile
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                attrs: { type: "text", label: "Filtrer" },
                                model: {
                                  value: _vm.search.phone2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "phone2", $$v)
                                  },
                                  expression: "search.phone2",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.mobile
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                attrs: { type: "text", label: "Filtrer" },
                                model: {
                                  value: _vm.search.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "email", $$v)
                                  },
                                  expression: "search.email",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { "max-width": "800px" },
          scopedSlots: _vm._u(
            [
              _vm.modalItemInfo.contact
                ? {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "strong",
                          { staticClass: "text-h5 mb-0 text-uppercase" },
                          [
                            _vm._v(
                              _vm._s(_vm.modalItemInfo.contact.firstname) +
                                " " +
                                _vm._s(_vm.modalItemInfo.contact.lastname)
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
          model: {
            value: _vm.modalInfo,
            callback: function ($$v) {
              _vm.modalInfo = $$v
            },
            expression: "modalInfo",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-left", attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Fonction :")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.modalItemInfo.contact &&
                                        _vm.modalItemInfo.contact.function
                                        ? _vm.modalItemInfo.contact.function
                                        : "Non renseigné"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "ma-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("E-mail :")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.modalItemInfo.contact &&
                                        _vm.modalItemInfo.contact.email
                                        ? _vm.modalItemInfo.contact.email
                                        : "Non renseigné"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "ma-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Téléphone fixe :")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.modalItemInfo.contact &&
                                        _vm.modalItemInfo.contact.phone
                                        ? _vm.modalItemInfo.contact.phone
                                        : "Non renseigné"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "ma-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Téléphone portable :")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.modalItemInfo.contact &&
                                        _vm.modalItemInfo.contact.phone2
                                        ? _vm.modalItemInfo.contact.phone2
                                        : "Non renseigné"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      false
                        ? _c(
                            "v-col",
                            { staticClass: "ma-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Fax :")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.modalItemInfo.contact &&
                                            _vm.modalItemInfo.contact.fax
                                            ? _vm.modalItemInfo.contact.fax
                                            : "Non renseigné"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      false
                        ? _c(
                            "v-col",
                            { staticClass: "ma-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Skype :")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.modalItemInfo.contact &&
                                            _vm.modalItemInfo.contact.skype
                                            ? _vm.modalItemInfo.contact.skype
                                            : "Non renseigné"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-left", attrs: { cols: "6" } },
                [
                  _c("h3", [_vm._v("Note")]),
                  _c(
                    "v-col",
                    { staticClass: "ma-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.modalItemInfo.contact &&
                                      _vm.modalItemInfo.contact.note
                                      ? _vm.modalItemInfo.contact.note
                                      : "Aucune note"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.modalItemInfo &&
              _vm.modalItemInfo.customer &&
              _vm.modalItemInfo.customer.length > 0
                ? _c(
                    "v-col",
                    { staticClass: "ma-0", attrs: { cols: "12" } },
                    [
                      _c("span", { staticClass: "text-overline" }, [
                        _vm._v("Associations"),
                      ]),
                      _c("v-divider"),
                      _vm._l(_vm.modalItemInfo.customer, function (itm, key) {
                        return _c(
                          "v-col",
                          {
                            key: key,
                            staticClass: "pb-0",
                            attrs: { cols: "12" },
                            on: {
                              click: function ($event) {
                                return _vm.loadCustomer(itm.id)
                              },
                            },
                          },
                          [
                            _c(
                              "v-chip",
                              {
                                staticStyle: { cursor: "pointer" },
                                attrs: { small: "", label: "" },
                              },
                              [_vm._v(" " + _vm._s(itm.name) + " ")]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { "max-width": "1024px" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Edition contact"),
                  ]),
                  _c("br"),
                  _c(
                    "small",
                    { staticClass: "mt-0" },
                    [
                      _vm.modalEditInfo.contact
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(_vm.modalEditInfo.contact.firstname) +
                                " " +
                                _vm._s(_vm.modalEditInfo.contact.lastname)
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        disabled:
                          !_vm.singleContact &&
                          _vm.singleContactId !== _vm.modalEditInfo.contact.id,
                        variant: "flat",
                        color: "primary",
                        elevation: "0",
                      },
                      on: { click: _vm.validateEdit },
                    },
                    [_vm._v(" Enregistrer ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.modalEdit = false
                        },
                      },
                    },
                    [_vm._v(" Fermer ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.modalEdit,
            callback: function ($$v) {
              _vm.modalEdit = $$v
            },
            expression: "modalEdit",
          },
        },
        [
          _vm.modalEditInfo.contact
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      !_vm.singleContact &&
                      _vm.singleContactId !== _vm.modalEditInfo.contact.id
                        ? _c(
                            "v-alert",
                            {
                              attrs: {
                                color: "error",
                                outlined: "",
                                small: "",
                                prominent: "",
                                dense: "",
                                text: "",
                                type: "error",
                              },
                            },
                            [_vm._v(" Un contact existe déjà avec cet email. ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "ma-0 mt-3", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "ma-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      items: _vm.civilities,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Civilité",
                                    },
                                    model: {
                                      value:
                                        _vm.modalEditInfo.contact.civilityId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalEditInfo.contact,
                                          "civilityId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "modalEditInfo.contact.civilityId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "ma-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: { outlined: "", label: "Prénom" },
                                    model: {
                                      value:
                                        _vm.modalEditInfo.contact.firstname,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalEditInfo.contact,
                                          "firstname",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "modalEditInfo.contact.firstname",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "ma-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: { outlined: "", label: "Nom" },
                                    model: {
                                      value: _vm.modalEditInfo.contact.lastname,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalEditInfo.contact,
                                          "lastname",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "modalEditInfo.contact.lastname",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          false
                            ? _c(
                                "v-col",
                                { staticClass: "ma-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          label: "Fonction",
                                        },
                                        model: {
                                          value: _vm.modalEditInfo.role,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modalEditInfo,
                                              "role",
                                              $$v
                                            )
                                          },
                                          expression: "modalEditInfo.role",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-col", {
                            staticClass: "ma-0",
                            attrs: { cols: "12" },
                          }),
                          _c(
                            "v-col",
                            { staticClass: "ma-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-textarea", {
                                    attrs: { outlined: "", label: "Note" },
                                    model: {
                                      value: _vm.modalEditInfo.contact.note,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalEditInfo.contact,
                                          "note",
                                          $$v
                                        )
                                      },
                                      expression: "modalEditInfo.contact.note",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "ma-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "ma-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          label: "E-mail",
                                        },
                                        model: {
                                          value:
                                            _vm.modalEditInfo.contact.email,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modalEditInfo.contact,
                                              "email",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "modalEditInfo.contact.email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "ma-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          label: "Téléphone fixe",
                                        },
                                        model: {
                                          value:
                                            _vm.modalEditInfo.contact.phone,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modalEditInfo.contact,
                                              "phone",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "modalEditInfo.contact.phone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "ma-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          label: "Téléphone portable",
                                        },
                                        model: {
                                          value:
                                            _vm.modalEditInfo.contact.phone2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modalEditInfo.contact,
                                              "phone2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "modalEditInfo.contact.phone2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              false
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "ma-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              label: "Skype",
                                            },
                                            model: {
                                              value:
                                                _vm.modalEditInfo.contact.skype,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.modalEditInfo.contact,
                                                  "skype",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "modalEditInfo.contact.skype",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.modalEditInfo.customer.length > 0
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "ma-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-overline" },
                                        [_vm._v("Associations")]
                                      ),
                                      _c("v-divider"),
                                      _vm._l(
                                        _vm.modalEditInfo.customer,
                                        function (itm, key) {
                                          return _c(
                                            "v-col",
                                            {
                                              key: key,
                                              staticClass: "pb-0",
                                              attrs: { cols: "12" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.loadCustomer(itm)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    small: "",
                                                    label: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(itm.name) + " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "modal",
        {
          model: {
            value: _vm.modalSettings,
            callback: function ($$v) {
              _vm.modalSettings = $$v
            },
            expression: "modalSettings",
          },
        },
        [_c("listUser", { on: { selectChange: _vm.setAsUser } })],
        1
      ),
      _c("view-email-contact", {
        attrs: {
          contact: _vm.modalItemInfo.contact,
          emails: _vm.selectedContactEmails,
        },
        model: {
          value: _vm.modalEmail,
          callback: function ($$v) {
            _vm.modalEmail = $$v
          },
          expression: "modalEmail",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }