<template>
  <MaterialCard icon="far fa-envelope" class="mt-10" title="E-mails">
    <modal v-model="linkModal" max-width="calc(100% - 122px)">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Boîte mail</strong>
        <br /><small class="mt-0">Filtrez les dossiers...</small>
      </template>
      <div class="text-left">
        <Inbox
          v-if="linkModal"
          @close="
            getEmails();
            linkModal = false;
          "
          v-model="item"
        ></Inbox>
      </div>
    </modal>
    <div class="listbox--actions-add">
      <v-btn small color="primary" class="linkEmailBtn" fab @click="linkModal = !linkModal"><v-icon small>fa-fw fa-link</v-icon></v-btn>
    </div>

    <modal v-model="seeAll" max-width="calc(100% - 122px)">
      <template v-slot:header v-if="selectedMessage">
        <strong class="text-h5 mb-0"
          >{{ selectedMessage.sender }} <span style="font-size: 0.9rem; font-style: italic">{{ selectedMessage.senderEmail }} </span></strong
        >
        <br /><small class="mt-0">{{ selectedMessage.name }}</small>
      </template>
      <div v-if="selectedMessage" class="text-left messagesModal">
        <v-card class="pa-2" elevation="0">
          <v-row>
            <v-col cols="12" class="pa-3">
              <p v-html="selectedMessage.body"></p>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </modal>
    <div>
      <v-text-field v-model="search" label="Rechercher un e-mail"></v-text-field>
    </div>
    <div style="max-height: 500px; overflow-y: auto" v-if="emails.length > 0">
      <v-container>
        <v-timeline align-top dense>
          <v-timeline-item small v-for="(item, i) in emails" color="primary" :key="i" class="my-0 mb-4 pl-0">
            <template v-slot:icon>
              <UserAvatar :userId="item.user" size="40" />
              <span class="white" style="position: absolute; top: 55px; text-align: center; font-size: 0.8rem">
                {{ dayjs(item.linkedAt, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") }}<br />{{ dayjs(item.linkedAt, "YYYY-MM-DD HH:mm:ss").format("HH:mm") }}
              </span>
              <div style="position: absolute; top: 35px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }" style="position: absolute; top: -20px">
                    <v-icon class="white" v-bind="attrs" v-on="on" small> {{ returnIcon(item).icon }} </v-icon>
                  </template>
                  <span>{{ returnIcon(item).text }}</span>
                </v-tooltip>
              </div>
            </template>
            <template>
              <v-card>
                <v-card-title class="ma-0 pt-0">
                  <strong style="font-size: 0.9rem">
                    {{ item.name }} <span class="font-italic mx-3" style="font-size: 0.8rem">{{ item.sender }} - {{ item.senderEmail }} </span>
                  </strong>
                </v-card-title>

                <v-card-text>
                  <div class="text-caption">
                    {{ item.preview ? item.preview : "Pas de commentaire" }}
                  </div>
                </v-card-text>
                <div style="position: absolute; top: -15px; right: 5px">
                  <v-btn
                    color="primary"
                    outlined
                    fab
                    elevation="0"
                    x-small
                    class="mr-1"
                    @click="
                      selectedMessage = item;
                      seeAll = true;
                    "
                  >
                    <v-icon color="primary" x-small> far fa-fw fa-eye</v-icon>
                  </v-btn>
                  <v-btn x-small color="error" outlined fab elevation="0" class="mr-1 deleteEmailBtn" @click="deleteEmail(item.id)">
                    <v-icon color="error" x-small>fa-fw fa-trash-alt</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </template>
          </v-timeline-item>
        </v-timeline>
      </v-container>
    </div>
    <div v-else class="font-weight-light grey--text mb-5 text-center">Aucun e-mail lié</div>
  </MaterialCard>
</template>

<script>
import MaterialCard from "../common/MaterialCard";
import Tools from "../../mixins/Tools";
import Modal from "../common/modal";
import Inbox from "../../views/inbox/Inbox";
import simpleDebounce from "simple-debounce-vue-mixin";
import UserAvatar from "../user/UserAvatar.vue";

export default {
  name: "emailList",
  components: { Inbox, Modal, MaterialCard, UserAvatar },
  props: ["value"],
  mixins: [Tools, simpleDebounce],
  data() {
    return {
      selectedMessage: null,
      opportunity: this.value,
      seeAll: false,
      linkModal: false,
      item: {},
      emails: [],
      search: "",
    };
  },
  created() {},
  mounted() {
    this.item = {
      name: this.$route.name,
      id: this.$route.params.id,
    };

    this.getEmails();
  },
  methods: {
    async deleteEmail(id) {
      let res = await this.$confirm("Êtes-vous sur de vouloir délier cet e-mail ?");

      if (res) {
        this.$axios.delete("/api/email/" + id).then(() => {
          this.$simpleToast.success("Email", "Cet e-mail a bien été délier");
          this.getEmails();
        });
      }
    },
    getEmails() {
      if (this.$route.name === "Opportunité") {
        this.$axios("/api/opportunities/" + this.$route.params.id + "/emails").then((resp) => {
          this.emails = resp.data.reverse();
        });
      } else {
        this.$axios("/api/customers/" + this.$route.params.id + "/emails").then((resp) => {
          this.emails = resp.data.reverse();
        });
      }
    },
    returnIcon(item) {
      if (item.opportunity) {
        return { icon: "far fa-handshake", text: "Opportunité" };
      } else if (item.event) {
        return { icon: "far fa-calendar-alt", text: "Événement" };
      } else if (item.customer) {
        return { icon: "far fa-user-circle", text: "Compte" };
      } else if (item.contact) {
        return { icon: "fa-address-book", text: "Contact" };
      }
    },
  },
  watch: {
    search() {
      this.simpleDebounce(() => {
        this.getEmails();
      }, 500);
    },
    value() {
      this.opportunity = this.value;
    },
    item() {
      this.$emit("input", this.opportunity);
    },
  },
};
</script>

<style scoped></style>
