<template>
  <v-container fluid v-if="isGranted('INFRASTRUCTURES_INDEX')">
    <modal v-model="openFilter">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Filtrer</strong>
      </template>
      <v-switch label="Voir uniquement mes comptes" v-model="search.affect"></v-switch>
    </modal>
    <v-card style="z-index: 9" elevation="1">
      <Landscape />
      <v-toolbar color="primary" dark elevation="0">
        <v-card-title>
          Parcs Clients
          <div style="position: absolute; top: 0; left: 50%; background: #78909c; padding: 5px; translate: -50% 0; border-radius: 0 0 5px 5px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="openFilter = true" small text v-bind="attrs" v-on="on">
                  <v-icon small>fa-fw fa-filter</v-icon>
                </v-btn>
              </template>
              <span>Filtrer</span>
            </v-tooltip>
            <v-menu content-class="mt-2" offset-y right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">fa-fw fa-project-diagram </v-icon>
                    </template>
                    <span>Type de parc</span>
                  </v-tooltip>
                </v-btn>
              </template>

              <v-card>
                <v-toolbar color="primary" dark elevation="0" class="py-0" height="45px"> Type de parc </v-toolbar>
                <v-card-text class="pa-1 pb-3" style="min-width: 180px; max-height: calc(100vh - 115px); overflow-y: auto">
                  <v-list>
                    <div v-for="(item, key) in typesInfra" :key="key">
                      <v-list-item @click="toggleItemParc(item)">
                        <v-list-item-title>
                          <v-icon color="primary" v-if="currentInfra === item" small>far fa-fw fa-check-square</v-icon>
                          <v-icon v-else small>far fa-fw fa-square</v-icon>
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item>
                    </div>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-menu content-class="mt-2" :close-on-content-click="false" offset-y right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text small v-bind="attrs" v-on="on">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">fa-fw fa-cog </v-icon>
                    </template>
                    <span>Colonnes</span>
                  </v-tooltip>
                </v-btn>
              </template>

              <v-card>
                <v-toolbar color="primary" dark elevation="0" class="py-0" height="45px"> Colonnes visibles </v-toolbar>
                <v-card-text class="pa-1 pb-3" style="min-width: 180px; max-height: calc(100vh - 115px); overflow-y: auto">
                  <v-list>
                    <v-list-item style="min-height: 40px" v-for="(item, key) in columnsAvailable" :key="key" @click="toggleItem(item, key)">
                      <v-list-item-title>
                        <v-icon color="primary" v-if="checkArrayContains(item.value, headers)" small>far fa-fw fa-check-square </v-icon>
                        <v-icon v-else small>far fa-fw fa-square</v-icon>
                        <span class="ml-1">{{ item.text }}</span>
                      </v-list-item-title>
                      <v-divider></v-divider>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="listInfras"
        :page="page"
        :search.sync="researchInfra"
        :items-per-page="limitItem"
        :loading="isLoading"
        class="elevation-1"
        style="z-index: 1000"
        :server-items-length="maxItem"
        :footer-props="{ disableItemsPerPages: true }"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
        @update:page="setPage"
        @click:row="openClient"
        @update:items-per-page="setItemMax"
      >
        <template v-slot:body.prepend>
          <tr class="py-2">
            <td v-if="checkArrayContains('entreprise.name', headers)">
              <v-text-field v-model="search.customerName" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('user', headers)">
              <v-text-field v-model="search.asUser" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('infra.id', headers)"></td>
            <td v-if="checkArrayContains('infra.typeId', headers)"></td>
            <td v-if="checkArrayContains('brand.name', headers)">
              <v-text-field v-model="search.brandName" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('infra.field1', headers)">
              <v-text-field v-model="search.field1" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('model.name', headers)">
              <v-text-field v-model="search.modelName" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('infra.numberfield', headers)">
              <v-text-field v-model="search.numberfield" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('infra.field2', headers)">
              <v-text-field v-model="search.field2" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('infra.birthday', headers)">
              <v-menu
                ref="menu1"
                v-model="menuBirthdayStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="pb-0"
                    v-model="search.startDateBirthdayFormatted"
                    label="Filtrer début"
                    v-bind="attrs"
                    min="2000-01-01T00:00:00+01:00"
                    max="2000-01-01T00:00:00+01:00"
                    @blur="search.startDate = parseDate(search.startDateFormatted)"
                    :append-outer-icon="search.startBirthdayDate !== null ? 'fa-times' : ''"
                    @click:append-outer="
                      search.startBirthdayDate = null;
                      search.startDateFormatted = null;
                    "
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.startBirthdayDate" no-title @input="menuBirthdayStart = false"></v-date-picker>
              </v-menu>
              <v-menu
                ref="menu1"
                v-model="menuBirthdayEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="py-0"
                    v-model="search.endDateBirthdayFormatted"
                    label="Filtrer fin"
                    v-bind="attrs"
                    min="2000-01-01T00:00:00+01:00"
                    max="2000-01-01T00:00:00+01:00"
                    @blur="search.endBirthdayDate = parseDate(search.endDateFormatted)"
                    :append-outer-icon="search.endBirthdayDate !== null ? 'fa-times' : ''"
                    @click:append-outer="
                      search.endBirthdayDate = null;
                      search.endDateFormatted = null;
                    "
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.endBirthdayDate" no-title @input="menuBirthdayEnd = false"></v-date-picker>
              </v-menu>
            </td>
            <td v-if="checkArrayContains('infra.date', headers)">
              <v-menu ref="menu1" v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="pb-0"
                    v-model="search.startDateFormatted"
                    label="Filtrer début"
                    v-bind="attrs"
                    @blur="search.startDate = parseDate(search.startDateFormatted)"
                    :append-outer-icon="search.startDate !== null ? 'fa-times' : ''"
                    @click:append-outer="
                      search.startDate = null;
                      search.startDateFormatted = null;
                    "
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.startDate" no-title @input="menuStart = false"></v-date-picker>
              </v-menu>
              <v-menu ref="menu1" v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="py-0"
                    v-model="search.endDateFormatted"
                    label="Filtrer fin"
                    v-bind="attrs"
                    @blur="search.endDate = parseDate(search.endDateFormatted)"
                    :append-outer-icon="search.endDate !== null ? 'fa-times' : ''"
                    @click:append-outer="
                      search.endDate = null;
                      search.endDateFormatted = null;
                    "
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.endDate" no-title @input="menuEnd = false"></v-date-picker>
              </v-menu>
            </td>

            <td v-if="checkArrayContains('infra.body', headers)">
              <v-text-field v-model="search.body" type="text" label="Filtrer"></v-text-field>
            </td>
            <template v-if="itemsInfras && itemsInfras.spe && Object.keys(itemsInfras?.spe).length > 0">
              <td v-for="(field, key) in Object.keys(itemsInfras.spe)" :key="key + '-spe'">
                <v-select
                  :menu-props="{ maxHeight: '300' }"
                  item-value="id"
                  item-text="name"
                  clearable
                  :label="itemsInfras.spe[field].label"
                  v-model="itemsInfras.spe[field].value"
                  :items="itemsInfras.spe[field].items"
                  :append-outer-icon="itemsInfras.spe[field].value !== null && itemsInfras.spe[field].value !== '' ? 'fa-times' : ''"
                  @click:append-outer="itemsInfras.spe[field].value = null"
                ></v-select>
              </td>
            </template>
            <td v-if="checkArrayContains('concurrent.name', headers)">
              <v-text-field v-model="search.presta" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('entreprise.adresse.postalCode', headers)">
              <v-text-field v-model="search.sector" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('sector.name', headers)">
              <v-text-field v-model="search.sectornm" type="text" label="Filtrer"></v-text-field>
            </td>
          </tr>
        </template>
        <template v-slot:item.infra.date="{ item }">
          <template v-if="item.infra.date">
            {{ dayjs(item.infra.date).format("DD/MM/YYYY") }}
          </template>
        </template>

        <template v-slot:item.infra.birthday="{ item }">
          <template v-if="item.infra.birthday">
            {{ dayjs(item.infra.birthday).format("DD/MM") }}
          </template>
        </template>

        <template v-slot:item.entreprise.adresse.postalCode="{ item }">
          <template v-if="item.entreprise"> {{ item.entreprise.adresse.postalCode }} {{ item.entreprise.adresse.city }} </template>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small elevation="0" fab color="primary" v-bind="attrs" v-on="on" class="openMenuBtn">
                <v-icon small> fa-fw fa-ellipsis-v</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click.stop="openClient(item)">
                <v-list-item-title>
                  <v-icon x-small>far fa-user fa-fw</v-icon>
                  Consulter le client
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click.stop="editInfra(item)" class="editInfraBtn">
                <v-list-item-title>
                  <v-icon x-small>fa-edit fa-fw</v-icon>
                  Modifier
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="newOpp(item)">
                <v-list-item-title>
                  <v-icon x-small color="warning">fa-plus fa-fw</v-icon>
                  Créer une opportunité
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click.stop="removeInfra(item)">
                <v-list-item-title>
                  <v-icon x-small color="error">fa-trash-alt fa-fw</v-icon>
                  Supprimer
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <modal max-width="1024" v-model="addParcModal">
      <template v-slot:header v-if="addHeader">
        <strong class="text-h5 mb-0 text-uppercase"> {{ addHeader && addHeader.id ? "Edition" : "Ajout" }} d'un élément de parc</strong>
        <br /><small class="mt-0">
          <template> </template>
        </small>
      </template>

      <v-row v-if="addHeader">
        <v-col cols="12"> </v-col>
        <v-col cols="6" class="py-0" v-for="(field, key) in Object.keys(addHeader.classic)" :key="key">
          <template v-if="field === 'date'">
            <v-menu ref="menu1" v-model="menuDate" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="dateFormattedEdit"
                  v-bind="attrs"
                  :label="addHeader.classic[field].label"
                  @blur="dateEdit = parseDate(dateFormattedEdit)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateEdit" no-title @input="menuDate = false"></v-date-picker>
            </v-menu>
          </template>
          <template v-else-if="field === 'birthday'">
            <v-menu
              ref="menu1"
              v-model="menuBirthdayDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="dateBirthdayFormatted"
                  v-bind="attrs"
                  :label="addHeader.classic[field].label"
                  @blur="dateBirthday = parseDateWithoutYear(dateBirthdayFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker outlined v-model="dateBirthday" no-title @input="menuBirthdayDate = false"></v-date-picker>
            </v-menu>
          </template>
          <template v-else-if="field === 'body'">
            <v-textarea outlined :label="addHeader.classic[field].label" v-model="addHeader.classic[field].value"></v-textarea>
          </template>
          <template v-else>
            <v-text-field outlined :label="addHeader.classic[field].label" v-model="addHeader.classic[field].value"></v-text-field>
          </template>
        </v-col>
        <v-col cols="6" class="py-0" v-for="(field, key) in Object.keys(addHeader.spe)" :key="key + '-spe'">
          <v-select
            outlined
            clearable
            :menu-props="{ maxHeight: '300' }"
            item-value="id"
            item-text="name"
            :label="addHeader.spe[field].label"
            v-model="addHeader.spe[field].value"
            :items="addHeader.spe[field].items"
          ></v-select>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-autocomplete
            outlined
            :menu-props="{ maxHeight: '300' }"
            v-model="addHeader.concurrent"
            :items="prestatairesList"
            clearable
            :loading="isLoading"
            :search-input.sync="searchPrestataire.name"
            item-text="entreprise.name"
            item-value="entreprise.concurrents.id"
            label="Rechercher un prestataire"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <template v-slot:actions>
        <v-btn variant="flat" color="primary" elevation="0" @click="saveParcElem"> Enregistrer </v-btn>
        <v-btn color="secondary" text class="mr-4" @click="addParcModal = false"> Fermer </v-btn>
      </template>
    </modal>
  </v-container>
</template>

<script>
import Modal from "../components/common/modal";
import Tools from "../mixins/Tools";
import simpleDebounce from "simple-debounce-vue-mixin";
import dayjs from "dayjs";
import Landscape from "../components/common/Landscape.vue";

export default {
  name: "Infrastructures",
  components: { Modal, Landscape },
  mixins: [Tools, simpleDebounce],
  data() {
    return {
      openFilter: false,
      headersInfras: [],
      itemsInfras: {},
      search: {
        sectornm: "",
        affect: false,
        startBirthdayDate: null,
        startDate: null,
        startDateFormatted: null,
        startDateBirthdayFormatted: null,
        endDate: null,
        endBirthdayDate: null,
        endDateBirthdayFormatted: null,
        endDateFormatted: null,
        asUser: null,
        customerName: "",
        brandName: "",
        field1: "",
        modelName: "",
        numberfield: "",
        field2: "",
        concurrentName: "",
        body: "",
        presta: "",
        sortedDesc: false,
        sortedBy: null,
        sector: "",
      },
      limitItem: 10,
      prestatairesList: [],
      maxItem: 0,
      menuDate: false,
      dateFormattedEdit: null,
      typesInfra: [],
      menuBirthdayStart: false,
      menuBirthdayEnd: false,
      menuBirthdayDate: false,
      dateBirthdayFormatted: "",
      dateBirthday: null,
      menuStart: false,
      menuEnd: false,
      page: 1,
      sortedDesc: false,
      sortedBy: null,
      isLoading: false,
      listInfras: [],
      researchInfra: "",
      infraEdited: null,
      editionModal: false,
      loadingSave: false,
      menu: false,
      dateEdit: null,
      date: dayjs().format("YYYY-MM-DD"),
      dateFormatted: dayjs().format("DD/MM/YYYY"),
      editorsList: null,
      modelsList: null,
      columnsAvailable: [
        { text: "Client", value: "entreprise.name", sortable: false },
        { text: "Commercial", value: "user", sortable: false },
        { text: "id", value: "infra.id", sortable: false },
        { text: "Type id", value: "infra.typeId", sortable: false },
        { text: "Version", value: "infra.field1", sortable: false },
        { text: "Quantité", value: "infra.numberfield", sortable: false },
        { text: "N° Série", value: "infra.field2", sortable: false },
        { text: "Date d'anniversaire", value: "infra.birthday", sortable: false },
        { text: "Date d'échéance", value: "infra.date", sortable: false },
        { text: "Note", value: "infra.body", sortable: false },
        { text: "Prestataire", value: "concurrent.name", sortable: false },
        { text: "Localisation", value: "entreprise.adresse.postalCode", sortable: false },
        { text: "Secteur", value: "sector.name", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      defaultHeaders: [
        { text: "Client", value: "entreprise.name", sortable: false },
        { text: "Commercial", value: "user", sortable: false },
      ],
      headers: [
        { text: "Client", value: "entreprise.name", sortable: false },
        { text: "Commercial", value: "user", sortable: false },
        { text: "Version", value: "infra.field1", sortable: false },
        { text: "Quantité", value: "infra.numberfield", sortable: false },
        { text: "N° Série", value: "infra.field2", sortable: false },
        { text: "Note", value: "infra.body", sortable: false },
        { text: "Prestataire", value: "concurrent.name", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      addHeader: null,
      addParcModal: false,
      searchPrestataire: {
        name: "",
        isIndirect: null,
        siret: null,
        postalCode: null,
        city: null,
        sector: null,
        phone: null,
        ctNumAb: null,
        ape: null,
        sortedBy: null,
        typeInfras: [],
        sortedDesc: false,
      },
      currentInfra: null,
    };
  },
  mounted() {
    let search = sessionStorage.getItem("searchInfras");
    if (search) {
      this.search = JSON.parse(search);
    }

    let currentInfra = sessionStorage.getItem("curTypeInfra");
    if (currentInfra) {
      this.currentInfra = JSON.parse(currentInfra);
    }

    let headersInfras = sessionStorage.getItem("headersInfras");
    if (currentInfra) {
      this.headers = JSON.parse(headersInfras);
    }

    let itemsInfras = sessionStorage.getItem("itemsInfras");
    if (currentInfra) {
      this.itemsInfras = JSON.parse(itemsInfras);
    }

    this.getItems();
    this.getInfras();
    this.getInfrasAll();
    this.getPrestataires();
  },
  beforeDestroy() {
    sessionStorage.setItem("searchInfras", JSON.stringify(this.search));
    sessionStorage.setItem("curTypeInfra", JSON.stringify(this.currentInfra));
    sessionStorage.setItem("headersInfras", JSON.stringify(this.headers));
    sessionStorage.setItem("itemsInfras", JSON.stringify(this.itemsInfras));
  },
  methods: {
    setAsUser(event) {
      this.search.asUser = event;
    },
    toggleItemParc(val) {
      this.page = 1;
      this.headers = [];
      this.headers = JSON.parse(JSON.stringify(this.defaultHeaders));
      this.headersInfras[val.id].forEach((item) => {
        this.headers.push(item);
      });

      if (this.currentInfra && this.currentInfra.id === val.id) {
        this.currentInfra = null;
        this.search.typeInfras = [];
      } else {
        this.currentInfra = val;
      }

      this.$axios("/api/types/parc/" + val.id, { params: { order: "name" } }).then((resp) => {
        let infos = resp.data;
        let item = { classic: {}, spe: [], id: val.id };
        this.headersInfras[val.id].sort((a, b) => {
          let keyA = a.text,
            keyB = b.text;
          if (keyA === "Actions") return 1;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        this.headersInfras[val.id].forEach((itm) => {
          if (itm.value !== "concurrent.name" && itm.value !== "actions") {
            if (!itm.value.includes("infra.")) {
              item.spe.push({ label: itm.text, value: "", items: infos[itm.value] });
            }
          }

          this.itemsInfras = item;
        });
      });

      this.getInfras();
    },
    getPrestataires() {
      this.$axios("/api/concurrents/onlyPresta", {
        params: {
          page: 1,
          limit: 1000,
        },
      }).then((resp) => {
        this.prestatairesList = resp.data.data;
      });
    },
    getInfrasAll() {
      this.$axios("/api/types/infra").then((resp) => {
        this.typesInfra = resp.data;
        resp.data.forEach((item) => {
          if (item && item.settings) {
            this.headersInfras[item.id] = [];

            if (item.settings.field1.enable) {
              this.headersInfras[item.id].push({
                text: item.settings.field1.name,
                value: "infra.field1",
                sortable: false,
              });
            }
            if (item.settings.numberfield.enable) {
              this.headersInfras[item.id].push({
                text: item.settings.numberfield.name,
                value: "infra.numberfield",
                sortable: false,
              });
            }
            if (item.settings.field2.enable) {
              this.headersInfras[item.id].push({
                text: item.settings.field2.name,
                value: "infra.field2",
                sortable: false,
              });
            }
            if (item.settings.date.enable) {
              this.headersInfras[item.id].push({ text: item.settings.date.name, value: "infra.date", sortable: false });
            }
            if (item.settings.body.enable) {
              this.headersInfras[item.id].push({ text: item.settings.body.name, value: "infra.body", sortable: false });
            }
          } else {
            this.headersInfras[item.id] = [
              //   { text: 'id', value: 'infra.id', sortable: false },
              { text: "Version", value: "infra.field1", sortable: false },
              { text: "Quantité", value: "infra.numberfield", sortable: false },
              { text: "N° License / série", value: "infra.field2", sortable: false },
              { text: "Date anniversaire", value: "infra.date", sortable: false },
              //{ text: 'Prestataire', value: 'concurrent.name', sortable: false },
              { text: "Note", value: "infra.body", sortable: false },
            ];
          }
        });
        let tmp = {};
        this.$axios("/api/types/parc").then((res) => {
          res.data.parc.forEach((item) => {
            if (!tmp[item.type.id]) {
              tmp[item.type.id] = [];
            }
            this.headersInfras[item.type.id].push({ text: item.name, value: item.slug, sortable: false });
          });

          Object.keys(this.headersInfras).forEach((key) => {
            this.headersInfras[key].push({ text: "Prestataire", value: "concurrent.name", sortable: false });
            this.headersInfras[key].push({ text: "Actions", value: "actions", sortable: false });
          });
          this.parcTypes = tmp;
          this.infras = resp.data;
        });
      });
    },
    saveParcElem() {
      if (this.addHeader.itemid) {
        this.$axios.patch("/api/customers/" + this.addHeader.customer + "/parc/" + this.addHeader.itemid, { data: this.addHeader }).then(() => {
          this.getInfras();
          this.addParcModal = false;
          this.$simpleToast.success("Parc", "L'élément a bien été modifié");
        });
      } else {
        this.$axios.post("/api/customers/" + this.addHeader.customer + "/parc", { data: this.addHeader }).then(() => {
          this.getInfras();
          this.addParcModal = false;

          this.$simpleToast.success("Parc", "L'élément a bien été ajouté");
        });
      }
    },
    toggleItem(item, key) {
      let index = this.headers.findIndex((x) => x.value === item.value);
      let tmp = key + 1;
      let valI = -1;
      while (valI === -1 && tmp < this.columnsAvailable.length) {
        valI = this.headers.findIndex((x) => x.value === this.columnsAvailable[tmp].value);
        tmp += 1;
      }
      if (index === -1) {
        if (key === 0) {
          this.headers.splice(0, 0, item);
        } else if (key === this.columnsAvailable.length - 1 || valI === -1) {
          this.headers.push(this.columnsAvailable[key]);
        } else {
          this.headers.splice(valI, 0, item);
        }
      } else {
        this.headers.splice(index, 1);
      }
      this.$forceUpdate();
    },
    newOpp(item) {
      this.$axios.post("/api/opportunities", { data: item }).then((res) => {
        this.$router.push({ name: "Opportunité", params: { id: res.data.opp.id } });
      });
    },
    getItems() {
      this.$axios("/api/infrastructures/editor").then((resp) => {
        this.editorsList = resp.data;
      });
      this.$axios("/api/infrastructures/models").then((resp) => {
        this.modelsList = resp.data;
      });
    },
    saveEdit() {
      this.infraEdited.item.date = this.date;
      this.$axios.patch("/api/infrastructures/" + this.infraEdited.item.id, { data: this.infraEdited }).then(() => {
        this.$simpleToast.success("Parc", "La ligne a bien été modifiée.");
        this.getInfras();
        this.editionModal = false;
      });
    },
    async removeInfra(infra) {
      const res = await this.$confirm("Êtes-vous sur ?", { title: "Confirmation", color: "primary" });
      if (res) {
        this.$axios.delete("/api/infrastructures/" + infra.item.id).then(() => {
          this.$simpleToast.success("Parc", "La ligne a bien été supprimée");
          this.getInfras();
        });
      }
    },
    editInfra(row) {
      let infra = row.infra;
      this.search.name = row.concurrent?.name ? row.concurrent.name : "";

      this.$axios("/api/types/parc/" + infra.typeId).then((resp) => {
        let item = {
          classic: {},
          spe: {},
          itemid: infra.id,
          id: infra.typeId,
          customer: row.entreprise.id,
        };
        item.concurrent = infra.concurrentId;
        if (infra.settings) {
          if (infra.settings.field1.enable) {
            item.classic.field1 = { label: infra.settings.field1.name, value: infra.field1 };
          }
          if (infra.settings.numberfield.enable) {
            item.classic.numberfield = { label: infra.settings.numberfield.name, value: infra.numberfield };
          }
          if (infra.settings.field2.enable) {
            item.classic.field2 = { label: infra.settings.field2.name, value: infra.field2 };
          }
          if (infra.settings.date.enable) {
            item.classic.date = {
              label: infra.settings.date.name,
              value: infra.date ? dayjs(infra.date).format("YYYY-MM-DD") : null,
            };
            if (infra.date) {
              this.dateFormattedEdit = infra.date ? dayjs(infra.date).format("YYYY-MM-DD") : null;
            }
          }
          if (infra.settings.body.enable) {
            item.classic.body = { label: infra.settings.body.name, value: row.item.body };
          }
          if (infra.settings.birthday.enable) {
            item.classic.birthday = {
              label: infra.settings.birthday.name,
              value: infra.birthday ? dayjs(infra.birthday).format("YYYY-MM-DD") : null,
            };
            this.dateBirthday = infra.birthday ? dayjs(infra.birthday).format("YYYY-MM-DD") : null;
            if (infra.birthday) {
              this.dateBirthdayFormatted = dayjs(infra.birthday).format("YYYY-MM-DD");
            }
          }
        } else {
          item.classic.field1 = { label: "Version", value: infra.field1 };
          item.classic.numberfield = { label: "Quantité", value: infra.numberfield };
          item.classic.field2 = { label: "N° License / série", value: infra.field2 };
          item.classic.date = {
            label: "Date d'anniversaire",
            value: dayjs(infra.date).format("YYYY-MM-DD") === "Invalid Date" ? null : dayjs(infra.date).format("YYYY-MM-DD"),
          };
          this.dateEdit = infra.date ? dayjs(infra.date).format("YYYY-MM-DD") : null;

          item.classic.body = { label: "Note", value: infra.body };
        }

        Object.keys(resp.data).forEach((elem) => {
          let value = null;
          let label = null;

          resp.data[elem].forEach((i) => {
            if (row[elem] === i.name) {
              value = i.id;
            }
          });

          this.headersInfras[infra.typeId].forEach((head) => {
            if (head.value === elem) {
              label = head.text;
            }
          });
          item.spe[elem] = { label: label, items: resp.data[elem], value: value };
        });
        this.addHeader = item;
        this.addParcModal = true;
      });
    },
    openClient(item) {
      this.$router.push({ name: "Client", params: { id: item.entreprise.id } });
    },
    getInfras() {
      this.isLoading = true;
      let search = this.search;
      let page = this.page;
      let type = null;
      if (this.currentInfra) {
        type = this.currentInfra.id;
      }
      this.$axios("/api/infrastructures", {
        params: {
          page: page,
          search,
          limit: this.limitItem,
          asUser: this.asUser,
          type: type,
        },
      }).then((res) => {
        this.listInfras = res.data.data;
        this.maxItem = res.data.max;
        this.isLoading = false;
      });
    },
    setItemMax(event) {
      this.limitItem = event;
    },
    setPage(event) {
      this.page = event;
    },
    parseDateWithoutYear(date) {
      if (!date) return null;

      let data = date.split("/");
      return `${2000}-${data[1].padStart(2, "0")}-${data[0].padStart(2, "0")}`;
    },
    getInfrasDebounce() {
      this.simpleDebounce(() => {
        this.getInfras();
      }, 500);
    },
    getInfrasDebouncePage1() {
      this.page = 1;
      this.simpleDebounce(() => {
        this.getInfras();
      }, 500);
    },
  },
  watch: {
    "search.affect": "getInfrasDebounce",
    "search.sectornm": "getInfrasDebounce",
    "search.customerName": "getInfrasDebouncePage1",
    "search.brandName": "getInfrasDebouncePage1",
    "search.field1": "getInfrasDebouncePage1",
    "search.presta": "getInfrasDebouncePage1",
    "search.sector": "getInfrasDebouncePage1",
    "search.asUser": "getInfrasDebouncePage1",
    "search.modelName": "getInfrasDebouncePage1",
    "search.numberfield": "getInfrasDebouncePage1",
    "search.field2": "getInfrasDebouncePage1",
    "search.body": "getInfrasDebouncePage1",
    // "searchPrestataire.name"() {
    //   this.simpleDebounce(() => {
    //     this.getPrestataires();
    //   }, 500);
    // },
    dateEdit() {
      this.dateFormattedEdit = this.dateEdit ? dayjs(this.dateEdit).format("DD/MM/YYYY") : null;
      this.addHeader.classic.date.value = this.dateEdit;
    },
    dateBirthday() {
      this.dateBirthdayFormatted = this.dateBirthday ? dayjs(this.dateBirthday).format("DD/MM") : null;
      this.addHeader.classic.birthday.value = this.dateBirthday;
    },
    "search.startBirthdayDate"() {
      this.search.startDateBirthdayFormatted = this.search.startBirthdayDate ? dayjs(this.search.startBirthdayDate).format("DD/MM") : null;
      this.getInfrasDebounce();
    },
    "search.endBirthdayDate"() {
      this.search.endDateBirthdayFormatted = this.search.endBirthdayDate ? dayjs(this.search.endBirthdayDate).format("DD/MM") : null;
      this.getInfrasDebounce();
    },
    "search.startDate"() {
      this.search.startDateFormatted = this.search.startDate ? dayjs(this.search.startDate).format("DD-MM-YYYY") : null;
      this.getInfrasDebounce();
    },

    "search.endDate"() {
      this.search.endDateFormatted = this.search.endDate ? dayjs(this.search.endDate).format("DD/MM/YYYY") : null;
      this.getInfrasDebounce();
    },
    "itemsInfras.spe": {
      deep: true,
      handler() {
        this.simpleDebounce(() => {
          this.search.typeInfras = [];
          if (this.itemsInfras?.spe) {
            for (const item of this.itemsInfras.spe) {
              if (item.value) {
                this.search.typeInfras.push(item.value);
              }
            }
          }
          this.getInfras();
        });
      },
    },
    date() {
      this.dateFormatted = dayjs(this.date).format("DD/MM/YYYY");
    },
    page() {
      this.getInfras();
    },
    sortedBy() {
      this.search.sortedBy = this.sortedBy;
      this.getInfrasDebounce();
    },
    sortedDesc() {
      this.search.sortedDesc = this.sortedDesc;
      this.getInfrasDebounce();
    },
  },
};
</script>

<style scoped></style>
