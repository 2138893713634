<template>
  <v-autocomplete
    :menu-props="{ maxHeight: '300' }"
    v-model="selectedConcurrent"
    :items="concurrentsList"
    :loading="isLoading"
    :search-input.sync="search.name"
    item-text="entreprise.name"
    item-value="entreprise.concurrents.id"
    label="Rechercher un concurrent"
    :prepend-icon="icon.default"
  ></v-autocomplete>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";

export default {
  name: "selectConcurrents",
  props: {
    value: {},

    icon: {
      default: "fa-users",
    },
  },
  mixins: [simpleDebounce],
  data() {
    return {
      isLoading: false,
      search: {
        name: "",
        isIndirect: null,
        isKey1: null,
        isKey2: null,
        isKey3: null,
        isKey4: null,
        siret: null,
        postalCode: null,
        city: null,
        sector: null,
        phone: null,
        ctNumAb: null,
        ape: null,
        sortedBy: null,
        sortedDesc: false,
      },
      selectedConcurrent: this.value,
      concurrentsList: [],
    };
  },
  methods: {
    getConcurrents() {
      let search = this.search;
      this.$axios("/api/concurrents/list", { params: { page: 1, search, limit: 5, allAgency: false, allUser: true } }).then((resp) => {
        this.concurrentsList = resp.data.data;
      });
    },
  },
  watch: {
    value() {
      this.selectedConcurrent = this.value;
    },
    selectedConcurrent() {
      this.$emit("input", this.selectedConcurrent);
    },
    "search.name"() {
      this.simpleDebounce(() => {
        this.getConcurrents();
      }, 500);
    },
  },
};
</script>

<style scoped></style>
