import "vue-toast-notification/dist/theme-sugar.css";
import "vue-easytable/libs/theme-default/index.css"; // import style
import "@jledentu/vue-finder/dist/vue-finder.css";
import "leaflet/dist/leaflet.css";
import "./assets/global.css";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VuetifyConfirm from "vuetify-confirm";
import VueToast from "vue-toast-notification";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import store from "./store/store";
import HTTP from "./config/axios";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import TreeView from "vue-json-tree-view";
import * as msal from "@azure/msal-browser";
import { msalConfig } from "./config/msalConfig";
import { simpleToast } from "./config/simpleToast";
import { VeTable, VePagination, VeIcon, VeLoading, VeLocale } from "vue-easytable";

// DAYJS
dayjs.extend(utc);
dayjs.extend(timezone);

//MSAL
const msalInstance = new msal.PublicClientApplication(msalConfig);

// Vue plugins et autres
Vue.config.productionTip = false;
Vue.use(CKEditor);
Vue.use(TreeView);
Vue.use(VeTable);
Vue.use(VePagination);
Vue.use(VeIcon);
Vue.use(VeLoading);
Vue.use(VueToast);
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: "Confirmer",
  buttonFalseText: "Annuler",
  buttonTrueFlat: false,
  buttonTrueColor: "primary",
  color: "primary",
  title: "Confirmation",
  width: 380,
  property: "$confirm",
});

Vue.prototype.$msal = msalInstance;
Vue.prototype.$axios = HTTP;
Vue.prototype.$toast = Vue.$toast;
Vue.prototype.$simpleToast = simpleToast;
Vue.prototype.$veLoading = VeLoading;
Vue.prototype.$veLocale = VeLocale;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
