<template>
  <v-container fluid>
    <modal v-model="editModal">
      <template v-slot:header>{{ item.id ? "Edition de la fonction" : "Ajout d'une fonction" }}</template>
      <v-text-field outlined class="mt-5" label="Nom du métier" v-model="item.name"></v-text-field>
      <template v-slot:actions>
        <v-btn color="primary" @click="saveJobs">Enregistrer</v-btn>
      </template>
    </modal>
    <v-card style="z-index: 99">
      <v-toolbar color="primary " dark elevation="0">
        <v-row>
          <v-col cols="11">
            <v-text-field v-model="search" label="Rechercher" single-line prepend-icon="fa-search" hide-details></v-text-field>
          </v-col>
          <v-col cols="1" class="text-center">
            <v-btn
              @click="
                item = { name: '' };
                editModal = true;
              "
              color="primary"
              small
              fab
              class="listbox--actions-add"
            >
              <v-icon small>fa-plus fa-fw</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-container fluid>
        <v-data-table :headers="headers" :items="jobs" :search="search">
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="editJob(item)">fa-edit fa-fw</v-icon>
            <v-icon small class="ml-2" @click="deleteJob(item)" color="error">fa-trash-alt fa-fw</v-icon>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Modal from "../../components/common/modal";
export default {
  name: "fonctionSettings",
  components: { Modal },
  data() {
    return {
      editModal: false,
      search: "",
      item: { name: "" },
      jobs: [],
      headers: [
        { text: "Nom", value: "name" },
        { text: "Actions", value: "actions", align: "right" },
      ],
    };
  },
  mounted() {
    this.getJobs();
  },
  methods: {
    async deleteJob(item) {
      let res = await this.$confirm("Êtes-vous sûr de vouloir supprimé l'élément?");

      if (res) {
        this.$axios.delete("/api/jobs/" + item.id).then(() => {
          this.$simpleToast.success("Métier", "L'élément a bien été supprimé");
          this.getJobs();
        });
      }
    },
    editJob(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.editModal = true;
    },
    saveJobs() {
      if (this.item.id) {
        this.$axios.patch("/api/jobs/" + this.item.id, { data: this.item }).then(() => {
          this.getJobs();
          this.$simpleToast.success("Métier", "L'élément a bien été modifié");
          this.editModal = false;
        });
      } else {
        this.$axios.post("/api/jobs", { data: this.item }).then(() => {
          this.getJobs();
          this.$simpleToast.success("Métier", "L'élément a bien été ajouté");
          this.editModal = false;
        });
      }
    },
    getJobs() {
      this.$axios.get("/api/jobs").then((resp) => {
        this.jobs = resp.data;
      });
    },
  },
};
</script>

<style scoped></style>
