<template>
  <v-container fluid>
    <v-card v-if="isGranted('OPPORTUNITIES_INDEX')" style="z-index: 99">
      <v-toolbar color="primary" dark elevation="0">
        <v-card-title>
          <div class="listbox--actions-add">
            <v-btn @click="newOpp" small fab color="primary" dark>
              <v-icon small>fa-plus</v-icon>
            </v-btn>
          </div>
          {{ search.all ? "Toutes les opportunités" : asUser !== null ? "Opportunités" : "Mes opportunités" }}
          <div
            :style="
              isMobile()
                ? {
                    marginLeft: '20px',
                  }
                : {
                    position: 'absolute',
                    top: '0',
                    left: '50%',
                    background: '#78909c',
                    padding: '5px',
                    transform: 'translate(-50%)',
                    borderRadius: '0 0 5px 5px',
                  }
            "
          >
            <v-btn v-if="isGranted('OPPORTUNITIES_INDEX_ALLSERVICE')" text @click="modalSettings = true" small>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small> fa-fw fa-filter </v-icon>
                </template>
                <span>Filtrer</span>
              </v-tooltip>
            </v-btn>

            <v-menu content-class="mt-2 pa-0" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }" style="max-height: none">
                <v-btn text v-bind="attrs" v-on="on" small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small> fa-fw fa-cog </v-icon>
                    </template>
                    <span>Colonnes</span>
                  </v-tooltip>
                </v-btn>
              </template>
              <v-card>
                <v-toolbar color="primary" dark elevation="0" class="py-0" height="45px"> Colonnes visibles </v-toolbar>
                <v-card-text class="pa-1 pb-3" style="min-width: 180px; max-height: calc(100vh - 115px); overflow-y: auto">
                  <v-list>
                    <v-list-item style="min-height: 40px" v-for="(item, key) in columnsAvailable" :key="key" @click="toggleItem(item, key)">
                      <v-list-item-title>
                        <v-icon color="primary" v-if="checkArrayContains(item.value, headers)" small>far fa-fw fa-check-square </v-icon>
                        <v-icon v-else small>far fa-fw fa-square</v-icon>
                        <span class="ml-1">{{ item.text }}</span>
                      </v-list-item-title>
                      <v-divider></v-divider>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="listOpp"
        :page="page"
        :search.sync="customerSearch.name"
        :items-per-page="limitItem"
        :loading="isLoading"
        class="elevation-0"
        style="z-index: 1000"
        :server-items-length="maxItem"
        @click:row="loadOpportunity"
        @update:page="setPage"
        @update:items-per-page="setItemMax"
        @update:options="setPageOptions"
        :options="tableOptions"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 15, 25, 50],
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right',
        }"
      >
        >
        <template v-slot:item.item.deadline="{ item }">
          <template v-if="item.item.deadline">
            <span class="px-2">
              {{ dayjs(item.item.deadline).format("DD/MM/YYYY") }}
            </span>
          </template>
          <template v-else>
            <v-chip color="error" small label>Sans échéance</v-chip>
          </template>
        </template>

        <template v-slot:item.item.createdAt="{ item }">
          <template v-if="item.item.createdAt">
            {{ dayjs(item.item.createdAt).format("DD/MM/YYYY") }}
          </template>
          <template v-else>
            <v-chip small color="error">Sans date</v-chip>
          </template>
        </template>

        <template v-slot:item.item.stateId="{ item }">
          <v-icon v-if="item.item.statusId !== null" small label :color="item.item.statusId === 1 ? 'success' : 'error'">
            {{ item.item.statusId === 1 ? "fa-thumbs-up" : "fa-thumbs-down" }}
          </v-icon>

          <v-icon v-else small :color="item.item.stateId === 1 ? '' : 'error'">
            {{ item.item.stateId === 1 ? "fas fa-file-signature" : "fa-thumbs-down" }}
          </v-icon>
        </template>

        <template v-slot:item.item.statusId="{ item }">
          <div v-if="item.item.statusId !== null">
            <v-chip v-if="item.item.statusId !== null" label small :color="item.item.statusId === 1 ? 'success' : 'error'">
              {{ item.item.statusId === 1 ? "Gagné" : "Perdu" }}
            </v-chip>
          </div>
        </template>

        <template v-slot:item.item.stepId="{ item }">
          <v-chip
            v-if="item.item.stepId && step[item.item.stepId]"
            label
            small
            style="width: 100px; margin-left: auto; margin-right: auto; text-align: center; display: block"
          >
            {{ step[item.item.stepId].name }}
          </v-chip>
        </template>
        <template v-slot:item.item.categoryId="{ item }">
          <template v-if="item.item.categoryId && listCat[item.item.categoryId]">
            {{ listCat[item.item.categoryId].name }}
          </template>
        </template>
        <template v-slot:item.array.paction="{ item }">
          <v-container v-if="item.array && item.array.paction">
            <v-card x-small color="transparent" elevation="0" class="pa-0 ma-0">
              <v-card-title small class="text-subtitle-2 text-no-wrap ma-0 pa-0 px-1 grey--text text--darken-3">
                <span class="text-no-wrap">
                  <v-icon x-small class="mr-2">{{ item.array.paction.type.icon }} fa-fw</v-icon>{{ dayjs(item.array.paction.event.start).format("DD/MM/YYYY") }}
                  {{ dayjs(item.array.paction.event.start).format("HH:mm") }}
                </span>
              </v-card-title>
              <v-card-text class="text-caption ma-0 pa-1">
                <UserAvatar :userId="item.array.paction.event.userId" size="18" />
                {{ item.array.paction.event.name }}
              </v-card-text>
            </v-card>
          </v-container>
        </template>

        <template v-slot:item.array.daction="{ item }">
          <v-container v-if="item.array && item.array.daction">
            <v-card x-small color="transparent" elevation="0" class="pa-0 ma-0">
              <v-card-title small class="text-subtitle-2 text-no-wrap ma-0 pa-0 px-1 grey--text text--darken-3">
                <span class="text-no-wrap"
                  ><v-icon x-small class="mr-2">{{ item.array.daction.type.icon }} fa-fw</v-icon
                  >{{ dayjs(item.array.daction.event.start).format("DD/MM/YYYY") }}
                  {{ dayjs(item.array.daction.event.start).format("HH:mm") }}
                </span>
              </v-card-title>
              <v-card-text class="text-caption ma-0 pa-1">
                <UserAvatar :userId="item.array.daction.event.userId" size="18" />
                {{ item.array.daction.event.name }}
              </v-card-text>
            </v-card>
          </v-container>
        </template>

        <template v-slot:item.item.typeId="{ item }">
          <template v-if="item.item.typeId && typeListObj[item.item.typeId]">
            {{ typeListObj[item.item.typeId].name }}
          </template>
        </template>

        <template v-slot:item.item.probability="{ item }">
          <v-progress-circular
            :rotate="360"
            :size="40"
            :width="5"
            :value="item.item.probability ? item.item.probability : '0'"
            :color="item.item.probability > 50 ? 'success' : 'error'"
          >
            {{ item.item.probability ? item.item.probability + "%" : "0" + "%" }}
          </v-progress-circular>
        </template>

        <template v-slot:item.item.amount="{ item }">
          {{ item.item.amount && parseInt(item.item.amount) > 0 ? Math.round(item.item.amount) : "" }}
        </template>

        <template v-slot:item.item.customerId="{ item }">
          <template v-if="item.item.customerId">
            {{ item.entreprise.name }}
          </template>
          <template v-if="!item.item.customerId"> Non rattaché </template>
        </template>
        <template v-slot:item.campagne.name="{ item }">
          {{ item.campagne?.name }}
        </template>

        <template v-slot:body.prepend v-if="!isMobile()">
          <tr>
            <td v-if="checkArrayContains('item.id', headers)">
              <v-text-field v-model="search.id" type="number" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('item.createdAt', headers)"></td>
            <td v-if="checkArrayContains('item.deadline', headers)">
              <v-menu ref="menu1" v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="pb-0"
                    v-model="search.startDateFormatted"
                    label="Filtrer début"
                    v-bind="attrs"
                    @blur="search.startDate = parseDate(search.startDateFormatted)"
                    :append-outer-icon="search.startDate !== null ? 'fa-times' : ''"
                    @click:append-outer="
                      search.startDate = null;
                      search.startDateFormatted = null;
                    "
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.startDate" no-title @input="menuStart = false"></v-date-picker>
              </v-menu>
              <v-menu ref="menu1" v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="py-0"
                    v-model="search.endDateFormatted"
                    label="Filtrer fin"
                    v-bind="attrs"
                    @blur="search.endDate = parseDate(search.endDateFormatted)"
                    :append-outer-icon="search.endDate !== null ? 'fa-times' : ''"
                    @click:append-outer="
                      search.endDate = null;
                      search.endDateFormatted = null;
                    "
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.endDate" no-title @input="menuEnd = false"></v-date-picker>
              </v-menu>
            </td>
            <td v-if="checkArrayContains('item.stateId', headers)">
              <v-select
                :items="stateArray"
                item-text="name"
                item-value="id"
                label="Filtrer"
                v-model="search.state"
                :append-outer-icon="search.state !== null ? 'fa-times' : ''"
                @click:append-outer="search.state = null"
              ></v-select>
            </td>
            <td v-if="checkArrayContains('item.statusId', headers)">
              <v-select
                :items="statusArray"
                item-text="name"
                item-value="id"
                label="Filtrer"
                v-model="search.status"
                :append-outer-icon="search.status !== null ? 'fa-times' : ''"
                @click:append-outer="search.status = null"
              ></v-select>
            </td>
            <td v-if="checkArrayContains('item.stepId', headers)">
              <v-select
                multiple
                :items="stepArray"
                item-text="name"
                item-value="id"
                label="Filtrer"
                v-model="search.step"
                :append-outer-icon="search.step !== null ? 'fa-times' : ''"
                @click:append-outer="search.step = null"
              ></v-select>
            </td>
            <td v-if="checkArrayContains('item.typeId', headers)">
              <v-autocomplete
                :menu-props="{ maxHeight: '300 !important' }"
                v-model="search.type"
                :items="listTypeArray"
                :search-input.sync="searchType"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="id"
                label="Filtrer"
                :append-outer-icon="search.type !== null ? 'fa-times' : ''"
                @click:append-outer="search.type = null"
              ></v-autocomplete>
            </td>
            <td v-if="checkArrayContains('item.categoryId', headers)">
              <v-autocomplete
                :menu-props="{ maxHeight: '300' }"
                v-model="search.categories"
                :items="listCatArray"
                :search-input.sync="searchCat"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="id"
                label="Filtrer"
                :append-outer-icon="search.categories !== null ? 'fa-times' : ''"
                @click:append-outer="search.categories = null"
              ></v-autocomplete>
            </td>

            <td v-if="checkArrayContains('item.probability', headers)"></td>

            <td v-if="checkArrayContains('item.amount', headers)"></td>
            <td v-if="checkArrayContains('array.daction', headers)"></td>
            <td v-if="checkArrayContains('array.paction', headers)"></td>
            <td v-if="checkArrayContains('item.com', headers)"></td>
            <td v-if="checkArrayContains('array.commercial', headers)">
              <list-user label="Filtrer" @selectChange="setAsFilter" v-model="search.commercial" :user-multiple="search.commercial"></list-user>
            </td>
            <td v-if="checkArrayContains('item.customerId', headers)">
              <v-autocomplete
                :menu-props="{ maxHeight: '300' }"
                v-model="search.client"
                :items="listClient"
                :search-input.sync="customerSearch.name"
                hide-no-data
                hide-selected
                item-text="entreprise.name"
                item-value="customer.id"
                label="Filtrer"
                :append-outer-icon="search.client !== null ? 'fa-times' : ''"
                @click:append-outer="search.client = null"
              ></v-autocomplete>
            </td>

            <td v-if="checkArrayContains('item.name', headers)">
              <v-text-field v-model="search.name" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('entreprise.adresse.postalCode', headers)">
              <v-text-field v-model="search.postalCode" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('campagne.name', headers)">
              <v-autocomplete
                :menu-props="{ maxHeight: '300' }"
                v-model="search.campagne"
                :items="compaigns"
                item-text="name"
                item-value="id"
                label="Filtrer"
                :append-outer-icon="search.campagne !== null ? 'fa-times' : ''"
                @click:append-outer="search.campagne = null"
              ></v-autocomplete>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <modal v-model="modalSettings">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Filtrer</strong>
      </template>
      <listUser v-if="isGranted('OPPORTUNITIES_INDEX_ALLSERVICE')" @selectChange="setAsUser"></listUser>
      <v-switch v-if="isGranted('OPPORTUNITIES_INDEX_ALLSERVICE')" v-model="search.all" label="Voir toutes les opportunités"></v-switch>
    </modal>
  </v-container>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import listUser from "../../components/user/listUser";
import modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";
import savePage from "../../mixins/savePage";
import UserAvatar from "../../components/user/UserAvatar.vue";
import MobileDetection from "../../mixins/MobileDetection";

export default {
  name: "listOpportunity",
  components: { modal, listUser, UserAvatar },
  mixins: [simpleDebounce, Tools, savePage, MobileDetection],
  data() {
    return {
      mobile: false,
      isLoading: false,
      listOpp: [],
      listCat: [],
      compaigns: [],
      sortedBy: null,
      sortedDesc: false,
      menu: false,
      listCatArray: [],
      calories: 0,
      searchType: "",
      limitItem: 10,
      maxItem: 0,
      page: 1,
      listClient: [],
      listType: [],
      listTypeArray: [],
      asUser: null,
      itemNbr: 10,
      searchCat: "",
      modalSettings: false,
      menuStart: false,
      menuEnd: false,
      customerSearch: {
        name: "",
      },
      search: {
        startDate: null,
        startDateFormatted: null,
        endDate: null,
        endDateFormatted: null,
        id: null,
        deadline: null,
        state: null,
        status: null,
        step: null,
        type: null,
        categories: null,
        probability: null,
        commercial: null,
        name: "",
        client: null,
        sortedBy: null,
        sortedDesc: false,
        userId: false,
        all: false,
        postalCode: null,
        campagne: null,
      },
      searchCompaigns: {
        id: null,
        name: null,
        types: null,
        dateStart: null,
        dateEnd: null,
        status: null,
        typeComp: null,
      },
      stateArray: [
        {
          id: 1,
          name: "Ouvert",
        },
        {
          id: 2,
          name: "Fermée",
        },
      ],
      statusArray: [
        {
          id: 1,
          name: "Gagné",
        },
        {
          id: 2,
          name: "Perdu",
        },
      ],
      stepArray: [
        {
          id: 1,
          name: "Identifié",
        },
        {
          id: 2,
          name: "Étude",
        },
        {
          id: 3,
          name: "Négociation",
        },
      ],
      step: {
        1: {
          name: "Identifié",
        },
        2: {
          name: "Étude",
        },
        3: {
          name: "Négociation",
        },
      },
      typeListObj: {},
      columnsAvailable: [
        { text: "Numero", value: "item.id", class: "text-no-wrap" },
        { text: "Date", value: "item.createdAt", class: "text-no-wrap" },
        { text: "Échéance", value: "item.deadline", class: "text-no-wrap" },
        { text: "État", value: "item.stateId", align: "center", class: "text-no-wrap" },
        { text: "Résultat", value: "item.statusId", align: "center", class: "text-no-wrap" },
        { text: "Étape", value: "item.stepId", align: "center", class: "text-no-wrap" },
        { text: "Métier", value: "item.typeId", class: "text-no-wrap" },
        { text: "Classement", value: "item.categoryId", class: "text-no-wrap" },
        { text: "Probabilité", value: "item.probability", align: "center", class: "text-no-wrap" },
        { text: "Montant", value: "item.amount", align: "right", class: "text-no-wrap" },
        { text: "D. action", value: "array.daction", class: "text-no-wrap" },
        { text: "P. action", value: "array.paction", class: "text-no-wrap" },
        { text: "Commercial", value: "array.commercial", class: "text-no-wrap" },
        { text: "Client", value: "item.customerId", class: "text-no-wrap" },
        { text: "Sujet", value: "item.name", class: "text-no-wrap" },
        { text: "Code postal", value: "entreprise.adresse.postalCode", class: "text-no-wrap" },
        { text: "Campagne", value: "campagne.name", class: "text-no-wrap" },
      ],
      headers: [
        { text: "Numero", value: "item.id", class: "text-no-wrap" },
        { text: "Échéance", value: "item.deadline", class: "text-no-wrap" },
        { text: "État", value: "item.stateId", align: "center", class: "text-no-wrap" },
        { text: "Résultat", value: "item.statusId", align: "center", class: "text-no-wrap" },
        { text: "Étape", value: "item.stepId", align: "center", class: "text-no-wrap" },
        { text: "Métier", value: "item.typeId", class: "text-no-wrap" },
        { text: "Classement", value: "item.categoryId", class: "text-no-wrap" },
        { text: "Commercial", value: "array.commercial", class: "text-no-wrap" },
        { text: "Client", value: "item.customerId", class: "text-no-wrap" },
        { text: "Sujet", value: "item.name", class: "text-no-wrap" },
      ],
    };
  },
  mounted() {
    this.mobile = this.isMobile();
    this.getData("listOpportunity");
    let headers = sessionStorage.getItem("headersOpp");
    let asUser = sessionStorage.getItem("asUserOpp");
    let page = sessionStorage.getItem("pageOpp");

    if (headers) {
      this.headers = JSON.parse(headers);
    }

    if (asUser) {
      this.asUser = JSON.parse(asUser);
    }

    if (page) {
      this.page = JSON.parse(page);
    }

    this.loadCat();
    this.loadType();
    this.loadOpp();
    this.getCompaigns();
  },
  methods: {
    newOpp() {
      this.$axios.post("/api/opportunities").then((res) => {
        this.$router.push({ name: "Opportunité", params: { id: res.data.opp.id } });
      });
    },
    getCompaigns() {
      this.loading = true;
      let params = { page: 1, limit: 100, allListing: true, search: this.searchCompaigns };
      this.$axios("/api/compaign", { params }).then((resp) => {
        this.compaigns = resp.data.data;
        this.loading = false;
      });
    },
    toggleItem(item, key) {
      let index = this.headers.findIndex((x) => x.value === item.value);
      let tmp = key + 1;
      let valI = -1;
      while (valI === -1 && tmp < this.columnsAvailable.length) {
        valI = this.headers.findIndex((x) => x.value === this.columnsAvailable[tmp].value);
        tmp += 1;
      }
      if (index === -1) {
        if (key === 0) {
          this.headers.splice(0, 0, item);
        } else if (key === this.columnsAvailable.length - 1 || valI === -1) {
          this.headers.push(this.columnsAvailable[key]);
        } else {
          this.headers.splice(valI, 0, item);
        }
      } else {
        this.headers.splice(index, 1);
      }

      sessionStorage.setItem("headersOpp", JSON.stringify(this.headers));
      this.$forceUpdate();
    },
    setAsFilter(event) {
      this.search.commercial = event;
    },
    setAsUser(event) {
      if (event.length > 0) {
        this.search.all = false;
      }
      this.asUser = event;
    },
    setItemMax(event) {
      this.limitItem = event;
    },
    setPage(event) {
      this.page = event;
    },
    loadCat() {
      this.$axios("/api/categories").then((res) => {
        this.listCatArray = [];
        this.listCat = res.data;
        Object.keys(res.data).forEach((item) => {
          this.listCatArray.push(res.data[item]);
        });
      });
    },
    loadType() {
      this.$axios("/api/types/active").then((res) => {
        this.listType = res.data.types;
        this.listType.forEach((item) => {
          this.typeListObj[item.id] = item;
        });
        Object.keys(res.data.types).forEach((item) => {
          this.listTypeArray.push(res.data.types[item]);
        });
      });
    },
    loadOpp() {
      this.isLoading = true;
      this.listOpp = [];

      if (this.search.client) {
        this.$axios("/api/customers/" + this.search.client).then((res) => {
          this.listClient = [{ customer: { id: res.data.entreprise.id, name: res.data.entreprise.name } }];
        });
      }
      this.$axios("/api/opportunities/user/all", {
        params: {
          page: this.page,
          search: this.search,
          limit: this.limitItem,
          asUser: this.asUser,
        },
      }).then((res) => {
        this.listOpp = res.data.data;
        this.maxItem = res.data.max;
        this.isLoading = false;
      });
      sessionStorage.setItem("headersOpp", JSON.stringify(this.headers));
      sessionStorage.setItem("asUserOpp", JSON.stringify(this.asUser));
      sessionStorage.setItem("pageOpp", JSON.stringify(this.page));
      this.setData("listOpportunity");
    },
    loadClient() {
      this.$axios("/api/customers", {
        params: {
          limit: 10,
          page: 1,
          search: this.customerSearch,
        },
      }).then((res) => {
        this.listClient = res.data.data;
      });
    },
    loadOpportunity(row) {
      this.$router.push({ name: "Opportunité", params: { id: row.item.id } });
    },
    loadOppDebounce() {
      this.simpleDebounce(() => {
        this.loadOpp();
      }, 300 /* optional debounce time: default is 300 */);
    },
    loadOppDebouncePage1() {
      this.page = 1;
      this.loadOppDebounce();
    },
  },
  watch: {
    mobile() {
      this.headers = [
        // { text: "Numero", value: "item.id", class: "text-no-wrap" },
        // { text: "Échéance", value: "item.deadline", class: "text-no-wrap" },
        // { text: "État", value: "item.stateId", align: "center", class: "text-no-wrap" },
        // { text: "Résultat", value: "item.statusId", align: "center", class: "text-no-wrap" },
        // { text: "Étape", value: "item.stepId", align: "center", class: "text-no-wrap" },
        // { text: "Métier", value: "item.typeId", class: "text-no-wrap" },
        // { text: "Classement", value: "item.categoryId", class: "text-no-wrap" },
        // { text: "Commercial", value: "array.commercial", class: "text-no-wrap" },
        { text: "Client", value: "item.customerId", class: "text-no-wrap" },
        { text: "Sujet", value: "item.name", class: "text-no-wrap" },
      ];
    },
    asUser: "loadOpp",
    limitItem: "loadOpp",
    "search.client": "loadOpp",
    page: "loadOpp",
    "search.all": "loadOppDebouncePage1",
    "search.state": "loadOppDebouncePage1",
    "search.id": "loadOppDebouncePage1",
    "search.deadline": "loadOppDebouncePage1",
    "search.type": "loadOppDebouncePage1",
    "search.status": "loadOppDebouncePage1",
    "search.step": "loadOppDebouncePage1",
    "search.categories": "loadOppDebouncePage1",
    "search.probability": "loadOppDebouncePage1",
    "search.name": "loadOppDebouncePage1",
    "search.commercial": "loadOppDebouncePage1",
    "search.postalCode": "loadOppDebouncePage1",
    "search.campagne": "loadOppDebouncePage1",
    "search.startDate"() {
      this.search.startDateFormatted = this.formatDate(this.search.startDate);
      this.loadOppDebounce();
    },
    "search.endDate"() {
      this.search.endDateFormatted = this.formatDate(this.search.endDate);
      this.loadOppDebounce();
    },
    "customerSearch.name"() {
      this.simpleDebounce(() => {
        this.loadClient();
      }, 300 /* optional debounce time: default is 300 */);
    },
    sortedBy() {
      this.search.sortedBy = this.sortedBy;
      this.loadOppDebounce();
    },
    sortedDesc() {
      this.search.sortedDesc = this.sortedDesc;
      this.loadOppDebounce();
    },
  },
};
</script>

<style scoped>
.v-text-field__details {
  display: none;
}

tr {
  cursor: pointer;
}

th.text-start {
  text-size-adjust: auto;
  font-size: 0.9rem !important;
}

.v-application--is-ltr .v-text-field .v-label {
  font-size: 0.9rem;
}
</style>
