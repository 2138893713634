var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    { attrs: { title: "Legende" } },
    [
      _c(
        "v-container",
        { staticClass: "pb-10", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0 text-center", attrs: { cols: "6" } },
                [
                  _c("img", {
                    attrs: {
                      src: "/images/challenge/bag.svg",
                      height: "48",
                      alt: "",
                    },
                  }),
                  _c("div", [
                    _vm._v("Plus grosse marge de la saison en une commande"),
                  ]),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "py-0 text-center", attrs: { cols: "6" } },
                [
                  _c("img", {
                    attrs: {
                      src: "/images/challenge/coin.svg",
                      height: "48",
                      alt: "",
                    },
                  }),
                  _c("div", [
                    _vm._v("Plus grosse marge de la semaine en une commande"),
                  ]),
                ]
              ),
              _vm._l(_vm.types, function (item, i) {
                return _c(
                  "div",
                  { key: i },
                  [
                    item.active
                      ? _c(
                          "v-col",
                          {
                            staticClass: "py-0 text-center",
                            attrs: { cols: "4" },
                          },
                          [
                            _c("v-icon", [
                              _vm._v(_vm._s(_vm.returnInfo(item.name).icon)),
                            ]),
                            _c("div", [_vm._v("Bonus " + _vm._s(item.name))]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }