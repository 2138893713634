var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    staticStyle: { "max-width": "225px" },
    attrs: {
      items: [
        "Mes clients",
        "Mes prospects",
        "Mes clients partagés",
        "Mes prospects partagés",
      ],
      "hide-no-data": "",
      "item-text": "name",
      "item-value": "name",
      placeholder: "Recherche rapide",
      "return-object": "",
      clearable: "",
      small: "",
      "persistent-placeholder": "",
      "hide-details": "",
    },
    on: { change: _vm.emitSearch },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v
      },
      expression: "search",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }