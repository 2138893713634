<template>
  <material-card title="Événements" icon="fa-calendar" v-if="compaign" class="mt-8">
    <modal v-model="linkOppModal" max-width="1024">
      <opportunitiesCustomers
        v-if="newEvent"
        :customer-id.sync="newEvent.customerId"
        v-model="linkOppModal"
        :link="true"
        @linkOpp="linkOppFct"
      ></opportunitiesCustomers>
    </modal>
    <modal v-model="linkModal" max-width="1920">
      <div class="text-left">
        <Inbox
          @close="
            getEvents();
            linkModal = false;
          "
          v-model="typeLink"
        ></Inbox>
      </div>
    </modal>
    <modal v-model="editEvent" max-width="1080">
      <template v-slot:header v-if="newEvent">
        <strong class="text-h5 mb-0 text-uppercase">Evènement</strong>
        <br /><small class="mt-0">{{ dayjs(newEvent.date).format("DD/MM/YYYY") }} {{ newEvent.time }} - {{ newEvent.name }}</small>

        <template v-if="newEvent.id">
          <div class="modal-menu--actions">
            <v-row>
              <v-col cols="12" class="text-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      elevation="0"
                      plain
                      color="primary"
                      @click="$router.push({ name: 'Client', params: { id: newEvent.customerId } })"
                    >
                      <v-icon x-small>fa-search</v-icon>
                    </v-btn>
                  </template>
                  <span>Fiche client</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ml-1"
                      small
                      elevation="0"
                      plain
                      color="none"
                      @click="
                        typeLink.id = newEvent.id;
                        linkModal = true;
                      "
                    >
                      <v-icon x-small>fa-envelope</v-icon>
                    </v-btn>
                  </template>
                  <span>Lier un e-mail</span>
                </v-tooltip>
                <template v-if="newEvent.opportunityId">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1"
                        elevation="0"
                        small
                        plain
                        color="warning"
                        @click="$router.push({ name: 'Opportunité', params: { id: newEvent.opportunityId } })"
                      >
                        <v-icon x-small>fa-handshake</v-icon>
                      </v-btn>
                    </template>
                    <span>Opportunité {{ newEvent.opportunityId }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="newOpp(newEvent)" v-bind="attrs" v-on="on" class="mx-1" elevation="0" small plain color="warning">
                        <v-icon x-small>fa-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Créer opportunité</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" @click="linkOppModal = true" small plain elevation="0">
                        <v-icon x-small>fa-link</v-icon>
                      </v-btn>
                    </template>
                    <span>Associer opportunité</span>
                  </v-tooltip>
                </template>
              </v-col>
            </v-row>
          </div>
        </template>
      </template>
      <v-container fluid>
        <v-row>
          <v-form v-model="valid" ref="form">
            <commentFormOpportunities v-model="newEvent"></commentFormOpportunities>
          </v-form>
        </v-row>
      </v-container>
      <template v-slot:actions>
        <v-btn variant="flat" color="primary" elevation="0" @click="editEventFct" :loading="loadingSave"> Enregistrer </v-btn>
        <v-btn color="secondary" text class="mr-4" @click="editEvent = false"> Fermer </v-btn>
      </template>
    </modal>
    <modal v-model="confirmPresence" max-width="1280">
      <template v-slot:header>
        <v-row>
          <v-col cols="6"> Confirmation des présences </v-col>
          <v-col cols="6" class="text-right">
            <v-btn @click="savePresence" color="primary" class="mr-4"> Enregistrer </v-btn>
          </v-col>
        </v-row>
      </template>
      <div>
        <div>
          <v-expansion-panels focusable>
            <v-expansion-panel v-for="(customer, i) in Object.keys(customers)" :key="i">
              <v-expansion-panel-header expand-icon="fas fa-angle-down">
                {{ customer }}
                <span style="position: absolute; right: 50px" class="ml-1"
                  ><v-switch v-model="presenceKeys" :value="customer" inset @click.stop="checkContain(customer)"></v-switch
                ></span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col class="py-1" cols="4" v-for="(contact, key) in customers[customer]" :key="key">
                    <v-switch v-model="presence[customer]" :value="contact.contact.id">
                      <template v-slot:label>
                        <v-icon color="yellow darken-1" class="mr-1" v-if="contact.favorite" small>fa-star</v-icon>
                        {{ contact.contact.firstname }} {{ contact.contact.lastname }}
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </modal>
    <modal v-model="openModalMail" max-width="1280">
      <template v-if="openModalMail">
        <v-card v-for="(email, key) in events[this.tab].email[mailSelected]" :key="key" class="my-2" elevation="2">
          <v-card-title>{{ email.name }}</v-card-title>
          <v-card-subtitle>{{ email.sender }} - {{ email.senderEmail }}</v-card-subtitle>
          <v-card-text v-html="email.body"></v-card-text>
        </v-card>
      </template>
    </modal>
    <v-card>
      <v-tabs fixed-tabs v-model="tab" background-color="primary" slider-color="white">
        <v-tab class="mr-3" :disabled="!compaign.defaultSettings.phases[1].enable">
          <v-row class="pa-0 ma-0">
            <v-col cols="2">
              <v-icon small class="mr-2">fa-envelope</v-icon>
            </v-col>
            <v-col cols="9"> Mailing </v-col>
          </v-row>
        </v-tab>
        <v-tab class="mr-3" :disabled="!compaign.defaultSettings.phases[2].enable">
          <v-row class="pa-0 ma-0">
            <v-col cols="2">
              <v-icon small class="mr-2">fa-reply</v-icon>
            </v-col>
            <v-col cols="9"> Phoning </v-col>
          </v-row>
        </v-tab>
        <v-tab class="mr-3" :disabled="!compaign.defaultSettings.phases[3].enable">
          <v-row class="pa-0 ma-0">
            <v-col cols="2">
              <v-icon small class="mr-2">fa-users</v-icon>
            </v-col>
            <v-col cols="9"> Rencontre </v-col>
          </v-row>
        </v-tab>
        <v-tab class="mr-3" :disabled="!compaign.defaultSettings.phases[4].enable">
          <v-row class="pa-0 ma-0">
            <v-col cols="2">
              <v-icon small class="mr-2">fa-reply-all</v-icon>
            </v-col>
            <v-col cols="9"> Génération business </v-col>
          </v-row>
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items v-model="tab" class="transparent">
      <v-tab-item>
        <div style="overflow-y: auto; max-height: calc((100vh / 2) - 75px)" id="infinite-list-0">
          <v-container>
            <v-timeline align-top dense large>
              <v-timeline-item
                small
                v-for="(item, i) in events[0].events"
                :color="events[0].type[item.eventtypeId] && events[0].type[item.eventtypeId].color ? events[0].type[item.eventtypeId].color : ''"
                :key="i"
                class="my-0 mb-4 pl-0"
                right
              >
                <template v-slot:icon>
                  <template v-if="events[0].users[item.id].length > 3">
                    <v-avatar color="primary" size="40">
                      <span class="white--text text-h5">{{ events[0].users[item.id].length }}</span>
                    </v-avatar>
                  </template>
                  <template v-else>
                    <UserAvatar v-for="(user, key) in events[0].users[item.id]" :key="key" :userId="user.userId" size="40" />
                  </template>
                  <v-icon class="white" small :color="returnIcon(item.status).color" style="position: absolute; top: 35px"
                    >{{ returnIcon(item.status).icon }} fa-fw
                  </v-icon>
                  <span class="white" style="position: absolute; top: 55px; text-align: center; font-size: 0.8rem">
                    {{ dayjs(item.start).format("DD/MM/YYYY") }}<br />{{ dayjs(item.start).format("HH:mm") }}
                  </span>
                </template>
                <template>
                  <v-card :class="{ eventstandby: item.status == 0, eventsuccess: item.status == 1, eventcancel: item.status == 2 }">
                    <v-card-title class="ma-0 pt-0">
                      <strong style="font-size: 0.9rem">
                        <v-icon small class="mr-3" v-if="item.private">fa-lock</v-icon>
                        <v-icon v-if="events" class="mr-3" x-small :color="events[0].type[item.eventtypeId].color">
                          {{ events[0].type[item.eventtypeId].icon }}
                        </v-icon>
                        {{ events[0].type[item.eventtypeId].name }} - {{ item.name }}
                        <v-btn
                          class="ma-0 p-0"
                          outlined
                          x-small
                          v-if="item.opportunity"
                          elevation="0"
                          :color="item.opportunity.statusId !== null ? '' : 'primary'"
                          @click="openOpportunity(item.opportunity.id)"
                          >{{ item.opportunity.id }}
                        </v-btn>
                      </strong>
                    </v-card-title>
                    <v-card-text>
                      <template v-if="item.report && item.report.length > 0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span class="mr-1" v-bind="attrs" v-on="on">- Note interne disponible</span>
                          </template>
                          <span>{{ item.report }}</span>
                        </v-tooltip>
                      </template>
                      <template v-if="events[0].email[item.id].length > 0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              class="mr-1"
                              style="cursor: pointer"
                              v-bind="attrs"
                              v-on="on"
                              @click="
                                mailSelected = item.id;
                                openModalMail = true;
                              "
                              ><v-icon small color="primary">fa-envelope</v-icon></span
                            >
                          </template>
                          <span>Voir les e-mails liés</span>
                        </v-tooltip>
                      </template>
                      <div style="position: absolute; top: -15px; right: 5px">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark v-bind="attrs" v-on="on" x-small fab elevation="0">
                              <v-icon x-small> fa-ellipsis-v </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item v-if="item.status === 0" @click="changeStatus(item, 1)">
                              <v-list-item-title>
                                <v-icon class="mx-1" small>fa-check</v-icon>
                                Valider
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="openEvents(item, 0)">
                              <v-icon class="mx-1" small>fa-pen</v-icon>
                              Editer
                            </v-list-item>
                            <v-list-item v-if="item.status === 0" @click="changeStatus(item, 2)">
                              <v-icon class="mx-1" small>fa-trash</v-icon>
                              Annuler
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>

                      <div class="text-caption">
                        <div v-if="item.teams">
                          <a :href="item.teamsLink" target="_blank" v-if="item.status !== 2">
                            <img src="/images/microsoft-teams.svg" style="max-width: 15px" /> - TEAMS
                          </a>
                          <span v-else> <img src="/images/microsoft-teams.svg" style="max-width: 15px" /> - TEAMS </span>
                        </div>
                        <read-more
                          v-if="item.body && item.body.length > 250"
                          more-str="Lire plus"
                          :text="item.body"
                          less-str="Lire moins"
                          :max-chars="250"
                        ></read-more>
                        <p v-else v-html="item.body ? item.body.replaceAll('\n', '<br/>') : 'Pas de commentaire'"></p>
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
              </v-timeline-item>
            </v-timeline>
          </v-container>
        </div>
        <div class="text-center">
          <v-btn @click="page[0] -= 1" :disabled="page[0] === 1" small><v-icon small>fa-angle-left</v-icon></v-btn>
          <v-btn :disabled="page[0] * 10 >= max[0]" @click="page[0] += 1" small class="ml-1"><v-icon small>fa-angle-right</v-icon></v-btn>
        </div>
      </v-tab-item>

      <v-tab-item>
        <div style="overflow-y: scroll" id="infinite-list-1">
          <v-timeline align-top dense large>
            <v-timeline-item
              small
              v-for="(item, i) in events[1].events"
              :color="events[1].type[item.eventtypeId] && events[1].type[item.eventtypeId].color ? events[1].type[item.eventtypeId].color : ''"
              :key="i"
              class="my-0 mb-4 pl-0"
              right
            >
              <template v-slot:icon>
                <template v-if="events[1].users[item.id].length > 3">
                  <v-avatar color="primary" size="40">
                    <span class="white--text text-h5">{{ events[1].users[item.id].length }}</span>
                  </v-avatar>
                </template>
                <template v-else>
                  <UserAvatar v-for="(user, key) in events[1].users[item.id]" :key="key" :userId="user.userId" size="40" />
                </template>
                <v-icon class="white" small :color="returnIcon(item.status).color" style="position: absolute; top: 35px"
                  >{{ returnIcon(item.status).icon }} fa-fw
                </v-icon>
                <span class="white" style="position: absolute; top: 55px; text-align: center; font-size: 0.8rem">
                  {{ dayjs(item.start).format("DD/MM/YYYY") }}<br />{{ dayjs(item.start).format("HH:mm") }}
                </span>
              </template>
              <template>
                <v-card :class="{ eventstandby: item.status == 0, eventsuccess: item.status == 1, eventcancel: item.status == 2 }">
                  <v-card-title class="ma-0 pt-0">
                    <strong style="font-size: 0.9rem">
                      <v-icon small class="mr-3" v-if="item.private">fa-lock</v-icon>
                      <v-icon v-if="events" class="mr-3" x-small :color="events[1].type[item.eventtypeId].color">
                        {{ events[1].type[item.eventtypeId].icon }}
                      </v-icon>
                      {{ events[1].type[item.eventtypeId].name }} - {{ item.name }}
                      <v-btn
                        class="ma-0 p-0"
                        outlined
                        x-small
                        v-if="item.opportunity"
                        elevation="0"
                        :color="item.opportunity.statusId !== null ? '' : 'primary'"
                        @click="openOpportunity(item.opportunity.id)"
                        >{{ item.opportunity.id }}
                      </v-btn>
                    </strong>
                  </v-card-title>
                  <v-card-text>
                    <template v-if="item.report && item.report.length > 0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span class="mr-1" v-bind="attrs" v-on="on">- Note interne disponible</span>
                        </template>
                        <span>{{ item.report }}</span>
                      </v-tooltip>
                    </template>
                    <template v-if="events[1].email[item.id].length > 0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="mr-1"
                            style="cursor: pointer"
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              mailSelected = item.id;
                              openModalMail = true;
                            "
                            ><v-icon small color="primary">fa-envelope</v-icon></span
                          >
                        </template>
                        <span>Voir les e-mails liés</span>
                      </v-tooltip>
                    </template>
                    <div style="position: absolute; top: -15px; right: 5px">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" dark v-bind="attrs" v-on="on" x-small fab elevation="0">
                            <v-icon x-small> fa-ellipsis-v </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item v-if="item.status === 0" @click="changeStatus(item, 1)">
                            <v-list-item-title>
                              <v-icon class="mx-1" small>fa-check</v-icon>
                              Valider
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="openEvents(item, 1)" v-if="item.status === 0">
                            <v-icon class="mx-1" small>fa-pen</v-icon>
                            Editer
                          </v-list-item>
                          <v-list-item v-else>
                            <v-tooltip left>
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <v-btn disabled small>
                                    <v-icon class="mr-2" small>fa-fw fa-edit</v-icon>
                                    Editer
                                  </v-btn>
                                </div>
                              </template>
                              <span>Un événement validé ou annulé ne peut être modifié.</span>
                            </v-tooltip>
                          </v-list-item>
                          <v-list-item v-if="item.status === 0" @click="changeStatus(item, 2)">
                            <v-icon class="mx-1" small>fa-trash</v-icon>
                            Annuler
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>

                    <div class="text-caption">
                      <div v-if="item.teams">
                        <a :href="item.teamsLink" target="_blank" v-if="item.status !== 2">
                          <img src="/images/microsoft-teams.svg" style="max-width: 15px" /> - TEAMS
                        </a>
                        <span v-else> <img src="/images/microsoft-teams.svg" style="max-width: 15px" /> - TEAMS </span>
                      </div>
                      <read-more
                        v-if="item.body && item.body.length > 250"
                        more-str="Lire plus"
                        :text="item.body"
                        less-str="Lire moins"
                        :max-chars="250"
                      ></read-more>
                      <p v-else v-html="item.body ? item.body.replaceAll('\n', '<br/>') : 'Pas de commentaire'"></p>
                    </div>
                  </v-card-text>
                </v-card>
              </template>
            </v-timeline-item>
          </v-timeline>
        </div>
        <div class="text-center">
          <v-btn @click="page[1] -= 1" :disabled="page[1] === 1" small><v-icon small>fa-angle-left</v-icon></v-btn>
          <v-btn @click="page[1] += 1" :disabled="page[1] * 10 >= max[1]" small class="ml-1"><v-icon small>fa-angle-right</v-icon></v-btn>
        </div>
      </v-tab-item>

      <v-tab-item>
        <div class="text-center mt-3" v-if="!compaign.commando && compaign.phase4date && Date.now() >= compaign.phase4date">
          <v-btn
            small
            color="primary"
            @click="
              presence =
                compaign.phase3Presence.presence && typeof compaign.phase3Presence.presence === 'object' ? compaign.phase3Presence.presence : {};
              presenceKeys = compaign.phase3Presence.presenceKeys ? compaign.phase3Presence.presenceKeys : [];
              confirmPresence = true;
            "
            >Confirmer les présences</v-btn
          >
        </div>

        <v-timeline align-top dense large>
          <v-timeline-item
            small
            v-for="(item, i) in events[2].events"
            :color="events[2].type[item.eventtypeId] && events[2].type[item.eventtypeId].color ? events[2].type[item.eventtypeId].color : ''"
            :key="i"
            class="my-0 mb-4 pl-0"
            right
          >
            <template v-slot:icon>
              <template v-if="events[2].users[item.id].length > 3">
                <v-avatar color="primary" size="40">
                  <span class="white--text text-h5">{{ events[2].users[item.id].length }}</span>
                </v-avatar>
              </template>
              <template v-else>
                <UserAvatar v-for="(user, key) in events[2].users[item.id]" :key="key" :userId="user.userId" size="40" />
              </template>
              <v-icon class="white" small :color="returnIcon(item.status).color" style="position: absolute; top: 35px"
                >{{ returnIcon(item.status).icon }} fa-fw
              </v-icon>
              <span class="white" style="position: absolute; top: 55px; text-align: center; font-size: 0.8rem">
                {{ dayjs(item.start).format("DD/MM/YYYY") }}<br />{{ dayjs(item.start).format("HH:mm") }}
              </span>
            </template>
            <template>
              <v-card :class="{ eventstandby: item.status == 0, eventsuccess: item.status == 1, eventcancel: item.status == 2 }">
                <v-card-title class="ma-0 pt-0">
                  <strong style="font-size: 0.9rem">
                    <v-icon small class="mr-3" v-if="item.private">fa-lock</v-icon>
                    <v-icon v-if="events" class="mr-3" x-small :color="events[2].type[item.eventtypeId].color">
                      {{ events[2].type[item.eventtypeId].icon }}
                    </v-icon>
                    {{ events[2].type[item.eventtypeId].name }} - {{ item.name }}
                    <v-btn
                      class="ma-0 p-0"
                      outlined
                      x-small
                      v-if="item.opportunity"
                      elevation="0"
                      :color="item.opportunity.statusId !== null ? '' : 'primary'"
                      @click="openOpportunity(item.opportunity.id)"
                      >{{ item.opportunity.id }}
                    </v-btn>
                  </strong>
                </v-card-title>
                <v-card-text>
                  <template v-if="item.report && item.report.length > 0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span class="mr-1" v-bind="attrs" v-on="on">- Note interne disponible</span>
                      </template>
                      <span>{{ item.report }}</span>
                    </v-tooltip>
                  </template>
                  <template v-if="events[2].email[item.id].length > 0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="mr-1"
                          style="cursor: pointer"
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            mailSelected = item.id;
                            openModalMail = true;
                          "
                          ><v-icon small color="primary">fa-envelope</v-icon></span
                        >
                      </template>
                      <span>Voir les e-mails liés</span>
                    </v-tooltip>
                  </template>
                  <div style="position: absolute; top: -15px; right: 5px">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on" x-small fab elevation="0">
                          <v-icon x-small> fa-ellipsis-v </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-if="item.status === 0" @click="changeStatus(item, 1)">
                          <v-list-item-title>
                            <v-icon class="mx-1" small>fa-check</v-icon>
                            Valider
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openEvents(item, 2)">
                          <v-icon class="mx-1" small>fa-pen</v-icon>
                          Editer
                        </v-list-item>
                        <v-list-item v-if="item.status === 0" @click="changeStatus(item, 2)">
                          <v-icon class="mx-1" small>fa-trash</v-icon>
                          Annuler
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>

                  <div class="text-caption">
                    <div v-if="item.teams">
                      <a :href="item.teamsLink" target="_blank" v-if="item.status !== 2">
                        <img src="/images/microsoft-teams.svg" style="max-width: 15px" /> - TEAMS
                      </a>
                      <span v-else> <img src="/images/microsoft-teams.svg" style="max-width: 15px" /> - TEAMS </span>
                    </div>
                    <read-more
                      v-if="item.body && item.body.length > 250"
                      more-str="Lire plus"
                      :text="item.body"
                      less-str="Lire moins"
                      :max-chars="250"
                    ></read-more>
                    <p v-else v-html="item.body ? item.body.replaceAll('\n', '<br/>') : 'Pas de commentaire'"></p>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </v-timeline-item>
        </v-timeline>
        <div class="text-center">
          <v-btn @click="page[2] -= 1" :disabled="page[2] === 1" small><v-icon small>fa-angle-left</v-icon></v-btn>
          <v-btn @click="page[2] += 1" :disabled="page[2] * 10 >= max[2]" small class="ml-1"><v-icon small>fa-angle-right</v-icon></v-btn>
        </div>
      </v-tab-item>

      <v-tab-item>
        <v-timeline align-top large dense>
          <v-timeline-item
            small
            v-for="(item, i) in events[3].events"
            :color="events[3].type[item.eventtypeId] && events[3].type[item.eventtypeId].color ? events[3].type[item.eventtypeId].color : ''"
            :key="i"
            class="my-0 mb-4 pl-0"
            right
          >
            <template v-slot:icon>
              <template v-if="events[3].users[item.id].length > 3">
                <v-avatar color="primary" size="40">
                  <span class="white--text text-h5">{{ events[3].users[item.id].length }}</span>
                </v-avatar>
              </template>
              <template v-else>
                <UserAvatar v-for="(user, key) in events[3].users[item.id]" :key="key" :userId="user.userId" size="40" />
              </template>
            </template>
            <v-row class="pt-1 mx-0">
              <v-col cols="2" class="text-center" style="white-space: nowrap">
                <strong class="ml-1"
                  >{{ dayjs(item.start).format("DD-MM-YYYY") }} <br />
                  {{ dayjs(item.start).format("HH:mm") }}
                </strong>
              </v-col>
              <v-col cols="2">
                <v-row>
                  <v-col cols="12" md="2">
                    <v-icon small :color="returnIcon(item.status).color">{{ returnIcon(item.status).icon }}</v-icon>
                  </v-col>
                  <v-col cols="12" md="9" class="text-center">
                    <v-chip
                      label
                      small
                      v-if="item.opportunity"
                      :color="item.opportunity.statusId !== null ? '' : 'success'"
                      @click="openOpportunity(item.opportunity.id)"
                      >{{ item.opportunity.id }}</v-chip
                    >
                  </v-col>
                </v-row>
              </v-col>

              <v-col class="my-0 py-1">
                <v-row>
                  <v-col cols="8">
                    <strong>
                      <v-icon v-if="events" class="mr-3" x-small :color="events[3].type[item.eventtypeId].color">
                        {{ events[3].type[item.eventtypeId].icon }}
                      </v-icon>
                      {{ events[3].type[item.eventtypeId].name }} - {{ item.name }}</strong
                    >
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <template v-if="item.report && item.report.length > 0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span class="mr-1" v-bind="attrs" v-on="on">- Note interne disponible</span>
                        </template>
                        <span>{{ item.report }}</span>
                      </v-tooltip>
                    </template>
                    <template v-if="events[3].email[item.id].length > 0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="mr-1"
                            style="cursor: pointer"
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              mailSelected = item.id;
                              openModalMail = true;
                            "
                            ><v-icon small color="primary">fa-envelope</v-icon></span
                          >
                        </template>
                        <span>Voir les e-mails liés</span>
                      </v-tooltip>
                    </template>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on" x-small>
                          <v-icon x-small> fa-ellipsis-h </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-if="item.status === 0" @click="changeStatus(item, 1)">
                          <v-list-item-title><v-icon class="mx-1" small>fa-check</v-icon> Valider</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openEvents(item, 3)"> <v-icon class="mx-1" small>fa-pen</v-icon> Editer </v-list-item>
                        <v-list-item v-if="item.status === 0" @click="changeStatus(item, 2)">
                          <v-icon class="mx-1" small>fa-trash</v-icon> Annuler
                        </v-list-item>
                        <v-list-item v-if="!item.opportunity" @click="newOpp(item)">
                          <v-icon class="mx-1" small>fa-plus</v-icon> Opportunité
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>

                <div class="text-caption">
                  <div v-if="item.teams">
                    <a :href="item.teamsLink" target="_blank" v-if="item.status !== 2">
                      <img src="images/microsoft-teams.svg" style="max-width: 15px" /> - TEAMS
                    </a>
                    <span v-else> <img src="images/microsoft-teams.svg" style="max-width: 15px" /> - TEAMS </span>
                  </div>
                  <div><v-icon x-small>fa-user-circle</v-icon> {{ events[3].customers[item.id].name }}</div>

                  <read-more
                    v-if="item.body && item.body.length > 250"
                    more-str="Lire plus"
                    :text="item.body.replaceAll('\n', '<br/>')"
                    link="#"
                    less-str="Lire moins"
                    :max-chars="250"
                  ></read-more>
                  <p v-else v-html="item.body ? item.body.replaceAll('\n', '<br/>') : 'Pas de commentaire'"></p>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
        <div class="text-center">
          <v-btn @click="page[3] -= 1" :disabled="page[3] === 1" small><v-icon small>fa-angle-left</v-icon></v-btn>
          <v-btn @click="page[3] += 1" :disabled="page[3] * 10 >= max[3]" small class="ml-1"><v-icon small>fa-angle-right</v-icon></v-btn>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </material-card>
</template>

<script>
import CommentFormOpportunities from "../../../../components/common/EventForm";

import MaterialCard from "../../../../components/common/MaterialCard";
import Modal from "../../../../components/common/modal";
import ReadMore from "../../../../components/common/read-more";
import Tools from "../../../../mixins/Tools";
import EventsTools from "../../../../mixins/Events";
import Inbox from "../../../../views/inbox/Inbox";
import OpportunitiesCustomers from "../../../customer/opportunitiesCustomers";
import UserAvatar from "../../../../components/user/UserAvatar.vue";

export default {
  name: "eventCompaign",
  components: { OpportunitiesCustomers, Inbox, Modal, MaterialCard, CommentFormOpportunities, ReadMore, UserAvatar },
  mixins: [Tools, EventsTools],
  props: ["value", "curItem"],
  data() {
    return {
      linkModal: false,
      compaign: this.value,
      tab: this.checkTabRet(),
      presence: {},
      confirmPresence: false,
      presenceKeys: [],
      editEvent: false,
      linkOppModal: false,
      newEvent: null,
      customers: {},
      loadingSave: false,
      mailSelected: null,
      openModalMail: false,
      pageCusto: 1,
      typeLink: { name: "Événement" },
      valid: false,
      events: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      page: {
        0: 1,
        1: 1,
        2: 1,
        3: 1,
      },
      max: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
      },
    };
  },
  mounted() {
    if (this.compaign) {
      this.compaign = this.value;
      this.tab = this.checkTabRet();
      this.getEvents();
    }
  },
  methods: {
    checkContain(customer) {
      if (this.presenceKeys.includes(customer)) {
        this.customers[customer].forEach((item) => {
          this.presence[customer].push(item.contact.id);
        });
      } else {
        this.presence[customer] = [];
      }
    },
    savePresence() {
      this.$axios
        .post("/api/compaign/" + this.$route.params.id + "/presence", { presenceKeys: this.presenceKeys, presence: this.presence })
        .then(() => {
          this.confirmPresence = false;

          this.$simpleToast.success("Campagne", "Les présences ont été validées.");
        });
    },
    linkOppFct(idOpp) {
      this.$axios.patch("/api/events/" + this.newEvent.id + "/link", { opp: idOpp }).then(() => {
        this.linkOppModal = false;
        this.editEvent = false;
        this.getEvents();

        this.$simpleToast.success("Evènement Campagne", "L'élément a bien été lié.");
      });
    },
    newOpp(item) {
      this.$axios.post("/api/opportunities", { compaign: this.compaign, eventItem: item, customerId: item.customerId }).then((res) => {
        this.linkOppFct(item.id, res.data.opp.id);
        this.$router.push({ name: "Opportunité", params: { id: res.data.opp.id } });
      });
    },
    openOpportunity(id) {
      this.$router.push({ name: "Opportunité", params: { id: id } });
    },
    async changeStatus(item, status) {
      if (status === 2) {
        try {
          let tokenResponse = await this.$msal.acquireTokenSilent({
            account: this.$msal.getAllAccounts()[0],
            scopes: ["user.read", "mail.send", "Calendars.ReadWrite"],
          });

          let axios = require("axios");

          this.$axios.patch("/api/events/" + item.id + "/status", { status: status }).then(() => {
            this.getEvents();

            this.$simpleToast.success("Evènement Campagne", "L'action a bien été annulée.");
          });
          axios
            .delete(process.env.VUE_APP_GRAPH_V1 + "calendar/events/" + item.ewsid, {
              headers: {
                Authorization: "Bearer " + tokenResponse.accessToken,
              },
            })
            .then((resp) => {
              this.newEvent.idMS = resp.data.id;
            });
        } catch (err) {
          this.$simpleToast.error("Evènement Campagne", "Une erreur est survenue");
        }
      } else {
        this.$axios.patch("/api/events/" + item.id + "/status", { status: status }).then(() => {
          this.getEvents();

          this.$simpleToast.success("Campagne", "L'action a bien été validé.");
          if (this.tab === 2) {
            this.$axios.patch("/api/compaign/commando/" + item.id).then(() => {
              this.$simpleToast.success("Campagne", "L'événement a bien été généré.");
              this.getEvents();
            });
          }
        });
      }
    },
    async editEventFct() {
      let res = false;
      this.$refs.form.validate();
      if (!this.valid) {
        this.$simpleToast.error("Evènement Campagne", "Un des champs est vide.");
      } else {
        this.loadingSave = true;
        res = await this.addEventSubmit(this.newEvent);
        this.loadingSave = false;
      }

      if (res) {
        this.getEvents();
        this.addEvent = false;
        this.editEvent = false;
        this.loadingSave = false;
      }
    },
    openEvents(tmp, index) {
      let item = JSON.parse(JSON.stringify(tmp));
      let dateStart = this.dayjs(item.start).format("DD/MM/YYYY");
      let timeStart = this.dayjs(item.start).format("HH:mm");
      this.newEvent = {};
      let tmpContacts = [];
      this.currentCustomer = this.events[index].customers[item.id];
      this.events[index].info[item.id].forEach((item) => {
        tmpContacts.push(item.contactId);
      });

      this.newEvent = {
        id: item.id,
        opp: this.$route.params.id,
        eventtypeId: item.eventtypeId,
        name: item.name,
        time: timeStart,
        ewsid: item.ewsid,
        date: dateStart,
        usersList: this.events[index].users[item.id],
        duration: item.duration,
        private: item.private,
        status: item.status,
        body: item.body,
        teams: item.teams,
        contacts: tmpContacts,
        customer: item.customerId,
        customerId: item.customerId,
        history: [],
        immutableEws: item.immutableEws,
      };
      this.editEvent = true;
    },
    getEvents() {
      let tab = this.tab + 1;
      this.$axios("/api/compaign/" + this.$route.params.id + "/events", {
        params: { page: this.page[this.tab], phase: tab, customer: this.curItem },
      }).then((resp) => {
        this.events[this.tab] = resp.data.data;
        this.max[this.tab] = resp.data.max;
      });
    },
    checkTabRet() {
      if (this.compaign) {
        if (this.compaign.defaultSettings.phases[1].enable) {
          return 0;
        }
        if (this.compaign.defaultSettings.phases[2].enable) {
          return 1;
        }
        if (this.compaign.defaultSettings.phases[3].enable) {
          return 2;
        }
        if (this.compaign.defaultSettings.phases[4].enable) {
          return 3;
        }
      }
      return 0;
    },
    returnIcon(status) {
      switch (status) {
        case 0:
          return { icon: "far fa-pause-circle", color: "#CFD8DC" };
        case 1:
          return { icon: "far fa-check-circle", color: "#43A047" };
        case 2:
          return { icon: "far fa-times-circle", color: "#C62828" };
      }
    },
    getCusto() {
      this.$axios("/api/compaign/" + this.$route.params.id + "/customers", { params: { page: this.pageCusto, search: this.search } }).then((resp) => {
        this.customers = { ...this.customers, ...resp.data.data };

        // Detect when scrolled to bottom.
        const listElm = document.querySelector("#infinitelist");
        if (listElm) {
          listElm.addEventListener("scroll", () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
              this.pageCusto += 1;
            }
          });
        }
      });
    },
  },
  watch: {
    presenceKeys() {
      if (this.presenceKeys) {
        this.presenceKeys.forEach((item) => {
          if (!this.presence[item]) {
            this.presence[item] = [];
          }
        });
      }
    },
    editEvent() {
      if (this.editEvent === false) {
        this.newEvent = false;
      }
    },
    confirmPresence() {
      if (this.confirmPresence) {
        this.getCusto();
      }
    },
    pageCusto() {
      this.getCusto();
    },
    curItem() {
      this.getEvents();
    },
    page: {
      deep: true,
      handler() {
        this.getEvents();
      },
    },
    tab() {
      this.getEvents();
    },
    value() {
      this.compaign = this.value;
      this.tab = this.checkTabRet();
      this.getEvents();
    },
  },
};
</script>

<style scoped>
.eventstandby {
  border-bottom: 3px solid #cfd8dc;
}

.eventsuccess {
  border-bottom: 3px solid #43a047;
}

.eventcancel {
  border-bottom: 3px solid #c62828;
}
</style>
