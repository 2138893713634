<template>
  <v-container fluid>
    <Landscape />
    <v-card style="z-index: 90" elevation="1">
      <v-toolbar color="primary" dark elevation="0">
        <v-card-title>
          Liste des contacts
          <div class="listbox--actions-add">
            <v-btn @click="addContact" v-if="$store.state.settings && !$store.state.settings.sage" small fab color="primary" dark>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">fa-fw fa-plus</v-icon>
                </template>
                <span>Ajouter</span>
              </v-tooltip>
            </v-btn>
          </div>
          <modal v-model="contactModal" max-width="720">
            <v-alert v-if="!singleContact" dense outlined type="error"> Un contact existe déjà avec cet email. </v-alert>
            <contact-form v-model="newContact" :status="false"></contact-form>
            <v-row v-if="similar && similar.length > 0">
              <v-col cols="12">
                <v-divider></v-divider>
                <h4>Contacts similaires</h4>
              </v-col>
              <v-col cols="6" md="6" v-for="(item, key) in similar" :key="key" class="py-1">
                <v-card class="pa-2">
                  <v-card-text>
                    <v-row class="text-left">
                      <v-col cols="12" class="py-0"
                        ><v-icon class="mr-1" x-small>fa-user</v-icon> {{ item.contact.firstname + " " + item.contact.lastname }}</v-col
                      >
                      <v-col cols="12" class="py-0"><v-icon class="mr-1" x-small>fa-envelope</v-icon> {{ item.contact.email }}</v-col>
                      <v-col cols="12" class="py-0"><v-icon class="mr-1" x-small>fa-phone</v-icon> {{ item.contact.phone }}</v-col>
                      <v-col cols="12" class="py-0"><v-icon class="mr-2" x-small>fa-mobile</v-icon> {{ item.contact.phone2 }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <template v-slot:actions>
              <v-btn text :disabled="!singleContact" class="mr-4" @click="newContactSub"> Enregistrer </v-btn>
            </template>
          </modal>

          <v-btn v-if="false" text @click="modalSettings = true">
            <v-icon>fa-filter</v-icon>
          </v-btn>

          <v-menu v-if="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <v-icon>fa-cog</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="onClick">
                <v-list-item-title>Option 1</v-list-item-title>
              </v-list-item>

              <v-list-item disabled>
                <v-list-item-title>Option 2</v-list-item-title>
              </v-list-item>

              <v-list-item @click="onClick">
                <v-list-item-title>Option 3</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
        </v-card-title>
      </v-toolbar>
      <modal v-model="allCusto">
        <v-chip
          v-for="(item, key) in allCustoItems"
          :key="key"
          style="width: 100%; text-align: center; display: block"
          class="my-1"
          label
          small
          @click="loadCustomer(item.id)"
        >
          {{ item.name }}
        </v-chip>
      </modal>
      <v-data-table
        :headers="headers"
        :items="listContacts"
        :page="page"
        :search.sync="researchClient"
        :items-per-page="limitItem"
        :loading="isLoading"
        class="elevation-0"
        style="z-index: 1000"
        :server-items-length="maxItem"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
        @update:page="setPage"
        @update:items-per-page="setItemMax"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 15, 25, 50],
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right',
        }"
      >
        <template v-slot:item.contact.customerId="{ item }">
          <template v-if="item.customer.length > 0">
            <div style="position: relative">
              <v-chip style="width: 100%; text-align: center; display: block" label small @click="loadCustomer(item.customer[0].id)">
                {{ item.customer[0].name }}
              </v-chip>
              <v-chip
                style="position: absolute; top: -5px; right: -5px"
                color="primary"
                class="pa-1"
                @click="openCustomer(item.customer)"
                v-if="item.customer.length > 1"
                x-small
                >+{{ item.customer.length - 1 }}</v-chip
              >
            </div>
          </template>
          <!-- <template v-if="!item.contact.customerId">
            Non rattaché
          </template>-->
        </template>

        <template v-slot:item.actions="{ item }">
          <v-row>
            <v-col class="text-right">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="openEmails(item)" v-if="item.contact.emails.length">
                    <v-list-item-title><v-icon small class="mr-2">fa-fw fa-envelope</v-icon>Emails</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openEdit(item)">
                    <v-list-item-title><v-icon small class="mr-2">fa-fw fa-edit</v-icon>Editer</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openInfo(item)">
                    <v-list-item-title><v-icon small class="mr-2">fa-fw fa-eye</v-icon>Voir</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('CONTACTS_ANONYME')" @click="toggleAnonymize(item)">
                    <v-list-item-title v-if="!item.contact.anonyme"> <v-icon small class="mr-2">fa-fw fa-mask</v-icon>Anonymiser </v-list-item-title>
                    <v-list-item-title v-else> <v-icon small class="mr-2">fa-fw fa-user</v-icon>Retirer l'anonymat </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="disableItem(item)">
                    <v-list-item-title><v-icon small class="mr-2">fa-fw fa-trash</v-icon>Supprimer</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>

        <template v-slot:body.prepend>
          <tr>
            <td v-if="!mobile"></td>
            <!--<td></td>-->
            <td>
              <v-text-field v-model="search.lastname" type="text" label="Filtrer"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="search.firstname" type="text" label="Filtrer"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="search.customer" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="!mobile">
              <v-text-field v-model="search.function" type="text" label="Filtrer"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="search.phone" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="!mobile">
              <v-text-field v-model="search.phone2" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="!mobile">
              <v-text-field v-model="search.email" type="text" label="Filtrer"></v-text-field>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <modal v-model="modalInfo" max-width="800px">
      <template v-slot:header v-if="modalItemInfo.contact">
        <strong class="text-h5 mb-0 text-uppercase">{{ modalItemInfo.contact.firstname }} {{ modalItemInfo.contact.lastname }}</strong>
      </template>
      <v-row>
        <v-col cols="6" class="text-left">
          <v-row>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-col cols="12">
                  <span class="font-weight-bold">Fonction :</span>

                  {{ modalItemInfo.contact && modalItemInfo.contact.function ? modalItemInfo.contact.function : "Non renseigné" }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-col cols="12">
                  <span class="font-weight-bold">E-mail :</span>
                  {{ modalItemInfo.contact && modalItemInfo.contact.email ? modalItemInfo.contact.email : "Non renseigné" }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-col cols="12">
                  <span class="font-weight-bold">Téléphone fixe :</span>
                  {{ modalItemInfo.contact && modalItemInfo.contact.phone ? modalItemInfo.contact.phone : "Non renseigné" }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-col cols="12">
                  <span class="font-weight-bold">Téléphone portable :</span>
                  {{ modalItemInfo.contact && modalItemInfo.contact.phone2 ? modalItemInfo.contact.phone2 : "Non renseigné" }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0" v-if="false">
              <v-row>
                <v-col cols="12">
                  <span class="font-weight-bold">Fax :</span>
                  {{ modalItemInfo.contact && modalItemInfo.contact.fax ? modalItemInfo.contact.fax : "Non renseigné" }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0" v-if="false">
              <v-row>
                <v-col cols="12">
                  <span class="font-weight-bold">Skype :</span>
                  {{ modalItemInfo.contact && modalItemInfo.contact.skype ? modalItemInfo.contact.skype : "Non renseigné" }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="text-left">
          <h3>Note</h3>
          <v-col cols="12" class="ma-0">
            <v-row>
              <v-col cols="12">
                <p>
                  {{ modalItemInfo.contact && modalItemInfo.contact.note ? modalItemInfo.contact.note : "Aucune note" }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
        <v-col cols="12" class="ma-0" v-if="modalItemInfo && modalItemInfo.customer && modalItemInfo.customer.length > 0">
          <span class="text-overline">Associations</span>
          <v-divider></v-divider>
          <v-col cols="12" class="pb-0" v-for="(itm, key) in modalItemInfo.customer" :key="key" @click="loadCustomer(itm.id)">
            <v-chip small label style="cursor: pointer">
              {{ itm.name }}
            </v-chip>
          </v-col>
        </v-col>
      </v-row>
    </modal>

    <modal v-model="modalEdit" max-width="1024px">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Edition contact</strong>
        <br /><small class="mt-0">
          <template v-if="modalEditInfo.contact"> {{ modalEditInfo.contact.firstname }} {{ modalEditInfo.contact.lastname }}</template>
        </small>
      </template>
      <v-row v-if="modalEditInfo.contact">
        <v-col cols="12">
          <v-alert v-if="!singleContact && singleContactId !== modalEditInfo.contact.id" color="error" outlined small prominent dense text type="error">
            Un contact existe déjà avec cet email.
          </v-alert>
        </v-col>
        <v-col cols="6" class="ma-0 mt-3">
          <v-row>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-select outlined :items="civilities" v-model="modalEditInfo.contact.civilityId" item-text="name" item-value="id" label="Civilité"></v-select>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-text-field outlined v-model="modalEditInfo.contact.firstname" label="Prénom"></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-text-field outlined v-model="modalEditInfo.contact.lastname" label="Nom"></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0" v-if="false">
              <v-row>
                <v-text-field outlined v-model="modalEditInfo.role" label="Fonction"></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0"> </v-col>
            <v-col cols="12" class="ma-0">
              <v-row>
                <v-textarea outlined v-model="modalEditInfo.contact.note" label="Note"></v-textarea>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="ma-0">
          <v-col cols="12" class="ma-0">
            <v-row>
              <v-col cols="12" class="ma-0">
                <v-row>
                  <v-text-field outlined v-model="modalEditInfo.contact.email" label="E-mail"></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0">
                <v-row>
                  <v-text-field outlined v-model="modalEditInfo.contact.phone" label="Téléphone fixe"></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0">
                <v-row>
                  <v-text-field outlined v-model="modalEditInfo.contact.phone2" label="Téléphone portable"></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0" v-if="false">
                <v-row>
                  <v-text-field outlined v-model="modalEditInfo.contact.skype" label="Skype"></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0" v-if="modalEditInfo.customer.length > 0">
                <span class="text-overline">Associations</span>
                <v-divider></v-divider>
                <v-col cols="12" class="pb-0" v-for="(itm, key) in modalEditInfo.customer" :key="key" @click="loadCustomer(itm)">
                  <v-chip small label style="cursor: pointer">
                    {{ itm.name }}
                  </v-chip>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn
          :disabled="!singleContact && singleContactId !== modalEditInfo.contact.id"
          variant="flat"
          color="primary"
          elevation="0"
          class="mr-4"
          @click="validateEdit"
        >
          Enregistrer
        </v-btn>
        <v-btn color="secondary" text class="mr-4" @click="modalEdit = false"> Fermer </v-btn>
      </template>
    </modal>

    <modal v-model="modalSettings">
      <listUser @selectChange="setAsUser"></listUser>
    </modal>

    <view-email-contact v-model="modalEmail" :contact="modalItemInfo.contact" :emails="selectedContactEmails"></view-email-contact>
  </v-container>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import ListUser from "../../components/user/listUser";
import Modal from "../../components/common/modal";
import ContactForm from "./contactForm";
import Landscape from "../../components/common/Landscape.vue";
import MobileDetection from "../../mixins/MobileDetection";
import viewEmailContact from "./viewEmailContact.vue";
import Tools from "../../mixins/Tools";

export default {
  name: "listContact",
  components: { ContactForm, Modal, ListUser, Landscape, viewEmailContact },
  mixins: [simpleDebounce, MobileDetection, Tools],
  data() {
    return {
      mobile: false,
      similar: [],
      newContact: {},
      modalInfo: false,
      modalEmail: false,
      modalItemInfo: {},
      modalEdit: false,
      modalEditInfo: {},
      isLoading: false,
      listContacts: [],
      allCusto: false,
      allCustoItems: [],
      sortedBy: null,
      contactModal: false,
      sortedDesc: false,
      selectedContactEmails: [],
      search: {
        lastname: "",
        firstname: "",
        function: "",
        customer: "",
        phone: "",
        phone2: "",
        email: "",
        sortedBy: null,
        sortedDesc: false,
      },
      civilities: [
        {
          id: 0,
          name: "Monsieur",
        },
        {
          id: 1,
          name: "Madame",
        },
        {
          id: 2,
          name: "Mademoiselle",
        },
      ],
      listCat: [],
      menu: false,
      listCatArray: [],
      calories: 0,
      searchType: "",
      researchClient: "",
      limitItem: 10,
      maxItem: 0,
      page: 1,
      listClient: [],
      listType: [],
      asUser: null,
      itemNbr: 10,
      searchCat: "",
      singleContact: true,
      singleContactId: null,
      isReady: false,
      modalSettings: false,
      typeListObj: {},
      headers: [
        { text: "id", value: "contact.id" },
        //{ text: "id Helios", value: "contact.idContacts" },
        { text: "Nom", value: "contact.lastname" },
        { text: "Prénom", value: "contact.firstname" },
        { text: "Entreprise", value: "contact.customerId", sortable: false },
        { text: "Fonction", value: "contact.function" },
        { text: "Téléphone", value: "contact.phone" },
        { text: "Mobile", value: "contact.phone2" },
        { text: "Email", value: "contact.email" },
        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  mounted() {
    this.mobile = this.isMobile();
    let search = sessionStorage.getItem("searchContact");
    if (search) {
      this.search = JSON.parse(search);
    }
    this.loadContact();
  },
  beforeDestroy() {
    sessionStorage.setItem("searchContact", JSON.stringify(this.search));
  },
  methods: {
    async disableItem(item) {
      let res = await this.$confirm("Êtes-vous sûr ?");
      if (res) {
        this.$axios.delete("/api/contacts/" + item.contact.id + "/disable").then(() => {
          this.$simpleToast.success("Contact", "L'élément a bien été supprimé");
          this.loadContact();
        });
      }
    },
    openCustomer(items) {
      this.allCustoItems = items;
      this.allCusto = true;
    },
    newContactSub() {
      this.$axios.post("/api/contacts", { data: this.newContact }).then(() => {
        this.$simpleToast.success("Contact", "Le contact a bien été ajouté");
        this.contactModal = false;
      });
    },
    addContact() {
      this.newContact = {
        firstname: "",
        lastname: "",
        fax: "",
        phone: "",
        phone2: "",
        email: "",
        note: "",
        customerId: null,
        job: null,
        isFavorite: null,
        function: "",
      };
      this.contactModal = true;
    },
    loadCustomer(id) {
      this.$router.push({ name: "Client", params: { id: id } });
    },
    validateEdit() {
      this.$axios.patch("/api/contacts/" + this.modalEditInfo.contact.id + "/edit", { contact: { contact: this.modalEditInfo.contact } }).then(() => {
        this.loadContact();
        this.modalEdit = false;
        this.$simpleToast.success("Contact", "Le contact a bien été édité");
      });
    },
    openEdit(item) {
      this.modalEditInfo = JSON.parse(JSON.stringify(item));
      if (this.modalEditInfo.contact.civilityId) {
        this.modalEditInfo.contact.civilityId = 1;
      } else {
        this.modalEditInfo.contact.civilityId = 0;
      }
      this.modalEdit = true;
    },
    toggleAnonymize(item) {
      this.$axios.patch("/api/contacts/" + item.contact.id + "/anonyme").then(() => {
        this.loadContact();
        this.$simpleToast.success("Contact", item.contact.anonyme ? "Le contact n'est plus anonymisé" : "Le contact a bien été anonymisé");
      });
    },
    openEmails(item) {
      this.loading = true;
      if (item.contact?.id) {
        this.$axios(`/api/contacts/email/linked/${item.contact.id}`)
          .then((res) => {
            this.selectedContactEmails = res.data;
            this.modalItemInfo = item;
            this.modalEmail = true;
          })
          .catch(() => {
            this.$simpleToast.error("Erreur", "Une erreur est survenue lors de la récupération des emails");
          });
      }
    },
    openInfo(item) {
      this.modalItemInfo = item;
      this.modalInfo = true;
    },
    setAsUser(event) {
      this.asUser = event;
    },
    setItemMax(event) {
      this.limitItem = event;
    },
    setPage(event) {
      this.page = event;
    },
    loadContact() {
      this.isLoading = true;
      let search = this.search;
      let page = this.page;
      this.listOpp = [];
      this.$axios("/api/contacts", { params: { page: page, search, limit: this.limitItem, asUser: this.asUser } }).then((res) => {
        this.listContacts = res.data.data;
        this.maxItem = res.data.max;
        this.isLoading = false;
      });
    },
    getContactsSearch() {
      let search = this.newContact;
      search.sortedBy = null;
      search.sortedDesc = false;
      this.$axios("/api/contacts", { params: { search, limit: 999 } }).then((resp) => {
        this.similar = resp.data.data;
      });
    },
    loadClient() {
      this.$axios("/api/customers/search?s=" + this.researchClient).then((res) => {
        this.listClient = res.data;
      });
    },
    loadOpportunity(row) {
      this.$router.push({ name: "Opportunité", params: { id: row.item.id } });
    },
    getSingleEmail(email) {
      this.$axios("/api/contacts/email", { params: { email: email } }).then((resp) => {
        this.singleContact = !resp.data;
        this.singleContactId = resp.data ? resp.data : null;
      });
    },
    loadContactDebounce() {
      this.simpleDebounce(() => {
        this.loadContact();
      }, 300);
    },
  },
  watch: {
    mobile() {
      this.headers = [
        //{ text: "id", value: "contact.id" },
        //{ text: "id Helios", value: "contact.idContacts" },
        { text: "Nom", value: "contact.lastname" },
        { text: "Prénom", value: "contact.firstname" },
        { text: "Entreprise", value: "contact.customerId", sortable: false },
        { text: "Téléphone", value: "contact.phone" },
        { text: "Actions", value: "actions", align: "end", sortable: false },
        //{ text: "Fonction", value: "contact.function" },
        //{ text: "Mobile", value: "contact.phone2" },
        //{ text: "Email", value: "contact.email" },
        //{ text: "Actions", value: "actions", align: "end", sortable: false },
      ];
    },
    "modalEditInfo.contact.email"() {
      this.simpleDebounce(() => {
        this.getSingleEmail(this.modalEditInfo.contact.email);
      }, 500);
    },
    "newContact.email"() {
      if (this.newContact.email.length > 0) {
        this.simpleDebounce(() => {
          this.getSingleEmail(this.newContact.email);
          this.getContactsSearch(true);
        }, 500);
      }
    },
    "newContact.firstname"() {
      if (this.newContact.firstname.length > 0) {
        this.simpleDebounce(() => {
          this.getContactsSearch(true);
        }, 500);
      }
    },
    "newContact.lastname"() {
      if (this.newContact.lastname.length > 0) {
        this.simpleDebounce(() => {
          this.getContactsSearch(true);
        }, 500);
      }
    },
    limitItem() {
      this.loadContact();
    },
    "search.email"() {
      this.page = 1;
      this.loadContactDebounce();
    },
    "search.phone2"() {
      this.page = 1;
      this.loadContactDebounce();
    },
    "search.phone"() {
      this.page = 1;
      this.loadContactDebounce();
    },
    "search.function"() {
      this.page = 1;
      this.loadContactDebounce();
    },
    "search.firstname"() {
      this.page = 1;
      this.loadContactDebounce();
    },

    "search.lastname"() {
      this.page = 1;
      this.loadContactDebounce();
    },
    "search.customer"() {
      this.page = 1;
      this.loadContactDebounce();
    },
    sortedBy() {
      this.search.sortedBy = this.sortedBy;
      this.loadContactDebounce();
    },
    sortedDesc() {
      this.search.sortedDesc = this.sortedDesc;
      this.loadContactDebounce();
    },
    page() {
      this.loadContactDebounce();
    },
  },
};
</script>

<style>
tr {
  cursor: pointer;
}
</style>
