<template>
  <material-card title="Statistiques" class="mt-8" icon="fas fa-chart-bar">
    <h3 style="text-align: center">Comparatif opportunitées remportées par métier</h3>
    <Bar :chartData="chartData" :chartOptions="chartOptions" class="chartBar"></Bar>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
import { Bar } from "vue-chartjs";

export default {
  name: "statsCustomers",
  components: {
    MaterialCard,
    Bar,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        indexAxis: "y",
        maintainAspectRatio: false,
        barPercentage: 0.65,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                if (value > 1000) {
                  return value / 1000 + "k";
                } else {
                  return value;
                }
              },
            },
          },
          y: {
            grid: {
              display: false, // Ajoutez cette ligne
            },
          },
        },
        plugins: {
          legend: {
            align: "end",

            labels: {
              usePointStyle: true,
              textAlign: "right",
              font: {
                size: 11,
                weight: "bold",
              },
            },
          },
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  mounted() {
    this.getStats();
  },
  methods: {
    getStats() {
      this.$axios("/api/stats/customer/" + this.$route.params.id).then((resp) => {
        this.chartData.labels = Object.keys(resp.data);
        let value = {};

        Object.keys(resp.data).forEach((item) => {
          Object.keys(resp.data[item]).forEach((val, index) => {
            if (!(val in value)) {
              value[val] = { label: val, data: [], backgroundColor: index ? "#434348" : "#7cb5ec" };
            }
            value[val].data.push(resp.data[item][val]);
          });
        });
        Object.keys(value).forEach((val) => {
          this.chartData.datasets.push(value[val]);
        });
      });
    },
  },
};
</script>

<style scoped>
.chartBar {
  min-height: 400px;
  padding: 10px 20px;
}
</style>
