<template>
  <v-container fluid>
    <v-card v-if="isGranted('ROLES_INDEX')">
      <v-toolbar color="primary " dark elevation="0">
        <v-row>
          <v-col cols="11">
            <v-text-field v-model="search" label="Rechercher" single-line prepend-icon="fa-search" hide-details></v-text-field>
          </v-col>
          <v-col cols="1" class="text-center">
            <v-btn
              @click="
                editGroupItem = { group: { name: '' }, grants: [] };
                editModal = true;
              "
              color="primary"
              small
              fab
              class="listbox--actions-add"
            >
              <v-icon small>fa-plus fa-fw</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <div style="position: relative">
        <v-data-table :headers="headers" :items="roles.groups" :search="search">
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="editItem(item)" v-if="isGranted('ROLES_EDIT')">fa-edit</v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <modal v-model="editModal" max-width="1024">
      <template v-slot:header>{{ editGroupItem && editGroupItem.group.id ? "Édition" : "Ajout" }}</template>
      <v-row v-if="editGroupItem">
        <v-col cols="12">
          <v-text-field v-model="editGroupItem.group.name" label="Nom"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-row style="max-height: 500px; overflow-y: scroll">
            <v-expansion-panels>
              <v-expansion-panel v-for="(name, key) in Object.keys(rolesList)" :key="'label-' + key">
                <v-expansion-panel-header class="text-overline" expand-icon="fas fa-angle-down">{{ name }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col class="py-0" cols="6" v-for="(item, key) in rolesList[name]" :key="'item-' + key">
                      <v-switch inset :label="item.name" :value="item.id" v-model="editGroupItem.grants"></v-switch>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            :menu-props="{ maxHeight: '300' }"
            v-if="false"
            v-model="editGroupItem.grants"
            :items="roles.grants"
            item-text="name"
            item-value="id"
            outlined
            dense
            chips
            small-chips
            label="Droits"
            multiple
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.categoryName"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn color="primary" @click="saveRoles" :loading="loading">Enregistrer</v-btn>
      </template>
    </modal>
  </v-container>
</template>

<script>
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";
export default {
  name: "rolesSettings",
  components: { Modal },
  mixins: [Tools],
  data() {
    return {
      loading: false,
      search: "",
      editModal: false,
      editGroupItem: null,
      rolesList: {},
      roles: [],
      headers: [
        { text: "#", value: "group.id", align: "start", sortable: false },
        { text: "Nom", value: "group.name", align: "start", sortable: false },
        { text: "Actions", value: "actions", align: "right", sortable: false },
      ],
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    editItem(item) {
      this.editGroupItem = JSON.parse(JSON.stringify(item));
      this.editModal = true;
    },
    saveRoles() {
      this.loading = true;
      if (this.editGroupItem.group.id) {
        this.$axios.patch("/api/roles/groups/" + this.editGroupItem.group.id, { data: this.editGroupItem }).then(() => {
          this.getRoles();
          this.$simpleToast.success("Rôle", "L'élément a bien été enregistré");
          this.editModal = false;
          this.loading = false;
        });
      } else {
        this.$axios.post("/api/roles/groups", { data: this.editGroupItem }).then(() => {
          this.getRoles();
          this.$simpleToast.success("Rôle", "L'élément a bien été enregistré");
          this.editModal = false;
          this.loading = false;
        });
      }
    },
    getRoles() {
      this.rolesList = {};
      this.roles = [];
      this.$axios("/api/roles/groups").then((resp) => {
        this.roles = resp.data;
        resp.data.grants.forEach((item) => {
          if (!(item.categoryName in this.rolesList)) {
            this.rolesList[item.categoryName] = [];
          }
          this.rolesList[item.categoryName].push(item);
        });
      });
    },
  },
};
</script>

<style scoped></style>
