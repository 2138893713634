<template>
  <v-select
    v-model="search"
    :items="['Mes clients', 'Mes prospects', 'Mes clients partagés', 'Mes prospects partagés']"
    hide-no-data
    item-text="name"
    item-value="name"
    placeholder="Recherche rapide"
    return-object
    clearable
    small
    @change="emitSearch"
    persistent-placeholder
    style="max-width: 225px"
    hide-details
  >
  </v-select>
</template>

<script>
export default {
  name: "RapidSearchCustomers",
  data() {
    return {
      search: null,
    };
  },
  methods: {
    emitSearch() {
      this.$emit("selectedRapidSearch", this.search);
    },
  },
};
</script>
