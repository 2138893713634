var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MaterialCard",
    {
      staticStyle: { "min-height": "13.5rem" },
      attrs: { icon: "fa-paperclip", title: "Fichiers" },
    },
    [
      _c(
        "div",
        { staticClass: "listbox--actions-add" },
        [
          _c(
            "v-menu",
            {
              attrs: { left: "", "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            { attrs: { fab: "", small: "", color: "primary" } },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa-plus fa-fw"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                [
                  this.$store.state.settings.sage
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.devisModal = true
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v("far fa-file-alt fa-fw")]
                              ),
                              _vm._v(" Devis SAGE "),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider"),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          _vm.fileModal = true
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("far fa-file-archive fa-fw")]
                          ),
                          _vm._v(" Fichiers "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Import de devis"),
                  ]),
                  _c("br"),
                  _c("small", { staticClass: "mt-0" }, [
                    _vm._v("Choisissez parmi les devis proposés"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        variant: "flat",
                        color: "primary",
                        elevation: "0",
                      },
                      on: { click: _vm.linkDevis },
                    },
                    [_vm._v(" Enregistrer ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.devisModal = false
                        },
                      },
                    },
                    [_vm._v(" Fermer ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.devisModal,
            callback: function ($$v) {
              _vm.devisModal = $$v
            },
            expression: "devisModal",
          },
        },
        [
          _c("v-autocomplete", {
            staticClass: "mt-5",
            attrs: {
              "menu-props": { maxHeight: "300" },
              items: _vm.listDevis,
              loading: _vm.isLoading,
              "search-input": _vm.search,
              "hide-no-data": "",
              "persistent-placeholder": "",
              outlined: "",
              "hide-selected": "",
              "item-text": "piece",
              "item-value": "piece",
              label: "Recherche de devis",
              placeholder: "Recherche par référence",
              "prepend-icon": "fa-search",
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.search = $event
              },
              "update:search-input": function ($event) {
                _vm.search = $event
              },
            },
            model: {
              value: _vm.selectedDevis,
              callback: function ($$v) {
                _vm.selectedDevis = $$v
              },
              expression: "selectedDevis",
            },
          }),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { "max-width": "1080" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _vm.showPreviewItem
                    ? _c(
                        "strong",
                        { staticClass: "text-h5 mb-0 text-uppercase" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.showPreviewItem.name
                                ? _vm.showPreviewItem.name
                                : ""
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("br"),
                  _c("small", { staticClass: "mt-0" }),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showImage = false
                        },
                      },
                    },
                    [_vm._v(" Fermer ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showImage,
            callback: function ($$v) {
              _vm.showImage = $$v
            },
            expression: "showImage",
          },
        },
        [
          !_vm.showPreviewItem.data
            ? [
                _c("v-progress-circular", {
                  attrs: {
                    color: "primary",
                    indeterminate: "",
                    size: 54,
                    width: 5,
                  },
                }),
              ]
            : [
                _c(
                  "v-card-text",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticStyle: {
                          position: "absolute",
                          right: "-5px",
                          top: "-5px",
                        },
                        attrs: { fab: "", small: "", color: "secondary" },
                        on: {
                          click: function ($event) {
                            return _vm.download(_vm.showPreviewItem)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("fa-download fa-fw"),
                        ]),
                      ],
                      1
                    ),
                    _c("img", {
                      staticStyle: { "max-width": "100%" },
                      attrs: { src: _vm.showPreviewItem.data },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c(
        "modal",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Import de pièces jointes"),
                  ]),
                  _c("br"),
                  _c("small", { staticClass: "mt-0" }, [
                    _vm._v("Veillez à limiter la taille de vos pièces"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        variant: "flat",
                        color: "primary",
                        elevation: "0",
                      },
                      on: { click: _vm.postFiles },
                    },
                    [_vm._v(" Enregistrer ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.fileModal = false
                        },
                      },
                    },
                    [_vm._v(" Fermer ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.fileModal,
            callback: function ($$v) {
              _vm.fileModal = $$v
            },
            expression: "fileModal",
          },
        },
        [
          _c("v-file-input", {
            staticClass: "mt-5",
            attrs: {
              "small-chips": "",
              label: "Lier un fichier",
              outlined: "",
              "persistent-placeholder": "",
            },
            model: {
              value: _vm.filesUp,
              callback: function ($$v) {
                _vm.filesUp = $$v
              },
              expression: "filesUp",
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mx-1" },
        [
          _vm.listTotalDevis.length == 0 && _vm.files.length == 0
            ? _c(
                "v-col",
                {
                  staticClass:
                    "grey--text text--lighten-1 text-center font-italic mt-2",
                },
                [_vm._v(" Aucun document joint ")]
              )
            : _vm._e(),
          _vm._l(_vm.listTotalDevis, function (item, key) {
            return _c(
              "v-col",
              { key: key, attrs: { cols: "12", md: "6", lg: "4" } },
              [
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ hover }) {
                          return [
                            _c(
                              "v-card",
                              {
                                staticClass: "mx-auto text-center",
                                attrs: { elevation: hover ? 2 : 1 },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDevis(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "green lighten-1 white--text",
                                        staticStyle: {
                                          position: "absolute",
                                          left: "0",
                                          top: "10px",
                                          "border-radius": "0 3px 3px 0",
                                          "font-size": "0.8em",
                                          padding: "1px 5px",
                                        },
                                      },
                                      [_vm._v("SAGE")]
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "5px",
                                          right: "5px",
                                        },
                                        attrs: {
                                          "x-small": "",
                                          color: "error",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.removeQuotation(
                                              item.header.id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("fa-trash-alt fa-fw ")]
                                    ),
                                    _c("v-icon", { attrs: { "x-large": "" } }, [
                                      _vm._v("far fa-file-alt"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text--grey mt-4",
                                        staticStyle: {
                                          color: "grey",
                                          "font-size": "0.7rem",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " DEVIS " +
                                            _vm._s(
                                              item.header.name
                                                ? item.header.name + " - "
                                                : ""
                                            ) +
                                            _vm._s(item.header.sageIncrement) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            )
          }),
          _vm._l(_vm.files, function (item, key) {
            return _c(
              "v-col",
              { key: key, attrs: { cols: "12", md: "6", lg: "6", xl: "4" } },
              [
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ hover }) {
                          return [
                            _c(
                              "v-card",
                              {
                                staticClass: "mx-auto text-center",
                                attrs: { elevation: hover ? 2 : 1 },
                                on: {
                                  click: function ($event) {
                                    return _vm.openSecureFile(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  [
                                    !item.preview
                                      ? _c(
                                          "v-icon",
                                          { attrs: { "x-large": "" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getIcon(
                                                    _vm.getExtension(item.file)
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c("div", {
                                          style:
                                            "height: 40px; width: 100%;background-image:url(" +
                                            item.preview +
                                            ");background-size:cover;background-position:center center",
                                        }),
                                    _c("div", { staticClass: "mt-4" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "grey",
                                            "font-size": "0.7rem",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.getFileName(item.name))
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "modal",
        {
          attrs: { "max-width": "1024" },
          scopedSlots: _vm._u(
            [
              _vm.showingItem
                ? {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "strong",
                          { staticClass: "text-h5 mb-0 text-uppercase" },
                          [
                            _vm._v(
                              "Devis " +
                                _vm._s(_vm.showingItem.header.sageIncrement)
                            ),
                          ]
                        ),
                        _c("br"),
                        _c(
                          "small",
                          { staticClass: "mt-0" },
                          [
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.showingItem.header.name
                                      ? _vm.showingItem.header.name
                                      : ""
                                  ) +
                                  " "
                              ),
                            ],
                          ],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
          model: {
            value: _vm.devisShowModal,
            callback: function ($$v) {
              _vm.devisShowModal = $$v
            },
            expression: "devisShowModal",
          },
        },
        [
          _vm.showingItem
            ? _c(
                "div",
                [
                  _c("v-simple-table", {
                    attrs: { dense: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v("Article"),
                                  ]),
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v("Nom"),
                                  ]),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("Prix unitaire"),
                                  ]),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("Quantité"),
                                  ]),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("Remise"),
                                  ]),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("Total"),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(_vm.showingItem.item, function (item) {
                                  return _c("tr", { key: item.name }, [
                                    _c("td", { staticClass: "text-left" }, [
                                      _vm._v(_vm._s(item.sku)),
                                    ]),
                                    _c("td", { staticClass: "text-left" }, [
                                      _vm._v(_vm._s(item.body)),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.quantity
                                            ? _vm.numStr(item.unitPrice)
                                            : ""
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.quantity ? item.quantity : ""
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(item.quantity ? item.rebate : "")
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.quantity
                                            ? _vm.numStr(
                                                item.unitPrice * item.quantity
                                              )
                                            : ""
                                        )
                                      ),
                                    ]),
                                  ])
                                }),
                                0
                              ),
                              _c("tfoot", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-right font-weight-bold",
                                      attrs: { colspan: "5" },
                                    },
                                    [_vm._v("Total :")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-right font-weight-bold",
                                      attrs: { colspan: "1" },
                                    },
                                    [_vm._v(_vm._s(_vm.numStr(_vm.getTotal())))]
                                  ),
                                ]),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2737165730
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }