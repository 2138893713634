import { render, staticRenderFns } from "./dashboardOpportunities.vue?vue&type=template&id=6182c335&scoped=true&"
import script from "./dashboardOpportunities.vue?vue&type=script&lang=js&"
export * from "./dashboardOpportunities.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6182c335",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Actuelburo\\srv\\jenkins\\crm-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6182c335')) {
      api.createRecord('6182c335', component.options)
    } else {
      api.reload('6182c335', component.options)
    }
    module.hot.accept("./dashboardOpportunities.vue?vue&type=template&id=6182c335&scoped=true&", function () {
      api.rerender('6182c335', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/opportunities/dashboardOpportunities.vue"
export default component.exports