import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/fr";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.locale("fr");

const Tools = {
  methods: {
    dayjs,
    getPhase(item) {
      let now = dayjs();
      let ret = { color: "error", name: "Erreur" };

      if (dayjs(item.startDate).isAfter(now)) {
        return { color: "warning", name: "En attente" };
      }
      function checkPhase(date, text, color = "") {
        if (dayjs(date).isBefore(now)) {
          ret = { color: color, name: text };
        }
      }
      checkPhase(item.startDate, "Démarré", "#99e2b4");
      checkPhase(item.phase1date, "Phase 1", "#78c6a3");
      checkPhase(item.phase2date, "Phase 2", "#56ab91");
      checkPhase(item.phase3date, "Phase 3", "#358f80");
      checkPhase(item.phase4date, "Phase 4", "#C14746f");
      checkPhase(item.endDate, "Terminé", "#198754");

      return ret;
    },
    checkArrayContains(val, headersColumn) {
      return headersColumn.some((e) => e.value === val);
    },
    isGranted(role) {
      if (typeof role === "string") {
        let str = "ROLE_" + role;
        if (str === "ROLE_") return true;
        if (Object.keys(this.$store.state.user).length === 0 || !this.$store.state.user.roles || this.$store.state.user.roles.length === 0) {
          return false;
        }
        return Object.keys(this.$store.state.user).length > 0 ? this.$store.state.user.roles.includes(str) : false;
      } else {
        const included = role.filter((r) => this.isGranted(r));
        return included.length > 0;
      }
    },
    // DAYJS cause des bugs pour formatDate et parseDate et à été exclus volontairement.
    formatDate(date) {
      if (!date) return null;

      return dayjs(date).isValid() ? dayjs(date).format("DD/MM/YYYY") : null;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      if (!day || !month || !year) return null;

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
export default Tools;
