var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MaterialCard",
    {
      staticClass: "mt-10",
      attrs: { title: "Commentaires", icon: "fa-comment" },
    },
    [
      false
        ? _c(
            "div",
            [
              [
                _c(
                  "v-tabs",
                  {
                    attrs: {
                      "fixed-tabs": "",
                      "background-color": "transparent",
                      "slider-color": "white",
                    },
                    model: {
                      value: _vm.tab,
                      callback: function ($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab",
                    },
                  },
                  [
                    _c("v-tab", { staticClass: "mr-3" }, [
                      _vm._v(" Commentaire "),
                    ]),
                    _vm.isGranted("OPPORTUNITIES_EDIT_HISTORY")
                      ? _c("v-tab", [_vm._v(" Historique ")])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm.isGranted("OPPORTUNITIES_EDIT_COMMENT")
        ? _c(
            "div",
            { staticClass: "listbox--actions-add" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", color: "primary", fab: "" },
                  on: {
                    click: function ($event) {
                      _vm.showAddComment = !_vm.showAddComment
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fa-fw fa-plus"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAddComment,
                  expression: "showAddComment",
                },
              ],
              attrs: { elevation: "0" },
            },
            [
              _c(
                "v-card-text",
                { staticClass: "text-right" },
                [
                  _c("v-textarea", {
                    staticClass: "mb-3",
                    attrs: {
                      "auto-grow": "",
                      "hide-details": "",
                      rows: "3",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "Nouveau commentaire",
                    },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        dark: "",
                        elevation: "0",
                        small: "",
                      },
                      on: { click: _vm.addComment },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { "x-small": "" } },
                        [_vm._v("fa-check fa-fw")]
                      ),
                      _vm._v(" Ajouter "),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mt-5" }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Rechercher un commentaire" },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.comments &&
              _vm.comments.events &&
              _vm.comments.events.length > 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "max-height": "400px",
                        "overflow-y": "scroll",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-timeline",
                            { attrs: { "align-top": "", dense: "" } },
                            _vm._l(_vm.comments.events, function (item, i) {
                              return _c(
                                "v-timeline-item",
                                {
                                  key: i,
                                  staticClass: "my-0 mb-4 pl-0",
                                  attrs: {
                                    small: "",
                                    color:
                                      Object.keys(item).length > 6 &&
                                      _vm.events.type
                                        ? _vm.events.type[item.eventtypeId]
                                            .color
                                        : "error",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [
                                            _c("UserAvatar", {
                                              attrs: {
                                                userId: item.userId,
                                                size: "40",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "white",
                                                staticStyle: {
                                                  position: "absolute",
                                                  top: "35px",
                                                  "text-align": "center",
                                                  "font-size": "0.8rem",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm
                                                        .dayjs(item.createdAt)
                                                        .format("DD/MM/YYYY")
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm
                                                        .dayjs(item.createdAt)
                                                        .format("HH:mm")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      class: {
                                        eventstandby: item.status == 0,
                                        eventsuccess: item.status == 1,
                                        eventcancel: item.status == 2,
                                      },
                                    },
                                    [
                                      _c("v-card-text", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.body
                                                ? item.body
                                                : "Pas de commentaire"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "font-weight-light grey--text mb-5 text-center",
                    },
                    [_vm._v("Aucun commentaire")]
                  ),
            ],
            1
          ),
          _vm.isGranted("OPPORTUNITIES_EDIT_HISTORY")
            ? _c("v-tab-item", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "max-height": "400px",
                      "overflow-y": "scroll",
                    },
                  },
                  [
                    _c(
                      "v-timeline",
                      { attrs: { "align-top": "", dense: "" } },
                      _vm._l(_vm.history, function (item, i) {
                        return _c(
                          "v-timeline-item",
                          {
                            key: i,
                            attrs: { small: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "icon",
                                  fn: function () {
                                    return [
                                      _c("UserAvatar", {
                                        attrs: {
                                          userId: item.timeline.userId,
                                          size: "40",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "v-row",
                              { staticClass: "pt-1 px-0" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "1" } },
                                  [
                                    _c("v-icon", { attrs: { "x-small": "" } }, [
                                      _vm._v(
                                        _vm._s(_vm.returnIcon(item.status))
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("v-col", { staticClass: "my-0 py-1" }, [
                                  _c(
                                    "strong",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v(" fa-history ")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(item.timeline.action) +
                                          " " +
                                          _vm._s(
                                            item.timeline.commentId
                                              ? "commentaire"
                                              : ""
                                          ) +
                                          " " +
                                          _vm._s(
                                            item.timeline.eventId ? "event" : ""
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "text-caption" },
                                    [
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass: "white text--primary",
                                          staticStyle: { padding: "0.5rem" },
                                        },
                                        [
                                          _vm._v(
                                            " le " +
                                              _vm._s(
                                                _vm
                                                  .dayjs(
                                                    item.timeline.createdAt
                                                  )
                                                  .format("DD/MM/YYYY à HH:mm")
                                              ) +
                                              " "
                                          ),
                                          item.timelineField
                                            ? _c(
                                                "v-row",
                                                [
                                                  _vm.isJsonString(
                                                    item.timelineField.oldValue
                                                  ) &&
                                                  JSON.parse(
                                                    item.timelineField.oldValue
                                                  )
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "12",
                                                          },
                                                        },
                                                        [
                                                          _c("tree-view", {
                                                            attrs: {
                                                              data: JSON.parse(
                                                                item
                                                                  .timelineField
                                                                  .oldValue
                                                              ),
                                                              options: {
                                                                maxDepth: 0,
                                                                rootObjectKey:
                                                                  "Ancienne Valeur",
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.isJsonString(
                                                    item.timelineField.newValue
                                                  ) &&
                                                  JSON.parse(
                                                    item.timelineField.newValue
                                                  )
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "12",
                                                          },
                                                        },
                                                        [
                                                          _c("tree-view", {
                                                            attrs: {
                                                              data: JSON.parse(
                                                                item
                                                                  .timelineField
                                                                  .newValue
                                                              ),
                                                              options: {
                                                                maxDepth: 0,
                                                                rootObjectKey:
                                                                  "Nouvelle Valeur",
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  !_vm.isJsonString(
                                                    item.timelineField.newValue
                                                  ) ||
                                                  !JSON.parse(
                                                    item.timelineField.newValue
                                                  )
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "12",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item
                                                                  .timelineField
                                                                  .newValue
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }