import { render, staticRenderFns } from "./customerOpportunities.vue?vue&type=template&id=5524f1eb&"
import script from "./customerOpportunities.vue?vue&type=script&lang=js&"
export * from "./customerOpportunities.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Actuelburo\\srv\\jenkins\\crm-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5524f1eb')) {
      api.createRecord('5524f1eb', component.options)
    } else {
      api.reload('5524f1eb', component.options)
    }
    module.hot.accept("./customerOpportunities.vue?vue&type=template&id=5524f1eb&", function () {
      api.rerender('5524f1eb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/opportunities/customerOpportunities.vue"
export default component.exports