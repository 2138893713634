var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    _vm._b(
      {
        staticClass: "v-card--material pa-1 mx-1",
        class: _vm.classes,
        staticStyle: { "z-index": "10" },
        attrs: {
          height: _vm.heightTot,
          color: _vm.background,
          elevation: _vm.elevation,
        },
      },
      "v-card",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "div",
        { staticClass: "d-flex grow flex-wrap" },
        [
          _vm.avatar
            ? _c("UserAvatar", {
                staticClass: "mx-auto v-card--material__avatar elevation-6",
                attrs: { userId: _vm.avatar, size: "53", color: "grey" },
              })
            : _vm._e(),
          _vm.avatarText
            ? _c(
                "v-chip",
                {
                  staticClass:
                    "mx-auto v-card--material__avatar elevation-6 white--text",
                  attrs: { label: "", large: "", color: _vm.color },
                },
                [_c("h1", [_vm._v(_vm._s(_vm.avatarText))])]
              )
            : _vm._e(),
          !_vm.avatar && _vm.header && !_vm.title
            ? _c(
                "v-sheet",
                {
                  staticClass: "text-start v-card--material__heading mb-n6",
                  class: {
                    "pa-1": !_vm.$slots.image,
                  },
                  attrs: {
                    rounded: "",
                    color: _vm.color,
                    "max-height": !_vm.isMobile() ? 60 : "auto",
                    width: "auto",
                    elevation: "0",
                    dark: "",
                  },
                },
                [
                  _vm.$slots.heading
                    ? _vm._t("heading")
                    : _vm.$slots.image
                    ? _vm._t("image")
                    : _vm.title && !_vm.icon
                    ? _c("div", {
                        staticClass: "display-1 font-weight-light",
                        domProps: { textContent: _vm._s(_vm.title) },
                      })
                    : _vm.icon
                    ? _c("v-icon", {
                        attrs: { small: "" },
                        domProps: { textContent: _vm._s(_vm.icon) },
                      })
                    : _vm._e(),
                  _vm.text
                    ? _c("div", {
                        staticClass: "headline font-weight-thin",
                        domProps: { textContent: _vm._s(_vm.text) },
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.$slots["after-heading"]
            ? _c("div", { staticClass: "ml-6" }, [_vm._t("after-heading")], 2)
            : _vm.icon || _vm.title
            ? _c(
                "div",
                { staticClass: "ml-4" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "card-title font-weight-bold pa-1",
                      staticStyle: {
                        "margin-top": "-20px",
                        width: "150px",
                        "text-align": "left",
                        display: "block",
                      },
                      attrs: {
                        label: "",
                        color: _vm.color,
                        "text-color": "white",
                        dark: "",
                        elevation: "0",
                      },
                    },
                    [
                      _vm.icon
                        ? _c(
                            "v-icon",
                            { staticClass: "mx-1", attrs: { "x-small": "" } },
                            [_vm._v(_vm._s(_vm.icon) + " fa-fw")]
                          )
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.title) + " "),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { style: { marginTop: _vm.$slots.heading ? 0 : "25px" } },
        [_vm._t("default")],
        2
      ),
      _vm.$slots.actions
        ? [
            _c("v-divider", { staticClass: "mt-2" }),
            _c(
              "v-card-actions",
              { staticClass: "pb-0" },
              [_vm._t("actions")],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }