var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "py-0" },
        [
          _c(
            "v-col",
            { attrs: { cols: "11" } },
            [
              _c("step-phase-compaign", {
                model: {
                  value: _vm.compaign,
                  callback: function ($$v) {
                    _vm.compaign = $$v
                  },
                  expression: "compaign",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { cols: "1" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", fab: "", color: "primary" },
                  on: { click: _vm.editRoute },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fa-fw fa-edit"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4 mr-1",
                  attrs: { small: "", fab: "", color: "error" },
                  on: { click: _vm.deleteRoute },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fa-fw fa-trash-alt"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "py-0 mt-4" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("info-compaign", {
                model: {
                  value: _vm.compaign,
                  callback: function ($$v) {
                    _vm.compaign = $$v
                  },
                  expression: "compaign",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [_c("stats-compaign")],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "py-0 mt-4" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("goal-compaign", {
                model: {
                  value: _vm.curItem,
                  callback: function ($$v) {
                    _vm.curItem = $$v
                  },
                  expression: "curItem",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("event-compaign", {
                attrs: { "cur-item": _vm.curItem },
                on: {
                  "update:curItem": function ($event) {
                    _vm.curItem = $event
                  },
                  "update:cur-item": function ($event) {
                    _vm.curItem = $event
                  },
                },
                model: {
                  value: _vm.compaign,
                  callback: function ($$v) {
                    _vm.compaign = $$v
                  },
                  expression: "compaign",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }