var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isGranted("CUSTOMERS_INDEX")
        ? _c(
            "v-card",
            { staticStyle: { "z-index": "99" }, attrs: { elevation: "1" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary", dark: "", elevation: "0" } },
                [
                  _c(
                    "div",
                    { staticClass: "listbox--actions-add" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            fab: "",
                            color: "primary",
                            dark: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.newClient = {
                                name: "",
                                ape: "",
                                ctNumAb: "",
                                siret: "",
                                adresse: "",
                                adresse2: "",
                                postalCode: "",
                                city: "",
                                isIndirect: "",
                                peopleId: null,
                                email: "",
                                phone: "",
                                phone2: "",
                                site: "",
                              }
                              _vm.modalAdd = true
                            },
                          },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { small: "" } },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" fa-fw fa-plus ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1508421645
                              ),
                            },
                            [_c("span", [_vm._v("Ajouter")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-card-title", [
                    _c("div", { staticClass: "d-flex align-center" }, [
                      _c("div", [
                        _c(
                          "h3",
                          {
                            staticClass: "mb-2",
                            staticStyle: { "min-width": "95px" },
                          },
                          [_vm._v("Clients")]
                        ),
                      ]),
                      _c(
                        "div",
                        [
                          _c("RapidSearchCustomers", {
                            on: { selectedRapidSearch: _vm.rapidSearch },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        style: _vm.isMobile()
                          ? {
                              marginLeft: "20px",
                            }
                          : {
                              position: "absolute",
                              top: "0",
                              left: "50%",
                              background: "#78909c",
                              padding: "5px",
                              transform: "translate(-50%)",
                              borderRadius: "0 0 5px 5px",
                            },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", small: "" },
                            on: {
                              click: function ($event) {
                                _vm.modalSettings = true
                              },
                            },
                          },
                          [
                            _vm.isGranted("CUSTOMERS_INDEX_ALL") ||
                            _vm.isGranted("AGENCY_CUSTOMERS")
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" fa-fw fa-filter ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      725421783
                                    ),
                                  },
                                  [_c("span", [_vm._v("Filtrer")])]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "content-class": "mt-2",
                              "close-on-content-click": false,
                              "offset-y": "",
                              right: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { text: "", small: "" } },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " fa-fw fa-cog "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Colonnes")])]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1154628350
                            ),
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "py-0",
                                    attrs: {
                                      color: "primary",
                                      dark: "",
                                      elevation: "0",
                                      height: "45px",
                                    },
                                  },
                                  [_vm._v(" Colonnes visibles ")]
                                ),
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "pa-1 pb-3",
                                    staticStyle: {
                                      "min-width": "180px",
                                      "max-height": "calc(100vh - 115px)",
                                      "overflow-y": "auto",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      _vm._l(
                                        _vm.columnsAvailable,
                                        function (item, key) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: key,
                                              staticStyle: {
                                                "min-height": "40px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleItem(
                                                    item,
                                                    key
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _vm.checkArrayContains(
                                                    item.value,
                                                    _vm.headers
                                                  )
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            small: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "far fa-fw fa-check-square "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "far fa-fw fa-square"
                                                          ),
                                                        ]
                                                      ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "ml-1" },
                                                    [_vm._v(_vm._s(item.text))]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-0",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.customersList,
                  "server-items-length": _vm.maxItem,
                  "items-per-page": _vm.limitItem,
                  page: _vm.page,
                  loading: _vm.loading,
                  "sort-by": _vm.sortedBy,
                  "sort-desc": _vm.sortedDesc,
                  options: _vm.tableOptions,
                  "footer-props": {
                    showFirstLastPage: true,
                    itemsPerPageOptions: [5, 10, 15, 25, 50],
                    firstIcon: "fa-angle-double-left",
                    lastIcon: "fa-angle-double-right",
                    prevIcon: "fa-angle-left",
                    nextIcon: "fa-angle-right",
                  },
                  "hide-default-header": _vm.isMobile(),
                },
                on: {
                  "update:items-per-page": _vm.setItemMax,
                  "update:page": _vm.setPage,
                  "click:row": _vm.loadCustomer,
                  "update:options": _vm.setPageOptions,
                  "update:sortBy": function ($event) {
                    _vm.sortedBy = $event
                  },
                  "update:sort-by": function ($event) {
                    _vm.sortedBy = $event
                  },
                  "update:sortDesc": function ($event) {
                    _vm.sortedDesc = $event
                  },
                  "update:sort-desc": function ($event) {
                    _vm.sortedDesc = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.isClient",
                      fn: function ({ item }) {
                        return _vm._l(item.isClient, function (infra, key) {
                          return _c(
                            "v-tooltip",
                            {
                              key: key,
                              attrs: { color: infra.color, bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mr-1 mb-1",
                                                attrs: {
                                                  label: "",
                                                  depressed: "",
                                                  color: infra.color,
                                                  outlined:
                                                    !item.custoKey.includes(
                                                      infra.id
                                                    ),
                                                  small: "",
                                                },
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-small": "" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      infra.icon
                                                        ? infra.icon
                                                        : "fa-star"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-center grey--text text--darken-4",
                                },
                                [_vm._v(_vm._s(infra.name))]
                              ),
                            ]
                          )
                        })
                      },
                    },
                    {
                      key: "item.custoKey",
                      fn: function ({ item }) {
                        return _vm._l(
                          item.custoKey,
                          function (customerKeyId, key) {
                            return _c(
                              "v-tooltip",
                              {
                                key: key,
                                attrs: {
                                  color: _vm.findInfraColor(customerKeyId),
                                  bottom: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-1 mb-1",
                                                  attrs: {
                                                    label: "",
                                                    depressed: "",
                                                    color:
                                                      _vm.findInfraColor(
                                                        customerKeyId
                                                      ),
                                                    outlined: false,
                                                    small: "",
                                                  },
                                                },
                                                "v-chip",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-small": "" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.findInfraIcon(
                                                          customerKeyId
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-center grey--text text--darken-4",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.findInfraName(customerKeyId))
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        )
                      },
                    },
                    {
                      key: "item.customer.isIndirect",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                color: item.customer.isIndirect
                                  ? "success"
                                  : "error",
                              },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  _vm._s(
                                    item.customer.isIndirect
                                      ? "fa-check"
                                      : "fa-not-equal"
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.entreprise.adresse.postalCode",
                      fn: function ({ item }) {
                        return [
                          item.entreprise.adresse
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(item.entreprise.adresse.postalCode) +
                                    " " +
                                    _vm._s(item.entreprise.adresse.city)
                                ),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.affectation",
                      fn: function ({ item }) {
                        return _vm._l(item.users, function (user, key) {
                          return _c(
                            "div",
                            {
                              key: key,
                              staticClass: "customer-list--affectation-badge",
                            },
                            [
                              user.dateEnd === null
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        color: user.typeElem.color,
                                        bottom: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                user.type
                                                  ? _c(
                                                      "v-badge",
                                                      {
                                                        attrs: {
                                                          bordered: "",
                                                          bottom: "",
                                                          color:
                                                            user.typeElem.color,
                                                          dot: "",
                                                          "offset-x": "10",
                                                          "offset-y": "10",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "UserAvatar",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  size: "40",
                                                                  userId:
                                                                    user.id,
                                                                },
                                                              },
                                                              "UserAvatar",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-center grey--text text--darken-4",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                user.typeElem &&
                                                  user.typeElem.name
                                                  ? user.typeElem.name
                                                  : "Pas de service"
                                              )
                                            ),
                                          ]),
                                          _c("br"),
                                          _c(
                                            "span",
                                            { staticClass: "text-capitalize" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  user.firstname.toLowerCase()
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(user.lastname) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        })
                      },
                    },
                    {
                      key: "item.groups",
                      fn: function ({ item }) {
                        return _vm._l(item.groups, function (group, key) {
                          return _c(
                            "v-chip",
                            {
                              key: key,
                              staticClass: "mr-1",
                              attrs: { small: "", label: "" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.groups[parseInt(group - 1)].name) +
                                  " "
                              ),
                            ]
                          )
                        })
                      },
                    },
                    {
                      key: "body.prepend",
                      fn: function () {
                        return [
                          _c("tr", [
                            _vm.checkArrayContains("entreprise.id", _vm.headers)
                              ? _c("td")
                              : _vm._e(),
                            _vm.checkArrayContains(
                              "entreprise.name",
                              _vm.headers
                            )
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "text",
                                        label: "Rechercher",
                                      },
                                      model: {
                                        value: _vm.search.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "name", $$v)
                                        },
                                        expression: "search.name",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains(
                              "entreprise.siret",
                              _vm.headers
                            )
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "text",
                                        label: "Rechercher",
                                      },
                                      model: {
                                        value: _vm.search.siret,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "siret", $$v)
                                        },
                                        expression: "search.siret",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains(
                              "entreprise.adresse.postalCode",
                              _vm.headers
                            )
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Rechercher",
                                        type: "text",
                                      },
                                      model: {
                                        value: _vm.search.cityPostalCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "cityPostalCode",
                                            $$v
                                          )
                                        },
                                        expression: "search.cityPostalCode",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains("groups", _vm.headers)
                              ? _c(
                                  "td",
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.groups,
                                        "item-text": "name",
                                        "item-value": "id",
                                        label: "Rechercher",
                                        multiple: "",
                                        chips: "",
                                      },
                                      model: {
                                        value: _vm.search.groups,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "groups", $$v)
                                        },
                                        expression: "search.groups",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains("isClient", _vm.headers)
                              ? _c("td")
                              : _vm._e(),
                            _vm.checkArrayContains("custoKey", _vm.headers)
                              ? _c("td")
                              : _vm._e(),
                            _vm.checkArrayContains("affectation", _vm.headers)
                              ? _c("td")
                              : _vm._e(),
                            _vm.checkArrayContains("sector.name", _vm.headers)
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "Rechercher" },
                                      model: {
                                        value: _vm.search.sector,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "sector", $$v)
                                        },
                                        expression: "search.sector",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains(
                              "entreprise.phone",
                              _vm.headers
                            )
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "Rechercher" },
                                      model: {
                                        value: _vm.search.phone,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "phone", $$v)
                                        },
                                        expression: "search.phone",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains(
                              "customer.ctNumAb",
                              _vm.headers
                            )
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "Rechercher" },
                                      model: {
                                        value: _vm.search.ctNumAb,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "ctNumAb", $$v)
                                        },
                                        expression: "search.ctNumAb",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains("customer.ape", _vm.headers)
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "Rechercher" },
                                      model: {
                                        value: _vm.search.ape,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "ape", $$v)
                                        },
                                        expression: "search.ape",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains("people.name", _vm.headers)
                              ? _c("td")
                              : _vm._e(),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1731104053
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "modal",
        {
          attrs: { maxWidth: "700" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Filtrer"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.modalSettings,
            callback: function ($$v) {
              _vm.modalSettings = $$v
            },
            expression: "modalSettings",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: ["tous", "au moins", "aucun"],
                      label: "Mode de recherche",
                      "persistent-placeholder": "",
                      chips: "",
                    },
                    model: {
                      value: _vm.usersSearch.mode,
                      callback: function ($$v) {
                        _vm.$set(_vm.usersSearch, "mode", $$v)
                      },
                      expression: "usersSearch.mode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _vm.isGranted("CUSTOMERS_INDEX_ALL")
                    ? _c("listUser", {
                        attrs: { includeAll: "" },
                        on: { selectChange: _vm.setUser },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: ["tous", "au moins", "aucun"],
                      label: "Mode de recherche",
                      "persistent-placeholder": "",
                      chips: "",
                    },
                    model: {
                      value: _vm.servicesSearch.mode,
                      callback: function ($$v) {
                        _vm.$set(_vm.servicesSearch, "mode", $$v)
                      },
                      expression: "servicesSearch.mode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _vm.isGranted("CUSTOMERS_INDEX_ALL")
                    ? _c("ListServices", {
                        attrs: { allInfras: _vm.allInfras },
                        on: { selectChange: _vm.setServices },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.isGranted("CUSTOMERS_INDEX_ALL")
                ? _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Afficher les prospects" },
                        model: {
                          value: _vm.prospectOnly,
                          callback: function ($$v) {
                            _vm.prospectOnly = $$v
                          },
                          expression: "prospectOnly",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isGranted("CUSTOMERS_INDEX_ALL")
                ? _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Afficher les clients" },
                        model: {
                          value: _vm.clientOnly,
                          callback: function ($$v) {
                            _vm.clientOnly = $$v
                          },
                          expression: "clientOnly",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-switch", {
                    attrs: { label: "Uniquement liés à une campagne" },
                    model: {
                      value: _vm.campaignOnly,
                      callback: function ($$v) {
                        _vm.campaignOnly = $$v
                      },
                      expression: "campaignOnly",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-switch", {
                    attrs: { label: "Uniquement les clients clés" },
                    model: {
                      value: _vm.customerKey,
                      callback: function ($$v) {
                        _vm.customerKey = $$v
                      },
                      expression: "customerKey",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ClientFormCustomers", {
        on: { refresh: _vm.getCustomersList },
        model: {
          value: _vm.modalAdd,
          callback: function ($$v) {
            _vm.modalAdd = $$v
          },
          expression: "modalAdd",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }