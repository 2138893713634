<template>
  <v-container fluid>
    <v-card style="z-index: 10" v-if="isGranted('EVENTS_SETTINGS')">
      <v-toolbar color="primary " dark elevation="0">
        <v-row>
          <v-col cols="11">
            <v-text-field v-model="search" label="Rechercher" single-line prepend-icon="fa-search" hide-details></v-text-field>
          </v-col>
          <v-col cols="1" class="text-center">
            <v-btn
              @click="
                edition = {
                  name: '',
                  shortcut: false,
                  teams: null,
                  duration: null,
                  defaultText: '',
                  report: false,
                  ewsCalendar: false,
                  icon: '',
                  colorArr: { r: 0, g: 0, b: 0, a: 1 },
                };
                editModal = true;
              "
              color="primary"
              small
              fab
              class="listbox--actions-add"
            >
              <v-icon small>fa-plus fa-fw</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-data-table :headers="headers" :items="types" :search.sync="search">
        <template v-slot:item.action="{ item }">
          <v-icon
            @click="
              edition = JSON.parse(JSON.stringify(item));
              editModal = true;
            "
            small
            v-if="isGranted('EVENTS_SETTINGS')"
            >fa-edit</v-icon
          >
        </template>

        <template v-slot:item.color="{ item }">
          <v-chip label :color="item.color" dark> &nbsp; </v-chip>
        </template>

        <template v-slot:item.teams="{ item }">
          <v-icon small label :color="item.teams ? 'success' : 'error'" dark> {{ item.teams ? "fa-check" : "fa-times" }} fa-fw </v-icon>
        </template>

        <template v-slot:item.icon="{ item }">
          <v-icon small>{{ item.icon }}</v-icon>
        </template>

        <template v-slot:item.shortcut="{ item }">
          <v-icon small label :color="item.shortcut ? 'success' : 'error'" dark> {{ item.shortcut ? "fa-check" : "fa-times" }} fa-fw </v-icon>
        </template>
        <template v-slot:item.inForm="{ item }">
          <v-icon small :color="item.inForm ? 'success' : 'error'" dark> {{ item.inForm ? "fa-check" : "fa-times" }} fa-fw </v-icon>
        </template>
      </v-data-table>

      <modal v-model="editModal" max-width="1024">
        <template v-slot:header>
          {{ edition && edition.id ? "Édition" : "Ajout" }}
        </template>
        <v-row v-if="edition">
          <v-col cols="8">
            <v-text-field outlined label="Nom" v-model="edition.name"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select outlined :menu-props="{ maxHeight: '300' }" v-model="edition.icon" label="Icône" :items="icons">
              <template v-slot:selection="{ item }">
                <v-icon small label>{{ item }}</v-icon>
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center" class="text-center">
                        <v-icon label :color="edition.color ? edition.color : 'primary'">{{ item }}</v-icon>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-select outlined :menu-props="{ maxHeight: '300' }" v-model="edition.color" label="Couleur" :items="colors">
              <template v-slot:selection="{ item }">
                <v-chip small label :color="item">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-chip>
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <v-chip small label :color="item">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-chip>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field outlined label="Durée par défaut" type="number" v-model="edition.duration"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea outlined label="Texte par défaut" v-model="edition.defaultText"></v-textarea>
          </v-col>
          <v-col cols="3">
            <v-switch label="Teams" v-model="edition.teams"></v-switch>
          </v-col>
          <v-col cols="3">
            <v-switch label="Calendrier Outlook" v-model="edition.ewsCalendar"></v-switch>
          </v-col>
          <v-col cols="3">
            <v-switch label="Raccourci" v-model="edition.shortcut"></v-switch>
          </v-col>
          <v-col cols="3">
            <v-switch label="Formulaire" v-model="edition.inForm"></v-switch>
          </v-col>
          <!-- NON FONCTIONNEL EN BACK -->
          <!-- <v-col cols="12" class="pt-0 text-overline text-center">
            STATISTIQUES
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6">
            <v-switch label="Prise en compte statistiques relations" v-model="edition.countStats"></v-switch>
          </v-col>
          <v-col cols="6" v-if="edition.countStats">
            <v-switch label="Prise en compte statistiques relations (rdv)" v-model="edition.countMeet"></v-switch>
          </v-col> -->
        </v-row>
        <template v-slot:actions>
          <v-btn color="error" text @click="editModal = false"> Fermer </v-btn>
          <v-btn text class="mr-4" @click="saveType"> Enregistrer </v-btn>
        </template>
      </modal>
    </v-card>
  </v-container>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Tools from "../../mixins/Tools";
import Modal from "../../components/common/modal";

export default {
  name: "userSettings",
  components: { Modal },
  mixins: [simpleDebounce, Tools],

  data() {
    return {
      editModal: false,
      edition: null,
      icons: [
        "fa-laptop",
        "fa-phone",
        "far fa-copy",
        "fa-glasses",
        "fa-search",
        "fa-briefcase",
        "far fa-envelope",
        "fas fa-running",
        "far fa-dot-circle",
        "fas fa-car",
        "far fa-id-card",
        "far fa-hand-peace",
        "fas fa-map-marker-alt",
        "far fa-lightbulb",
        "fas fa-mask",
      ],
      colors: ["#aed581", "#b2dfdb", "#ff7961", "#ffcc80", "#c5cae9", "#81d4fa", "#80deea", "#4db6ac", "#a5d6a7"],
      headers: [
        { text: "#", value: "id", align: "left" },
        { text: "Nom", value: "name", align: "left" },
        { text: "Durée par défaut", value: "duration", align: "center" },
        { text: "Icône", sortable: false, value: "icon", align: "center" },
        { text: "Raccourci", sortable: false, value: "shortcut", align: "center" },
        { text: "Formulaire", sortable: false, value: "inForm", align: "center" },
        { text: "Couleur", sortable: false, value: "color", align: "center" },
        { text: "Teams", sortable: false, value: "teams", align: "center" },

        { text: "", value: "action", align: "right" },
      ],
      types: [],
      search: "",
    };
  },
  mounted() {
    this.getTypes();
  },

  methods: {
    saveType() {
      if (this.edition.name.length === 0) {
        this.$simpleToast.error("Type d'évènements", "Un des champs est vide");

        return;
      }
      if (this.edition.id) {
        this.$axios.patch("/api/events/types/" + this.edition.id, { data: this.edition }).then(() => {
          this.getTypes();
          this.$simpleToast.success("Type d'évènements", "L'élément a été modifié");

          this.editModal = false;
        });
      } else {
        this.$axios.post("/api/events/types", { data: this.edition }).then(() => {
          this.getTypes();
          this.$simpleToast.success("Type d'évènements", "L'élément a été ajouté");

          this.editModal = false;
        });
      }
    },
    openFontAwesome() {
      window.open("https://fontawesome.com/icons", "_blank").focus();
    },
    getTypes() {
      this.$axios("/api/events/types").then((resp) => {
        this.types = resp.data;
      });
    },
  },
  watch: {
    search() {
      this.simpleDebounce(() => {
        this.getUsers();
      }, 500);
    },
  },
};
</script>

<style scoped></style>
