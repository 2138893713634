<template>
  <v-list-item>
    <v-col cols="1"></v-col>
    <v-list-item-avatar width="40" v-if="hebdo || season"></v-list-item-avatar>
    <v-list-item-content>
      <v-row v-if="hebdo">
        <v-col cols="3" class="py-0 pl-6 text-left d-none d-sm-flex" style="font-size: 0.9rem"></v-col>
        <v-col cols="4" sm="3" class="py-0 text-right text-no-wrap">
          <strong>CA</strong>
        </v-col>
        <v-col cols="4" sm="3" class="py-0 text-right text-no-wrap">
          <strong>Marge</strong>
        </v-col>
        <v-col cols="2" class="py-0 text-right text-no-wrap">
          <strong>Points</strong>
        </v-col>
      </v-row>

      <v-row v-if="season">
        <v-col cols="3" class="py-0 text-left d-none d-sm-flex" style="font-size: 0.9rem"></v-col>
        <v-col cols="3" sm="2" class="p-0 text-right text-no-wrap">
          <strong>CA</strong>
        </v-col>
        <v-col cols="2" sm="2" class="p-0 text-right text-no-wrap">
          <strong>Marge</strong>
        </v-col>
        <v-col cols="2" class="p-0 text-right text-no-wrap">
          <strong>Semaines</strong>
        </v-col>
        <v-col cols="2" class="p-0 text-right text-no-wrap">
          <strong>Points</strong>
        </v-col>
      </v-row>

      <v-row v-if="ecurie">
        <v-col cols="6" class="p-0"></v-col>
        <v-col cols="2" class="p-0 text-right text-no-wrap">
          <strong>CA</strong>
        </v-col>
        <v-col cols="2" class="p-0 text-right text-no-wrap">
          <strong>Marge</strong>
        </v-col>
        <v-col cols="2" class="p-0 text-right text-no-wrap">
          <strong>Points</strong>
        </v-col>
      </v-row>
    </v-list-item-content>
    <v-list-item-icon width="24" v-if="hebdo || season"></v-list-item-icon>
    <v-list-item-icon width="24" v-if="hebdo"></v-list-item-icon>
  </v-list-item>
</template>

<script>
export default {
  name: "challengeTablesHeader",
  props: {
    season: {
      type: Boolean,
      default: false,
    },
    hebdo: {
      type: Boolean,
      default: false,
    },
    ecurie: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
