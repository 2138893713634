var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    { attrs: { title: _vm.item.item.name } },
    [
      _c(
        "v-row",
        { staticClass: "mb-3" },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "3" } }, [
                    _c("span", { staticClass: "pa-0" }, [_vm._v("Modèle : ")]),
                  ]),
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "8" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.item.item.model))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "3" } }, [
                    _c("span", { staticClass: "pa-0" }, [
                      _vm._v("N° Série : "),
                    ]),
                  ]),
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "8" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.item.item.serialNumber))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "3" } }, [
                    _c("span", { staticClass: "pa-0" }, [_vm._v("OS : ")]),
                  ]),
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "8" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.item.item.os))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "3" } }, [
                    _c("span", { staticClass: "pa-0" }, [_vm._v("SP : ")]),
                  ]),
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "8" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.item.item.servicePack))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "3" } }, [
                    _c("span", { staticClass: "pa-0" }, [_vm._v("Client : ")]),
                  ]),
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "8" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.item.customer.name))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "3" } }, [
                    _c("span", { staticClass: "pa-0" }, [_vm._v("Site : ")]),
                  ]),
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "8" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.item.site.name))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }