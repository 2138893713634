<template>
  <material-card title="Logiciels">
    <v-data-table
      :headers="headers"
      :items="tab"
      :server-items-length="maxItem"
      :items-per-page="limitItem"
      @update:items-per-page="setItemMax"
      @update:page="setPage"
      class="elevation-1"
    >
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-text-field v-model="search.name" type="text" label="Rechercher"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="search.version" type="text" label="Rechercher"></v-text-field>
          </td>
        </tr>
      </template>

      <template v-slot:item.installDate="{ item }">
        <template v-if="item.installDate">
          {{ dayjs(item.installDate).format("DD/MM/YYYY") }}
        </template>
      </template>
    </v-data-table>
  </material-card>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import MaterialCard from "../../components/common/MaterialCard";
import Tools from "../../mixins/Tools";
export default {
  name: "softwareSupervision",
  components: { MaterialCard },
  props: ["value"],
  mixins: [simpleDebounce, Tools],
  data() {
    return {
      item: this.value,
      limitItem: 10,
      maxItem: 0,
      page: 1,
      tab: [],
      search: {
        name: "",
        version: "",
      },
      headers: [
        { text: "Logiciel", value: "name" },
        { text: "Version", value: "version" },
        { text: "Date d'installation", value: "installDate" },
      ],
    };
  },
  mounted() {
    this.getSoftware();
  },
  methods: {
    setItemMax(event) {
      this.limitItem = event;
    },
    setPage(event) {
      this.page = event;
    },
    getSoftware() {
      let search = this.search;
      this.$axios("/api/supervision/swdevices/" + this.$route.params.id + "/software", {
        params: { page: this.page, search, limit: this.limitItem },
      }).then((resp) => {
        this.tab = resp.data.data;
        this.maxItem = resp.data.max;
      });
    },
    getSoftwareDebounce() {
      this.page = 1;
      this.simpleDebounce(() => {
        this.getSoftware();
      }, 300 /* optional debounce time: default is 300 */);
    },
  },
  watch: {
    "search.name": "getSoftwareDebounce",
    "search.version": "getSoftwareDebounce",
    limitItem: "getSoftware",
    page: "getSoftware",
    item() {
      this.$emit("input", this.item);
    },
    value() {
      this.item = this.value;
    },
  },
};
</script>

<style scoped></style>
