export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_API_MS_CLIENT_ID,
    authority: process.env.VUE_APP_API_MS_AUTHORITY,
    //redirectUri: process.env.VUE_APP_URL, // It has to be configured on your Azure tenant
    //tenant: '<your-tenant>',
    //Dev
    //clientId: "bdb65b40-f256-4bc8-9b9b-677114e563e0",
    //Prod
    //clientId: 'a430f507-829a-470a-8a7a-cb7ef50f40e7',
    //Deploy
    // clientId: '3c309944-4ca1-41ca-8222-2be63263092b',
    // authority: 'https://login.microsoftonline.com/268c56a6-bd4d-4a84-8ac9-0892d506cd81',
    //scopes: ['<your-scopes>']
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  graph: {
    //url: '<your-graph-api-url>',
    //scopes: '<your-graph-api-scopes>',
    //response_type: "blob"
  },
  //mode: "redirect"
};
