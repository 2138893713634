import { render, staticRenderFns } from "./singleOpportunity.vue?vue&type=template&id=5c1fbbe3&scoped=true&"
import script from "./singleOpportunity.vue?vue&type=script&lang=js&"
export * from "./singleOpportunity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c1fbbe3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Actuelburo\\srv\\jenkins\\crm-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c1fbbe3')) {
      api.createRecord('5c1fbbe3', component.options)
    } else {
      api.reload('5c1fbbe3', component.options)
    }
    module.hot.accept("./singleOpportunity.vue?vue&type=template&id=5c1fbbe3&scoped=true&", function () {
      api.rerender('5c1fbbe3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/opportunities/singleOpportunity.vue"
export default component.exports