<template>
  <div style="position: relative">
    <div class="d-flex" style="position: absolute; right: 0px; top: -47px">
      <v-tooltip v-if="customer && customer.customer" bottom right color="error">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="makeObsolete mr-1" v-if="isGranted('CUSTOMERS_LOCK')">
            <template v-if="!customer.customer.obsolete">
              <v-icon x-small @click.stop="lockCust()">fa-moon</v-icon>
            </template>
            <template v-else>
              <v-icon x-small color="warning" @click.stop="lockCust()">fa-sun</v-icon>
            </template>
          </div>
        </template>
        <span>{{ customer.customer.obsolete ? "Activer" : "Mettre en sommeil" }}</span>
      </v-tooltip>

      <v-tooltip v-if="customer && customer.customer && isGranted('CUSTOMERS_ANONYME')" bottom right color="error">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="makeAnonyme mr-1 cursor-pointer" @click.stop="toggleAnonymize(customer.customer)">
            <template v-if="!customer.customer.anonyme">
              <v-icon x-small color="red">fa-mask</v-icon>
            </template>
            <template v-else>
              <v-icon x-small>fa-eye</v-icon>
            </template>
          </div>
        </template>
        <span>{{ customer.customer.anonyme ? "Retirer l'anonymat" : "Anonymiser" }}</span>
      </v-tooltip>

      <v-menu open-on-hover :close-on-content-click="false" v-if="customer && customer.customer" offset-y z-index="1000" max-width="350" min-width="350">
        <template v-slot:activator="{ on, attrs }">
          <v-icon x-small v-bind="attrs" v-on="on">fa-info-circle</v-icon>
        </template>
        <v-card elevation="0">
          <v-card-title class="py-2 grey--text text--darken-3 text-uppercase subtitle-1"> Historique éditions </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span>
              <div class="my-1" v-if="customer.entreprise.createdAt">
                <span v-if="customer.entreprise.createdUser">
                  <UserAvatar size="35" class="mr-2" :userId="customer.entreprise.createdUser.id" />
                </span>
                <span v-else>
                  <v-avatar size="35" class="mr-2"> <img src="/images/users/nopic.png" /> </v-avatar
                ></span>
                Création le {{ dayjs(customer.entreprise.createdAt).format("DD/MM/YYYY à HH:mm") }}
              </div>
              <div v-if="customer.entreprise.updatedAt">
                <span v-if="customer.entreprise.updatedUser">
                  <UserAvatar size="35" class="mr-2" :userId="customer.entreprise.updatedUser.id" />
                </span>
                <span v-else>
                  <v-avatar size="35" class="mr-2"> <img src="/images/users/nopic.png" /> </v-avatar
                ></span>
                Dernière modification le {{ dayjs(customer.entreprise.updatedAt).format("DD/MM/YYYY à HH:mm") }}
              </div>
            </span>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <MaterialCard class="px-5 py-3" :color="'primary'">
      <modal max-width="1024" v-model="addParcModal">
        <template v-slot:header v-if="addHeader">
          <strong class="text-h5 mb-0 text-uppercase"> {{ addHeader && addHeader.id ? "Edition" : "Ajout" }} d'un élément de parc</strong>
          <br /><small class="mt-0">
            <template> </template>
          </small>
        </template>

        <v-row v-if="addHeader">
          <v-col cols="12"> </v-col>
          <v-col cols="6" class="py-0" v-for="(field, key) in Object.keys(addHeader.classic)" :key="key">
            <template v-if="field === 'date'">
              <v-menu ref="menu1" v-model="menuDate" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="dateFormatted"
                    v-bind="attrs"
                    :label="addHeader.classic[field].label"
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title @input="menuDate = false"></v-date-picker>
              </v-menu>
            </template>
            <template v-else-if="field === 'birthday'">
              <v-menu
                ref="menu1"
                v-model="menuBirthdayDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="dateBirthdayFormatted"
                    v-bind="attrs"
                    :label="addHeader.classic[field].label"
                    @blur="dateBirthday = parseDateWithoutYear(dateBirthdayFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateBirthday" no-title @input="menuBirthdayDate = false"></v-date-picker>
              </v-menu>
            </template>
            <template v-else-if="field === 'body'">
              <v-textarea outlined :label="addHeader.classic[field].label" v-model="addHeader.classic[field].value"></v-textarea>
            </template>
            <template v-else>
              <v-text-field outlined :label="addHeader.classic[field].label" v-model="addHeader.classic[field].value"></v-text-field>
            </template>
          </v-col>
          <v-col cols="6" class="py-0" v-for="(field, key) in Object.keys(addHeader.spe)" :key="key + '-spe'">
            <v-select
              outlined
              clearable
              :menu-props="{ maxHeight: '300' }"
              item-value="id"
              item-text="name"
              :label="addHeader.spe[field].label"
              v-model="addHeader.spe[field].value"
              :items="addHeader.spe[field].items"
              @change="handleClearField(`spe`, field)"
            ></v-select>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-autocomplete
              :menu-props="{ maxHeight: '300' }"
              v-model="addHeader.concurrent"
              :items="concurrentsList"
              :loading="isLoading"
              clearable
              :search-input.sync="search.name"
              item-text="entreprise.name"
              item-value="entreprise.concurrents.id"
              label="Rechercher un prestataire"
              outlined
              @change="handleClearField('concurrent')"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <template v-slot:actions>
          <v-btn @click="saveParcElem" variant="flat" color="primary" elevation="0" depressed class="rounded-1 px-3" small>
            <v-icon x-small class="mr-2">fas fa-check fa-fw</v-icon>
            Enregistrer
          </v-btn>
          <v-btn color="secondary" text class="mr-4" @click="addParcModal = false"> Fermer </v-btn>
        </template>
      </modal>
      <modal max-width="1024" v-model="openEditModal">
        <template v-slot:header>
          <strong class="text-h5 mb-0 text-uppercase">Edition client</strong>
          <br /><small class="mt-0">Commencez par saisir le N° de SIRET</small>
        </template>

        <v-alert dense type="error" v-if="alreadySiret && alreadySiret.id !== newClient.entreprise.id">
          <div>Un compte existe déjà avec ce SIRET:</div>
          <div>{{ alreadySiret.name }}</div>
          <div>
            <v-btn color="secondary" v-if="isGranted('CUSTOMERS_VIEW')" @click="$router.push({ name: 'Client', params: { id: alreadySiret.id } })"
              >Accéder au compte
            </v-btn>
          </div>
        </v-alert>

        <v-row v-if="newClient">
          <v-col cols="12" md="6">
            <v-col cols="12" class="pa-0 py-1 ma-0" v-if="this.$store.state.settings.sage">
              <v-text-field
                class="pa-0 ma-0"
                v-model="newClient.customer.ctNumAb"
                :maxlength="17"
                persistent-hint
                hint="Voir la norme de nommage"
                label="N° Client"
                outlined
                :readonly="originalCustomer.customer.ctNumAb !== null && originalCustomer.customer.ctNumAb !== ''"
                :disabled="originalCustomer.customer.ctNumAb !== null && originalCustomer.customer.ctNumAb !== ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-text-field
                class="pa-0 ma-0 mb-3"
                maxlength="35"
                v-model="newClient.entreprise.name"
                hide-details="true"
                outlined
                label="Intitulé"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-text-field
                class="pa-0 ma-0 mb-3"
                maxlength="14"
                v-model="newClient.entreprise.siret"
                outlined
                label="SIRET"
                hide-details="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-text-field
                class="pa-0 ma-0 mb-3"
                v-model="newClient.entreprise.adresse.address"
                hide-details="true"
                :maxlength="35"
                outlined
                label="Adresse"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-text-field
                class="pa-0 ma-0 mb-3"
                v-model="newClient.entreprise.adresse.address2"
                hide-details="true"
                :maxlength="35"
                outlined
                label="Complément"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-text-field
                class="pa-0 ma-0 mb-3"
                v-model="newClient.entreprise.adresse.postalCode"
                hide-details="true"
                :maxlength="5"
                outlined
                label="Code postal"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-text-field
                class="pa-0 ma-0 mb-1"
                v-model="newClient.entreprise.adresse.city"
                hide-details="true"
                :maxlength="35"
                outlined
                label="Ville"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-switch v-model="newClient.customer.isIndirect" label="Client Indirect"></v-switch>
            </v-col>
          </v-col>
          <v-col cols="12" md="6">
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-text-field
                class="pa-0 ma-0 mb-0"
                persistent-hint
                type="email"
                :error="newClient && newClient.entreprise.email && newClient.entreprise.email.length === 0"
                hint="Obligatoire pour la synchronisation avec SAGE"
                outlined
                v-model="newClient.entreprise.email"
                label="Email"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-select
                :menu-props="{ maxHeight: '300' }"
                class="pa-0 ma-0 mb-3"
                hide-details="true"
                v-model="newClient.entreprise.peopleId"
                outlined
                label="Effectifs"
                item-value="id"
                item-text="name"
                :items="listPeople"
              ></v-select>
            </v-col>
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-text-field class="pa-0 ma-0 mb-3" v-model="newClient.entreprise.ape" hide-details="true" outlined label="APE"></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-text-field
                class="pa-0 ma-0 mb-3"
                v-model="newClient.entreprise.phone"
                hide-details="true"
                :maxlength="21"
                outlined
                label="Téléphone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-text-field
                class="pa-0 ma-0 mb-3"
                hide-details="true"
                v-model="newClient.entreprise.phone2"
                :maxlength="21"
                outlined
                label="FAX"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-text-field class="pa-0 ma-0 mb-3" hide-details="true" v-model="newClient.entreprise.site" outlined label="Site Web" type="url"></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 py-1 ma-0">
              <v-alert
                dense
                border="top"
                colored-border
                elevation="2"
                x-small
                type="info"
                color="primary"
                v-if="this.$store.state.settings.sage"
                class="text-left pt-3"
              >
                <div class="caption">
                  <h3 class="text-h6">Norme N° Client</h3>
                  <ul>
                    <li>NOM de l’entreprise sans SARL, SA, EURL, GROUPE…</li>
                    <li>M + VILLE si Mairie</li>
                    <li>CC + Ville si Communauté de Communes</li>
                    <li>A défaut on appelle le service COMPTA/ADV</li>
                  </ul>
                </div>
              </v-alert>
            </v-col>
          </v-col>
        </v-row>

        <template v-slot:actions>
          <v-btn
            @click="saveClient"
            :disabled="alreadySiret !== null && alreadySiret.id === newClient.entreprise.siret ? true : false"
            variant="flat"
            color="primary"
            elevation="0"
            depressed
            class="rounded-1 px-3"
            small
          >
            <v-icon x-small class="mr-2">fas fa-check fa-fw</v-icon>
            Enregistrer
          </v-btn>
          <v-btn color="secondary" text class="mr-4" @click="openEditModal = false"> Fermer </v-btn>
        </template>
      </modal>
      <template v-slot:heading>
        <div>
          <v-row>
            <v-col cols="12">
              <v-tabs v-model="tabs" background-color="transparent" slider-color="white" grow :vertical="isMobile()">
                <v-tab @click="tabs === 0 ? (toggleInfo = !toggleInfo) : (tabs = 0)">
                  <v-row class="pa-0 ma-0">
                    <!--<v-col cols="1" class="pr-0">
                            <v-icon x-small @click="editCustomerOpen">fa-pen</v-icon>
                    </v-col>-->
                    <v-col cols="12">
                      <div v-if="customer && customer.customer" style="font-size: 1.1rem">{{ customer.entreprise.name }}</div>
                    </v-col>
                  </v-row>
                </v-tab>
                <v-tab @click.stop="toggleInfo = false">
                  <v-row class="pa-0 ma-0">
                    <v-col cols="2" class="px-0">
                      <v-icon class="mr-2" small> fa-retweet </v-icon>
                    </v-col>
                    <v-col cols="10">
                      <v-badge :content="maxItemsSubs !== 0 ? maxItemsSubs : '0'" color="secondary" overlap>
                        <span class="mr-6">Abonnements</span>
                      </v-badge>
                    </v-col>
                  </v-row>
                </v-tab>
                <v-tab v-for="(parc, key) in infras" :key="key" @click="toggleInfo = false">
                  <v-row class="pa-0 ma-0">
                    <v-col cols="1" class="px-0">
                      <v-icon class="mr-2" small>
                        {{ parc.icon }}
                      </v-icon>
                    </v-col>
                    <v-col cols="11">
                      <v-badge :content="items[key] && items[key].length ? items[key].length : '0'" color="secondary" overlap>
                        <span class="mr-6">{{ parc.name }}</span>
                      </v-badge>
                    </v-col>
                  </v-row>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </template>
      <v-tabs-items v-model="tabs" class="transparent">
        <v-tab-item>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-expand-transition>
                <v-container fluid v-show="true" class="mb-2">
                  <v-row>
                    <v-col cols="12" lg="4" xl="4" class="py-0">
                      <v-col cols="12" class="py-0">
                        <div class="float-md-right">
                          <v-tooltip bottom color="warning">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab x-small outlined color="warning" class="ml-1" @click="openLink(1)" v-bind="attrs" v-on="on">
                                <v-icon x-small> fa-fw fa-address-book</v-icon>
                              </v-btn>
                            </template>
                            <span>Pages jaunes</span>
                          </v-tooltip>
                          <v-tooltip bottom color="error">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab x-small outlined color="error" class="ml-1" @click="openLink(2)" v-bind="attrs" v-on="on">
                                <v-icon x-small>fab fa-fw fa-google</v-icon>
                              </v-btn>
                            </template>
                            <span>Google</span>
                          </v-tooltip>
                          <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab x-small outlined color="primary" class="ml-1" @click="openLink(3)" v-bind="attrs" v-on="on">
                                <v-icon x-small> fa-fw fa-building</v-icon>
                              </v-btn>
                            </template>
                            <span>Société.com</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab x-small color="primary" class="ml-4" @click="editCustomerOpen" v-bind="attrs" v-on="on">
                                <v-icon x-small>fa-fw fa-pen</v-icon>
                              </v-btn>
                            </template>
                            <span>Editer le client</span>
                          </v-tooltip>
                        </div>

                        <v-icon class="mr-2" small>fa-fw fa-map-marker-alt</v-icon>
                        <span class="font-italic grey--text text-body-2 text--darken-2">
                          <template v-if="customer.entreprise.adresse.address">{{ customer.entreprise.adresse.address }}</template>

                          <template v-if="customer.entreprise.adresse.address2"> - {{ customer.entreprise.adresse.address2 }} </template>
                          {{ customer.entreprise.adresse.postalCode + " " + customer.entreprise.adresse.city }}
                        </span>
                        <br style="clear: both" />
                      </v-col>
                      <v-col cols="12" class="py-0" v-if="customer.entreprise.phone">
                        <v-icon class="mr-2" small>fa-fw fa-phone</v-icon>
                        <span>
                          <a :href="'tel:' + customer.entreprise.phone">{{ customer.entreprise.phone }}</a>
                        </span>
                      </v-col>

                      <v-col>
                        <v-row>
                          <v-col cols="12" lg="6" class="py-0">
                            <v-icon class="mr-2" small>fa-fw fa-envelope</v-icon>
                            <template v-if="customer.entreprise.email">
                              <span class="grey--text text--darken-2">
                                <a :href="'mailto:' + customer.entreprise.email">{{ customer.entreprise.email }}</a>
                              </span></template
                            >
                          </v-col>
                          <v-col cols="12" xl="6" class="py-0" v-if="customer.entreprise.site">
                            <v-icon class="mr-2" small>fa-fw fa-link</v-icon>
                            <span>
                              <a :href="customer.entreprise.site" target="_blank">{{ customer.entreprise.site }}</a>
                            </span>
                          </v-col>
                          <v-col cols="12" xl="6" class="py-0" v-else>
                            <v-icon class="mr-2" small>fa-fw fa-link</v-icon>
                            <span> - </span>
                          </v-col>
                          <v-col cols="12" xl="6" class="py-0" @dblclick="copy(customer.customer.ctNumAb)" v-if="customer.customer.ctNumAb">
                            <v-icon class="mr-2 green--text text--lighten-1" small>far fa-fw fa-id-badge</v-icon>
                            <span class="grey--text text--darken-2">
                              {{ customer.customer.ctNumAb }}
                              <v-icon x-small @click="copy(customer.customer.ctNumAb)">far fa-copy fa-fw</v-icon>
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col>
                        <v-row>
                          <v-col cols="12" xl="6" class="py-0" @dblclick="copy(customer.entreprise.siret)" v-if="customer.entreprise.siret">
                            <v-icon class="mr-2" small>fa-fw fa-barcode</v-icon>
                            <span class="grey--text text--darken-2">{{ customer.entreprise.siret }} </span>
                            <v-icon x-small @click="copy(customer.entreprise.siret)">far fa-copy fa-fw</v-icon>
                          </v-col>
                          <v-col cols="12" xl="6" class="py-0" v-else>
                            <v-icon class="mr-2" small>fa-fw fa-barcode</v-icon>
                            <span class="grey--text text--darken-2"> - </span>
                          </v-col>
                          <v-col cols="12" xl="6" class="py-0" v-if="customer.entreprise.entrepriseSieges">
                            <v-icon class="mr-2" small>fa-fw fa-building</v-icon>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  depressed
                                  outlined
                                  rounded
                                  color="indigo"
                                  v-bind="attrs"
                                  v-on="on"
                                  x-small
                                  @click="$router.push({ name: 'Client', params: { id: customer.entreprise.entrepriseSieges.id } })"
                                  >{{ customer?.entreprise?.entrepriseSieges?.name }}</v-btn
                                >
                              </template>
                              <span>Siège social</span>
                            </v-tooltip>
                          </v-col>
                          <v-col
                            cols="12"
                            xl="6"
                            class="py-0"
                            v-else-if="customer?.entreprise?.entreprisesSecondaire && customer?.entreprise?.entreprisesSecondaire.length > 0"
                          >
                            <v-icon class="mr-2" small>fa-fw fa-building</v-icon>
                            <v-tooltip top v-for="(secondaire, i) of customer?.entreprise?.entreprisesSecondaire" :key="'entreprise-secondaire-' + i">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="mr-1"
                                  depressed
                                  outlined
                                  rounded
                                  color="green"
                                  v-bind="attrs"
                                  v-on="on"
                                  x-small
                                  @click="$router.push({ name: 'Client', params: { id: secondaire.id } })"
                                  >{{ secondaire.name }}</v-btn
                                >
                              </template>
                              <span>Etablissement secondaire</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="12" xl="6" class="py-0" v-else-if="customer?.entreprise?.siege">
                            <v-icon class="mr-2" small>fa-fw fa-building</v-icon>
                            <span class="font-italic">Siège (entreprises secondaires inconnues)</span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col cols="12" lg="6" class="py-0" v-if="customer.entreprise.ape">
                            <v-icon class="mr-2" small>fa-fw fa-university</v-icon>
                            <span class="grey--text text--darken-2">
                              {{ customer.entreprise.ape }}<br />
                              <p class="font-italic text-caption ma-0 pa-0 ml-3 pl-4">{{ getApeLabel() }}</p>
                            </span>
                          </v-col>
                          <v-col cols="12" lg="6" class="py-0" v-if="customer.people">
                            <v-icon class="mr-2" small>fa-fw fa-users</v-icon>
                            <span class="grey--text text--darken-2">
                              {{ customer.people.name }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-col>
                    <v-col cols="12" lg="8" xl="8">
                      <v-row>
                        <v-col cols="12" md="8" lg="6" class="py-0">
                          <v-container>
                            <v-row align="center" class="mb-1">
                              <v-divider></v-divider>
                              <span>
                                <v-btn small text color="primary" @click="isGranted('CUSTOMERS_EDITAFFECTATIONS') ? (modalAffect = true) : ''"
                                  >Affectations
                                </v-btn>
                              </span>
                              <v-divider></v-divider>
                            </v-row>
                          </v-container>

                          <div cols="10" class="py-0 mt-0 text-center">
                            <v-tooltip v-for="(infra, key) in infrasAll" :key="key" bottom :color="infra.color">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  width="75"
                                  height="30"
                                  justify="end"
                                  depressed
                                  rounded
                                  class="mr-1 mb-1 text-end toggleKeyCustomer"
                                  @click="setAffects(infra.id)"
                                  :color="infra.color"
                                  :outlined="customer.custoKey.includes(infra.id) ? false : true"
                                  v-bind="attrs"
                                  v-on="on"
                                  x-small
                                >
                                  <v-avatar
                                    left
                                    size="30"
                                    :color="infra.color"
                                    style="position: absolute; left: -9.5px"
                                    class=""
                                    v-if="customer.users[infra.id]"
                                  >
                                    <UserAvatar size="30" :userId="customer.users[infra.id].id" />
                                  </v-avatar>
                                  <v-icon x-small class="ml-4" :color="customer.custoKey.includes(infra.id) ? '' : ''">
                                    {{ infra.icon ? infra.icon : "fa-star" }}
                                  </v-icon>

                                  <v-icon x-small class="ml-4" v-if="customer.custoKey.includes(infra.id) && false">fa-key </v-icon>
                                </v-btn>
                              </template>
                              <div class="text-center grey--text text--darken-4">
                                <strong>{{ infra.name }}</strong
                                ><br />
                                <template v-if="customer.users[infra.id]">
                                  <span class="text-capitalize">{{ customer.users[infra.id].firstname.toLowerCase() }}</span>
                                  {{ customer.users[infra.id].lastname }}
                                </template>
                                <template v-else> Aucune affectation </template>
                              </div>
                            </v-tooltip>
                          </div>

                          <!--<v-divider class="my-1"></v-divider>-->
                          <v-spacer></v-spacer>
                          <v-row class="py-2 mt-9-px">
                            <v-col cols="12" class="py-1 text-center">
                              <v-container>
                                <v-row align="center" class="mb-1">
                                  <v-divider></v-divider>
                                  <span>
                                    <v-btn small text color="primary" @click="isGranted('CUSTOMERS_EDITGROUPEMENTS') ? openGroups() : ''">Groupes </v-btn>
                                  </span>
                                  <v-divider></v-divider>
                                </v-row>
                              </v-container>

                              <v-btn depressed rounded outlined color="primary" v-for="(group, key) in customer.groups" :key="key" x-small class="mr-1 mb-1">
                                {{ groups[parseInt(group)] ? groups[parseInt(group)].name : "" }}
                              </v-btn>
                            </v-col>
                            <v-col cols="10" class="py-1" v-if="groups"> </v-col>
                          </v-row>
                        </v-col>
                        <v-col md="4" lg="6" class="pb-0 mb-0">
                          <v-spacer></v-spacer>
                          <v-textarea
                            outlined
                            persistent-placeholder
                            v-if="customer.customer"
                            label="Commentaires"
                            v-model="customer.customer.body"
                            rows="3"
                            class="text-caption mb-0"
                            no-resize
                            hide-details
                          ></v-textarea>

                          <v-row class="mt-0">
                            <v-col cols="12" class="py-1 text-center">
                              <v-container>
                                <v-row align="center" class="mb-1">
                                  <v-divider></v-divider>
                                  <span>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn small text color="primary" @click="openLinkParent()" v-bind="attrs" v-on="on">Comptes liés</v-btn>
                                      </template>
                                      <span>Le compte parent partage ses contacts avec le compte enfant</span>
                                    </v-tooltip>
                                  </span>
                                  <v-divider></v-divider>
                                </v-row>
                              </v-container>
                              <v-tooltip top v-if="customer.customer?.parent?.id">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    depressed
                                    outlined
                                    rounded
                                    color="indigo"
                                    x-small
                                    class="mr-1 mb-1"
                                    @click="$router.push({ name: 'Client', params: { id: customer.customer.parent.id } })"
                                    >{{ customer.customer.parent.entreprise.name }}</v-btn
                                  >
                                </template>
                                <span>Compte parent</span>
                              </v-tooltip>
                              <template v-if="customer.customer.children">
                                <v-tooltip top v-for="(children, i) in customer.customer.children" :key="i">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      depressed
                                      outlined
                                      rounded
                                      color="green"
                                      x-small
                                      class="mr-1 mb-1"
                                      @click="$router.push({ name: 'Client', params: { id: children.id } })"
                                      >{{ children.entreprise.name }}</v-btn
                                    >
                                  </template>
                                  <span>Compte enfant</span>
                                </v-tooltip>
                              </template>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            :headers="headersSubs"
            :items="itemsSubs"
            :page="page"
            :items-per-page="limit"
            :loading="isLoading"
            class="elevation-1"
            style="z-index: 1000"
            :server-items-length="maxItemsSubs"
            :footer-props="{ disableItemsPerPages: true }"
            @update:page="setPage"
            @update:items-per-page="setItemMax"
          >
            <template v-slot:item.line.numLicence="{ item }">
              <div v-for="(line, key) in item.line" :key="key">
                <v-chip v-if="line.numLicence" class="ma-1" x-small label>{{ line.numLicence }}</v-chip>
              </div>
            </template>
            <template v-slot:item.line.version="{ item }">
              <div v-for="(line, key) in item.line" :key="key">
                <v-chip v-if="line.version" class="ma-1" x-small label>{{ line.version }}</v-chip>
              </div>
            </template>
            <template v-slot:item.item.startDate="{ item }">
              {{ dayjs(item.item.startDate).format("DD/MM/YYYY") }}
            </template>

            <template v-slot:item.item.endDate="{ item }">
              {{ dayjs(item.item.endDate).format("DD/MM/YYYY") }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn
                small
                text
                @click="
                  subscriptionSelected = item;
                  subscriptionModal = true;
                "
              >
                <v-icon small> fa-fw fa-search </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-for="(infra, key) in infras" :key="'tab' + key">
          <div style="position: relative">
            <div style="position: absolute; top: 5px; right: 15px">
              <v-btn
                small
                fab
                color="primary"
                @click="
                  initField(headers[infra.id], infra.id);
                  addParcModal = true;
                "
              >
                <v-icon small>fa-plus</v-icon>
              </v-btn>
            </div>
            <v-data-table :headers="headers[infra.id]" :items="items[key]" :items-per-page="5" class="elevation-0" @click:row="openEdit">
              <template v-slot:item.infra.date="{ item }">
                <template v-if="item.infra.date">
                  {{ dayjs(item.infra.date).format("DD/MM/YYYY") }}
                </template>
              </template>
              <template v-slot:item.infra.birthday="{ item }">
                <template v-if="item.infra.birthday">
                  {{ dayjs(item.infra.birthday).format("DD/MM") }}
                </template>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click.stop="newOpp(item)"> fa-plus fa-fw </v-icon>
                  </template>
                  <span>Créer une opportunité</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2" small v-bind="attrs" v-on="on" color="primary"> fa-info-circle fa-fw</v-icon>
                  </template>
                  <span>
                    <div v-if="item.infra.updatedAt">
                      <strong>Dernière modification :</strong><br />{{ dayjs(item.infra.updatedAt).format("DD/MM/YYYY à HH:mm") }}
                    </div>
                    <div v-if="item.infra.updatedUser">
                      <strong>Par:</strong>
                      <UserAvatar size="35" class="ml-2" :userId="item.infra.updatedUser.id" />
                    </div>
                  </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small color="error" @click.stop="removeInfra(item)" class="deleteInfra"> fa-fw fa-trash-alt </v-icon>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-expansion-panels class="ma-0 pa-0">
              <v-expansion-panel class="ma-0 pa-0">
                <v-expansion-panel-header class="ma-0 pa-0" expand-icon="mdi-menu-down">
                  <template v-if="Object.keys(comments).includes(infra.id.toString()) && comments[infra.id].body">
                    <v-container>
                      <v-row>
                        <v-col>
                          <h3 class="mt-0 py-2 mb-3 text-uppercase">Commentaires parc - {{ infra.name }}</h3>
                          <v-divider></v-divider>
                          <p class="text-caption mt-3">
                            {{ comments[infra.id].body }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <template v-else>
                    <v-container>
                      <v-row>
                        <v-col>
                          <h3 class="mt-0 py-0 mb-1 text-uppercase">Commentaires parc - {{ infra.name }}</h3>
                          <v-divider></v-divider>
                          <p class="text-caption mt-3">Pas de commentaires</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="ma-0 pa-0">
                  <v-textarea
                    outlined
                    persistent-placeholder
                    v-if="Object.keys(comments).includes(infra.id.toString())"
                    v-model="comments[infra.id].body"
                    label="Commentaire de parc"
                  ></v-textarea>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <modal v-model="subscriptionModal" max-width="1024">
        <template v-slot:header v-if="subscriptionSelected">
          <strong class="text-h5 mb-0 text-uppercase">{{ subscriptionSelected.item.abModele }} {{ subscriptionSelected.item.abIntitule }}</strong>
          <br /><small class="mt-0">
            <template> {{ customer.entreprise.name }} </template>
          </small>
        </template>

        <v-container v-if="customer && subscriptionSelected" fluid>
          <v-row class="mb-3">
            <v-col cols="12" md="6" class="py-0 text-left">
              <div>
                Période du
                {{ dayjs(subscriptionSelected.item.startDate).format("DD/MM/YYYY") }} au
                {{ dayjs(subscriptionSelected.item.endDate).format("DD/MM/YYYY") }}
              </div>
            </v-col>
            <v-col cols="12" md="6" class="py-0 text-right">
              <div>
                <v-chip label>
                  {{ subscriptionSelected.user }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Article</th>
                      <th class="text-left">Désignation</th>
                      <th class="text-right">Quantité</th>
                      <th class="text-right">Montant HT</th>
                      <th class="text-right">Numéro licence</th>
                      <th class="text-right">Version</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in subscriptionSelected.line" :key="item.id">
                      <td class="text-left">{{ item.arRef }}</td>
                      <td class="text-left">{{ item.alDesign }}</td>
                      <td class="text-right">{{ item.alQte }}</td>
                      <td class="text-right">{{ item.alMontantht }}</td>
                      <td class="text-right">{{ item.numLicence }}</td>
                      <td class="text-right">{{ item.version }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </modal>
      <modal v-model="modalAffect">
        <template v-slot:header>
          <strong class="text-h5 mb-0 text-uppercase">Affectations métiers</strong>
        </template>
        <v-col class="d-flex" cols="12" v-for="(item, key) in Object.keys(affects)" :key="key">
          <v-row>
            <v-col cols="12" class="pa-0">
              <listUser
                :label="affects[item].name"
                :multiple="false"
                includeAll
                :user="customer.users[item]?.id"
                @selectChange="(event) => setAsUser(event, item)"
                :usersList="usersList"
              >
                <template v-slot:title>
                  <v-icon :color="affects[item].color">
                    {{ affects[item].icon }}
                  </v-icon>
                </template>
              </listUser>
            </v-col>
          </v-row>
        </v-col>
        <template v-slot:actions>
          <v-btn variant="flat" color="primary" elevation="0" class="mr-4" @click="editAffects"> Enregistrer </v-btn>
          <v-btn color="secondary" text class="mr-4" @click="modalAffect = false"> Fermer </v-btn>
        </template>
      </modal>
      <modal v-model="modalGroups">
        <template v-slot:header>
          <strong class="text-h5 mb-0 text-uppercase affectationGroup">Affectations groupes</strong>
        </template>
        <v-col class="d-flex" cols="12">
          <v-select outlined :items="groupsItem" multiple v-model="selectedGroups" item-value="id" item-text="name" chips label="Groupements"></v-select>
        </v-col>
        <template v-slot:actions>
          <v-btn variant="flat" color="primary" elevation="0" class="mr-4" @click="editGroups"> Enregistrer </v-btn>
          <v-btn color="secondary" text class="mr-4" @click="modalGroups = false"> Fermer </v-btn>
        </template>
      </modal>
      <LinkParent v-model="linkParentModal" :customer="customer" @updateCustomer="updateCustomer" @reload-contact="reloadContact" />
    </MaterialCard>
  </div>
</template>

<script>
import dayjs from "dayjs";
import simpleDebounce from "simple-debounce-vue-mixin";
import MaterialCard from "../../components/common/MaterialCard";
import ListUser from "../../components/user/listUser";
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";
import UserAvatar from "../../components/user/UserAvatar.vue";
import MobileDetection from "../../mixins/MobileDetection.js";
import LinkParent from "./LinkParent.vue";

export default {
  name: "titleCustomers",
  components: { ListUser, Modal, MaterialCard, UserAvatar, LinkParent },
  props: ["value"],
  mixins: [simpleDebounce, Tools, MobileDetection],
  data() {
    return {
      comments: {},
      toggleInfo: false,
      openEditModal: false,
      tabs: 0,
      infras: [],
      listPeople: [],
      subscriptionSelected: null,
      subscriptionModal: null,
      customer: this.value,
      isLoading: false,
      groups: [],
      maxItemsSubs: 0,
      itemsSubs: 0,
      groupsItem: [],
      dateBirthday: null,
      selectedGroups: this.value.groups,
      modalGroups: false,
      modalAffect: false,
      alreadySiret: null,
      addParcModal: false,
      addHeader: null,
      affects: {},
      emptyAffects: true,
      concurrentsList: [],
      date: null,
      addingConcurent: null,
      menuBirthdayDate: false,
      linkParentModal: false,
      usersList: null,
      search: {
        name: "",
        isIndirect: null,
        siret: null,
        postalCode: null,
        city: null,
        sector: null,
        phone: null,
        ctNumAb: null,
        ape: null,
        sortedBy: null,
        sortedDesc: false,
      },
      menuDate: false,
      dateFormatted: null,
      headersSubs: [
        { text: "Affaire", value: "item.caNum" },
        { text: "Modele", value: "item.abModele" },
        { text: "Intitulé", value: "item.abIntitule" },
        {
          text: "Numéro Licence",
          value: "line.numLicence",
        },
        {
          text: "Version",
          value: "line.version",
        },
        { text: "Début", value: "item.startDate" },
        { text: "Fin", value: "item.endDate" },
        { text: "", value: "action", align: "right" },
      ],
      page: 1,
      dateBirthdayFormatted: "",
      limit: 5,
      headers: {
        subs: [],
      },
      newClient: null,
      originalCustomer: null,
      items: [],
      affectsArr: {},
      infrasAll: [],
      parcTypes: {},
    };
  },
  mounted() {
    if (this.value) {
      this.getInfras();
      this.getInfrasAll();
      this.loadInfra();
      this.loadSubs();
      this.getApeLabel();
      this.getGroups();
      this.getTypes();
      this.getAffectations();
      this.getPeople();
      this.getUsersList();
      //this.getComments();
    }
  },
  methods: {
    handleClearField(field, subfield = null) {
      if (subfield && this.addHeader[field][subfield].value === null) {
        this.addHeader[field][subfield].value = "";
      } else if (this.addHeader[field] === null && field === "concurrent") {
        delete this.addHeader[field];
      }
    },
    toggleAnonymize(customer) {
      this.$axios.patch("/api/customers/" + customer.id + "/anonyme").then(() => {
        this.customer.anonyme = !customer.anonyme;
        this.$simpleToast.success("Client", customer.anonyme ? "Le client n'est plus anonymisé" : "Le client a bien été anonymisé");
        window.location.reload();
      });
    },
    updateCustomer(customer) {
      this.customer = customer;
    },
    reloadContact() {
      this.$emit("reload-contact", true);
    },
    async getComments() {
      let tmpComment = {};
      if (this.$route.params.id)
        await this.$axios("/api/customers/" + this.$route.params.id + "/parc/comment").then((resp) => {
          for (const item of resp.data) {
            tmpComment[item.typeId] = item;
          }
          for (const infra of this.infras) {
            if (!tmpComment[infra.id]) {
              tmpComment[infra.id] = {};

              tmpComment[infra.id].body = "";
            }
          }
        });
      this.comments = tmpComment;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getConcurrents() {
      let search = this.search;
      this.$axios("/api/concurrents/list", {
        params: {
          page: 1,
          search,
          limit: 5,
          allAgency: false,
          allUser: true,
        },
      }).then((resp) => {
        this.concurrentsList = resp.data.data;
      });
    },
    async removeInfra(item) {
      const res = await this.$confirm("Êtes-vous sur ?", { title: "Confirmation", color: "primary" });
      if (res) {
        this.$axios.delete("/api/infrastructures/" + item.infra.id).then(() => {
          this.$simpleToast.success("Parc Client", "La ligne a bien été supprimée.");
          this.loadInfra();
        });
      }
    },
    newOpp(item) {
      this.$axios.post("/api/opportunities", { data: item }).then((res) => {
        this.$router.push({ name: "Opportunité", params: { id: res.data.opp.id } });
      });
    },
    parseDateWithoutYear(date) {
      if (!date) return null;

      let data = date.split("/");
      return `${2000}-${data[1].padStart(2, "0")}-${data[0].padStart(2, "0")}`;
    },
    openEdit(row) {
      let infra = row.infra;
      this.search.name = row.concurrent?.name ? row.concurrent.name : "";

      this.$axios("/api/types/parc/" + infra.typeId).then((resp) => {
        let item = { classic: {}, spe: {}, itemid: infra.id, id: infra.typeId, customer: this.$route.params.id };
        item.concurrent = infra.concurrentId;
        if (infra.settings) {
          if (infra.settings.field1.enable) {
            item.classic.field1 = { label: infra.settings.field1.name, value: infra.field1 };
          }
          if (infra.settings.numberfield.enable) {
            item.classic.numberfield = { label: infra.settings.numberfield.name, value: infra.numberfield };
          }
          if (infra.settings.field2.enable) {
            item.classic.field2 = { label: infra.settings.field2.name, value: infra.field2 };
          }
          if (infra.settings.date.enable) {
            item.classic.date = {
              label: infra.settings.date.name,
              value: infra.date ? dayjs(infra.date).format("YYYY-MM-DD") : null,
            };
            if (infra.date) {
              this.dateFormatted = dayjs(infra.date).format("YYYY-MM-DD");
            }
            if (infra.settings.birthday.enable) {
              item.classic.birthday = {
                label: infra.settings.birthday.name,
                value: row.infra?.birthday ? dayjs(row.infra.birthday).format("YYYY-MM-DD") : null,
              };
              this.dateBirthday = row.infra.birthday ? dayjs(row.infra.birthday).format("YYYY-MM-DD") : null;
              if (row.infra.birthday) {
                this.dateBirthdayFormatted = row.infra.birthday;
              }
            }
          }
          if (infra.settings.body.enable) {
            item.classic.body = { label: infra.settings.body.name, value: row.infra.body };
          }
        } else {
          item.classic.field1 = { label: "Version", value: row.infra.field1 };
          item.classic.numberfield = { label: "Quantité", value: row.infra.numberfield };
          item.classic.field2 = { label: "N° License / série", value: row.infra.field2 };
          item.classic.date = {
            label: "Date d'anniversaire",
            value: row.infra?.date ? dayjs(row.infra.date).format("YYYY-MM-DD") : null,
          };
          this.date = dayjs(row.infra.date).format("YYYY-MM-DD");
          item.classic.body = { label: "Note", value: row.infra.body };
        }

        Object.keys(resp.data).forEach((elem) => {
          let value = null;
          let label = null;

          resp.data[elem].forEach((i) => {
            if (row[elem] === i.name) {
              value = i.id;
            }
          });

          this.headers[row.infra.typeId].forEach((head) => {
            if (head.value === elem) {
              label = head.text;
            }
          });
          item.spe[elem] = { label: label, items: resp.data[elem], value: value };
        });
        this.addHeader = item;
        this.addParcModal = true;
      });
    },
    saveParcElem() {
      if (this.addHeader.itemid) {
        this.$axios.patch("/api/customers/" + this.addHeader.customer + "/parc/" + this.addHeader.itemid, { data: this.addHeader }).then(() => {
          this.loadInfra();
          this.addParcModal = false;

          this.$simpleToast.success("Parc Client", "L'élément a bien été modifié.");
        });
      } else {
        this.$axios.post("/api/customers/" + this.addHeader.customer + "/parc", { data: this.addHeader }).then(() => {
          this.loadInfra();
          this.addParcModal = false;

          this.$simpleToast.success("Parc Client", "L'élément a bien été ajouté.");
        });
      }
    },
    initField(headers, id) {
      let infos = null;
      this.addHeader = null;
      this.$axios("/api/types/parc/" + id).then((resp) => {
        infos = resp.data;
        let item = { classic: {}, spe: {}, id: id, customer: this.$route.params.id };
        this.dateBirthday = null;
        this.dateBirthdayFormatted = null;
        this.date = null;
        this.dateFormatted = null;
        headers.forEach((val) => {
          if (val.value !== "concurrent.name" && val.value !== "actions") {
            if (val.value.includes("infra.")) {
              item.classic[val.value.replaceAll("infra.", "")] = { label: val.text, value: "" };
            } else {
              item.spe[val.value] = { label: val.text, value: "", items: infos[val.value] };
            }
          }
        });
        this.addHeader = item;
      });
    },
    copy(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.$simpleToast.info("Presse-papier", "Texte copié : <b>" + text + "</b>");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    getPeople() {
      this.$axios("/api/customers/people").then((resp) => {
        this.listPeople = resp.data;
      });
    },

    getInfrasAll() {
      this.$axios("/api/types/active").then((resp) => {
        this.infrasAll = resp.data.types;
      });
    },
    async getInfras() {
      await this.$axios("/api/types/infra").then((resp) => {
        resp.data.forEach((item) => {
          if (item && item.settings) {
            this.headers[item.id] = [];

            if (item.settings.field1.enable) {
              this.headers[item.id].push({ text: item.settings.field1.name, value: "infra.field1", sortable: false });
            }
            if (item.settings.numberfield.enable) {
              this.headers[item.id].push({
                text: item.settings.numberfield.name,
                value: "infra.numberfield",
                sortable: false,
              });
            }
            if (item.settings.field2.enable) {
              this.headers[item.id].push({ text: item.settings.field2.name, value: "infra.field2", sortable: false });
            }
            if (item.settings.date.enable) {
              this.headers[item.id].push({ text: item.settings.date.name, value: "infra.date", sortable: false });
            }
            if (item.settings.body.enable) {
              this.headers[item.id].push({ text: item.settings.body.name, value: "infra.body", sortable: false });
            }

            if (item.settings.birthday.enable) {
              this.headers[item.id].push({ text: item.settings.birthday.name, value: "infra.birthday", sortable: false });
            }
          } else {
            this.headers[item.id] = [
              //   { text: 'id', value: 'item.id', sortable: false },
              { text: "Version", value: "infra.field1", sortable: false },
              { text: "Quantité", value: "infra.numberfield", sortable: false },
              { text: "N° License / série", value: "infra.field2", sortable: false },
              { text: "Date d'échéance", value: "infra.date", sortable: false },
              { text: "Date anniversaire", value: "infra.birthday", sortable: false },
              //{ text: 'Prestataire', value: 'concurrent.name', sortable: false },
              { text: "Note", value: "infra.body", sortable: false },
            ];
          }
        });
        let tmp = {};
        this.$axios("/api/types/parc").then((res) => {
          res.data.parc.forEach((item) => {
            if (!tmp[item.type.id]) {
              tmp[item.type.id] = [];
            }
            this.headers[item.type.id].push({ text: item.name, value: item.slug, sortable: false });
          });
          /*Object.keys(this.headers).forEach((key) => {
            this.headers[key].push( { text: 'Actions', value: 'actions, sortable: false' });
          });*/
          Object.keys(this.headers).forEach((key) => {
            this.headers[key].push({ text: "Prestataire", value: "concurrent.name", sortable: false });
            this.headers[key].push({ text: "Actions", value: "actions", sortable: false });
          });
          this.parcTypes = tmp;
          this.infras = resp.data;
          this.getComments();
        });
      });
    },
    async lockCust() {
      let res = await this.$confirm("Êtes-vous sûr ?");
      if (res) {
        this.$axios.patch("/api/customers/" + this.customer.customer.id + "/lock").then((resp) => {
          this.$simpleToast.success("Client", "Le compte a bien été enregistré.");
          this.customer = resp.data;
        });
      }
    },
    getAffect(id, user = false) {
      let ret = null;
      Object.keys(this.affectsArr).forEach((item) => {
        if (this.affectsArr[item].type === id) {
          if (user) {
            ret = this.affectsArr[item].firstname + " " + this.affectsArr[item].lastname;
          } else {
            ret = this.affectsArr[item].id;
          }
        }
      });
      return ret;
    },
    getAffectations() {
      return "";
    },
    getUsersList() {
      this.$axios("/api/users", { params: { limit: false, active: true } }).then((resp) => {
        this.usersList = resp.data;
      });
    },
    saveClient() {
      this.$axios.patch("/api/customers/" + this.$route.params.id, { data: this.newClient }).then((resp) => {
        this.customer = resp.data;
        this.$simpleToast.success("Client", "Le client a été mis à jour");
        this.openEditModal = false;
      });
    },
    openLinkParent() {
      this.linkParentModal = true;
    },
    editCustomerOpen() {
      this.newClient = JSON.parse(JSON.stringify(this.customer));
      this.originalCustomer = JSON.parse(JSON.stringify(this.customer));
      this.openEditModal = true;
      this.toggleInfo = false;
    },
    setItemMax(event) {
      this.limit = event;
    },
    setPage(event) {
      this.page = event;
    },
    saveComment() {
      this.$axios.patch("/api/customers/" + this.$route.params.id + "/comment", { body: this.customer.customer.body }).then((resp) => {
        this.customer = resp.data;

        this.$simpleToast.success("Client", "Le commentaire a bien été sauvegardé.");
      });
    },
    update() {
      this.$forceUpdate();
    },
    getTypes() {
      this.$axios.get("/api/types/active").then((res) => {
        res.data.types.forEach((item) => {
          this.affects[item.id] = {
            name: item.name,
            id: item.id,
            value: this.customer.users[item.id]?.id ? this.customer.users[item.id]?.id : null,
            color: item.color,
            icon: item.icon,
          };
        });
      });
    },
    setAffects(id) {
      this.$axios.patch("/api/customers/" + this.$route.params.id + "/key", { custoKey: id }).then(() => {
        this.$simpleToast.success("Client", "La fiche a été modifiée.");
        this.$emit("refresh-all");
      });
    },
    openGroups() {
      this.selectedGroups = this.customer.groups;
      this.modalGroups = true;
    },
    editGroups() {
      this.$axios.patch("/api/customers/" + this.$route.params.id + "/groups", { groups: this.selectedGroups }).then((resp) => {
        this.customer = resp.data;
        this.$simpleToast.success("Client Groupements", "Affectations modifiées.");
      });
      this.modalGroups = false;
    },
    getGroups() {
      this.$axios("/api/groups").then((resp) => {
        this.groups = resp.data;
        Object.keys(this.groups).forEach((item) => {
          this.groupsItem.push(this.groups[item]);
        });
      });
    },
    setAsUser(event, item) {
      this.affects[item].value = event;
    },
    editAffects() {
      this.$axios.patch("/api/customers/" + this.$route.params.id + "/affects", { affects: this.affects }).then((resp) => {
        this.customer = resp.data;
        this.modalAffect = false;

        this.$simpleToast.success("Client affectations commerciaux", "Affectations modifiées.");
        this.$emit("refresh-all");
      });
    },
    isEmptyAffectation() {
      let tmp = true;
      if (this.customer && this.customer.users) {
        this.customer.users.forEach((user) => {
          if (user.dateStart <= Date.now() && (user.dateEnd === null || user.dateEnd >= Date.now())) {
            tmp = false;
          }
        });
      }
      return tmp;
    },
    getApeLabel() {
      let json = require("../../assets/ape.json");
      let ape = "";
      json.forEach((item) => {
        if (this.customer && this.customer.customer && item.fields.code_naf === this.customer.entreprise.ape) {
          ape = item.fields.intitule_naf;
        }
      });
      return ape;
    },
    openLink(id) {
      let tmp = {
        1:
          "https://www.pagesjaunes.fr/annuaire/chercherlespros?quoiqui=" +
          this.customer.entreprise.name +
          "&ou=" +
          this.customer.entreprise.city +
          "&univers=pagesjaunes&idOu=L04008800",
        2: "https://www.google.com/search?q=soci%C3%A9t%C3%A9+" + this.customer.entreprise.name + " " + this.customer.entreprise.adresse.postalCode,
        3: "https://www.societe.com/cgi-bin/search?champs=" + this.customer.entreprise.siret,
      };

      window.open(tmp[id], "_blank");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDateWithoutYear(date) {
      if (!date) return null;

      let data = date.split("-");
      return `${data[2]}/${data[1]}`;
    },
    giveDate(date) {
      if (date && date.date && date.date) {
        let dateD = new Date(date.date);
        let dateM = dayjs(dateD);
        if (dayjs().diff(dateM, "days") > 0) {
          dateM = dateM.add(1, "y");
        }
        return dateM.format("L");
      }
      return "";
    },
    loadSubs() {
      this.$axios
        .get("/api/subscriptions/" + this.$route.params.id, {
          params: {
            page: this.page,
            limit: this.limit,
          },
        })
        .then((resp) => {
          this.maxItemsSubs = resp.data.max;
          this.itemsSubs = resp.data.data;
        });
    },
    async loadInfra() {
      await this.$axios.get("/api/infrastructures/" + this.$route.params.id).then((resp) => {
        this.items = resp.data;
      });
      await this.getComments();
    },
    saveCommentParc() {
      if (this.$route.params.id) this.$axios.patch("/api/customers/" + this.$route.params.id + "/parc/comment", { data: this.comments });
    },
    formatName(name) {
      let Arr = ["SELARL ", "SOC ", "EURL ", "GROUPE ", "SA ", "SCI ", "SARL ", "SCIC ", "SCP "];
      let ret = name;
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = name.substring(start.length);
        }
      }

      Arr = ["MAIRIE DE ", "COMMUNE DE "];
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = "M" + name.substring(start.length);
        }
      }

      Arr = ["LYCEE PROFESSIONNEL "];
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = "LP" + name.substring(start.length);
        }
      }

      Arr = ["COMMUNAUTE DE COMMUNES "];
      for (const start of Arr) {
        if (name.startsWith(start)) {
          ret = "CC" + name.substring(start.length);
        }
      }

      return ret
        .trim()
        .replace(/[^a-z0-9]/gi, "")
        .substring(0, 17);
    },
  },
  watch: {
    "newClient.entreprise.name"() {
      if (!this.newClient.customer.ctNumAb || this.newClient.customer.ctNumAb.length === 0) {
        this.simpleDebounce(() => {
          this.newClient.customer.ctNumAb = this.formatName(this.newClient.entreprise.name);
        }, 500);
      }
    },
    comments: {
      handler() {
        this.simpleDebounce(() => {
          this.saveCommentParc();
        }, 500);
      },
      deep: true,
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.addHeader.classic.date.value = this.date;
    },
    dateBirthday() {
      this.dateBirthdayFormatted = this.formatDateWithoutYear(this.dateBirthday);
      this.addHeader.classic.birthday.value = this.dateBirthday;
    },
    "search.name"() {
      this.simpleDebounce(() => {
        this.getConcurrents();
      }, 500);
    },
    "newClient.entreprise.siret"() {
      this.simpleDebounce(() => {
        this.alreadySiret = null;

        if (this.newClient.entreprise.siret.length === 14) {
          this.$axios("/api/customers/siret", { params: { siret: this.newClient.entreprise.siret } }).then((resp) => {
            if (resp.data) {
              this.alreadySiret = resp.data;
            } else {
              this.$axios("/api/customers/siretData", {
                params: {
                  siret: this.newClient.entreprise.siret,
                },
              }).then((resp) => {
                let tmp = resp.data.etablissement;
                this.newClient.entreprise.name = tmp.uniteLegale.denominationUniteLegale ? tmp.uniteLegale.denominationUniteLegale.substr(0, 35) : "";
                this.newClient.entreprise.ape = tmp.uniteLegale.activitePrincipaleUniteLegale
                  ? tmp.uniteLegale.activitePrincipaleUniteLegale.replace(".", "")
                  : "";
                this.newClient.entreprise.adresse.address = tmp.adresseEtablissement.typeVoieEtablissement
                  ? tmp.adresseEtablissement.typeVoieEtablissement
                  : "" + " " + tmp.adresseEtablissement.libelleVoieEtablissement;
                this.newClient.entreprise.peopleId = parseInt(tmp.uniteLegale.trancheEffectifsUniteLegale);
                this.newClient.entreprise.adresse.address2 = tmp.adresseEtablissement.complementAdresseEtablissement;
                this.newClient.entreprise.adresse.postalCode = tmp.adresseEtablissement.codePostalEtablissement;
                this.newClient.entreprise.adresse.city = tmp.adresseEtablissement.libelleCommuneEtablissement;
                this.$forceUpdate();
              });
            }
          });
        }
      }, 300 /* optional debounce time: default is 300 */);
    },
    "customer.customer.body"() {
      this.simpleDebounce(() => {
        this.saveComment();
      }, 2500 /* optional debounce time: default is 300 */);
    },
    page() {
      this.loadSubs();
    },
    limit() {
      this.loadSubs();
    },
    value() {
      this.customer = this.value;
      this.loadInfra();
      this.loadSubs();
      this.getApeLabel();
      this.getGroups();
      this.getTypes();
      this.getAffectations();
    },
  },
};
</script>

<style scoped>
.toggleKeyCustomer {
  overflow: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.mt-9-px {
  margin-top: 9px;
}
</style>
