<template>
  <div v-if="isGranted('EVENTS_INDEX')">
    <modal v-model="newEventModal" max-width="1024">
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1"> Choix du client </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2"> Choix du contact </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3"> Titre & description </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-autocomplete
              :menu-props="{ maxHeight: '300' }"
              v-model="selectCustomer"
              :items="itemsCustomers"
              :loading="isLoadingCustomers"
              :search-input.sync="searchCustomer.name"
              hide-no-data
              hide-selected
              item-text="customer.entreprise.name"
              label="Choix du client"
              placeholder="Ecrire pour rechercher..."
              return-object
            ></v-autocomplete>
            <div v-if="selectCustomer">
              <v-card color="secondary" class="mb-6">
                <v-row>
                  <v-col cols="12" class="my-0 py-0 pt-2">
                    {{ selectCustomer.entreprise.name }}
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    {{ selectCustomer.entreprise.adresse.adress }}
                  </v-col>
                  <v-col cols="12" class="my-0 py-0"> {{ selectCustomer.entreprise.adresse.postalCode }} {{ selectCustomer.entreprise.adresse.city }} </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    {{ selectCustomer.customer.comment }}
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    {{ selectCustomer.entreprise.email }}
                  </v-col>
                  <v-col cols="12" class="my-0 py-0 pb-2">
                    {{ selectCustomer.entreprise.phone }}
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <div>
              <v-btn color="primary" @click="selectCustomer ? (e1 = 2) : false"> Suivant </v-btn>

              <v-btn text @click="newEventModal = false"> Annuler </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-row class="pb-6" v-if="selectCustomer">
              <v-col cols="4" v-for="(item, key) in selectCustomer.contact" :key="key">
                <v-card elevation="2" class="pa-4" @click="selectedContact.push(item.contact)">
                  <v-switch v-model="selectedContact" :label="item.contact.firstname + ' ' + item.contact.lastname" :value="item.contact.id"> </v-switch>
                </v-card>
              </v-col>
            </v-row>

            <v-btn color="primary" @click="e1 = 3"> Continue </v-btn>

            <v-btn
              text
              @click="
                selectedContact = null;
                e1 = 1;
              "
            >
              Retour
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Titre" v-model="newEvent.name"></v-text-field>
                </v-col>
                <v-col cols="12" class="mx-0 px-0">
                  <listUser @selectChange="setAsUser" multiple label="Choisir un participant"></listUser>
                </v-col>
                <v-col cols="6">
                  <v-switch label="Déjà effectué" inset v-model="newEvent.status"></v-switch>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="newEvent.duration" label="Durée" type="number"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dateFormatted" label="Date" v-bind="attrs" @blur="date = parseDate(dateFormatted)" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker first-day-of-week="1" v-model="date" no-title @input="menu1 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="time" label="Heure" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="menu2" format="24hr" v-model="time" full-width @click:minute="$refs.menu.save(time)"></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="newEvent.body" label="Commentaire"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-btn color="primary" @click="postNewEvent()"> Continue </v-btn>

            <v-btn text @click="e1 = 2"> Annuler </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </modal>
    <Calendar></Calendar>
  </div>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import dayjs from "dayjs";

import Calendar from "./Calendar";
import Tools from "../../mixins/Tools";
import Modal from "../../components/common/modal";
import ListUser from "../../components/user/listUser";

export default {
  name: "CalendarView",
  components: { ListUser, Modal, Calendar },
  mixins: [Tools, simpleDebounce],
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)),
      menu1: false,
      time: null,
      asUser: [this.$store.state.user.id],
      menu2: false,
      searchCustomer: {
        name: "",
        isIndirect: null,
        siret: "",
        postalCode: "",
        city: "",
        sector: "",
        phone: "",
        ctNumAb: "",
        ape: "",
        sortedBy: null,
        sortedDesc: false,
      },
      selectCustomer: null,
      itemsCustomers: [],
      isLoadingCustomers: false,
      newEventModal: false,
      e1: 1,
      typeEvent: [],
      page: 1,
      limitItem: 7,
      newEvent: {
        opp: null,
        eventtypeId: null,
        name: "",
        time: null,
        date: null,
        duration: 30,
        private: false,
        status: false,
        body: "",
        contacts: [],
        user: null,
        history: [],
      },
      selectedContact: [],
    };
  },
  mounted() {
    this.getTypeEvent();
    this.getCustomersList();
  },
  methods: {
    getTypeEvent() {
      this.$axios("/api/events/types").then((resp) => {
        this.typeEvent = resp.data;
      });
    },
    setAsUser(event) {
      this.newEvent.user = event;
    },
    postNewEvent() {
      this.newEvent.customer = this.selectCustomer.customer.id;
      this.newEvent.contacts = this.selectedContact;

      let date = dayjs(this.date + " " + this.time),
        end = date.clone(),
        now = dayjs();

      end.add(parseInt(this.newEvent.duration), "minutes");

      this.newEvent.start = date;
      this.newEvent.createdAt = now;
      this.newEvent.end = end;

      this.$axios.post("/api/events/calendar", { data: this.newEvent }).then(() => {
        this.$simpleToast.success("Evènement", "L'événement a été ajouté");
      });

      this.newEvent = {
        opp: null,
        eventtypeId: null,
        name: "",
        time: null,
        date: null,
        duration: 30,
        private: false,
        status: false,
        body: "",
        contacts: [],
        asUser: [this.$store.state.user.id],
        history: [],
      };
      this.newEventModal = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getCustomersList() {
      this.loading = true;
      this.itemsCustomers = [];
      let search = this.searchCustomer;

      this.$axios("/api/customers", {
        params: {
          page: this.page,
          search,
          limit: this.limitItem,
          asUser: [this.asUser],
          userMode: "tous",
          typeMode: "tous",
          onlyClient: true,
          onlyProspect: true,
        },
      }).then((resp) => {
        this.itemsCustomers = resp.data.data;
      });
      this.loading = false;
    },
  },
  watch: {
    "newEvent.eventtypeId"() {
      this.typeEvent.forEach((item) => {
        if (item.id === this.newEvent.eventtypeId) {
          this.newEvent.duration = item.duration;
        }
      });
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    "searchCustomer.name"() {
      this.page = 1;
      this.simpleDebounce(() => {
        this.getCustomersList();
      }, 300 /* optional debounce time: default is 300 */);
    },
  },
};
</script>

<style scoped></style>
