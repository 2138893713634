import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");

const EventsTools = {
  methods: {
    async deleteEvent(event) {
      let ret = false;
      if (event.ewsid) {
        let tokenResponse = await this.$msal.acquireTokenSilent({
          account: this.$msal.getAllAccounts()[0],
          scopes: ["user.read", "mail.send", "Calendars.ReadWrite", "MailboxSettings.ReadWrite"],
        });

        await axios
          .delete(process.env.VUE_APP_GRAPH_V1 + "calendar/events/" + event.ewsid, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then(() => {
            this.$simpleToast.success("Evènement", "L'evènement à bien été supprimé");
          })
          .catch(() => {
            ret = false;
          });
        ret = true;
      }
      return ret;
    },
    async changeStatusSubmit(event, status) {
      let ret = true;
      let item = JSON.parse(JSON.stringify(event));

      await this.$axios.patch("/api/events/" + item.id + "/status", { status: status }).then(() => {
        this.$simpleToast.success("Evènement", "Changement de statut effectué");

        ret = true;
      });

      return ret;
    },

    async addEventSubmit(event) {
      let ret = true;
      let newEvent = JSON.parse(JSON.stringify(event));

      if (!newEvent.teamsLink) {
        newEvent.teamsLink = "";
      }

      if (newEvent.duration === null || newEvent.duration.length === 0 || newEvent.duration <= 0) {
        this.$simpleToast.error("Evènement", "Durée invalide");
        return false;
      }

      if (newEvent.id) {
        ret = await this.editEventSubmit(event);
        this.$root.$emit("refresh-late");
        return ret;
      }

      let now = dayjs();

      newEvent.start = dayjs(newEvent.date + " " + newEvent.time);
      newEvent.createdAt = now;
      newEvent.end = dayjs(newEvent.date + " " + newEvent.time).add(parseInt(newEvent.duration), "minutes");

      await this.$axios.post("/api/events/" + newEvent.customerId + "/customer", { newEvent }).then(() => {
        this.$simpleToast.success("Evènement", "Modifications enregistrées");
        ret = true;
      });
      this.$root.$emit("refresh-late");
      return ret;
    },
    async editEventSubmit(event, toast = true) {
      let newEvent = JSON.parse(JSON.stringify(event));
      let ret = true;

      if (newEvent.duration === null || newEvent.duration.length === 0 || newEvent.duration === 0) {
        this.$simpleToast.error("Evènement", "Durée invalide");
        return;
      }

      let now = dayjs();

      newEvent.customerId = newEvent.customer;
      newEvent.start = dayjs(newEvent.date + " " + newEvent.time);
      newEvent.createdAt = now;
      newEvent.end = dayjs(newEvent.date + " " + newEvent.time).add(parseInt(newEvent.duration), "minutes");

      await this.$axios.patch("/api/events/" + newEvent.id, { newEvent }).then(() => {
        if (toast) this.$simpleToast.success("Evènement", "Modifications enregistrées");
        ret = true;
      });

      return ret;
    },
  },
};
export default EventsTools;
