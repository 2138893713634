<template>
  <material-card :title="item.item.name">
    <v-row class="mb-3">
      <v-col cols="6">
        <v-row>
          <v-col cols="3" class="py-0">
            <span class="pa-0">Modèle : </span>
          </v-col>
          <v-col cols="8" class="py-0">
            <span>{{ item.item.model }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="3" class="py-0">
            <span class="pa-0">N° Série : </span>
          </v-col>
          <v-col cols="8" class="py-0">
            <span>{{ item.item.serialNumber }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="3" class="py-0">
            <span class="pa-0">OS : </span>
          </v-col>
          <v-col cols="8" class="py-0">
            <span>{{ item.item.os }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="3" class="py-0">
            <span class="pa-0">SP : </span>
          </v-col>
          <v-col cols="8" class="py-0">
            <span>{{ item.item.servicePack }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="3" class="py-0">
            <span class="pa-0">Client : </span>
          </v-col>
          <v-col cols="8" class="py-0">
            <span>{{ item.customer.name }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="3" class="py-0">
            <span class="pa-0">Site : </span>
          </v-col>
          <v-col cols="8" class="py-0">
            <span>{{ item.site.name }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
export default {
  name: "titleSupervision",
  components: { MaterialCard },
  props: ["value"],
  data() {
    return {
      item: this.value,
    };
  },
  watch: {
    item() {
      this.$emit("input", this.item);
    },
    value() {
      this.item = this.value;
    },
  },
};
</script>

<style scoped></style>
