var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "extension-customers-container" },
    [
      _c(
        "v-row",
        [
          _vm.showCustomers
            ? [
                _c(
                  "v-col",
                  { attrs: { cols: "4" } },
                  [
                    _c("v-text-field", {
                      staticClass: "text-field",
                      attrs: {
                        label: "Nom",
                        dense: "",
                        color: "#fff",
                        "background-color": "#004f9f",
                        filled: "",
                      },
                      model: {
                        value: _vm.searchCustomers.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchCustomers, "name", $$v)
                        },
                        expression: "searchCustomers.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "4" } },
                  [
                    _c("v-text-field", {
                      staticClass: "text-field",
                      attrs: {
                        label: "Siret",
                        dense: "",
                        color: "#fff",
                        "background-color": "#004f9f",
                        small: "",
                        filled: "",
                      },
                      model: {
                        value: _vm.searchCustomers.siret,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchCustomers, "siret", $$v)
                        },
                        expression: "searchCustomers.siret",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "v-col",
                  { attrs: { cols: "4" } },
                  [
                    _c("v-text-field", {
                      staticClass: "text-field",
                      attrs: {
                        label: "Nom",
                        dense: "",
                        color: "#fff",
                        "background-color": "#004f9f",
                        filled: "",
                      },
                      model: {
                        value: _vm.searchContacts.lastname,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchContacts, "lastname", $$v)
                        },
                        expression: "searchContacts.lastname",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "4" } },
                  [
                    _c("v-text-field", {
                      staticClass: "text-field",
                      attrs: {
                        label: "Prenom",
                        dense: "",
                        color: "#fff",
                        "background-color": "#004f9f",
                        filled: "",
                      },
                      model: {
                        value: _vm.searchContacts.firstname,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchContacts, "firstname", $$v)
                        },
                        expression: "searchContacts.firstname",
                      },
                    }),
                  ],
                  1
                ),
              ],
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-btn-toggle",
                {
                  attrs: { group: "", dense: "", small: "" },
                  model: {
                    value: _vm.showCustomers,
                    callback: function ($$v) {
                      _vm.showCustomers = $$v
                    },
                    expression: "showCustomers",
                  },
                },
                [
                  _c(
                    "v-btn",
                    { attrs: { value: true, dense: "", small: "" } },
                    [
                      _c("span", { staticClass: "text-white" }, [
                        _vm._v("Client"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { value: false, dense: "", small: "" } },
                    [
                      _c("span", { staticClass: "text-white" }, [
                        _vm._v("Contact"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "notificationBell" },
                [_c("NotificationBell")],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("v-data-table", {
        staticClass: "data-table primary",
        attrs: {
          loading: "",
          "loading-text": "Chargement...",
          dense: "",
          color: "#004f9f",
          headers: _vm.showCustomers
            ? _vm.headersCustomers
            : _vm.headersContacts,
          items: _vm.showCustomers ? _vm.customers : _vm.contacts,
          "items-per-page": _vm.limitItem,
          "hide-default-header": true,
          "server-items-length": _vm.maxItem,
          "mobile-breakpoint": "0",
          "no-data-text": "Aucun résultat",
          "no-results-text": "Aucun résultat",
          "footer-props": {
            dense: true,
            itemsPerPageOptions: [9],
            showFirstLastPage: true,
            "first-icon": "mdi-chevron-double-left mdi-light",
            "prev-icon": "mdi-chevron-left mdi-light",
            "next-icon": "mdi-chevron-right mdi-light",
            "last-icon": "mdi-chevron-double-right mdi-light",
          },
        },
        on: { "click:row": _vm.linkToEntity, "update:page": _vm.setPage },
        scopedSlots: _vm._u([
          {
            key: "item.contact.phone",
            fn: function ({ item }) {
              return [
                item.contact.phone
                  ? _c(
                      "div",
                      { staticClass: "text-no-wrap text-white" },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { "x-small": "" } },
                          [_vm._v("fa-fw fa-phone")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text-white",
                            attrs: { href: "tel:" + item.contact.phone },
                          },
                          [_vm._v(_vm._s(item.contact.phone))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                item.contact.phone2
                  ? _c(
                      "div",
                      { staticClass: "text-no-wrap text-white" },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { "x-small": "" } },
                          [_vm._v("fa-fw fa-mobile-alt")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text-white",
                            attrs: { href: "tel:" + item.contact.phone2 },
                          },
                          [_vm._v(_vm._s(item.contact.phone2))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }