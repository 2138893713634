export const settingsListData = [
  {
    name: "Configuration Générale",
    icon: "fa-cog",
    role: "CONFIGURATIONS_INDEX",
    path: "global-settings",
  },
  {
    name: "Gestion des types d'événements",
    icon: "far fa-calendar-alt",
    role: "EVENTS_SETTINGS",
    path: "event-settings",
  },
  {
    name: "Gestion des utilisateurs",
    icon: "far fa-user",
    role: "USERS_EDIT",
    path: "user-settings",
  },
  {
    name: "Gestion des fonctions",
    icon: "fas fa-user-secret",
    role: "JOBS_EDIT",
    path: "job-settings",
  },
  {
    name: "Gestion des rôles",
    icon: "fa-user-tag",
    role: "ROLES_EDIT",
    path: "roles-settings",
  },
  {
    name: "Gestion des services & métiers",
    icon: "fa-graduation-cap",
    role: "SERVICES_EDIT",
    path: "services-settings",
  },
  {
    name: "Gestion des secteurs",
    icon: "fa-globe-europe",
    role: "USERS_AFFECTATIONS",
    path: "sector-settings",
  },
  {
    name: "Gestion des catégories d'affaires",
    icon: "fa-tags",
    role: "CATEGORIES_EDIT",
    path: "categories-settings",
  },
  {
    name: "Gestion des groupements",
    icon: "fa-user-friends",
    role: "GROUPS_INDEX",
    path: "groupements-settings",
  },
  {
    name: "Gestion des parcs",
    icon: "fab fa-apple",
    role: "INFRASTRUCTURETYPES_INDEX",
    path: "parcs-settings",
  },
  {
    name: "Gestion des challenges",
    icon: "fa fa-flag-checkered",
    role: "CHALLENGES_EDIT",
    path: "challenges-settings",
  },
];
