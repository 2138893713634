var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item-icon",
    {
      staticStyle: { cursor: "pointer" },
      attrs: { small: "" },
      on: { click: _vm.eventLink },
    },
    [
      _vm.eventsLate &&
      _vm.eventsLate.events &&
      _vm.eventsLate.events.length > 0
        ? _c(
            "v-badge",
            {
              attrs: {
                dot: "",
                content: _vm.eventsLate.events.length,
                color: "#EF5350",
                overlap: "",
              },
            },
            [
              _c("v-icon", { attrs: { small: "", color: "#fff" } }, [
                _vm._v(" far fa-bell fa-fw "),
              ]),
            ],
            1
          )
        : _c("v-icon", { attrs: { small: "", color: "#fff" } }, [
            _vm._v(" far fa-bell-slash fa-fw "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }