import GlobalSettings from "../../views/settings/globalSettings";
import UserSettings from "../../views/settings/userSettings";
import RolesSettings from "../../views/settings/rolesSettings";
import ServicesSettings from "../../views/settings/servicesSettings";
import SectorSettings from "../../views/settings/sectorSettings";
import CategoriesSettings from "../../views/settings/categoriesSettings";
import GroupementsSettings from "../../views/settings/groupementsSettings";
import ParcsSettings from "../../views/settings/parcsSettings";
import ChallengesSettings from "../../views/settings/challengesSettings";
import EventSettings from "../../views/settings/eventSettings";
import fonctionSettings from "../../views/settings/fonctionSettings";

export const settingsRoute = [
  {
    path: "",
    name: "Configuration Générale",
    component: GlobalSettings,
    meta: { requiresAuth: true },
  },
  {
    path: "global-settings",
    name: "Configuration Générale",
    component: GlobalSettings,
    meta: { requiresAuth: true, grant: "CONFIGURATIONS_INDEX" },
  },
  {
    path: "event-settings",
    name: "Gestion des types d'événements",
    component: EventSettings,
    meta: { requiresAuth: true, grant: "EVENTS_SETTINGS" },
  },
  {
    path: "user-settings",
    name: "Gestion des utilisateurs",
    component: UserSettings,
    meta: { requiresAuth: true, grant: "USERS_EDIT" },
  },
  {
    path: "job-settings",
    name: "Gestion des fonctions",
    component: fonctionSettings,
    meta: { requiresAuth: true, grant: "JOBS_EDIT" },
  },
  {
    path: "roles-settings",
    name: "Gestion des rôles",
    component: RolesSettings,
    meta: { requiresAuth: true, grant: "ROLES_EDIT" },
  },
  {
    path: "services-settings",
    name: "Gestion des services & métiers",
    component: ServicesSettings,
    meta: { requiresAuth: true, grant: "SERVICES_EDIT" },
  },
  {
    path: "sector-settings",
    name: "Gestion des secteurs",
    component: SectorSettings,
    meta: { requiresAuth: true, grant: "USERS_AFFECTATIONS" },
  },
  {
    path: "categories-settings",
    name: "Gestion des catégories d'affaires",
    component: CategoriesSettings,
    meta: { requiresAuth: true, grant: "CATEGORIES_EDIT" },
  },
  {
    path: "groupements-settings",
    name: "Gestion des groupements",
    component: GroupementsSettings,
    meta: { requiresAuth: true, grant: "GROUPS_INDEX" },
  },
  {
    path: "parcs-settings",
    name: "Gestion des parcs",
    component: ParcsSettings,
    meta: { requiresAuth: true, grant: "INFRASTRUCTURETYPES_INDEX" },
  },
  {
    path: "challenges-settings",
    name: "Gestion des challenges",
    component: ChallengesSettings,
    meta: { requiresAuth: true, grant: "CHALLENGES_EDIT" },
  },
];
