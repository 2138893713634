var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isGranted("EVENTS_INDEX")
    ? _c(
        "div",
        [
          _c(
            "modal",
            {
              attrs: { "max-width": "1024" },
              model: {
                value: _vm.newEventModal,
                callback: function ($$v) {
                  _vm.newEventModal = $$v
                },
                expression: "newEventModal",
              },
            },
            [
              _c(
                "v-stepper",
                {
                  model: {
                    value: _vm.e1,
                    callback: function ($$v) {
                      _vm.e1 = $$v
                    },
                    expression: "e1",
                  },
                },
                [
                  _c(
                    "v-stepper-header",
                    [
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.e1 > 1, step: "1" } },
                        [_vm._v(" Choix du client ")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.e1 > 2, step: "2" } },
                        [_vm._v(" Choix du contact ")]
                      ),
                      _c("v-divider"),
                      _c("v-stepper-step", { attrs: { step: "3" } }, [
                        _vm._v(" Titre & description "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-items",
                    [
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              "menu-props": { maxHeight: "300" },
                              items: _vm.itemsCustomers,
                              loading: _vm.isLoadingCustomers,
                              "search-input": _vm.searchCustomer.name,
                              "hide-no-data": "",
                              "hide-selected": "",
                              "item-text": "customer.entreprise.name",
                              label: "Choix du client",
                              placeholder: "Ecrire pour rechercher...",
                              "return-object": "",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                return _vm.$set(
                                  _vm.searchCustomer,
                                  "name",
                                  $event
                                )
                              },
                              "update:search-input": function ($event) {
                                return _vm.$set(
                                  _vm.searchCustomer,
                                  "name",
                                  $event
                                )
                              },
                            },
                            model: {
                              value: _vm.selectCustomer,
                              callback: function ($$v) {
                                _vm.selectCustomer = $$v
                              },
                              expression: "selectCustomer",
                            },
                          }),
                          _vm.selectCustomer
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mb-6",
                                      attrs: { color: "secondary" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "my-0 py-0 pt-2",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectCustomer
                                                      .entreprise.name
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "my-0 py-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectCustomer
                                                      .entreprise.adresse.adress
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "my-0 py-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectCustomer
                                                      .entreprise.adresse
                                                      .postalCode
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.selectCustomer
                                                      .entreprise.adresse.city
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "my-0 py-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectCustomer.customer
                                                      .comment
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "my-0 py-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectCustomer
                                                      .entreprise.email
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "my-0 py-0 pb-2",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectCustomer
                                                      .entreprise.phone
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.selectCustomer ? (_vm.e1 = 2) : false
                                    },
                                  },
                                },
                                [_vm._v(" Suivant ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.newEventModal = false
                                    },
                                  },
                                },
                                [_vm._v(" Annuler ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _vm.selectCustomer
                            ? _c(
                                "v-row",
                                { staticClass: "pb-6" },
                                _vm._l(
                                  _vm.selectCustomer.contact,
                                  function (item, key) {
                                    return _c(
                                      "v-col",
                                      { key: key, attrs: { cols: "4" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "pa-4",
                                            attrs: { elevation: "2" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectedContact.push(
                                                  item.contact
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-switch", {
                                              attrs: {
                                                label:
                                                  item.contact.firstname +
                                                  " " +
                                                  item.contact.lastname,
                                                value: item.contact.id,
                                              },
                                              model: {
                                                value: _vm.selectedContact,
                                                callback: function ($$v) {
                                                  _vm.selectedContact = $$v
                                                },
                                                expression: "selectedContact",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 3
                                },
                              },
                            },
                            [_vm._v(" Continue ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.selectedContact = null
                                  _vm.e1 = 1
                                },
                              },
                            },
                            [_vm._v(" Retour ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "3" } },
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Titre" },
                                        model: {
                                          value: _vm.newEvent.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.newEvent, "name", $$v)
                                          },
                                          expression: "newEvent.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mx-0 px-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("listUser", {
                                        attrs: {
                                          multiple: "",
                                          label: "Choisir un participant",
                                        },
                                        on: { selectChange: _vm.setAsUser },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: "Déjà effectué",
                                          inset: "",
                                        },
                                        model: {
                                          value: _vm.newEvent.status,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newEvent,
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression: "newEvent.status",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Durée",
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.newEvent.duration,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newEvent,
                                              "duration",
                                              $$v
                                            )
                                          },
                                          expression: "newEvent.duration",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "menu1",
                                          attrs: {
                                            "close-on-content-click": false,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "max-width": "290px",
                                            "min-width": "auto",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              label: "Date",
                                                            },
                                                            on: {
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                _vm.date =
                                                                  _vm.parseDate(
                                                                    _vm.dateFormatted
                                                                  )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.dateFormatted,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.dateFormatted =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "dateFormatted",
                                                            },
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1606125251
                                          ),
                                          model: {
                                            value: _vm.menu1,
                                            callback: function ($$v) {
                                              _vm.menu1 = $$v
                                            },
                                            expression: "menu1",
                                          },
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: {
                                              "first-day-of-week": "1",
                                              "no-title": "",
                                            },
                                            on: {
                                              input: function ($event) {
                                                _vm.menu1 = false
                                              },
                                            },
                                            model: {
                                              value: _vm.date,
                                              callback: function ($$v) {
                                                _vm.date = $$v
                                              },
                                              expression: "date",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "menu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "nudge-right": 40,
                                            "return-value": _vm.time,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "max-width": "290px",
                                            "min-width": "290px",
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              _vm.time = $event
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              _vm.time = $event
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              label: "Heure",
                                                              readonly: "",
                                                            },
                                                            model: {
                                                              value: _vm.time,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.time = $$v
                                                                },
                                                              expression:
                                                                "time",
                                                            },
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            408623483
                                          ),
                                          model: {
                                            value: _vm.menu2,
                                            callback: function ($$v) {
                                              _vm.menu2 = $$v
                                            },
                                            expression: "menu2",
                                          },
                                        },
                                        [
                                          _vm.menu2
                                            ? _c("v-time-picker", {
                                                attrs: {
                                                  format: "24hr",
                                                  "full-width": "",
                                                },
                                                on: {
                                                  "click:minute": function (
                                                    $event
                                                  ) {
                                                    return _vm.$refs.menu.save(
                                                      _vm.time
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.time,
                                                  callback: function ($$v) {
                                                    _vm.time = $$v
                                                  },
                                                  expression: "time",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: { label: "Commentaire" },
                                        model: {
                                          value: _vm.newEvent.body,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.newEvent, "body", $$v)
                                          },
                                          expression: "newEvent.body",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.postNewEvent()
                                },
                              },
                            },
                            [_vm._v(" Continue ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 2
                                },
                              },
                            },
                            [_vm._v(" Annuler ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("Calendar"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }