<template>
  <MaterialCard :title="label" class="mt-8">
    <v-row v-if="stats">
      <v-col cols="4" class="text-center">
        <h1 class="primary--text py-0 my-0">{{ stats.created }}</h1>
        <h4 style="font-style: italic; font-weight: normal;" class="py-0 my-0">Opportunités créées</h4>
      </v-col>
      <v-col cols="4" class="text-center">
        <h1 class="primary--text py-0 my-0">{{ stats.amount }} €</h1>
        <h4 style="font-style: italic; font-weight: normal;" class="py-0 my-0">Prévisionnel</h4>
      </v-col>
      <v-col cols="4" class="text-center">
        <h1 class="primary--text py-0 my-0">
          <span class="success--text">{{ stats.win }}</span> / <span class="error--text">{{ stats.loose }}</span>
        </h1>
        <h4 style="font-style: italic; font-weight: normal;" class="py-0 my-0">Opportunités fermées</h4>
      </v-col>
    </v-row>
  </MaterialCard>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
export default {
  name: "opportunitiesStatistics",
  props: ["value", "label"],
  components: { MaterialCard },
  data() {
    return {
      stats: this.value,
    };
  },
  watch: {
    value() {
      this.stats = this.value;
    },
  },
};
</script>

<style scoped></style>
