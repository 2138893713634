<template>
  <div class="d-block d-sm-none landscape">
    <img src="/images/rotate-phone.png" alt="Icône Téléphone" width="32px" height="32px" />
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped>
.landscape {
  position: fixed;
  z-index: 9999;
  top: 55px;
  right: 15px;
  animation: bounce 1s infinite;
}
@keyframes bounce {
  from {
    top: 55px;
  }
  50% {
    top: 60px;
  }
  to {
    top: 55px;
  }
}
</style>
