<template>
  <v-row class="py-0">
    <template v-if="challenge && challenge.global">
      <Landscape />
      <v-col cols="12" class="py-0">
        <title-challenge v-model="challenge" @prevChall="prevChall" @nextChall="nextChall"></title-challenge>
      </v-col>
      <template v-if="challenge.global.length > 0">
        <v-col cols="12" lg="6" xl="4" class="py-0 mt-10">
          <hebdo-challenge v-model="challenge"></hebdo-challenge>
        </v-col>
        <v-col cols="12" lg="6" xl="4" class="py-0 mt-10">
          <season-challenge v-model="challenge"></season-challenge>
        </v-col>
        <v-col cols="12" xl="4" class="pa-0 ma-0">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="py-0 mt-10">
              <bonus-challenge v-model="challenge"></bonus-challenge>
            </v-col>
            <v-col cols="12" class="py-0 mt-10">
              <ecurie-challenge v-model="challenge"></ecurie-challenge>
            </v-col>
            <v-col cols="12" class="py-0 mt-10">
              <legende-challenge v-model="challenge"></legende-challenge>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" class="text-center mt-16 v-card-title">
          <v-icon color="red" size="100">mdi-database-alert</v-icon>
          <v-card-title class="mt-2 justify-center primary--text">Aucune donnée pour ce challenge</v-card-title>
        </v-col>
      </template>
    </template>
    <template v-else>
      <v-col cols="12" class="text-center mt-16">
        <LoaderActuelburo />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import TitleChallenge from "./titleChallenge";
import HebdoChallenge from "./hebdoChallenge";
import SeasonChallenge from "./seasonChallenge";
import BonusChallenge from "./bonusChallenge";
import LegendeChallenge from "./legendeChallenge";
import EcurieChallenge from "./ecurieChallenge";
import LoaderActuelburo from "../../components/common/LoaderActuelburo.vue";
import Landscape from "../../components/common/Landscape.vue";

export default {
  name: "challengeView",
  components: { EcurieChallenge, LegendeChallenge, BonusChallenge, SeasonChallenge, HebdoChallenge, TitleChallenge, LoaderActuelburo, Landscape },
  data() {
    return {
      challenge: null,
      id: null,
    };
  },
  mounted() {
    this.getInfos();
  },
  methods: {
    getInfos() {
      if (!this.$route.params.id) {
        this.$axios("/api/challenges/last").then((resp) => {
          this.id = resp.data.id;
        });
      }
    },
    prevChall() {
      this.$axios("/api/challenges/" + this.id + "/prev").then((resp) => {
        if (this.id > resp.data.id) {
          this.id = resp.data.id;
          this.$forceUpdate();
        }
      });
    },
    getInfoItem() {
      this.challenge = null;
      this.$axios("/api/challenges/" + this.id + "/infos").then((resp) => {
        let key = Object.keys(resp.data.classement.general);
        let chall = [];
        key.forEach((item) => {
          chall.push(resp.data.classement.general[item]);
        });

        chall.sort((a, b) => {
          if (a["points"] === b["points"] && a["nb"] && b["nb"]) return a["nb"] > b["nb"] ? -1 : 1;
          else if (a["points"] === b["points"]) return a["gain"] > b["gain"] ? -1 : 1;
          else return a["points"] > b["points"] ? -1 : 1;
        });
        resp.data.global = chall;

        let tmp = [];
        let tmpArr = [];
        for (const it of Object.keys(resp.data.classement.week)) {
          if (tmpArr.length > 0) {
            if (it > parseInt(tmpArr[0]) + 26) {
              tmp.unshift(resp.data.classement.week[it]);
              tmpArr.unshift(it);
            } else {
              tmp.push(resp.data.classement.week[it]);
              tmpArr.push(it);
            }
          } else {
            tmp.push(resp.data.classement.week[it]);
            tmpArr.push(it);
          }
        }

        let tmpObj = {};
        for (let i = 0; i < tmp.length; i++) {
          tmpObj[" " + tmpArr[i]] = tmp[i];
        }
        resp.data.classement.week = tmpObj;
        this.challenge = resp.data;
      });
    },
    nextChall() {
      this.$axios("/api/challenges/" + this.id + "/next").then((resp) => {
        if (this.id < resp.data.id) {
          this.id = resp.data.id;
          this.$forceUpdate();
        }
        this.$forceUpdate();
      });
    },
  },
  watch: {
    id() {
      this.getInfoItem();
    },
  },
};
</script>

<style scoped></style>
