<template>
  <material-card icon="fa-paper-plane" title="Campagne" style="position: relative" class="mt-10">
    <modal v-model="linkCompaign" max-width="1024">
      <v-data-table
        :headers="headers"
        :items="compaigns"
        class="elevation-1 tableCampaign"
        :loading="loading"
        :server-items-length="maxItem"
        @update:page="setPage"
        @update:items-per-page="setItemMax"
        @click:row="linkCompaignFct"
      >
        <template v-slot:body.prepend>
          <tr>
            <td>
              <v-text-field v-model="search.id" type="number" label="Filtrer"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="search.name" label="Filtrer"></v-text-field>
            </td>
            <td>
              <v-select multiple v-model="search.types" :items="types" item-text="name" item-value="id" label="Filtrer"></v-select>
            </td>
            <td>
              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStartFormatted"
                    label="Filtrer"
                    persistent-hint
                    v-bind="attrs"
                    readonly
                    @click:append-outer="search.dateStart = null"
                    :append-outer-icon="search.dateStart === null ? '' : 'fa-times'"
                    @blur="search.dateStart = parseDate(dateStartFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.dateStart" no-title @input="menu1 = false"></v-date-picker>
              </v-menu>
            </td>
            <td>
              <v-menu ref="menu1" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEndFormatted"
                    label="Filtrer"
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    @click:append-outer="search.dateEnd = null"
                    :append-outer-icon="search.dateEnd === null ? '' : 'fa-times'"
                    @blur="search.dateEnd = parseDate(dateEndFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.dateEnd" no-title @input="menu2 = false"></v-date-picker>
              </v-menu>
            </td>
            <td>
              <v-select
                :menu-props="{ maxHeight: '300' }"
                label="Filtrer"
                :items="status"
                v-model="search.status"
                item-value="value"
                item-text="name"
                :append-outer-icon="search.status === null ? '' : 'fa-times'"
                @click:append-outer="search.status = null"
              ></v-select>
            </td>
          </tr>
        </template>

        <template v-slot:item.bu="{ item }">
          <v-chip dark v-for="bu in item.types" :key="bu.id + '-' + item.id" label x-small :color="bu.color" class="mr-1">
            <v-icon x-small>fa-fw {{ bu.icon }}</v-icon>
          </v-chip>
        </template>

        <template v-slot:item.draft="{ item }">
          <v-chip label small v-if="item.draft" :color="'error'" class="mr-1"> Brouillon </v-chip>
          <template v-else>
            <v-chip label small :color="getPhase(item).color">
              {{ getPhase(item).name }}
            </v-chip>
          </template>
        </template>

        <template v-slot:item.startDate="{ item }">
          {{ dayjs(item.startDate).format("DD/MM/YYYY") }}
        </template>

        <template v-slot:item.endDate="{ item }">
          {{ dayjs(item.endDate).format("DD/MM/YYYY") }}
        </template>
      </v-data-table>
    </modal>
    <template v-if="compaign === null">
      <div class="listbox--actions-add">
        <v-btn fab small color="primary" @click="linkCompaign = true"><v-icon small>fa-link</v-icon></v-btn>
      </div>
      <div class="font-weight-light grey--text mb-5 text-center">L'opportunité n'est lié à aucune campagne</div>
    </template>
    <template v-else>
      <div @click="openCompaign()" style="cursor: pointer">
        <v-row class="py-0">
          <v-col cols="12" class="text-center py-0">
            <v-card-subtitle class="ma-0 pa-0"
              ><span class="text-overline" style="font-size: 1rem !important">{{ compaign.name }}</span></v-card-subtitle
            >
          </v-col>
          <v-col cols="12" class="py-0 text-center">
            <v-card-subtitle class="ma-0 pa-0"
              >{{ dayjs(compaign.startDate).format("DD/MM/YYYY") }} - {{ dayjs(compaign.endDate).format("DD/MM/YYYY") }}</v-card-subtitle
            >
          </v-col>
        </v-row>
        <v-row class="py-0">
          <v-col cols="12" class="text-center">
            <v-chip label small v-if="compaign.draft" :color="'error'" class="mr-1"> Brouillon </v-chip>
            <template v-else>
              <v-chip label small :color="getPhase(compaign).color" class="mr-1">
                {{ getPhase(compaign).name }}
              </v-chip>
            </template>

            <v-chip v-for="bu in compaign.types" :key="bu.id + '-' + compaign.id" label small :color="bu.color" class="mr-1">
              {{ bu.name }}
            </v-chip>
          </v-col>
        </v-row>
      </div>
    </template>
  </material-card>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import MaterialCard from "../../components/common/MaterialCard";
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";

export default {
  name: "compaignOpportunities",
  components: { Modal, MaterialCard },
  mixins: [simpleDebounce, Tools],
  data() {
    return {
      compaign: null,
      headers: [
        { text: "#", sortable: false, value: "id" },
        { text: "Nom", sortable: false, value: "name" },
        { text: "Métiers Concernés", sortable: false, value: "bu" },
        { text: "Date de début", sortable: false, value: "startDate" },
        { text: "Date de fin", sortable: false, value: "endDate" },
        { text: "Status", sortable: false, value: "draft" },
      ],
      loading: false,
      compaigns: [],
      maxItem: 0,
      page: 1,
      limit: 5,
      search: {
        id: null,
        name: null,
        types: null,
        dateStart: null,
        dateEnd: null,
        status: null,
      },
      dateStartFormatted: null,
      dateEndFormatted: null,
      menu1: false,
      menu2: false,
      status: [
        { name: "Publié", value: 0 },
        { name: "Brouillon", value: 1 },
      ],
      linkCompaign: false,
    };
  },
  mounted() {
    this.getCompaign();
    this.getCompaigns();
    this.getTypes();
  },
  methods: {
    openCompaign() {
      this.$router.push({ name: "Campagne", params: { id: this.compaign.id } });
    },
    async linkCompaignFct(item) {
      let res = await this.$confirm("Lier à cette campagne ?");

      if (res) {
        this.$axios.patch("/api/opportunities/" + this.$route.params.id + "/compaign", { data: item.id }).then(() => {
          this.getCompaign();
          this.linkCompaign = false;
          this.$simpleToast.success("Campagnes", "La campagne a bien été lié");
        });
      }
    },
    getTypes() {
      this.$axios("/api/types").then((resp) => {
        this.types = resp.data.types;
      });
    },
    getCompaigns() {
      this.loading = true;
      this.$axios("/api/compaign", { params: { page: this.page, limit: this.limit, search: this.search } }).then((resp) => {
        this.maxItem = resp.data.max;
        this.compaigns = resp.data.data;
        this.loading = false;
      });
    },

    setItemMax(max) {
      this.limit = max;
    },
    setPage(page) {
      this.page = page;
    },
    getCompaign() {
      this.$axios("/api/opportunities/" + this.$route.params.id + "/compaign").then((resp) => {
        this.compaign = resp.data;
      });
    },
    getCompaignsDebounce() {
      this.simpleDebounce(() => {
        this.getCompaigns();
      }, 500);
    },
  },
  watch: {
    "$route.params.id": "getCompaigns",
    limit: "getCompaignsDebounce",
    page: "getCompaignsDebounce",
    "search.id": "getCompaignsDebounce",
    "search.name": "getCompaignsDebounce",
    "search.status": "getCompaignsDebounce",
    "search.types": "getCompaignsDebounce",
    search: "getCompaignsDebounce",
    "search.dateStart"() {
      this.dateStartFormatted = this.formatDate(this.search.dateStart);
      this.simpleDebounce(() => {
        this.getCompaigns();
      }, 500);
    },
    "search.dateEnd"() {
      this.dateEndFormatted = this.formatDate(this.search.dateEnd);
      this.simpleDebounce(() => {
        this.getCompaigns();
      }, 500);
    },
  },
};
</script>

<style scoped></style>
