<template>
  <MaterialCard class="mt-10" title="Commentaires" icon="fa-comment">
    <div v-if="false">
      <template>
        <v-tabs fixed-tabs v-model="tab" background-color="transparent" slider-color="white">
          <v-tab class="mr-3"> Commentaire </v-tab>
          <v-tab v-if="isGranted('OPPORTUNITIES_EDIT_HISTORY')"> Historique </v-tab>
        </v-tabs>
      </template>
    </div>
    <div class="listbox--actions-add" v-if="isGranted('OPPORTUNITIES_EDIT_COMMENT')">
      <v-btn small color="primary" fab @click="showAddComment = !showAddComment">
        <v-icon small>fa-fw fa-plus</v-icon>
      </v-btn>
    </div>

    <v-expand-transition>
      <v-card v-show="showAddComment" elevation="0">
        <v-card-text class="text-right">
          <v-textarea auto-grow hide-details class="mb-3" rows="3" outlined v-model="comment" persistent-placeholder label="Nouveau commentaire"></v-textarea>
          <v-btn color="primary" dark elevation="0" small @click="addComment"> <v-icon x-small class="mr-1">fa-check fa-fw</v-icon> Ajouter </v-btn>
          <v-divider class="mt-5"></v-divider>
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row class="ma-0" style="">
          <v-col cols="12" class="py-0">
            <v-text-field label="Rechercher un commentaire" v-model="search"></v-text-field>
          </v-col>
        </v-row>
        <div style="max-height: 400px; overflow-y: scroll" v-if="comments && comments.events && comments.events.length > 0">
          <v-container>
            <v-timeline align-top dense>
              <v-timeline-item
                small
                v-for="(item, i) in comments.events"
                :color="Object.keys(item).length > 6 && events.type ? events.type[item.eventtypeId].color : 'error'"
                :key="i"
                class="my-0 mb-4 pl-0"
              >
                <template v-slot:icon>
                  <UserAvatar :userId="item.userId" size="40" />
                  <span class="white" style="position: absolute; top: 35px; text-align: center; font-size: 0.8rem">
                    {{ dayjs(item.createdAt).format("DD/MM/YYYY") }}<br />
                    {{ dayjs(item.createdAt).format("HH:mm") }}
                  </span>
                </template>
                <v-card :class="{ eventstandby: item.status == 0, eventsuccess: item.status == 1, eventcancel: item.status == 2 }">
                  <v-card-text>
                    {{ item.body ? item.body : "Pas de commentaire" }}
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-container>
        </div>
        <div v-else class="font-weight-light grey--text mb-5 text-center">Aucun commentaire</div>
      </v-tab-item>
      <v-tab-item v-if="isGranted('OPPORTUNITIES_EDIT_HISTORY')">
        <div style="max-height: 400px; overflow-y: scroll">
          <v-timeline align-top dense>
            <v-timeline-item small v-for="(item, i) in history" :key="i">
              <template v-slot:icon>
                <UserAvatar :userId="item.timeline.userId" size="40" />
              </template>
              <v-row class="pt-1 px-0">
                <v-col cols="1">
                  <v-icon x-small>{{ returnIcon(item.status) }}</v-icon>
                </v-col>
                <v-col class="my-0 py-1">
                  <strong>
                    <v-icon class="mr-1" small> fa-history </v-icon>
                    {{ item.timeline.action }} {{ item.timeline.commentId ? "commentaire" : "" }} {{ item.timeline.eventId ? "event" : "" }}
                  </strong>
                  <div class="text-caption">
                    <v-card-text class="white text--primary" style="padding: 0.5rem">
                      le {{ dayjs(item.timeline.createdAt).format("DD/MM/YYYY à HH:mm") }}
                      <v-row v-if="item.timelineField">
                        <v-col cols="12" md="12" v-if="isJsonString(item.timelineField.oldValue) && JSON.parse(item.timelineField.oldValue)">
                          <tree-view :data="JSON.parse(item.timelineField.oldValue)" :options="{ maxDepth: 0, rootObjectKey: 'Ancienne Valeur' }"></tree-view>
                        </v-col>
                        <v-col cols="12" md="12" v-if="isJsonString(item.timelineField.newValue) && JSON.parse(item.timelineField.newValue)">
                          <tree-view :data="JSON.parse(item.timelineField.newValue)" :options="{ maxDepth: 0, rootObjectKey: 'Nouvelle Valeur' }"></tree-view>
                        </v-col>
                        <v-col cols="12" md="12" v-if="!isJsonString(item.timelineField.newValue) || !JSON.parse(item.timelineField.newValue)">
                          {{ item.timelineField.newValue }}
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </MaterialCard>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
import UserAvatar from "../../components/user/UserAvatar.vue";
import Tools from "../../mixins/Tools";
import simpleDebounce from "simple-debounce-vue-mixin";
export default {
  name: "commentOpportunities",
  mixins: [Tools, simpleDebounce],
  components: { MaterialCard, UserAvatar },
  props: ["value"],
  data() {
    return {
      tab: 0,
      comments: [],
      events: [],
      comment: "",
      addEvent: false,
      asUser: null,
      contactsList: [],
      newEvent: {},
      loadingSave: false,
      item: null,
      history: [],
      customerInfo: null,
      search: "",
      showAddComment: false,
    };
  },
  created() {},
  mounted() {
    this.getComments();
    this.getHistory();
  },
  methods: {
    returnIcon(status) {
      switch (status) {
        case 0:
          return { icon: "far fa-pause-circle", color: "#CFD8DC" };
        case 1:
          return { icon: "far fa-check-circle", color: "#43A047" };
        case 2:
          return { icon: "far fa-times-circle", color: "#C62828" };
      }
    },
    setAsUser(event) {
      this.asUser = event;
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    addComment() {
      this.$axios.post("/api/comments", { comment: this.comment, opp: this.$route.params.id }).then(() => {
        this.$simpleToast.success("Commentaires", "Le commentaire a bien été ajouté");
        this.getComments();
        this.getHistory();
        this.comment = "";
        this.showAddComment = false;
      });
    },
    getComments() {
      this.$axios("/api/comments/" + this.$route.params.id, { params: { search: this.search } }).then((data) => {
        this.comments = data.data;
      });
    },
    getHistory() {
      this.$axios("/api/timeline/opportunity/" + this.$route.params.id).then((data) => {
        this.history = data.data;
      });
    },

    getCustomersInfo() {
      this.$axios("/api/customers/" + this.item.customer.id).then((data) => {
        this.customerInfo = data.data;
        this.contactsList = [];
        this.newEvent.customer = data.data.customer.id;
        if (data && data.data.contact)
          data.data.contact.forEach((item) => {
            this.contactsList.push({
              name: item.contact.firstname + " " + item.contact.lastname,
              email: item.contact.email,
              origin: item,
              id: item.id,
              favorite: item.contact.isFavorite,
            });
          });

        this.newEvent.contactsList = this.contactsList;
      });
    },
  },
  watch: {
    "$route.params.id"() {
      this.getComments();
    },
    search() {
      this.simpleDebounce(() => {
        this.getComments();
      }, 300 /* optional debounce time: default is 300 */);
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    value() {
      this.item = this.value;
      if (this.item.customer && this.contactsList.length === 0) {
        this.getCustomersInfo();
      }
    },
    item() {
      this.$emit("input", this.item);
    },
  },
};
</script>

<style scoped></style>
