var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "modal",
        {
          attrs: { "max-width": "760" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [_c("div")]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.openUploadModal,
            callback: function ($$v) {
              _vm.openUploadModal = $$v
            },
            expression: "openUploadModal",
          },
        },
        [
          _c("v-card-title", [_vm._v("Changer la photo")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-file-input", {
                        attrs: {
                          label: "Choisir une image",
                          accept: "image/png, image/jpeg, image/bmp",
                          "prepend-icon": "fa-mdiCamera",
                        },
                        model: {
                          value: _vm.filePicture,
                          callback: function ($$v) {
                            _vm.filePicture = $$v
                          },
                          expression: "filePicture",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "error" },
                          on: {
                            click: function ($event) {
                              _vm.openUploadModal = false
                            },
                          },
                        },
                        [_vm._v("Annuler")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.uploadPicture },
                        },
                        [_vm._v("Sauvegarder")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isGranted("USERS_INDEX")
        ? _c(
            "v-card",
            { staticStyle: { "z-index": "10" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary ", dark: "", elevation: "0" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "11" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "prepend-icon": "fa-search",
                              label: "Rechercher un utilisateur",
                              "single-line": "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "1" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "listbox--actions-add",
                              attrs: { fab: "", color: "primary", small: "" },
                              on: { click: _vm.addUserModal },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("fa-plus fa-fw"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-0",
                attrs: { headers: _vm.headers, items: _vm.users },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.picture",
                      fn: function ({ item }) {
                        return [
                          _c("UserAvatar", {
                            attrs: { userId: item.id, size: "35" },
                          }),
                        ]
                      },
                    },
                    {
                      key: "item.state",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color: item.state ? "success" : "warning",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(item.state ? "fa-check" : "fa-times") +
                                  " fa-fw "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.lastLogin",
                      fn: function ({ item }) {
                        return [
                          item.lastLogin
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .dayjs(item.lastLogin)
                                        .format("DD/MM/YYYY")
                                    ) +
                                    " "
                                ),
                              ]
                            : [_vm._v(" Pas de connexion ")],
                        ]
                      },
                    },
                    {
                      key: "item.role",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.getRoleName(item.role)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.actions",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "primary", small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.takePlace(item)
                                },
                              },
                            },
                            [_vm._v("fa-key fa-fw")]
                          ),
                          _vm.isGranted("USERS_EDIT")
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editUser(item)
                                    },
                                  },
                                },
                                [_vm._v("fa-edit fa-fw")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  103891237
                ),
              }),
              _c(
                "modal",
                {
                  attrs: { "max-width": "1024" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "strong",
                              { staticClass: "text-h5 mb-0 text-uppercase" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.editionUser && _vm.editionUser.id
                                      ? "Édition"
                                      : "Ajout"
                                  ) + " - utilisateur "
                                ),
                              ]
                            ),
                            _c("br"),
                            _c("small", { staticClass: "mt-0" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.editionUser && _vm.editionUser.id
                                    ? _vm.editionUser.firstname +
                                        " " +
                                        _vm.editionUser.lastname
                                    : ""
                                )
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "actions",
                        fn: function () {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  variant: "flat",
                                  color: "primary",
                                  elevation: "0",
                                },
                                on: { click: _vm.editUserSave },
                              },
                              [_vm._v(" Enregistrer ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-4",
                                attrs: { color: "secondary", text: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.editModal = false
                                  },
                                },
                              },
                              [_vm._v(" Fermer ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1207081568
                  ),
                  model: {
                    value: _vm.editModal,
                    callback: function ($$v) {
                      _vm.editModal = $$v
                    },
                    expression: "editModal",
                  },
                },
                [
                  _vm.editionUser
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-hover", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ hover }) {
                                        return [
                                          _vm.editionUser.id
                                            ? _c(
                                                "v-avatar",
                                                {
                                                  staticClass:
                                                    "v-avatar-light-bg primary--text",
                                                  attrs: {
                                                    size: "100px",
                                                    color: "primary",
                                                  },
                                                },
                                                [
                                                  !hover
                                                    ? _c("UserAvatar", {
                                                        attrs: {
                                                          userId:
                                                            _vm.editionUser.id,
                                                          size: "100",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  hover
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-large": "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.openUploadModal = true
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fa-edit fa-fw"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1522943809
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-switch", {
                                attrs: { label: "Actif" },
                                model: {
                                  value: _vm.editionUser.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editionUser, "state", $$v)
                                  },
                                  expression: "editionUser.state",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: { outlined: "", label: "Nom" },
                                model: {
                                  value: _vm.editionUser.lastname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editionUser, "lastname", $$v)
                                  },
                                  expression: "editionUser.lastname",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: { outlined: "", label: "Prénom" },
                                model: {
                                  value: _vm.editionUser.firstname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editionUser, "firstname", $$v)
                                  },
                                  expression: "editionUser.firstname",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: { outlined: "", label: "Email" },
                                model: {
                                  value: _vm.editionUser.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editionUser, "email", $$v)
                                  },
                                  expression: "editionUser.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  "menu-props": { maxHeight: "300" },
                                  label: "Couleur",
                                  items: _vm.colors,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                label: "",
                                                color: item,
                                              },
                                            },
                                            [_vm._v("     ")]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item",
                                      fn: function ({
                                        active,
                                        item,
                                        attrs,
                                        on,
                                      }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                label: "",
                                                                color: item,
                                                              },
                                                            },
                                                            [_vm._v("     ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1324972624
                                ),
                                model: {
                                  value: _vm.editionUser.color,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editionUser, "color", $$v)
                                  },
                                  expression: "editionUser.color",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.editionUser.id && false
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: "Mot de passe",
                                      type: "password",
                                    },
                                    model: {
                                      value: _vm.editionUser.password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editionUser,
                                          "password",
                                          $$v
                                        )
                                      },
                                      expression: "editionUser.password",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editionUser.id && false
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: "Confirmation du mot de passe",
                                      type: "password",
                                    },
                                    model: {
                                      value: _vm.editionUser.rePassword,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editionUser,
                                          "rePassword",
                                          $$v
                                        )
                                      },
                                      expression: "editionUser.rePassword",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  "menu-props": { maxHeight: "300" },
                                  items: _vm.roles,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Rôle",
                                },
                                model: {
                                  value: _vm.editionUser.role.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editionUser.role, "id", $$v)
                                  },
                                  expression: "editionUser.role.id",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  "menu-props": { maxHeight: "300" },
                                  items: _vm.services,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Service",
                                },
                                model: {
                                  value: _vm.editionUser.service.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editionUser.service, "id", $$v)
                                  },
                                  expression: "editionUser.service.id",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.editionUser.id && false
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Actif" },
                                    model: {
                                      value: _vm.editionUser.state,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.editionUser, "state", $$v)
                                      },
                                      expression: "editionUser.state",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }