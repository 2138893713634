<template>
  <v-card elevation="1">
    <Landscape />
    <v-toolbar color="primary" dark elevation="0">
      <v-card-title>
        <modal v-model="settingsModal">
          <template v-slot:header>
            <strong class="text-h5 mb-0 text-uppercase">Filtrer</strong>
          </template>
          <v-row>
            <v-col cols="12">
              <v-switch label="Voir toutes les campagnes" v-model="allListing"> </v-switch>
            </v-col>
          </v-row>
        </modal>
        <h2 class="ma-0 text-base">Campagnes</h2>

        <div class="listbox--actions-add">
          <v-btn small fab color="primary" dark @click="$router.push({ path: '/compaign/new' })">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-plus </v-icon>
              </template>
              <span>Ajouter</span>
            </v-tooltip>
          </v-btn>
        </div>
        <div style="position: absolute; top: 0; left: 50%; background: #78909c; padding: 5px; translate: -50% 0; border-radius: 0 0 5px 5px">
          <v-btn text @click="settingsModal = true" small>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-filter </v-icon>
              </template>
              <span>Filtrer</span>
            </v-tooltip>
          </v-btn>
        </div>
      </v-card-title>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="compaigns"
        class="elevation-0"
        :loading="loading"
        :server-items-length="maxItem"
        :options.sync="options"
        @update:page="setPage"
        @update:items-per-page="setItemMax"
        @click:row="openCompaign"
      >
        <template v-slot:body.prepend>
          <tr>
            <td>
              <v-text-field v-model="search.id" label="Filtrer"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="search.name" label="Filtrer"></v-text-field>
            </td>
            <td>
              <v-select multiple v-model="search.types" :items="types" item-text="name" item-value="id" label="Filtrer"></v-select>
            </td>
            <td>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStartFormatted"
                    label="Filtrer"
                    persistent-hint
                    v-bind="attrs"
                    readonly
                    @click:append-outer="
                      search.dateStart = null;
                      dateStartFormatted = null;
                    "
                    :append-outer-icon="search.dateStart === null ? '' : 'fa-times'"
                    @blur="search.dateStart = parseDate(dateStartFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.dateStart" no-title @input="menu1 = false"></v-date-picker>
              </v-menu>
            </td>
            <td>
              <v-menu
                ref="menu1"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEndFormatted"
                    label="Filtrer"
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    @click:append-outer="
                      search.dateEnd = null;
                      dateEndFormatted = null;
                    "
                    :append-outer-icon="search.dateEnd === null ? '' : 'fa-times'"
                    @blur="search.dateEnd = parseDate(dateEndFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.dateEnd" no-title @input="menu2 = false"></v-date-picker>
              </v-menu>
            </td>
            <td>
              <v-select
                :menu-props="{ maxHeight: '300' }"
                label="Filtrer"
                :items="status"
                v-model="search.status"
                item-value="value"
                item-text="name"
                :append-outer-icon="search.status === null ? '' : 'fa-times'"
                @click:append-outer="search.status = null"
              ></v-select>
            </td>
            <td v-if="isGranted('COMPAIGN_COMPANY')">
              <v-select
                v-model="search.typeComp"
                :items="typeComp"
                item-value="value"
                item-text="name"
                label="Filtrer"
                :append-outer-icon="search.typeComp !== null ? 'fa-times' : ''"
                @click:append-outer="search.typeComp = null"
              ></v-select>
            </td>
          </tr>
        </template>

        <template v-slot:item.bu="{ item }">
          <v-icon v-for="bu in item.types" :key="bu.id + '-' + item.id" label small :color="bu.color" class="mr-1 my-1"> {{ bu.icon }} fa-fw </v-icon>
        </template>

        <template v-slot:item.solo="{ item }">
          {{ item.solo ? "Individuelle" : "Entreprise" }}
        </template>

        <template v-slot:item.draft="{ item }">
          <v-chip label small v-if="item.draft" color="#ABABAB" class="mr-1"> Brouillon </v-chip>
          <template v-else>
            <v-chip label small :color="getPhase(item).color">
              {{ getPhase(item).name }}
            </v-chip>
          </template>
        </template>

        <template v-slot:item.startDate="{ item }">
          {{ dayjs(item.startDate).format("DD/MM/YYYY") }}
        </template>

        <template v-slot:item.userId="{ item }">
          <UserAvatar :userId="item.userId" size="35" class="ml-2" />
        </template>

        <template v-slot:item.endDate="{ item }">
          {{ dayjs(item.endDate).format("DD/MM/YYYY") }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-row>
            <v-icon small @click.stop="editItem(item.id)" class="mr-6">fa-fw fa-edit</v-icon>
            <v-icon small color="error" @click.stop="deleteCompaign(item.id)">fa-fw fa-trash-alt</v-icon>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
import simpleDebounce from "simple-debounce-vue-mixin";
import Tools from "../../../mixins/Tools";
import Modal from "../../../components/common/modal";
import UserAvatar from "../../../components/user/UserAvatar";
import Landscape from "../../../components/common/Landscape.vue";

export default {
  name: "listCompaign",
  components: { Modal, UserAvatar, Landscape },
  mixins: [simpleDebounce, Tools],
  data() {
    return {
      allListing: false,
      settingsModal: false,
      loading: true,
      dateStartFormatted: null,
      dateEndFormatted: null,
      menu1: false,
      options: {},
      sortBy: "",
      order: "",
      status: [
        { name: "Publié", value: 0 },
        { name: "Brouillon", value: 1 },
      ],
      menu2: false,
      typeComp: [],
      headers: [
        { text: "# ", value: "id" },
        { text: "Nom", value: "name" },
        { text: "Métiers Concernés", sortable: false, value: "bu" },
        { text: "Date de début", sortable: false, value: "startDate" },
        { text: "Date de fin", sortable: false, value: "endDate" },
        { text: "Statut", sortable: false, value: "draft" },
        { text: "Type", sortable: false, value: "solo" },
        { text: "Responsable", sortable: false, value: "userId" },
        { text: "Actions", sortable: false, value: "actions" },
      ],
      types: [],
      compaigns: [],
      page: 1,
      limit: 10,
      maxItem: 0,
      search: {
        id: null,
        name: null,
        types: null,
        dateStart: null,
        dateEnd: null,
        status: null,
        typeComp: null,
      },
    };
  },
  mounted() {
    this.initTypeComp();
    let search = sessionStorage.getItem("searchCompaign");
    if (search) {
      this.search = JSON.parse(search);
    }
    this.getCompaigns();
    this.getTypes();
  },
  beforeDestroy() {
    sessionStorage.setItem("searchCompaign", JSON.stringify(this.search));
  },
  methods: {
    initTypeComp() {
      this.typeComp = [
        { value: 0, name: "Entreprise" },
        { value: 1, name: "Individuelle" },
      ];
    },
    editItem(id) {
      this.$router.push({ name: "Campagne Edition", params: { id: id } });
    },
    openCompaign(row) {
      if (row.draft) {
        this.$router.push({ name: "Campagne Edition", params: { id: row.id } });
      } else {
        this.$router.push({ name: "Campagne", params: { id: row.id } });
      }
    },
    async deleteCompaign(id) {
      let res = await this.$confirm("Êtes-vous sûr de vouloir supprimer cette campagne ?");
      if (res) {
        this.$axios.delete("/api/compaign/" + id).then(() => {
          this.getCompaigns();
          this.$simpleToast.success("Campagne", "L'élément a bien été supprimé");
        });
      }
    },
    setItemMax(max) {
      this.limit = max;
    },
    setPage(page) {
      this.page = page;
    },
    getTypes() {
      this.$axios("/api/types").then((resp) => {
        this.types = resp.data.types;
      });
    },
    getCompaigns() {
      this.loading = true;
      let params = { page: this.page, limit: this.limit, search: this.search, allListing: this.allListing };
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.order = this.order;
      }
      this.$axios("/api/compaign", {
        params,
      }).then((resp) => {
        this.maxItem = resp.data.max;
        this.compaigns = resp.data.data;
        this.loading = false;
      });
    },
    getCompaignsDebounce() {
      this.simpleDebounce(() => {
        this.getCompaigns();
      }, 500);
    },
  },
  watch: {
    options(value) {
      this.sortBy = value.sortBy[0];
      this.order = value.sortDesc[0] ? "desc" : "asc";
      this.getCompaignsDebounce();
    },
    limit() {
      this.getCompaignsDebounce();
    },
    page() {
      this.getCompaignsDebounce();
    },
    "search.typeComp"() {
      this.getCompaignsDebounce();
    },
    "search.id"() {
      this.getCompaignsDebounce();
    },
    "search.name"() {
      this.getCompaignsDebounce();
    },
    "search.status"() {
      this.getCompaignsDebounce();
    },
    "search.types"() {
      this.getCompaignsDebounce();
    },
    "search.dateStart"() {
      this.dateStartFormatted = this.search.dateStart ? dayjs(this.search.dateStart, "YYYY-MM-DD").format("DD/MM/YYYY") : null;
      this.getCompaignsDebounce();
    },
    allListing() {
      this.getCompaignsDebounce();
    },
    "search.dateEnd"() {
      this.dateEndFormatted = this.search.dateEnd ? dayjs(this.search.dateEnd, "YYYY-MM-DD").format("DD/MM/YYYY") : null;
      this.getCompaignsDebounce();
    },
    search() {
      this.getCompaignsDebounce();
    },
  },
};
</script>

<style scoped></style>
