var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { "max-width": "900px" },
      scopedSlots: _vm._u(
        [
          _vm.emails.length
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { cols: "9" } }, [
                          _c("h4", { staticClass: "pa-0 ma-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.emails[_vm.index]?.sender) +
                                " - "
                            ),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "pa-0 ma-0 text-decoration-underline white--text",
                                staticStyle: { "font-style": "italic" },
                                attrs: {
                                  href:
                                    "mailto:" +
                                    _vm.emails[_vm.index]?.senderEmail,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.emails[_vm.index]?.senderEmail)
                                ),
                              ]
                            ),
                          ]),
                          _c("h3", { staticClass: "pa-0 ma-0" }, [
                            _vm._v(_vm._s(_vm.emails[_vm.index]?.name)),
                          ]),
                        ]),
                        _c("v-col", { attrs: { cols: "2 d-flex align-end" } }, [
                          _c("span", { staticClass: "pa-0 ma-0" }, [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .dayjs(_vm.emails[_vm.index]?.linkedAt)
                                  .format("DD/MM/YYYY HH:mm")
                              )
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.toggleModal,
        callback: function ($$v) {
          _vm.toggleModal = $$v
        },
        expression: "toggleModal",
      },
    },
    [
      _vm.emails.length
        ? _c(
            "v-carousel",
            {
              ref: "carousel",
              attrs: {
                height: "auto",
                "hide-delimiters": "",
                "progress-color": "primary",
              },
              on: { change: _vm.setIndex },
              scopedSlots: _vm._u(
                [
                  {
                    key: "prev",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              icon: "",
                              large: "",
                              disabled: _vm.emails.length < 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.carousel.prev()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { large: "" } }, [
                              _vm._v("mdi-chevron-left"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "next",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              icon: "",
                              large: "",
                              disabled: _vm.emails.length < 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.carousel.next()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { large: "" } }, [
                              _vm._v("mdi-chevron-right"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1116035922
              ),
            },
            _vm._l(_vm.emails, function (email, i) {
              return _c("v-carousel-item", { key: i }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "container-fluid d-flex justify-center align-center",
                  },
                  [
                    _c(
                      "v-card",
                      { staticClass: "pa-2", attrs: { elevation: "0" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "pa-3 ma-0 d-flex justify-center align-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "max-height": "calc(100vh - 260px)",
                                      "overflow-y": "auto",
                                      "overflow-x": "hidden",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "message-box--body text-left",
                                      staticStyle: { width: "600px" },
                                      domProps: {
                                        innerHTML: _vm._s(email.body),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }