import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Settings from "../views/settings/Settings";
import Login from "../views/Login";
import CalendarView from "../views/calendar/CalendarView";
import challengeView from "../views/challenge/challengeView";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import store from "../store/store";
import CustomersContactsList from "../views/extension/CustomersContactsList";
import Map from "../views/map/Map.vue";

import listOpportunity from "../views/opportunities/listOpportunity";
import singleOpportunity from "../views/opportunities/singleOpportunity";

import listCustomers from "../views/customer/listCustomers";
import singleCustomer from "../views/customer/singleCustomer";

import listConcurrents from "../views/concurrent/listConcurrents";

import Infrastructures from "../views/listInfrastructures";
import OppPanel from "../views/OppPanel";
import Inbox from "../views/inbox/Inbox";

import listSubscriptions from "../views/subscriptions/listSubscriptions";

import listContact from "../views/contacts/listContact";

import Affects from "../views/statistics/Affects";
import SingleAffect from "../views/affects/singleAffect";

import statisticsPerso from "../views/statistics/statisticsPerso";
import statisticsService from "../views/statistics/statisticsService";
import statisticsCommercial from "../views/statistics/statisticsCommercial";
import StatisticsRelations from "../views/statistics/statisticsRelations";
import StatisticsAgency from "../views/statistics/statisticsAgency";
import StatisticsCrossSelling from "../views/statistics/statisticsCrossSelling";

import listCompaign from "../views/compaign/list/listCompaign";
import editionCompaign from "../views/compaign/edition/editionCompaign";
import singleCompaign from "../views/compaign/single/singleCompaign";

import listSupervision from "../views/supervision/listSupervision";
import singleSupervision from "../views/supervision/singleSupervision";
import { settingsRoute } from "./settings/settingsRoute";
import { simpleToast } from "../config/simpleToast";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Panneau de supervision d'opportunités",
    component: OppPanel,
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/extension/customers",
    name: "CustomersExtension",
    component: CustomersContactsList,
    meta: { requiresAuth: true },
  },
  {
    path: "/events",
    name: "Calendrier",
    component: CalendarView,
    meta: { requiresAuth: true, grant: "EVENTS_INDEX" },
  },
  {
    path: "/settings",
    name: "Paramètres",
    component: Settings,
    meta: { requiresAuth: true, grant: "SETTINGS_ALL" },
    children: settingsRoute,
  },
  {
    path: "/opportunities/edit/:id",
    name: "Opportunité",
    component: singleOpportunity,
    meta: { requiresAuth: true, grant: "OPPORTUNITIES_EDIT" },
  },
  {
    path: "/opportunities",
    name: "Opportunités",
    component: listOpportunity,
    meta: { requiresAuth: true, grant: "OPPORTUNITIES_INDEX" },
  },
  {
    path: "/customers",
    name: "Clients",
    component: listCustomers,
    meta: { requiresAuth: true, grant: "CUSTOMERS_INDEX" },
  },
  {
    path: "/concurrents",
    name: "Concurrents",
    component: listConcurrents,
    meta: { requiresAuth: true, grant: "CUSTOMERS_INDEX" },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: listContact,
    meta: { requiresAuth: true, grant: "CONTACTS_INDEX" },
  },
  {
    path: "/affectations",
    name: "Affectations",
    component: Affects,
    meta: { requiresAuth: true, grant: "SECTOR_STATISTICS" },
  },
  {
    path: "/affectations/:id",
    name: "Affectation",
    component: SingleAffect,
    meta: { requiresAuth: true },
  },
  {
    path: "/cross-selling",
    name: "Cross-Selling",
    component: StatisticsCrossSelling,
    meta: { requiresAuth: true, grant: "OPPORTUNITIES_STATISTICSCROSSSELLING" },
  },
  {
    path: "/relations",
    name: "Relations",
    component: StatisticsRelations,
    meta: { requiresAuth: true },
  },
  {
    path: "/infrastructures",
    name: "Infrastuctures",
    component: Infrastructures,
    meta: { requiresAuth: true },
  },
  {
    path: "/customer/:id",
    name: "Client",
    component: singleCustomer,
    meta: { requiresAuth: true, grant: "CONTACTS_INDEX" },
  },
  {
    path: "/subscriptions",
    name: "Abonnements",
    component: listSubscriptions,
    meta: { requiresAuth: true },
  },
  {
    path: "/inbox",
    name: "E-mail",
    component: Inbox,
    meta: { requiresAuth: true },
  },
  {
    path: "/compaign",
    name: "Campagnes",
    component: listCompaign,
    meta: { requiresAuth: true, grant: "COMPAIGN_LIST" },
  },
  {
    path: "/compaign/new",
    name: "Nouvelle campagne",
    component: editionCompaign,
    meta: { requiresAuth: true, grant: "COMPAIGN_LIST" },
  } /*
  {
    path: '/compaign/single/new',
    name: 'Nouvelle campagne',
    component: editionCompaign
  },*/,
  {
    path: "/compaign/:id",
    name: "Campagne",
    component: singleCompaign,
    meta: { requiresAuth: true, grant: "COMPAIGN_LIST" },
  },
  {
    path: "/swdevices",
    name: "Liste supervision",
    component: listSupervision,
    meta: { requiresAuth: true, grant: "SWDEVICES_INDEX" },
  },
  {
    path: "/swdevices/:id",
    name: "Supervision",
    component: singleSupervision,
    meta: { requiresAuth: true, grant: "SWDEVICES_INDEX" },
  },
  {
    path: "/compaign/:id/edit",
    name: "Campagne Edition",
    component: editionCompaign,
    meta: { requiresAuth: true, grant: "COMPAIGN_LIST" },
  },
  {
    path: "/statistics",
    name: "Statistiques Personnelles",
    component: statisticsPerso,
    meta: { requiresAuth: true, grant: "OPPORTUNITIES_STATISTICSPERS" },
  },
  {
    path: "/statistics/service",
    name: "Statistiques BU",
    component: statisticsService,
    meta: { requiresAuth: true, grant: "OPPORTUNITIES_STATISTICSPERS" },
  },
  {
    path: "/statistics/agency",
    name: "Statistiques Agence",
    component: StatisticsAgency,
    meta: { requiresAuth: true, grant: "OPPORTUNITIES_STATISTICSAGENCY" },
  },
  {
    path: "/statistics/commercial",
    name: "Statistiques Commerciaux",
    component: statisticsCommercial,
    meta: { requiresAuth: true, grant: "STATS_COM" },
  },
  {
    path: "/challenges/view",
    name: "Challenges",
    component: challengeView,
    meta: { requiresAuth: true, grant: "CHALLENGES_INDEX" },
  },
  {
    path: "/map",
    name: "Visualisation des clients et secteurs",
    component: Map,
    meta: { requiresAuth: true, grant: "SECTOR_INDEX" },
  },
  {
    path: "/:pageNotFound(.*)",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

function isGranted(role) {
  let str = "ROLE_" + role;
  if (Object.keys(store.state.user).length === 0 || !store.state.user.roles || store.state.user.roles.length === 0) {
    return false;
  }
  return Object.keys(store.state.user).length > 0 ? store.state.user.roles.includes(str) : false;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      if (to.meta && to.meta.grant && !isGranted(to.meta.grant)) {
        simpleToast.error("ACCÈS NON-AUTORISÉ", "Contactez votre administrateur pour ouvrir ce droit.");
      } else {
        next();
        return;
      }
    } else if (to.name != "Login") {
      next({ name: "Login" });
    }
  } else {
    next();
  }
});

export default router;
