var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticStyle: { "z-index": "99" }, attrs: { elevation: "1" } },
        [
          _c(
            "v-toolbar",
            {
              attrs: {
                color: "primary",
                dark: "",
                elevation: "0",
                height: "90",
                "min-width": "100%",
              },
            },
            [
              !_vm.small
                ? _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-1 align-middle" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-left align-self-center d-none d-lg-flex",
                              attrs: { cols: "3", lg: "2" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text-overline text-h6",
                                  staticStyle: {
                                    "font-size": "0.9rem !important",
                                  },
                                },
                                [_vm._v(" Calendrier")]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-center text-subtitle-2 align-self-center",
                              attrs: { cols: "12", lg: "8" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: { icon: "", large: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.calendar.prev()
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                                1
                              ),
                              _vm.typeSelected === "week"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "text-overline text-h6",
                                      staticStyle: {
                                        "font-size": "0.9rem !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Semaine du " +
                                          _vm._s(
                                            _vm
                                              .dayjs(_vm.startDate)
                                              .format("DD MMMM YYYY")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.typeSelected === "month"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "text-overline",
                                      staticStyle: {
                                        "font-size": "0.9rem !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm
                                              .dayjs(_vm.startDate)
                                              .format("MMMM YYYY")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.typeSelected === "day"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "text-overline",
                                      staticStyle: {
                                        "font-size": "0.9rem !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm
                                              .dayjs(_vm.startDate)
                                              .format("DD MMMM YYYY")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: { icon: "", large: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.calendar.next()
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-right align-self-center d-none d-lg-flex",
                              attrs: { cols: "3", lg: "2" },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-select", {
                                    staticClass: "mx-2",
                                    attrs: {
                                      items: _vm.types,
                                      "item-text": "name",
                                      "item-value": "value",
                                      "hide-details": "",
                                      outlined: "",
                                      dense: "",
                                      label: "Affichage",
                                    },
                                    model: {
                                      value: _vm.typeSelected,
                                      callback: function ($$v) {
                                        _vm.typeSelected = $$v
                                      },
                                      expression: "typeSelected",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "listbox--actions-add" },
                _vm._l(_vm.eventTypeArray, function (item, key) {
                  return _c(
                    "v-tooltip",
                    {
                      key: key,
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          small: "",
                                          fab: "",
                                          color: item.color,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.newEventFct()
                                            _vm.newEvent.eventtypeId = item.id
                                            _vm.newEvent.teams = item.teams
                                            _vm.newEvent.ewsToggle =
                                              item.ewsCalendar
                                            _vm.newEvent.body = item.defaultText
                                            _vm.newEvent.duration =
                                              item.duration
                                          },
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v(
                                        " " + _vm._s(item.icon) + " fa-fw "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(item.name))])]
                  )
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "modal-menu--actions" },
                [
                  _vm.isGranted("EVENTS_INDEX_ALL")
                    ? _c(
                        "v-btn",
                        {
                          attrs: { text: "", small: "" },
                          on: {
                            click: function ($event) {
                              _vm.editUserList = true
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" fa-cog fa-fw "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isGranted("EVENTS_INDEX_ALL")
            ? _c(
                "modal",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "strong",
                              { staticClass: "text-h5 mb-0 text-uppercase" },
                              [_vm._v("Filtre")]
                            ),
                            _c("br"),
                            _c("small", { staticClass: "mt-0" }, [
                              _vm._v("Choix du commercial"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1201106702
                  ),
                  model: {
                    value: _vm.editUserList,
                    callback: function ($$v) {
                      _vm.editUserList = $$v
                    },
                    expression: "editUserList",
                  },
                },
                [_c("listUser", { on: { selectChange: _vm.setAsUser } })],
                1
              )
            : _vm._e(),
          _vm.isGranted("EVENTS_INDEX")
            ? _c(
                "v-sheet",
                [
                  _c("v-calendar", {
                    ref: "calendar",
                    style:
                      this.typeSelected != "month"
                        ? "max-height: calc(100vh - 165px )"
                        : "",
                    attrs: {
                      weekdays: _vm.weekday,
                      type: _vm.typeSelected,
                      events: _vm.events,
                      start: _vm.start,
                      "event-overlap-mode": _vm.mode,
                      "event-overlap-threshold": 30,
                      "event-color": _vm.getEventColor,
                      "first-time": "08:00",
                      "interval-minutes": "30",
                      "interval-count": "22",
                    },
                    on: {
                      "click:event": _vm.showEvent,
                      "click:time": _vm.addEventHere,
                      "click:date": function ($event) {
                        _vm.typeSelected = "day"
                      },
                      change: _vm.getEvents,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "event",
                          fn: function ({ event }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "v-event-draggable",
                                  staticStyle: {
                                    padding: "2px",
                                    "font-size": "0.9em",
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getState(event.init)
                                      ),
                                    },
                                  }),
                                  _c(
                                    "strong",
                                    {
                                      style:
                                        event.init.status != 0
                                          ? "text-decoration: line-through;color:#494949;"
                                          : "color:#494949;",
                                    },
                                    [_vm._v(" " + _vm._s(event.name))]
                                  ),
                                  _vm.typeSelected != "month"
                                    ? _c("br")
                                    : _vm._e(),
                                  _c("span", {
                                    staticStyle: { "min-width": "2em" },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--lighten-4 ms-4",
                                      staticStyle: { "font-size": "0.95em" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dayjs(event.start).format("HH:mm")
                                        ) +
                                          " - " +
                                          _vm._s(
                                            _vm.dayjs(event.end).format("HH:mm")
                                          )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      975034307
                    ),
                    model: {
                      value: _vm.valueCal,
                      callback: function ($$v) {
                        _vm.valueCal = $$v
                      },
                      expression: "valueCal",
                    },
                  }),
                  _vm.selectedEvent && false
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            activator: _vm.selectedElement,
                            "offset-x": "",
                          },
                          model: {
                            value: _vm.selectedOpen,
                            callback: function ($$v) {
                              _vm.selectedOpen = $$v
                            },
                            expression: "selectedOpen",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                color: "grey lighten-4",
                                "min-width": "350px",
                                flat: "",
                              },
                            },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    color: _vm.selectedEvent.color,
                                    dark: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditModal()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("fa-pen"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: { click: _vm.removeEvent },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("fa-trash"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("v-toolbar-title", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.selectedEvent.name),
                                    },
                                  }),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                              _vm.selectedEvent && _vm.selectedEvent.init
                                ? _c("v-card-text", [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.selectedEvent.init.body
                                        ),
                                      },
                                    }),
                                    _vm.selectedEvent &&
                                    _vm.globalResp &&
                                    _vm.globalResp.entreprise[
                                      _vm.selectedEvent.init.id
                                    ]
                                      ? _c("div", [
                                          _c("span", [
                                            _vm._v(
                                              "Client: " +
                                                _vm._s(
                                                  _vm.globalResp.entreprise[
                                                    _vm.selectedEvent.init.id
                                                  ].name
                                                )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          "Début: " +
                                            _vm._s(
                                              _vm
                                                .dayjs(
                                                  _vm.selectedEvent.init.start
                                                )
                                                .format("DD-MM-YYYY à HH:mm")
                                            )
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          "Fin: " +
                                            _vm._s(
                                              _vm
                                                .dayjs(
                                                  _vm.selectedEvent.init.end
                                                )
                                                .format("DD-MM-YYYY à HH:mm")
                                            )
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        color: _vm.selectedEvent.color,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.selectedOpen = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Fermer ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "modal",
            {
              attrs: { "max-width": "1024" },
              scopedSlots: _vm._u(
                [
                  _vm.newEvent && _vm.newEvent.customerId
                    ? {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "strong",
                              { staticClass: "text-h5 mb-0 text-uppercase" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.newEvent && _vm.newEvent.id
                                      ? "Édition"
                                      : "Création"
                                  ) + " Evènement"
                                ),
                              ]
                            ),
                            _c("br"),
                            _c(
                              "small",
                              { staticClass: "mt-0" },
                              [
                                _vm.newEvent
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm
                                              .dayjs(_vm.newEvent.date)
                                              .format("DD/MM/YYYY")
                                          ) +
                                          " " +
                                          _vm._s(_vm.newEvent.time) +
                                          " - " +
                                          _vm._s(_vm.newEvent.name) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm.newEvent
                              ? [
                                  _vm.newEvent.id
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              top: "0",
                                              left: "50%",
                                              background: "#78909c",
                                              padding: "5px",
                                              translate: "-50% 0",
                                              "border-radius": "0 0 5px 5px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "text-left",
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              elevation:
                                                                                "0",
                                                                              plain:
                                                                                "",
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$router.push(
                                                                                  {
                                                                                    name: "Client",
                                                                                    params:
                                                                                      {
                                                                                        id: _vm
                                                                                          .newEvent
                                                                                          .customerId,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "v-btn",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "fa-search"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1168081894
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Fiche client"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-1",
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              elevation:
                                                                                "0",
                                                                              plain:
                                                                                "",
                                                                              color:
                                                                                "none",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.typeLink.id =
                                                                                  _vm.newEvent.id
                                                                                _vm.linkModal = true
                                                                              },
                                                                          },
                                                                        },
                                                                        "v-btn",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "fa-envelope"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1386472408
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Lier un e-mail"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm.newEvent.opportunityId
                                                      ? [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-1",
                                                                                  attrs:
                                                                                    {
                                                                                      elevation:
                                                                                        "0",
                                                                                      small:
                                                                                        "",
                                                                                      plain:
                                                                                        "",
                                                                                      color:
                                                                                        "warning",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$router.push(
                                                                                          {
                                                                                            name: "Opportunité",
                                                                                            params:
                                                                                              {
                                                                                                id: _vm
                                                                                                  .newEvent
                                                                                                  .opportunityId,
                                                                                              },
                                                                                          }
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "x-small":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fa-handshake"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  303942909
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Opportunité " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .newEvent
                                                                        .opportunityId
                                                                    )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      : [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-1",
                                                                                  attrs:
                                                                                    {
                                                                                      elevation:
                                                                                        "0",
                                                                                      small:
                                                                                        "",
                                                                                      plain:
                                                                                        "",
                                                                                      color:
                                                                                        "warning",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      _vm.newOpp,
                                                                                  },
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "x-small":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fa-plus"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  680333465
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Créer opportunité"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      small:
                                                                                        "",
                                                                                      plain:
                                                                                        "",
                                                                                      elevation:
                                                                                        "0",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.linkOppModal = true
                                                                                      },
                                                                                  },
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "x-small":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fa-link"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  4288396378
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Associer opportunité"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _vm.newEvent && _vm.newEvent.customerId !== null
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  variant: "flat",
                                  color: "primary",
                                  elevation: "0",
                                  loading: _vm.loadingSave,
                                },
                                on: { click: _vm.addEventFct },
                              },
                              [_vm._v(" Enregistrer ")]
                            )
                          : _c(
                              "v-btn",
                              {
                                attrs: {
                                  variant: "flat",
                                  color: "primary",
                                  elevation: "0",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.newEvent.customerId =
                                      _vm.selectedCustomer
                                  },
                                },
                              },
                              [_vm._v(" Suivant ")]
                            ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: { color: "secondary", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.createModal = false
                              },
                            },
                          },
                          [_vm._v(" Fermer ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.createModal,
                callback: function ($$v) {
                  _vm.createModal = $$v
                },
                expression: "createModal",
              },
            },
            [
              _vm.newEvent && _vm.newEvent.customerId !== null
                ? [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        model: {
                          value: _vm.valid,
                          callback: function ($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid",
                        },
                      },
                      [
                        _c("commentFormOpportunities", {
                          model: {
                            value: _vm.newEvent,
                            callback: function ($$v) {
                              _vm.newEvent = $$v
                            },
                            expression: "newEvent",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _vm._v(" Rechercher un compte "),
                            _c("v-autocomplete", {
                              staticStyle: { "z-index": "999" },
                              attrs: {
                                "menu-props": { maxHeight: "300" },
                                items: _vm.customersList,
                                loading: _vm.isLoading,
                                "search-input": _vm.search,
                                "hide-no-data": "",
                                "hide-selected": "",
                                "item-text": "entreprise.name",
                                "item-value": "customer.id",
                                label: "Rechercher un compte",
                                "prepend-icon": "fa-users",
                              },
                              on: {
                                "update:searchInput": function ($event) {
                                  _vm.search = $event
                                },
                                "update:search-input": function ($event) {
                                  _vm.search = $event
                                },
                              },
                              model: {
                                value: _vm.selectedCustomer,
                                callback: function ($$v) {
                                  _vm.selectedCustomer = $$v
                                },
                                expression: "selectedCustomer",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _c(
            "modal",
            {
              attrs: { "max-width": "1024" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "strong",
                        { staticClass: "text-h5 mb-0 text-uppercase" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.newEvent && _vm.newEvent.id
                                ? "Édition"
                                : "Ajout"
                            ) + " Evènement"
                          ),
                        ]
                      ),
                      _c("br"),
                      _c(
                        "small",
                        { staticClass: "mt-0" },
                        [
                          _vm.newEvent
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .dayjs(_vm.newEvent.date)
                                        .format("DD/MM/YYYY")
                                    ) +
                                    " " +
                                    _vm._s(_vm.newEvent.time) +
                                    " - " +
                                    _vm._s(_vm.newEvent.name) +
                                    " "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.newEvent
                        ? [
                            _vm.newEvent.id
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        top: "0",
                                        left: "50%",
                                        background: "#78909c",
                                        padding: "5px",
                                        translate: "-50% 0",
                                        "border-radius": "0 0 5px 5px",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-left",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      elevation:
                                                                        "0",
                                                                      plain: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$router.push(
                                                                            {
                                                                              name: "Client",
                                                                              params:
                                                                                {
                                                                                  id: _vm
                                                                                    .newEvent
                                                                                    .customerId,
                                                                                },
                                                                            }
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fa-search"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1168081894
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Fiche client"),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    attrs: {
                                                                      small: "",
                                                                      elevation:
                                                                        "0",
                                                                      plain: "",
                                                                      color:
                                                                        "none",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.typeLink.id =
                                                                            _vm.newEvent.id
                                                                          _vm.linkModal = true
                                                                        },
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fa-envelope"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1386472408
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Lier un e-mail"),
                                                  ]),
                                                ]
                                              ),
                                              _vm.newEvent.opportunityId
                                                ? [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "mx-1",
                                                                          attrs:
                                                                            {
                                                                              elevation:
                                                                                "0",
                                                                              small:
                                                                                "",
                                                                              plain:
                                                                                "",
                                                                              color:
                                                                                "warning",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$router.push(
                                                                                  {
                                                                                    name: "Opportunité",
                                                                                    params:
                                                                                      {
                                                                                        id: _vm
                                                                                          .newEvent
                                                                                          .opportunityId,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "v-btn",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "fa-handshake"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          303942909
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Opportunité " +
                                                              _vm._s(
                                                                _vm.newEvent
                                                                  .opportunityId
                                                              )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "mx-1",
                                                                          attrs:
                                                                            {
                                                                              elevation:
                                                                                "0",
                                                                              small:
                                                                                "",
                                                                              plain:
                                                                                "",
                                                                              color:
                                                                                "warning",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              _vm.newOpp,
                                                                          },
                                                                        },
                                                                        "v-btn",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "fa-plus"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          680333465
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Créer opportunité"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              plain:
                                                                                "",
                                                                              elevation:
                                                                                "0",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.linkOppModal = true
                                                                              },
                                                                          },
                                                                        },
                                                                        "v-btn",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "fa-link"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          4288396378
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Associer opportunité"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            variant: "flat",
                            color: "primary",
                            elevation: "0",
                            loading: _vm.loadingSave,
                          },
                          on: { click: _vm.editEventFct },
                        },
                        [_vm._v(" Enregistrer ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: { color: "secondary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.editModal = false
                            },
                          },
                        },
                        [_vm._v(" Fermer ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.editModal,
                callback: function ($$v) {
                  _vm.editModal = $$v
                },
                expression: "editModal",
              },
            },
            [
              _c(
                "v-form",
                {
                  ref: "formEdit",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("commentFormOpportunities", {
                    model: {
                      value: _vm.newEvent,
                      callback: function ($$v) {
                        _vm.newEvent = $$v
                      },
                      expression: "newEvent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }