<template :style="isMobile() {marginTop: '50px'} : {}">
  <modal max-width="800" v-model="modalVal">
    <template v-slot:header>
      <strong class="text-h5 mb-0 text-uppercase"> {{ concurrent.name }}</strong>
    </template>

    <v-row class="d-flex justify-center">
      <v-col cols="8" class="text-left">
        <span class="text-button font-weight-medium">Informations</span>
        <v-divider class="mb-2"></v-divider>
        <v-row>
          <v-col cols="6">
            <span class="font-weight-bold">APE :</span>
            {{ concurrent.ape }}
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold">SIRET :</span>
            {{ concurrent.siret }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <span class="font-weight-bold">Adresse :</span>
            {{ concurrent.address }}
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold">Adresse 2 :</span>
            {{ concurrent.address2 }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <span class="font-weight-bold">Code postal :</span>
            {{ concurrent.postalCode }}
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold">Ville :</span>
            {{ concurrent.city }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <span class="font-weight-bold">Email :</span>
            {{ concurrent.email }}
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold">Téléphone :</span>
            {{ concurrent.phone }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <span class="font-weight-bold">Téléphone 2 :</span>
            {{ concurrent.phone2 }}
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold">Site :</span>
            {{ concurrent.site }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="d-flex justify-start align-center">
            <span class="font-weight-bold mr-2">Prestataire :</span>
            <v-icon v-if="concurrent?.prestataire" color="success"> fa-fw fa-check </v-icon>
            <v-icon v-else color="error"> fa-fw fa-times </v-icon>
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold">ID :</span>
            {{ concurrent.id }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" class="">
        <h5 class="text-button font-weight-medium mb-0">Opportunités gagnées</h5>
        <v-divider class="mb-2"></v-divider>
        <template v-if="concurrent?.allInfo?.opportunitiesWin.length > 0">
          <v-chip
            class="mb-2 mr-2"
            color="success"
            v-for="opportunity in concurrent?.allInfo?.opportunitiesWin"
            :key="opportunity.id"
            @click="$router.push({ name: 'Opportunité', params: { id: opportunity.id } })"
          >
            {{ opportunity.id }}
          </v-chip>
        </template>
        <span v-else class="italic">Aucune</span>
        <h5 class="text-button font-weight-medium mb-0">Opportunités en cours</h5>
        <v-divider class="mb-2"></v-divider>
        <template v-if="concurrent?.allInfo?.opportunitiesConcurrentCustomers.length > 0">
          <v-chip
            class="mb-2 mr-2"
            color="primary"
            v-for="opportunity in concurrent?.allInfo?.opportunitiesConcurrentCustomers"
            :key="opportunity.id"
            @click="$router.push({ name: 'Opportunité', params: { id: opportunity.id } })"
          >
            {{ opportunity.id }}
          </v-chip>
        </template>
        <span v-else class="italic">Aucune</span>
        <div class="mt-4">
          <v-btn
            class="text-uppercase font-size-10"
            color="primary"
            elevation="2"
            small
            v-if="isGranted('CUSTOMERS_VIEW') && concurrent?.allInfo?.entreprise?.customers?.id"
            @click="$router.push({ name: 'Client', params: { id: concurrent.allInfo.entreprise.customers.id } })"
            >Accéder au compte
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" v-if="concurrent?.allInfo?.client.length > 0">
        <v-expansion-panels class="elevation-0">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5 class="text-button font-weight-medium mb-0">Liste des clients</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                style="width: 100%"
                dense
                :headers="headers"
                :items="concurrent?.allInfo?.client"
                :items-per-page="10"
                class="elevation-1"
                @click:row="(row) => $router.push({ name: 'Client', params: { id: row.id } })"
              ></v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </modal>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Modal from "../../components/common/modal";
import MobileDetection from "../../mixins/MobileDetection";
import Tools from "../../mixins/Tools";

export default {
  name: "viewConcurrent",
  components: { Modal },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    concurrentData: {
      default: () => {},
      type: Object,
    },
  },
  mixins: [simpleDebounce, MobileDetection, Tools],
  data() {
    return {
      modalVal: this.value,
      concurrent: this.concurrentData,
      headers: [
        { text: "Nom", value: "name" },
        { text: "id", value: "id" },
      ],
    };
  },
  mounted() {},
  methods: {},
  watch: {
    concurrentData() {
      this.concurrent = this.concurrentData;
    },
    value() {
      this.modalVal = this.value;
    },
    modalVal() {
      this.$emit("input", this.modalVal);
    },
  },
};
</script>

<style scoped></style>
