<template>
  <div class="d-flex align-center">
    <v-avatar :size="size" :left="left" :right="right" :color="color">
      <img :src="cachedAvatarUrl" />
    </v-avatar>
    <span v-if="fullname" class="ml-2">{{ fullname }}</span>
  </div>
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    userId: {
      type: Number,
      default: null,
    },
    fullname: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "35",
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      avatarUrl: "/images/users/nopic.png",
      cacheKey: `userAvatar_${this.userId}`,
    };
  },
  computed: {
    cachedAvatarUrl() {
      const cachedUrl = localStorage.getItem(this.cacheKey);
      return cachedUrl || this.avatarUrl;
    },
  },
  mounted() {
    this.getAvatar(this.userId);
  },
  methods: {
    getAvatar(userId) {
      const cachedUrl = localStorage.getItem(this.cacheKey);

      if (cachedUrl) {
        this.avatarUrl = cachedUrl;
      } else {
        if ((userId && typeof userId === "number") || typeof userId === "string") {
          this.$axios
            .get(`/api/users/picture/user/${userId}`)
            .then((response) => {
              this.avatarUrl = response.data;
              localStorage.setItem(this.cacheKey, this.avatarUrl);
            })
            .catch(() => {
              this.avatarUrl = "/images/users/nopic.png";
            });
        } else {
          this.avatarUrl = "/images/users/nopic.png";
        }
      }
    },
  },
  watch: {
    userId() {
      if (typeof this.userId === "object") {
        this.userId = null;
      }
      this.getAvatar(this.userId);
      this.cacheKey = `userAvatar_${this.userId}`;
    },
  },
};
</script>

<style scoped></style>
