<template>
  <material-card icon="fa-info" title="Informations" class="mt-8">
    <v-container v-if="compaign">
      <v-row>
        <v-col cols="12" lg="6">
          <div><span style="font-weight: bold">Titre : </span> {{ compaign.name }}</div>
          <div><span style="font-weight: bold">Date de début : </span> {{ dayjs(compaign.startDate).format("DD/MM/YYYY") }}</div>
          <div><span style="font-weight: bold">Date de fin : </span> {{ dayjs(compaign.endDate).format("DD/MM/YYYY") }}</div>
          <div>
            <span style="font-weight: bold">Métiers ciblés : </span>
            <v-chip class="mr-1" outlined v-for="(item, i) in compaign.types" :color="item.color" :key="i" label small>
              <v-icon x-small class="mr-2">fa-fw {{ item.icon }}</v-icon> {{ item.name }}</v-chip
            >
          </div>
        </v-col>
        <v-col cols="12" lg="6">
          <div>
            <span style="font-weight: bold">Description : </span>
          </div>
          <p v-html="compaign.body" class="text-caption"></p>
        </v-col>
      </v-row>
    </v-container>
  </material-card>
</template>

<script>
import MaterialCard from "../../../../components/common/MaterialCard";
import Tools from "../../../../mixins/Tools";

export default {
  name: "infoCompaign",
  props: ["value"],
  components: { MaterialCard },
  mixins: [Tools],
  data() {
    return {
      compaign: this.value,
    };
  },
  watch: {
    value() {
      this.compaign = this.value;
    },
  },
};
</script>

<style scoped></style>
