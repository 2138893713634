<template>
  <material-card class="mt-8" title="Phase" icon="fas fa-step-forward" id="phases">
    <template v-slot:heading v-if="false">
      <v-tabs v-model="tabs" background-color="transparent" slider-color="white">
        <v-tab> <v-switch v-model="item.phases[1].enable" @click.stop=""></v-switch> Phase 1 : Mailing </v-tab>
        <v-tab> <v-switch v-model="item.phases[2].enable" @click.stop=""></v-switch> Phase 2 : Phoning </v-tab>
        <v-tab> <v-switch v-model="item.phases[3].enable" @click.stop=""></v-switch> Phase 3 : Rencontre </v-tab>
        <v-tab> <v-switch v-model="item.phases[4].enable" @click.stop=""></v-switch> Phase 4 : Génération business </v-tab>
      </v-tabs>
    </template>

    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step @click="e1 = 1" v-if="item.phases[1].enable" :complete="e1 > 1 && item.phases[1].enable" step="1" style="cursor: pointer">
          Mailing
        </v-stepper-step>

        <v-divider v-if="item.phases[2].enable"></v-divider>

        <v-stepper-step @click="e1 = 2" style="cursor: pointer" v-if="item.phases[2].enable" :complete="e1 > 2 && item.phases[2].enable" step="2">
          Phoning
        </v-stepper-step>

        <v-divider v-if="item.phases[3].enable"></v-divider>

        <v-stepper-step style="cursor: pointer" @click="e1 = 3" v-if="item.phases[3].enable" :complete="e1 > 3 && item.phases[3].enable" step="3">
          Rencontre
        </v-stepper-step>
        <v-divider v-if="item.phases[4].enable"></v-divider>
        <v-stepper-step style="cursor: pointer" @click="e1 = 4" v-if="item.phases[4].enable" :complete="e1 > 4 && item.phases[4].enable" step="4">
          Génération business
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-container fluid>
            <div class="text-center">
              <h4>
                Phase 1 : Mailing
              </h4>
            </div>

            <v-expand-transition>
              <div>
                <div class="text-center" v-if="item.phases[1].emailing">Template Mailjet : {{ item.phases[1].templateName }}</div>
                <v-container fluid>
                  <v-row>
                    <v-col :offset="!item.phases[1].emailing ? 4 : 3" v-show="item.phases[1].enable" class="text-center py-0" :cols="1">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="phase1Formatted"
                            label="Date envoi"
                            persistent-hint
                            v-bind="attrs"
                            :disabled="checkDisable(1) && !item.draft"
                            @blur="phase1Start = parseDate(phase1Formatted)"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          first-day-of-week="1"
                          v-model="phase1Start"
                          no-title
                          :min="item.startDate"
                          @input="menu1 = false"
                          :max="item.endDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="1" class="py-0" v-if="item.phases[1].emailing">
                      <time-picker :disabled="checkDisable(1) && !item.draft" v-model="item.phases[1].timeStart" label="Heure d'envoie" />
                    </v-col>
                    <v-col v-show="item.phases[1].enable" cols="1" class="text-center py-0" v-if="$store.state.settings.mailjet">
                      <v-switch label="Mailjet" :disabled="checkDisable(1) && !item.draft" v-model="item.phases[1].emailing"></v-switch>
                    </v-col>
                    <v-col v-show="item.phases[1].enable" cols="5" class="py-0">
                      <v-expand-x-transition>
                        <v-row v-show="item.phases[1].emailing">
                          <v-col cols="3" v-if="item.phases[1].emailing">
                            <v-text-field
                              :disabled="checkDisable(1) && !item.draft"
                              label="ID Template"
                              :error="!validTemplate"
                              v-model="item.phases[1].mailjet"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6" v-if="item.phases[1].emailing && false">
                            <v-text-field v-model="item.phases[1].subject" label="Sujet du mail"></v-text-field>
                          </v-col>

                          <v-col cols="3" v-if="item.phases[1].emailing">
                            <v-switch label="Filtrer " v-model="item.phases[1].filter"></v-switch>
                          </v-col>
                        </v-row>
                      </v-expand-x-transition>
                    </v-col>

                    <v-col v-show="item.phases[1].enable" :cols="5" offset="5" class="py-0">
                      <v-expand-x-transition>
                        <v-row v-show="item.phases[1].filter && item.phases[1].emailing">
                          <v-col cols="3" class="py-0">
                            <v-switch label="Mail ouvert" :disabled="checkDisable(1) && !item.draft" v-model="item.phases[1].openMail"></v-switch>
                          </v-col>
                          <v-col cols="3" class="py-0">
                            <v-switch label="Mail clic" :disabled="checkDisable(1) && !item.draft" v-model="item.phases[1].clicMail"></v-switch>
                          </v-col>
                        </v-row>
                      </v-expand-x-transition>
                    </v-col>
                    <v-col cols="12" class="text-center py-0">
                      <v-alert border="bottom" colored-border type="warning" elevation="2">
                        Attention : Veillez à bien choisir la date et l'heure de l'envoi. Une fois celle-ci dépassée il sera impossible de la modifier et de
                        renvoyer la campagne.
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-expand-transition>
          </v-container>

          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn color="primary" @click="continueWindow(e1)">
                Continuer
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-container fluid>
            <div class="text-center">
              <h4>
                Phase 2 : Phoning
              </h4>
            </div>
            <v-expand-transition>
              <div>
                <v-row>
                  <v-col class="py-2" offset="5" v-show="item.phases[2].enable" cols="2">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="phase2StartFormatted"
                          label="Date"
                          persistent-hint
                          v-bind="attrs"
                          :disabled="checkDisable(2) && !item.draft"
                          @blur="phase2Start = parseDate(phase2StartFormatted)"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        first-day-of-week="1"
                        v-model="phase2Start"
                        :min="item.startDate"
                        :max="item.endDate"
                        no-title
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-container>

          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn text @click="goBack(e1)">
                Retour
              </v-btn>
              <v-btn color="primary" @click="continueWindow(e1)">
                Continuer
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-container fluid>
            <div class="text-center">
              <h4>
                Phase 3 : Rencontre
              </h4>
            </div>
            <v-expand-transition>
              <div>
                <v-row>
                  <v-col class="py-0" v-show="item.phases[3].enable" offset="1" cols="2">
                    <v-menu
                      ref="menu5"
                      v-model="menu5"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="phase3Formatted"
                          label="Date"
                          persistent-hint
                          v-bind="attrs"
                          :disabled="checkDisable(3) && !item.draft"
                          @blur="phase3Start = parseDate(phase3Formatted)"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        first-day-of-week="1"
                        v-model="phase3Start"
                        :min="item.phases[2].enable ? item.phases[2].startDate : item.startDate"
                        :max="item.endDate"
                        no-title
                        @input="menu5 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="py-0" v-show="item.phases[3].enable" cols="1">
                    <time-picker v-model="item.phases[3].timeStart"></time-picker>
                  </v-col>
                  <v-col class="py-0" v-show="item.phases[3].enable" cols="2">
                    <v-select :menu-props="{ maxHeight: '300' }" :items="typePhase3" v-model="item.phases[3].type" label="Type"></v-select>
                  </v-col>
                  <v-col class="py-0" v-show="item.phases[3].enable" cols="2">
                    <v-text-field label="Lieu" v-model="item.phases[3].place"></v-text-field>
                  </v-col>
                  <v-col class="py-0" v-show="item.phases[3].enable" cols="2">
                    <v-text-field label="Lien (facultatif)" v-model="item.phases[3].url"></v-text-field>
                  </v-col>
                  <v-col class="py-0" v-show="item.phases[3].enable" cols="1">
                    <v-text-field label="Durée (minutes)" v-model="item.phases[3].duration" type="number"></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-container>

          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn text @click="goBack(e1)">
                Retour
              </v-btn>
              <v-btn color="primary" @click="continueWindow(e1)">
                Continuer
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-container fluid>
            <div class="text-center">
              <h4>
                Phase 4 : Génération business
              </h4>
            </div>
            <v-expand-transition>
              <div>
                <v-row>
                  <v-col class="text-center py-4" cols="4" offset-md="4">
                    La phase est active
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-container>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn text @click="goBack(e1)">
                Retour
              </v-btn>
              <v-btn color="primary" @click="continueWindow(e1)">
                Continuer
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-tabs-items v-model="tabs" v-if="false">
      <v-tab-item>
        <v-container fluid>
          <div class="text-center">
            <h4>
              Annonce (mailing ou e-mailing)
            </h4>
          </div>

          <v-expand-transition>
            <div v-show="item.phases[1].enable">
              <v-container fluid>
                <v-row>
                  <v-col
                    :offset="!item.phases[1].emailing ? 4 : item.phases[1].filter ? 1 : 3"
                    class="text-center py-0"
                    :cols="!item.phases[1].emailing ? 2 : 1"
                  >
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="phase1Formatted"
                          label="Date envoi"
                          persistent-hint
                          v-bind="attrs"
                          @blur="phase1Start = parseDate(phase1Formatted)"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker first-day-of-week="1" v-model="phase1Start" no-title @input="menu1 = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="1" class="text-center py-0">
                    <v-switch :label="item.phases[1].emailing ? 'E-mailing' : 'Mailing'" v-model="item.phases[1].emailing"></v-switch>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-expand-x-transition>
                      <v-row v-show="item.phases[1].emailing && $store.state.settings.mailjet">
                        <v-col cols="3" v-if="item.phases[1].emailing && $store.state.settings.mailjet">
                          <v-text-field label="ID Template" v-model="item.phases[1].mailjet"></v-text-field>
                        </v-col>

                        <v-col cols="9" v-if="$store.state.settings.mailjet && item.phases[1].emailing">
                          <v-switch label="Filtrer la cible pour la phase 2" v-model="item.phases[1].filter"></v-switch>
                        </v-col>
                      </v-row>
                    </v-expand-x-transition>
                  </v-col>

                  <v-col :cols="item.phases[1].filter && item.phases[1].emailing ? 5 : 1">
                    <v-expand-x-transition>
                      <v-row v-show="item.phases[1].filter && item.phases[1].emailing">
                        <v-col cols="3" class="py-0">
                          <v-menu
                            ref="menu4"
                            v-model="menu4"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="phase1EndFormatted"
                                label="Date fin"
                                persistent-hint
                                v-bind="attrs"
                                @blur="phase1End = parseDate(phase1EndFormatted)"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker first-day-of-week="1" v-model="phase1End" no-title @input="menu4 = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="3" class="py-0">
                          <v-switch label="Mail délivré" v-model="item.phases[1].deliveredMail"></v-switch>
                        </v-col>
                        <v-col cols="3" class="py-0">
                          <v-switch label="Mail ouvert" v-model="item.phases[1].openMail"></v-switch>
                        </v-col>
                        <v-col cols="3" class="py-0">
                          <v-switch label="Mail clic" v-model="item.phases[1].clicMail"></v-switch>
                        </v-col>
                      </v-row>
                    </v-expand-x-transition>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-expand-transition>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <div class="text-center">
            <h4>
              Rappel d'annonce d'un événement
            </h4>
          </div>
          <v-expand-transition>
            <div v-show="item.phases[2].enable">
              <v-row>
                <v-col offset="4" class="py-2" cols="2">
                  <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="phase2StartFormatted"
                        label="Début"
                        persistent-hint
                        v-bind="attrs"
                        @blur="phase2Start = parseDate(phase2StartFormatted)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker first-day-of-week="1" v-model="phase2Start" no-title @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="py-2" cols="2">
                  <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="phase2EndFormatted"
                        label="Fin"
                        persistent-hint
                        v-bind="attrs"
                        @blur="phase2End = parseDate(phase2EndFormatted)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker first-day-of-week="1" v-model="phase2End" no-title @input="menu3 = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <div class="text-center">
            <h4>
              Rencontre
            </h4>
          </div>
          <v-expand-transition>
            <div v-show="item.phases[3].enable">
              <v-row>
                <v-col offset="1" cols="2">
                  <v-menu ref="menu5" v-model="menu5" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="phase3Formatted"
                        label="Date"
                        persistent-hint
                        v-bind="attrs"
                        @blur="phase3Start = parseDate(phase3Formatted)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker first-day-of-week="1" v-model="phase3Start" no-title @input="menu5 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="1">
                  <time-picker v-model="item.phases[3].timeStart"></time-picker>
                </v-col>
                <v-col cols="2">
                  <v-select :menu-props="{ maxHeight: '300' }" :items="typePhase3" v-model="item.phases[3].type" label="Type"></v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field label="Lieu" v-model="item.phases[3].place"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field label="Lien (facultatif)" v-model="item.phases[3].url"></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Durée (minutes)" v-model="item.phases[3].duration" type="number"></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <div class="text-center">
            <h4>
              Génération business
            </h4>
          </div>
          <v-expand-transition>
            <div v-show="item.phases[4].enable">
              <v-row>
                <v-col offset="5" class="py-2" cols="2">
                  <v-menu ref="menu6" v-model="menu6" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="phase4Formatted"
                        label="Début"
                        persistent-hint
                        v-bind="attrs"
                        @blur="phase4Start = parseDate(phase4Formatted)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker first-day-of-week="1" v-model="phase4Start" no-title @input="menu6 = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </material-card>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";

import MaterialCard from "../../../../components/common/MaterialCard";
import TimePicker from "../../../../components/common/timePicker";
import Tools from "../../../../mixins/Tools";

export default {
  name: "phaseFormCompaign",
  components: { TimePicker, MaterialCard },
  mixins: [simpleDebounce, Tools],
  props: ["value"],
  data() {
    return {
      validTemplate: true,
      item: this.value,
      tabs: 0,
      e1: 1,
      menu1: false,
      menu2: false,
      templateName: "",
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      phase4Start: null,
      phase4Formatted: null,
      phase3Start: null,
      phase3Formatted: null,
      phase1Start: null,
      phase1Formatted: null,
      phase1End: null,
      phase1EndFormatted: null,
      phase2Start: null,
      phase2End: null,
      phase2StartFormatted: null,
      phase2EndFormatted: null,
      typePhase3: ["Séminaire", "Webinar", "Démo"],
    };
  },
  mounted() {
    this.phase1Start = this.item.phases[1].startDate;
    this.phase2Start = this.item.phases[2].startDate;
    this.phase2End = this.item.phases[2].endDate;
    this.phase3Start = this.item.phases[3].startDate;
    this.phase4Start = this.item.phases[4].startDate;
  },
  methods: {
    checkDisable(phase) {
      let today = Date.now();

      if (this.item.id) {
        if (phase === 1 && this.item.phases[1].enable) {
          let date1 = Date.parse(this.item.phases[1].startDate + " " + this.item.phases[1].timeStart);
          if (today > date1) {
            return true;
          }
        }
        if (phase === 2 && this.item.phases[2].enable) {
          let date1 = Date.parse(this.item.phases[2].startDate);
          if (today > date1) {
            return true;
          }
        }
        if (phase === 2 && this.item.phases[3].enable) {
          let date1 = Date.parse(this.item.phases[3].startDate);
          if (today > date1) {
            return true;
          }
        }
        if (phase === 4 && this.item.phases[4].enable) {
          let date1 = Date.parse(this.item.phases[4].startDate);
          if (today > date1) {
            return true;
          }
        }
      }

      return false;
    },
    goBack(val) {
      if (val === 2) {
        if (this.item.phases[1].enable) {
          this.e1 = 1;
        } else {
          document.getElementById("infos").scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      if (val === 3) {
        if (this.item.phases[2].enable) {
          this.e1 = 1;
        } else if (this.item.phases[1].enable) {
          this.e1 = 2;
        } else {
          document.getElementById("infos").scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      if (val === 4) {
        if (this.item.phases[3].enable) {
          this.e1 = 3;
        } else if (this.item.phases[2].enable) {
          this.e1 = 2;
        } else if (this.item.phases[1].enable) {
          this.e1 = 1;
        } else {
          document.getElementById("infos").scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      if (val === 1) {
        document.getElementById("infos").scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    continueWindow(val) {
      if (val === 1) {
        if (this.item.phases[2].enable) {
          this.e1 = 2;
        } else if (this.item.phases[3].enable) {
          this.e1 = 3;
        } else if (this.item.phases[4].enable) {
          this.e1 = 4;
        } else {
          document.getElementById("customer").scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      if (val === 2) {
        if (this.item.phases[3].enable) {
          this.e1 = 3;
        } else if (this.item.phases[4].enable) {
          this.e1 = 4;
        } else {
          document.getElementById("customer").scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      if (val === 3) {
        if (this.item.phases[4].enable) {
          this.e1 = 4;
        } else {
          document.getElementById("customer").scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      if (val === 4) {
        document.getElementById("customer").scrollIntoView({
          behavior: "smooth",
        });
      }
    },
  },
  watch: {
    "item.phases.1.mailjet"() {
      this.simpleDebounce(() => {
        this.$axios("/api/compaign/template", { params: { template: this.item.phases[1].mailjet } }).then((resp) => {
          if (resp.data.status === 200) {
            this.validTemplate = true;
            this.item.phases[1].subject = resp.data.name;
            this.item.phases[1].validTemplate = true;
            this.item.phases[1].templateName = resp.data.name;
          } else {
            this.validTemplate = false;
            this.item.phases[1].validTemplate = false;
            this.item.phases[1].templateName = "";
            this.item.phases[1].subject = "";
          }
        });
      }, 500);
    },
    "item.phases.1.enable"() {
      if (!this.item.phases[1].enable) {
        this.phase2Start = this.item.phases[1].startDate;
        if (this.item.phases[2].enable) {
          this.e1 = 2;
        } else if (this.item.phases[3].enable) {
          this.e1 = 3;
        } else if (this.item.phases[4].enable) {
          this.e1 = 4;
        }
      } else {
        this.e1 = 1;
      }
    },
    "item.phases.2.enable"() {
      if (!this.item.phases[2].enable) {
        if (this.item.phases[1].enable) {
          this.e1 = 1;
        } else if (this.item.phases[2].enable) {
          this.e1 = 3;
        } else if (this.item.phases[3].enable) {
          this.e1 = 3;
        } else if (this.item.phases[4].enable) {
          this.e1 = 4;
        }
      } else {
        this.e1 = 1;
      }
    },
    "item.phases.3.enable"() {
      if (!this.item.phases[3].enable) {
        if (this.item.phases[1].enable) {
          this.e1 = 1;
        } else if (this.item.phases[2].enable) {
          this.e1 = 3;
        } else if (this.item.phases[3].enable) {
          this.e1 = 3;
        } else if (this.item.phases[4].enable) {
          this.e1 = 4;
        }
      } else {
        this.e1 = 1;
      }
    },
    "item.phases.4.enable"() {
      if (!this.item.phases[4].enable) {
        if (this.item.phases[1].enable) {
          this.e1 = 1;
        } else if (this.item.phases[2].enable) {
          this.e1 = 3;
        } else if (this.item.phases[3].enable) {
          this.e1 = 3;
        } else if (this.item.phases[4].enable) {
          this.e1 = 4;
        }
      } else {
        this.e1 = 1;
      }
    },
    phase1Start() {
      this.item.phases[1].startDate = this.phase1Start;
      this.phase2Start = this.phase1Start;
      this.phase1Formatted = this.formatDate(this.phase1Start);

      if (Date.parse(this.item.phases[2].startDate) < Date.parse(this.item.phases[1].startDate)) {
        this.item.phases[2].startDate = this.item.phases[1].startDate;
      }
    },
    phase1End() {
      this.item.phases[1].endDate = this.phase1End;
      this.phase1EndFormatted = this.formatDate(this.phase1End);
    },
    phase2Start() {
      this.item.phases[2].startDate = this.phase2Start;
      this.phase2StartFormatted = this.formatDate(this.phase2Start);
      if (Date.parse(this.item.phases[3].startDate) < Date.parse(this.item.phases[2].startDate)) {
        this.item.phases[3].startDate = this.item.phases[2].startDate;
      }
    },
    phase2End() {
      this.item.phases[2].endDate = this.phase2End;
      this.phase2EndFormatted = this.formatDate(this.phase2End);
    },
    phase3Start() {
      this.item.phases[3].startDate = this.phase3Start;
      this.phase3Formatted = this.formatDate(this.phase3Start);
      if (Date.parse(this.item.phases[4].startDate) < Date.parse(this.item.phases[3].startDate)) {
        this.item.phases[4].startDate = this.item.phases[3].startDate;
      }
    },
    phase4Start() {
      this.item.phases[4].startDate = this.phase4Start;
      this.phase4Formatted = this.formatDate(this.phase4Start);
    },
    value() {
      this.item = this.value;
    },
    /*item(){
      this.$simpleToast.success('Campagne',"OK");
      this.$emit("input", this.item);
    },*/
    "item.startDate"() {
      if (Date.parse(this.item.startDate) > Date.parse(this.item.phases[1].startDate)) {
        this.phase1Start = this.item.startDate;
        this.phase2Start = this.item.startDate;
        this.phase3Start = this.item.startDate;
        this.phase4Start = this.item.startDate;
      }
      if (Date.parse(this.item.startDate) > Date.parse(this.item.phases[2].startDate)) {
        this.phase2Start = this.item.startDate;
        this.phase3Start = this.item.startDate;
        this.phase4Start = this.item.startDate;
      }
      if (Date.parse(this.item.startDate) > Date.parse(this.item.phases[3].startDate)) {
        this.phase3Start = this.item.startDate;
        this.phase4Start = this.item.startDate;
      }
      if (Date.parse(this.item.startDate) > Date.parse(this.item.phases[4].startDate)) {
        this.phase4Start = this.item.startDate;
      }
    },
  },
};
</script>

<style scoped></style>
