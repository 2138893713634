var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticStyle: { "z-index": "10" },
      attrs: { "min-height": "60vh", elevation: "0" },
    },
    [
      _c(
        "modal",
        {
          attrs: { "max-width": "1024" },
          model: {
            value: _vm.linkModal,
            callback: function ($$v) {
              _vm.linkModal = $$v
            },
            expression: "linkModal",
          },
        },
        [
          _c(
            "v-stepper",
            {
              attrs: { vertical: "" },
              model: {
                value: _vm.newLink.step,
                callback: function ($$v) {
                  _vm.$set(_vm.newLink, "step", $$v)
                },
                expression: "newLink.step",
              },
            },
            [
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.newLink.step > 1, step: "1" } },
                [
                  _vm._v(" Selectionner un type de lien "),
                  _c("small", [
                    _vm._v("Opportunité, événement, client, contact..."),
                  ]),
                ]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c("v-select", {
                    attrs: {
                      "menu-props": { maxHeight: "300" },
                      outlined: "",
                      items: _vm.linkType,
                      "item-text": "name",
                      "item-value": "type",
                      "return-object": "",
                      label: "Sélectionner le type de lien",
                    },
                    model: {
                      value: _vm.newLink.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.newLink, "type", $$v)
                      },
                      expression: "newLink.type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.newLink.step > 2, step: "2" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.newLink.type ? _vm.newLink.type.name : "Choix"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "menu-props": { maxHeight: "300" },
                      items: _vm.itemsList,
                      chips: "",
                      outlined: "",
                      label: "Choisir l'élément à lier",
                      "item-text": "name",
                      "item-value": "id",
                      "search-input": _vm.search.name,
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        return _vm.$set(_vm.search, "name", $event)
                      },
                      "update:search-input": function ($event) {
                        return _vm.$set(_vm.search, "name", $event)
                      },
                    },
                    model: {
                      value: _vm.newLink.link,
                      callback: function ($$v) {
                        _vm.$set(_vm.newLink, "link", $$v)
                      },
                      expression: "newLink.link",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.newLink.step = 1
                        },
                      },
                    },
                    [_vm._v(" Retour ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", loading: _vm.loading },
                      on: { click: _vm.saveLink },
                    },
                    [_vm._v(" Lier ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "v-navigation-drawer",
                {
                  attrs: { height: "100%", width: "100%", permanent: "" },
                  model: {
                    value: _vm.drawer,
                    callback: function ($$v) {
                      _vm.drawer = $$v
                    },
                    expression: "drawer",
                  },
                },
                [
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "Dossiers",
                        solo: "",
                        "solo-inverted": "",
                        flat: "",
                        "hide-details": "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.searchFolder,
                        callback: function ($$v) {
                          _vm.searchFolder = $$v
                        },
                        expression: "searchFolder",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "max-height": "calc(100vh - 95px)",
                          overflow: "auto",
                        },
                      },
                      [
                        _c("v-treeview", {
                          attrs: {
                            items: _vm.items,
                            "on-icon": "fa-folder-open",
                            updateAll: "selectTree()",
                            activatable: "",
                            search: _vm.searchFolder,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "prepend",
                              fn: function ({ item, open }) {
                                return [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(
                                      " far " +
                                        _vm._s(
                                          open ? "fa-folder-open" : "fa-folder"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "label",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.searchInMessages = null
                                          _vm.load(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "append",
                              fn: function ({ item }) {
                                return [
                                  item.value.unreadItemCount > 0
                                    ? _c(
                                        "v-chip",
                                        {
                                          staticClass: "float-end",
                                          attrs: { "x-small": "" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.value.unreadItemCount
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.treeSelected,
                            callback: function ($$v) {
                              _vm.treeSelected = $$v
                            },
                            expression: "treeSelected",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _vm.selectedDirectory
                ? _c(
                    "v-card",
                    { attrs: { elevation: "0" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(
                            " " + _vm._s(_vm.selectedDirectory.name) + " "
                          ),
                          _vm.selectedDirectory.value.unreadItemCount > 0
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ml-2 float-end",
                                  attrs: { small: "" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedDirectory.value
                                          .unreadItemCount
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    "border-right": "solid 1px lightgray",
                                  },
                                  attrs: { cols: "5", lg: "3", xl: "3" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "left",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _vm.page > 1
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.loadMail(
                                                        _vm.page === 2
                                                          ? false
                                                          : _vm.prevLink
                                                      )
                                                      _vm.page -= 1
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("fa-chevron-left")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "8" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "my-0 py-0",
                                            attrs: {
                                              label: "Recherche",
                                              dense: "",
                                              loading: _vm.searchInProgress,
                                              "hide-details": "",
                                              clearable: "",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                return _vm.filterEmailList()
                                              },
                                              "click:clear": function ($event) {
                                                _vm.searchInMessages = null
                                                _vm.loadMail()
                                              },
                                            },
                                            model: {
                                              value: _vm.searchInMessages,
                                              callback: function ($$v) {
                                                _vm.searchInMessages = $$v
                                              },
                                              expression: "searchInMessages",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "", small: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.page += 1
                                                  _vm.loadMail(_vm.nextLink)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("fa-chevron-right")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.mails && _vm.mails.length > 0
                                    ? _c("v-list", [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "max-height":
                                                "calc(100vh - 200px)",
                                              "overflow-y": "auto",
                                            },
                                          },
                                          _vm._l(
                                            _vm.mails,
                                            function (items, key) {
                                              return _c(
                                                "div",
                                                { key: key },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      class: {
                                                        "font-weight-bold":
                                                          !items.isRead,
                                                      },
                                                      attrs: {
                                                        "three-line": "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.loadMessage(
                                                            items
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "font-italic mb-2",
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          staticClass:
                                                                            "mt-0 float-right",
                                                                          attrs:
                                                                            {
                                                                              fab: "",
                                                                              "x-small":
                                                                                "",
                                                                              elevation:
                                                                                "0",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.itemsList =
                                                                                  []
                                                                                _vm.newLink =
                                                                                  {
                                                                                    step: 1,
                                                                                    type: null,
                                                                                    link: null,
                                                                                    email:
                                                                                      items,
                                                                                  }
                                                                                _vm.openLinkModal()
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  "x-small":
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " fa-fw fa-link"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            items
                                                                              .sender
                                                                              .emailAddress
                                                                              .name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c("br"),
                                                                      _c(
                                                                        "small",
                                                                        {
                                                                          staticClass:
                                                                            "grey--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .dayjs(
                                                                                  items.sentDateTime
                                                                                )
                                                                                .format(
                                                                                  "DD/MM/YYYY HH:mm"
                                                                                )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              staticClass:
                                                                "blue-grey--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  items.subject
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    items
                                                                      .bodyPreview
                                                                      .length >
                                                                      50
                                                                      ? items.bodyPreview.substr(
                                                                          0,
                                                                          50
                                                                        ) +
                                                                          "..."
                                                                      : items.bodyPreview
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider", {
                                                    key: key + "divider",
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ])
                                    : !_vm.searchPending
                                    ? _c(
                                        "v-list",
                                        { staticClass: "text-center" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "max-height":
                                                  "calc(100vh - 200px)",
                                                "min-height":
                                                  "calc(100vh - 200px)",
                                                overflow: "auto",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-center my-10 py-10 grey--text text--darken-2",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "my-3",
                                                      attrs: { "x-large": "" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "far fa-question-circle fa-fw "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c("h3", [
                                                    _vm._v(
                                                      "Aucun message " +
                                                        _vm._s(
                                                          this
                                                            .searchInMessages &&
                                                            this
                                                              .searchInMessages
                                                              .length > 3
                                                            ? "pour cette recherche"
                                                            : "dans ce dossier"
                                                        ) +
                                                        "."
                                                    ),
                                                  ]),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "font-italic",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            this
                                                              .searchInMessages &&
                                                              this
                                                                .searchInMessages
                                                                .length > 3
                                                              ? "Essayez un autre terme ou dans un autre dossier"
                                                              : "Sélectionnez un autre dossier"
                                                          ) +
                                                          ". "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.mails && _vm.mails.length > 0
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "left",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _vm.page > 1
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.page -= 1
                                                          _vm.loadMail(
                                                            _vm.page === 2
                                                              ? false
                                                              : _vm.prevLink
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fa-chevron-left"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-right",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.page += 1
                                                      _vm.loadMail(_vm.nextLink)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("fa-chevron-right")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "7", lg: "9", xl: "9" } },
                                [
                                  _vm.selectedMessage
                                    ? _c(
                                        "div",
                                        { staticClass: "selectedMessage" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "container-fluid" },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass: "pa-2",
                                                  attrs: { elevation: "0" },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "h4",
                                                            {
                                                              staticClass:
                                                                "mb-1 pb-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .selectedMessage
                                                                      .sender
                                                                      .emailAddress
                                                                      .name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "0.9rem",
                                                                    "font-style":
                                                                      "italic",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .selectedMessage
                                                                        .sender
                                                                        .emailAddress
                                                                        .address
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "mb-1 pb-3 pt-0 mt-0 blue-grey--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .selectedMessage
                                                                    .subject
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "grey--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .dayjs(
                                                                      _vm
                                                                        .selectedMessage
                                                                        .sentDateTime
                                                                    )
                                                                    .format(
                                                                      "DD/MM/YYYY HH:mm"
                                                                    )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-divider"),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-3 ma-0",
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "max-height":
                                                                  "calc(100vh - 260px)",
                                                                "overflow-y":
                                                                  "auto",
                                                                "overflow-x":
                                                                  "hidden",
                                                              },
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "message-box--body",
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      _vm
                                                                        .selectedMessage
                                                                        .body
                                                                        .content
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "text-center" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-large": "" },
                                                    },
                                                    [_vm._v("fa-mail-bulk")]
                                                  ),
                                                  _c("h3", [
                                                    _vm._v(
                                                      "Sélectionner un élément pour le lire"
                                                    ),
                                                  ]),
                                                  _c("h5", [
                                                    _vm._v(
                                                      "Aucun élément sélectionné"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }