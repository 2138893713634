export const errorMsg = {
  301: "Vous n'avez pas accès à cette ressource",
  400: "Requête incorrecte ou incomplète.",
  401: "Accès non autorisé. Vérifiez vos identifiants.",
  402: "Paiement requis pour accéder à cette ressource.",
  403: "Accès refusé à cette ressource.",
  404: "La ressource demandée est introuvable.",
  405: "Méthode de requête non autorisée pour cette ressource.",
  406: "Format de requête non accepté.",
  407: "Authentification requise.",
  408: "Temps de réponse dépassé.",
  409: "Conflit : la requête ne peut pas être traitée.",
  410: "Ressource indisponible, aucune redirection.",
  411: "Longueur de requête non spécifiée.",
  412: "Prérequis non remplis pour la requête.",
  413: "Requête trop volumineuse.",
  414: "Adresse trop longue pour être traitée.",
  415: "Type de média non supporté.",
  416: "Intervalle de ressource non satisfaisant.",
  417: "Le serveur n'a pas répondu comme attendu.",
  418: "Réponse amusante : le serveur est une théière.",
  422: "Requête correcte mais impossible à suivre.",
  423: "Ressource bloquée, impossible à traiter.",
  424: "Requiert une modification préalable de la ressource.",
  425: "Requête obsolète, ne doit plus être utilisée.",
  426: "Le serveur nécessite une mise à jour du protocole.",
  428: "Requête conditionnelle nécessaire.",
  429: "Trop de requêtes en peu de temps. Attendez un moment.",
  431: "Requête trop grosse, simplifiez-la.",
  432: "Veuillez sélectionner un client",
  433: "Un client existe déjà pour ce siret",
  434: "Un client existe déjà pour ce numéro SAGE",
  500: "Erreur interne au serveur",
};
