<template>
  <modal :style="isMobile() ? { marginTop: '50px' } : {}" max-width="768" v-model="modalVal">
    <template v-slot:header>
      <strong class="text-h5 mb-0 text-uppercase">générer un formulaire d'abonnement</strong>
      <br /><small class="mt-0">Choissisez un contact</small>
    </template>

    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-select
          v-model="selectedContact"
          :items="contacts"
          item-text="name"
          item-value="id"
          label="Choissisez un contact"
          outlined
          dense
          hide-no-data
          hide-details
          return-object
        >
        </v-select>
      </v-col>
      <v-alert type="info" class="mx-4" outlined dense>
        Le contact doit avoir une <strong>adresse email</strong> et un <strong>téléphone</strong> valide pour pouvoir générer le formulaire
      </v-alert>
    </v-row>

    <template v-slot:actions>
      <v-btn @click="generateJotForm" :loading="isLoading" variant="flat" color="primary" elevation="0" class="rounded-1 px-3" small>
        <v-icon x-small class="mr-2">fas fa-check fa-fw</v-icon>
        Saisir la proposition
      </v-btn>
      <v-btn
        color="secondary"
        text
        class="mr-4"
        @click="
          isLoading = false;
          modalVal = false;
        "
      >
        Fermer
      </v-btn>
    </template>
  </modal>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";
import MobileDetection from "../../mixins/MobileDetection";

export default {
  name: "jotFormModal",
  components: { Modal },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
  },
  mixins: [Tools, simpleDebounce, MobileDetection],
  data() {
    return {
      contacts: [],
      modalVal: this.value,
      isLoading: false,
      selectedContact: null,
    };
  },
  mounted() {},
  methods: {
    async getContact() {
      this.contacts = [];
      await this.$axios("/api/customers/" + this.$route.params.id + "/contacts", {
        params: { search: this.searchContact, page: this.page, limit: 100 },
      }).then((resp) => {
        resp.data.data.forEach((item) => {
          if (item.contact.contact.email && (item.contact.contact.phone || item.contact.contact.phone2)) {
            this.contacts.push({
              name: item.contact.contact.lastname + " " + item.contact.contact.firstname,
              email: item.contact.contact.email,
              phone: item.contact.contact.phone,
              phone2: item.contact.contact.phone2,
              emails: item.contact.contact.emails,
              customers: item.customers,
              id: item.contact.contact.id,
            });
          }
        });
      });
    },
    async generateJotForm() {
      this.isLoading = true;
      if (!this.selectedContact) {
        this.$toast.error("Veuillez choisir un contact");
        this.isLoading = false;
        return;
      }
      const response = await this.$axios.post("/api/customers/jotform/link", {
        entrepriseId: this.$route.params.id,
        contactId: this.selectedContact.id,
      });
      if (response.status === 200) {
        window.open(response.data, "_blank");
        this.$toast.success("Le formulaire a été généré avec succès");
      } else {
        this.isLoading = false;
      }
      this.isLoading = false;
      this.modalVal = false;
    },
  },
  watch: {
    value() {
      this.modalVal = this.value;
      if (this.value) this.getContact();
    },
    modalVal() {
      this.$emit("input", this.modalVal);
    },
  },
};
</script>

<style scoped></style>
