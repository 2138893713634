<template>
  <material-card height-tot="700px" color="info" icon="fa-calendar" title="Mon calendrier">
    <div>
      <Calendar type="month" small></Calendar>
    </div>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
import Calendar from "./Calendar";

export default {
  name: "dashboardCalendar",
  components: { Calendar, MaterialCard },
  data: () => ({}),
  methods: {},
};
</script>

<style scoped></style>
