var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.customer
    ? _c(
        "MaterialCard",
        {
          staticClass: "v-card-profile mt-10",
          attrs: {
            color: "primary",
            icon: "fa-handshake",
            header: true,
            title: "Opportunités",
          },
        },
        [
          _c(
            "div",
            { staticClass: "listbox--actions-add" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", color: "primary", fab: "" },
                  on: { click: _vm.createOpp },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fa-fw fa-plus"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-0",
            staticStyle: { "z-index": "1000" },
            attrs: {
              headers: _vm.headers,
              items: _vm.opportunities,
              page: _vm.page,
              "items-per-page": _vm.limitItem,
              loading: _vm.isLoading,
              "server-items-length": _vm.maxItem,
              "footer-props": { disableItemsPerPages: true },
            },
            on: {
              "click:row": _vm.linkOrLoad,
              "update:page": _vm.setPage,
              "update:items-per-page": _vm.setItemMax,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.item.deadline",
                  fn: function ({ item }) {
                    return [
                      item.item.deadline
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .dayjs(item.item.deadline)
                                    .format("DD/MM/YYYY")
                                ) +
                                " "
                            ),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "item.item.createdAt",
                  fn: function ({ item }) {
                    return [
                      item.item.createdAt
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .dayjs(item.item.createdAt)
                                    .format("DD/MM/YYYY à HH:mm")
                                ) +
                                " "
                            ),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "item.actions",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.linkItem(item)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa-link"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "item.item.stateId",
                  fn: function ({ item }) {
                    return [
                      item.item.statusId !== null
                        ? _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color:
                                  item.item.statusId === 1 ? "success" : "",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.item.statusId === 1
                                      ? "fa-thumbs-up"
                                      : "fa-thumbs-down"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color:
                                  item.item.stateId === 1 ? "warning" : "error",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.item.stateId === 1
                                      ? "fas fa-file-signature"
                                      : "fa-thumbs-down"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                    ]
                  },
                },
                {
                  key: "item.item.statusId",
                  fn: function ({ item }) {
                    return [
                      item.item.statusId !== null
                        ? _c(
                            "div",
                            [
                              item.item.statusId !== null
                                ? _c(
                                    "v-chip",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        label: "",
                                        color:
                                          item.item.statusId === 1
                                            ? "success"
                                            : "error",
                                        dark: "",
                                        small: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.item.statusId === 1
                                              ? "Gagné"
                                              : "Perdu"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "item.item.stepId",
                  fn: function ({ item }) {
                    return [
                      item.item.stepId && _vm.step
                        ? _c(
                            "v-chip",
                            {
                              staticStyle: {
                                width: "100px",
                                "text-align": "center",
                                display: "block",
                                cursor: "pointer",
                              },
                              attrs: { small: "", label: "" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.step[item.item.stepId].name
                                      ? _vm.step[item.item.stepId].name
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "item.item.categoryId",
                  fn: function ({ item }) {
                    return [
                      item.item.categoryId
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.listCat[item.item.categoryId].name
                                    ? _vm.listCat[item.item.categoryId].name
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "item.commercial",
                  fn: function ({ item }) {
                    return [
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            bordered: "",
                                            bottom: "",
                                            color:
                                              item && item.type
                                                ? item.type.color
                                                : "",
                                            dot: "",
                                            "offset-x": "10",
                                            "offset-y": "10",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            _vm._g(
                                              _vm._b({}, "div", attrs, false),
                                              on
                                            ),
                                            [
                                              _c("UserAvatar", {
                                                attrs: {
                                                  size: "35",
                                                  userId: item.commercial.id,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  item && item.type ? item.type.name + " -" : ""
                                ) +
                                  " " +
                                  _vm._s(
                                    item && item.commercial
                                      ? item.commercial.fullname
                                      : ""
                                  )
                              ),
                            ]),
                          ]
                        ),
                      ],
                    ]
                  },
                },
                {
                  key: "item.item.probability",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-progress-circular",
                        {
                          attrs: {
                            rotate: 360,
                            size: 40,
                            width: 5,
                            value: item.item.probability
                              ? item.item.probability
                              : "0",
                            color:
                              item.item.probability > 50 ? "success" : "error",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.item.probability
                                  ? item.item.probability + "%"
                                  : "0" + "%"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "item.item.customerId",
                  fn: function ({ item }) {
                    return [
                      item.item.customerId
                        ? [
                            _vm._v(
                              " " + _vm._s(item.customer.entreprise.name) + " "
                            ),
                          ]
                        : _vm._e(),
                      !item.item.customerId
                        ? [_vm._v(" Non rattaché ")]
                        : _vm._e(),
                    ]
                  },
                },
                !_vm.link
                  ? {
                      key: "body.prepend",
                      fn: function () {
                        return [
                          _c("tr", [
                            _c("td"),
                            _c("td"),
                            _c("td"),
                            _c("td"),
                            _c("td"),
                            _c(
                              "td",
                              [
                                _c("v-text-field", {
                                  attrs: { label: "Rechercher" },
                                  model: {
                                    value: _vm.search.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "name", $$v)
                                    },
                                    expression: "search.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }