var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("material-card", { attrs: { title: "Caractéristiques" } }, [
    _c(
      "div",
      { staticStyle: { position: "relative" } },
      [
        _c(
          "div",
          { staticStyle: { position: "absolute", right: "5px", top: "-5px" } },
          [
            _c("v-text-field", {
              attrs: { label: "Rechercher" },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
          ],
          1
        ),
        _c("v-data-table", {
          staticClass: "elevation-1",
          attrs: {
            headers: _vm.headers,
            items: _vm.tab,
            "items-per-page": 10,
            search: _vm.search,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }