var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isGranted("GROUPS_INDEX")
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            { staticStyle: { "z-index": "10" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary ", dark: "", elevation: "0" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "11" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mx-4",
                            attrs: {
                              label: "Rechercher",
                              "prepend-icon": "fa-search",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "1" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "listbox--actions-add",
                              attrs: { color: "primary", small: "", fab: "" },
                              on: { click: _vm.addFct },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("fa-plus fa-fw"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.groups,
                  search: _vm.search,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.active",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-btn",
                            { attrs: { small: "", text: "" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    color: item.active ? "success" : "warning",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.active ? "fa-check" : "fa-times"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.actions",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: { small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.editItem(item)
                                },
                              },
                            },
                            [_vm._v("fa-edit")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  564698463
                ),
              }),
            ],
            1
          ),
          _c(
            "modal",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.editModal = false
                              },
                            },
                          },
                          [_vm._v(" Fermer ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: { text: "" },
                            on: { click: _vm.saveGroups },
                          },
                          [_vm._v(" Enregistrer ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3014540685
              ),
              model: {
                value: _vm.editModal,
                callback: function ($$v) {
                  _vm.editModal = $$v
                },
                expression: "editModal",
              },
            },
            [
              _vm.editableItem
                ? _c(
                    "v-row",
                    [
                      _vm.editableItem.id
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: { disabled: "", label: "#" },
                                model: {
                                  value: _vm.editableItem.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editableItem, "id", $$v)
                                  },
                                  expression: "editableItem.id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-switch", {
                            attrs: { inset: "", label: "Actif" },
                            model: {
                              value: _vm.editableItem.active,
                              callback: function ($$v) {
                                _vm.$set(_vm.editableItem, "active", $$v)
                              },
                              expression: "editableItem.active",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Nom" },
                            model: {
                              value: _vm.editableItem.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.editableItem, "name", $$v)
                              },
                              expression: "editableItem.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }