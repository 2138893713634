var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                _vm._v("Mise à jour"),
              ]),
              _c("br"),
              _c("small", { staticClass: "mt-0" }, [
                _vm._v("La mise à jour est conseillée"),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", variant: "flat", elevation: "0" },
                  on: { click: _vm.refreshPage },
                },
                [_vm._v("Rafraîchir")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "error" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleRefreshModal()
                    },
                  },
                },
                [_vm._v("Ignorer")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.refreshModal,
        callback: function ($$v) {
          _vm.refreshModal = $$v
        },
        expression: "refreshModal",
      },
    },
    [
      _c("div", { staticClass: "text-left py-4" }, [
        _c("p", [_vm._v("Une nouvelle mise à jour est disponible.")]),
        _c("p", [
          _vm._v(
            " Vous pouvez ignorer la mise à jour et la faire ultérieurement. "
          ),
          _c("br"),
          _vm._v(" Nous vous conseillons de maintenir à jour l'application. "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }