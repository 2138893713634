<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="2" v-if="$slots.title" class="py-0 mt-5">
      <slot name="title"></slot>
    </v-col>
    <v-col :cols="$slots.title ? 10 : 12" class="py-0 ma-0">
      <v-autocomplete
        :menu-props="{ maxHeight: '300' }"
        v-model="usersSelected"
        :items="users"
        :clearable="clearable"
        chips
        :disabled="disabled"
        :color="labelColor"
        :label="label"
        item-text="fullname"
        item-value="id"
        :return-object="returnObject"
        :multiple="multiple"
        :search-input.sync="search"
        :persistent-placeholder="!!$slots.title"
        @focus="loadUsers"
      >
        <template v-slot:selection="data">
          <v-chip v-bind="data.attrs" :input-value="data.selected" :close="multiple" @click="data.select" @click:close="remove(data.item)">
            <UserAvatar :userId="data.item.id" left />
            {{ data.item.firstname + " " + data.item.lastname }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template>
            <v-list-item-avatar>
              <UserAvatar :userId="data.item.id" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.firstname + " " + data.item.lastname }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Tools from "../../mixins/Tools";
import UserAvatar from "./UserAvatar.vue";

export default {
  name: "listUser",
  mixins: [simpleDebounce, Tools],
  props: {
    includeAll: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Number,
      default: null,
    },
    userMultiple: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "Choisir un utilisateur",
    },
    labelColor: {
      type: String,
      default: "blue-grey lighten-2",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    usersList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      usersSelected: [],
      toggleUserList: false,
      search: "",
      users: this.usersList,
    };
  },
  methods: {
    remove(item) {
      const index = this.usersSelected.indexOf(item.id);
      if (index >= 0) this.usersSelected.splice(index, 1);
    },
    searchUser() {
      this.$axios("/api/users?s=" + this.search, { params: { all: this.includeAll ? true : false, active: this.includeAll ? true : false } }).then((resp) => {
        this.users = resp.data;
      });
    },
    loadUsers() {
      if (!this.users) {
        this.$axios("/api/users", { params: { limit: false, active: true } }).then((resp) => {
          this.users = resp.data;
        });
      }
    },
  },
  created() {
    if (this.usersList) {
      this.users = this.usersList;
    } else {
      this.loadUsers();
    }
    if (this.user || this.userMultiple) {
      if (this.user) this.usersSelected = this.user;
      else if (this.userMultiple.length > 0) this.usersSelected = this.userMultiple;
    }
  },
  watch: {
    usersSelected() {
      this.search = "";
      this.$emit("selectChange", this.usersSelected);
      this.$emit("input", this.usersSelected);
    },
    search() {
      this.simpleDebounce(() => {
        if (this.search && this.search !== "") {
          this.searchUser();
        }
      }, 300 /* optional debounce time: default is 300 */);
    },
    toggleUserList() {},
  },
  components: { UserAvatar },
};
</script>

<style scoped></style>
