<template>
  <MaterialCard class="v-card-profile mt-10" v-if="customer" :color="'primary'" icon="fa-handshake" :header="true" title="Opportunités">
    <div class="listbox--actions-add">
      <v-btn small color="primary" fab @click="createOpp"><v-icon small>fa-fw fa-plus</v-icon></v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="opportunities"
      :page="page"
      :items-per-page="limitItem"
      :loading="isLoading"
      class="elevation-0"
      style="z-index: 1000"
      :server-items-length="maxItem"
      @click:row="linkOrLoad"
      :footer-props="{ disableItemsPerPages: true }"
      @update:page="setPage"
      @update:items-per-page="setItemMax"
    >
      <template v-slot:item.item.deadline="{ item }">
        <template v-if="item.item.deadline">
          {{ dayjs(item.item.deadline).format("DD/MM/YYYY") }}
        </template>
      </template>

      <template v-slot:item.item.createdAt="{ item }">
        <template v-if="item.item.createdAt">
          {{ dayjs(item.item.createdAt).format("DD/MM/YYYY à HH:mm") }}
        </template>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn small icon @click="linkItem(item)"><v-icon small>fa-link</v-icon></v-btn>
      </template>

      <template v-slot:item.item.stateId="{ item }">
        <!--<v-chip v-if="item.item.statusId !== null" label

                      style="cursor: pointer"
                      small
              >-->
        <v-icon v-if="item.item.statusId !== null" small :color="item.item.statusId === 1 ? 'success' : ''">
          {{ item.item.statusId === 1 ? "fa-thumbs-up" : "fa-thumbs-down" }}
        </v-icon>
        <!--</v-chip>-->
        <!--<v-chip label v-else

                     style="cursor: pointer"
                     small
             >-->
        <v-icon v-else small :color="item.item.stateId === 1 ? 'warning' : 'error'">
          {{ item.item.stateId === 1 ? "fas fa-file-signature" : "fa-thumbs-down" }}
        </v-icon>
        <!-- </v-chip>-->
      </template>

      <template v-slot:item.item.statusId="{ item }">
        <div v-if="item.item.statusId !== null">
          <v-chip v-if="item.item.statusId !== null" label :color="item.item.statusId === 1 ? 'success' : 'error'" dark style="cursor: pointer" small>
            {{ item.item.statusId === 1 ? "Gagné" : "Perdu" }}
          </v-chip>
        </div>
      </template>

      <template v-slot:item.item.stepId="{ item }">
        <v-chip small v-if="item.item.stepId && step" label style="width: 100px; text-align: center; display: block; cursor: pointer">
          {{ step[item.item.stepId].name ? step[item.item.stepId].name : "" }}
        </v-chip>
      </template>
      <template v-slot:item.item.categoryId="{ item }">
        <template small v-if="item.item.categoryId">
          {{ listCat[item.item.categoryId].name ? listCat[item.item.categoryId].name : "" }}
        </template>
      </template>

      <template v-slot:item.commercial="{ item }">
        <template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-badge bordered bottom :color="item && item.type ? item.type.color : ''" dot offset-x="10" offset-y="10">
                <div v-bind="attrs" v-on="on">
                  <UserAvatar size="35" :userId="item.commercial.id" />
                </div>
              </v-badge>
            </template>
            <span>{{ item && item.type ? item.type.name + " -" : "" }} {{ item && item.commercial ? item.commercial.fullname : "" }}</span>
          </v-tooltip>
        </template>
      </template>

      <template v-slot:item.item.probability="{ item }">
        <v-progress-circular
          :rotate="360"
          :size="40"
          :width="5"
          :value="item.item.probability ? item.item.probability : '0'"
          :color="item.item.probability > 50 ? 'success' : 'error'"
        >
          {{ item.item.probability ? item.item.probability + "%" : "0" + "%" }}
        </v-progress-circular>
      </template>

      <template v-slot:item.item.customerId="{ item }">
        <template v-if="item.item.customerId">
          {{ item.customer.entreprise.name }}
        </template>
        <template v-if="!item.item.customerId"> Non rattaché </template>
      </template>

      <template v-slot:body.prepend v-if="!link">
        <tr>
          <td>
            <!-- <v-text-field
                   v-model="search.id"
                   type="number"
                   label="Rechercher"
               ></v-text-field>-->
          </td>
          <td>
            <!-- <div style="width: 76px"></div>-->
          </td>
          <td>
            <!--
                <v-select
                  :items="statusArray"
                  item-text="name"
                  item-value="id"
                  label="Rechercher"
                  v-model="search.status"
                  :append-outer-icon="search.status !== null ? 'fa-times' : ''"
                  @click:append-outer="search.status = null"
              ></v-select>-->
          </td>
          <td>
            <!-- <v-select
                    :items="stateArray"
                    item-text="name"
                    item-value="id"
                    label="Rechercher"
                    v-model="search.state"
                    :append-outer-icon="search.state !== null ? 'fa-times' : ''"
                    @click:append-outer="search.state = null"
                ></v-select>-->
          </td>
          <td></td>
          <td>
            <v-text-field v-model="search.name" label="Rechercher"></v-text-field>
          </td>
        </tr>
      </template>
    </v-data-table>
  </MaterialCard>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import MaterialCard from "../../components/common/MaterialCard";
import Tools from "../../mixins/Tools";
import UserAvatar from "../../components/user/UserAvatar.vue";

export default {
  name: "opportunitiesCustomers",
  mixins: [Tools, simpleDebounce],
  components: { MaterialCard, UserAvatar },
  props: {
    value: { required: false },
    link: { default: false },
    customerId: { default: false },
  },
  data() {
    return {
      searchCat: "",
      stepArray: [
        { id: 1, name: "Identifié" },
        { id: 2, name: "Étude" },
        { id: 3, name: "Négociation" },
      ],
      statusArray: [
        { id: 1, name: "Gagné" },
        { id: 2, name: "Perdu" },
      ],
      search: {
        id: null,
        deadline: null,
        state: null,
        status: null,
        step: null,
        type: null,
        categories: null,
        probability: null,
        commercial: null,
        name: "",
        client: null,
        sortedBy: null,
        sortedDesc: false,
        all: false,
      },
      stateArray: [
        { id: 1, name: "Ouvert" },
        { id: 2, name: "Fermée" },
      ],
      customer: this.value,
      opportunities: [],
      itemNbr: 5,
      maxItem: 0,
      page: 1,
      limitItem: 5,
      isLoading: true,
      step: {
        1: {
          name: "Identifié",
        },
        2: {
          name: "Étude",
        },
        3: {
          name: "Négociation",
        },
      },
      listCatArray: [],
      listCat: [],
      typeListObj: {},

      headers: [
        { text: "Numero", value: "item.id", sortable: false },
        { text: "Commercial", value: "commercial", sortable: false, align: "center" },
        { text: "État", value: "item.stateId", sortable: false },
        { text: "Échéance", value: "item.deadline", sortable: false },
        { text: "Étape", value: "item.stepId", sortable: false },
        //{ text: 'Classement', value: 'item.categoryId' },
        { text: "Sujet", value: "item.name", sortable: false },
      ],
    };
  },
  mounted() {
    if (this.link) {
      this.headers = [
        { text: "Numero", value: "item.id", sortable: false },
        { text: "Date de création", value: "item.createdAt", sortable: false },
        { text: "Intitulé", value: "item.name", sortable: false },
      ];
    }
    this.loadCat();
    this.loadType();
    this.loadOpportunities();
  },
  methods: {
    linkOrLoad(item) {
      if (this.link) {
        this.linkItem(item);
      } else {
        this.loadOpportunity(item);
      }
    },
    async createOpp() {
      const res = await this.$confirm("Confirmez-vous la création d'une opportunité ?", {
        title: "Création opportunité".toUpperCase(),
        color: "primary",
      });
      if (res) {
        this.$axios
          .post("/api/opportunities", {
            forceCreate: true,
            customer: this.$route.params.id,
          })
          .then((resp) => {
            this.$router.push({ name: "Opportunité", params: { id: resp.data.opp.id } });
          });
      }
    },
    linkItem(item) {
      this.$emit("linkOpp", item.item.id);
    },
    loadType() {
      this.$axios("/api/types/active").then((res) => {
        this.listType = res.data.types;
        this.listType.forEach((item) => {
          this.typeListObj[item.id] = item;
        });
      });
    },
    loadCat() {
      this.$axios("/api/categories").then((res) => {
        this.listCatArray = [];
        this.listCat = res.data;
        Object.keys(res.data).forEach((item) => {
          this.listCatArray.push(res.data[item]);
        });
      });
    },
    loadOpportunities() {
      let page = this.page;
      let search = this.search;
      this.isLoading = true;
      let id = this.customerId ? this.customerId : this.$route.params.id;
      this.$axios("/api/opportunities/customer/" + id, { params: { page: page, limit: this.limitItem, search } }).then((resp) => {
        this.opportunities = resp.data.data;
        this.maxItem = resp.data.max;
        this.isLoading = false;
      });
    },
    setItemMax(event) {
      this.limitItem = event;
    },
    setPage(event) {
      this.page = event;
    },
    loadOpportunity(row) {
      if (this.link) {
        return;
      }
      this.$router.push({ name: "Opportunité", params: { id: row.item.id } });
    },
    loadOpportunitiesDebouncePage1() {
      this.page = 1;
      this.simpleDebounce(() => {
        this.loadOpportunities();
      }, 300 /* optional debounce time: default is 300 */);
    },
  },
  computed: {},
  watch: {
    "search.state": "loadOpportunitiesDebouncePage1",
    "search.id": "loadOpportunitiesDebouncePage1",
    "search.deadline": "loadOpportunitiesDebouncePage1",
    "search.type": "loadOpportunitiesDebouncePage1",
    "search.status": "loadOpportunitiesDebouncePage1",
    "search.step": "loadOpportunitiesDebouncePage1",
    "search.categories": "loadOpportunitiesDebouncePage1",
    "search.probability": "loadOpportunitiesDebouncePage1",
    "search.name": "loadOpportunitiesDebouncePage1",
    "search.commercial": "loadOpportunitiesDebouncePage1",
    limitItem() {
      this.loadOpportunities();
    },
    page() {
      this.loadOpportunities();
    },
    value() {
      this.customer = this.value;
    },
    customer() {
      this.$emit("input", this.customer);
    },
  },
};
</script>

<style scoped></style>
