// css FA
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import fr from 'vuetify/lib/locale/fr'


Vue.use(Vuetify);


export default new Vuetify({
    lang: {
        locales: {fr},
        current: 'fr',
    },
    icons: {
        iconfont: 'fa',
    },
    options: {
        customProperties: true,
        variations: false
    },
    theme: {
        themes: {
            light: {
                //primary: '#8dadbf',
              //  primary: '#82b3c9',
                primary: '#90A4AE',
                secondary: '#757575',
                accent: '#82B1FF',
                error: '#C62828',
                info: '#2196F3',
                success: '#43A047',
                warning: '#ffb74d',
               // background: 'rgba(166, 161, 125, 0.2)',
              //  background: 'rgba(144, 164, 174, 0.05)',
                background: '#f9fafb'
            },
            dark: {
                primary: '#004da5',
                secondary: '#97ca00',
            }
        },
    },
});


//var date = new Date(idDateIndex);

//let ts = date.now();

