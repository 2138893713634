<template>
  <v-container fluid>
    <v-card style="z-index: 10;">
      <v-toolbar color="primary " dark elevation="0">
        <v-row>
          <v-col cols="11">
            <v-text-field v-model="search" label="Rechercher" single-line prepend-icon="fa-search" hide-details></v-text-field>
          </v-col>
          <v-col cols="1" class="text-center">
            <v-btn @click="addFct" v-if="isGranted('CATEGORIES_ADD')" color="primary" small fab class="listbox--actions-add">
              <v-icon small>fa-plus fa-fw</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table v-if="isGranted('CATEGORIES_INDEX')" :headers="headers" :items="categories" :items-per-page="10" class="elevation-1" :search="search">
        <template v-if="isGranted('CATEGORIES_EDIT')" v-slot:item.actions="{ item }">
          <v-icon small @click="editItem(item)">fa-edit</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <modal v-model="editModal">
      <v-row v-if="editableItem">
        <v-col cols="12" v-if="editableItem.id">
          <v-text-field disabled v-model="editableItem.id" label="#"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="editableItem.name" label="Nom"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select
            :menu-props="{ maxHeight: '300' }"
            :items="services"
            item-text="name"
            item-value="id"
            m-
            v-model="editableItem.typeId"
            label="Métier"
          ></v-select>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn text class="mr-4" @click="editCat">
          Enregistrer
        </v-btn>
      </template>
    </modal>
  </v-container>
</template>

<script>
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";
export default {
  name: "categoriesSettings",
  components: { Modal },
  mixins: [Tools],
  data() {
    return {
      categories: [],
      services: [],
      search: "",
      editableItem: null,
      editModal: false,
      headers: [
        { text: "#", value: "id", align: "left" },
        { text: "Nom", value: "name", align: "left" },
        { text: "Actions", value: "actions", align: "right" },
      ],
    };
  },
  methods: {
    addFct() {
      this.editableItem = {
        name: "",
        typeId: null,
        orangeProductCategory: "",
      };
      this.editModal = true;
    },
    editItem(item) {
      this.editableItem = JSON.parse(JSON.stringify(item));
      this.editModal = true;
    },
    editCat() {
      if (this.editableItem.id) {
        this.$axios.patch("/api/categories/" + this.editableItem.id, { data: this.editableItem }).then(() => {
          this.$simpleToast.success("Catégorie", "La catégorie a bien été mise à jour");
          this.editModal = false;
          this.getCategories();
        });
      } else {
        this.$axios.post("/api/categories", { data: this.editableItem }).then(() => {
          this.$simpleToast.success("Catégorie", "La catégorie a bien été ajoutée");
          this.editModal = false;
          this.getCategories();
        });
      }
    },
    getCategories() {
      this.$axios("/api/categories").then((resp) => {
        let tmp = [];

        Object.keys(resp.data).forEach((key) => {
          tmp.push(resp.data[key]);
        });
        this.categories = tmp;
      });
    },
    getServices() {
      this.$axios("/api/types/active").then((resp) => {
        this.services = resp.data.types;
      });
    },
  },
  mounted() {
    this.getCategories();
    this.getServices();
  },
};
</script>

<style scoped></style>
