var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { "max-width": "800" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                _vm._v(" " + _vm._s(_vm.concurrent.name)),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.modalVal,
        callback: function ($$v) {
          _vm.modalVal = $$v
        },
        expression: "modalVal",
      },
    },
    [
      _c(
        "v-row",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-col",
            { staticClass: "text-left", attrs: { cols: "8" } },
            [
              _c("span", { staticClass: "text-button font-weight-medium" }, [
                _vm._v("Informations"),
              ]),
              _c("v-divider", { staticClass: "mb-2" }),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("APE :"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.concurrent.ape) + " "),
                  ]),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("SIRET :"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.concurrent.siret) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Adresse :"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.concurrent.address) + " "),
                  ]),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Adresse 2 :"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.concurrent.address2) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Code postal :"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.concurrent.postalCode) + " "),
                  ]),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Ville :"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.concurrent.city) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Email :"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.concurrent.email) + " "),
                  ]),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Téléphone :"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.concurrent.phone) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Téléphone 2 :"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.concurrent.phone2) + " "),
                  ]),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Site :"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.concurrent.site) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-start align-center",
                      attrs: { cols: "6" },
                    },
                    [
                      _c("span", { staticClass: "font-weight-bold mr-2" }, [
                        _vm._v("Prestataire :"),
                      ]),
                      _vm.concurrent?.prestataire
                        ? _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v(" fa-fw fa-check "),
                          ])
                        : _c("v-icon", { attrs: { color: "error" } }, [
                            _vm._v(" fa-fw fa-times "),
                          ]),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("ID :"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.concurrent.id) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("h5", { staticClass: "text-button font-weight-medium mb-0" }, [
                _vm._v("Opportunités gagnées"),
              ]),
              _c("v-divider", { staticClass: "mb-2" }),
              _vm.concurrent?.allInfo?.opportunitiesWin.length > 0
                ? _vm._l(
                    _vm.concurrent?.allInfo?.opportunitiesWin,
                    function (opportunity) {
                      return _c(
                        "v-chip",
                        {
                          key: opportunity.id,
                          staticClass: "mb-2 mr-2",
                          attrs: { color: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                name: "Opportunité",
                                params: { id: opportunity.id },
                              })
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(opportunity.id) + " ")]
                      )
                    }
                  )
                : _c("span", { staticClass: "italic" }, [_vm._v("Aucune")]),
              _c("h5", { staticClass: "text-button font-weight-medium mb-0" }, [
                _vm._v("Opportunités en cours"),
              ]),
              _c("v-divider", { staticClass: "mb-2" }),
              _vm.concurrent?.allInfo?.opportunitiesConcurrentCustomers.length >
              0
                ? _vm._l(
                    _vm.concurrent?.allInfo?.opportunitiesConcurrentCustomers,
                    function (opportunity) {
                      return _c(
                        "v-chip",
                        {
                          key: opportunity.id,
                          staticClass: "mb-2 mr-2",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                name: "Opportunité",
                                params: { id: opportunity.id },
                              })
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(opportunity.id) + " ")]
                      )
                    }
                  )
                : _c("span", { staticClass: "italic" }, [_vm._v("Aucune")]),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _vm.isGranted("CUSTOMERS_VIEW") &&
                  _vm.concurrent?.allInfo?.entreprise?.customers?.id
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase font-size-10",
                          attrs: {
                            color: "primary",
                            elevation: "2",
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                name: "Client",
                                params: {
                                  id: _vm.concurrent.allInfo.entreprise
                                    .customers.id,
                                },
                              })
                            },
                          },
                        },
                        [_vm._v("Accéder au compte ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm.concurrent?.allInfo?.client.length > 0
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-expansion-panels",
                    { staticClass: "elevation-0" },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "text-button font-weight-medium mb-0",
                              },
                              [_vm._v("Liste des clients")]
                            ),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("v-data-table", {
                                staticClass: "elevation-1",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  dense: "",
                                  headers: _vm.headers,
                                  items: _vm.concurrent?.allInfo?.client,
                                  "items-per-page": 10,
                                },
                                on: {
                                  "click:row": (row) =>
                                    _vm.$router.push({
                                      name: "Client",
                                      params: { id: row.id },
                                    }),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }