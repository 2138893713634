<template>
  <v-container fluid v-if="isGranted('INFRASTRUCTURES_INDEX')">
    <v-card style="z-index: 9; position: relative">
      <modal v-model="addTypes">
        <template v-slot:header>{{ newType.id ? "Édition" : "Création" }}</template>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="newType.name" label="Nom"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select :menu-props="{ maxHeight: '300' }" v-model="newType.type.id" :items="types" label="Métier" item-text="name" item-value="id"></v-select>
          </v-col>
        </v-row>
        <template v-slot:actions>
          <v-btn color="primary" @click="saveType">Enregistrer</v-btn>
        </template>
      </modal>

      <modal v-model="settingsModal">
        <template v-slot:header>{{ types[typeValue].name }}</template>
        <v-row class="mt-2" v-if="types[typeValue]?.settings !== undefined">
          <v-col class="pb-0" cols="12" v-for="(item, key) in Object.keys(types[typeValue].settings)" :key="key">
            <v-row class="py-0">
              <v-col class="py-0" cols="8">
                <v-text-field v-model="types[typeValue].settings[item].name" :label="types[typeValue].settings[item].initName"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="4">
                <v-switch v-model="types[typeValue].settings[item].enable" label="Actif"></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <template v-slot:actions>
          <v-btn text color="error" @click="settingsModal = false">ANNULER</v-btn>
          <v-btn color="primary" @click="saveCurSettings">Enregistrer</v-btn>
        </template>
      </modal>
      <v-btn
        small
        fab
        style="position: absolute; right: 5rem; top: 15px; z-index: 11"
        color="primary"
        @click="
          addTypes = true;
          newType = { name: '', type: { id: null }, slug: '' };
        "
        ><v-icon small>fa-plus fa-fw</v-icon></v-btn
      >
      <v-btn
        small
        fab
        outlined
        style="position: absolute; right: 15px; top: 15px; z-index: 11"
        color="primary"
        @click="types[typeValue]?.settings === null ? initSettings() : addBirthday()"
        ><v-icon small>fa-cog fa-fw</v-icon></v-btn
      >
      <v-tabs v-model="typeValue" fixed-tabs>
        <v-tab v-for="(type, key) in types" :key="key">{{ type?.name }}</v-tab>
        <v-tab-item v-for="(type, key) in types" :key="key">
          <v-row>
            <v-col cols="12" md="6" lg="4" v-for="(parcType, keyT) in parcTypes[type.id]" :key="keyT">
              <v-card color="basil" class="mx-3 my-10">
                <v-toolbar color="primary " dark elevation="0">
                  <v-card-title
                    >{{ parcType?.name }} <span style="font-size: 0.7rem; font-style: italic">- {{ parcType.slug }}</span>
                    <div class="listbox--actions-add mt-10">
                      <v-btn
                        color="white"
                        class="ma-0"
                        @click="
                          newType = JSON.parse(JSON.stringify(parcType));
                          addTypes = true;
                        "
                        text
                        small
                      >
                        <v-icon small>fa-edit fa-fw</v-icon>
                      </v-btn>
                      <v-btn color="error" text class="ma-0" @click="deleteParc(parcType)" small>
                        <v-icon small>fa-trash-alt fa-fw</v-icon>
                      </v-btn>
                      <v-btn color="primary" fab class="ml-2" @click="addItem({ typeId: type.id, model: parcType.slug })" x-small>
                        <v-icon x-small>fa-plus fa-fw</v-icon>
                      </v-btn>
                    </div>
                  </v-card-title></v-toolbar
                >

                <v-card-text>
                  <v-data-table :headers="headers" :items="infos[type.id][parcType.slug]" class="elevation-0">
                    <template v-slot:item.count="{ item }">
                      {{ count[item.id] }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon small @click="editItem(item)">fa-edit</v-icon>
                      <v-icon color="error" small class="ml-2" @click="deleteItem(item)">fa-trash-alt</v-icon>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>

      <modal v-model="editModal">
        <v-row v-if="editableItem">
          <v-col cols="12">
            <v-switch inset v-model="editableItem.active" label="Actif"></v-switch>
          </v-col>
          <v-col cols="12" v-if="editableItem.typeId === 2 && editableItem.model === 'brands'">
            <v-select :menu-props="{ maxHeight: '300' }" :items="editors[1]" v-model="editableItem.filterId" item-text="name" item-value="id"></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Nom" v-model="editableItem.name"></v-text-field>
          </v-col>
        </v-row>
        <template v-slot:actions>
          <v-btn color="error" text @click="editModal = false"> Fermer </v-btn>
          <v-btn text class="mr-4" @click="saveItem"> Enregistrer </v-btn>
        </template>
      </modal>
    </v-card>
  </v-container>
</template>

<script>
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";
export default {
  name: "parcsSettings",
  components: { Modal },
  mixins: [Tools],
  data() {
    return {
      addTypes: false,
      editModal: false,
      editableItem: null,
      typeValue: 0,
      newType: { name: "", type: { id: null }, slug: "" },
      settingsModal: false,
      types: [],
      headers: [
        { text: "#", value: "id", align: "left", sortable: false },
        { text: "Nom", value: "name", align: "left", sortable: false },
        { text: "Occurences", value: "count", align: "right", sortable: false },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      headers2: [
        { text: "#", value: "id", align: "left", sortable: false },
        { text: "Nom", value: "name", align: "left", sortable: false },
        { text: "Catégorie", value: "filterId", align: "left", sortable: false },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      editors: [],
      parcTypes: {},
      infos: {},
      count: {},
    };
  },
  mounted() {
    this.getTypes();
    this.getParcTypes();
  },
  methods: {
    saveCurSettings() {
      this.$axios.patch("/api/types/" + this.types[this.typeValue].id, { data: this.types[this.typeValue] }).then(() => {
        this.getParcTypes();
        this.$simpleToast.success("Parc", "L'élément a bien été enregistré");
        this.settingsModal = false;
      });
    },
    initSettings() {
      this.types[this.typeValue].settings = {
        field1: { name: "Version", initName: "Version", enable: true },
        numberfield: { name: "Quantité", initName: "Quantité", enable: true },
        field2: { name: "N° License / série", initName: "N° License / série", enable: true },
        date: { name: "Date d'échéance", initName: "Date d'échéance", enable: true },
        body: { name: "Note", initName: "Note", enable: true },
        birthday: { name: "Date d'anniversaire", initName: "Date d'anniversaire", enable: true },
      };
      this.settingsModal = true;
    },
    addBirthday() {
      if (!Object.keys(this.types[this.typeValue].settings).includes("birthday")) {
        this.types[this.typeValue].settings.birthday = { name: "Date d'anniversaire", initName: "Date d'anniversaire", enable: true };
      }
      this.settingsModal = true;
    },
    async deleteParc(item) {
      let res = await this.$confirm("Êtes-vous sûr de vouloir supprimer cet élément ?");
      if (res) {
        this.$axios.delete("/api/types/parc/" + item.id).then(() => {
          this.getParcTypes();
          this.$forceUpdate();
        });
      }
    },
    saveType() {
      if (this.newType.type.id === null) {
        this.$simpleToast.error("Parc", "Le métier n'est pas renseigné");
        return;
      }
      if (this.newType.id) {
        this.$axios.patch("/api/types/parc/" + this.newType.id, { data: this.newType }).then(() => {
          this.getParcTypes();
          this.$simpleToast.success("Parc", "L'élément a bien été enregistré");
          this.addTypes = false;
        });
      } else {
        this.$axios.post("/api/types/parc", { data: this.newType }).then(() => {
          this.getParcTypes();
          this.$simpleToast.success("Parc", "L'élément a bien été enregistré");
          this.addTypes = false;
        });
      }
    },
    getTypes() {
      this.$axios("/api/types/infra").then((resp) => {
        this.types = resp.data;
      });
    },
    getParcTypes() {
      let tmp = {};
      this.$axios("/api/types/parc").then((resp) => {
        resp.data.parc.forEach((item) => {
          if (!tmp[item.type.id]) {
            tmp[item.type.id] = [];
          }
          tmp[item.type.id].push(item);
        });
        this.count = resp.data.count;
        this.parcTypes = tmp;
        this.infos = resp.data.infos;
        this.$forceUpdate();
      });
    },
    addItem(base) {
      base.name = "";
      base.active = 1;
      base.filterId = null;
      this.editableItem = base;
      this.editModal = true;
    },
    async deleteItem(item) {
      const res = await this.$confirm("Êtes-vous sur ?", { title: "Confirmation", color: "primary" });
      if (res) {
        this.$axios.delete("/api/infrastructures/type/" + item.id).then(() => {
          this.$simpleToast.success("Parc", "L'élément a bien été suprimé");
          this.getParcTypes();
          this.$forceUpdate();
        });
      }
    },
    saveItem() {
      this.editors = [];
      this.models = [];
      this.links = [];

      if (this.editableItem.id) {
        this.$axios.patch("/api/infrastructures/type/" + this.editableItem.id, { data: this.editableItem }).then(() => {
          this.$simpleToast.success("Parc", "L'élément a bien été mis à jour");
          this.editModal = false;
          this.getParcTypes();
        });
      } else {
        this.$axios.post("/api/infrastructures/type", { data: this.editableItem }).then(() => {
          this.$simpleToast.success("Parc", "L'élément a bien été ajouté");
          this.editModal = false;
          this.getParcTypes();
        });
      }
      this.getParcTypes();
    },
    editItem(item) {
      this.editableItem = JSON.parse(JSON.stringify(item));
      this.editModal = true;
    },
  },
};
</script>

<style scoped></style>
