<template>
  <v-container fluid>
    <Landscape />
    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="fas fa-angle-down" class="text-uppercase blue-grey--text text--darken-2">
          Toutes les catégories
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <template v-for="setting in settingsList">
              <v-col cols="6" md="2" sm="4" :key="setting.name" v-if="isGranted(setting.role)">
                <v-card
                  hover
                  class="mx-auto text-center"
                  @click="setCurrentSetting(setting)"
                  :to="'/settings/' + setting.path"
                  :class="{ activeCard: setting === currentSetting }"
                  elevation="1"
                >
                  <v-card-text>
                    <v-icon x-large>{{ setting.icon }} fa-fw</v-icon>
                    <div class="text--primary mt-6">
                      {{ setting.name }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row>
      <v-col class="mt-8">
        <h2 class="text-uppercase primary--text pl-3" v-if="currentSetting">{{ currentSetting.name }}</h2>
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Tools from "../../mixins/Tools";
import { msalMixin } from "vue-msal";
import { settingsListData } from "./settingsList.js";
import Landscape from "../../components/common/Landscape.vue";

export default {
  name: "Settings",
  mixins: [Tools, msalMixin],
  data() {
    return {
      panel: 0,
      settingsList: settingsListData,
      currentSetting: settingsListData.find((s) => s.name === this.$route.name) || settingsListData[0],
    };
  },
  methods: {
    setCurrentSetting(setting) {
      this.currentSetting = setting;
    },
  },
  components: { Landscape },
};
</script>

<style scoped>
.activeCard {
  background-color: #eceff1;
}
</style>
