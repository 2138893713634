<template>
  <div style="margin-top: -25px">
    <landscape />
    <modal v-model="modalSettings" max-width="1024">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Critères</strong>
      </template>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6" class="text-right">
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedStart"
                  label="Date de début"
                  persistent-hint
                  prepend-icon="far fa-calendar-alt"
                  v-bind="attrs"
                  hide-details
                  outlined
                  @blur="dateStart = parseDate(dateFormattedStart)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateStart" outlined @input="menu1 = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6" class="text-left">
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormattedEnd"
                  label="Date de fin"
                  persistent-hint
                  prepend-icon="far fa-calendar-alt "
                  v-bind="attrs"
                  hide-details
                  outlined
                  @blur="dateEnd = parseDate(dateFormattedEnd)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateEnd" outlined @input="menu2 = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col col="12" class="text-left">
            <list-user :multiple="false" @selectChange="setAsUser" includeAll></list-user>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:actions>
        <v-btn color="primary" variant="flat" elevation="0" depressed class="rounded-1 px-3" small @click="getEvents()">Générer</v-btn>

        <v-btn color="secondary" text class="mr-4" @click="modalSettings = false"> Fermer </v-btn>
      </template>
    </modal>

    <v-card elevation="1">
      <v-toolbar color="primary" elevation="0" dark>
        <v-row class="p-0 m-0 text-left">
          <v-col class="p-0 m-0 text-left">
            <strong class="mb-0 text-uppercase">Statistiques cross-selling</strong>
            <br /><small class="mt-0 grey--text text--lighten-4">Du {{ dateFormattedStart }} au {{ dateFormattedEnd }}</small>

            <div
              style="
                position: absolute;
                top: 0;
                left: 50%;
                background: #78909c;
                padding: 5px;
                translate: -50% 0;

                border-radius: 0 0 5px 5px;
              "
            >
              <v-btn small text @click="modalSettings = true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-filter </v-icon>
                  </template>
                  <span>Critères</span>
                </v-tooltip>
              </v-btn>

              <v-menu offset-y style="z-index: 1111111">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text small v-bind="attrs" v-on="on"> <v-icon small> fa-ellipsis-v fa-fw </v-icon></v-btn>
                </template>
                <v-list style="z-index: 1111111">
                  <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICS')" @click="$router.push({ name: 'Statistiques Personnelles' })">
                    <v-list-item-title>Mes statistiques</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('STATS_COM')" @click="$router.push({ name: 'Statistiques Commerciaux' })">
                    <v-list-item-title>Statistiques commerciaux</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICSAGENCY')" @click="$router.push({ name: 'Statistiques Agence' })">
                    <v-list-item-title>Statistiques de mon agence</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICSPERS')" @click="$router.push({ name: 'Statistiques BU' })">
                    <v-list-item-title>Statistiques de mon service</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('EVENTS_EVENTSKEY')" @click="$router.push({ name: 'Relations' })">
                    <v-list-item-title>Relation clients</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isGranted('SECTOR_STATISTICS')" @click="$router.push({ name: 'Affectations' })">
                    <v-list-item-title>Affectations</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table :headers="headers" :items="events" :items-per-page="20" @click:row="loadItem" class="elevation-1" :loading="loading">
        <template v-slot:item.event.start="{ item }">
          {{ dayjs(item.event.start).format("DD/MM/YYYY") }}
        </template>
        <template v-slot:item.user.firstname="{ item }"> {{ item.user.firstname }} {{ item.user.lastname }} </template>
        <template v-slot:item.event.status="{ item }">
          <template v-if="item.event.status === 0"> En attente </template>
          <template v-else-if="item.event.status === 1"> Réalisé </template>
          <template v-else> Annulé </template>
        </template>
        <template v-slot:item.opp="{ item }">
          <v-chip v-if="item.event.opportunity" label small @click.stop="loadOpp(item.event.opportunity.id)">{{ item.event.opportunity.id }}</v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Tools from "../../mixins/Tools";
import Modal from "@/components/common/modal.vue";
import Landscape from "../../components/common/Landscape.vue";
import ListUser from "@/components/user/listUser.vue";

export default {
  name: "statisticsCrossSelling",
  components: { Modal, Landscape, ListUser },
  mixins: [Tools],
  data() {
    return {
      headers: [
        { text: "Date du phoning", value: "event.start" },
        { text: "Client", value: "entreprise.name" },
        { text: "Commercial", value: "user.firstname" },
        { text: "État", value: "event.status" },
        { text: "Lié à une opportunité", value: "opp" },
      ],
      modalSettings: false,
      loading: false,
      events: [],
      dateStart: this.parseDate(dayjs().subtract(1, "month").format("DD/MM/YYYY")),
      dateFormattedStart: dayjs().subtract(1, "month").format("DD/MM/YYYY"),
      menu1: false,
      menu2: false,
      dateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      dateFormattedEnd: this.formatDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)),
      user: "",
    };
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    loadItem(item) {
      if (this.isGranted("CUSTOMERS_VIEW") && item.entreprise.customers.id) {
        this.$router.push({ name: "Client", params: { id: item.entreprise.customers.id } });
      }
    },
    loadOpp(id) {
      this.$router.push({ name: "Opportunité", params: { id: id } });
    },
    getEvents() {
      this.loading = true;
      this.$axios("/api/stats/cross-selling", { params: { start: this.dateStart, end: this.dateEnd, user: this.user } }).then((resp) => {
        this.modalSettings = false;
        this.loading = false;

        this.events = resp.data;
      });
    },
    setAsUser(item) {
      this.user = item;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    dateStart() {
      this.dateFormattedStart = this.formatDate(this.dateStart);
    },
    dateEnd() {
      this.dateFormattedEnd = this.formatDate(this.dateEnd);
    },
  },
};
</script>

<style scoped></style>
