var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    { staticClass: "mb-5 pb-7", attrs: { title: "Saison" } },
    [
      _c(
        "v-list",
        [
          _c("challenge-tables-header", { attrs: { season: true } }),
          _vm._l(_vm.chall, function (item, i) {
            return _c(
              "div",
              { key: i },
              [
                _c(
                  "v-list-item",
                  {
                    style: {
                      borderLeft:
                        "solid 5px " +
                        (item.colorType ? item.colorType : "grey"),
                    },
                  },
                  [
                    _c("v-col", { attrs: { cols: "1" } }, [
                      _vm._v(_vm._s(i + 1)),
                    ]),
                    _c(
                      "v-list-item-avatar",
                      [
                        _c("UserAvatar", {
                          attrs: { userId: item.user, size: "40" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "py-0 pl-6 text-left d-none d-sm-flex",
                                staticStyle: { "font-size": "0.9rem" },
                                attrs: { cols: "3" },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 text-right",
                                attrs: { cols: "3", sm: "2" },
                              },
                              [
                                item.user === _vm.$store.state.user.id ||
                                _vm.isGranted("CHALLENGES_VIEW_AMOUNT")
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            Math.round(item.ca).toLocaleString(
                                              "fr"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 text-right",
                                attrs: { cols: "2", sm: "2" },
                              },
                              [
                                item.user === _vm.$store.state.user.id ||
                                _vm.isGranted("CHALLENGES_VIEW_AMOUNT")
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            Math.round(
                                              item.gain
                                            ).toLocaleString("fr")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 text-right",
                                attrs: { cols: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.nb) +
                                    " / " +
                                    _vm._s(_vm.challenge.classement.nb_week) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 text-right",
                                attrs: { cols: "2" },
                              },
                              [
                                _vm._v(
                                  " +" +
                                    _vm._s(
                                      Math.round(item.points).toLocaleString(
                                        "fr"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-list-item-icon", { attrs: { width: "24" } }, [
                      i === 0
                        ? _c("img", {
                            attrs: {
                              src: "/images/challenge/helmet--gold.svg",
                              width: "24",
                            },
                          })
                        : _vm._e(),
                      i === 1
                        ? _c("img", {
                            attrs: {
                              src: "/images/challenge/helmet--silver.svg",
                              width: "24",
                            },
                          })
                        : _vm._e(),
                      i === 2
                        ? _c("img", {
                            attrs: {
                              src: "/images/challenge/helmet--bronze.svg",
                              width: "24",
                            },
                          })
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c("v-divider"),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }