<template>
  <MaterialCard class="mt-9" title="Description" icon="fa-pen">
    <v-form>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="my-0 py-0 mt-2">
            <v-textarea
              :disabled="!isGranted('OPPORTUNITIES_EDIT') || !opp.opened"
              name="input-7-1"
              label="Description"
              outlined
              v-model="opp.description"
              class="ma-0"
              row="10"
              auto-grow
              style="min-height: 100px; font-size: 0.85rem !important"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </MaterialCard>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import MaterialCard from "../../components/common/MaterialCard";
import Tools from "../../mixins/Tools";
import dayjs from "dayjs";

export default {
  name: "descriptionOpportunities",
  components: { MaterialCard },
  mixins: [simpleDebounce, Tools],
  props: ["value"],
  data() {
    return {
      edit: false,
      opportunity: null,
      opp: {
        id: null,
        name: "",
        deadline: dayjs().format("YYYY-MM-DD"),
        job: null,
        rank: null,
        amount: "",
        probability: 0,
        description: "",
      },
      categories: [],
      types: [],
      load: false,
    };
  },
  mounted() {
    this.getTypes();
    this.opp = {
      description: this.value.opp.note,
      opened: this.value.opp.opened,
    };
    setTimeout(() => {
      this.load = true;
    }, 1000);
  },
  methods: {
    getTypes() {
      this.$axios.get("/api/types/active").then((res) => {
        res.data.types.forEach((item) => {
          this.types.push({ state: item.name, abbr: item.id });
        });
        Object.keys(res.data.cat).forEach((items) => {
          this.categories[items] = [];
          res.data.cat[items].forEach((item) => {
            this.categories[items].push({ state: item.name, abbr: item.id });
          });
        });
      });
    },
    saveIt(toast = true) {
      if (this.opp.amount < 0) {
        this.$simpleToast.error("Opportunité", "Le montant doit être positif ou null");
        return;
      }
      this.$axios.patch("/api/opportunities/" + this.opp.id, { data: this.opp }).then(() => {
        if (toast) {
          this.$simpleToast.success("Opportunité", "Les modifications ont été enregistrées");
        }
        this.edit = false;
        this.$root.$emit("save");
      });
    },
  },
  watch: {
    opportunity() {
      this.$emit("input", this.opportunity);
    },
    value() {
      this.opportunity = this.value;
      this.opp = {
        description: this.value.opp.note,
        opened: this.value.opp.opened,
      };
      setTimeout(() => {
        this.load = true;
      }, 1000);

      this.edit = false;
    },
    opp: {
      handler() {
        this.$root.$emit("description-change", this.opp.description);
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
