<template>
  <v-container fluid>
    <landscape />
    <modal v-model="openFilter">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Filtrer</strong>
      </template>
      <user-list v-if="isGranted('OPPORTUNITIES_INDEX_ALLSERVICE')" label="Voir les comptes d'un utilisateur" @selectChange="setAsUser"></user-list>

      <v-switch label="Voir uniquement mes comptes" v-model="search.affect"></v-switch>
    </modal>
    <v-card style="z-index: 9" elevation="1">
      <v-toolbar color="primary" dark elevation="0">
        <v-card-title>
          LISTES DES ABONNEMENTS AVEC LEURS ARTICLES

          <div
            :style="
              isMobile()
                ? {
                    marginLeft: '20px',
                  }
                : {
                    position: 'absolute',
                    top: '0',
                    left: '50%',
                    background: '#78909c',
                    padding: '5px',
                    transform: 'translate(-50%)',
                    borderRadius: '0 0 5px 5px',
                  }
            "
          >
            <v-btn text @click="openFilter = true" small>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-filter </v-icon>
                </template>
                <span>Filtrer</span>
              </v-tooltip>
            </v-btn>

            <v-menu content-class="mt-2" :close-on-content-click="false" offset-y right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-cog </v-icon>
                    </template>
                    <span>Colonnes</span>
                  </v-tooltip>
                </v-btn>
              </template>

              <v-card>
                <v-toolbar color="primary" dark elevation="0" class="py-0" height="45px"> Colonnes visibles </v-toolbar>
                <v-card-text class="pa-1 pb-3" style="min-width: 180px; max-height: calc(100vh - 115px); overflow-y: auto">
                  <v-list>
                    <v-list-item style="min-height: 40px" v-for="(item, key) in columnsAvailable" :key="key" @click="toggleItem(item, key)">
                      <v-list-item-title>
                        <v-icon color="primary" v-if="checkArrayContains(item.value, headers)" small>far fa-fw fa-check-square</v-icon>
                        <v-icon v-else small>far fa-fw fa-square</v-icon>
                        <span class="ml-1">{{ item.text }}</span>
                      </v-list-item-title>
                      <v-divider></v-divider>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>

          <v-spacer></v-spacer>
        </v-card-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="listSubs"
        :page="page"
        :search.sync="researchInfra"
        :items-per-page="limitItem"
        :loading="isLoading"
        class="elevation-0"
        style="z-index: 1000"
        calculate-widths
        :server-items-length="maxItem"
        :footer-props="{ disableItemsPerPages: true }"
        @update:page="setPage"
        @click:row="loadSub"
        @update:items-per-page="setItemMax"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
      >
        <template v-slot:item.line.arRef="{ item }">
          <v-chip class="mx-1" x-small label v-for="(line, key) in item.line" :key="key">{{ line.arRef }}</v-chip>
        </template>
        <template v-slot:item.line.alDesign="{ item }">
          <div v-for="(line, key) in item.line" :key="key">
            <v-chip class="ma-1" x-small label>{{ line.alDesign }}</v-chip>
          </div>
        </template>
        <template v-slot:item.line.numLicence="{ item }">
          <div v-for="(line, key) in item.line" :key="key">
            <v-chip v-if="line.numLicence" class="ma-1" x-small label>{{ line.numLicence }}</v-chip>
          </div>
        </template>
        <template v-slot:item.line.version="{ item }">
          <div v-for="(line, key) in item.line" :key="key">
            <v-chip v-if="line.version" class="ma-1" x-small label>{{ line.version }}</v-chip>
          </div>
        </template>
        <template v-slot:item.item.startDate="{ item }">
          {{ dayjs(item.item.startDate).format("DD/MM/YYYY") }}
        </template>
        <template v-slot:item.item.endDate="{ item }">
          {{ dayjs(item.item.endDate).format("DD/MM/YYYY") }}
        </template>
        <template v-slot:body.prepend>
          <tr>
            <td v-if="checkArrayContains('customer', headers)">
              <v-text-field v-model="search.customer" type="text" label="Filtrer"></v-text-field>
            </td>
            <td v-if="checkArrayContains('item.abModele', headers)">
              <v-select
                :menu-props="{ maxHeight: '300' }"
                small-chips
                deletable-chips
                v-model="searchSubType"
                :items="typeAbos"
                multiple
                style="z-index: 150"
                label="Filtrer"
              ></v-select>
            </td>
            <td v-if="checkArrayContains('item.abIntitule', headers)">
              <v-text-field v-model="search.intitule" label="Filtrer" type="text"></v-text-field>
            </td>
            <td v-if="checkArrayContains('item.startDate', headers)">
              <v-menu ref="menu1" v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="search.dateStartFormatted"
                    label="Filtrer"
                    v-bind="attrs"
                    @blur="search.dateStart = parseDate(search.dateStartFormatted)"
                    v-on="on"
                    :append-outer-icon="search.dateStart !== null && search.dateStartFormatted !== null ? 'fa-times' : ''"
                    @click:append-outer="
                      search.dateStart = null;
                      search.dateStartFormatted = null;
                    "
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.dateStart" no-title @input="menuStart = false"></v-date-picker>
              </v-menu>
            </td>
            <td v-if="checkArrayContains('item.endDate', headers)">
              <v-menu ref="menu1" v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="search.dateEndFormatted"
                    label="Filtrer"
                    v-bind="attrs"
                    @blur="search.dateEnd = parseDate(search.dateEndFormatted)"
                    v-on="on"
                    :append-outer-icon="search.dateEnd !== null && search.dateEndFormatted !== null ? 'fa-times' : ''"
                    @click:append-outer="
                      search.dateEnd = null;
                      search.dateEndFormatted = null;
                    "
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.dateEnd" no-title @input="menuEnd = false"></v-date-picker>
              </v-menu>
            </td>
            <td v-if="checkArrayContains('line.arRef', headers)">
              <v-autocomplete
                class="pt-3"
                :menu-props="{ maxHeight: '300' }"
                item-text="arRef"
                item-value="arRef"
                small-chips
                deletable-chips
                v-model="search.arRefArr"
                :search-input.sync="searchCodeSubs"
                :items="codeSubs"
                multiple
                style="z-index: 150"
                label="Code article"
              ></v-autocomplete>
            </td>
            <td v-if="checkArrayContains('user', headers)">
              <v-text-field v-model="search.user" type="text" label="Filtrer" style="max-width: 200px"></v-text-field>
            </td>
            <td v-if="checkArrayContains('line.alDesign', headers)">
              <v-text-field v-model="search.alDesign" type="text" label="Filtrer" style="max-width: 200px"></v-text-field>
            </td>
            <td v-if="checkArrayContains('sector', headers)">
              <v-text-field v-model="search.sector" type="text" label="Filtrer" style="max-width: 200px"></v-text-field>
            </td>
            <td v-if="checkArrayContains('city', headers)">
              <v-text-field v-model="search.city" type="text" label="Filtrer" style="max-width: 200px"></v-text-field>
            </td>
            <td v-if="checkArrayContains('line.numLicence', headers)">
              <v-text-field v-model="search.numLicence" type="text" label="Filtrer" style="max-width: 200px"></v-text-field>
            </td>
            <td v-if="checkArrayContains('line.version', headers)">
              <v-text-field v-model="search.version" type="text" label="Filtrer" style="max-width: 200px"></v-text-field>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Tools from "../../mixins/Tools";
import Modal from "../../components/common/modal";
import UserList from "../../components/user/listUser.vue";
import Landscape from "../../components/common/Landscape.vue";
import MobileDetection from "../../mixins/MobileDetection";

export default {
  name: "listSubscriptions",
  components: { Modal, UserList, Landscape },
  mixins: [simpleDebounce, Tools, MobileDetection],
  data() {
    return {
      openFilter: false,
      mobile: false,
      search: {
        dateStartFormatted: null,
        dateEndFormatted: null,
        asUser: null,
        arRef: "",
        alDesign: "",
        intitule: "",
        sortedBy: null,
        sortedDesc: false,
        dateStart: null,
        customer: "",
        dateEnd: null,
        arRefArr: [],
        affect: false,
        user: null,
        version: "",
        numLicence: "",
      },
      menuStart: false,
      menuEnd: false,
      limitItem: 10,
      maxItem: 0,
      page: 1,
      isLoading: false,
      listSubs: [],
      researchInfra: "",
      sortedBy: null,
      searchSubType: [],
      typeAbos: [],
      searchCodeSubs: "",
      sortedDesc: false,
      columnsAvailable: [
        { text: "Client", value: "customer" },
        { text: "Type", value: "item.abModele" },
        { text: "Intitulé", value: "item.abIntitule" },
        { text: "Date de début", value: "item.startDate" },
        { text: "Date de fin", value: "item.endDate" },
        { text: "Code Article", value: "line.arRef" },
        { text: "Commercial", value: "user" },
        { text: "Intitulé article", value: "line.alDesign" },
        { text: "Secteur", value: "sector" },
        { text: "Localisation", value: "city" },
        { text: "Numéro Licence", value: "line.numLicence" },
        { text: "Version", value: "line.version" },
      ],
      headers: [
        { text: "Client", value: "customer" },
        { text: "Type", value: "item.abModele" },
        { text: "Intitulé", value: "item.abIntitule" },
        { text: "Date de début", value: "item.startDate" },
        { text: "Date de fin", value: "item.endDate" },
        { text: "Code Article", value: "line.arRef" },
        { text: "Commercial", value: "user" },
        { text: "Intitulé article", value: "line.alDesign" },
        { text: "Numéro Licence", value: "line.numLicence", sortable: false },
        { text: "Version", value: "line.version", sortable: false },
      ],
      codeSubs: [],
    };
  },
  mounted() {
    this.mobile = this.isMobile();
    let search = sessionStorage.getItem("searchSubscriptions");
    if (search) {
      this.search = JSON.parse(search);
    }
    this.getSubs();
    this.getSubsInfos();
  },
  beforeDestroy() {
    sessionStorage.setItem("searchSubscriptions", JSON.stringify(this.search));
  },
  methods: {
    getCodeArticle() {
      this.$axios("/api/subscriptions/codeArticle", { params: { search: this.searchCodeSubs } }).then((resp) => {
        this.codeSubs = resp.data;
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getSubsInfos() {
      this.$axios.get("/api/subscriptions/compaignInfo").then((resp) => {
        this.typeAbos = resp.data.type;
      });
    },
    loadSub(row) {
      if (this.isGranted("CUSTOMERS_VIEW")) {
        this.$router.push({ name: "Client", params: { id: row.custoId } });
      }
    },
    toggleItem(item, key) {
      let index = this.headers.findIndex((x) => x.value === item.value);
      let tmp = key + 1;
      let valI = -1;
      while (valI === -1 && tmp < this.columnsAvailable.length) {
        valI = this.headers.findIndex((x) => x.value === this.columnsAvailable[tmp].value);
        tmp += 1;
      }
      if (index === -1) {
        if (key === 0) {
          this.headers.splice(0, 0, item);
        } else if (key === this.columnsAvailable.length - 1 || valI === -1) {
          this.headers.push(this.columnsAvailable[key]);
        } else {
          this.headers.splice(valI, 0, item);
        }
      } else {
        this.headers.splice(index, 1);
      }
      this.$forceUpdate();
    },
    getSubs() {
      this.isLoading = true;
      let search = this.search;
      let page = this.page;
      this.listSubs = [];

      this.$axios("/api/subscriptions", {
        params: { page: page, search, limit: this.limitItem, asUser: this.asUser, searchSubType: this.searchSubType },
      }).then((res) => {
        this.listSubs = res.data.data;
        this.maxItem = res.data.max;
        this.isLoading = false;
      });
    },
    setItemMax(event) {
      this.limitItem = event;
    },
    setPage(event) {
      this.page = event;
    },
    setAsUser(event) {
      this.search.asUser = event.length > 0 ? event : null;
    },
    getSubsDebounce() {
      this.simpleDebounce(() => {
        this.getSubs();
      }, 500);
    },
    getSubsDebouncePage1() {
      this.page = 1;
      this.simpleDebounce(() => {
        this.getSubs();
      }, 500);
    },
  },
  watch: {
    mobile() {
      this.headers = [
        { text: "Client", value: "customer" },
        // { text: "Type", value: "item.abModele" },
        // { text: "Intitulé", value: "item.abIntitule" },
        // { text: "Date de début", value: "item.startDate" },
        // { text: "Date de fin", value: "item.endDate" },
        // { text: "Code Article", value: "line.arRef" },
        { text: "Commercial", value: "user" },
        { text: "Intitulé article", value: "line.alDesign" },
      ];
    },
    searchCodeSubs: "getCodeArticle",
    searchSubType: "getSubsDebounce",
    page: "getSubs",
    "search.asUser": "getSubsDebouncePage1",
    "search.user": "getSubsDebouncePage1",
    "search.intitule": "getSubsDebouncePage1",
    "search.customer": "getSubsDebouncePage1",
    "search.arRef": "getSubsDebouncePage1",
    "search.affect": "getSubsDebouncePage1",
    "search.arRefArr": "getSubsDebouncePage1",
    "search.sector": "getSubsDebouncePage1",
    "search.city": "getSubsDebouncePage1",
    "search.alDesign": "getSubsDebouncePage1",
    "search.numLicence": "getSubsDebouncePage1",
    "search.version": "getSubsDebouncePage1",
    limitItem: "getSubsDebounce",
    "search.dateStart"() {
      this.search.dateStartFormatted = this.formatDate(this.search.dateStart);
      this.getSubsDebouncePage1();
    },
    "search.dateEnd"() {
      this.search.dateEndFormatted = this.formatDate(this.search.dateEnd);
      this.getSubsDebouncePage1();
    },
    sortedBy() {
      this.search.sortedBy = this.sortedBy;
      this.getSubsDebounce();
    },
    sortedDesc() {
      this.search.sortedDesc = this.sortedDesc;
      this.getSubsDebounce();
    },
  },
};
</script>

<style scoped></style>
