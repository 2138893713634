<template>
  <v-container fluid>
    <v-card style="z-index: 99" elevation="1" v-if="isGranted('CONCURRENTS_INDEX')">
      <v-toolbar color="primary" dark elevation="0">
        <div class="listbox--actions-add">
          <v-btn small fab color="primary" dark @click="modalForm = true">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-plus </v-icon>
              </template>
              <span>Ajouter</span>
            </v-tooltip>
          </v-btn>
        </div>
        <v-card-title>
          Liste des concurrents

          <div
            :style="
              isMobile()
                ? {
                    marginLeft: '20px',
                  }
                : {
                    position: 'absolute',
                    top: '0',
                    left: '50%',
                    background: '#78909c',
                    padding: '5px',
                    transform: 'translate(-50%)',
                    borderRadius: '0 0 5px 5px',
                  }
            "
          >
            <v-menu content-class="mt-2" :close-on-content-click="false" offset-y right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on"> fa-fw fa-cog </v-icon>
                    </template>
                    <span>Colonnes</span>
                  </v-tooltip>
                </v-btn>
              </template>

              <v-card>
                <v-toolbar color="primary" dark elevation="0" class="py-0" height="45px"> Colonnes visibles </v-toolbar>
                <v-card-text class="pa-1 pb-3" style="min-width: 180px; max-height: calc(100vh - 115px); overflow-y: auto">
                  <v-list>
                    <v-list-item style="min-height: 40px" v-for="(item, key) in columnsAvailable" :key="key" @click="toggleItem(item, key)">
                      <v-list-item-title>
                        <v-icon color="primary" v-if="checkArrayContains(item.value, headers)" small>far fa-fw fa-check-square </v-icon>
                        <v-icon v-else small>far fa-fw fa-square</v-icon>
                        <span class="ml-1">{{ item.text }}</span>
                      </v-list-item-title>
                      <v-divider></v-divider>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </v-card-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="concurrentsList"
        :server-items-length="maxItem"
        :items-per-page="limitItem"
        class="elevation-0"
        :page="page"
        @update:items-per-page="setItemMax"
        @update:page="setPage"
        @click:row="(item) => setConcurrent(item, false)"
        @update:options="setPageOptions"
        :loading="loading"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
        :options="tableOptions"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 15, 25, 50],
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right',
        }"
        :hide-default-header="isMobile()"
      >
        <template v-slot:item.entreprise.adresse.postalCode="{ item }">
          <template v-if="item.entreprise.adresse"> {{ item.entreprise.adresse.postalCode }} {{ item.entreprise.adresse.city }}</template>
        </template>

        <template v-slot:item.entreprise.concurrents.prestataire="{ item }" style="text-align: center">
          <v-icon v-if="item.entreprise.concurrents.prestataire" color="success"> fa-fw fa-check </v-icon>
          <v-icon v-else color="error"> fa-fw fa-times </v-icon>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small @click.stop="setConcurrent(item, true)" class="mr-2"> fa-fw fa-edit </v-icon>
        </template>

        <template v-slot:body.prepend>
          <tr>
            <td v-if="checkArrayContains('entreprise.concurrents.id', headers)">id</td>
            <td v-if="checkArrayContains('entreprise.name', headers)">
              <v-text-field v-model="search.name" type="text" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('entreprise.siret', headers)">
              <v-text-field v-model="search.siret" type="text" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('entreprise.adresse.postalCode', headers)">
              <v-text-field v-model="search.cityPostalCode" label="Rechercher" type="text"></v-text-field>
            </td>
            <td v-if="checkArrayContains('entreprise.concurrents.prestataire', headers)" style="width: 75px">
              <v-select v-model="search.prestataire" :items="selectListPrestataire" style="width: 75px"></v-select>
            </td>
            <td v-if="checkArrayContains('entreprise.phone', headers)">
              <v-text-field v-model="search.phone" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('entreprise.ape', headers)">
              <v-text-field v-model="search.ape" label="Rechercher"></v-text-field>
            </td>
            <td v-if="checkArrayContains('actions', headers)"></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <editFormConcurrent v-model="modalForm" @refresh="getconcurrentsList" :concurrentData="concurrent"></editFormConcurrent>
    <viewConcurrent v-model="modalView" :concurrentData="concurrent"></viewConcurrent>
  </v-container>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import editFormConcurrent from "./editFormConcurrent.vue";
import viewConcurrent from "./viewConcurrent.vue";
import Tools from "../../mixins/Tools";
import savePage from "../../mixins/savePage";
import MobileDetection from "../../mixins/MobileDetection";

export default {
  name: "listconcurrents",
  components: { editFormConcurrent, viewConcurrent },
  mixins: [Tools, simpleDebounce, savePage, MobileDetection, editFormConcurrent, viewConcurrent],

  data() {
    return {
      mobile: false,
      modalForm: false,
      modalView: false,
      sortedBy: null,
      concurrent: {
        name: "",
        siret: "",
        address: "",
        address2: "",
        postalCode: "",
        city: "",
        email: "",
        ape: "",
        phone: "",
        phone2: "",
        site: "",
        prestataire: true,
        id: null,
      },
      sortedDesc: false,
      listTypeArray: [],
      selectListPrestataire: [
        { text: "Tous", value: null },
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      limitItem: 10,
      loading: false,
      headers: [
        { text: "Intitulé", value: "entreprise.name", class: "text-no-wrap" },
        { text: "SIRET", value: "entreprise.siret", class: "text-no-wrap" },
        { text: "Localisation", value: "entreprise.adresse.postalCode", class: "text-no-wrap" },
        { text: "Prestataire", value: "entreprise.concurrents.prestataire", align: "center", class: "text-no-wrap" },
        { text: "Actions", value: "actions", sortable: false, align: "end", class: "text-no-wrap" },
      ],
      columnsAvailable: [
        { text: "id", value: "entreprise.concurrents.id", class: "text-no-wrap" },
        { text: "Intitulé", value: "entreprise.name", class: "text-no-wrap" },
        { text: "SIRET", value: "entreprise.siret", class: "text-no-wrap" },
        { text: "Localisation", value: "entreprise.adresse.postalCode", class: "text-no-wrap" },
        { text: "Prestataire", value: "entreprise.concurrents.prestataire", class: "text-no-wrap" },
        { text: "Téléphone", value: "entreprise.phone", class: "text-no-wrap" },
        { text: "APE", value: "entreprise.ape", class: "text-no-wrap" },
        { text: "Actions", value: "actions", sortable: false, align: "end", class: "text-no-wrap" },
      ],
      concurrentsList: [],

      search: {
        name: "",
        siret: "",
        cityPostalCode: "",
        phone: "",
        ape: "",
        sortedBy: null,
        sortedDesc: false,
        prestataire: null,
      },
      maxItem: 0,
      page: 1,
      date: new Date(),
      modalSettings: false,
    };
  },
  mounted() {
    this.getData("listconcurrents");
    this.mobile = this.isMobile();
    let search = sessionStorage.getItem("searchConcu");
    let headers = sessionStorage.getItem("headersConcu");
    if (headers) {
      this.headers = JSON.parse(headers);
    }
    if (search) {
      this.search = JSON.parse(search);
    }

    this.getconcurrentsList();
    this.loadType();
  },
  methods: {
    openTargetModal() {
      const concurrentName = JSON.parse(sessionStorage.getItem("concurrentModalTarget"));
      if (!concurrentName) return;

      this.$axios("/api/concurrents/list", {
        params: {
          page: this.page,
          search: { ...this.search, name: concurrentName },
          limit: this.limitItem,
        },
      }).then((resp) => {
        const concurrents = resp.data.data;
        const findedConcurrent = concurrents.find((concurrent) => concurrent.entreprise.name == concurrentName);
        this.setConcurrent(findedConcurrent, false);
      });
      this.modalView = true;
      sessionStorage.removeItem("concurrentModalTarget");
    },
    toggleItem(item, key) {
      let index = this.headers.findIndex((x) => x.value === item.value);
      let tmp = key + 1;
      let valI = -1;
      while (valI === -1 && tmp < this.columnsAvailable.length) {
        valI = this.headers.findIndex((x) => x.value === this.columnsAvailable[tmp].value);
        tmp += 1;
      }
      if (index === -1) {
        if (key === 0) {
          this.headers.splice(0, 0, item);
        } else if (key === this.columnsAvailable.length - 1 || valI === -1) {
          this.headers.push(this.columnsAvailable[key]);
        } else {
          this.headers.splice(valI, 0, item);
        }
      } else {
        this.headers.splice(index, 1);
      }

      sessionStorage.setItem("headersConcu", JSON.stringify(this.headers));
      this.$forceUpdate();
    },
    loadType() {
      this.$axios("/api/types/active").then((res) => {
        this.listType = res.data.types;

        Object.keys(res.data.types).forEach((item) => {
          this.listTypeArray.push(res.data.types[item]);
        });
      });
    },
    setAsUser(event) {
      this.asUser = event;
    },
    setConcurrent(item, edit = false) {
      this.concurrent = {
        name: item?.entreprise?.name,
        ape: item?.entreprise?.ape,
        siret: item?.entreprise?.siret,
        address: item?.entreprise?.adresse?.address,
        address2: item?.entreprise?.adresse?.address2,
        postalCode: item?.entreprise?.adresse?.postalCode,
        city: item?.entreprise?.adresse?.city,
        email: item?.entreprise?.email,
        phone: item?.entreprise?.phone,
        phone2: item?.entreprise?.phone2,
        site: item?.entreprise?.site,
        active: 1,
        prestataire: item?.entreprise?.concurrents?.prestataire,
        id: item?.entreprise?.concurrents?.id,
      };
      if (edit) {
        this.modalForm = true;
      } else {
        this.concurrent.allInfo = item;
        this.modalView = true;
      }
    },
    setItemMax(event) {
      this.limitItem = event;
    },
    setPage(event) {
      this.page = event;
    },
    getconcurrentsList() {
      this.loading = true;
      this.concurrentsList = [];
      this.$axios("/api/concurrents/list", {
        params: {
          page: this.page,
          search: this.search,
          limit: this.limitItem,
        },
      }).then((resp) => {
        this.maxItem = resp.data.max;
        this.concurrentsList = resp.data.data;
        this.openTargetModal();
      });
      this.loading = false;
      sessionStorage.setItem("searchConcu", JSON.stringify(this.search));
      sessionStorage.setItem("headersConcu", JSON.stringify(this.headers));
      this.setData("listconcurrents");
    },
    getconcurrentsListDebounce() {
      this.simpleDebounce(() => {
        this.getconcurrentsList();
      }, 300 /* optional debounce time: default is 300 */);
    },
  },
  watch: {
    mobile() {
      this.headers = [
        { text: "Intitulé", value: "entreprise.name", class: "text-no-wrap" },
        { text: "Téléphone", value: "entreprise.phone", class: "text-no-wrap" },
      ];
    },

    "search.name"() {
      this.page = 1;
      this.getconcurrentsListDebounce();
    },
    "search.prestataire"() {
      this.page = 1;
      this.getconcurrentsListDebounce();
    },
    "search.phone"() {
      this.page = 1;
      this.getconcurrentsListDebounce();
    },
    "search.ape"() {
      this.page = 1;
      this.getconcurrentsListDebounce();
    },
    "search.siret"() {
      this.page = 1;
      this.getconcurrentsListDebounce();
    },
    "search.cityPostalCode"() {
      this.page = 1;
      this.getconcurrentsListDebounce();
    },
    sortedBy() {
      this.search.sortedBy = this.sortedBy;
      this.getconcurrentsListDebounce();
    },
    sortedDesc() {
      this.search.sortedDesc = this.sortedDesc;
      this.getconcurrentsListDebounce();
    },
    limitItem() {
      this.getconcurrentsList();
    },
    page() {
      this.getconcurrentsList();
    },
  },
};
</script>

<style scoped></style>
