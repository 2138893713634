<template>
  <div class="bannerPreprod">
    <strong class="text">⚠️ Environnement de pré-production ⚠️</strong>
  </div>
</template>

<script>
export default {
  name: "PreprodBanner",
};
</script>

<style scoped>
.bannerPreprod {
  display: flex;
  justify-content: start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 26px;
  background-color: #ff4d4f50;
  color: #fff;
  font-size: 16px;
  z-index: 9999;
}
.text {
  width: 100%;
  animation: textScroll 10s linear infinite;
  white-space: nowrap;
  opacity: 0; /* Définir l'opacité initiale à 1 pour l'apparition */
  transition: opacity 0.5s ease-in-out; /* Ajouter une transition sur l'opacité */
}

@keyframes textScroll {
  0% {
    transform: translateX(-10%);
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    transform: translateX(95%);
    opacity: 0;
  }
}
</style>
