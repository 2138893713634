var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "ma-0 pa-0" },
    [
      _vm.$slots.title
        ? _c(
            "v-col",
            { staticClass: "py-0 mt-5", attrs: { cols: "2" } },
            [_vm._t("title")],
            2
          )
        : _vm._e(),
      _c(
        "v-col",
        {
          staticClass: "py-0 ma-0",
          attrs: { cols: _vm.$slots.title ? 10 : 12 },
        },
        [
          _c("v-autocomplete", {
            attrs: {
              "menu-props": { maxHeight: "300" },
              items: _vm.infras,
              clearable: "",
              chips: "",
              disabled: _vm.disabled,
              color: _vm.labelColor,
              label: _vm.label,
              "item-text": "name",
              "item-value": "id",
              multiple: _vm.multiple,
              "cache-items": "",
              "persistent-placeholder": !!_vm.$slots.title,
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function (data) {
                  return [
                    _c(
                      "v-chip",
                      { attrs: { color: data.item.color } },
                      [
                        _c("v-icon", { attrs: { "x-small": "" } }, [
                          _vm._v(_vm._s(data.item.icon)),
                        ]),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(data.item.name)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item",
                fn: function (data) {
                  return [
                    _c(
                      "v-chip",
                      { attrs: { color: data.item.color } },
                      [
                        _c("v-icon", { attrs: { "x-small": "" } }, [
                          _vm._v(_vm._s(data.item.icon)),
                        ]),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(data.item.name)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.servicesSelected,
              callback: function ($$v) {
                _vm.servicesSelected = $$v
              },
              expression: "servicesSelected",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }