var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    {
      staticClass: "px-5 py-3",
      attrs: { "height-tot": "700px" },
      scopedSlots: _vm._u([
        {
          key: "heading",
          fn: function () {
            return [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            "background-color": "transparent",
                            "slider-color": "white",
                          },
                          model: {
                            value: _vm.tabs,
                            callback: function ($$v) {
                              _vm.tabs = $$v
                            },
                            expression: "tabs",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "subheading font-weight-light mx-3",
                              staticStyle: { "align-self": "center" },
                            },
                            [_vm._v("Opportunités en cours:")]
                          ),
                          _c(
                            "v-tab",
                            { staticClass: "mr-3" },
                            [
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    content: _vm.tasks[0].length
                                      ? _vm.tasks[0].length
                                      : "0",
                                    color: "primary",
                                    overlap: "",
                                  },
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" fa-fingerprint "),
                                  ]),
                                  _c("span", { staticClass: "mr-6" }, [
                                    _vm._v("Identifié"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab",
                            { staticClass: "mr-3" },
                            [
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    content: _vm.tasks[1].length
                                      ? _vm.tasks[1].length
                                      : "0",
                                    color: "primary",
                                    overlap: "",
                                  },
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" fa-search "),
                                  ]),
                                  _c("span", { staticClass: "mr-6" }, [
                                    _vm._v("Étude"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab",
                            [
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    content: _vm.tasks[2].length
                                      ? _vm.tasks[2].length
                                      : "0",
                                    color: "primary",
                                    overlap: "",
                                  },
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" fa-handshake "),
                                  ]),
                                  _c("span", { staticClass: "mr-6" }, [
                                    _vm._v("Négociation"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pt-5 text-md-right", attrs: { cols: "4" } },
                    [
                      _c("v-icon", { on: { click: _vm.newOpp } }, [
                        _vm._v("fa-plus"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-tabs-items",
        {
          staticClass: "transparent",
          model: {
            value: _vm.tabs,
            callback: function ($$v) {
              _vm.tabs = $$v
            },
            expression: "tabs",
          },
        },
        _vm._l(3, function (n) {
          return _c(
            "v-tab-item",
            { key: n },
            [
              _c(
                "v-card-text",
                [
                  _vm.tasks[_vm.tabs].length === 0
                    ? [
                        _c(
                          "v-alert",
                          { attrs: { text: "", prominent: "", type: "info" } },
                          [_vm._v(" Il n'y a rien à afficher. ")]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "v-list",
                    {
                      staticStyle: {
                        "max-height": "440px",
                        "overflow-y": "scroll",
                      },
                    },
                    _vm._l(_vm.tasks[_vm.tabs], function (item, i) {
                      return _c(
                        "div",
                        { key: i },
                        [
                          _c(
                            "v-list-item-group",
                            { attrs: { color: "primary" } },
                            [
                              _c(
                                "v-list-item",
                                {
                                  key: item.opp.name,
                                  attrs: {
                                    to: "/opportunities/edit/" + item.opp.id,
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src:
                                            _vm.$axios.defaults.baseURL +
                                            "/upload/users/" +
                                            item.opp.userId +
                                            ".png",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "8" } },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  item
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "mb-1 mr-2",
                                                          attrs: {
                                                            color: "warning",
                                                            label: "",
                                                            "text-color":
                                                              "white",
                                                            small: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " fa-hashtag "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.opp.id
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      staticClass: "mr-2 mb-1",
                                                      attrs: {
                                                        color: "primary",
                                                        label: "",
                                                        small: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [_vm._v(" fa-user ")]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.customer &&
                                                              item.customer
                                                                .entreprise.name
                                                              ? item.customer
                                                                  .entreprise
                                                                  .name
                                                              : "Pas de client"
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  item.type
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          staticClass: "mb-1",
                                                          attrs: {
                                                            color: "pink",
                                                            label: "",
                                                            "text-color":
                                                              "white",
                                                            small: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                              },
                                                            },
                                                            [_vm._v(" fa-tag ")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.type
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("br"),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.opp.name
                                                          ? item.opp.name
                                                          : "Pas de nom"
                                                      )
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-subtitle",
                                                { staticClass: "font-italic" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm
                                                        .dayjs(
                                                          item.opp.createdAt
                                                        )
                                                        .format("DD/MM/YYYY")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "heading",
                                              attrs: { cols: "12", md: "2" },
                                            },
                                            [
                                              item.opp.amount
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass: "ma-2",
                                                      attrs: {
                                                        color: "success",
                                                        label: "",
                                                        "text-color": "white",
                                                        "x-large": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            " fa-euro-sign "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.opp.amount
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "2" } },
                                            [
                                              _c(
                                                "v-progress-circular",
                                                {
                                                  attrs: {
                                                    rotate: 360,
                                                    size: 100,
                                                    width: 15,
                                                    value: item.opp.probability
                                                      ? item.opp.probability
                                                      : "0",
                                                    color:
                                                      item.opp.probability > 50
                                                        ? "success"
                                                        : "error",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.opp.probability
                                                          ? item.opp
                                                              .probability + "%"
                                                          : "0" + "%"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.tasks[_vm.tabs].length > i
                                ? _c("v-divider")
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                2
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }