<template>
  <material-card id="infos" title="Informations" icon="fa-info">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6" class="py-0">
          <v-row class="py-0">
            <v-col cols="12" md="5" class="py-0">
              <v-text-field outlined v-model="item.name" label="Titre de campagne"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0" v-if="item.company !== 'Individuelle'">
              <v-select
                outlined
                :menu-props="{ maxHeight: '300' }"
                :disabled="item.commando === 1 && !item.draft"
                multiple
                v-model="item.types"
                label="Métier"
                :items="types"
                item-value="id"
                item-text="name"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" class="py-0" v-if="typeList.length > 1">
              <v-select
                outlined
                :menu-props="{ maxHeight: '300' }"
                label="Type de campagne"
                :disabled="Object.keys(item).includes('id') && !item.draft"
                :items="typeList"
                v-model="item.company"
              ></v-select>
            </v-col>
            <v-col cols="12" md="5" class="py-0">
              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStartFormatted"
                    label="Date de début"
                    persistent-hint
                    v-bind="attrs"
                    outlined
                    @blur="item.startDate = parseDate(dateStartFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker first-day-of-week="1" v-model="item.startDate" :min="tomorrow" outlined @input="menu1 = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-menu ref="menu1" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEndFormatted"
                    label="Date de fin"
                    persistent-hint
                    v-bind="attrs"
                    outlined
                    @blur="item.endDate = parseDate(dateEndFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker first-day-of-week="1" v-model="item.endDate" outlined @input="menu2 = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="py-0" v-if="isGranted('COMPAIGN_COMMANDO') && item.company === 'Commando'">
              <v-row class="py-0">
                <v-col cols="12" class="pa-0">
                  <listUser
                    label="Responsables de campagne"
                    multiple
                    includeAll
                    :disabled="item.company !== 'Commando' && !item.draft"
                    @selectChange="setAsUser"
                  ></listUser>
                </v-col>
                <v-col cols="4" class="py-0" v-if="false">
                  <v-checkbox v-model="item.commando" label="Commando"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" v-if="item.company === 'Entreprise'" class="py-0">
              <v-autocomplete
                :menu-props="{ maxHeight: '300' }"
                v-model="item.commercial"
                :items="users"
                chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Utilisateurs participants"
                item-text="fullname"
                item-value="id"
                multiple
                outlined
                persistent-placeholder
                :search-input.sync="search"
                cache-items
              >
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
                    <UserAvatar :userId="data.item.id" left />
                    {{ data.item.firstname + " " + data.item.lastname }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <UserAvatar :userId="data.item.id" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.firstname + " " + data.item.lastname }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="isGranted('COMPAIGN_COMPANY') && item.company === 'Entreprise'">
              <v-row class="py-0">
                <v-col cols="3" class="py-0">
                  <v-switch color="warning" v-model="item.phases[1].enable" :disabled="item.commando === 1 && !item.draft" label="Mailing"></v-switch>
                </v-col>
                <v-col cols="3" class="py-0">
                  <v-switch color="primary" v-model="item.phases[2].enable" :disabled="item.commando === 1 && !item.draft" label="Phoning"></v-switch>
                </v-col>
                <v-col cols="3" class="py-0">
                  <v-switch color="error" v-model="item.phases[3].enable" label="Rencontre"></v-switch>
                </v-col>
                <v-col cols="3" class="py-0">
                  <v-switch color="info" v-model="item.phases[4].enable" label="Génération business"></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" v-if="false">
              <v-switch label="Publié" v-model="item.status" value="1"></v-switch>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <v-row class="py-0">
            <v-col cols="12" class="py-0">
              <v-textarea outlined class="py-0" label="Description" v-model="item.body"></v-textarea>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn color="primary" @click="continuePhase()">
            Continuer
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </material-card>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import dayjs from "dayjs";

import MaterialCard from "../../../../components/common/MaterialCard";
import ListUser from "../../../../components/user/listUser";
import Tools from "../../../../mixins/Tools";
import UserAvatar from "../../../../components/user/UserAvatar.vue";

export default {
  name: "informationsFormCompaign",
  components: { ListUser, MaterialCard, UserAvatar },
  props: ["value"],
  mixins: [Tools, simpleDebounce],
  data() {
    return {
      search: "",
      users: [],
      focus: false,
      item: this.value,
      typeList: [],
      menu1: null,
      menu2: null,
      dateStartFormatted: this.formatDate(dayjs().format("YYYY-MM-DD")),
      dateEndFormatted: this.formatDate(dayjs().format("YYYY-MM-DD")),
      types: [],
    };
  },
  mounted() {
    if (this.isGranted("COMPAIGN_SOLO")) {
      this.typeList.push("Individuelle");
      if (this.item.company === null) {
        this.item.company = "Individuelle";
      }
    }

    if (this.isGranted("COMPAIGN_COMPANY")) {
      this.typeList.push("Entreprise");
      if (this.item.company === null) {
        this.item.company = "Entreprise";
      }
    }

    if (this.isGranted("COMPAIGN_COMMANDO")) {
      this.typeList.push("Commando");
      if (this.item.company === null) {
        this.item.company = "Commando";
      }
    }

    for (const commercial of this.item.commercial) {
      this.search = commercial.toString();
      this.searchUser();
    }

    this.getTypes();
    this.dateStartFormatted = this.formatDate(this.item.startDate);
    this.dateEndFormatted = this.formatDate(this.item.endDate);
  },
  methods: {
    remove(item) {
      const index = this.item.commercial.indexOf(item.id);
      if (index >= 0) this.item.commercial.splice(index, 1);
    },
    setForEvent(item) {
      this.item.commercial = item;
    },
    setAsUser(event) {
      this.item.asUser = event;
    },
    continuePhase() {
      document.getElementById("phases").scrollIntoView({
        behavior: "smooth",
      });
    },
    handleFocus() {},
    getTypes() {
      this.$axios("/api/types/active").then((resp) => {
        this.types = resp.data.types;
      });
    },
    searchUser() {
      this.$axios("/api/users?s=" + this.search, { params: { all: true, active: true } }).then((resp) => {
        this.users = resp.data;
      });
    },
  },
  computed: {
    tomorrow() {
      const d = new Date();
      d.setDate(d.getDate() + 1);
      return d.toISOString();
    },
  },
  watch: {
    search() {
      this.simpleDebounce(() => {
        this.searchUser();
      }, 500);
    },
    "item.commando"() {
      this.item.phases[1].enable = false;
      this.item.phases[2].enable = false;
      this.item.types = [];
    },
    "item.startDate"() {
      this.dateStartFormatted = this.formatDate(this.item.startDate);
    },
    "item.endDate"() {
      this.dateEndFormatted = this.formatDate(this.item.endDate);
    },
    item() {
      this.$emit("input", this.item);
    },
    "item.company"() {
      this.item.customers = [];
      if (this.item.company === "Individuelle") {
        this.item.phases[1].enable = true;
        this.item.phases[2].enable = false;
        this.item.phases[3].enable = false;
        this.item.phases[4].enable = false;
      }
      if (this.item.company === "Entreprise") {
        this.item.phases[1].enable = true;
        this.item.phases[2].enable = true;
        this.item.phases[3].enable = true;
        this.item.phases[4].enable = true;
      }
      if (this.item.company === "Commando") {
        this.item.phases[1].enable = false;
        this.item.phases[2].enable = false;
        this.item.phases[3].enable = true;
        this.item.phases[4].enable = true;
      }
      this.getTypes();
      this.$forceUpdate();
    },
    value() {
      this.item = this.value;
      this.dateStartFormatted = this.formatDate(this.item.startDate);
      this.dateEndFormatted = this.formatDate(this.item.endDate);

      this.dateStartFormatted = this.formatDate(this.item.startDate);
      this.dateEndFormatted = this.formatDate(this.item.endDate);

      for (const commercial of this.item.commercial) {
        this.search = commercial.toString();
        this.searchUser();
      }
      this.getTypes();
    },
  },
};
</script>

<style scoped></style>
