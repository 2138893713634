var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MaterialCard",
    { staticClass: "mt-8", attrs: { title: "Pipeline" } },
    [
      _vm.stats && _vm.stats.pipeline
        ? _c(
            "v-row",
            { staticClass: "py-2" },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "6" } },
                [
                  _c("h1", { staticClass: "primary--text py-0 my-0" }, [
                    _vm._v(
                      _vm._s(
                        Math.round(
                          _vm.stats.pipeline.totalProba
                        ).toLocaleString()
                      ) + " €"
                    ),
                  ]),
                  _c(
                    "h4",
                    {
                      staticClass: "py-0 my-0",
                      staticStyle: {
                        "font-style": "italic",
                        "font-weight": "normal",
                      },
                    },
                    [_vm._v("Prévisionnel")]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "6" } },
                [
                  _c("h1", { staticClass: "primary--text my-0" }, [
                    _vm._v(
                      _vm._s(
                        Math.round(_vm.stats.pipeline.total).toLocaleString()
                      ) + " €"
                    ),
                  ]),
                  _c(
                    "h4",
                    {
                      staticClass: "py-0 my-0",
                      staticStyle: {
                        "font-style": "italic",
                        "font-weight": "normal",
                      },
                    },
                    [_vm._v("Total")]
                  ),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("Bar", {
                    staticClass: "chartBar",
                    attrs: {
                      chartData: _vm.chartData,
                      chartOptions: _vm.chartOptions,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }