var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      style: {
        height:
          _vm.textVal.length > _vm.maxChars && !_vm.toggle ? "auto" : "auto",
      },
      domProps: {
        innerHTML: _vm._s(
          _vm.textVal.length > _vm.maxChars && !_vm.toggle
            ? _vm.textShort + "..."
            : _vm.textVal
        ),
      },
    }),
    _c(
      "div",
      {
        staticClass: "text-overline primary--text",
        staticStyle: { cursor: "pointer" },
        on: {
          click: function ($event) {
            _vm.toggle = !_vm.toggle
          },
        },
      },
      [
        _vm._v(
          _vm._s(
            _vm.textVal.length > _vm.maxChars && !_vm.toggle
              ? _vm.moreStr
              : _vm.lessStr
          )
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }