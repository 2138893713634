var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "-25px" } },
    [
      _c("landscape"),
      _c(
        "v-card",
        { staticClass: "mb-3", attrs: { elevation: "1" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary", elevation: "0", dark: "" } },
            [
              _c("v-card-title", [
                _vm._v(" Affectations "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      top: "0",
                      left: "50%",
                      background: "#78909c",
                      padding: "5px",
                      translate: "-50% 0",
                      "border-radius": "0 0 5px 5px",
                    },
                  },
                  [
                    _c(
                      "v-menu",
                      {
                        staticStyle: { "z-index": "1111111" },
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { text: "", small: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v(" fa-ellipsis-v fa-fw "),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "v-list",
                          { staticStyle: { "z-index": "1111111" } },
                          [
                            _vm.isGranted("OPPORTUNITIES_STATISTICS")
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Statistiques Personnelles",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Mes statistiques"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isGranted("STATS_COM")
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Statistiques Commerciaux",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Statistiques commerciaux"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isGranted("OPPORTUNITIES_STATISTICSPERS")
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Statistiques BU",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Statistiques de mon service"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isGranted("EVENTS_EVENTSKEY")
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Relations",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Relation clients"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isGranted("EVENTS_EVENTSKEY")
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "Cross-Selling",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Cross-Selling"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "pt-6" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "5" } },
            [
              _c(
                "material-card",
                { attrs: { icon: "fa-chart-line", title: "Affectation" } },
                [
                  _c("AffectsChart", {
                    attrs: { labels: _vm.labels, datasets: _vm.datasets },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "7" } },
            [
              _c(
                "material-card",
                { attrs: { icon: "fa-table", title: "Tableau" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.users,
                      "hide-default-footer": "",
                      loading: _vm.loading,
                      "items-per-page": 100,
                      "sort-by": "total",
                      "sort-desc": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.headers, function (header) {
                          return {
                            key: `header.${header.value}`,
                            fn: function () {
                              return [
                                header.legend
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        key: header.value,
                                        attrs: { bottom: "", color: "primary" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c("span", _vm._g({}, on), [
                                                    _vm._v(_vm._s(header.text)),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "text-center" },
                                          [_vm._v(_vm._s(header.legend))]
                                        ),
                                      ]
                                    )
                                  : _c("span", { key: header.value }, [
                                      _vm._v(_vm._s(header.text)),
                                    ]),
                              ]
                            },
                            proxy: true,
                          }
                        }),
                        {
                          key: "body",
                          fn: function ({ items }) {
                            return [
                              _c(
                                "tbody",
                                _vm._l(items, function (item) {
                                  return _c(
                                    "tr",
                                    {
                                      key: item.id,
                                      on: {
                                        click: function ($event) {
                                          return _vm.loadAffect(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                left: "",
                                                color: item.color,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-avatar",
                                                          {
                                                            staticClass: "mx-0",
                                                            attrs: {
                                                              size: "25",
                                                              color: item.color,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.icon
                                                                  ) + " fa-fw"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "small" },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v(" " + _vm._s(item.lastname))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "text-capitalize" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.firstname.toLowerCase()
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", { staticClass: "text-end" }, [
                                        _vm._v(_vm._s(item.contracts)),
                                      ]),
                                      _c("td", { staticClass: "text-end" }, [
                                        _vm._v(
                                          _vm._s(_vm.numStr(item.prospect))
                                        ),
                                      ]),
                                      _c("td", { staticClass: "text-end" }, [
                                        _vm._v(_vm._s(_vm.numStr(item.total))),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "text-end marge-cell" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.subscriptions_gain_metier
                                                ? item.subscriptions_gain_metier
                                                : ""
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-end marge-cell-metier",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.subscriptions_gain
                                                ? item.subscriptions_gain
                                                : ""
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-end marge-cell" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.subscriptions_gain_metier_pot
                                                ? item.subscriptions_gain_metier_pot
                                                : ""
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-end marge-cell-metier",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.subscriptions_gain_metier_not_affected
                                                  ? item.subscriptions_gain_metier_not_affected
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }