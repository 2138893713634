<template>
  <v-row v-if="newEvent && Object.keys(newEvent).length > 0">
    <v-col cols="12" v-if="newEvent.teams">
      <v-alert color="error" outlined small prominent dense text type="error">
        Les contacts liés à l'événement vont recevoir une invitation et les modifications de celui-ci.
      </v-alert>
    </v-col>
    <v-col cols="12" md="6" class="pb-0 py-4 my-0">
      <v-autocomplete
        :menu-props="{ maxHeight: '300' }"
        v-model="usersSelected"
        :items="users"
        small-chips
        outlined
        deletable-chips
        class="utilisateursParticipantsSelect"
        label="Utilisateurs participants"
        item-text="fullname"
        item-value="id"
        multiple
        :search-input.sync="search"
        cache-items
      >
        <template v-slot:selection="data">
          <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
            <UserAvatar :userId="data.item.id" size="25" class="mr-2" />
            {{ data.item.firstname + " " + data.item.lastname }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template>
            <v-list-item-avatar>
              <UserAvatar :userId="data.item.id" size="25" class="mr-2" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.firstname + " " + data.item.lastname }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-col>
    <template>
      <v-col cols="6" sm="4" md="2">
        <v-switch label="En attente" color="secondary" :value="'0'" v-model="status"></v-switch>
      </v-col>
      <v-col cols="6" sm="4" md="2" v-if="newEvent">
        <v-switch label="Réalisé" color="success" :value="'1'" v-model="status"></v-switch>
      </v-col>
      <v-col v-if="newEvent.id" cols="6" sm="4" md="2">
        <v-switch label="Annulé" color="error" :value="'2'" v-model="status"></v-switch>
      </v-col>
    </template>

    <v-col cols="12" md="12" class="py-0 my-0 mt-4 mt-md-0">
      <v-text-field label="Sujet" class="my-0 py-0" outlined :rules="rules" v-model="newEvent.name"></v-text-field>
    </v-col>
    <v-col cols="12" md="3" class="py-0 my-0 mt-4 mt-md-0">
      <v-select
        v-model="newEvent.eventtypeId"
        :items="newEvent.eventtypeId && typeEventNotForm.includes(newEvent.eventtypeId) ? typeEvent : typeEventCreate"
        class="my-0 py-0"
        outlined
        :rules="rules"
        item-text="name"
        item-value="id"
        label="Type"
        persistent-hint
        hide-details
      >
        <template v-slot:selection="{ item }">
          <div class="overflow-hidden text-no-wrap">
            <v-icon :color="item.color" class="mr-2" x-small label>{{ item.icon }} fa-fw</v-icon>
            <span class="overflow-hidden text-no-wrap">{{ item.name }}</span>
          </div>
        </template>
        <template v-slot:item="{ active, item }">
          <span class="text-no-wrap">
            <v-icon :color="item.color" class="mr-2" x-small label>{{ item.icon }} fa-fw</v-icon>
            {{ item.name }}
          </span>
        </template>
      </v-select>
    </v-col>

    <v-col cols="12" md="3" class="py-0 my-0 mt-4 mt-md-0">
      <v-menu ref="menu1" v-model="menuDate" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormatted"
            label="Date"
            persistent-hint
            v-bind="attrs"
            class="my-0 py-0"
            outlined
            hide-details
            :rules="rules"
            @blur="newEvent.date = parseDate(dateFormatted)"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker first-day-of-week="1" v-model="newEvent.date" color="primary" @input="menuDate = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" md="3" class="py-0 my-0 mt-4 mt-md-0">
      <time-picker v-if="newEvent.time" v-model="newEvent.time" outlined></time-picker>
    </v-col>
    <v-col cols="12" :md="3" class="py-0 my-0 mt-4 mt-md-0">
      <v-text-field label="Durée" v-model="newEvent.duration" type="number" class="my-0 py-0" outlined :rules="rules" hide-details></v-text-field>
    </v-col>
    <v-col cols="12" md="2" class="py-0 my-0 pt-sm-3 pt-md-0">
      <v-switch color="error" label="Privé" v-model="newEvent.private" hide-details></v-switch>
    </v-col>
    <v-col v-if="newEvent.userId === $store.state.user.id || !newEvent.userId" cols="12" md="2" class="py-0 my-0 pt-sm-3 pt-md-0">
      <v-switch color="error" label="Confidentiel" v-model="newEvent.confidential" hide-details></v-switch>
    </v-col>
    <v-col cols="12" class="py-0 my-0 mt-7">
      <v-textarea label="Commentaires internes" v-model="newEvent.body" class="my-0 py-1" outlined hide-details></v-textarea>
    </v-col>
    <v-col cols="12">
      <v-row class="my-0 py-3 grey lighten-5" style="clear: both">
        <v-col cols="12" class="py-0 my-0">
          <div class="d-flex justify-start align-center mb-10">
            <span class="mr-3 text-left text-h6 text-uppercase blue-grey--text">Contacts Associés</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-switch v-on="on" class="mt-0 pt-0 text-body-2" v-model="newEvent.invitation" color="primary" hide-details dense>
                  <template v-slot:label>
                    <span class="text-body-2">Invitation calendrier</span>
                  </template>
                </v-switch>
              </template>
              <span>Envois une invitation au contact sélectionné</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12" class="py-0 my-0">
          <v-row class="ma-0">
            <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, key) in contactsList" :key="key" class="py-0 my-0">
              <v-switch v-model="newEvent.contacts" color="primary" :value="item.origin.contact.id" class="pa-0 ma-0">
                <template v-slot:label>
                  <span class="text-caption">{{ item.name }}</span>
                  <sup>
                    <v-icon color="yellow darken-1" v-if="item.favorite" x-small>fa-star</v-icon>
                  </sup>
                </template>
              </v-switch>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <template v-if="customer">
      <v-row class="my-3 mt-0">
        <v-col cols="12" lg="3">
          <v-row>
            <v-col cols="12" class="py-0 text-start">
              <h4 class="ml-3 blue-grey--text">{{ customer.entreprise.name }}</h4>
              <v-col cols="12" class="py-0 mb-1" v-if="customer.entreprise.adresse.address">
                <v-icon class="mr-2 float-start" small>fa-map-marker-alt fa-fw</v-icon>
                <p class="float-start">
                  {{ customer.entreprise.adresse.address }}<br />
                  <template v-if="customer.entreprise.adresse.address2"> {{ customer.entreprise.adresse.address2 }}<br /> </template>
                  {{ customer.entreprise.adresse.postalCode + " " + customer.entreprise.adresse.city }}
                </p>
              </v-col>
              <br style="clear: both" />
              <v-col cols="12" class="py-0 mb-1" v-if="customer.entreprise.phone">
                <v-divider class="my-3"></v-divider>
                <v-icon class="mr-2 float-start" small>fa-phone fa-fw</v-icon>
                <span>
                  <a :href="'tel:' + customer.entreprise.phone">{{ customer.entreprise.phone }}</a>
                </span>
              </v-col>
            </v-col>
            <br style="clear: both" />
            <v-col cols="12" class="py-0 mb-1">
              <v-col cols="12" class="py-0" v-if="customer.entreprise.email">
                <v-icon class="mr-2 float-start" small>fa-envelope fa-fw</v-icon>
                <span class="py-0 float-start">
                  <a :href="'mailto:' + customer.entreprise.email">{{ customer.entreprise.email }}</a>
                </span>
              </v-col>
              <br style="clear: both" />
              <v-col cols="12" class="py-0 mb-1" v-if="customer.entreprise.site">
                <v-divider class="my-3"></v-divider>
                <v-icon class="mr-2 float-start" small>fa-home fa-fw</v-icon>
                <span class="py-0 float-start text-wrap">
                  <a :href="customer.entreprise.site">{{ customer.entreprise.site }}</a>
                </span>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" lg="9">
          <template v-if="contactsList && contactsList.length > 0">
            <v-col cols="12" class="py-0 my-0">
              <v-row class="py-0 my-0">
                <v-col cols="12" md="6" v-for="(itemContact, key) in selectedContact" :key="key">
                  <v-card elevation="1">
                    <v-toolbar color="primary" dark height="35px" elevation="0">
                      <v-icon color="yellow darken-1" class="mr-1" v-if="itemContact.favorite" small>fa-star fa-fw</v-icon>
                      {{ itemContact.name }}
                    </v-toolbar>

                    <v-card-text class="text-left">
                      <div>
                        <v-icon x-small class="mr-1">fas fa-graduation-cap fa-fw</v-icon>
                        {{ itemContact.origin && itemContact.origin.job ? itemContact.origin.job.name : "Non renseigné" }}
                      </div>
                      <div>
                        <v-icon x-small class="mr-1">fa-phone fa-fw</v-icon>
                        <a v-if="itemContact.origin.contact.phone" :href="'tel:' + itemContact.origin.contact.phone">{{ itemContact.origin.contact.phone }}</a>
                        <template v-if="itemContact.origin.contact.phone && itemContact.origin.contact.phone2"> - </template>
                        <a v-if="itemContact.origin.contact.phone2" :href="'tel:' + itemContact.origin.contact.phone2">{{
                          itemContact.origin.contact.phone2
                        }}</a>
                        <template v-if="!itemContact.origin.contact.phone && !itemContact.origin.contact.phone2"> Non renseigné </template>
                      </div>
                      <div>
                        <v-icon x-small class="mr-1">far fa-envelope fa-fw</v-icon>
                        <template v-if="itemContact.origin.contact.email">
                          <a :href="'mailto:' + itemContact.origin.contact.email">{{ itemContact.origin.contact.email }}</a>
                        </template>
                        <template v-else> Non renseigné </template>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-col>
      </v-row>
    </template>

    <v-col cols="12" class="py-0 my-0">
      <v-row class="ma-0">
        <template v-if="false">
          <v-col cols="6" class="px-0 mx-0">
            <v-switch label="Déjà effectué" value="1" v-model="newEvent.status" class="pa-0 ma-0"></v-switch>
          </v-col>
        </template>
      </v-row>
    </v-col>
    <v-col cols="12" class="py-0"> </v-col>
  </v-row>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import Tools from "../../mixins/Tools";
import dayjs from "dayjs";
import TimePicker from "./timePicker";
import UserAvatar from "../user/UserAvatar.vue";

export default {
  name: "EventForm",
  props: ["value"],
  components: { TimePicker, UserAvatar },
  mixins: [simpleDebounce, Tools],
  data() {
    return {
      valid: false,
      selectedContact: [],
      status: "0",
      usersSelected: [],
      toggleUserList: false,
      customer: null,
      search: this.$store.state.user.lastname,
      users: [],
      rules: [(v) => !!v || "Name is required"],
      eventTypeArray: {},
      newEvent: this.value,
      activePicker: null,
      menuDate: false,
      date: dayjs().format("YYYY-MM-DD"),
      dateFormatted: this.formatDate(dayjs().format("YYYY-MM-DD")),
      contactsList: this.value.contactsList ? this.value.contactsList : [],
      typeEvent: [],
      typeEventCreate: [],
      typeEventNotForm: [],
      initStatus: null,
      statusEvent: [
        {
          id: 0,
          name: "En attente",
        },
        {
          id: 1,
          name: "Réalisé",
        },
        {
          id: 2,
          name: "Annulé",
        },
      ],
    };
  },
  mounted() {
    this.getTypeEvent();

    this.newEvent = this.value;
    this.contactsList = this.newEvent.contactsList;

    this.newEvent.status = this.newEvent.status.toString();
    this.initStatus = this.newEvent.status;
    if (!this.contactsList || this.contactsList.length === 0) {
      this.setContactList();
    }

    if (this.newEvent.date === null) {
      this.newEvent.date = dayjs().format("YYYY-MM-DD");
    }

    if (this.newEvent.status === null) {
      this.newEvent.status = 0;
    }

    if (this.newEvent.time === null) {
      let date = dayjs();
      let hours = dayjs(date).format("HH");
      let minutes = dayjs(date).format("mm");

      let m = minutes >= 30 ? 0 : 30;
      let h = m === 0 ? hours + 1 : hours;
      h += 1;
      date.set("hour", h).set("minute", m);
      this.newEvent.time = dayjs(date).format("HH:mm");
    }

    this.searchUser();
    if (this.newEvent.id) {
      this.dateFormatted = this.newEvent.date;
      this.newEvent.date = this.parseDate(this.dateFormatted);
      this.newEvent.usersList.forEach((item) => {
        this.search = item.userId;
        this.searchUser();
        this.usersSelected.push(item.userId);
      });
      this.newEvent.user = this.usersSelected;
      this.getCustomer();
    } else {
      if (this.newEvent.customerId) this.getCustomer();
      else this.customer = null;
      this.usersSelected.push(this.$store.state.user.id);
    }

    this.newEvent.status = parseInt(this.newEvent.status);

    switch (this.newEvent.status) {
      case 0: {
        this.status = "0";
        break;
      }
      case 1: {
        this.status = "1";
        break;
      }
      case 2: {
        this.status = "2";
        break;
      }
    }

    this.setContent();
    this.$forceUpdate();
  },
  methods: {
    getCustomer() {
      this.$axios("/api/customers/" + this.newEvent.customerId).then((resp) => {
        this.customer = resp.data;
      });
    },
    setContactList() {
      if (this.newEvent.customerId === null) return;
      this.$axios("/api/customers/" + this.newEvent.customerId + "/contacts", {
        params: {
          page: 1,
          limit: 99,
        },
      }).then((data) => {
        let contactsList = [];
        let favList = [];
        data.data.data.forEach((item) => {
          if (item.favorite) {
            favList.push({
              name: item.contact.contact.firstname + " " + item.contact.contact.lastname,
              email: item.contact.contact.email,
              origin: item.contact,
              id: item.contact.contact.id,
              favorite: item.contact.favorite,
            });
          } else {
            contactsList.push({
              name: item.contact.contact.firstname + " " + item.contact.contact.lastname,
              email: item.contact.contact.email,
              origin: item.contact,
              id: item.contact.contact.id,
              favorite: item.contact.favorite,
            });
          }
        });

        contactsList.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        favList.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        let tmp = favList.concat(contactsList);

        this.newEvent.contactsList = tmp;
        this.contactsList = tmp;
        this.selectedContact = [];
        if (this.newEvent && this.newEvent.contacts) {
          this.contactsList.forEach((cont) => {
            if (cont.origin.contact && this.newEvent.contacts.includes(cont.origin.contact.id)) {
              this.selectedContact.push(cont);
            }
          });
        }
      });
    },

    remove(item) {
      const index = this.usersSelected.indexOf(item.id);
      if (index >= 0) this.usersSelected.splice(index, 1);
    },
    searchUser() {
      this.$axios("/api/users", { params: { s: this.search, limit: false, all: true, active: true } }).then((resp) => {
        this.users = resp.data;
      });
    },
    getTypeEvent() {
      this.$axios("/api/events/types").then((resp) => {
        this.typeEvent = resp.data;
        resp.data.forEach((item) => {
          this.eventTypeArray[item.id] = item;
          if (item.inForm) {
            this.typeEventCreate.push(item);
          } else {
            this.typeEventNotForm.push(item.id);
          }
        });
      });
    },
    saveDate(date) {
      this.$refs.menu.save(date);
    },
    setContent() {
      if (
        this.newEvent &&
        this.newEvent.eventtypeId &&
        this.eventTypeArray &&
        Object.keys(this.eventTypeArray).length > 0 &&
        this.eventTypeArray[this.newEvent.eventtypeId]
      ) {
        if (this.eventTypeArray[this.newEvent.eventtypeId] && this.eventTypeArray[this.newEvent.eventtypeId].duration) {
          this.newEvent.duration = this.eventTypeArray[this.newEvent.eventtypeId].duration;
        }
        if (
          this.newEvent.body === undefined ||
          !this.newEvent.body ||
          (this.newEvent.body.length === 0 && this.eventTypeArray[this.newEvent.eventtypeId] && this.eventTypeArray[this.newEvent.eventtypeId].defaultText)
        ) {
          this.newEvent.body = this.eventTypeArray[this.newEvent.eventtypeId].defaultText;
        }
        this.newEvent.teams = this.eventTypeArray[this.newEvent.eventtypeId].teams;
        this.newEvent.ewsToggle = this.eventTypeArray[this.newEvent.eventtypeId].ewsCalendar;
      }
    },
  },
  watch: {
    usersSelected() {
      this.search = "";
      this.newEvent.user = this.usersSelected;
    },
    value() {
      this.newEvent = this.value;
      if (this.newEvent) {
        this.dateFormatted = null;
        this.usersSelected = [];
        this.contactsList = [];
        if (this.newEvent.id) {
          this.dateFormatted = this.newEvent.date;
          this.newEvent.date = this.parseDate(this.dateFormatted);
          this.newEvent.usersList.forEach((item) => {
            this.search = item.userId;
            this.searchUser();
            this.usersSelected.push(item.userId);
          });
          this.getCustomer();
        } else {
          this.customer = null;
          if (this.newEvent.date === null) {
            this.newEvent.date = dayjs().format("YYYY-MM-DD");
            this.dateFormatted = this.formatDate(this.newEvent.date);
          }
          this.usersSelected.push(this.$store.state.user.id);
        }

        if (!this.contactsList || this.contactsList.length === 0) {
          this.setContactList();
        }
        this.initStatus = this.newEvent.status;
        this.status = this.newEvent.status;

        switch (this.newEvent.status) {
          case false: {
            this.status = "0";
            break;
          }
          case 0: {
            this.status = "0";
            break;
          }
          case 1: {
            this.status = "1";
            break;
          }
          case 2: {
            this.status = "2";
            break;
          }
        }
        this.setContent();
      }
    },
    search() {
      this.simpleDebounce(() => {
        this.searchUser();
      }, 300 /* optional debounce time: default is 300 */);
    },
    "newEvent.eventtypeId"() {
      this.setContent();
    },
    "newEvent.time"() {
      if (this.newEvent && this.newEvent.time === null) {
        let date = dayjs();
        let hours = parseInt(date.format("HH"));
        let minutes = parseInt(date.format("mm"));
        let m = minutes >= 30 ? 0 : 30;
        let h = m === 0 ? hours + 1 : hours;
        date.set("hour", h).set("minute", m);
        this.newEvent.time = date.format("HH:mm");
      }
    },
    status() {
      this.newEvent.status = parseInt(this.status);
    },
    "newEvent.date"() {
      if (this.newEvent) {
        this.dateFormatted = this.formatDate(this.newEvent.date);
      }
    },
    newEvent() {
      this.$emit("input", this.newEvent);
    },
    "newEvent.contacts"() {
      this.selectedContact = [];
      this.contactsList.forEach((cont) => {
        if (cont.origin && cont.origin.contact && cont.origin.contact.id && this.newEvent && this.newEvent.contacts.includes(cont.origin.contact.id)) {
          this.selectedContact.push(cont);
        } else if (cont.contact && cont.contact.id && this.newEvent.contacts.includes(cont.contact.id)) {
          this.selectedContact.push(cont);
        } else {
          this.$forceUpdate();
        }
      });
    },
  },
};
</script>

<style>
.vue__time-picker {
  width: 100%;
  border: solid 1px #9d9e9e;
  min-height: 56px;
  border-radius: 4px;
  border-color: #9d9e9e;
  border-width: thin;
}

.vue__time-picker input {
  width: 100%;
  min-height: 56px;
}

.v-text-field {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
}

.vue__time-picker input.display-time {
  border: none;
}

.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: #8dadbf !important;
}
</style>
