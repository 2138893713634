<template>
  <div class="percentage-span-container">
    <span :class="'percentage-span ' + colorClass()" :style="{ width: cellData + '%' }">
      <span>{{ cellData }}% </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "percentageCell",
  props: {
    cellData: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    colorClass() {
      if (this.cellData < 20) {
        return "color1 percentage-span-zero percentageText";
      } else if (this.cellData >= 20 && this.cellData < 60) {
        return "color1";
      } else if (this.cellData >= 60 && this.cellData < 90) {
        return "color2";
      } else {
        return "color3";
      }
    },
  },
};
</script>
<style scoped>
.percentage-span-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.percentage-span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
  font-weight: bold;
}
.percentage-span-zero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  color: #322c2c !important;
  font-weight: bold;
}
.percentageText span {
  transform: translateX(10px);
}

.color1 {
  background-color: #ff4c4c; /* Rouge clair per 0-10% */
}
.color2 {
  background-color: #ffa500; /* Rouge-Orange per 10-20% */
}
.color3 {
  background-color: #4caf50; /* Orange clair per 20-30% */
}
</style>
