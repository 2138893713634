var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "cookieBtn",
      attrs: { "x-small": "", color: "primary" },
      on: {
        click: function ($event) {
          _vm.clearLocalCookie()
          _vm.clearServiceWorker()
        },
      },
    },
    [
      _c("v-icon", { attrs: { "x-small": "" } }, [_vm._v("fa-fw fa-recycle")]),
      _c("span", { staticClass: "cookieText ml-2" }, [
        _vm._v("Effacer les cookies"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }