<template>
  <material-card title="Hebdomadaire" class="mb-5 pb-7">
    <v-container>
      <v-row align="center">
        <v-col class="text-right">
          <v-btn
            icon
            small
            v-if="curKey !== 0"
            @click="
              curKey = curKey - 1;
              week = Object.keys(challenge.classement.week)[curKey];
            "
          >
            <v-icon small>fa-chevron-left fa-fw </v-icon>
          </v-btn>
        </v-col>
        <v-col class="text-center week--title primary--text text--darken-1 text-uppercase font-italic">Semaine {{ week }} </v-col>
        <v-col class="text-left">
          <v-btn
            icon
            small
            v-if="curKey !== Object.keys(challenge.classement.week).length - 1"
            @click="
              curKey = curKey + 1;
              week = Object.keys(challenge.classement.week)[curKey];
            "
          >
            <v-icon small>fa-chevron-right fa-fw </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-list v-if="week">
      <challenge-tables-header :hebdo="true"></challenge-tables-header>
      <div v-for="(item, key) in chall" :key="key">
        <v-list-item :style="{ borderLeft: 'solid 5px ' + (item.colorType ? item.colorType : 'grey') }">
          <v-col cols="1">{{ key + 1 }}</v-col>

          <v-list-item-avatar width="40">
            <UserAvatar :userId="item.user" size="40" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-row>
              <v-col cols="3" class="py-0 pl-6 text-left d-none d-sm-flex" style="font-size: 0.9rem">
                {{ item.name }}
              </v-col>
              <v-col cols="4" sm="3" class="py-0 text-right">
                <template v-if="item.user === $store.state.user.id || isGranted('CHALLENGES_VIEW_AMOUNT')">
                  {{ Math.round(item.ca).toLocaleString("fr") }}
                </template>
              </v-col>
              <v-col cols="4" sm="3" class="py-0 text-right">
                <template v-if="item.user === $store.state.user.id || isGranted('CHALLENGES_VIEW_AMOUNT')">
                  {{ Math.round(item.gain).toLocaleString("fr") }}
                </template>
              </v-col>

              <v-col cols="2" class="py-0 text-right"> +{{ item.points }} </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-icon width="24">
            <img src="/images/challenge/coin.svg" v-if="item.has_bonus" height="24" style="margin: 0" />
          </v-list-item-icon>
          <v-list-item-icon width="24">
            <img src="/images/challenge/helmet--gold.svg" v-if="challenge.global[0].user === item.user" width="24" />
            <img src="/images/challenge/helmet--silver.svg" v-if="challenge.global[1].user === item.user" width="24" />
            <img src="/images/challenge/helmet--bronze.svg" v-if="challenge.global[2].user === item.user" width="24" />
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </v-list>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
import UserAvatar from "../../components/user/UserAvatar.vue";
import Tools from "../../mixins/Tools";
import challengeTablesHeader from "./challengeTablesHeader.vue";

export default {
  name: "hebdoChallenge",
  components: { MaterialCard, UserAvatar, challengeTablesHeader },
  mixins: [Tools],
  props: ["value"],
  data() {
    return {
      challenge: this.value,
      week: 0,
      users: [],
      chall: [],
      curKey: null,
    };
  },
  mounted() {
    let key = Object.keys(this.challenge.classement.week);
    this.week = key[key.length - 1];
    this.curKey = key.length - 1;
    key = Object.keys(this.challenge.classement.week[this.week]);
    this.chall = [];

    key.forEach((item) => {
      this.chall.push(this.challenge.classement.week[this.week][item]);
    });

    this.chall.sort((a, b) => {
      if (a["points"] === b["points"] && a["nb"] && b["nb"]) return a["nb"] > b["nb"] ? -1 : 1;
      else if (a["points"] === b["points"]) return a["gain"] > b["gain"] ? -1 : 1;
      else return a["points"] > b["points"] ? -1 : 1;
    });
  },
  watch: {
    week() {
      let key = Object.keys(this.challenge.classement.week[this.week]);
      this.chall = [];

      key.forEach((item) => {
        this.chall.push(this.challenge.classement.week[this.week][item]);
      });

      this.chall.sort((a, b) => {
        if (a["points"] === b["points"] && a["nb"] && b["nb"]) return a["nb"] > b["nb"] ? -1 : 1;
        else if (a["points"] === b["points"]) return a["gain"] > b["gain"] ? -1 : 1;
        else return a["points"] > b["points"] ? -1 : 1;
      });
    },
    challenge() {
      this.$emit("input", this.challenge);
    },
    value() {
      this.challenge = this.value;
      let key = Object.keys(this.challenge.classement.week);
      this.curKey = key.length - 1;

      this.week = key[key.length - 1];
      key = Object.keys(this.challenge.classement.week[this.week]);
      this.chall = [];

      key.forEach((item) => {
        this.chall.push(this.challenge.classement.week[this.week][item]);
      });

      this.chall.sort((a, b) => {
        if (a["points"] === b["points"] && a["nb"] && b["nb"]) return a["nb"] > b["nb"] ? -1 : 1;
        else if (a["points"] === b["points"]) return a["gain"] > b["gain"] ? -1 : 1;
        else return a["points"] > b["points"] ? -1 : 1;
      });
    },
  },
};
</script>

<style scoped></style>
