<template>
  <v-container fluid>
    <modal v-model="linkModal" max-width="calc(100% - 122px)">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Boîte mail</strong>
        <br /><small class="mt-0">Filtrez les dossiers...</small>
      </template>
      <div class="text-left">
        <Inbox @close="linkModal = false" v-model="typeLink"></Inbox>
      </div>
    </modal>
    <modal v-model="editEvent" max-width="1080">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Evènement</strong>
        <br /><small class="mt-0">
          <template v-if="newEvent"> {{ dayjs(newEvent.date).format("DD/MM/YYYY") }} {{ newEvent.time }} - {{ newEvent.name }} </template>
        </small>

        <template v-if="newEvent">
          <template v-if="newEvent.id">
            <div class="modal-menu--actions">
              <v-row>
                <v-col cols="12" class="text-left">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        elevation="0"
                        plain
                        color="primary"
                        @click="$router.push({ name: 'Client', params: { id: newEvent.customerId } })"
                      >
                        <v-icon x-small>fa-search</v-icon>
                      </v-btn>
                    </template>
                    <span>Fiche client</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="ml-1"
                        small
                        elevation="0"
                        plain
                        color="none"
                        @click="
                          typeLink.id = newEvent.id;
                          linkModal = true;
                        "
                      >
                        <v-icon x-small>fa-envelope</v-icon>
                      </v-btn>
                    </template>
                    <span>Lier un e-mail</span>
                  </v-tooltip>

                  <template v-if="newEvent.opportunityId">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="mx-1"
                          elevation="0"
                          small
                          plain
                          color="warning"
                          @click="$router.push({ name: 'Opportunité', params: { id: newEvent.opportunityId } })"
                        >
                          <v-icon x-small>fa-handshake</v-icon>
                        </v-btn>
                      </template>
                      <span>Opportunité {{ newEvent.opportunityId }}</span>
                    </v-tooltip>
                  </template>
                  <template v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="newOpp" v-bind="attrs" v-on="on" class="mx-1" elevation="0" small plain color="warning">
                          <v-icon x-small>fa-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Créer opportunité</span>
                    </v-tooltip>
                    <!--<v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs"
                               v-on="on" @click="linkOppModal = true" small plain elevation="0">
                          <v-icon x-small>fa-link</v-icon>
                        </v-btn>
                      </template>
                      <span>Associer opportunité</span>
                    </v-tooltip>-->
                  </template>
                </v-col>
              </v-row>
            </div>
          </template>
        </template>
      </template>
      <v-container fluid>
        <v-row>
          <v-form v-model="valid" ref="form">
            <commentFormOpportunities v-model="newEvent"></commentFormOpportunities>
          </v-form>
        </v-row>
      </v-container>
      <template v-slot:actions>
        <v-btn variant="flat" color="primary" elevation="0" @click="editEventFct" :loading="loadingSave"> Enregistrer </v-btn>
        <v-btn color="secondary" text class="mr-4" @click="editEvent = false"> Fermer </v-btn>
      </template>
    </modal>
    <modal v-model="eventModal" no-padding>
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Événements en retard</strong>
        <br /><small class="mt-0">
          <template v-if="eventsLate && eventsLate.events">
            {{ eventsLate && eventsLate.events ? eventsLate.events.length : "" }}
            événement{{ eventsLate && eventsLate.events && eventsLate.events.length > 1 ? "s" : "" }} à traiter
          </template>
        </small>
      </template>

      <v-list class="ma-0 pa-0" subheader three-line>
        <v-list-item-group v-for="(item, key) in eventsLate.events" :key="key">
          <v-list-item class="my-0 py-0" @click="openEvents(item)" @click.middle="openTab({ name: 'Client', params: { id: item.customerId } })">
            <v-list-item-avatar size="40">
              <v-icon dark x-small :style="'background-color:' + eventsLate.type[item.eventtypeId].color">
                {{ eventsLate.type[item.eventtypeId].icon }} fa-fw
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="text-left pb-0">
              <v-list-item-title class="primary--text"
                >{{ eventsLate.entreprise[item.id].name }}
                <template v-if="item.opportunity">
                  <v-chip x-small outlined class="ml-2" label color="primary">
                    <v-icon start class="mr-1">far fa-handshake fa-fw</v-icon>
                    {{ item.opportunity && item.opportunity.id ? item.opportunity.id : "" }}
                  </v-chip>
                </template>
              </v-list-item-title>

              <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="text-right error--text">
              <v-list-item-action-text class="text-right error--text">{{ dayjs().diff(item.start, "days") }} j </v-list-item-action-text>
              <v-list-item-action-text>
                {{ dayjs(item.start).format("DD/MM/YYYY") }}
                <br />
                {{ dayjs(item.start).format("HH:mm") }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>

          <v-divider inset></v-divider>
        </v-list-item-group>
      </v-list>
    </modal>
    <v-app-bar app color="#f9fafb" clipped-left v-if="isMobile()" elevation="1" :style="{ zIndex: 1000 }" dense>
      <v-row>
        <v-col cols="12" class="d-flex justify-end align-center">
          <v-label>Menu</v-label>
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
          <!-- <v-toolbar-title class="mt-3 ml-1 text-uppercase blue-grey--text font-weight-regular" v-if="!isMobile()">{{ currentRouteName }} </v-toolbar-title> -->
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :expand-on-hover="false"
      floating
      :permanent="!isMobile()"
      :temporary="isMobile()"
      src="/images/abImg.jpg"
      location="start"
      style="z-index: 1001"
      color="black"
      mini-variant
      app
      dark
    >
      <v-list>
        <v-list-item class="px-0">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-avatar v-bind="attrs" v-on="on" class="px-0 mx-2">
                <UserAvatar :userId="$store.state.user.id" size="40" />
              </v-list-item-avatar>
            </template>
            <span style="z-index: 111111">{{ $store.state.user.firstname }} {{ $store.state.user.lastname }}</span>
          </v-tooltip>
          <v-list-item-title>{{ $store.state.user.firstname }} {{ $store.state.user.lastname }}</v-list-item-title>
        </v-list-item>
        <v-list-item class="px-0">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon @click="eventModal = true" v-bind="attrs" v-on="on" style="cursor: pointer">
                <v-badge :content="eventsLate.events.length" color="secondary" overlap v-if="eventsLate && eventsLate.events && eventsLate.events.length > 0">
                  <v-icon> far fa-bell fa-fw </v-icon>
                </v-badge>
                <v-icon v-else> far fa-bell-slash fa-fw </v-icon>
              </v-list-item-icon>
            </template>
            <span style="z-index: 111111">
              <template v-if="eventsLate && eventsLate.events && eventsLate.events.length > 0">
                Il y a {{ eventsLate.events.length }} événement{{ eventsLate.events.length > 1 ? "s" : "" }} en retard{{
                  eventsLate.events.length > 1 ? "s" : ""
                }}
              </template>
              <template v-else> Il n'y a pas d'événement en retard </template>
            </span>
          </v-tooltip>
        </v-list-item>
        <v-list-item class="pa-0">
          <v-tooltip right :color="bebedc.length > 0 ? 'error' : 'success'">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on" style="cursor: pointer">
                <v-badge :content="bebedc.length" color="error" overlap v-if="bebedc.length > 0 && loadBebedc">
                  <v-icon> fa-exclamation-circle fa-fw </v-icon>
                </v-badge>
                <v-icon v-else :color="!loadBebedc ? '' : 'success'" :style="!loadBebedc ? 'opacity:0.5;' : ''"> far fa-check-circle fa-fw </v-icon>
              </v-list-item-icon>
            </template>
            <span style="z-index: 111111">
              <template v-if="bebedc.length > 0">
                <div v-for="(item, key) in bebedc" :key="key">
                  {{ item }}
                </div>
              </template>
              <template v-else> Vous êtes à jour sur vos objectifs de prospection. </template>
            </span>
          </v-tooltip>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list nav dense dark class="listFeature">
        <div v-for="(feature, index) in features" :key="'feature-' + index">
          <v-tooltip right v-if="isMobile() ? feature.mobile : true">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-if="isGranted(feature.permission)"
                v-bind="attrs"
                v-on="on"
                link
                :color="feature.color"
                :to="feature.path"
                exact-active-class="activeLinkMenu"
              >
                <v-list-item-icon>
                  <v-icon>{{ feature.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <span style="z-index: 111111">{{ feature.name }}</span>
          </v-tooltip>
        </div>
      </v-list>

      <template v-slot:append>
        <v-list nav dense>
          <v-tooltip right v-if="isGranted('SETTINGS_ALL')">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" link color="white" to="/settings" exact-active-class="activeLinkMenu">
                <v-list-item-icon>
                  <v-icon>fa-fw fa-cog</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <span style="z-index: 111111">Paramètres</span>
          </v-tooltip>

          <v-tooltip right v-if="$store.state.token !== $store.state.initToken">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" link color="white" @click="relogin" exact-active-class="activeLinkMenu">
                <v-list-item-icon>
                  <v-icon>fa-fw fa-sign-in-alt</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <span style="z-index: 111111">Reconnexion</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" link color="white" @click="logout" exact-active-class="activeLinkMenu">
                <v-list-item-icon>
                  <v-icon>fa-fw fa-power-off</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <span style="z-index: 111111">Déconnexion</span>
          </v-tooltip>
        </v-list>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import MobileDetection from "../../mixins/MobileDetection.js";
import Tools from "../../mixins/Tools";
import Modal from "../common/modal";
import dayjs from "dayjs";
import CommentFormOpportunities from "../common/EventForm";
import Inbox from "../../views/inbox/Inbox";
import EventsTools from "../../mixins/Events";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { EventBus } from "@/event-bus";
import UserAvatar from "../user/UserAvatar.vue";
import { featuresList } from "./featuresList";

export default {
  name: "Menu",
  components: { Inbox, CommentFormOpportunities, Modal, UserAvatar },
  data: () => ({
    features: featuresList,
    typeLink: { name: "Événement" },
    drawer: false,
    user: null,
    eventsLate: [],
    eventModal: false,
    loadBebedc: false,
    bebedc: [],
    currentCustomer: null,
    editEvent: false,
    newEvent: null,
    linkModal: false,
    loadingSave: false,
    valid: false,
  }),
  mixins: [MobileDetection, Tools, EventsTools],
  mounted() {
    this.user = this.$store.getters.userInfo;
    this.updatePhoto();
    this.getEventsLate();
    this.getBebedc();
    this.$root.$on("refresh-late", this.getEventsLate);
    //this.migrationEvent();
  },
  methods: {
    async relogin() {
      let res = await this.$confirm("Êtes-vous sur de vouloir vous reconnecter ?");

      if (res) {
        // this.$store.state.token = this.$store.state.initToken;
        localStorage.setItem("token-actuelburo", this.$store.state.initToken);
        this.$router.push({ name: "Panneau de supervision d'opportunités" });
        location.reload();
      }
    },
    getBebedc() {
      this.loadBebedc = false;
      this.$axios("/api/settings/bebedc").then((resp) => {
        this.loadBebedc = true;
        this.bebedc = resp.data;
      });
    },
    linkOppFct(idOpp) {
      this.$axios.patch("/api/events/" + this.newEvent.id + "/link", { opp: idOpp }).then(() => {
        this.linkOppModal = false;
        this.addEvent = false;
        this.editEvent = false;
        this.getEventsLate();
        this.$simpleToast.success("Evènement", "L'élément a bien été lié");
      });
    },
    newOpp() {
      this.$axios.post("/api/opportunities", { event: this.newEvent }).then((res) => {
        this.linkOppFct(res.data.opp.id);
        this.$router.push({ name: "Opportunité", params: { id: res.data.opp.id } });
      });
    },
    async editEventFct() {
      let res = false;
      this.$refs.form.validate();

      if (!this.valid) {
        this.$simpleToast.error("Evènement", "Un des champs est vide.");
      } else {
        this.loadingSave = true;
        res = await this.addEventSubmit(this.newEvent);
        this.loadingSave = false;
        location.reload();
      }

      if (res) {
        this.getEventsLate();
        this.addEvent = false;
        this.editEvent = false;
        this.loadingSave = false;
      }
    },
    openTab(params) {
      const routeData = this.$router.resolve(params);
      window.open(routeData.href, "_blank");
    },
    openEvents(tmp) {
      EventBus.$emit("hideLeftLayout", 1);
      let item = JSON.parse(JSON.stringify(tmp));

      let dateStart = dayjs(item.start).format("DD/MM/YYYY");
      let timeStart = dayjs(item.start).format("HH:mm");
      this.newEvent = {};
      let tmpContacts = [];
      this.currentCustomer = this.eventsLate.entreprise[item.id];
      this.eventsLate.info[item.id].forEach((item) => {
        tmpContacts.push(item.contactId);
      });

      this.newEvent = {
        id: item.id,
        opp: this.$route.params.id,
        eventtypeId: item.eventtypeId,
        name: item.name,
        time: timeStart,
        date: dateStart,
        usersList: this.eventsLate.users[item.id],
        duration: item.duration,
        private: item.private,
        status: item.status,
        body: item.body,
        teams: item.teams,
        contacts: tmpContacts,
        customer: item.customerId,
        customerId: item.customerId,
        opportunityId: item.opportunity ? item.opportunity.id : null,
        ewsid: item.ewsid,
        idMS: item.ewsid,
        history: [],
        immutableEws: item.immutableEws,
      };
      this.editEvent = true;
    },
    getEventsLate() {
      this.$axios("/api/events/late").then((resp) => {
        this.eventsLate = {
          type: [],
          customer: [],
          info: [],
          users: [],
          events: [],
        };
        this.eventsLate.type = resp.data.type;
        this.eventsLate.entreprise = resp.data.entreprise;
        this.eventsLate.info = resp.data.info;
        this.eventsLate.users = resp.data.users;

        resp.data.events.forEach((item) => {
          if (this.eventsLate.type[item.eventtypeId] && this.eventsLate.type[item.eventtypeId].shortcut) this.eventsLate.events.push(item);
        });
      });
    },
    updatePhoto() {
      let request = {
        scopes: ["user.read", "mail.send"], // optional Array<string>
      };

      this.$msal
        .acquireTokenSilent(request)
        .then(() => {})
        .catch(async (error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return this.$msal.acquireTokenPopup(request);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async migrationEvent() {
      let respdata = null;
      await this.$axios("/api/events/migration/user").then((resp) => {
        respdata = resp.data;
      });
      for (const data of respdata) {
        //let data = respdata[0];
        let item = data.event;
        let dateStart = dayjs(item.start).format("YYYY-MM-DD");
        let timeStart = dayjs(item.start).format("HH:mm");
        let tmpContacts = [];
        data.contacts.forEach((item) => {
          tmpContacts.push(item.contactId);
        });
        let ev = {
          id: item.id,
          opp: item.opportunity ? item.opportunity.id : null,
          eventtypeId: item.eventtypeId,
          opportunityId: item.opportunity ? item.opportunity.id : null,
          name: item.name,
          time: timeStart,
          date: dateStart,
          usersList: data.users,
          duration: item.duration,
          private: item.private,
          teams: item.teams,
          status: item.status,
          body: item.body,
          contacts: tmpContacts,
          customer: item.customerId,
          customerId: item.customerId,
          history: [],
          report: item.report,
          ewsid: item.ewsid,
          user: data.user,
        };
        await this.deleteEvent(ev);
        ev.ewsid = null;
        await this.editEventSubmit(ev, false);
      }
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {},
};
</script>

<style scoped>
/*.activeLinkMenu{
    background-color: #82b3c9;
}*/
.v-list-item__icon i {
  font-size: 21px !important;
}
.listFeature {
  position: relative;
}
</style>
