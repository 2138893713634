var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("Landscape"),
      _vm.isGranted("CUSTOMERS_INDEX")
        ? _c(
            "v-card",
            { staticStyle: { "z-index": "99" }, attrs: { elevation: "1" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary", dark: "", elevation: "0" } },
                [_c("v-card-title", [_vm._v(" Liste des postes supervisés ")])],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.customersList,
                  "server-items-length": _vm.maxItem,
                  "items-per-page": _vm.limitItem,
                  loading: _vm.loading,
                  "sort-by": _vm.sortedBy,
                  "sort-desc": _vm.sortedDesc,
                },
                on: {
                  "update:items-per-page": _vm.setItemMax,
                  "update:page": _vm.setPage,
                  "click:row": _vm.loadSupervision,
                  "update:sortBy": function ($event) {
                    _vm.sortedBy = $event
                  },
                  "update:sort-by": function ($event) {
                    _vm.sortedBy = $event
                  },
                  "update:sortDesc": function ($event) {
                    _vm.sortedDesc = $event
                  },
                  "update:sort-desc": function ($event) {
                    _vm.sortedDesc = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.item.installDate",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .dayjs(item.item.installDate)
                                  .format("DD/MM/YYYY")
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "body.prepend",
                      fn: function () {
                        return [
                          _c("tr", [
                            _vm.checkArrayContains("customer.name", _vm.headers)
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "text",
                                        label: "Rechercher",
                                      },
                                      model: {
                                        value: _vm.search.customerName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "customerName",
                                            $$v
                                          )
                                        },
                                        expression: "search.customerName",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains("item.type", _vm.headers)
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "text",
                                        label: "Rechercher",
                                      },
                                      model: {
                                        value: _vm.search.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "type", $$v)
                                        },
                                        expression: "search.type",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains("item.name", _vm.headers)
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "text",
                                        label: "Rechercher",
                                      },
                                      model: {
                                        value: _vm.search.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "name", $$v)
                                        },
                                        expression: "search.name",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains(
                              "item.manufacturer",
                              _vm.headers
                            )
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "text",
                                        label: "Rechercher",
                                      },
                                      model: {
                                        value: _vm.search.manufacturer,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "manufacturer",
                                            $$v
                                          )
                                        },
                                        expression: "search.manufacturer",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains("item.model", _vm.headers)
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "text",
                                        label: "Rechercher",
                                      },
                                      model: {
                                        value: _vm.search.model,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "model", $$v)
                                        },
                                        expression: "search.model",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains("item.os", _vm.headers)
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "text",
                                        label: "Rechercher",
                                      },
                                      model: {
                                        value: _vm.search.os,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "os", $$v)
                                        },
                                        expression: "search.os",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkArrayContains(
                              "item.serialNumber",
                              _vm.headers
                            )
                              ? _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "text",
                                        label: "Rechercher",
                                      },
                                      model: {
                                        value: _vm.search.serialNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "serialNumber",
                                            $$v
                                          )
                                        },
                                        expression: "search.serialNumber",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2794512632
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }