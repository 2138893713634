<template>
  <v-container fluid fill-height="fill-height">
    <v-layout column>
      <v-layout align-center="align-center" justify-center="justify-center">
        <v-flex class="login-form text-xs-center">
          <div class="display-1 mb-3 text-center">
            <img src="images/actuelburo.svg" style="max-width: 350px" />
          </div>
          <v-container>
            <v-row align="center">
              <v-divider></v-divider>
              <v-btn small text color="primary">Restez connecté </v-btn>
              <v-divider></v-divider>
            </v-row>
          </v-container>
          <v-card class="mb-5" v-if="false">
            <v-card-text>
              <div class="subheading">
                <template>Se connecter</template>
              </div>
              <v-alert v-if="alertToggle" dense outlined type="error">
                {{ this.alertMessage }}
              </v-alert>
              <v-form>
                <v-text-field v-model="user.email" light="light" prepend-icon="fa-envelope" label="Email" type="email"></v-text-field>
                <v-text-field v-model="user.password" light="light" prepend-icon="fa-lock" label="Password" type="password"></v-text-field>
                <v-btn @click.prevent="" block="block" type="submit" color="primary" @click="loginAction">Connexion</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
          <div class="text-center mt-7">
            <v-btn @click.prevent="" type="submit" color="primary" @click="loginMicrosoft">
              <v-icon class="mr-4">fab fa-microsoft</v-icon>
              Connexion Microsoft o365</v-btn
            >
          </div>
        </v-flex>
      </v-layout>
      <div class="absolute bottom-10 left-10">
        <ClearCookie />
      </div>
    </v-layout>
  </v-container>
</template>

<script>
let axios = require("axios");
import { msalMixin } from "vue-msal";
import md5 from "blueimp-md5";
import ClearCookie from "../components/common/ClearCookie.vue";

export default {
  name: "Login",
  data() {
    return {
      alertToggle: false,
      alertMessage: "",
      account: undefined,
      user: {
        email: "",
        password: "",
      },
    };
  },
  mixins: [msalMixin],
  mounted() {
    if (this.$store.getters.isMsalAuth) {
      // Note that the dollar sign ($) is missing from this.msal
      let email = md5(this.msal.user.userName);
      this.$store.dispatch("loginMsal", { email }).then(() => this.$router.push("/"));
    }
  },
  methods: {
    loginAction() {
      this.alertToggle = false;
      if (this.user.email.length === 0 || this.user.password.length === 0) {
        this.alertMessage = "Un des champs est vide.";
        this.alertToggle = true;
      }
      let email = this.user.email;
      let password = this.user.password;
      this.$store
        .dispatch("login", { email, password })
        .then(() => this.$router.push("/"))
        .catch((err) => console.log(err));
    },
    async updatePhoto() {
      try {
        let tokenResponse = await this.$msal.acquireTokenSilent({
          account: this.$msal.getAllAccounts()[0],
          scopes: ["user.read", "mail.send"],
        });
        axios(process.env.VUE_APP_GRAPH_V1 + "photos/96x96/$value", {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
            contentType: "image/jpeg",
          },
          responseType: "blob",
        })
          .then((resp) => {
            var reader = new FileReader();
            reader.readAsDataURL(resp.data);
            reader.onloadend = () => {
              this.image = reader.result;
              this.$axios.post("/api/users/picture", { data: reader.result }).then(() => {
                this.$router.push("/");
              });
            };
          })
          .catch(() => {
            this.$simpleToast.error("Profil", "Erreur sur la mise à jour de la photo");
            this.$router.push("/");
          });
      } catch (err) {
        console.log(err);
        await this.$msal.acquireTokenRedirect({ scopes: ["user.read"] });
      }
    },
    async loginMicrosoft() {
      let loginRequest = {
        scopes: [
          "MailboxSettings.ReadWrite",
          "MailboxSettings.Read",
          "user.read",
          "mail.send",
          "Mail.ReadBasic",
          "Mail.ReadWrite",
          "Mail.Read",
          "Contacts.Read",
          "Calendars.ReadWrite",
        ],
      };
      try {
        //await this.$msal.handleRedirectPromise();
        let loginResponse = await this.$msal.loginPopup(loginRequest);
        let tenant = loginResponse.tenantId;
        let idToken = loginResponse.idToken;
        let accessToken = loginResponse.accessToken;
        let clientId = loginResponse.account.idTokenClaims.aud;
        this.$store.dispatch("loginMsal", { tenant, idToken, accessToken, clientId }).then(() => {
          this.updatePhoto();
          //
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
  components: { ClearCookie },
};
</script>

<style scoped>
@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,https://grc.preprod.actuelburo.fr/events
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate(10px, 0, 0);
  }
}

.login-form {
  max-width: 500px;
}

.shake {
  animation-name: shake;
  animation-duration: 1s;
  /*animation-fill-mode: both;*/
}
</style>
