<template>
  <MaterialCard icon="fa-paperclip" title="Fichiers" style="min-height: 13.5rem">
    <div class="listbox--actions-add">
      <v-menu left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" fab small v-on="on" color="primary">
            <v-icon small>fa-plus fa-fw</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="devisModal = true" v-if="this.$store.state.settings.sage">
            <v-list-item-title>
              <v-icon small class="mr-1">far fa-file-alt fa-fw</v-icon>
              Devis SAGE
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item @click="fileModal = true">
            <v-list-item-title>
              <v-icon small class="mr-1">far fa-file-archive fa-fw</v-icon>
              Fichiers
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- Devis add -->
    <modal v-model="devisModal">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Import de devis</strong>
        <br /><small class="mt-0">Choisissez parmi les devis proposés</small>
      </template>
      <v-autocomplete
        :menu-props="{ maxHeight: '300' }"
        v-model="selectedDevis"
        :items="listDevis"
        :loading="isLoading"
        :search-input.sync="search"
        hide-no-data
        persistent-placeholder
        outlined
        hide-selected
        item-text="piece"
        item-value="piece"
        label="Recherche de devis"
        placeholder="Recherche par référence"
        prepend-icon="fa-search"
        class="mt-5"
      ></v-autocomplete>
      <template v-slot:actions>
        <v-btn variant="flat" color="primary" elevation="0" @click="linkDevis"> Enregistrer </v-btn>
        <v-btn color="secondary" text class="mr-4" @click="devisModal = false"> Fermer </v-btn>
      </template>
    </modal>
    <modal v-model="showImage" max-width="1080">
      <template v-slot:header>
        <strong v-if="showPreviewItem" class="text-h5 mb-0 text-uppercase">{{ showPreviewItem.name ? showPreviewItem.name : "" }}</strong>
        <br /><small class="mt-0"></small>
      </template>
      <template v-if="!showPreviewItem.data">
        <v-progress-circular color="primary" indeterminate :size="54" :width="5"></v-progress-circular>
      </template>
      <template v-else>
        <v-card-text style="position: relative">
          <v-btn fab style="position: absolute; right: -5px; top: -5px" small @click="download(showPreviewItem)" color="secondary">
            <v-icon small>fa-download fa-fw</v-icon>
          </v-btn>
          <img :src="showPreviewItem.data" style="max-width: 100%" />
        </v-card-text>
      </template>

      <template v-slot:actions>
        <v-btn color="secondary" text class="mr-4" @click="showImage = false"> Fermer </v-btn>
      </template>
    </modal>

    <!-- add file -->
    <modal v-model="fileModal">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Import de pièces jointes</strong>
        <br /><small class="mt-0">Veillez à limiter la taille de vos pièces</small>
      </template>
      <v-file-input small-chips label="Lier un fichier" outlined persistent-placeholder v-model="filesUp" class="mt-5"></v-file-input>
      <template v-slot:actions>
        <v-btn variant="flat" color="primary" elevation="0" @click="postFiles"> Enregistrer </v-btn>
        <v-btn color="secondary" text class="mr-4" @click="fileModal = false"> Fermer </v-btn>
      </template>
    </modal>
    <v-row class="mx-1">
      <v-col v-if="listTotalDevis.length == 0 && files.length == 0" class="grey--text text--lighten-1 text-center font-italic mt-2">
        Aucun document joint
      </v-col>
      <v-col cols="12" md="6" lg="4" v-for="(item, key) in listTotalDevis" :key="key">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 2 : 1" class="mx-auto text-center" @click="openDevis(item)">
              <v-card-text>
                <span
                  style="position: absolute; left: 0; top: 10px; border-radius: 0 3px 3px 0; font-size: 0.8em; padding: 1px 5px"
                  class="green lighten-1 white--text"
                  >SAGE</span
                >

                <v-icon x-small color="error" style="position: absolute; top: 5px; right: 5px" @click.stop="removeQuotation(item.header.id)"
                  >fa-trash-alt fa-fw
                </v-icon>

                <v-icon x-large>far fa-file-alt</v-icon>
                <div class="text--grey mt-4" style="color: grey; font-size: 0.7rem">
                  DEVIS {{ item.header.name ? item.header.name + " - " : "" }}{{ item.header.sageIncrement }}
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="4" v-for="(item, key) in files" :key="key">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 2 : 1" class="mx-auto text-center" @click="openSecureFile(item)">
              <v-card-text>
                <v-icon x-large v-if="!item.preview">
                  {{ getIcon(getExtension(item.file)) }}
                </v-icon>
                <div
                  v-else
                  :style="'height: 40px; width: 100%;background-image:url(' + item.preview + ');background-size:cover;background-position:center center'"
                ></div>
                <div class="mt-4">
                  <span style="color: grey; font-size: 0.7rem">{{ getFileName(item.name) }}</span>
                  <!--  <span style="color: grey;font-size: 0.6rem;"> ({{item.size}})</span>-->
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <modal v-model="devisShowModal" max-width="1024">
      <template v-slot:header v-if="showingItem">
        <strong class="text-h5 mb-0 text-uppercase">Devis {{ showingItem.header.sageIncrement }}</strong>
        <br /><small class="mt-0">
          <template>
            {{ showingItem.header.name ? showingItem.header.name : "" }}
          </template>
        </small>
      </template>
      <div v-if="showingItem">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Article</th>
                <th class="text-left">Nom</th>
                <th class="text-right">Prix unitaire</th>
                <th class="text-right">Quantité</th>
                <th class="text-right">Remise</th>
                <th class="text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in showingItem.item" :key="item.name">
                <td class="text-left">{{ item.sku }}</td>
                <td class="text-left">{{ item.body }}</td>
                <td class="text-right">{{ item.quantity ? numStr(item.unitPrice) : "" }}</td>
                <td class="text-right">{{ item.quantity ? item.quantity : "" }}</td>
                <td class="text-right">{{ item.quantity ? item.rebate : "" }}</td>
                <td class="text-right">{{ item.quantity ? numStr(item.unitPrice * item.quantity) : "" }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="5" class="text-right font-weight-bold">Total :</th>
                <th colspan="1" class="text-right font-weight-bold">{{ numStr(getTotal()) }}</th>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </div>
    </modal>
  </MaterialCard>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import MaterialCard from "../../components/common/MaterialCard";
import Modal from "../../components/common/modal";
import { icons, extToType } from "../../utils/fileType";

export default {
  name: "filesOpportunities.vue",
  components: { Modal, MaterialCard },
  mixins: [simpleDebounce],
  data() {
    return {
      count: [],
      filesUp: null,
      files: [],
      devisModal: false,
      fileModal: false,
      search: "",
      selectedDevis: null,
      listDevis: [],
      isLoading: false,
      listTotalDevis: [],
      devisShowModal: false,
      showingItem: null,
      showImage: false,
      showPreviewItem: {},
    };
  },
  mounted() {
    this.loadFiles();
    this.loadDevis();
  },
  methods: {
    async removeQuotation(id) {
      const res = await this.$confirm("Êtes-vous sur ?", { title: "Confirmation", color: "primary" });
      if (res) {
        this.$axios.delete("/api/sage/devis/" + id).then(() => {
          this.loadDevis();
          this.$simpleToast.success("Pièces jointes opportunité", "L'élément a bien été supprimé");
        });
      }
    },
    openSecureFile(item) {
      if (item.preview) {
        this.showPreviewItem = {};
        this.showImage = true;
        this.$axios("/api/medias/previewInModal/" + item.id).then((res) => {
          this.showPreviewItem = {
            id: item.id,
            name: item.name,
            data: res.data.src,
          };
        });
      } else {
        this.download(item);
      }
    },
    download(item) {
      this.$axios("/api/medias/read/" + item.id, { responseType: "blob" }).then((res) => {
        try {
          if (res.data) {
            this.itemPreview;
            let fileUrl = window.URL.createObjectURL(res.data);
            let fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", item.name);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    getTotal() {
      let tmp = 0;
      if (this.showingItem && this.showingItem.item.length > 0) {
        this.showingItem.item.forEach((item) => {
          tmp += parseFloat(item.unitPrice * item.quantity);
        });
      }
      return tmp;
    },
    getExtension(name) {
      var re = /(?:\.([^.]+))?$/;
      let tmp = re.exec(name);
      return tmp[1];
    },
    getIcon(ext) {
      const type = extToType[ext] || "file";
      return icons[type];
    },
    getFileName(name) {
      let tmp = name.split("/");
      return tmp[tmp.length - 1].replaceAll("_", " ").replace("." + this.getExtension(name), "");
    },
    loadFiles() {
      this.$axios("/api/medias/" + this.$route.params.id).then((res) => {
        this.files = res.data;
      });
    },
    postFiles() {
      let formData = new FormData();
      formData.append("file", this.filesUp);

      this.$axios
        .post("/api/medias/" + this.$route.params.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.files = res.data;
          this.filesUp = null;
          this.fileModal = false;
          this.$simpleToast.success("Pièces jointes apportunité", "Le fichier a été ajouté");
        });
    },
    loadDevis() {
      this.$axios("/api/sage/devis/" + this.$route.params.id).then((resp) => {
        this.listTotalDevis = resp.data;
      });
    },
    searchDevis() {
      this.$axios("/api/sage/devis?s=" + this.search + "&c=" + this.$route.params.id).then((resp) => {
        this.listDevis = resp.data;
      });
    },
    linkDevis() {
      this.$axios.post("/api/sage/devis/link", { id: this.selectedDevis, opp: this.$route.params.id }).then(() => {
        this.loadDevis();
        this.devisModal = false;
        this.$simpleToast.success("Pièces jointes apportunité", "Le devis a bien été lié");
      });
    },
    openDevis(item) {
      this.showingItem = item;

      this.devisShowModal = true;
    },
    numStr(nbr) {
      var nombre = "" + nbr;
      var retour = "";
      var count = 0;
      for (var i = nombre.length - 1; i >= 0; i--) {
        if (count != 0 && count % 3 == 0) retour = nombre[i] + " " + retour;
        else retour = nombre[i] + retour;
        count++;
      }
      let tmp = retour.split(".");

      return tmp[0];
    },
  },
  watch: {
    "$route.params.id"() {
      this.loadFiles();
      this.loadDevis();
    },
    search() {
      this.simpleDebounce(() => {
        this.searchDevis();
      }, 300 /* optional debounce time: default is 300 */);
    },
  },
};
</script>

<style scoped></style>
