var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      "menu-props": { maxHeight: "300" },
      items: _vm.concurrentsList,
      loading: _vm.isLoading,
      "search-input": _vm.search.name,
      "item-text": "entreprise.name",
      "item-value": "entreprise.concurrents.id",
      label: "Rechercher un concurrent",
      "prepend-icon": _vm.icon.default,
    },
    on: {
      "update:searchInput": function ($event) {
        return _vm.$set(_vm.search, "name", $event)
      },
      "update:search-input": function ($event) {
        return _vm.$set(_vm.search, "name", $event)
      },
    },
    model: {
      value: _vm.selectedConcurrent,
      callback: function ($$v) {
        _vm.selectedConcurrent = $$v
      },
      expression: "selectedConcurrent",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }