<template>
  <div id="app">
    <template v-if="$store.getters.isLoggedIn && $route.name === 'CustomersExtension'"><router-view></router-view> </template>
    <template v-else>
      <template v-if="$store.state.settings">
        <template v-if="isGranted('MAINTENANCE_ACCESS') || !$store.state.settings.maintenance">
          <v-app v-if="$route.name !== 'Login'" :style="{ background: $vuetify.theme.themes.light.background }">
            <Menu v-if="$store.getters.isLoggedIn" />
            <v-main style="position: relative">
              <PreprodBanner v-if="preprodBanner" />
              <v-container fluid>
                <v-row class="fill-height">
                  <v-col>
                    <transition name="fade">
                      <router-view></router-view>
                    </transition>
                  </v-col>
                </v-row>
              </v-container>
              <img v-if="false" src="images/logo.png" style="max-width: 150px; position: absolute; right: 5px; top: -15px; z-index: 0" />
            </v-main>
          </v-app>
          <v-app v-else-if="$store.state.settings && !$store.state.settings.maintenance">
            <router-view></router-view>
          </v-app>
        </template>
        <SWRefresh :toggleRefreshModal="toggleRefreshModal" :refreshModal="refreshModal" v-if="refreshModal" />
        <v-app v-else style="background-color: #f9fafb">
          <v-container fluid fill-height="fill-height">
            <v-layout align-center="align-center" justify-center="justify-center">
              <v-flex class="login-form text-xs-center">
                <v-card class="text-center" elevation="0" style="background: transparent">
                  <article>
                    <svg style="width: 75px; margin-top: 1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.24 202.24">
                      <defs></defs>
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Capa_1" data-name="Capa 1">
                          <path
                            class="cls-1"
                            d="M101.12,0A101.12,101.12,0,1,0,202.24,101.12,101.12,101.12,0,0,0,101.12,0ZM159,148.76H43.28a11.57,11.57,0,0,1-10-17.34L91.09,31.16a11.57,11.57,0,0,1,20.06,0L169,131.43a11.57,11.57,0,0,1-10,17.34Z"
                          />
                          <path
                            class="cls-1"
                            d="M101.12,36.93h0L43.27,137.21H159L101.13,36.94Zm0,88.7a7.71,7.71,0,1,1,7.71-7.71A7.71,7.71,0,0,1,101.12,125.63Zm7.71-50.13a7.56,7.56,0,0,1-.11,1.3l-3.8,22.49a3.86,3.86,0,0,1-7.61,0l-3.8-22.49a8,8,0,0,1-.11-1.3,7.71,7.71,0,1,1,15.43,0Z"
                          />
                        </g>
                      </g>
                    </svg>
                    <h1 class="grey--text text--darken-3 my-6">Maintenance</h1>
                    <div>
                      <p class="text-caption">Désolé pour le dérangement. Le GRC est actuellement en maintenance. Nous serons de retour sous peu!</p>
                      <p class="text-caption">&mdash; L'équipe Actuelburo</p>
                      <v-col cols="12" v-if="isGranted('MAINTENANCE_ACCESS')">
                        <v-btn color="primary">Accéder au GRC</v-btn>
                      </v-col>
                    </div>
                  </article>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-app>
      </template>
    </template>
  </div>
</template>

<script>
import Menu from "./components/sidebar/Menu";
import Tools from "./mixins/Tools";
import PreprodBanner from "./components/common/PreprodBanner.vue";
import SWRefresh from "./components/common/SWRefresh.vue";
import dayjs from "dayjs";

export default {
  name: "App",
  mixins: [Tools],
  components: { Menu, PreprodBanner, SWRefresh },
  data: () => ({
    refreshModal: false,
    preprodBanner: process.env.VUE_APP_PREPROD_BANNER === "true",
  }),
  created() {
    console.log("%cACTUELBURO - GRC", "color: #005AAB; font-size: 26px;font-weight:bold");
    console.log("%ccopyright 2016-2023", "color: #494949; font-size: 11px; ");

    this.$axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        throw err;
      });
    });
  },
  mounted() {
    document.addEventListener("swUpdated", this.showRefreshUI);
    this.toggleRefreshModal();
    sessionStorage.removeItem("searchCusto");
    sessionStorage.removeItem("pageCusto");
    sessionStorage.removeItem("allAgencyCusto");
    sessionStorage.removeItem("searchSubscriptions");
    sessionStorage.removeItem("asUserCusto");
    sessionStorage.removeItem("searchOpp");
    sessionStorage.removeItem("searchInfras");
    sessionStorage.removeItem("curTypeInfra");
    sessionStorage.removeItem("asUserOpp");
    sessionStorage.removeItem("pageOpp");
    sessionStorage.removeItem("itemsInfras");
    this.getSettings();
  },
  beforeDestroy() {
    document.removeEventListener("swUpdated", this.showRefreshUI);
  },
  methods: {
    async validity365() {
      let authTime = localStorage.getItem("authTime");
      if (dayjs(authTime).isBefore(dayjs(authTime).add(1, "hour"))) return;
      try {
        let tokenResponse = await this.$msal.acquireTokenSilent({
          account: this.$msal.getAllAccounts()[0],
          scopes: ["user.read", "mail.send", "Mail.ReadBasic", "Mail.ReadWrite", "Mail.Read", "Contacts.Read"],
          //redirectUri: process.env.VUE_APP_BASE + this.$route.path,
        });
        console.log("Token refreshed", tokenResponse);
        localStorage.setItem("authTime", dayjs());
      } catch (e) {
        console.log("Token expired");
        this.$store.dispatch("logout").then(() => {
          this.$router.push("/login").catch(() => {});
        });
      }
    },
    toggleRefreshModal() {
      this.refreshModal = !this.refreshModal;
    },
    showRefreshUI() {
      this.refreshModal = true;
    },
    getSettings() {
      this.$axios("/app/settings").then((resp) => {
        this.$store.state.settings = resp.data;
        if (resp.data.errorSage) {
          this.$simpleToast.error(
            "Erreur de connexion SAGE.",
            "L'ajout de compte et de contact peut subir des perturbations.<br/>Contactez un administrateur."
          );
        }
      });
    },
  },
  watch: {
    "$route.name"() {
      // if (window.isSecureContext) {
      //   if (navigator.serviceWorker)
      //     navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
      //       try {
      //         if (registrationsArray && registrationsArray.length > 0) registrationsArray[0].update();
      //       } catch (e) {
      //         console.log(e, registrationsArray, navigator.serviceWorker);
      //       }
      //     });
      // }

      this.getSettings();
      if (this.$route.name !== "Login") this.validity365(this.$route.path);
    },
  },
};
</script>

<style>
.spinInfinite {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
