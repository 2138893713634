var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "material-card",
        {
          staticClass: "v-card-profile mt-10",
          attrs: {
            color: "primary",
            icon: "fa-paper-plane",
            header: true,
            title: "Helios",
          },
        },
        [
          _vm.heliosTickets === false
            ? _c("div", { staticClass: "font-weight-light grey--text mb-5" }, [
                _vm._v("Ce compte ne fait parti d'aucune campagne"),
              ])
            : _c("v-data-table", {
                staticClass: "elevation-0",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.filteredTickets,
                  loading: _vm.loading,
                  "item-per-page": "10",
                },
                on: { "click:row": _vm.openHeliosTickets },
                scopedSlots: _vm._u([
                  {
                    key: "body.prepend",
                    fn: function () {
                      return [
                        _c("tr", [
                          _c(
                            "td",
                            [
                              _c("v-text-field", {
                                attrs: { placeholder: "ID" },
                                model: {
                                  value: _vm.filters.idTickets,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "idTickets", $$v)
                                  },
                                  expression: "filters.idTickets",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("v-text-field", {
                                attrs: { placeholder: "Titre" },
                                model: {
                                  value: _vm.filters.titre,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "titre", $$v)
                                  },
                                  expression: "filters.titre",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("v-text-field", {
                                attrs: { placeholder: "Demandeur" },
                                model: {
                                  value: _vm.filters.demandeur,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "demandeur", $$v)
                                  },
                                  expression: "filters.demandeur",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.selectListStatus,
                                  label: "Status",
                                  placeholder: "Status",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.filters.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "status", $$v)
                                  },
                                  expression: "filters.status",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("v-select", {
                                attrs: {
                                  items: [1, 3, 6, 12, 24],
                                  label:
                                    _vm.heliosLastMonths + " Derniers mois",
                                  placeholder: "Derniers mois",
                                },
                                model: {
                                  value: _vm.heliosLastMonths,
                                  callback: function ($$v) {
                                    _vm.heliosLastMonths = $$v
                                  },
                                  expression: "heliosLastMonths",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.status",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-chip",
                          {
                            staticClass: "white--text",
                            attrs: {
                              color:
                                item.status === "Résolu" ? "green" : "#555555",
                              small: "",
                            },
                          },
                          [_vm._v(" " + _vm._s(item.status))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.dateDerniereReponse",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm
                                .dayjs(item.dateDerniereReponse)
                                .format("HH:mm DD/MM/YYYY")
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { "max-width": "1000px" },
          scopedSlots: _vm._u(
            [
              _vm.selectedTicket?.titre
                ? {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center justify-space-between",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "white--text mr-2",
                                    attrs: { small: "" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.selectedTicket?.idTickets)
                                    ),
                                  ]
                                ),
                                _c(
                                  "strong",
                                  {
                                    staticClass: "text-h5 mb-0 text-uppercase",
                                  },
                                  [_vm._v(_vm._s(_vm.selectedTicket?.titre))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _vm.selectedTicket?.status
                                  ? [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "white--text mr-10 mt-0",
                                          attrs: {
                                            color:
                                              _vm.selectedTicket?.status ===
                                              "Résolu"
                                                ? "green"
                                                : "#555555",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selectedTicket?.status)
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
          model: {
            value: _vm.ticketModal,
            callback: function ($$v) {
              _vm.ticketModal = $$v
            },
            expression: "ticketModal",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "ma-2" },
            [
              _c(
                "v-col",
                { staticClass: "text-left", attrs: { cols: "8" } },
                [
                  _c(
                    "span",
                    { staticClass: "text-button font-weight-medium" },
                    [_vm._v("Informations")]
                  ),
                  _c("v-divider", { staticClass: "mb-2" }),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Demandeur :"),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.selectedTicket?.demandeur) + " "
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Assigné à :"),
                        ]),
                        _vm._v(" " + _vm._s(_vm.selectedTicket?.assigne) + " "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Dernier correspondant :"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.selectedTicket?.dernierCorrespondant) +
                            " "
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Type :"),
                        ]),
                        _vm._v(" " + _vm._s(_vm.selectedTicket?.type) + " "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Catégorie :"),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.selectedTicket?.categorie) + " "
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Pole :"),
                        ]),
                        _vm._v(" " + _vm._s(_vm.selectedTicket?.pole) + " "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Niveau :"),
                        ]),
                        _vm._v(" " + _vm._s(_vm.selectedTicket?.niveau) + " "),
                      ]),
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Priorité :"),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.selectedTicket?.priorite) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-left", attrs: { cols: "4" } },
                [
                  _c(
                    "span",
                    { staticClass: "text-button font-weight-medium" },
                    [_vm._v("Dates")]
                  ),
                  _c("v-divider", { staticClass: "mb-2" }),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Date de création :"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm
                                .dayjs(_vm.selectedTicket?.dateCreation)
                                .format("DD/MM/YYYY à HH:mm")
                            ) +
                            " "
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Date de rappel :"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedTicket?.dateRappel
                                ? _vm
                                    .dayjs(_vm.selectedTicket?.dateRappel)
                                    .format("DD/MM/YYYY à HH:mm")
                                : "Pas de rappel"
                            ) +
                            " "
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Date de dernière réponse :"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedTicket?.dateDerniereReponse
                                ? _vm
                                    .dayjs(
                                      _vm.selectedTicket?.dateDerniereReponse
                                    )
                                    .format("DD/MM/YYYY à HH:mm")
                                : "Pas de réponse"
                            ) +
                            " "
                        ),
                      ]),
                      _vm.selectedTicket?.dateResolution
                        ? _c("v-col", { attrs: { cols: "12" } }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Date de résolution :"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .dayjs(_vm.selectedTicket?.dateResolution)
                                    .format("DD/MM/YYYY à HH:mm")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.selectedTicket?.description
                ? _c(
                    "v-col",
                    { staticClass: "ma-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        { staticClass: "text-button font-weight-medium" },
                        [_vm._v("Description")]
                      ),
                      _c("v-divider", { staticClass: "mb-2" }),
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("p", [
                            _vm._v(_vm._s(_vm.selectedTicket?.description)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }