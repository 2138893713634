var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MaterialCard",
    { staticClass: "mt-8", attrs: { title: "Opps. ouvertes" } },
    [
      _c("bar-chart", {
        staticClass: "chartBar",
        attrs: { chartData: _vm.chartData, chartOptions: _vm.chartOptions },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }