var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("title-supervision", {
                model: {
                  value: _vm.item,
                  callback: function ($$v) {
                    _vm.item = $$v
                  },
                  expression: "item",
                },
              }),
              _c("caracteristique-supervision", {
                staticClass: "mt-14",
                model: {
                  value: _vm.item,
                  callback: function ($$v) {
                    _vm.item = $$v
                  },
                  expression: "item",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("software-supervision", {
                model: {
                  value: _vm.item,
                  callback: function ($$v) {
                    _vm.item = $$v
                  },
                  expression: "item",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }