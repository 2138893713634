<template>
  <div class="extension-customers-container">
    <v-row>
      <template v-if="showCustomers">
        <v-col cols="4">
          <v-text-field v-model="searchCustomers.name" label="Nom" dense class="text-field" color="#fff" background-color="#004f9f" filled></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="searchCustomers.siret"
            label="Siret"
            dense
            class="text-field"
            color="#fff"
            background-color="#004f9f"
            small
            filled
          ></v-text-field>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="4">
          <v-text-field v-model="searchContacts.lastname" label="Nom" dense class="text-field" color="#fff" background-color="#004f9f" filled></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="searchContacts.firstname"
            label="Prenom"
            dense
            class="text-field"
            color="#fff"
            background-color="#004f9f"
            filled
          ></v-text-field>
        </v-col>
      </template>
      <v-col cols="4">
        <v-btn-toggle v-model="showCustomers" group dense small>
          <v-btn :value="true" dense small><span class="text-white">Client</span></v-btn>
          <v-btn :value="false" dense small><span class="text-white">Contact</span></v-btn>
        </v-btn-toggle>
        <div class="notificationBell">
          <NotificationBell />
        </div>
      </v-col>
    </v-row>

    <v-data-table
      class="data-table primary"
      loading
      loading-text="Chargement..."
      dense
      color="#004f9f"
      @click:row="linkToEntity"
      :headers="showCustomers ? headersCustomers : headersContacts"
      :items="showCustomers ? customers : contacts"
      :items-per-page="limitItem"
      :hide-default-header="true"
      :server-items-length="maxItem"
      mobile-breakpoint="0"
      no-data-text="Aucun résultat"
      no-results-text="Aucun résultat"
      @update:page="setPage"
      :footer-props="{
        dense: true,
        itemsPerPageOptions: [9],
        showFirstLastPage: true,
        'first-icon': 'mdi-chevron-double-left mdi-light',
        'prev-icon': 'mdi-chevron-left mdi-light',
        'next-icon': 'mdi-chevron-right mdi-light',
        'last-icon': 'mdi-chevron-double-right mdi-light',
      }"
    >
      <template v-slot:item.contact.phone="{ item }">
        <div v-if="item.contact.phone" class="text-no-wrap text-white">
          <v-icon x-small class="mr-1">fa-fw fa-phone</v-icon><a class="text-white" :href="'tel:' + item.contact.phone">{{ item.contact.phone }}</a>
        </div>
        <div v-if="item.contact.phone2" class="text-no-wrap text-white">
          <v-icon x-small class="mr-1">fa-fw fa-mobile-alt</v-icon><a class="text-white" :href="'tel:' + item.contact.phone2">{{ item.contact.phone2 }}</a>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import NotificationBell from "../../components/common/NotificationBell.vue";

export default {
  name: "CustomersContactsList",
  mixins: [simpleDebounce],
  components: { NotificationBell },
  data() {
    return {
      showCustomers: true,
      headersCustomers: [
        { text: "Nom", value: "entreprise.name", align: "start", sortable: false, class: "text-left data-table" },
        { text: "Siret", value: "entreprise.siret", align: "end", sortable: false, class: "text-left data-table" },
      ],
      headersContacts: [
        {
          text: "Nom",
          value: "contact.fullname",
          align: "start",
          sortable: false,
          class: "text-left data-table",
        },
        { text: "phone", value: "contact.phone", align: "end", sortable: false, class: "text-left data-table" },
      ],
      customers: [],
      contacts: [],
      limitItem: 9,
      campaign: false,
      searchCustomers: {
        name: "",
        isIndirect: null,
        isKey1: null,
        isKey2: null,
        isKey3: null,
        isKey4: null,
        type: null,
        siret: "",
        postalCode: "",
        city: "",
        sector: "",
        phone: "",
        ctNumAb: "",
        ape: "",
        sortedBy: null,
        sortedDesc: false,
        cityPostalCode: "",
      },
      searchContacts: {
        lastname: "",
        firstname: "",
        function: "",
        customer: "",
        phone: "",
        phone2: "",
        email: "",
        sortedBy: null,
        sortedDesc: false,
      },
      maxItem: 0,
      page: 1,
    };
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    linkToEntity(row) {
      const route = this.$router.resolve({ name: "Client", params: { id: row?.contact?.id ? row.customer[0].id : row.customer.id } });
      window.open(route.href, "_blank");
    },
    setPage(event) {
      this.page = event;
    },
    getContacts() {
      this.contacts = [];
      let page = this.page;
      this.$axios("/api/contacts", { params: { page: page, search: this.searchContacts, limit: this.limitItem, asUser: false } }).then((res) => {
        this.contacts = res.data.data;
        this.maxItem = res.data.max;
        this.contacts = this.contacts.map((contact) => {
          let fullname = contact.contact.lastname + " " + contact.contact.firstname;
          return {
            ...contact,
            contact: {
              ...contact.contact,
              fullname: fullname,
            },
          };
        });
      });
    },
    getCustomers() {
      this.customers = [];
      this.$axios("/api/customers", {
        params: {
          page: this.page,
          search: this.searchCustomers,
          limit: this.limitItem,
          asUser: [],
          asType: [],
          userMode: "tous",
          typeMode: "tous",
          prospectOnly: true,
          clientOnly: true,
        },
      }).then((resp) => {
        this.maxItem = resp.data.max;
        this.customers = resp.data.data;
      });
    },
    getCustomersDebounce() {
      this.simpleDebounce(() => {
        this.getCustomers();
      }, 300 /* optional debounce time: default is 300 */);
    },
    getContactsDebounce() {
      this.simpleDebounce(() => {
        this.getContacts();
      }, 300 /* optional debounce time: default is 300 */);
    },
  },
  watch: {
    showCustomers(value) {
      this.searchContacts.lastname = "";
      this.searchContacts.firstname = "";
      this.searchCustomers.name = "";
      this.searchCustomers.siret = "";
      this.page = 1;
      this.maxItem = 0;
      value ? this.getCustomers() : this.getContacts();
    },
    "searchContacts.firstname"() {
      this.page = 1;
      this.getContactsDebounce();
    },
    "searchContacts.lastname"() {
      this.page = 1;
      this.getContactsDebounce();
    },
    "searchCustomers.name"() {
      this.page = 1;
      this.getCustomersDebounce();
    },
    "searchCustomers.siret"() {
      this.page = 1;
      this.getCustomersDebounce();
    },
    page() {
      this.showCustomers ? this.getCustomers() : this.getContacts();
    },
  },
};
</script>

<style>
.text-white {
  color: white !important;
}

.extension-customers-container {
  overflow: hidden;
  width: 500px;
  min-width: 500px;
  min-height: 400px;
  height: 400px;
  background-color: #004f9f;
  color: white;
}

.text-left {
  text-align: left !important;
}

.text-field {
  font-size: 12px !important;
  margin: 0;
  padding: 0;
}

.text-field .v-text-field__details {
  display: none !important;
  height: 0;
}

.data-table {
  margin-top: 0;
  padding-top: 0;
  background-color: #004f9f !important;
  color: white !important;
}

.data-table .v-data-table__empty-wrapper {
  color: white !important;
}

.data-table .v-data-table__wrapper {
  color: white !important;
}

.extension-customers-container .v-text-field__slot input {
  color: white !important;
}

.extension-customers-container .v-text-field__slot label {
  color: white !important;
}

.data-table .v-data-footer {
  display: flex;
  justify-content: flex-end;
}

.extension-customers-container .v-list-item__icon {
  align-self: end;
  text-align: end !important;
  margin: 0;
  color: white !important;
}

.notificationBell {
  text-align: end;
  margin-right: 5px;
  margin-bottom: 5px;
}

.data-table tbody tr:hover {
  background-color: #ffffff20 !important;
}
</style>
