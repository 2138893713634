var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "material-card",
    { attrs: { title: "Écurie" } },
    [
      _c(
        "v-list",
        [
          _c("challengeTablesHeader", { attrs: { ecurie: true } }),
          _vm._l(_vm.ecurie, function (item, i) {
            return _c(
              "div",
              { key: i },
              [
                _c(
                  "v-list-item",
                  [
                    _c("v-col", { attrs: { cols: "1" } }, [
                      _vm._v(_vm._s(i + 1)),
                    ]),
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "py-0", attrs: { cols: "6" } },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 text-right",
                                attrs: { cols: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      Math.round(item.ca).toLocaleString("fr")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 text-right",
                                attrs: { cols: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      Math.round(item.gain).toLocaleString("fr")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 text-right",
                                attrs: { cols: "2" },
                              },
                              [
                                _vm._v(
                                  " +" +
                                    _vm._s(
                                      Math.round(item.points).toLocaleString(
                                        "fr"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider"),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }