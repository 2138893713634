<template>
  <MaterialCard title="Opps. ouvertes" class="mt-8">
    <bar-chart :chartData="chartData" :chartOptions="chartOptions" class="chartBar"></bar-chart>
  </MaterialCard>
</template>

<script>
import { Bar } from "vue-chartjs";
import dayjs from "dayjs";
import MaterialCard from "../../components/common/MaterialCard";

export default {
  name: "OpportunitiesIncomingStatistics",
  components: { MaterialCard, "bar-chart": Bar },
  props: ["value"],
  data() {
    return {
      stats: this.value,
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.65,
        elements: {
          bar: {
            borderWidth: 1,
            borderColor: "#fff",
          },
        },
        scales: {
          y: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return value / 1000 + "k";
              },
            },
          },
          x: {
            stacked: true,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            align: "center",
            position: "bottom",
            labels: {
              padding: 20,
              usePointStyle: true,
              textAlign: "right",
              font: {
                size: 11,
                weight: "bold",
              },
            },
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                let arrayMois = Object.values(tooltipItem.parsed._stacks.y).slice(0, Object.values(tooltipItem.parsed._stacks.y).length - 3);
                const totalMois = arrayMois.reduce((a, b) => a + b, 0);
                return `${tooltipItem.dataset.label}: ${tooltipItem.parsed.y} Total: ${totalMois}`;
              },
            },
          },
        },
      },
    };
  },
  mounted() {
    this.initSeries();
  },
  methods: {
    initSeries() {
      if (this.stats.opened) {
        this.chartData.labels = [];
        Object.keys(this.stats.opened).forEach((item) => {
          this.chartData.labels.push(dayjs(new Date(item), "mm-dd-yy").add("1", "month").format("MMM YYYY"));
        });

        let datasetInfo = {};
        let isDataPresent = {};

        Object.keys(this.stats.colors).forEach((item) => {
          datasetInfo[item] = { label: item, backgroundColor: this.stats.colors[item], data: [] };
          isDataPresent[item] = false;
        });

        Object.keys(this.stats.opened).forEach((item) => {
          Object.keys(this.stats.opened[item]).forEach((bu) => {
            isDataPresent[bu] = true;
            datasetInfo[bu].data.push(this.stats.opened[item][bu]);
          });
          Object.keys(isDataPresent).forEach((it) => {
            if (isDataPresent[it]) {
              isDataPresent[it] = false;
            } else {
              datasetInfo[it].data.push(0);
            }
          });
        });

        this.chartData.datasets = Object.values(datasetInfo);
      }
    },
  },
  watch: {
    value() {
      this.stats = this.value;
      this.initSeries();
    },
  },
};
</script>

<style scoped>
.chartBar {
  min-height: 400px;
}
</style>
