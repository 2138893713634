<template>
  <MaterialCard title="Pipeline" class="mt-8">
    <v-row v-if="stats && stats.pipeline" class="py-2">
      <v-col cols="6" class="text-center">
        <h1 class="primary--text py-0 my-0">{{ Math.round(stats.pipeline.totalProba).toLocaleString() }} €</h1>
        <h4 style="font-style: italic; font-weight: normal" class="py-0 my-0">Prévisionnel</h4>
      </v-col>
      <v-col cols="6" class="text-center">
        <h1 class="primary--text my-0">{{ Math.round(stats.pipeline.total).toLocaleString() }} €</h1>
        <h4 style="font-style: italic; font-weight: normal" class="py-0 my-0">Total</h4>
      </v-col>
      <v-col cols="12">
        <Bar :chartData="chartData" :chartOptions="chartOptions" class="chartBar" />
      </v-col>
    </v-row>
  </MaterialCard>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
import { Bar } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels);

export default {
  name: "pipelineStatistics",
  components: { MaterialCard, Bar },
  props: ["value"],
  data() {
    return {
      stats: this.value,
      chartData: {
        labels: ["Identifié", "Étude", "Négociation"],

        datasets: [],
      },
      chartOptions: {
        indexAxis: "y",
        maintainAspectRatio: false,
        barPercentage: 0.65,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return value / 1000 + "k";
              },
            },
          },
          y: {
            grid: {
              display: false, // Ajoutez cette ligne
            },
          },
        },
        plugins: {
          legend: {
            align: "end",
            labels: {
              usePointStyle: true,
              textAlign: "right",
              font: {
                size: 11,
                weight: "bold",
              },
            },
          },
          datalabels: {
            align: function (context) {
              var index = context.dataIndex;
              var value = context.dataset.data[index];
              var chartWidth = context.chart.width;
              var xScale = context.chart.scales["x"];
              var xPos = xScale.getPixelForValue(value);
              return xPos < chartWidth * 0.7 ? "end" : "start";
            },
            color: function (context) {
              var index = context.dataIndex;
              var value = context.dataset.data[index];
              var chartWidth = context.chart.width;
              var xScale = context.chart.scales["x"];
              var xPos = xScale.getPixelForValue(value);
              return xPos < chartWidth * 0.7 ? "#000000" : "#fff";
            },
            anchor: "end",
            font: {
              size: 11,
              weight: "bold",
            },
            formatter: function (value) {
              return Math.round(value).toLocaleString();
            },
          },
        },
      },
    };
  },
  mounted() {},
  methods: {},
  watch: {
    value() {
      this.stats = this.value;
      let newDatasets = [];
      if (this.stats.pipeline.totalStep1Proba || this.stats.pipeline.totalStep2Proba || this.stats.pipeline.totalStep3Proba) {
        newDatasets.push({
          axis: "y",
          label: "Avec probabilité",
          backgroundColor: "#7cb5ec",
          data: [this.stats.pipeline.totalStep1Proba, this.stats.pipeline.totalStep2Proba, this.stats.pipeline.totalStep3Proba],
        });
      }
      if (this.stats.pipeline.totalStep1 || this.stats.pipeline.totalStep2 || this.stats.pipeline.totalStep3) {
        newDatasets.push({
          axis: "y",
          label: "Sans probabilité",
          backgroundColor: "#434348",
          data: [this.stats.pipeline.totalStep1, this.stats.pipeline.totalStep2, this.stats.pipeline.totalStep3],
        });
      }
      this.chartData.datasets = newDatasets;
    },
  },
};
</script>

<style scoped>
.chartBar {
  min-height: 400px;
}
</style>
