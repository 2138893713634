<template>
  <v-container fluid v-if="isGranted('GROUPS_INDEX')">
    <v-card style="z-index: 10;">
      <v-toolbar color="primary " dark elevation="0">
        <v-row>
          <v-col cols="12" md="11">
            <v-text-field v-model="search" label="Rechercher" class="mx-4" prepend-icon="fa-search" hide-details></v-text-field>
          </v-col>
          <v-col cols="12" md="1">
            <v-btn @click="addFct" color="primary" small fab class="listbox--actions-add">
              <v-icon small>fa-plus fa-fw</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table :headers="headers" :items="groups" class="elevation-1" :search="search">
        <template v-slot:item.active="{ item }">
          <v-btn small text>
            <v-icon small :color="item.active ? 'success' : 'warning'">
              {{ item.active ? "fa-check" : "fa-times" }}
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editItem(item)">fa-edit</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <modal v-model="editModal">
      <v-row v-if="editableItem">
        <v-col cols="12" v-if="editableItem.id">
          <v-text-field disabled v-model="editableItem.id" label="#"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-switch inset v-model="editableItem.active" label="Actif"></v-switch>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="editableItem.name" label="Nom"></v-text-field>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn color="error" text @click="editModal = false">
          Fermer
        </v-btn>
        <v-btn text class="mr-4" @click="saveGroups">
          Enregistrer
        </v-btn>
      </template>
    </modal>
  </v-container>
</template>

<script>
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";
export default {
  name: "groupementsSettings",
  components: { Modal },
  mixins: [Tools],
  data() {
    return {
      groups: [],
      search: "",
      editModal: false,
      editableItem: null,
      headers: [
        { text: "#", value: "id", align: "left" },
        { text: "État", value: "active", align: "center" },
        { text: "Nom", value: "name", align: "left" },
        { text: "", value: "actions", align: "right" },
      ],
    };
  },
  mounted() {
    this.getGroupements();
  },
  methods: {
    saveGroups() {
      if (this.editableItem.id) {
        this.$axios.patch("/api/groups/" + this.editableItem.id, { data: this.editableItem }).then(() => {
          this.getGroupements();
          this.$simpleToast.success("Groupements", "Le groupements a été mis à jour");
          this.editModal = false;
        });
      } else {
        this.$axios.post("/api/groups", { data: this.editableItem }).then(() => {
          this.getGroupements();
          this.$simpleToast.success("Groupements", "Le groupements a été ajouté");
          this.editModal = false;
        });
      }
    },
    editItem(item) {
      this.editableItem = item;
      this.editModal = true;
    },
    addFct() {
      this.editableItem = {
        active: true,
        name: "",
      };
      this.editModal = true;
    },
    getGroupements() {
      this.$axios("/api/groups").then((resp) => {
        let tmp = [];
        Object.keys(resp.data).forEach((item) => {
          tmp.push(resp.data[item]);
        });
        this.groups = tmp;
      });
    },
  },
};
</script>

<style scoped></style>
