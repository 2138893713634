import Vuex from "vuex";
import Vue from "vue";
import VueJwtDecode from "vue-jwt-decode";
import HTTP from "../config/axios";
import dayjs from "dayjs";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token-actuelburo") || "",
    initToken: localStorage.getItem("token-actuelburo-init") || "",
    user: {},
    msalIsAuth: false,
    settings: null,
    addHour: 7200,
    authTime: 0,
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token) {
      state.status = "success";
      state.token = token;
      state.initToken = token;

      let tmpString = token.split(" ");
      let decryptedUser = VueJwtDecode.decode(tmpString[1]);

      state.user = decryptedUser.data;
      state.msalIsAuth = true;

      state.authTime = Date.now();
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.initToken = "";
      state.msalIsAuth = false;
      state.authTime = 0;
    },
  },

  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        HTTP({ url: "/auth/login", data: user, method: "POST" })
          .then((resp) => {
            const token = resp.data.token;
            localStorage.setItem("token-actuelburo", token);
            localStorage.setItem("token-actuelburo-init", token);
            localStorage.setItem("authTime", dayjs());
            HTTP.defaults.headers.common["Authorization"] = token;
            commit("auth_success", token);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token-actuelburo");
            localStorage.removeItem("authTime");
            reject(err);
          });
      });
    },
    loginMsal({ commit }, tenant, idToken, accessToken, clientId) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        HTTP({ url: "/auth/msal", data: { tenant, idToken, accessToken, clientId }, method: "POST" })
          .then((resp) => {
            const token = resp.data.token;
            localStorage.setItem("token-actuelburo", token);
            localStorage.setItem("token-actuelburo-init", token);
            localStorage.setItem("authTime", dayjs());
            HTTP.defaults.headers.common["Authorization"] = token;
            commit("auth_success", token);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token-actuelburo");
            localStorage.removeItem("token-actuelburo-init");
            localStorage.removeItem("authTime");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        sessionStorage.removeItem("headersCusto");
        commit("logout");
        localStorage.removeItem("token-actuelburo");
        localStorage.removeItem("authTime");

        delete HTTP.defaults.headers.common["Authorization"];
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    isMsalAuth: (state) => state.msalIsAuth,
    authStatus: (state) => state.status,
    userInfo: (state) => state.user,
  },
});

const token = localStorage.getItem("token-actuelburo");
if (token) {
  HTTP.defaults.headers.common["Authorization"] = token;
  let tmpString = token.split(" ");
  let decryptedUser = VueJwtDecode.decode(tmpString[1]);
  store.state.user = decryptedUser.data;
}

export default store;
