var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.customer
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _vm.customer.customer.obsolete && false
                ? _c(
                    "v-col",
                    {
                      staticStyle: { position: "fixed", "z-index": "20" },
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-0 text-center",
                          attrs: { color: "primary" },
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pa-1",
                              staticStyle: { color: "white" },
                            },
                            [_vm._v("COMPTE EN SOMMEIL")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("titleCustomers", {
                    on: {
                      "refresh-all": _vm.getCustomer,
                      "reload-contact": _vm.setReloadContact,
                    },
                    model: {
                      value: _vm.customer,
                      callback: function ($$v) {
                        _vm.customer = $$v
                      },
                      expression: "customer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("opportunitiesCustomers", {
                            model: {
                              value: _vm.customer,
                              callback: function ($$v) {
                                _vm.customer = $$v
                              },
                              expression: "customer",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("Event", {
                            attrs: { fromCustomers: "" },
                            model: {
                              value: _vm.customer,
                              callback: function ($$v) {
                                _vm.customer = $$v
                              },
                              expression: "customer",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [_c("compaign-customers")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("contactCustomers", {
                            attrs: { reloadContact: _vm.reloadContact },
                            on: { "refresh-all": _vm.getCustomer },
                            model: {
                              value: _vm.customer,
                              callback: function ($$v) {
                                _vm.customer = $$v
                              },
                              expression: "customer",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("emailList", {
                            on: { "refresh-all": _vm.getCustomer },
                            model: {
                              value: _vm.customer,
                              callback: function ($$v) {
                                _vm.customer = $$v
                              },
                              expression: "customer",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.customer.customer.id
                        ? _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("heliosCustomers", {
                                model: {
                                  value: _vm.customer,
                                  callback: function ($$v) {
                                    _vm.customer = $$v
                                  },
                                  expression: "customer",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [_c("statsCustomers")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }