<template>
  <v-container fluid class="pa-0">
    <material-card class="v-card-profile mt-10" :color="'primary'" icon="fa-paper-plane" :header="true" title="Helios">
      <div v-if="heliosTickets === false" class="font-weight-light grey--text mb-5">Ce compte ne fait parti d'aucune campagne</div>
      <v-data-table
        v-else
        :headers="headers"
        :items="filteredTickets"
        class="elevation-0"
        :loading="loading"
        item-per-page="10"
        @click:row="openHeliosTickets"
      >
        <template v-slot:body.prepend>
          <tr>
            <td><v-text-field v-model="filters.idTickets" placeholder="ID"></v-text-field></td>
            <td><v-text-field v-model="filters.titre" placeholder="Titre"></v-text-field></td>
            <td>
              <v-text-field v-model="filters.demandeur" placeholder="Demandeur"></v-text-field>
            </td>
            <td>
              <v-select v-model="filters.status" :items="selectListStatus" label="Status" placeholder="Status" clearable></v-select>
            </td>
            <td>
              <!-- heliosLastMonths -->
              <v-select
                v-model="heliosLastMonths"
                :items="[1, 3, 6, 12, 24]"
                :label="heliosLastMonths + ' Derniers mois'"
                placeholder="Derniers mois"
              ></v-select>
            </td>
          </tr>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="item.status === 'Résolu' ? 'green' : '#555555'" small class="white--text"> {{ item.status }}</v-chip>
        </template>
        <template v-slot:item.dateDerniereReponse="{ item }">
          {{ dayjs(item.dateDerniereReponse).format("HH:mm DD/MM/YYYY") }}
        </template>
      </v-data-table>
    </material-card>
    <modal v-model="ticketModal" max-width="1000px">
      <template v-slot:header v-if="selectedTicket?.titre">
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-chip small class="white--text mr-2">{{ selectedTicket?.idTickets }}</v-chip>
            <strong class="text-h5 mb-0 text-uppercase">{{ selectedTicket?.titre }}</strong>
          </div>
          <div>
            <template v-if="selectedTicket?.status">
              <v-chip class="white--text mr-10 mt-0" :color="selectedTicket?.status === 'Résolu' ? 'green' : '#555555'">{{
                selectedTicket?.status
              }}</v-chip>
            </template>
          </div>
        </div>
      </template>
      <v-row class="ma-2">
        <v-col cols="8" class="text-left">
          <span class="text-button font-weight-medium">Informations</span>
          <v-divider class="mb-2"></v-divider>
          <v-row>
            <v-col cols="6">
              <span class="font-weight-bold">Demandeur :</span>
              {{ selectedTicket?.demandeur }}
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold">Assigné à :</span>
              {{ selectedTicket?.assigne }}
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <span class="font-weight-bold">Dernier correspondant :</span>
              {{ selectedTicket?.dernierCorrespondant }}
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold">Type :</span>
              {{ selectedTicket?.type }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <span class="font-weight-bold">Catégorie :</span>
              {{ selectedTicket?.categorie }}
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold">Pole :</span>
              {{ selectedTicket?.pole }}
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <span class="font-weight-bold">Niveau :</span>
              {{ selectedTicket?.niveau }}
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold">Priorité :</span>
              {{ selectedTicket?.priorite }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="text-left">
          <span class="text-button font-weight-medium">Dates</span>
          <v-divider class="mb-2"></v-divider>
          <v-row>
            <v-col cols="12">
              <span class="font-weight-bold">Date de création :</span>
              {{ dayjs(selectedTicket?.dateCreation).format("DD/MM/YYYY à HH:mm") }}
            </v-col>
            <v-col cols="12">
              <span class="font-weight-bold">Date de rappel :</span>
              {{ selectedTicket?.dateRappel ? dayjs(selectedTicket?.dateRappel).format("DD/MM/YYYY à HH:mm") : "Pas de rappel" }}
            </v-col>
            <v-col cols="12">
              <span class="font-weight-bold">Date de dernière réponse :</span>
              {{ selectedTicket?.dateDerniereReponse ? dayjs(selectedTicket?.dateDerniereReponse).format("DD/MM/YYYY à HH:mm") : "Pas de réponse" }}
            </v-col>
            <v-col cols="12" v-if="selectedTicket?.dateResolution">
              <span class="font-weight-bold">Date de résolution :</span>
              {{ dayjs(selectedTicket?.dateResolution).format("DD/MM/YYYY à HH:mm") }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="ma-0" v-if="selectedTicket?.description">
          <span class="text-button font-weight-medium">Description</span>
          <v-divider class="mb-2"></v-divider>
          <v-col cols="12" class="pb-0">
            <p>{{ selectedTicket?.description }}</p>
          </v-col>
        </v-col>
      </v-row>
    </modal>
  </v-container>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard.vue";
import Tools from "../../mixins/Tools";
import modal from "../../components/common/modal.vue";
import dayjs from "dayjs";

export default {
  name: "heliosCustomers",
  components: { MaterialCard, modal },
  props: ["value"],
  mixins: [Tools],
  data() {
    return {
      customer: this.value,
      heliosTickets: [],
      selectedTicket: null,
      ticketModal: false,
      typeComp: [],
      loading: false,
      dateStartFormatted: null,
      dateEndFormatted: null,
      selectListStatus: ["Non traité", "A planifier", "Planifié", "En cours", "Rappel", "Résolu"],
      heliosLastMonths: 6,
      headers: [
        { text: "ID", align: "center", sortable: false, value: "idTickets", filterable: true },
        { text: "Titre", align: "start", sortable: false, value: "titre" },
        { text: "Demandeur", align: "start", sortable: false, value: "demandeur" },
        { text: "Status", align: "center", sortable: false, value: "status" },
        { text: "Dernière réponse", align: "center", sortable: false, value: "dateDerniereReponse" },
      ],
      filters: {
        idTickets: "",
        titre: "",
        demandeur: "",
        status: "",
      },
      compaigns: false,
    };
  },
  mounted() {
    this.getHeliosTickets();
  },
  computed: {
    filteredTickets() {
      return this.heliosTickets.filter((ticket) => {
        return Object.keys(this.filters).every((key) => {
          if (!this.filters[key]) return true;
          return ticket[key].includes(this.filters[key]);
        });
      });
    },
  },
  methods: {
    openHeliosTickets(row) {
      this.selectedTicket = row;
      this.ticketModal = true;
    },
    getHeliosTickets() {
      this.loading = true;
      this.$axios("/api/customers/" + this.customer.customer.id + "/helios", { params: { heliosLastMonths: this.heliosLastMonths } }).then(
        (resp) => {
          this.heliosTickets = this.mergeArraysFromObject(resp.data.heliosTickets);
          this.loading = false;
        }
      );
    },
    mergeArraysFromObject(obj) {
      const mergedArray = Object.keys(obj)
        .filter((key) => Array.isArray(obj[key]))
        .reduce((acc, key) => acc.concat(obj[key]), []);
      const sortedArray = mergedArray.sort((a, b) => dayjs(b.dateDerniereReponse).diff(dayjs(a.dateDerniereReponse)));
      return sortedArray;
    },
  },
  watch: {
    heliosLastMonths() {
      this.getHeliosTickets();
    },
  },
};
</script>

<style scoped></style>
