<template>
  <material-card title="Bonus">
    <v-container fluid>
      <v-row>
        <v-col cols="4" class="py-0 text-center">
          <img src="/images/challenge/bag.svg" height="48" alt="" />
        </v-col>
        <v-col cols="8" class="py-0">
          <div class="text-overline primary--text" style="font-size: 1rem !important">{{ challenge.classement.max_gain.value.name }}</div>
          <div style="font-size: 0.8em">
            <div v-if="challenge.classement.max_gain.user === $store.state.user.id || isGranted('CHALLENGES_VIEW_AMOUNT')">
              Marge : <strong>{{ Math.round(challenge.classement.max_gain.value.gain).toLocaleString("fr") }} €</strong>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </material-card>
</template>

<script>
import MaterialCard from "../../components/common/MaterialCard";
import Tools from "../../mixins/Tools";
export default {
  name: "bonusChallenge",
  props: ["value"],
  mixins: [Tools],
  components: { MaterialCard },
  data() {
    return {
      challenge: this.value,
    };
  },
  watch: {
    value() {
      this.challenge = this.value;
    },
  },
};
</script>

<style scoped></style>
