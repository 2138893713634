<template>
  <bar-chart :chartData="chartData" :chartOptions="chartOptions"></bar-chart>
</template>

<script>
import { Bar } from "vue-chartjs";
export default {
  name: "AffectChart",
  components: { "bar-chart": Bar },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.datasets,
      },
      chartOptions: {
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1,
        barPercentage: 1,
        categoryPercentage: 0.8,
        elements: {
          bar: {
            borderWidth: 1,
            borderColor: "#fff",
          },
        },
        scales: {
          y: {
            stacked: true,
            beginAtZero: true,
          },
          x: {
            stacked: true,
            grid: {
              display: false, // Ajoutez cette ligne
            },
            ticks: {
              callback: function (value) {
                return value / 1000 + "k";
              },
            },
          },
        },
        plugins: {
          legend: {
            align: "end",
            position: "top",
            labels: {
              padding: 10,
              usePointStyle: true,
              textAlign: "right",
              font: {
                size: 11,
                weight: "bold",
              },
            },
          },
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
};
</script>

<style scoped></style>
