<template>
  <div style="margin-top: -25px">
    <Landscape />
    <v-card elevation="1">
      <v-toolbar color="primary" elevation="0" dark>
        <v-card-title>
          Statistiques de mon service
          <div
            style="
              position: absolute;
              top: 0;
              left: 50%;
              background: #78909c;
              padding: 5px;
              translate: -50% 0;

              border-radius: 0 0 5px 5px;
            "
          >
            <v-menu offset-y style="z-index: 1111111">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text small v-bind="attrs" v-on="on"> <v-icon small> fa-ellipsis-v fa-fw </v-icon></v-btn>
              </template>
              <v-list style="z-index: 1111111">
                <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICS')" @click="$router.push({ name: 'Statistiques Personnelles' })">
                  <v-list-item-title>Mes statistiques</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isGranted('STATS_COM')" @click="$router.push({ name: 'Statistiques Commerciaux' })">
                  <v-list-item-title>Statistiques commerciaux</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICSAGENCY')" @click="$router.push({ name: 'Statistiques Agence' })">
                  <v-list-item-title>Statistiques de mon agence</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isGranted('OPPORTUNITIES_STATISTICSCROSSSELLING')" @click="$router.push({ name: 'Cross-Selling' })">
                  <v-list-item-title>Cross-selling</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isGranted('EVENTS_EVENTSKEY')" @click="$router.push({ name: 'Relations' })">
                  <v-list-item-title>Relation clients</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isGranted('SECTOR_STATISTICS')" @click="$router.push({ name: 'Affectations' })">
                  <v-list-item-title>Affectations</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-card-title>
      </v-toolbar>
    </v-card>

    <div>
      <v-row class="px-2">
        <v-col cols="12" md="4" class="px-1">
          <pipeline-statistics v-model="stats"></pipeline-statistics>
        </v-col>
        <v-col cols="12" md="8" class="px-1">
          <v-row class="px-1">
            <v-col cols="6">
              <opportunitiesStatistics v-if="stats" label="Cette semaine" v-model="stats.week"></opportunitiesStatistics>
            </v-col>
            <v-col cols="6">
              <opportunitiesStatistics v-if="stats" label="Exercice" v-model="stats.year"></opportunitiesStatistics>
            </v-col>
            <v-col cols="12">
              <mensuelStatistics v-if="stats" v-model="stats"></mensuelStatistics>
            </v-col>
            <v-col cols="12">
              <opportunitiesincomingStatistics v-if="stats" v-model="stats"></opportunitiesincomingStatistics>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PipelineStatistics from "./pipelineStatistics";
import OpportunitiesStatistics from "./opportunitiesStatistics";
import MensuelStatistics from "./mensuelStatistics";
import OpportunitiesincomingStatistics from "./opportunitiesincomingStatistics";
import Tools from "../../mixins/Tools";
import Landscape from "../../components/common/Landscape.vue";
//import dayjs from "dayjs";

export default {
  name: "statisticsPerso",
  mixins: [Tools],
  components: { OpportunitiesincomingStatistics, MensuelStatistics, OpportunitiesStatistics, PipelineStatistics, Landscape },
  data() {
    return {
      stats: null,
    };
  },
  mounted() {
    this.getStats();
  },
  methods: {
    getStats() {
      this.$axios("/api/stats/service").then((resp) => {
        this.stats = resp.data;
      });
    },
  },
};
</script>

<style scoped></style>
