import { render, staticRenderFns } from "./opportunitiesCustomers.vue?vue&type=template&id=3972404b&scoped=true&"
import script from "./opportunitiesCustomers.vue?vue&type=script&lang=js&"
export * from "./opportunitiesCustomers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3972404b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Actuelburo\\srv\\jenkins\\crm-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3972404b')) {
      api.createRecord('3972404b', component.options)
    } else {
      api.reload('3972404b', component.options)
    }
    module.hot.accept("./opportunitiesCustomers.vue?vue&type=template&id=3972404b&scoped=true&", function () {
      api.rerender('3972404b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/customer/opportunitiesCustomers.vue"
export default component.exports