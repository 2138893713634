<template>
  <div>
    <div v-html="textVal.length > maxChars && !toggle ? textShort + '...' : textVal " :style="{height: textVal.length > maxChars && !toggle ? 'auto' : 'auto'}">
    </div>
    <div class="text-overline primary--text " style="cursor:pointer" @click="toggle = !toggle">{{textVal.length > maxChars && !toggle ? moreStr : lessStr}}</div>

  </div>

</template>

<script>
export default {
  props: ["moreStr", "text", "lessStr", "maxChars"],
  name: "read-more",
  data(){
    return {
      toggle: false,
      textVal: "",
      textShort: "",
    }
  },
  mounted() {
    this.textVal = this.text.replaceAll('\n', '<br/>');
    this.textShort = this.text.replaceAll('\n', '').substr(0, 250);
  }
}
</script>

<style scoped>

</style>