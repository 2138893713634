var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isGranted("EVENTS_SETTINGS")
        ? _c(
            "v-card",
            { staticStyle: { "z-index": "10" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary ", dark: "", elevation: "0" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "11" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Rechercher",
                              "single-line": "",
                              "prepend-icon": "fa-search",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "1" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "listbox--actions-add",
                              attrs: { color: "primary", small: "", fab: "" },
                              on: {
                                click: function ($event) {
                                  _vm.edition = {
                                    name: "",
                                    shortcut: false,
                                    teams: null,
                                    duration: null,
                                    defaultText: "",
                                    report: false,
                                    ewsCalendar: false,
                                    icon: "",
                                    colorArr: { r: 0, g: 0, b: 0, a: 1 },
                                  }
                                  _vm.editModal = true
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("fa-plus fa-fw"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.types,
                  search: _vm.search,
                },
                on: {
                  "update:search": function ($event) {
                    _vm.search = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.action",
                      fn: function ({ item }) {
                        return [
                          _vm.isGranted("EVENTS_SETTINGS")
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { small: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.edition = JSON.parse(
                                        JSON.stringify(item)
                                      )
                                      _vm.editModal = true
                                    },
                                  },
                                },
                                [_vm._v("fa-edit")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.color",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-chip",
                            {
                              attrs: { label: "", color: item.color, dark: "" },
                            },
                            [_vm._v("   ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.teams",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                label: "",
                                color: item.teams ? "success" : "error",
                                dark: "",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(item.teams ? "fa-check" : "fa-times") +
                                  " fa-fw "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.icon",
                      fn: function ({ item }) {
                        return [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(_vm._s(item.icon)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.shortcut",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                label: "",
                                color: item.shortcut ? "success" : "error",
                                dark: "",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.shortcut ? "fa-check" : "fa-times"
                                  ) +
                                  " fa-fw "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.inForm",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color: item.inForm ? "success" : "error",
                                dark: "",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.inForm ? "fa-check" : "fa-times"
                                  ) +
                                  " fa-fw "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1597274708
                ),
              }),
              _c(
                "modal",
                {
                  attrs: { "max-width": "1024" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.edition && _vm.edition.id
                                    ? "Édition"
                                    : "Ajout"
                                ) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "actions",
                        fn: function () {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "error", text: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.editModal = false
                                  },
                                },
                              },
                              [_vm._v(" Fermer ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-4",
                                attrs: { text: "" },
                                on: { click: _vm.saveType },
                              },
                              [_vm._v(" Enregistrer ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2594910084
                  ),
                  model: {
                    value: _vm.editModal,
                    callback: function ($$v) {
                      _vm.editModal = $$v
                    },
                    expression: "editModal",
                  },
                },
                [
                  _vm.edition
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: { outlined: "", label: "Nom" },
                                model: {
                                  value: _vm.edition.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.edition, "name", $$v)
                                  },
                                  expression: "edition.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  "menu-props": { maxHeight: "300" },
                                  label: "Icône",
                                  items: _vm.icons,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "", label: "" } },
                                            [_vm._v(_vm._s(item))]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item",
                                      fn: function ({
                                        active,
                                        item,
                                        attrs,
                                        on,
                                      }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                label: "",
                                                                color: _vm
                                                                  .edition.color
                                                                  ? _vm.edition
                                                                      .color
                                                                  : "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(item)
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3711622624
                                ),
                                model: {
                                  value: _vm.edition.icon,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.edition, "icon", $$v)
                                  },
                                  expression: "edition.icon",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "text-right", attrs: { cols: "2" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  "menu-props": { maxHeight: "300" },
                                  label: "Couleur",
                                  items: _vm.colors,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                label: "",
                                                color: item,
                                              },
                                            },
                                            [_vm._v("     ")]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item",
                                      fn: function ({
                                        active,
                                        item,
                                        attrs,
                                        on,
                                      }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                label: "",
                                                                color: item,
                                                              },
                                                            },
                                                            [_vm._v("     ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1324972624
                                ),
                                model: {
                                  value: _vm.edition.color,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.edition, "color", $$v)
                                  },
                                  expression: "edition.color",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  label: "Durée par défaut",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.edition.duration,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.edition, "duration", $$v)
                                  },
                                  expression: "edition.duration",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  label: "Texte par défaut",
                                },
                                model: {
                                  value: _vm.edition.defaultText,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.edition, "defaultText", $$v)
                                  },
                                  expression: "edition.defaultText",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-switch", {
                                attrs: { label: "Teams" },
                                model: {
                                  value: _vm.edition.teams,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.edition, "teams", $$v)
                                  },
                                  expression: "edition.teams",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-switch", {
                                attrs: { label: "Calendrier Outlook" },
                                model: {
                                  value: _vm.edition.ewsCalendar,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.edition, "ewsCalendar", $$v)
                                  },
                                  expression: "edition.ewsCalendar",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-switch", {
                                attrs: { label: "Raccourci" },
                                model: {
                                  value: _vm.edition.shortcut,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.edition, "shortcut", $$v)
                                  },
                                  expression: "edition.shortcut",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-switch", {
                                attrs: { label: "Formulaire" },
                                model: {
                                  value: _vm.edition.inForm,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.edition, "inForm", $$v)
                                  },
                                  expression: "edition.inForm",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }