export const icons = {
  image: "fa-file-image",
  pdf: "far fa-file-pdf",
  word: "far fa-file-word",
  powerpoint: "far fa-file-powerpoint",
  excel: "far fa-file-excel",
  csv: "fa-file-csv",
  audio: "far fa-file-audio",
  video: "far fa-file-video",
  archive: "far fa-file-archive",
  code: "far fa-file-code",
  text: "far fa-file-alt",
  file: "far fa-file",
  message: "far fa-envelope",
};

export const extToType = {
  xls: "excel",
  xlsx: "excel",
  ppt: "powerpoint",
  pptx: "powerpoint",
  doc: "word",
  docx: "word",
  csv: "csv",
  pdf: "pdf",
  zip: "archive",
  tar: "archive",
  gz: "archive",
  rar: "archive",
  txt: "text",
  php: "code",
  js: "code",
  html: "code",
  msg: "message",
  mp3: "audio",
  wav: "audio",
  avi: "video",
  mkv: "video",
};
