var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(
        "v-row",
        { staticClass: "mt-6" },
        [
          _c("modal", {
            model: {
              value: _vm.readCommentModal,
              callback: function ($$v) {
                _vm.readCommentModal = $$v
              },
              expression: "readCommentModal",
            },
          }),
          _c(
            "v-col",
            {
              staticStyle: { position: "relative" },
              attrs: { cols: _vm.isGranted("OPPORTUNITIES_DELETE") ? 10 : 11 },
            },
            [
              _c(
                "sui-step-group",
                { staticStyle: { width: "100%", "margin-top": "-50px" } },
                [
                  _c(
                    "sui-step",
                    {
                      staticClass: "pa-2 text-left",
                      staticStyle: {
                        cursor: "pointer",
                        "justify-content": "left",
                      },
                      attrs: {
                        active: _vm.item && _vm.item.opp.stepId >= 1,
                        disabled: !_vm.item.opp.opened,
                      },
                    },
                    [
                      _vm.item &&
                      _vm.item.opp.stepId >= 1 &&
                      _vm.item.opp.createdAt &&
                      _vm.item.opp.userId !== null
                        ? _c(
                            "span",
                            { staticClass: "float-start ml-1" },
                            [
                              _c("UserAvatar", {
                                attrs: {
                                  userId: _vm.getUserIdFromStep(1)
                                    ? _vm.getUserIdFromStep(1)
                                    : _vm.item.opp.userId,
                                  size: "45",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "sui-step-content",
                        { staticClass: "ml-2" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "sui-step-title",
                                    {
                                      staticClass:
                                        "font-weight-regular text-uppercase text-subtitle-1",
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("fa-fingerprint fa-fw"),
                                      ]),
                                      _vm._v(" Identifié"),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "sui-step-description",
                                    {
                                      staticClass:
                                        "pl-6 mt-0 grey--text text--lighten-3 text-subtitle-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm
                                              .dayjs(_vm.item.opp.createdAt)
                                              .format("DD/MM/YYYY")
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "d-none d-lg-inline" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .dayjs(_vm.item.opp.createdAt)
                                                .format(" à HH:mm")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "sui-step",
                    {
                      staticClass: "pa-2",
                      staticStyle: {
                        cursor: "pointer",
                        "justify-content": "left",
                      },
                      attrs: {
                        disabled: !_vm.item.opp.opened,
                        active: _vm.item && _vm.item.opp.stepId >= 2,
                      },
                      on: {
                        click: function ($event) {
                          _vm.item && _vm.item.opp.stepId !== 2
                            ? _vm.setProgression(50)
                            : _vm.setProgression(25)
                        },
                      },
                    },
                    [
                      _vm.item && _vm.item.opp.stepId >= 2
                        ? _c(
                            "span",
                            { staticClass: "float-start ml-1" },
                            [
                              _c("UserAvatar", {
                                attrs: {
                                  userId: _vm.getUserIdFromStep(2),
                                  size: "45",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "sui-step-content",
                        { staticClass: "ml-2" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "sui-step-title",
                                    {
                                      staticClass:
                                        "font-weight-regular text-uppercase text-subtitle-1",
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("fa-user-graduate fa-fw"),
                                      ]),
                                      _vm._v(" Étude "),
                                    ],
                                    1
                                  ),
                                  _vm.item && _vm.item.opp.stepId >= 2
                                    ? _c(
                                        "sui-step-description",
                                        {
                                          staticClass:
                                            "pl-6 mt-0 grey--text text--lighten-3 text-subtitle-2",
                                          staticStyle: { color: "white" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .dayjs(
                                                    _vm.returnStepDatetime(2)
                                                  )
                                                  .format("DD/MM/YYYY")
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "d-none d-lg-inline",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .dayjs(
                                                      _vm.returnStepDatetime(2)
                                                    )
                                                    .format(" à HH:mm")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "sui-step-description",
                                        {
                                          staticClass: "pl-6",
                                          staticStyle: { color: "white" },
                                        },
                                        [_vm._v("  ")]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "sui-step",
                    {
                      staticClass: "pa-2",
                      staticStyle: {
                        cursor: "pointer",
                        "justify-content": "left",
                      },
                      attrs: {
                        disabled: !_vm.item.opp.opened,
                        active: _vm.item && _vm.item.opp.stepId >= 3,
                      },
                      on: {
                        click: function ($event) {
                          _vm.item && _vm.item.opp.stepId !== 3
                            ? _vm.setProgression(75)
                            : _vm.setProgression(50)
                        },
                      },
                    },
                    [
                      _vm.item && _vm.item.opp.stepId >= 3
                        ? _c(
                            "span",
                            { staticClass: "float-start ml-1" },
                            [
                              _c("UserAvatar", {
                                attrs: {
                                  userId: _vm.getUserIdFromStep(3),
                                  size: "45",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "sui-step-content",
                        { staticClass: "ml-2" },
                        [
                          _c(
                            "sui-step-title",
                            {
                              staticClass:
                                "font-weight-regular text-uppercase text-subtitle-1",
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("far fa-handshake fa-fw"),
                              ]),
                              _vm._v(" Négociation "),
                            ],
                            1
                          ),
                          _vm.item && _vm.item.opp.stepId >= 3
                            ? _c(
                                "sui-step-description",
                                {
                                  staticClass:
                                    "pl-6 mt-0 grey--text text--lighten-3 text-subtitle-2",
                                  staticStyle: { color: "white" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .dayjs(_vm.returnStepDatetime(3))
                                          .format("DD/MM/YYYY")
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-lg-inline" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .dayjs(_vm.returnStepDatetime(3))
                                            .format(" à HH:mm")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "sui-step-description",
                                {
                                  staticClass: "pl-6",
                                  staticStyle: { color: "white" },
                                },
                                [_vm._v("  ")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "sui-step",
                    {
                      staticClass: "pa-2",
                      staticStyle: {
                        cursor: "pointer",
                        "justify-content": "left",
                      },
                      attrs: { active: !_vm.item.opp.opened },
                      on: {
                        click: function ($event) {
                          _vm.item.opp.opened
                            ? _vm.setStatus(!_vm.item.opp.opened)
                            : false
                        },
                      },
                    },
                    [
                      _vm.item && !_vm.item.opp.opened
                        ? _c(
                            "span",
                            { staticClass: "float-start ml-1" },
                            [
                              _c("UserAvatar", {
                                attrs: {
                                  userId: _vm.item.opp.closedUserId,
                                  size: "45",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "sui-step-content",
                        { staticClass: "ml-2" },
                        [
                          _c(
                            "sui-step-title",
                            {
                              staticClass:
                                "font-weight-regular text-uppercase text-subtitle-1",
                            },
                            [
                              _vm.item && _vm.item.opp.stepId < 4
                                ? _c("v-icon", { attrs: { "x-small": "" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.item.opp.opened
                                            ? "fa-lock-open"
                                            : "fa-lock"
                                        ) +
                                        " fa-fw "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.item.opp.opened
                                      ? "Ouvert"
                                      : _vm.getStatus(_vm.item.opp.statusId)
                                  ) +
                                  " "
                              ),
                              !_vm.item.opp.opened
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " fa-info-circle fa-fw "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1958627155
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.item.opp.bodyClose)),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.item && !_vm.item.opp.opened
                            ? _c(
                                "sui-step-description",
                                {
                                  staticClass:
                                    "pl-6 mt-0 grey--text text--lighten-3 text-subtitle-2",
                                },
                                [
                                  _vm.item.win && _vm.item.win.name
                                    ? [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fa-trophy fa-fw"),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.item.win.name) +
                                            " - "
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .dayjs(_vm.item.opp.closedDatetime)
                                          .format("DD/MM/YYYY")
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-lg-inline" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .dayjs(_vm.item.opp.closedDatetime)
                                            .format(" à HH:mm")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              )
                            : _c(
                                "sui-step-description",
                                {
                                  staticClass: "pl-6",
                                  staticStyle: { color: "white" },
                                },
                                [_vm._v("  ")]
                              ),
                        ],
                        1
                      ),
                      !_vm.item.opp.opened
                        ? _c(
                            "v-btn",
                            {
                              staticStyle: {
                                "z-index": "11",
                                position: "absolute",
                                right: "5px",
                                top: "5px",
                              },
                              attrs: { text: "", "x-small": "" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { small: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.infoModal = true
                                    },
                                  },
                                },
                                [_vm._v("fa-retweet fa-fw")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              staticStyle: { "margin-top": "0.7rem" },
              attrs: { cols: _vm.isGranted("OPPORTUNITIES_DELETE") ? 2 : 1 },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("UserAvatar", {
                        attrs: { userId: _vm.item.opp.userId, size: "35" },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "float-lg-right",
                          style: _vm.isMobile()
                            ? {
                                marginTop: "10px",
                              }
                            : {},
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "ml-md-1 transfertOpp",
                                                class: _vm.isMobile()
                                                  ? "mb-1"
                                                  : "",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  disabled:
                                                    !_vm.item.entreprise,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.modalTransf = true
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(" fa-exchange-alt fa-fw "),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3043249253
                              ),
                            },
                            [_c("span", [_vm._v("Transfert")])]
                          ),
                          _vm.isGranted("OPPORTUNITIES_DELETE")
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "ml-md-3 deleteOpp",
                                                    attrs: {
                                                      fab: "",
                                                      "x-small": "",
                                                      color: "error",
                                                    },
                                                    on: {
                                                      click: _vm.deleteOpp,
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fa-trash fa-fw"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4094922234
                                  ),
                                },
                                [_c("span", [_vm._v("Supprimer")])]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "modal",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "strong",
                          { staticClass: "text-h5 mb-0 text-uppercase" },
                          [_vm._v("Transfert")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: {
                              variant: "flat",
                              color: "primary",
                              elevation: "0",
                            },
                            on: { click: _vm.transfert },
                          },
                          [_vm._v(" Valider ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: { color: "secondary", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.modalTransf = false
                              },
                            },
                          },
                          [_vm._v(" Fermer ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3246376086
              ),
              model: {
                value: _vm.modalTransf,
                callback: function ($$v) {
                  _vm.modalTransf = $$v
                },
                expression: "modalTransf",
              },
            },
            [
              _c("listUser", {
                attrs: { includeAll: "", multiple: false },
                on: { selectChange: _vm.setAsUser },
              }),
            ],
            1
          ),
          _c(
            "modal",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.infoModal = false
                              },
                            },
                          },
                          [_vm._v(" Fermer ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: { text: "", disabled: _vm.closeOppLoading },
                            on: { click: _vm.validateRelance },
                          },
                          [_vm._v(" Enregistrer ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                41262495
              ),
              model: {
                value: _vm.infoModal,
                callback: function ($$v) {
                  _vm.infoModal = $$v
                },
                expression: "infoModal",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menu1",
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "max-width": "290px",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              label: "Date de relance",
                                              placeholder: "Date de relance",
                                              "persistent-hint": "",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                _vm.date = _vm.parseDate(
                                                  _vm.dateFormatted
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.dateFormatted,
                                              callback: function ($$v) {
                                                _vm.dateFormatted = $$v
                                              },
                                              expression: "dateFormatted",
                                            },
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2727807271
                          ),
                          model: {
                            value: _vm.menu1,
                            callback: function ($$v) {
                              _vm.menu1 = $$v
                            },
                            expression: "menu1",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { "first-day-of-week": "1", "no-title": "" },
                            on: {
                              input: function ($event) {
                                _vm.menu1 = false
                              },
                            },
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "modal",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [_vm._v(" Cloturer l'opportunité ")]
                    },
                    proxy: true,
                  },
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error" },
                            on: {
                              click: function ($event) {
                                _vm.dialog = false
                              },
                            },
                          },
                          [_vm._v(" Fermer ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: { disabled: _vm.closeOppLoading },
                            on: { click: _vm.validate },
                          },
                          [_vm._v(" Enregistrer ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2303669005
              ),
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.statuses, function (status, key) {
                      return _c(
                        "v-col",
                        { key: key, attrs: { cols: "6" } },
                        [
                          _c("v-switch", {
                            attrs: { label: status.name, value: status.id },
                            model: {
                              value: _vm.state,
                              callback: function ($$v) {
                                _vm.state = $$v
                              },
                              expression: "state",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _vm.state === 2 && _vm.item.concurrents.length > 0
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _vm._v(
                              " Concurrent ayant " +
                                _vm._s(_vm.state === 2 ? "remporté" : "perdu") +
                                " l'opportunité "
                            ),
                          ]),
                          _vm._l(_vm.item.concurrents, function (concu, key) {
                            return _c(
                              "v-col",
                              { key: key, attrs: { cols: "6" } },
                              [
                                _c("v-switch", {
                                  attrs: { label: concu.name, value: concu.id },
                                  model: {
                                    value: _vm.concuWin,
                                    callback: function ($$v) {
                                      _vm.concuWin = $$v
                                    },
                                    expression: "concuWin",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("v-textarea", {
                    attrs: {
                      name: "input-7-1",
                      label: "Commentaire",
                      value: "",
                    },
                    model: {
                      value: _vm.comments,
                      callback: function ($$v) {
                        _vm.comments = $$v
                      },
                      expression: "comments",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }