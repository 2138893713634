// à ajouter dans v-data-table:
// @update:options="setPageOptions"
// :options="tableOptions"

const savePage = {
  data() {
    return {
      pageOptions: {},
      tableOptions: null,
    };
  },
  methods: {
    // à implementer dans @update:options du v-data-table concerné
    setPageOptions(event) {
      this.pageOptions = { ...this.pageOptions, ...event };
    },
    // à implémenter dans mounted() du component souhaité en indiquant le nom du composant en paramètre
    getData(featureName) {
      this.tableOptions = JSON.parse(sessionStorage.getItem(`savedOptions${featureName}`));
      this.search = { ...this.search, ...JSON.parse(sessionStorage.getItem(`savedSearch${featureName}`)) };
    },
    // à implémenter dans la requête API en indiquant le nom du composant en paramètre
    setData(featureName) {
      sessionStorage.setItem(`savedOptions${featureName}`, JSON.stringify(this.pageOptions));
      sessionStorage.setItem(`savedSearch${featureName}`, JSON.stringify({ ...this.search }));
    },
  },
};
export default savePage;
