var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "modal",
        {
          attrs: { "max-width": "calc(100% - 122px)" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Boîte mail"),
                  ]),
                  _c("br"),
                  _c("small", { staticClass: "mt-0" }, [
                    _vm._v("Filtrez les dossiers..."),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.linkModal,
            callback: function ($$v) {
              _vm.linkModal = $$v
            },
            expression: "linkModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "text-left" },
            [
              _c("Inbox", {
                on: {
                  close: function ($event) {
                    _vm.linkModal = false
                  },
                },
                model: {
                  value: _vm.typeLink,
                  callback: function ($$v) {
                    _vm.typeLink = $$v
                  },
                  expression: "typeLink",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "modal",
        {
          attrs: { "max-width": "1080" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Evènement"),
                  ]),
                  _c("br"),
                  _c(
                    "small",
                    { staticClass: "mt-0" },
                    [
                      _vm.newEvent
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .dayjs(_vm.newEvent.date)
                                    .format("DD/MM/YYYY")
                                ) +
                                " " +
                                _vm._s(_vm.newEvent.time) +
                                " - " +
                                _vm._s(_vm.newEvent.name) +
                                " "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.newEvent
                    ? [
                        _vm.newEvent.id
                          ? [
                              _c(
                                "div",
                                { staticClass: "modal-menu--actions" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-left",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                  elevation:
                                                                    "0",
                                                                  plain: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$router.push(
                                                                        {
                                                                          name: "Client",
                                                                          params:
                                                                            {
                                                                              id: _vm
                                                                                .newEvent
                                                                                .customerId,
                                                                            },
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-search"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1168081894
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Fiche client"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                                attrs: {
                                                                  small: "",
                                                                  elevation:
                                                                    "0",
                                                                  plain: "",
                                                                  color: "none",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.typeLink.id =
                                                                        _vm.newEvent.id
                                                                      _vm.linkModal = true
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-envelope"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3752907608
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Lier un e-mail"),
                                              ]),
                                            ]
                                          ),
                                          _vm.newEvent.opportunityId
                                            ? [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mx-1",
                                                                      attrs: {
                                                                        elevation:
                                                                          "0",
                                                                        small:
                                                                          "",
                                                                        plain:
                                                                          "",
                                                                        color:
                                                                          "warning",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$router.push(
                                                                              {
                                                                                name: "Opportunité",
                                                                                params:
                                                                                  {
                                                                                    id: _vm
                                                                                      .newEvent
                                                                                      .opportunityId,
                                                                                  },
                                                                              }
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fa-handshake"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      303942909
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Opportunité " +
                                                          _vm._s(
                                                            _vm.newEvent
                                                              .opportunityId
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            : [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mx-1",
                                                                      attrs: {
                                                                        elevation:
                                                                          "0",
                                                                        small:
                                                                          "",
                                                                        plain:
                                                                          "",
                                                                        color:
                                                                          "warning",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.newOpp,
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fa-plus"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      680333465
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Créer opportunité"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        variant: "flat",
                        color: "primary",
                        elevation: "0",
                        loading: _vm.loadingSave,
                      },
                      on: { click: _vm.editEventFct },
                    },
                    [_vm._v(" Enregistrer ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.editEvent = false
                        },
                      },
                    },
                    [_vm._v(" Fermer ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.editEvent,
            callback: function ($$v) {
              _vm.editEvent = $$v
            },
            expression: "editEvent",
          },
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("commentFormOpportunities", {
                        model: {
                          value: _vm.newEvent,
                          callback: function ($$v) {
                            _vm.newEvent = $$v
                          },
                          expression: "newEvent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { "no-padding": "" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Événements en retard"),
                  ]),
                  _c("br"),
                  _c(
                    "small",
                    { staticClass: "mt-0" },
                    [
                      _vm.eventsLate && _vm.eventsLate.events
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.eventsLate && _vm.eventsLate.events
                                    ? _vm.eventsLate.events.length
                                    : ""
                                ) +
                                " événement" +
                                _vm._s(
                                  _vm.eventsLate &&
                                    _vm.eventsLate.events &&
                                    _vm.eventsLate.events.length > 1
                                    ? "s"
                                    : ""
                                ) +
                                " à traiter "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.eventModal,
            callback: function ($$v) {
              _vm.eventModal = $$v
            },
            expression: "eventModal",
          },
        },
        [
          _c(
            "v-list",
            {
              staticClass: "ma-0 pa-0",
              attrs: { subheader: "", "three-line": "" },
            },
            _vm._l(_vm.eventsLate.events, function (item, key) {
              return _c(
                "v-list-item-group",
                { key: key },
                [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "my-0 py-0",
                      on: {
                        click: function ($event) {
                          return _vm.openEvents(item)
                        },
                        mouseup: function ($event) {
                          if ("button" in $event && $event.button !== 1)
                            return null
                          return _vm.openTab({
                            name: "Client",
                            params: { id: item.customerId },
                          })
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { size: "40" } },
                        [
                          _c(
                            "v-icon",
                            {
                              style:
                                "background-color:" +
                                _vm.eventsLate.type[item.eventtypeId].color,
                              attrs: { dark: "", "x-small": "" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.eventsLate.type[item.eventtypeId].icon
                                  ) +
                                  " fa-fw "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "text-left pb-0" },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "primary--text" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.eventsLate.entreprise[item.id].name
                                ) + " "
                              ),
                              item.opportunity
                                ? [
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ml-2",
                                        attrs: {
                                          "x-small": "",
                                          outlined: "",
                                          label: "",
                                          color: "primary",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: { start: "" },
                                          },
                                          [_vm._v("far fa-handshake fa-fw")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.opportunity &&
                                                item.opportunity.id
                                                ? item.opportunity.id
                                                : ""
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c("v-list-item-subtitle", [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        { staticClass: "text-right error--text" },
                        [
                          _c(
                            "v-list-item-action-text",
                            { staticClass: "text-right error--text" },
                            [
                              _vm._v(
                                _vm._s(_vm.dayjs().diff(item.start, "days")) +
                                  " j "
                              ),
                            ]
                          ),
                          _c("v-list-item-action-text", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dayjs(item.start).format("DD/MM/YYYY")
                                ) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(_vm.dayjs(item.start).format("HH:mm")) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { attrs: { inset: "" } }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.isMobile()
        ? _c(
            "v-app-bar",
            {
              style: { zIndex: 1000 },
              attrs: {
                app: "",
                color: "#f9fafb",
                "clipped-left": "",
                elevation: "1",
                dense: "",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-end align-center",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("v-label", [_vm._v("Menu")]),
                      _c("v-app-bar-nav-icon", {
                        on: {
                          click: function ($event) {
                            _vm.drawer = !_vm.drawer
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "1001" },
          attrs: {
            "expand-on-hover": false,
            floating: "",
            permanent: !_vm.isMobile(),
            temporary: _vm.isMobile(),
            src: "/images/abImg.jpg",
            location: "start",
            color: "black",
            "mini-variant": "",
            app: "",
            dark: "",
          },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function () {
                return [
                  _c(
                    "v-list",
                    { attrs: { nav: "", dense: "" } },
                    [
                      _vm.isGranted("SETTINGS_ALL")
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { right: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  link: "",
                                                  color: "white",
                                                  to: "/settings",
                                                  "exact-active-class":
                                                    "activeLinkMenu",
                                                },
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fa-fw fa-cog"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3481080255
                              ),
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "z-index": "111111" } },
                                [_vm._v("Paramètres")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.$store.state.token !== _vm.$store.state.initToken
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { right: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  link: "",
                                                  color: "white",
                                                  "exact-active-class":
                                                    "activeLinkMenu",
                                                },
                                                on: { click: _vm.relogin },
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "fa-fw fa-sign-in-alt"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4262769103
                              ),
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "z-index": "111111" } },
                                [_vm._v("Reconnexion")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { right: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-list-item",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            link: "",
                                            color: "white",
                                            "exact-active-class":
                                              "activeLinkMenu",
                                          },
                                          on: { click: _vm.logout },
                                        },
                                        "v-list-item",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("fa-fw fa-power-off"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", { staticStyle: { "z-index": "111111" } }, [
                            _vm._v("Déconnexion"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { staticClass: "px-0" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-list-item-avatar",
                                _vm._g(
                                  _vm._b(
                                    { staticClass: "px-0 mx-2" },
                                    "v-list-item-avatar",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("UserAvatar", {
                                    attrs: {
                                      userId: _vm.$store.state.user.id,
                                      size: "40",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", { staticStyle: { "z-index": "111111" } }, [
                        _vm._v(
                          _vm._s(_vm.$store.state.user.firstname) +
                            " " +
                            _vm._s(_vm.$store.state.user.lastname)
                        ),
                      ]),
                    ]
                  ),
                  _c("v-list-item-title", [
                    _vm._v(
                      _vm._s(_vm.$store.state.user.firstname) +
                        " " +
                        _vm._s(_vm.$store.state.user.lastname)
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "px-0" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-list-item-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          _vm.eventModal = true
                                        },
                                      },
                                    },
                                    "v-list-item-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm.eventsLate &&
                                  _vm.eventsLate.events &&
                                  _vm.eventsLate.events.length > 0
                                    ? _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            content:
                                              _vm.eventsLate.events.length,
                                            color: "secondary",
                                            overlap: "",
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(" far fa-bell fa-fw "),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _c("v-icon", [
                                        _vm._v(" far fa-bell-slash fa-fw "),
                                      ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { "z-index": "111111" } },
                        [
                          _vm.eventsLate &&
                          _vm.eventsLate.events &&
                          _vm.eventsLate.events.length > 0
                            ? [
                                _vm._v(
                                  " Il y a " +
                                    _vm._s(_vm.eventsLate.events.length) +
                                    " événement" +
                                    _vm._s(
                                      _vm.eventsLate.events.length > 1
                                        ? "s"
                                        : ""
                                    ) +
                                    " en retard" +
                                    _vm._s(
                                      _vm.eventsLate.events.length > 1
                                        ? "s"
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]
                            : [_vm._v(" Il n'y a pas d'événement en retard ")],
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        right: "",
                        color: _vm.bebedc.length > 0 ? "error" : "success",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-list-item-icon",
                                _vm._g(
                                  _vm._b(
                                    { staticStyle: { cursor: "pointer" } },
                                    "v-list-item-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm.bebedc.length > 0 && _vm.loadBebedc
                                    ? _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            content: _vm.bebedc.length,
                                            color: "error",
                                            overlap: "",
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " fa-exclamation-circle fa-fw "
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-icon",
                                        {
                                          style: !_vm.loadBebedc
                                            ? "opacity:0.5;"
                                            : "",
                                          attrs: {
                                            color: !_vm.loadBebedc
                                              ? ""
                                              : "success",
                                          },
                                        },
                                        [_vm._v(" far fa-check-circle fa-fw ")]
                                      ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { "z-index": "111111" } },
                        [
                          _vm.bebedc.length > 0
                            ? _vm._l(_vm.bebedc, function (item, key) {
                                return _c("div", { key: key }, [
                                  _vm._v(" " + _vm._s(item) + " "),
                                ])
                              })
                            : [
                                _vm._v(
                                  " Vous êtes à jour sur vos objectifs de prospection. "
                                ),
                              ],
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list",
            {
              staticClass: "listFeature",
              attrs: { nav: "", dense: "", dark: "" },
            },
            _vm._l(_vm.features, function (feature, index) {
              return _c(
                "div",
                { key: "feature-" + index },
                [
                  (_vm.isMobile() ? feature.mobile : true)
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _vm.isGranted(feature.permission)
                                      ? _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  link: "",
                                                  color: feature.color,
                                                  to: feature.path,
                                                  "exact-active-class":
                                                    "activeLinkMenu",
                                                },
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c("v-icon", [
                                                  _vm._v(_vm._s(feature.icon)),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", { staticStyle: { "z-index": "111111" } }, [
                            _vm._v(_vm._s(feature.name)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }