var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isGranted("OPPORTUNITIES_INDEX")
        ? _c(
            "v-card",
            { staticStyle: { "z-index": "99" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary", dark: "", elevation: "0" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "div",
                        { staticClass: "listbox--actions-add" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                fab: "",
                                color: "primary",
                                dark: "",
                              },
                              on: { click: _vm.newOpp },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("fa-plus"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.search.all
                              ? "Toutes les opportunités"
                              : _vm.asUser !== null
                              ? "Opportunités"
                              : "Mes opportunités"
                          ) +
                          " "
                      ),
                      _c(
                        "div",
                        {
                          style: _vm.isMobile()
                            ? {
                                marginLeft: "20px",
                              }
                            : {
                                position: "absolute",
                                top: "0",
                                left: "50%",
                                background: "#78909c",
                                padding: "5px",
                                transform: "translate(-50%)",
                                borderRadius: "0 0 5px 5px",
                              },
                        },
                        [
                          _vm.isGranted("OPPORTUNITIES_INDEX_ALLSERVICE")
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { text: "", small: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.modalSettings = true
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { small: "" } },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" fa-fw fa-filter ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        725421783
                                      ),
                                    },
                                    [_c("span", [_vm._v("Filtrer")])]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "content-class": "mt-2 pa-0",
                                "close-on-content-click": false,
                                "offset-y": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: { text: "", small: "" },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " fa-fw fa-cog "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Colonnes")])]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1154628350
                              ),
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-toolbar",
                                    {
                                      staticClass: "py-0",
                                      attrs: {
                                        color: "primary",
                                        dark: "",
                                        elevation: "0",
                                        height: "45px",
                                      },
                                    },
                                    [_vm._v(" Colonnes visibles ")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "pa-1 pb-3",
                                      staticStyle: {
                                        "min-width": "180px",
                                        "max-height": "calc(100vh - 115px)",
                                        "overflow-y": "auto",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        _vm._l(
                                          _vm.columnsAvailable,
                                          function (item, key) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: key,
                                                staticStyle: {
                                                  "min-height": "40px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleItem(
                                                      item,
                                                      key
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  [
                                                    _vm.checkArrayContains(
                                                      item.value,
                                                      _vm.headers
                                                    )
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "far fa-fw fa-check-square "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "far fa-fw fa-square"
                                                            ),
                                                          ]
                                                        ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-1" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.text)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("v-divider"),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-data-table",
                {
                  staticClass: "elevation-0",
                  staticStyle: { "z-index": "1000" },
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.listOpp,
                    page: _vm.page,
                    search: _vm.customerSearch.name,
                    "items-per-page": _vm.limitItem,
                    loading: _vm.isLoading,
                    "server-items-length": _vm.maxItem,
                    options: _vm.tableOptions,
                    "sort-by": _vm.sortedBy,
                    "sort-desc": _vm.sortedDesc,
                    "footer-props": {
                      showFirstLastPage: true,
                      itemsPerPageOptions: [5, 10, 15, 25, 50],
                      firstIcon: "fa-angle-double-left",
                      lastIcon: "fa-angle-double-right",
                      prevIcon: "fa-angle-left",
                      nextIcon: "fa-angle-right",
                    },
                  },
                  on: {
                    "update:search": function ($event) {
                      return _vm.$set(_vm.customerSearch, "name", $event)
                    },
                    "click:row": _vm.loadOpportunity,
                    "update:page": _vm.setPage,
                    "update:items-per-page": _vm.setItemMax,
                    "update:options": _vm.setPageOptions,
                    "update:sortBy": function ($event) {
                      _vm.sortedBy = $event
                    },
                    "update:sort-by": function ($event) {
                      _vm.sortedBy = $event
                    },
                    "update:sortDesc": function ($event) {
                      _vm.sortedDesc = $event
                    },
                    "update:sort-desc": function ($event) {
                      _vm.sortedDesc = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.item.deadline",
                        fn: function ({ item }) {
                          return [
                            item.item.deadline
                              ? [
                                  _c("span", { staticClass: "px-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .dayjs(item.item.deadline)
                                            .format("DD/MM/YYYY")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              : [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        color: "error",
                                        small: "",
                                        label: "",
                                      },
                                    },
                                    [_vm._v("Sans échéance")]
                                  ),
                                ],
                          ]
                        },
                      },
                      {
                        key: "item.item.createdAt",
                        fn: function ({ item }) {
                          return [
                            item.item.createdAt
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .dayjs(item.item.createdAt)
                                          .format("DD/MM/YYYY")
                                      ) +
                                      " "
                                  ),
                                ]
                              : [
                                  _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "error" } },
                                    [_vm._v("Sans date")]
                                  ),
                                ],
                          ]
                        },
                      },
                      {
                        key: "item.item.stateId",
                        fn: function ({ item }) {
                          return [
                            item.item.statusId !== null
                              ? _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      small: "",
                                      label: "",
                                      color:
                                        item.item.statusId === 1
                                          ? "success"
                                          : "error",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.item.statusId === 1
                                            ? "fa-thumbs-up"
                                            : "fa-thumbs-down"
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      small: "",
                                      color:
                                        item.item.stateId === 1 ? "" : "error",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.item.stateId === 1
                                            ? "fas fa-file-signature"
                                            : "fa-thumbs-down"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                      {
                        key: "item.item.statusId",
                        fn: function ({ item }) {
                          return [
                            item.item.statusId !== null
                              ? _c(
                                  "div",
                                  [
                                    item.item.statusId !== null
                                      ? _c(
                                          "v-chip",
                                          {
                                            attrs: {
                                              label: "",
                                              small: "",
                                              color:
                                                item.item.statusId === 1
                                                  ? "success"
                                                  : "error",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.item.statusId === 1
                                                    ? "Gagné"
                                                    : "Perdu"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.item.stepId",
                        fn: function ({ item }) {
                          return [
                            item.item.stepId && _vm.step[item.item.stepId]
                              ? _c(
                                  "v-chip",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      "margin-left": "auto",
                                      "margin-right": "auto",
                                      "text-align": "center",
                                      display: "block",
                                    },
                                    attrs: { label: "", small: "" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.step[item.item.stepId].name
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.item.categoryId",
                        fn: function ({ item }) {
                          return [
                            item.item.categoryId &&
                            _vm.listCat[item.item.categoryId]
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.listCat[item.item.categoryId].name
                                      ) +
                                      " "
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.array.paction",
                        fn: function ({ item }) {
                          return [
                            item.array && item.array.paction
                              ? _c(
                                  "v-container",
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "pa-0 ma-0",
                                        attrs: {
                                          "x-small": "",
                                          color: "transparent",
                                          elevation: "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          {
                                            staticClass:
                                              "text-subtitle-2 text-no-wrap ma-0 pa-0 px-1 grey--text text--darken-3",
                                            attrs: { small: "" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-no-wrap" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { "x-small": "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.array.paction.type
                                                          .icon
                                                      ) + " fa-fw"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .dayjs(
                                                        item.array.paction.event
                                                          .start
                                                      )
                                                      .format("DD/MM/YYYY")
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm
                                                        .dayjs(
                                                          item.array.paction
                                                            .event.start
                                                        )
                                                        .format("HH:mm")
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-card-text",
                                          {
                                            staticClass:
                                              "text-caption ma-0 pa-1",
                                          },
                                          [
                                            _c("UserAvatar", {
                                              attrs: {
                                                userId:
                                                  item.array.paction.event
                                                    .userId,
                                                size: "18",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.array.paction.event.name
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.array.daction",
                        fn: function ({ item }) {
                          return [
                            item.array && item.array.daction
                              ? _c(
                                  "v-container",
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "pa-0 ma-0",
                                        attrs: {
                                          "x-small": "",
                                          color: "transparent",
                                          elevation: "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          {
                                            staticClass:
                                              "text-subtitle-2 text-no-wrap ma-0 pa-0 px-1 grey--text text--darken-3",
                                            attrs: { small: "" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-no-wrap" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { "x-small": "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.array.daction.type
                                                          .icon
                                                      ) + " fa-fw"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .dayjs(
                                                        item.array.daction.event
                                                          .start
                                                      )
                                                      .format("DD/MM/YYYY")
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm
                                                        .dayjs(
                                                          item.array.daction
                                                            .event.start
                                                        )
                                                        .format("HH:mm")
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-card-text",
                                          {
                                            staticClass:
                                              "text-caption ma-0 pa-1",
                                          },
                                          [
                                            _c("UserAvatar", {
                                              attrs: {
                                                userId:
                                                  item.array.daction.event
                                                    .userId,
                                                size: "18",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.array.daction.event.name
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.item.typeId",
                        fn: function ({ item }) {
                          return [
                            item.item.typeId &&
                            _vm.typeListObj[item.item.typeId]
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.typeListObj[item.item.typeId].name
                                      ) +
                                      " "
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.item.probability",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-progress-circular",
                              {
                                attrs: {
                                  rotate: 360,
                                  size: 40,
                                  width: 5,
                                  value: item.item.probability
                                    ? item.item.probability
                                    : "0",
                                  color:
                                    item.item.probability > 50
                                      ? "success"
                                      : "error",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.item.probability
                                        ? item.item.probability + "%"
                                        : "0" + "%"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.item.amount",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.item.amount &&
                                    parseInt(item.item.amount) > 0
                                    ? Math.round(item.item.amount)
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.item.customerId",
                        fn: function ({ item }) {
                          return [
                            item.item.customerId
                              ? [
                                  _vm._v(
                                    " " + _vm._s(item.entreprise.name) + " "
                                  ),
                                ]
                              : _vm._e(),
                            !item.item.customerId
                              ? [_vm._v(" Non rattaché ")]
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.campagne.name",
                        fn: function ({ item }) {
                          return [
                            _vm._v(" " + _vm._s(item.campagne?.name) + " "),
                          ]
                        },
                      },
                      !_vm.isMobile()
                        ? {
                            key: "body.prepend",
                            fn: function () {
                              return [
                                _c("tr", [
                                  _vm.checkArrayContains("item.id", _vm.headers)
                                    ? _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              label: "Filtrer",
                                            },
                                            model: {
                                              value: _vm.search.id,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.search, "id", $$v)
                                              },
                                              expression: "search.id",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.createdAt",
                                    _vm.headers
                                  )
                                    ? _c("td")
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.deadline",
                                    _vm.headers
                                  )
                                    ? _c(
                                        "td",
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "menu1",
                                              attrs: {
                                                "close-on-content-click": false,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "max-width": "290px",
                                                "min-width": "auto",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "pb-0",
                                                                attrs: {
                                                                  label:
                                                                    "Filtrer début",
                                                                  "append-outer-icon":
                                                                    _vm.search
                                                                      .startDate !==
                                                                    null
                                                                      ? "fa-times"
                                                                      : "",
                                                                },
                                                                on: {
                                                                  blur: function (
                                                                    $event
                                                                  ) {
                                                                    _vm.search.startDate =
                                                                      _vm.parseDate(
                                                                        _vm
                                                                          .search
                                                                          .startDateFormatted
                                                                      )
                                                                  },
                                                                  "click:append-outer":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.search.startDate =
                                                                        null
                                                                      _vm.search.startDateFormatted =
                                                                        null
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.search
                                                                      .startDateFormatted,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.search,
                                                                        "startDateFormatted",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "search.startDateFormatted",
                                                                },
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3755642420
                                              ),
                                              model: {
                                                value: _vm.menuStart,
                                                callback: function ($$v) {
                                                  _vm.menuStart = $$v
                                                },
                                                expression: "menuStart",
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: { "no-title": "" },
                                                on: {
                                                  input: function ($event) {
                                                    _vm.menuStart = false
                                                  },
                                                },
                                                model: {
                                                  value: _vm.search.startDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.search,
                                                      "startDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "search.startDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "menu1",
                                              attrs: {
                                                "close-on-content-click": false,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "max-width": "290px",
                                                "min-width": "auto",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "py-0",
                                                                attrs: {
                                                                  label:
                                                                    "Filtrer fin",
                                                                  "append-outer-icon":
                                                                    _vm.search
                                                                      .endDate !==
                                                                    null
                                                                      ? "fa-times"
                                                                      : "",
                                                                },
                                                                on: {
                                                                  blur: function (
                                                                    $event
                                                                  ) {
                                                                    _vm.search.endDate =
                                                                      _vm.parseDate(
                                                                        _vm
                                                                          .search
                                                                          .endDateFormatted
                                                                      )
                                                                  },
                                                                  "click:append-outer":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.search.endDate =
                                                                        null
                                                                      _vm.search.endDateFormatted =
                                                                        null
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.search
                                                                      .endDateFormatted,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.search,
                                                                        "endDateFormatted",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "search.endDateFormatted",
                                                                },
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                4196404288
                                              ),
                                              model: {
                                                value: _vm.menuEnd,
                                                callback: function ($$v) {
                                                  _vm.menuEnd = $$v
                                                },
                                                expression: "menuEnd",
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: { "no-title": "" },
                                                on: {
                                                  input: function ($event) {
                                                    _vm.menuEnd = false
                                                  },
                                                },
                                                model: {
                                                  value: _vm.search.endDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.search,
                                                      "endDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "search.endDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.stateId",
                                    _vm.headers
                                  )
                                    ? _c(
                                        "td",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.stateArray,
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: "Filtrer",
                                              "append-outer-icon":
                                                _vm.search.state !== null
                                                  ? "fa-times"
                                                  : "",
                                            },
                                            on: {
                                              "click:append-outer": function (
                                                $event
                                              ) {
                                                _vm.search.state = null
                                              },
                                            },
                                            model: {
                                              value: _vm.search.state,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.search,
                                                  "state",
                                                  $$v
                                                )
                                              },
                                              expression: "search.state",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.statusId",
                                    _vm.headers
                                  )
                                    ? _c(
                                        "td",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.statusArray,
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: "Filtrer",
                                              "append-outer-icon":
                                                _vm.search.status !== null
                                                  ? "fa-times"
                                                  : "",
                                            },
                                            on: {
                                              "click:append-outer": function (
                                                $event
                                              ) {
                                                _vm.search.status = null
                                              },
                                            },
                                            model: {
                                              value: _vm.search.status,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.search,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression: "search.status",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.stepId",
                                    _vm.headers
                                  )
                                    ? _c(
                                        "td",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              multiple: "",
                                              items: _vm.stepArray,
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: "Filtrer",
                                              "append-outer-icon":
                                                _vm.search.step !== null
                                                  ? "fa-times"
                                                  : "",
                                            },
                                            on: {
                                              "click:append-outer": function (
                                                $event
                                              ) {
                                                _vm.search.step = null
                                              },
                                            },
                                            model: {
                                              value: _vm.search.step,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.search,
                                                  "step",
                                                  $$v
                                                )
                                              },
                                              expression: "search.step",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.typeId",
                                    _vm.headers
                                  )
                                    ? _c(
                                        "td",
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              "menu-props": {
                                                maxHeight: "300 !important",
                                              },
                                              items: _vm.listTypeArray,
                                              "search-input": _vm.searchType,
                                              "hide-no-data": "",
                                              "hide-selected": "",
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: "Filtrer",
                                              "append-outer-icon":
                                                _vm.search.type !== null
                                                  ? "fa-times"
                                                  : "",
                                            },
                                            on: {
                                              "update:searchInput": function (
                                                $event
                                              ) {
                                                _vm.searchType = $event
                                              },
                                              "update:search-input": function (
                                                $event
                                              ) {
                                                _vm.searchType = $event
                                              },
                                              "click:append-outer": function (
                                                $event
                                              ) {
                                                _vm.search.type = null
                                              },
                                            },
                                            model: {
                                              value: _vm.search.type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.search,
                                                  "type",
                                                  $$v
                                                )
                                              },
                                              expression: "search.type",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.categoryId",
                                    _vm.headers
                                  )
                                    ? _c(
                                        "td",
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              "menu-props": {
                                                maxHeight: "300",
                                              },
                                              items: _vm.listCatArray,
                                              "search-input": _vm.searchCat,
                                              "hide-no-data": "",
                                              "hide-selected": "",
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: "Filtrer",
                                              "append-outer-icon":
                                                _vm.search.categories !== null
                                                  ? "fa-times"
                                                  : "",
                                            },
                                            on: {
                                              "update:searchInput": function (
                                                $event
                                              ) {
                                                _vm.searchCat = $event
                                              },
                                              "update:search-input": function (
                                                $event
                                              ) {
                                                _vm.searchCat = $event
                                              },
                                              "click:append-outer": function (
                                                $event
                                              ) {
                                                _vm.search.categories = null
                                              },
                                            },
                                            model: {
                                              value: _vm.search.categories,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.search,
                                                  "categories",
                                                  $$v
                                                )
                                              },
                                              expression: "search.categories",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.probability",
                                    _vm.headers
                                  )
                                    ? _c("td")
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.amount",
                                    _vm.headers
                                  )
                                    ? _c("td")
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "array.daction",
                                    _vm.headers
                                  )
                                    ? _c("td")
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "array.paction",
                                    _vm.headers
                                  )
                                    ? _c("td")
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.com",
                                    _vm.headers
                                  )
                                    ? _c("td")
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "array.commercial",
                                    _vm.headers
                                  )
                                    ? _c(
                                        "td",
                                        [
                                          _c("list-user", {
                                            attrs: {
                                              label: "Filtrer",
                                              "user-multiple":
                                                _vm.search.commercial,
                                            },
                                            on: {
                                              selectChange: _vm.setAsFilter,
                                            },
                                            model: {
                                              value: _vm.search.commercial,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.search,
                                                  "commercial",
                                                  $$v
                                                )
                                              },
                                              expression: "search.commercial",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.customerId",
                                    _vm.headers
                                  )
                                    ? _c(
                                        "td",
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              "menu-props": {
                                                maxHeight: "300",
                                              },
                                              items: _vm.listClient,
                                              "search-input":
                                                _vm.customerSearch.name,
                                              "hide-no-data": "",
                                              "hide-selected": "",
                                              "item-text": "entreprise.name",
                                              "item-value": "customer.id",
                                              label: "Filtrer",
                                              "append-outer-icon":
                                                _vm.search.client !== null
                                                  ? "fa-times"
                                                  : "",
                                            },
                                            on: {
                                              "update:searchInput": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.customerSearch,
                                                  "name",
                                                  $event
                                                )
                                              },
                                              "update:search-input": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.customerSearch,
                                                  "name",
                                                  $event
                                                )
                                              },
                                              "click:append-outer": function (
                                                $event
                                              ) {
                                                _vm.search.client = null
                                              },
                                            },
                                            model: {
                                              value: _vm.search.client,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.search,
                                                  "client",
                                                  $$v
                                                )
                                              },
                                              expression: "search.client",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "item.name",
                                    _vm.headers
                                  )
                                    ? _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            attrs: { label: "Filtrer" },
                                            model: {
                                              value: _vm.search.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.search,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "search.name",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "entreprise.adresse.postalCode",
                                    _vm.headers
                                  )
                                    ? _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            attrs: { label: "Filtrer" },
                                            model: {
                                              value: _vm.search.postalCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.search,
                                                  "postalCode",
                                                  $$v
                                                )
                                              },
                                              expression: "search.postalCode",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.checkArrayContains(
                                    "campagne.name",
                                    _vm.headers
                                  )
                                    ? _c(
                                        "td",
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              "menu-props": {
                                                maxHeight: "300",
                                              },
                                              items: _vm.compaigns,
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: "Filtrer",
                                              "append-outer-icon":
                                                _vm.search.campagne !== null
                                                  ? "fa-times"
                                                  : "",
                                            },
                                            on: {
                                              "click:append-outer": function (
                                                $event
                                              ) {
                                                _vm.search.campagne = null
                                              },
                                            },
                                            model: {
                                              value: _vm.search.campagne,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.search,
                                                  "campagne",
                                                  $$v
                                                )
                                              },
                                              expression: "search.campagne",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [_vm._v(" > ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "modal",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                    _vm._v("Filtrer"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.modalSettings,
            callback: function ($$v) {
              _vm.modalSettings = $$v
            },
            expression: "modalSettings",
          },
        },
        [
          _vm.isGranted("OPPORTUNITIES_INDEX_ALLSERVICE")
            ? _c("listUser", { on: { selectChange: _vm.setAsUser } })
            : _vm._e(),
          _vm.isGranted("OPPORTUNITIES_INDEX_ALLSERVICE")
            ? _c("v-switch", {
                attrs: { label: "Voir toutes les opportunités" },
                model: {
                  value: _vm.search.all,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "all", $$v)
                  },
                  expression: "search.all",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }